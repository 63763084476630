import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import API_SERVICE from 'shared/services/api-service';

let AUTH_TOKEN: any = null;
let listener: any = null;

interface AuthContextProps {
    apiCreds: any | undefined;
    isAuth: boolean;
    updateAPICreds: (authToken: any | null) => void;
    permissions: any
}


const AuthContext = React.createContext<AuthContextProps>({
    apiCreds: undefined,
    isAuth: false,
    updateAPICreds: () => {
    },
    permissions: null
});

function AuthProvider({ children }: any) {
    const [apiCreds, setApiCreds] = useState(() => {
        const userStr = localStorage.getItem('user');
        return userStr ? JSON.parse(userStr) : undefined;
    });

    const [permissions, setPermissions] = useState(null as any);


    const updateAPICreds = useCallback((pAuthToken: any) => {
        if (pAuthToken.payload) {
            localStorage.setItem('user', JSON.stringify(pAuthToken.payload));
        } else {
            localStorage.removeItem('user');
        }
        AUTH_TOKEN = pAuthToken.payload;
        setApiCreds(pAuthToken.payload);
    }, []);


    useEffect(() => {
        function forceLogout() {
            notification.error({
                message: 'You are logged Out',
            });
            updateAPICreds(null);
        }
    }, [apiCreds]);

    return (
        <AuthContext.Provider
            value={{
                apiCreds,
                updateAPICreds,
                isAuth: !!apiCreds,
                permissions,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

const AuthConsumer = AuthContext.Consumer;

/**
 * We use this function to be able to access
 * the auth token from outside React components.
 */
function getAuthToken() {
    return AUTH_TOKEN;
}

function getUrlFormattedToken() {
    const token = getAuthToken();
    return token ? encodeURIComponent(token.value) : '';
}

function logout() {
    if (listener != null) {
        listener(null);
    }
    localStorage.clear();
    window.location.href = '/login';
    notification.error({ message: 'You are logged out' });
}

export {
    AuthProvider,
    AuthConsumer,
    AuthContext,
    getAuthToken,
    getUrlFormattedToken,
    logout,
};

