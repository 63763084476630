import { Button, Card, Col, Divider, Form, notification, Row, Tabs, Typography } from "antd";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
// import { useEffectOnce } from "react-use";
import AlternateAccountOne from "./payout-details/AlternateAccountOne";
import AlternateAccountTwo from "./payout-details/AlternateAccountTwo";
import PrimaryAccount from "./payout-details/PrimaryAccount";
import PaySubConnectore from "./payout-details/PaySubConnectore";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
const { TabPane } = Tabs;
const { Title, Text } = Typography;
type Props = {};


const ConnectorPayoutDetails: React.FunctionComponent<{ connector: any, disableValue: any, setConnector: any}> = (props) => {

    const [activeKey, setActiveKey] = useState("1");
    const history = useHistory();
    const [disablefieldPayout, setDisableFieldPayout] = useState(false);
    const [accountTypes, setAccountTypes] = useState({} as any);
    const [connectorDetails, setConnectorDetails] = useState(props?.connector);
    const {appColors}: any = useSelector((state: RootState) => state.common);
    let { id } = useParams() as any;

    useEffect(() => {
        getAllTypes();
        if (props?.connector){
            setConnectorDetails(props?.connector);
        }
    }, [props.connector]);

    const onTabChange = (key: any) => {
        setActiveKey(key);
    };
    const getAllTypes = () => {
        API_SERVICE.getTypes()
          .then(({ data }) => {
            let filterAccountType = data.find(
              (item: any) => item.propertyName == "ACCOUNT_TYPE"
            );
            setAccountTypes(filterAccountType?.keyAndDisplayValueList);
          })
          .catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          });
      }

    return (
        <Card className="custom-app-card">
            <Row>
                <Col className="btn-box" span={24}>
                    <Tabs activeKey={activeKey} onChange={onTabChange} className="dynamic-tabs" style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}>
                        <TabPane tab="Primary A/C" key="1" >
                            <PrimaryAccount primaryAccountDetails={connectorDetails} setConnectorDetails={setConnectorDetails} disablefieldPayout={disablefieldPayout} />
                        </TabPane>
                        {id == connectorDetails?.basicDetails?.ownerConnectorUserId &&
                            <TabPane tab="Pay Sub-Connectors" key="2">
                                <PaySubConnectore  isEditable={true}/>
                            </TabPane>
                        }
                    </Tabs>
                </Col>
            </Row>
        </Card>
    );
};

export default ConnectorPayoutDetails;