import "./profile.scss";
import { Button, Col, Row, Typography, notification } from "antd";
import React, { useEffect } from "react";
import API_SERVICE from "shared/services/api-service";
import {ReactComponent as IconCopy} from "../../assets/images/copy.svg";
import {ReactComponent as IconUser} from "../../assets/images/user.svg";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
const { Text } = Typography;

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = React.useState(null as any);
  const {appColors, isMobile}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({}));
    getUser();
  }, []);

  const getUser = () => {
    let user = localStorage.getItem("user");
    if (user !== null) {
      const id1 = JSON.parse(user);
      API_SERVICE.getUserProfileDetails()
        .then(({ data }) => {
          setUserInfo(data.payload.userDetails);
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  return (
    <div className="content-box">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="lead-detail-box1">
            <div className="lead-details">
              <Row gutter={[16, 16]} className="custom-row">
                <Col span={5} xs={24} xl={5} style={{ paddingLeft:"40px", display:isMobile ? "grid" : " " , justifyContent: isMobile? "center" : " ", flexDirection:'column'}}>
                  <IconUser
                    className="user-icon"
                    height={130}
                    width={130}
                    style={{"--appPrimary": appColors?.appPrimaryColor ?? ""} as any}
                  />

                  <Title level={5} style={{ marginLeft: "10px", marginTop:'10px' }}>{userInfo?.fullName ?? ""}</Title>
                  <div style={{display:'flex', marginTop:'5px'}}>
                    <span style={{ marginRight: "4px", marginTop:'2px' }}>{userInfo?.userId ?? ""}</span>
                    <Button
                      className="custome-btn-icon-secondary"                
                      style={{'--bgColor': appColors?.appSecondaryColor ?? "",borderColor:'transparent'}as any}
                      size="small"
                      shape="circle"
                      onClick={() => navigator.clipboard.writeText(userInfo?.userId ?? "")}
                      icon={<IconCopy width={14} />}
                    />
                  </div>
                </Col>
                <Col span={19}  xs={24} xl={19} style={{ width: "845px" }}>
                  <Row>
                    <Col span={20} xs={24} xl={20}>
                      <Title level={5}>Email</Title>
                      <Text>{userInfo ? userInfo.emailId : null}</Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "4%" }}>
                    <Col span={5} xs={24} xl={5}>
                      <Title level={5}>PhoneNumber</Title>
                      <Text>{userInfo ? userInfo.phoneNumber : null}</Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "4%" }}>
                    <Col span={6} xs={12} xl={6}>
                      <Title level={5}>Date Of Birth</Title>
                      <Text>{userInfo ? userInfo.dob : 20 / 20 / 2020}</Text>
                    </Col>
                    <Col span={4} xs={12} xl={4}>
                      <Title level={5}>Gender</Title>
                      <Text>{userInfo ? userInfo.gender : null}</Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "4%" }}>
                    <Col span={6} xs={12} xl={6}>
                      <Title level={5}>Pan</Title>
                      <Text>{userInfo ? userInfo.panCard : null}</Text>
                    </Col>
                    <Col span={4} xs={12} xl={6}>
                      <Title level={5}>Aadhar</Title>
                      <Text>{userInfo ? userInfo.aadhar : null}</Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "4%" }}>
                    <Col span={6} xs={12} xl={6}>
                      <Title level={5}>Department</Title>
                      <Text>{userInfo ? userInfo.department : null}</Text>
                    </Col>                    
                    <Col span={6} xs={12} xl={6}>
                      <Title level={5}>Line Manager</Title>
                      <Text>{userInfo ? userInfo.lineManager : null}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
