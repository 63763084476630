import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import AddtionalFieldsFlowable from "./AdditionalFieldsFlowable";

import { notification } from "antd";
import parameters from "shared/parameters";

const MobileLender = () => {
  const [loading, setloading] = useState(false);
  const [getNextTaskPayload, setGetNextTaskPayload] = useState<any>();
  const [openFlowablePath, setOpenFlowablePath] = useState(false);
  const [taskDetails, setTaskDetails] = useState<any>();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isLastTask, setIsLastTask] = useState(false);
  const [isConsentID, setIsConsentID] = useState(null);
  const [isLoanProductId, setLoanProductId] = useState(null);
  const [flowableLeadData, setFlowableLeadData] = useState<any>();
  const [selectedLander, setselectedLandervalue] = useState<any>();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const caseId = params.get("caseId");
  const lenderId = params.get("lenderId");

  useEffect(() => {
    getCaseDetails(caseId);
  }, []);

  const getCaseDetails = (caseId: any) => {
    API_SERVICE.getLeadsById(caseId)
      .then(({ data }) => {
        if (data?.payload) {
          setIsConsentID(data?.payload?.lenderConsentId);
          setLoanProductId(data?.payload?.loanProductId);
          setFlowableLeadData(data.payload);

          const lenderActivityDetails =
            data?.payload?.caseDetails?.lenderActivityHolder
              ?.lenderActivityDetails;

          const setselectedLander = lenderActivityDetails?.find((obj) => {
            return obj?.lenderInfoId === lenderId;
          });
          setselectedLandervalue(setselectedLander);

          handleGetNextTask(caseId, lenderId);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleGetNextTask = (caseId: any, lenderId: any) => {
    setloading(true);
    API_SERVICE.getNextTask(caseId, lenderId)
      .then(({ data }) => {
        const payload = data.payload;
        console.log("payload: ", payload);
        setGetNextTaskPayload(payload);
        setIsLastTask(payload.lastStep);

        setTaskDetails({
          taskName: payload?.taskName,
          taskDefinitionKey: payload?.taskDefinitionKey,
          taskId: payload?.taskId,
        });

        setOpenFlowablePath(true);
      })
      .catch((e: any) => {})
      .finally(() => {
        setloading(false);
      });
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    try {
      str = str?.replaceAll(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replaceAll(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replaceAll(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    } catch (error) {
      str = str?.replace(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replace(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replace(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    }
    return JSON.parse(str);
  };

  return (
    <>
      {openFlowablePath && (
        <AddtionalFieldsFlowable
          getNextTaskPayload={getNextTaskPayload}
          setOpenFlowablePath={setOpenFlowablePath}
          openFlowablePath={openFlowablePath}
          jsonSchema={getJSONSchema(getNextTaskPayload?.jsonSchema)}
          setTaskDetails={setTaskDetails}
          taskDetails={taskDetails}
          customUI={false}
          currLenderId={lenderId}
          currLoanProductId={isLoanProductId}
          currLeadId={caseId}
          leadData={flowableLeadData}
          setShowAdditionalFields={setShowAdditionalFields}
          callbackAdditionalField={() => {}}
          selectedLander={selectedLander}
          lenderLoginHandler={{}}
          skippedLoginStatus={() => {}}
          isLastTask={isLastTask}
          setIsLastTask={setIsLastTask}
        />
      )}
    </>
  );
};
export default MobileLender;
