import { notification } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "../../../LeadNew/LeadDetail/LenderPrivacyPolicy/LenderPrivacyPolicy.scss";
import PrivacyPolicyPanel from "./PrivacyPolicyPanel";
import API_SERVICE from "shared/services/api-service";

interface ILenderPrivacyPolicy {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  leadData?: any;
  getLeadById: any;
}

const LenderPrivacyPolicy: React.FC<ILenderPrivacyPolicy> = (
  props: ILenderPrivacyPolicy
) => {
  const { leadData, setIsLoading, getLeadById } = props;
  const [lenderListInsurer, setLenderListInsurer] = useState([] as any);
  const [lenders, setLenders] = useState([
    ...leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails,
  ] as any);

  useEffect(() => {
    setLenders([
      ...leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails,
    ]);
    getProductsPlans();
  }, [leadData]);

  const getProductsPlans = () => {
    setIsLoading(true);
    let payload = {
      lenderIds: lenders?.map((lender: any) => lender?.lenderInfoId)?.join(","),
      loanProductId: leadData?.loanProductId,
      status: "ACTIVE"
    };
    API_SERVICE.getLenderListInsurer(payload)
      .then(({ data }) => setLenderListInsurer(data?.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const updateCase = (lender) => {
    setIsLoading(true);
    const payload = {
      lenderDetails: [lender],
      comment: "",
    };

    let updatedStatus = lender.status;

     
    if (lender.status === 'POLICY_ISSUED') {
      updatedStatus = "POLICY_ISSUED";
    }
     else if (lender.status === 'UNDERWRITING_REJECT') {
      updatedStatus = "POLICY_STATUS_REJECTED";  
    } 
    else if (lender.status === 'WITHDRAWN') {
      updatedStatus = "POLICY_STATUS_WITHDRAWN";  
    } 
    else if (lender.status === 'COUNTER_OFFER') {
      updatedStatus = "POLICY_STATUS_COUNTER_OFFER";  
    }
  

    API_SERVICE.updateCase(
      leadData?.caseDetails?.caseId,
      updatedStatus,
      payload,
      leadData?.loanProductId
    )
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });

          const tmpLender = lenders.map((lender: any) => {
            const res =
              data?.payload?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.at(
                0
              );
            if (lender.lenderInfoId == res.lenderInfoId) {
              return res;
            }
            return lender;
          });

          setLenders(tmpLender);

          getLeadById()
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };

  const actionAdd = (i: number, iDetails: any) => {
    const tmpLender1 = lenders.at(i);
    const iDet = [...iDetails];
    iDet.push({});
    tmpLender1.insuranceDetails = { insuranceDetails: iDet };
    tmpLender1.status = "";
    tmpLender1.comments = "";
    const tmpLenders = [...lenders];
    tmpLenders[i] = tmpLender1;
    setLenders(tmpLenders);
  };

  return (
    <div>
      <div
        className="leadDisburse-Detail-scroll"
        style={{ maxHeight: "400px" }}
      >
        <ul style={{ listStyle: "none", padding: "0px" }}>
          {lenders?.map((lender: any, i: any) => {
            const tmpInsurer = lenderListInsurer?.find(
              (insurer: any) => insurer?.lenderId === lender?.lenderInfoId
            );
            const plans = tmpInsurer?.productPlanDetails;
            const iDetails = lender?.insuranceDetails?.insuranceDetails;
            const showAdd =
              (plans?.length ?? 0) > 1 &&
              (plans?.length ?? 0) > (iDetails?.length ?? 0);

            if ((iDetails?.length ?? 0) === 0) {
              return (
                <PrivacyPolicyPanel
                  lender={lender}
                  productPlans={plans}
                  lenderIndex={i}
                  updateCase={updateCase}
                  showAdd={showAdd}
                  onClickAdd={() => actionAdd(i, [])}
                />
              );
            } else {
              return iDetails?.map((detail: any) => {
                const tmpLender = { ...lender };
                tmpLender.insuranceDetails = detail;
                return (
                  <PrivacyPolicyPanel
                    lender={tmpLender}
                    productPlans={plans}
                    lenderIndex={i}
                    updateCase={updateCase}
                    showAdd={showAdd}
                    onClickAdd={() => actionAdd(i, iDetails)}
                  />
                );
              });
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default LenderPrivacyPolicy;
