import React from "react";
import logo53 from "../../assets/images/girl.png";
import logo1 from "../../assets/images/search.png";
import logo3 from "../../assets/images/plus.png";
import logo4 from "../../assets/images/view.png";
import logo5 from "../../assets/images/edit.png";
import logo6 from "../../assets/images/delete.png";
import logo7 from "../../assets/images/banner.png";
import { Avatar } from "antd";


// type Props = {};

const Banner: React.FunctionComponent = () => {
  return (
        <div style={{width:"83%"}}>
        <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center",marginTop:15}}>
        <div style={{marginRight:10,borderRadius:15,boxShadow:  "1px 1px 4px 1px  grey",border: "1px solid #fff",width:25,height:25}}>
        {/* <img src={logo49} /> */}
        </div>
         <div style={{marginRight:30}}>
        {/* <Chip
        avatar={<Avatar alt="Natacha" src={logo53} />}
        label="Leselie A.."
        variant="outlined"
        style={{ boxShadow:  "1px 1px 4px 1px  grey",border: "1px solid #fff"}}
      /> */}
      </div>
      </div>
        <div style={{marginTop:25}}>
        <span style={{fontSize:20,fontWeight:600,display:"flex",justifyContent:"flex-start",marginLeft:25}}>Banner</span>
        </div>
  
        <div style={{display:"flex",marginLeft:25,marginTop:15}}>
        <div style={{position:"relative"}}>
  <input type="search"  placeholder="Search..." style={{ boxShadow:  "1px 1px 9px 1px  grey",border: "3px solid #fff",width:600,height:50,outline:"none",paddingLeft:35,borderRadius:5}} />
  <img src={logo1} style={{position:"absolute",height:20,width:20,top:15,left:8}} />
    </div>
  
  
  <button  style={{backgroundColor:"#273896" , border: "none", borderRadius: 10, width: 250, height: 50, fontSize: 17, fontWeight: 600,display:"flex",alignItems:"center",justifyContent:"space-evenly",marginLeft:120}}>
   <img src={ logo3} />
    <a href= "/add-banner" style={{color:"white" ,textDecoration:"none"}}> Add Banner</a>
    </button>
  </div>
  
  <div style={{width:"85%",padding:18,marginTop:5}}>
   
      
  
        <table  style={{width:"102%"}}>
  <tr>
  <th style={{fontSize:15,fontWeight:"normal"}}>Banner</th>
  <th style={{fontSize:15,fontWeight:"normal"}}>Title</th>
  <th style={{fontSize:15,fontWeight:"normal"}}>Created</th>
  
              <th style={{fontSize:15,fontWeight:"normal"}}>Status</th>
              <th style={{fontSize:15,fontWeight:"normal"}}>Action</th>
   </tr>
  <tr>
  <td><img src={logo7} /></td>
                <td style={{fontWeight:600}}> Lorem Ipsum is simply dummy text of the printing a</td>
                <td style={{fontWeight:600}}> 04 May 2022 3:00 PM </td>
                <td style={{textAlign:"center"}}> </td>
                {/* <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}/></td> */}
                <td><img src={logo4} /> <img src={logo5} />  <img src={logo6} /></td>
  </tr>
  <tr>
  <td><img src={logo7} /></td>
                <td style={{fontWeight:600}}> Lorem Ipsum is simply dummy text of the printing a</td>
                <td style={{fontWeight:600}}> 04 May 2022 3:00 PM </td>
                <td style={{textAlign:"center"}}> </td>
                {/* <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}/></td> */}
                <td><img src={logo4} /> <img src={logo5} />  <img src={logo6} /></td>
  </tr>
  <tr>
  <td><img src={logo7} /></td>
                <td style={{fontWeight:600}}> Lorem Ipsum is simply dummy text of the printing a</td>
                <td style={{fontWeight:600}}> 04 May 2022 3:00 PM </td>
                <td style={{textAlign:"center"}}> </td>
                {/* <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}/></td> */}
                <td><img src={logo4} /> <img src={logo5} />  <img src={logo6} /></td>
  </tr>
        </table>
        </div>
        </div>
  );
};

export default Banner;
