import React, { useState, useEffect } from "react";
import {
  Button,
  notification,
  Space
} from "antd";
import { numberWithCommas } from "shared/services/Utility";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
interface ABCLDocument {
  data: any;
  responseJSONSchema: any;
  selectedLander: any;
  leadData: any;
  handleFormSubmit: any;
  setCloseConfirmation: any;
  setloading: any;
  setDocIframe: any;
}
const ABCLDocuments: React.FunctionComponent<ABCLDocument> = ({
  data,
  responseJSONSchema,
  selectedLander,
  leadData,
  handleFormSubmit,
  setCloseConfirmation,
  setloading,
  setDocIframe,
}) => {
  const [showIframe, setShowIframe] = useState(false);
  const [IframeURL, setIframeURL] = useState("");
  const [onLoadIframe, setOnLoadIframe] = useState(0);
  const [fetchingOffer, setFetchingOffer] = useState(false);
  const [IframeLoading, setIframeLoading] = useState(false);
  const [gst, setGst] = useState(null);
  const [banking, setBanking] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [maxOfferAmt, setMaxOfferAmt] = useState<any>(0);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const handleMessage = (event: any) => {
    if (
      event.data.type === "finbox-lending" ||
      event.data.type === "finbox-frame-lending"
    ) {
      console.log("event", event.data);
      if (
        event.data.status === "BC_COMPLETED" ||
        event.data.status === "EXIT" ||
        event.data.status === "GST_COMPLETED"
      ) {
        handleFormSubmit({ data: {} });
      }
    }
  };

  useEffect(() => {});
  const onHandleSkip = () => {
    handleFormSubmit({ data: { skip: true } });
  };
  useEffect(() => {
    API_SERVICE.getOffersForABCL(
      selectedLander?.lenderInfoId,
      leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          const OfferData = JSON.parse(data.payload);
          console.log('OfferData', OfferData);

          setBanking(OfferData.data.dataRequired.banking);
          setGst(OfferData.data.dataRequired.gst);

          const OfferData1 = OfferData.data.offers[0].slabs ?? [];

          const sortData = OfferData1.filter((item) => item.maxAmount > 0).sort(
            (a: { maxAmount: number }, b: { maxAmount: number }) =>
              a.maxAmount - b.maxAmount
          );
          const onlyMax = [
            ...Array.from(
              new Set(
                sortData
                  .map((item: { maxAmount: any }) => item.maxAmount)
                  .sort((a: number, b: number) => a - b)
              )
            ),
          ];
          setMaxOfferAmt(onlyMax.at(-1));

          // if (OfferData.length > 0) {

          //   console.log("testing ke liye")

          //   //console.log("OfferData", OfferData[0]);
          //   //console.log("OfferData.lead", props.leadData);
          //   // setGst(OfferData[0].gst);
          //   // setRoi(OfferData[0].interest);
          //   // setOfferID(OfferData[0].offerID);
          //   // setOfferMetaData(OfferData[0].offerMetadata);
          //   // updateValues(OfferData[0].offerMetadata);
          //   //console.log("data", OfferData);
          // }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // const onIframeLoad = (e) => {
  //   if (onLoadIframe === 0) {
  //     setOnLoadIframe(1);
  //     setloading(false);
  //   } else if (onLoadIframe === 1) {
  //     setOnLoadIframe(2);
  //   } else if (onLoadIframe === 2) {
  //     setFetchingOffer(true);
  //     handleFormSubmit({ data: {} });
  //   }
  //   let doc = document.getElementById("abcl-iframe");
  //   // let y = doc?.contentDocument;
  // };

  const handleShowIframe = (arg) => {
    console.log("argument", arg);
    setDocIframe(true);
    setIframeLoading(true);
    setloading(true);

    API_SERVICE.getDocumentURLForABCL(
      selectedLander?.lenderInfoId,
      leadData?.caseDetails?.caseId,
      arg
    )
      .then(({ data }) => {
        if (data) {
          console.log("data", data);
          setIframeURL(data?.payload.data.url);
          setShowIframe(true);
          setloading(false);
        }
      })
      .catch((error) => {
        setIframeLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
    setShowIframe(true);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "auto",
      }}
    >
      {!showIframe && !gst && banking ? (
        <>
          {banking === "mandatory" ? (
            <div
              style={{
                flexDirection: "column",

                display: "flex",
                justifyContent: "center",
                fontSize: "24px",
                fontWeight: "350",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "350",
                }}
              >
                To Get the loan Offer, Please upload
              </div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "350",
                }}
              >
                6 months Bank Statements or
              </div>

              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "350",
                }}
              >
                link your GST Number
              </div>
            </div>
          ) : (
            banking === "optional" && (
              <div
                style={{
                  flexDirection: "column",

                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: "350",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  {`You are eligible for a loan of Rs.${numberWithCommas(
                    maxOfferAmt
                  )}. `}
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  You can also connect your GSTIN or
                </div>

                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  Upload your Bank Statement to get the
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  highest possible loan amount.
                </div>
              </div>
            )
          )}
          {/* buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBlock: "10px",
            }}
          >
            <Button
              size="middle"
              className="dynamic-btn-primary"
              style={{
                '--btnColor' :appColors?.appPrimaryColor ?? "",
                paddingInline: "30px",
                width: "340px",
                height: "52px",
                borderRadius: "10px",
              } as any}
              type="primary"
              // disabled={loanStatus !== "Y" || isValuesUpdated}
              htmlType="button"
              onClick={() => handleShowIframe("BANKING")}
            >
              Upload Statement
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBlock: "10px",
            }}
          >
            <Button
              size="middle"
              className="dynamic-btn-primary"
              style={{
                '--btnColor' :appColors?.appPrimaryColor ?? "",
                paddingInline: "30px",
                width: "340px",
                height: "52px",
                borderRadius: "10px",
              } as any}
              type="primary"
              // disabled={loanStatus !== "Y" || isValuesUpdated}
              htmlType="button"
              onClick={() => handleShowIframe("GST")}
              disabled={isButtonDisabled}
            >
              Link GST Number
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBlock: "10px",
            }}
          >
            <Space className="">
              <Button
                className="dynamic-btn-default"
                style={{
                  '--borderColor': appColors?.appSecondaryColor ?? "",
                  paddingInline: "30px",
                  width: "340px",
                  height: "52px",
                  borderRadius: "10px",
                } as any}
                htmlType="button"
                onClick={() => {
                  if (banking === "optional") {
                    onHandleSkip();
                  } else {
                    setCloseConfirmation(true);
                  }
                }}
              >
                {banking === "optional" ? "Skip" : "Close"}
              </Button>
            </Space>
          </div>
          {/* Button end */}
        </>
      ) : !showIframe && banking && gst ? (
        <>
          {banking === "mandatory" && gst === "mandatory" ? (
            <div
              style={{
                flexDirection: "column",

                display: "flex",
                justifyContent: "center",
                fontSize: "24px",
                fontWeight: "350",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "350",
                }}
              >
                To Get the loan Offer, Please upload
              </div>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "350",
                }}
              >
                6 months Bank Statements or
              </div>

              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "350",
                }}
              >
                link your GST Number
              </div>
            </div>
          ) : (
            banking === "optional" &&
            gst === "optional" && (
              <div
                style={{
                  flexDirection: "column",

                  display: "flex",
                  justifyContent: "center",
                  fontSize: "24px",
                  fontWeight: "350",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  {`You are eligible for a loan of Rs.${numberWithCommas(
                    maxOfferAmt
                  )}. `}
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  You can also connect your GSTIN or
                </div>

                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  Upload your Bank Statement to get the
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "350",
                  }}
                >
                  highest possible loan amount.
                </div>
              </div>
            )
          )}
          {/* buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBlock: "10px",
            }}
          >
            <Button
              size="middle"
              className="dynamic-btn-primary"
              style={{
                '--btnColor' :appColors?.appPrimaryColor ?? "",
                paddingInline: "30px",
                width: "340px",
                height: "52px",
                borderRadius: "10px",
              } as any}
              type="primary"
              // disabled={loanStatus !== "Y" || isValuesUpdated}
              htmlType="button"
              onClick={() => handleShowIframe("BANKING")}
            >
              Upload Statement
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBlock: "10px",
            }}
          >
            <Button
              size="middle"
              className="dynamic-btn-primary"
              style={{
                '--btnColor' :appColors?.appPrimaryColor ?? "",
                paddingInline: "30px",
                width: "340px",
                height: "52px",
                borderRadius: "10px",
              } as any}
              type="primary"
              // disabled={loanStatus !== "Y" || isValuesUpdated}
              htmlType="button"
              onClick={() => handleShowIframe("GST")}
            >
              Link GST Number
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBlock: "10px",
            }}
          >
            <Space className="">
              <Button
                className="dynamic-btn-default"
                style={{
                  '--borderColor': appColors?.appSecondaryColor ?? "",
                  paddingInline: "30px",
                  width: "340px",
                  height: "52px",
                  borderRadius: "10px",
                } as any}
                htmlType="button"
                onClick={() => {
                  if (banking === "optional" && gst === "optional") {
                    onHandleSkip();
                  } else {
                    setCloseConfirmation(true);
                  }
                }}
              >
                {banking === "optional" && gst === "optional"
                  ? "Skip"
                  : "Close"}
              </Button>
            </Space>
          </div>
          {/* Button end */}
        </>
      ) : fetchingOffer ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
              alignContent: "center",
            }}
          >
            {" "}
            wait fetching offers...
          </div>
        </>
      ) : (
        <>
          {/* I-frame  */}
          <div
            style={{
              width: "100%",
            }}
          >
            <iframe
              src={IframeURL}
              id="abcl-iframe"
              height="700px"
              width="100%"
              // onLoad={(e) => onIframeLoad(e)}
            ></iframe>
          </div>
        </>
      )}
    </div>
  );
};

export default ABCLDocuments;
