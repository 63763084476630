import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const bulkUpdateSlice = createSlice({
  name: "BulkUpdateReducer",
  initialState: { pageNo: 1, pageSize: 10 },
  reducers: {
    setBulkUpdatePageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setBulkUpdatePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBulkUpdatePageNo, setBulkUpdatePageSize } =
bulkUpdateSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default bulkUpdateSlice.reducer;