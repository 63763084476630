import {
  Button,
  Col,
  Form,
  notification,
  Popconfirm,
  Row,
  Spin,
  Switch,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import { AiFillDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
const AppContent: React.FC = () => {
  // states
  const [faq, setFaq] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const [tNc, setTNc] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [faqLoading, setFaqLoading] = useState(false);
  const [privacyPolicyLoading, setPrivacyPolicyLoading] = useState(false);
  const [tNcLoading, setTNcLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    getFAQ();
    getPrivacyPolicy();
    getTermsNCondition();
  }, []);

  const onChangeStatus = (event: any, id: any, title: any, desc: any) => {
    // update API
    const payload = {
      active: event,
      title: title,
      description: desc,
      type: "FAQ",
    };
    API_SERVICE.updateAppContent(payload, id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
        }
        getFAQ();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  // Get API
  const getFAQ = (params?: any) => {
    setFaqLoading(true);
    // API_SERVICE.getAppContent("FAQ", params)
    API_SERVICE.getAppContentFAQ("FAQ", params)
      .then(({ data }: any) => {
        if (data?.payload?.content?.length > 0) {
          setFaq(data.payload.content);
        } else {
          setFaq([]);
          notification.error({ message: "No App Content found." });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setFaqLoading(false);
      });
  };

  // Get Privacy_policy
  const getPrivacyPolicy = (params?: any) => {
    setPrivacyPolicyLoading(true);
    API_SERVICE.getAppContent("PRIVACY_POLICY", params)
      .then(({ data }: any) => {
        if (data.payload.length > 0) {
          setPrivacyPolicy(data.payload);
        } else {
          notification.error({ message: "No Privacy Policy found." });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPrivacyPolicyLoading(false);
      });
  };

  // Get Terms and condition
  const getTermsNCondition = (params?: any) => {
    setTNcLoading(true);
    API_SERVICE.getAppContent("TERMS_AND_CONDITIONS", params)
      .then(({ data }: any) => {
        if (data.payload.length > 0) {
          setTNc(data.payload);
        } else {
          notification.error({
            message: "No Terms & Conditions found.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTNcLoading(false);
      });
  };

  // payload
  const buildPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }

    let desc = formData.answer;
    if((formData?.videoURL?.length ?? 0) > 0) {
      desc = formData.videoURL + "~" + formData.answer;
    }

    const payload = {
      active: true,
      title: formData.question,
      description: desc,
      type: "FAQ",
    };
    return payload;
  };

  // onSubmit
  const onFinish = (values: any) => {
    // Post API
    const payload = buildPayload(values);
    API_SERVICE.addAppContent(payload)
      .then(({ data }) => {
        setLoading(true);
        if (data) {
          setLoading(false)
          notification.success({ message: data.message });
          setShowForm(false);
          setFaq([]);
          getFAQ();
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onSave = (values: any, id) => {
    // update API
    const payload = {
      active: true,
      description: values.privacyPolicy,
      title: "Privacy Policy",
      type: "PRIVACY_POLICY",
    };
    API_SERVICE.updateAppContent(payload, id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onSaveTC = (values, id) => {
    // update API
    const payload = {
      active: true,
      description: values.termsAndConditions,
      title: "Terms & Conditions",
      type: "TERMS_AND_CONDITIONS",
    };
    API_SERVICE.updateAppContent(payload, id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onSaveFaq = (values: any, id: any) => {
    // update API
    let desc = values.answer;
    if((values?.videoURL?.length ?? 0) > 0) {
      desc = values.videoURL + "~" + values.answer;
    }

    const payload = {
      active: true,
      description: desc,
      title: values.question,
      type: "FAQ",
    };
    API_SERVICE.updateAppContent(payload, id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getFAQ();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const deleteHandler = (id) => {

    API_SERVICE.deleteAppContent(id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
        }
        setFaq([]);
        getFAQ();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <div>
      <Title level={3}>App Content</Title>

      {privacyPolicy.length !== 0 ? (
        privacyPolicy.map((p: any, index) => {
          return (
            <div style={{ marginBottom: "10px" }}>
              <Form layout="vertical" initialValues={{ privacyPolicy: `${p.description}` }} autoComplete="off"
                name="privacyPolicy" key={index} onFinish={(values) => onSave(values, p.busCommId)}>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item label={p.title} name="privacyPolicy" className="field-bg">
                      <TextArea className="custom-textarea" rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                  <Col>
                    <Form.Item>
                      <Button size="middle" type="primary" htmlType="submit" style={{ minWidth: "150px" }}>Save</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        })
      ) : (
        <div className="example">{privacyPolicyLoading && <Spin />}</div>
      )}

      {/* TAndC list form */}
      {
        tNc.length !== 0 ? (
          tNc.map((t: any, index) => {
            return (
              <div style={{ marginBottom: "10px" }}>
                <Form layout="vertical" initialValues={{ termsAndConditions: `${t.description}` }} autoComplete="off"
                  name="termsAndConditions" key={index} onFinish={(values) => onSaveTC(values, t.busCommId)}>
                  <Row gutter={[16, 16]} >
                    <Col span={24}>
                      <Form.Item label={t.title} name="termsAndConditions" className="field-bg">
                        <TextArea className="custom-textarea" rows={5} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                    <Col>
                      <Form.Item>
                        <Button size="middle" type="primary" htmlType="submit" style={{ minWidth: "150px" }}>Save</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            );
          })
        ) : (
          <div className="example">{tNcLoading && <Spin />}</div>
        )
      }

      {/* FAQ SECTION */}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <span style={{ opacity: "0.6" }}>Help</span>
        </Col>
      </Row>

      {/* FAQ List */}
      {faq.length !== 0 ? (
        faq.map((f: any, index) => {
          return (
            <div style={{ marginBottom: "7px" }}>
              <Form layout="vertical" initialValues={{
                question: `${f.title}`,
                videoURL: `${f.description}`.split("~").length >= 2 ? (`${f.description}`.split("~")[0].startsWith("https://vimeo.com") ? `${f.description}`.split("~")[0] : ``) : ``,
                answer: `${f.description}`.split("~").length >= 2 ? `${f.description}`.split("~")[1] : `${f.description}`,
              }} autoComplete="off" name="faq" key={index} onFinish={(values) => onSaveFaq(values, f.busCommId)}>
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <Form.Item name="question" rules={[{ required: true, message: "Please enter question", }]}>
                      <input className="custom-input" style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="videoURL">
                      <input className="custom-input" placeholder="Enter your video URL" style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item name="answer" className="field-bg" rules={[{ required: true, message: "Please enter answer" }]}>
                      <TextArea className="custom-textarea" rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]} justify="end">
                  <Col span={1} style={{ justifyContent: "end" }}>
                    <Popconfirm placement="topRight" title="Are you sure to delete?" okText="Yes" cancelText="No" className="remove" onConfirm={() => deleteHandler(f.busCommId)}
                        okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
                        cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
                      >
                      <AiFillDelete title="Remove" style={{ fontSize: "26px", color: "red", marginTop: "4px" }} />
                    </Popconfirm>
                  </Col>
                  <Col span={1} style={{ justifyContent: "end" }}>
                    <Switch defaultChecked={f.active} style={{ marginTop: "5px" }} onChange={(event) => onChangeStatus(event, f.busCommId, f.title, f.description)} />
                  </Col>
                  <Col span={5} style={{ display: "flex", justifyContent: "end" }}>
                    <Form.Item>
                      <Button size="middle" type="primary" htmlType="submit" style={{ minWidth: "150px" }}>Save</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        })
      ) : (
        <div className="example">{faqLoading && <Spin />}</div>
      )
      }

      {/* Add New FAQ Button */}
      {showForm !== true && (
        <Row gutter={[24, 24]} style={{ display: "flex" }}>
          <Col>
            <Form.Item>
              <Button size="middle" type="primary" htmlType="submit" onClick={() => setShowForm(true)}>Add New FAQ Item</Button>
            </Form.Item>
          </Col>
        </Row>
      )
      }

      {/* Add New FAQ */}
      {showForm && (
        <Form layout="vertical" title="Help" autoComplete="off" name="FAQ" onFinish={onFinish}>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item name="question" rules={[{ required: true, message: "Please enter question", }]}>
                <input className="custom-input" placeholder="Enter your question" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="videoURL">
                <input className="custom-input" placeholder="Enter your video URL" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item name="answer" className="field-bg" rules={[{ required: true, message: "Please enter answer" }]}>
                <TextArea className="custom-textarea" rows={3} placeholder="Enter your answer" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
            <Col>
              <Form.Item>
                <Button size="middle" type="primary" htmlType="submit" style={{ minWidth: "150px", marginRight: "5px" }} loading={loading}>Save</Button>
                <Button size="middle" type="default" style={{ minWidth: "150px" }} onClick={() => setShowForm(false)}>Cancel</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )
      }
    </div >
  );
};

export default AppContent;
