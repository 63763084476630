import "./index.scss";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import type { UploadProps } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
const EditOffer: React.FC = () => {
  const history = useHistory();

  const [fileList, setFileList] = useState([] as any);
  const [fileList1, setFileList1] = useState([] as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [uploadedFile1, setUploadedFile1] = useState(null as any);
  const [imageUrl1, setImageUrl1] = useState(null as any);
  const [leadData, setLeadData] = useState(null as any);
  const [documentList, setDocumentList] = useState([] as any);
  const {appColors}: any = useSelector((state: RootState) => state.common); 
  const dispatch = useDispatch();

  let { id } = useParams() as any;
  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Offers",
        backScreenPath: "/offers",
        screenTitle: "Edit Offer",
      })
    );
    getLeadById();
    getDocumentList();
  }, []);

  useEffect(() => {
  });

  const [form] = Form.useForm();
  const getLeadById = () => {
    // setLoading(true)
    API_SERVICE.getBusinessid(id)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data.payload);
          if (data.payload) {
            form.setFieldsValue({
              title: data.payload.title,
              description: data.payload.description,

            });
          }
          if (data?.payload?.caseDetails?.caseHistoryHolder) {
            // setLeadHistory(data.payload.caseDetails.caseHistoryHolder)
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const uploadProps: UploadProps = {
    listType: "text",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      // setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const beforeUpload = (file) => {
    let uploadExt = ['image/jpeg', 'image/png']
    if (!uploadExt.includes(file.type)) {
      notification.error({ message: 'Please upload valid image' })
      return;
    }
  }

  const uploadProps1: UploadProps = {
    listType: "text",
    onRemove: (file) => {
      const index = fileList1.indexOf(file);
      const newFileList1 = fileList1.slice();
      newFileList1.splice(index, 1);
      setFileList1(newFileList1);
    },
    beforeUpload: (file) => {
      // setFileList1([...fileList1, file]);
      return false;
    },
    fileList,
  };

  const uploadDocumentHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          setImageUrl(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const uploadDocumentHandler1 = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          setImageUrl1(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onUpload = useCallback((acceptedFiles: any) => {
    let uploadExt = ['image/jpeg', 'image/png']
    if (!uploadExt.includes(acceptedFiles.file.type)) {
      return;
    }
    else {
      setUploadedFile(acceptedFiles.file);
      uploadDocumentHandler(acceptedFiles?.file?.originFileObj);
    }

  }, []);

  const onUpload1 = useCallback((acceptedFiles: any) => {
    let uploadExt = ['image/jpeg', 'image/png']
    if (!uploadExt.includes(acceptedFiles.file.type)) {
      return;
    } else {
      setUploadedFile1(acceptedFiles.file);
      uploadDocumentHandler1(acceptedFiles?.file?.originFileObj);
    }

  }, []);

  const createPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }
    const data: any = {
      type: "OFFER",
      title: formData.title,
      description: formData.description,
      active: leadData?.active,
    };

    if (uploadedFile1?.name) {
      data.webBannerDetail = [{
        documentName: uploadedFile1.name,
        documentURL: imageUrl1,
      }];
    }

    if (uploadedFile?.name) {
      data.mobileBannerDetail = [{
        documentName: uploadedFile.name,
        documentURL: imageUrl,
      }];
    }
    return data;
  };

  const onFinish = (values: any) => {

    if(leadData?.mobileOfferDocumentDetails?.length === 0  && uploadedFile === null){
      notification.error({ message: "please select mobile upload file" });
      return;
    };

    if(leadData?.webOfferDocumentDetails?.length === 0  && uploadedFile1 === null){
      notification.error({ message: "please select web upload file" });
      return;
    };

    const payload = createPayload(values);
    API_SERVICE.updateOffers(payload, id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/offers");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getDocumentList = () => {
    const documentSetId = leadData?.mobileOfferDocumentDetails?.documentSetId;
    API_SERVICE.getDocumentList(documentSetId)
      .then(({ data }) => {
        if (data) {
          setDocumentList(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onDelete = (doc: any) => {
    API_SERVICE.deleteDocument(doc.businessId, doc.id)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          // setImage1(false)
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const documentMobile = (leadData?.mobileOfferDocumentDetails?.length ?? 0) > 0 ? leadData.mobileOfferDocumentDetails[0] : {};
  const documentWeb = (leadData?.webOfferDocumentDetails?.length ?? 0) > 0 ? leadData.webOfferDocumentDetails[0] : {};

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      <Form
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          profession: "Builder",
          gender: "M",
          //   description:{desc}
        }}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[16, 16]}>

          <Col span={8}>
            <Form.Item
              name="Mobile Upload"
              label="MOBILE image should be 340*170"
              rules={[
                {
                  required: false,
                  message: "Please Select Mobile Offer",
                }
              ]}
            >
              <Upload
                className="upload-wrapper"
                listType="picture"
                accept="image/png , image/jpeg"
                onChange={onUpload}
                {...uploadProps}
                beforeUpload={beforeUpload}
                maxCount={1}
              >

                {uploadedFile && (
                  <img
                    src={imageUrl}
                    width="370px"
                    height="80px"
                    alt="mobile offer"
                  />
                )}

                {!uploadedFile && leadData && (
                  <img
                    src={imageUrl ? imageUrl :
                      "data:image/" +
                      documentMobile?.fileExtn +
                      ";base64," +
                      documentMobile?.documentDetails
                    }
                    width="370px"
                    height="80px"
                    alt="mobile offer"
                  />
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="Web Upload"
              label="WEB image should be 1100*270 "
              rules={[
                {
                  required: false,
                  message: "Please Select Web Offer",
                }
              ]}
            >
              <Upload
                className="upload-wrapper"
                listType="picture"
                accept="image/png , image/jpeg"
                onChange={onUpload1}
                {...uploadProps1}
                beforeUpload={beforeUpload}
              >
                {uploadedFile1 && (
                  <img
                    src={imageUrl1}
                    width="370px"
                    height="80px"
                    alt="mobile offer"
                  />
                )}

                {!uploadedFile1 && leadData && (
                  <img
                    src={imageUrl1 ? imageUrl1 :
                      "data:image/" +
                      documentWeb?.fileExtn +
                      ";base64," +
                      documentWeb?.documentDetails
                    }
                    width="370px"
                    height="80px"
                    alt="mobile offer"
                  />
                )}
              </Upload>

            </Form.Item>
          </Col>


          <Col span={16}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
                {
                  pattern: /^[A-Za-z\\s ]+$/,
                  message: "Plesae enter valid title",
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter title"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea
                className="custom-textarea"
                autoSize={{ minRows: 5, maxRows: 8 }}
                placeholder="Please Enter Description"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Divider/> */}
          <Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditOffer;
