import { Button, Form, Image, Input, notification, Popconfirm, Progress, Space, Typography } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import API_SERVICE from "shared/services/api-service";
import Dragger from "antd/lib/upload/Dragger";
import greeting from "../../assets/images/payouts/addFile.svg";
import { CloseOutlined } from "@ant-design/icons";
import "./greeting-cards-index.scss";
import type { UploadProps } from "antd/es/upload/interface";
import Compressor from 'compressorjs';
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";


const { Title, Text } = Typography;
type Props = {};

const EditGreetingCards: React.FunctionComponent<Props> = () => {

    let { id } = useParams() as any;
    const [greetingData, setGreetingData] = useState({} as any);
    const [editGreetingForm] = Form.useForm() as any;
    const [selectedGreetingFileList, setSelectedGreetingFileList] = useState([] as any);
    const [selectMultipleFiles, setSelectMultipleFiles] = useState([] as any);
    const [changeTitle, setChangeTitle] = useState(null as any);
    const [documentDetail, setDocumentDetail] = useState([] as any);
    const history = useHistory();
    const [documentsetId, setDocumentsetId] = useState([] as any);
    const [displayImage, setDisplayImage] = useState([] as any);
    const [fileList, setFileList] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const [greetingEditData, setGreetingEditData] = useState({} as any);
    const {appColors}: any = useSelector((state: RootState) => state.common);

    const uploadProps: UploadProps = {
        fileList,

        onDrop: (item) => {
            let uploadExt = ['image/jpeg', 'image/png', "image/jpg"];

            for (let i = 0; i < item.dataTransfer.files.length; i++) {

                if (!uploadExt.includes(item.dataTransfer.files[i].type)) {
                    notification.error({ message: 'Please droped valid image' })
                    return;
                }

                if (item.dataTransfer.files[i].size > 9437184) {
                    notification.error({ message: 'Please upload image size less than 10 MB' })
                    return;
                }
            }
        },
    };

    useEffect(() => {
        getGreetingById();
    }, []);

    useEffect(() => {
        if (greetingData) {
            editGreetingForm.setFieldsValue({
                title: changeTitle ?? greetingData?.title,
            })
        }
    }, [greetingData, documentsetId, isLoading]);

    const getGreetingById = () => {

        API_SERVICE.getBusinessid(id)
            .then(({ data }) => {
                if (data?.payload) {
                    setGreetingData(data?.payload);
                    setGreetingEditData(data?.payload);
                    setDisplayImage(data?.payload?.documentDetails);
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => { });

    };

    const beforeUpload = (file) => {
        let uploadExt = ['image/jpeg', 'image/png', "image/jpg"]
        if (!uploadExt.includes(file.type)) {
            notification.error({ message: 'Please upload valid image' })
            return;
        }
    }

    const changeUploadFile = (fileList: any) => {

        const tmpList: any = [];
        let filterCounter = fileList?.fileList?.length ?? 0;
        let fileCounter = null as any;
        let files = [] as any;
        let tempFileArray = [] as any;

        if (filterCounter >= 0) {

            files = fileList?.fileList?.filter(
                (item: any) => {
                    let uploadExt = ['image/jpeg', 'image/png', "image/jpg"]
                    return item?.size < 9437184 && uploadExt.includes(item.type);
                }
            )
            tempFileArray = [...selectedGreetingFileList, ...files];
            setSelectedGreetingFileList(tempFileArray);
        }

        

        fileCounter = tempFileArray.length ?? 0;

        let counter = tempFileArray?.length ?? 0;

        tempFileArray?.map((file) => {

            let reader = new FileReader();
            reader.readAsDataURL(file?.originFileObj);

            reader.onload = () => {
                counter = counter - 1;
                tmpList.push(reader.result);
                if (counter <= 0) {
                    setSelectMultipleFiles(tmpList);
                }
                return true;
            }
        });
    }

    const onFinish = (values: any) => {
        if (displayImage.length === 0 && selectedGreetingFileList.length <= 0) {
            notification.error({ message: "please select file" });
            return;
        }

        if (changeTitle === "" || changeTitle === null && !greetingData?.title) {
            notification.error({ message: "Please enter title." });
            return;
        }


        if (!documentDetail) return;
        setDocumentDetail(null);

        if (selectedGreetingFileList?.length ?? 0 > 0) {
            const fileArray = Object.entries(selectedGreetingFileList);
            var temporiginObject = [] as any;
            let tempArray = [] as any;

            fileArray.map((file) => {
                temporiginObject = [...tempArray, file[1]];
                tempArray = temporiginObject
            });

            let temp = [] as any;
            let counter = tempArray?.length ?? 0;


            tempArray.map((file) => {

                let name = file?.name;
                new Compressor(file?.originFileObj, {
                    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        const formData = new FormData();
                        formData.append("file", compressedResult, name);

                        setIsLoading(true);
                        API_SERVICE.fileUpload(formData)
                            .then(({ data }) => {

                                counter = (counter - 1);
                                if (data) {
                                    temp.push(
                                        { documentName: name, documentURL: data }
                                    );
                                    if (counter <= 0) {
                                        setDocumentDetail(temp);
                                        submitGreetings(temp);
                                    }
                                }
                            })
                            .catch((e: any) => {
                                counter = (counter - 1);
                                notification.error({ message: API_SERVICE.handleErrors(e) });
                            });

                    },

                });
            });
        }
        else {
            let temp = [] as any;
            submitGreetings(temp);
        }
    }

    const createPayload = () => {
        let userData = localStorage.getItem("user") as any;
        if (userData) {
            userData = JSON.parse(userData);
        }
        const data = {
            type: "GREETING",
            title: changeTitle ?? greetingData?.title,
            description: " ",
            deletedDocumentIds: documentsetId,
            // documentDetail: temp,
        };
        return data;
    };

    const createPayload1 = (temp) => {
        let userData = localStorage.getItem("user") as any;
        if (userData) {
            userData = JSON.parse(userData);
        }
        const data = {
            type: "GREETING",
            title: changeTitle ?? greetingData?.title,
            description: " ",
            documentDetail: temp,
            deletedDocumentIds: documentsetId,
        };
        return data;
    };

    const submitGreetings = (temp) => {
        const payload = temp.length ?? 0 > 0 ? createPayload1(temp) : createPayload();

        if (!documentDetail) return;
        API_SERVICE.updateOffers(payload, id)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: data.message });
                    history.push("/greeting-cards");
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    }


    const onOkDeleteSingleImage = (id: any) => {
        let result = displayImage?.filter((data) => {
            return data?.businessDocumentId != id;
        });
        setDisplayImage(result);
        let tempDocumentId = [...documentsetId] as any;
        tempDocumentId.push(id);
        setDocumentsetId(tempDocumentId);
    }

    const onCancelClick = () => {
        history.push("/greeting-cards");
    }


    return (
        <>
            <Title level={3}>
                <Link className="link" to={"/greeting-cards"}>
                    Greeting Crad
                </Link>{" "}
                &nbsp; &gt; &nbsp; Edit Greeting Card
            </Title>

            <Form
                layout="vertical"
                form={editGreetingForm}
                name="editGreetingForm"
                onFinish={onFinish}
            >

                <Form.Item
                    label="Title"
                    name="title"
                >
                    <Input
                        className="custom-input"
                        placeholder="Please enter title"
                        value={changeTitle}
                        onChange={(e) => { setChangeTitle(e.target.value) }}
                    />
                </Form.Item>

                <div style={{}} className="dragger-greeting">

                    <div style={{ display: "flex", justifyContent: "center", margin: "20px", height: "150px", }}>

                        <Dragger

                            style={{ width: "600px", height: "400px", borderRadius: "20px" }}
                            accept=".png, .jpg, .jpeg"
                            showUploadList={false}
                            multiple={true}
                            name="editGreetingUpload"
                            maxCount={5}
                            onChange={changeUploadFile}
                            {...uploadProps}
                            beforeUpload={beforeUpload}
                        >

                            <div style={{ marginTop: "-60px" }}>
                                {selectedGreetingFileList?.length ?? 0 > 0 ?
                                    (
                                        <>
                                            <Progress
                                                style={{ width: "70%" }}
                                                percent={100}
                                                strokeColor="purple"
                                            />
                                            <div className="imageClass" style={{ marginTop: "10px", marginLeft: "10px", height: "50px" }}>

                                                {selectMultipleFiles?.map((file, index) => {
                                                    return (<>

                                                        <img src={file} alt="icons" key={index}
                                                            style={{ height: "100px", width: "100px", marginRight: "10px" }}
                                                        />
                                                    </>
                                                    )
                                                })}

                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <div>
                                            <Space>
                                                <img src={greeting} alt="" width={40} />
                                            </Space>
                                            <div style={{ display: "flex", justifyContent: "center" }}><Text> Drag & Drop your file here </Text></div>
                                        </div>
                                    )
                                }
                            </div>
                        </Dragger>
                    </div>
                </div>

                <div className="dashboard-menus">

                    {displayImage?.map((file, index) => {
                        return (
                            <>
                                <div className="outer">
                                    <div className="inner">
                                        <a>
                                            <span>
                                                <Image src=
                                                    {
                                                        "data:image/" +
                                                        file?.fileExtn +
                                                        ";base64," +
                                                        file?.documentDetails
                                                    } alt="icons" key={index}
                                                    style={{ height: "100px", width: "100px", background: "transparent", marginRight: "20px" }}
                                                />
                                            </span>
                                            <Popconfirm
                                                title="Sure to delete?"
                                                onConfirm={() => onOkDeleteSingleImage(file?.businessDocumentId)}
                                                okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
                                                cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
                                            >
                                                <span className="count">{<CloseOutlined />}
                                                </span>
                                            </Popconfirm>
                                            <span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: "85px" }}>
                    <Button
                        className="bulk-upload-btn"
                        style={{ marginRight: "10px" }}
                        onClick={onCancelClick}
                    >Cancel</Button>

                    <Form.Item name="submit-button">
                        <Button
                            loading={isLoading}
                            className="bulk-upload-btn"
                            style={{ marginLeft: "10px" }}
                            type="primary"
                            htmlType="submit"
                        >Submit</Button>
                    </Form.Item>
                </div>

            </Form>

        </>
    )
}

export default EditGreetingCards;