import {
  Button,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect } from "react";
import API_SERVICE from "shared/services/api-service";
import { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Text } = Typography;

const BulkupdateMapImport: React.FunctionComponent<{
  setActiveStepKey: any;
  setBulkUploadExecuteData: any;
  setStatus: any;
}> = (props) => {
  const { setActiveStepKey, setBulkUploadExecuteData } = props;
  var uploadId = null as any;
  var entityType = null as any;
  const [bulkUploadMapData, setBulkUploadMapData] = useState([] as any);
  const [isopenMap, setIsopenMap] = useState(true);
  const [loading, setLoading] = useState(false);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { setStatus } = props;

  useEffect(() => {
    BulkUploadMap();
  }, []);

  const BulkUploadMap = () => {
    uploadId = localStorage.getItem("uploadId");
    entityType = "UPDATE_LEAD";
    API_SERVICE.bulkUploadMap(uploadId, entityType)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: "File Mapped Successfully" });
          setBulkUploadMapData(data.payload.columnHeaderBeans);
        }
      })
      .catch((e: any) =>
        notification.error({ message: API_SERVICE.handleErrors(e) })
      );
  };

  const goToSuccessImportBulkUpload = () => {
    uploadId = localStorage.getItem("uploadId");
    entityType = "UPDATE_LEAD";
    setLoading(true);
    API_SERVICE.bulkUploadExecute(uploadId, entityType)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: "File Uploaded Successfully" });
          setActiveStepKey(3);
          setIsopenMap(false);
          setBulkUploadExecuteData(data.payload);
          setStatus(data.payload?.status);
        }
      })
      .catch((e: any) =>
        notification.error({ message: API_SERVICE.handleErrors(e) })
      )
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "DB column Name",
      dataIndex: "dbColumnName",
    },
    {
      title: "Excel column Name",
      dataIndex: "excelColumnName",
    },
    {
      title: "Mapped",
      dataIndex: "mapped",
      render: (_: any, recored: any) => (
        <Space className="action-button" size="middle">
          {recored.mapped ? (
            <Button
              className="green"
              size="small"
              type="primary"
              shape="circle"
              icon={<CheckOutlined />}
              // onClick={() => goToLeadDetails1(record)}
            />
          ) : (
            <Button
              className="red"
              size="small"
              type="primary"
              shape="circle"
              icon={<CloseOutlined style={{ color: "white" }} />}
              // onClick={() => goToLeadDetails1(record)}
            />
          )}
        </Space>
      ),
    },
  ];

  const onClickCancel = () => {
    const uploadId = localStorage.getItem("uploadId");
    API_SERVICE.bulkUploadDelete(uploadId).then(({ data }) => {
      setActiveStepKey(0);
    });
  };

  const customStylesBtn: any = {
    "--borderColor": appColors?.appSecondaryColor ?? "",
    marginLeft: "10px",
  };
  const customStylesBtnPrimary: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
    marginRight: "10px",
  };
  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };

  return (
    <>
      {isopenMap && (
        <div className="content-box">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Text>
                <h4>Map Columns in your file to the Lead Database Fields</h4>
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Text>
                Each column header below should be mapped to a contact
                properties. some of these have already been mapped based on
                their names.
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}></div>

            <div style={{ marginTop: "20px" }}>
              <div style={{}}>
                <Table
                  columns={columns}
                  dataSource={bulkUploadMapData}
                  className="dynamic-pagination-checkbox"
                  style={customStyles}
                />
              </div>
            </div>

            <div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  className="bulk-upload-btn  dynamic-btn-primary"
                  style={customStylesBtnPrimary}
                  type="primary"
                  loading={loading}
                  onClick={() => goToSuccessImportBulkUpload()}
                >
                  Next
                </Button>

                <Popconfirm
                  placement="topLeft"
                  title="Your uploaded file will be deleted"
                  onConfirm={onClickCancel}
                  okButtonProps={{
                    style: {
                      backgroundColor: appColors?.appPrimaryColor ?? "#273896",
                      border: "none",
                    },
                  }}
                  cancelButtonProps={{
                    style: {
                      borderColor: appColors?.appSecondaryColor ?? "#C71C8E",
                      color: appColors?.appSecondaryColor ?? "#C71C8E",
                    },
                  }}
                >
                  <Button
                    className="bulk-upload-btn  dynamic-btn-default"
                    style={customStylesBtn}
                  >
                    Back
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BulkupdateMapImport;
