import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  Radio,
  Space,
  notification,
  InputNumber,
  List,
  Upload,
  UploadProps,
  Popconfirm,
} from "antd";
import API_SERVICE from "shared/services/api-service";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
import { ReactComponent as AddFileGray } from "assets/images/upload-file-gray.svg";
import { fileUploadCommon } from "shared/services/Utility";
import { ReactComponent as FileBorder } from "../../assets/images/file-border.svg";
import { ReactComponent as DownloadBlack } from "../../assets/images/download-black.svg";
import { MdDelete } from "react-icons/md";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import "./employee.scss";
const { Title, Text } = Typography;

type Props = {};

const ViewEmployee: React.FunctionComponent<Props> = () => {
  const [genders, setGenders] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [bureauCreditDetails, setBureauCreditDetails] = useState(null as any);
  const [isDocumentUpload, setIsDocumentUpload] = useState(false);
  const [fileList, setFileList] = useState([] as any);
  const [fileListWeb, setfileListWeb] = useState([] as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [uploadedFileWeb, setuploadedFileWeb] = useState(null as any);
  const [allFileList, setAllFileList] = useState([] as any);
  const [documentBusinessID, setDocBusinessID] = useState([] as any);
  const [documentDetails, setDocumentDetails] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const nameLocation = useLocation();
  const userName: any = nameLocation.state;
  let { id } = useParams() as any;

  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Employee",
        backScreenPath: "/employees",
        screenTitle: userName?.fullName,
      })
    );
    getAllTypes();
    getAllTypes12();
    getBureauCreditsData();
  }, []);

  const getAllTypes12 = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredGenderType = data.find(
          (item: any) => item.propertyName == "GENDER"
        );
        setGenders(filteredGenderType.keyAndDisplayValueList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllTypes = () => {
    setDocumentDetails([]);
    try {
      API_SERVICE.getUser(id).then(({ data }) => {
        if (data) {
          let gender = data?.payload?.userDetails?.gender;
          gender =
            gender == "Male"
              ? "M"
              : gender == "Female"
              ? "F"
              : gender == "Other"
              ? "O"
              : gender;
          setSelectedGender(gender);
          form.setFieldsValue({
            name: data?.payload?.userDetails?.fullName,
            phoneNumber: data?.payload?.userDetails?.phoneNumber,
            email: data?.payload?.userDetails?.emailId,
            dob: data?.payload?.userDetails?.dob,
            gender: gender,
            pan: data?.payload?.userDetails?.panCard,
            aadhar: data?.payload?.userDetails?.aadhar,
            department: data?.payload?.userDetails?.department,
            designation: data?.payload?.userDetails?.designation,
            branch: data?.payload?.userDetails?.branch,
            lineManager: data?.payload?.userDetails?.lineManager,
            bureauCredits: data?.payload?.userDetails?.bureauCredits,
          });
          setDocBusinessID(
            data.payload.userDetails?.additionalInfo?.employeeDocumentIdList ??
              []
          );
        }
      });
    } catch (e: any) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const getBureauCreditsData = () => {
    API_SERVICE.getBureauCredits(id)
      .then(({ data }) => {
        if (data) {
          setBureauCreditDetails(data?.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const bureauRequestsSummary = [
    {
      title: "Current Month Consumed:",
      list: bureauCreditDetails?.currentMonthConsumed,
    },
    {
      title: "Current Month Remaining:",
      list: bureauCreditDetails?.currentMonthRemaining,
    },
    {
      title: "Lifetime Consumed:",
      list: bureauCreditDetails?.lifeTimeConsumed,
    },
  ];

  const btnRadioStyles: any = {
    "--borderColor": appColors?.appSecondaryColor ?? "",
  };

  //image upload
  const uploadPropsWeb: UploadProps = {
    listType: "text",
    showUploadList: true,
    beforeUpload: (file, fileList) => {
      let uploadExt = ["image/jpeg", "image/png"];
      if (!uploadExt.includes(file.type)) {
        setfileListWeb([file]);
        setuploadedFileWeb(null);
        setImageUrl(null);
        notification.error({ message: "Please upload valid image" });
        return false;
      }
      setfileListWeb([file]);
    },
    fileList: fileListWeb,
  };
  const onWebUpload = async (acceptedFiles: any) => {
    console.log("acceptedFiles", acceptedFiles);
    const file = acceptedFiles.file;
    let uploadExt = ["image/jpeg", "image/png"];
    if (!uploadExt.includes(file?.type)) {
      return;
    }
    setuploadedFileWeb(acceptedFiles.file.originFileObj);
    setFileList(acceptedFiles?.fileList);
    const response = await fileUploadCommon(file);
    console.log("::response", response);
    const obj = {
      documentName: response.file.originFileObj.name,
      documentURL: response?.data,
    };
    setAllFileList([obj]);
  };

  useEffect(() => {
    setIsDocumentUpload(tenantInfo?.businessProductType === "INSURANCE");
    console.log("tenantInfo", tenantInfo);
  }, [tenantInfo?.businessProductType]);

  useEffect(() => {
    if (documentBusinessID?.length > 0) {
      documentBusinessID.map((ids) => {
        API_SERVICE.getDocDetails(ids)
          .then(({ data }) => {
            console.log("DocDetails", data);
            setDocumentDetails((prev) => [...prev, data?.payload]);
          })
          .catch((e: any) => {
            API_SERVICE.handleErrors(e);
          });
      });
    }
  }, [documentBusinessID, documentBusinessID.length]);

  const customStyles: any = {
    "--fileIconColor": appColors?.appPrimaryColor ?? "",
  };

  const downloadDocument = (doc: any) => {
    API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray(
          [bytesData],
          `${data.payload.fileName}.${data.payload.fileExtn}`
        );
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  // const onWebUpload = async (acceptedFiles: any) => {
  //   console.log("acceptedFiles", acceptedFiles);
  //   const file = acceptedFiles.file;
  //   let uploadExt = ["image/jpeg", "image/png"];
  //   if (!uploadExt.includes(file?.type)) {
  //     return;
  //   }
  //   setuploadedFileWeb(acceptedFiles.file.originFileObj);
  //   setFileList(acceptedFiles?.fileList);
  //   const response = await fileUploadCommon(file);
  //   console.log("::response", response);
  //   const obj = {
  //     documentName: response.file.originFileObj.name,
  //     documentURL: response?.data,
  //   };
  //   setAllFileList([response?.data]);
  // };

  const onDelete = (doc: any) => {
    API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        if (data) {
          getAllTypes();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <div className="content-box">
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        name="leadForm"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }}>User Details</label>
            <div className="form-box">
              <Form.Item label="Full Name" name="name" className="field-bg">
                <Input
                  className="custom-input"
                  placeholder="Enter name"
                  readOnly
                />
              </Form.Item>

              <Form.Item
                label="Mobile Number"
                name="phoneNumber"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter mobile number"
                  readOnly
                />
              </Form.Item>

              <Form.Item label="Email ID" name="email" className="field-bg">
                <Input
                  className="custom-input"
                  placeholder="Enter email."
                  readOnly
                />
              </Form.Item>

              <Form.Item label="Date of Birth" name="dob" className="field-bg">
                <Input
                  className="custom-input"
                  placeholder="Enter mobile number"
                  readOnly
                />
              </Form.Item>
              <Form.Item label="PAN" name="pan" className="field-bg">
                <Input
                  className="custom-input"
                  placeholder="Enter PAN number"
                  readOnly
                />
              </Form.Item>
              <Form.Item label="Aadhar" name="aadhar" className="field-bg">
                <Input
                  className="custom-input"
                  placeholder="Enter aadhar details"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Select Gender"
                name="gender"
                rules={[{ required: true, message: "Please select gender." }]}
              >
                <Radio.Group
                  className="custom-radio"
                  style={btnRadioStyles}
                  name="gender"
                >
                  <Space direction="horizontal">
                    {genders &&
                      genders.map((gender: any, i: number) => (
                        <Radio.Button
                          disabled={gender.key != selectedGender}
                          key={i}
                          value={gender.key}
                        >
                          {gender.value}
                        </Radio.Button>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }}>Select Roles</label>
            <div className="form-box">
              <Form.Item
                label="Department"
                name="department"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter departement"
                  readOnly
                />
              </Form.Item>
              <Form.Item label="Location" name="branch" className="field-bg">
                <Input
                  className="custom-input"
                  placeholder="Enter Location"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Line Manager"
                name="lineManager"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter Line Manager"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Designation"
                name="designation"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter designation"
                  readOnly
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8}>
            {isDocumentUpload ? (
              <>
                <Row>
                <label style={{ marginBottom: "15px" }}>Documents</label>
                  <div className="doc-list">
                    <ul>
                      {documentDetails.length <= 0 && (
                        <div>No Document Uploded.</div>
                      )}
                      {documentDetails?.map((doc: any, i: number) => (
                        <li key={i}>
                          <div className="doc-details">
                            <FileBorder style={customStyles} />
                            <span>
                              {doc?.fileName}.{doc?.fileExtn}
                              <br />
                              <span className="type">{doc.documentType}</span>
                            </span>
                          </div>
                          <div className="doc-action">
                            <Space>
                              <span
                                onClick={() => downloadDocument(doc)}
                                className="remove"
                              >
                                <DownloadBlack />
                              </span>
                            </Space>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Row>
              </>
            ) : (
              <>
                <label style={{ marginBottom: "15px" }}>
                  Bureau Requests Tracking
                </label>
                <div className="form-box">
                  <Form.Item
                    label="Current Month Limit"
                    name="bureauCredits"
                    className="field-bg"
                  >
                    <InputNumber
                      className="custom-input-number"
                      placeholder="Enter bureau score limit"
                      readOnly
                    />
                  </Form.Item>
                  <label style={{ marginBottom: "15px" }}>
                    Bureau Requests Summary
                  </label>

                  <List
                    className="field-bg"
                    itemLayout="horizontal"
                    dataSource={bureauRequestsSummary}
                    renderItem={(item) => {
                      return (
                        <>
                          <List.Item>
                            <Text style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                              <Space>
                                {item.title}
                                {item.list}
                              </Space>
                            </Text>
                          </List.Item>
                        </>
                      );
                    }}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ViewEmployee;
