import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Row,
  Col,
  Form,
  Button,
  Table,
  Space,
  notification,
  AutoComplete,
  Select,
  Tooltip
} from "antd";
import { FaSearch, FaPlus, FaEye, FaPen } from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import "./index.scss";
import { hasUserRole } from "shared/services/Utility";
import CheckableTag from "antd/lib/tag/CheckableTag";
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { useDispatch } from "react-redux/es/exports";
import { setConnectorPageNo, setConnectorPageSize, setConnectorSearchParam, setConnectorStatus } from "shared/redux/connector-reducer";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import { setScreenHeader } from "shared/redux/common-reducer";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";

const { Title } = Typography;
type Props = {};

const Connectors: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const [allConnectors, setAllConnectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  //const [apiCount, setApiCount] = useState(0);
  //const [pages, setPages] = useState(1);
  //const [pageSize, setPageSize] = useState(10);
  //const [currentPage, setCurrentPage] = useState(1);
  //const [selectedCase, setSelectedCase] = useState("ALL");
  //const [salesUserId, setSalesUserId] = useState();
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  //const [checktagPermission, setChecktagPermission] = useState(false);
  //const [searchValue, setSearchValue] = useState(null as any);
  const {appColors}: any = useSelector((state: RootState) => state.common); 

  const connector: any = useSelector((state: RootState) => state.connector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Connector"}));
  }, []);

  useEffect(() => {
    /*if (userRolePermission["AssignConnector"] || userRolePermission["Admin"]) {
      setChecktagPermission(true);
    }*/
    // setPages(1); 
    // setCurrentPage(1);
    form.setFieldsValue({
      searchBox: connector.searchParam
    });
    getAllConnectors(connector.pageNo, connector.pageSize, connector.searchParam);
  }, [connector.status]);

  const [listCase, setListCase] = useState([
    {
      "key": "ALL",
      "label": "All",
      "total": "",
      "hasPermission": true
    },
    {
      "key": "PENDING_UNASSIGNED",
      "label": "Pending Approval Unassigned",
      "total": "",
      "hasPermission": ((userRolePermission["AssignConnector"] || userRolePermission["Admin"]))
    },
    {
      "key": "PENDING_ASSIGNED",
      "label": "Pending Approval Assigned",
      "total": "",
      "hasPermission": true
    },
    {
      "key": "APPROVED",
      "label": "Approved",
      "total": "",
      "hasPermission": true
    },
    {
      "key": "REJECTED",
      "label": "Rejected",
      "total": "",
      "hasPermission": true
    },
  ]);


  const searchAssignTo = (ev: any) => {
    if (ev.length!=0){
      const params = {
        name: ev,
      };
      API_SERVICE.getUsersWithRole(params).then(({ data }) => {
        if (data) {
          setAssignToData(data.payload);
        }
      });
  }
  };

  const selectAssignTo = (item, opt) => {
    setSelectedAssignTo(opt);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: ["userDetails", "fullName"],
      key: "fullName",
    },
    {
      title: "Profession",
      dataIndex: ["userDetails", "designation"],
      key: "profession",
    },
    {
      title: "Email",
      dataIndex: ["userDetails", "emailId"],
      key: "email",
    },
    {
      title: "Owner",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Mobile",
      dataIndex: ["userDetails", "phoneNumber"],
      key: "mobile",
    },
    {
      title: "Referral Connector",
      dataIndex: "referralName",
      key: "referralName",
    },
    {
      title: "Employer Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: 'center',
      render: (_: any, record: any) => (
        <Space className="action-button" size="small">
          <Button
            className="custome-btn-icon"
            style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEyeBorder />}
            onClick={() => goToLeadDetails1(record)}
          />
          {"Unassigned" === record.ownerName ?
            null
            :
            <Button
              className="custome-btn-icon-secondary"
              style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
              size="small"
              type="text"         
              icon={<IconEditBorder />}
              disabled={userRolePermission["ReadOnly"]}
              hidden={
                !(
                  userRolePermission["UpdateConnector"] || userRolePermission["Admin"]
                )
              }
              onClick={() => goToLeadDetails(record)}
            />
          }
        </Space>
      ),
    },
  ];

  const goToLeadDetails = (item: any) => {
    history.push(`/connectors/connector-edit/${item.userDetails.userId}`);
  };
  const goToLeadDetails1 = (item: any) => {
    history.push(`/connectors/${item.userDetails.userId}`);
  };

  const handlePaginationChange = (pagination) => {
    let page = (pagination?.pageSize != connector.pageSize) ? 1 : pagination.current;
    dispatch(setConnectorPageNo(page));
    dispatch(setConnectorPageSize(pagination.pageSize));
    getAllConnectors(page, pagination.pageSize, connector.searchParam);

  };

  const getAllConnectors = (pageNo: number = 1, pageSize: number = 10, searchParam: any = null) => {
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchParam, status: ("ALL" === connector.status ? null : connector.status) };
    setLoading(true);
    API_SERVICE.getConnectors(params)
      .then(({ data }: any) => {
        if ((data?.payload?.content?.length ?? 0) > 0) {
          data.payload.content.map(connector => {
            connector.key = connector.userDetails.id;
            // connector.userDetails.phoneNumber = connector.userDetails?.phoneNumber?.replace(/\D/g, '').slice(-10);
          })
          mapLeadsData(data);
        } else {
          //notification.error({ message: "No connector found." });
          mapLeadsData(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    listCase.map((item) => {
      if ("ALL" === item.key)
        item.total = data.payload.totalConnector
      else if ("PENDING_UNASSIGNED" === item.key)
        item.total = data.payload.totalPendingUnAssignedConnector
      else if ("PENDING_ASSIGNED" === item.key)
        item.total = data.payload.totalPendingAssignedConnector
      else if ("APPROVED" === item.key)
        item.total = data.payload.totalApprovedConnector
      else if ("REJECTED" === item.key)
        item.total = data.payload.totalRejectedConnector

    });
    setTotalCount(data.payload.totalElements);
    setAllConnectors(data.payload.content);
    //setApiCount(1);
  };

  const handleDelete = (event: any, item: any) => {
    item.userDetails.active = event;
    let data = {
      name: item.userDetails.firstName,
      profession: item.userDetails.designation,
      phoneNumber: item.userDetails.phoneNumber,
      userName: item.userDetails.emailId,
      email: item.userDetails.emailId,
      userId: item.userDetails.userId,
    };
    let employee = {
      ...item,
      ...data,
    };
    const id = employee.userId;
    const status = false;
    API_SERVICE.statusChange(id, status)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getAllConnectors(connector.pageNo, connector.pageSize, connector.searchParam);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const onChangeStatus = (event: any, item: any) => {
    item.userDetails.active = event;
    let data = {
      name: item.userDetails.firstName,
      profession: item.userDetails.designation,
      phoneNumber: item.userDetails.phoneNumber,
      userName: item.userDetails.emailId,
      email: item.userDetails.emailId,
      userId: item.userDetails.userId,
    };
    let employee = {
      ...item,
      ...data,
    };
    const id = employee.userId;
    const status = event;
    API_SERVICE.statusChange(id, status)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          getAllConnectors(connector.pageNo, connector.pageSize, connector.searchParam);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleFilterModal = () => { };

  const addConnectorHandler = () => {
    history.push("/connectors/add-connector");
  };

  const handleSearch = (event: any) => {
    //setSearchValue(event.target.value);
    dispatch(setConnectorPageNo(1));
    dispatch(setConnectorSearchParam(event.target.value));
    // const params = {
    //   name: event.target.value,
    // };
    getAllConnectors(1, connector.pageSize, event.target.value);
  };

  const handleConnectorTagChange = (i: number, value: string) => {
    dispatch(setConnectorPageNo(1));
    //setCurrentPage(1);
    //setSelectedCase(value);
    dispatch(setConnectorStatus(value));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [form] = Form.useForm();

  const assignSalesUserToConnector = () => {
    if ((undefined === selectedRowKeys || selectedRowKeys.length === 0)) {
      notification.error({ message: "Please select a connector to approve." });
      return;
    }

    if ((undefined === selectedAssignTo || null === selectedAssignTo || undefined === selectedAssignTo?.userId || null === selectedAssignTo.userId)) {
      notification.error({ message: "Please assignee to assign a connector." });
      return;
    }

    if ((selectedRowKeys && selectedRowKeys.length > 0) && (selectedAssignTo && selectedAssignTo.userId)) {
      API_SERVICE.assignSalesUserToConnector(selectedAssignTo.userId, selectedRowKeys)
        .then(({ data }) => {
          if (data) {
            let tmp = [""];
            tmp.push("salesUserId");
            setSelectedRowKeys([]);
            notification.success({ message: data.message });
            form.resetFields(tmp);
            getAllConnectors(connector.pageNo, connector.pageSize, connector.searchParam);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  }

  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""}; 

  return (
    <>
      <div className="content-box">
        <Form form={form}>
          <Row className="connector-filter-row">
            <Col className="filter-bar" span={18}>
              <Form.Item name="searchBox">
                <Input
                  size="large"
                  className="searchBox"
                  onChange={debounce(handleSearch, 1000)}
                  placeholder="Search"
                  allowClear
                  prefix={<FaSearch />}
                />
              </Form.Item>
            </Col>
            <Col span={6} className="filter-bar filter-bar-right">
              <Tooltip title="Refresh">
                <Button
                  type="primary"                 
                  className="refresh-button"
                  onClick={() => {
                    form.setFieldsValue({
                      searchBox: ""
                    })
                    //setSearchValue("");
                    dispatch(setConnectorStatus("ALL"))
                    dispatch(setConnectorSearchParam(""));
                    dispatch(setConnectorPageNo(1));
                    getAllConnectors(1, connector.pageSize);
                  }}
                  shape="round"
                 // icon={<ReloadOutlined />}blackRefreshIcon
                  size={"middle"}
                > <img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}} />
                </Button>
              </Tooltip>&nbsp;&nbsp;
              <Button
                type="primary"
                className="add-button dynamic-btn-primary"
                style={btnPrimaryStyles}
                onClick={addConnectorHandler}
                shape="round"
                icon={<FaPlus />}
                size={"middle"}
                disabled={!(userRolePermission["Admin"] || userRolePermission["CreateOperations"] || userRolePermission["CreateLead"])}
                hidden={
                  !(
                    userRolePermission["CreateLead"] ||
                    userRolePermission["Admin"]
                  )
                }
              >
                Add Connector
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col className="btn-box">
            {
              listCase.map((item: any, i: number) => (
                <>
                  {item.hasPermission ?
                    <CheckableTag className="custom-checkable"  style={customStyles} key={i} checked={item.key === connector.status} onChange={(checked) => handleConnectorTagChange(i, item.key)}>
                      {item.label} ({item.total})
                    </CheckableTag>
                    :
                    null
                  }
                </>
              ))
            }
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className={("PENDING_UNASSIGNED" === connector.status ? "" : "hide-selection") + " dynamic-pagination-checkbox dynamic-pagination-checkbox" }     
              style={customStyles}
              loading={loading}
              columns={(columns.filter(col => (col.key !== (("PENDING_UNASSIGNED" !== connector.status && "PENDING_ASSIGNED" !== connector.status) ? 'referralName' : ''))))}
              dataSource={allConnectors}
              rowSelection={rowSelection}
              scroll={{ x: 'max-content' }}
              size="small"
              onChange={handlePaginationChange}
              pagination={{
                current: connector.pageNo,
                pageSize: connector.pageSize,
                total: totalCount, // total count returned from backend
                //current: currentPage,
              }}
              locale={{ emptyText: "No connector available." }}
            />
          </Col>
        </Row>
        {listCase[1].key === connector.status ?
          <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
            <Col span={8}>
              <Form form={form}>
                <Form.Item label="Assign To:" name="salesUserId">
                  {/* <input /> */}
                  <AutoComplete
                    className="custom-auto-compleat"
                    onSearch={debounce(searchAssignTo, 1000)}
                    placeholder="input here"
                    dropdownClassName="custom-option-list"
                    onSelect={selectAssignTo}
                  >
                    {assignToData.map((item: any, i: any) => (
                      <Select.Option
                        key={item.userId}
                        {...item}
                        value={item.fullName}
                      >
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span><br />
                            <span className="city">{item?.address?.city && item?.address?.city}</span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">{item?.phoneNumber && item?.phoneNumber}</span>
                          </Col>
                        </Row>
                      </Select.Option>
                    ))}
                  </AutoComplete>&nbsp;&nbsp;&nbsp;
                </Form.Item>
              </Form>
            </Col>
            <Col span={3}>
              <Form.Item>
                <Button type="primary" htmlType="submit" onClick={assignSalesUserToConnector} className="dynamic-btn-primary" style={btnPrimaryStyles}>Assign</Button>
              </Form.Item>

            </Col>
          </Row>
          :
          null
        }
      </div>
    </>
  );
};

export default Connectors;
