import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import {
  Typography,
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Modal,
  Select,
  Form,
  notification,
  Switch,
  Popconfirm,
  Tag,
} from "antd";
import { FaSearch, FaFilter, FaPlus, FaEye, FaPen } from "react-icons/fa";
import type { ColumnsType } from 'antd/es/table';
import { AiFillDelete } from "react-icons/ai";
import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import { hasUserRole } from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as IconEyeBorder} from "../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setLendersPageNo, setLendersPageSize, setLendersSearchParam } from "shared/redux/lenders-reducer";
import { setScreenHeader } from "shared/redux/common-reducer";

const { Option } = Select;
const { Title, Text } = Typography;
type Props = {};

const Lenders: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const userRolePermission = hasUserRole();
  //const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allLenders, setAllLenders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLender, setAddLender] = useState(false);
  const [editLender, setEditLender] = useState(null as any);
  const [viewLender, setViewLender] = useState(null as any);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  //const [pages, setPages] = useState();
  //const [hubList, sethubList] = useState();
  // const [pageSize, setPageSize] = useState();
  const [form] = Form.useForm();
  //const [currentPage, setCurrentPage] = useState(1);
  //const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  //const [searchValue, setSearchValue] = useState(null as any);
  const lenders: any = useSelector((state: RootState) => state.lenders);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const columns: ColumnsType<any> = [
    {
      title: "Bank Name",
      dataIndex: "branchName",
      key: "branchName",
      width: 350,
      ellipsis: true,
    },
    {
      title: "Hub",
      dataIndex: "hubName",
      key: "hubName",
      width: 350,
      ellipsis: true,
    },
    {
      title: "Product",
      dataIndex: "products",
      key: "products",
      // width: 1110,
      ellipsis: true,
      render: (products: string[]) => (
        <span>
          {products?.map(product => {
           // let color = product.length > 5 ? 'geekblue' : 'green';
            return (
              <Tag  key={product}>
                {product?.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      )
    },
    {
      title: "Status",
      key: "status",
      render: (_: any, record: any) => (
        <Switch  
          style={customStyles}
          checked={record.active}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (_: any, record: any) => (
        <Space className="action-button" size="small">
          <Button
            className="custome-btn-icon"
            style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
            size="small"
            type="text"
            onClick={() => setViewLender(record)}
            icon={<IconEyeBorder />}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            onClick={() => {
              let initialFormData = {
                lenderInfoId: record.lenderInfoId,
                bankName: record.branchName,
                hub: record.hubName,
                products: record.products,
                active: record.active || false,
                dsaBusinessId: record.dsaBusinessId,
                fiInfoId: record.fiInfoId,
                pinCode: record.pinCode
              }
              handleEditLender(record);
              // setEditLender(initialFormData)
            }}
            icon={<IconEditBorder />}
            hidden={
              !(
                userRolePermission["UpdateOperations"] ||
                userRolePermission["Admin"]
              )
            }
          />

          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => deleteConfirm(record)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
          >
            <Button
              style={{backgroundColor:"transparent", border:'none'}}
              size="small"
              type="text"
              hidden={
                !(
                  userRolePermission["UpdateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
              icon={<IconDeleteBorder width={24} height={24}/>}
            />
          </Popconfirm>

        </Space>
      ),
    },
  ];

  const handleEditLender = (record) => {
    history.push({pathname:"lender/edit",state:{lenderData: record}});
  };

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Manage Lender"}));
    form.setFieldsValue({
      searchBox: lenders.searchParam
    });
    getAllLenders(lenders.pageNo, lenders.pageSize, lenders.searchParam);
  }, []);

  // const handleSearch = (event: any) => {
  //   const params = {
  //     name: event.target.value,
  //   };
  //   getAllLenders(1, event.target.value);

  // onChange={debounce(handleSearch, 1000)}
  // };

  const onChangeStatus = (event: any, item: any) => {
    item.active = event;
    API_SERVICE.LenderstatusChange(item.lenderMappingId, item.active)
      .then(({ data }) => {
        if (data) {
          getAllLenders(lenders.pageNo, lenders.pageSize, lenders.searchParam);
          if (data.message) {
            notification.success({ message: data.message });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  };

  const deleteConfirm = (lender) => {
    API_SERVICE.deleteLender(lender.lenderMappingId)
      .then(({ data }) => {
        if (data) {
          getAllLenders(lenders.pageNo, lenders.pageSize);
          if (data.message) {
            notification.success({ message: data.message });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  }

  const getAllLenders = (pageNo: number = 1, pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, name: searchVal };
    API_SERVICE.getLenders(params)
      .then(({ data }) => {
        let lenders = data.payload.content.map((lender) => ({
          lenderInfoId: lender.lenderInfoId,
          dsaBusinessId: lender.dsaBusinessId,
          fiInfoId: lender.fiInfoId,
          branchName: lender.branchName,
          hubName: lender.hubName,
          products: lender.products,
          role: lender.role,
          active: lender.active,
          pinCode: lender.pinCode
        }));
        setAllLenders(lenders);
        mapLeadsData(data);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data.payload.totalElements);
    setAllLenders(data.payload.content);
    setApiCount(1);
  };

  const handlePaginationChange = (pagination) => {
    //setCurrentPage(pagination.current);
    dispatch(setLendersPageNo(pagination.current));
    //setPageSize(pagination.pageSize);
    dispatch(setLendersPageSize(pagination.pageSize));
    setTotal(pagination.total);
    getAllLenders(pagination.current, pagination.pageSize, lenders.searchParam);

  };

  /*const handleFilterModal = () => {
    setIsFilterVisible(true);
  };*/

  const addHandler = () => {
    // setAddLender(true);
    history.push("/lender/add");
  };

  const addLenderHandler = (values: any) => {
    const payload = {
      active: values.active,
      finInfoId: values.selectedBank.id,
      hub: values.hub,
      name: values.bankName,
      products: values.products,
      pinCode: values.pinCode
    };
    API_SERVICE.addLender(payload)
      .then(({ data }) => {
        if (data) {
          setAddLender(false);
          dispatch(setLendersPageNo(1));
          getAllLenders(1, lenders.pageSize, lenders.searchParam);
          if (data.message) {
            notification.success({ message: data.message });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  }

  const updateLenderHandler = (values: any) => {
    const payload = {
      active: values.active,
      hub: values.hub,
      name: values.bankName,
      finInfoId: values.selectedBank.id,
      lenderInfoId: editLender.lenderInfoId,
      dsaBusinessId: editLender.dsaBusinessId,
      products: values.products,
      pinCode: values.pinCode
    }
    API_SERVICE.updateLender(payload, editLender.lenderInfoId)
      .then(({ data }) => {
        if (data) {
          setEditLender(false)
          getAllLenders(lenders.pageNo, lenders.pageSize, lenders.searchParam);
          if (data.message) {
            notification.success({ message: data.message });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  }

  const lenderSearchHandler = (event: any) => {
    //setCurrentPage(1);
    //setSearchValue(event.target.value);
    dispatch(setLendersPageNo(1));
    dispatch(setLendersSearchParam(event.target.value));
    getAllLenders(1, lenders.pageSize, event.target.value);
  }

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      {
        addLender &&
        <Modal
          title="Add New Lender"
          centered
          width={500}
          onCancel={() => setAddLender(false)}
          footer={null}
          visible={addLender}>
          <AddLenderForm addLenderHandler={addLenderHandler} />
        </Modal>
      }
      {/* {
        editLender &&
        <Modal
          title="Edit Lender"
          centered
          width={500}
          onCancel={() => setEditLender(null)}
          footer={null}
          visible={editLender}>
          <AddLenderForm formData={editLender} addLenderHandler={addLenderHandler} updateLenderHandler={updateLenderHandler} />
        </Modal>
      } */}
      {
        viewLender &&
        <Modal
          title="View Lender"
          centered
          width={500}
          onCancel={() => setViewLender(null)}
          footer={null}
          visible={viewLender}>
          <ViewLender lender={viewLender} />
        </Modal>
      }
      <Form form={form}>
      <Row>
        <Col className="filter-bar" span={16}>
        <Form.Item name="searchBox">
          <Input
            size="large"
            className="search-box"
            placeholder="Search"
            onChange={debounce(lenderSearchHandler, 600)}
            allowClear
            prefix={<FaSearch />}
          />
          </Form.Item>
        </Col>
        <Col span={8} className="filter-bar  filter-bar-right">
          <Button
            style={{ marginLeft: "80px" }}
            type="primary"
            className="refresh-button"
            onClick={() => {
              form.setFieldsValue({
                searchBox: ""
              })
              //setSearchValue("");
              dispatch(setLendersPageNo(1));
              dispatch(setLendersSearchParam(""));
              getAllLenders(1, lenders.pageSize);
            }}
            shape="round"
            //icon={<ReloadOutlined />}
            size={"middle"}
          ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
          <Button
            type="primary"
            className="add-button dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={addHandler}
            shape="round"
            icon={<FaPlus />}
            size={"middle"}
            disabled={!(userRolePermission["Admin"] ||  userRolePermission["CreateOperations"])}
            hidden={
              !(
                userRolePermission["CreateOperations"] ||
                userRolePermission["Admin"]
              )
            }
          >
            Add Lender
          </Button>
        </Col>
      </Row>
      </Form>
      <Row></Row>
      <Row>
        <Col span={24}>
          <Table
            className="dynamic-pagination-checkbox"
            style={customStyles}
            loading={loading}
            columns={columns}
            dataSource={allLenders}
            scroll={{ x: 'max-content' }}
            size="small"
            onChange={handlePaginationChange}
            pagination={{
              current: lenders.pageNo,
              pageSize: lenders.pageSize,
              total: totalCount, // total count returned from backend
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Lenders;



const AddLenderForm: React.FunctionComponent<any> = ({ formData, addLenderHandler, updateLenderHandler }) => {

  const [fiList, setFiList] = useState([] as any);
  const [products, setProducts] = useState([] as any);
  const [hubList, setHubList] = useState([] as any);
  const [selectedHub, setSelectedHub] = useState(null as any);
  const [addLenderForm] = Form.useForm() as any;

  useEffect(() => {
    getFiList();
    getLoanProducts();
  }, []);

  const getFiList = () => {
    API_SERVICE.getFiList({ pageSize: 500 }).then(({ data }) => {
      if (data?.payload.content) {
        setFiList(data.payload.content);
        if (formData) {
          let selectedHubList = data.payload.content.find(item => item.name == formData.bankName);
          setHubList(selectedHubList?.hubDetails)
        }
      }
    });
  };

  const addLender = (values: any) => {
    const selectedbank = fiList.find(
      (item: any) => item.name === values.bankName
    );
    values.selectedBank = selectedbank;
    addLenderHandler(values);
  };

  const getLoanProducts = () => {
    let userDetails = localStorage.getItem("user") as any;
    if (userDetails) {
      userDetails = JSON.parse(userDetails);
    }
    API_SERVICE.getLoanProductList(userDetails.businessId).then(({ data }) => {
      if (data) {
        let product = data.payload.filter((item) => item.active);
        setProducts(product);
      }
    });
  };

  const updateLender = (values: any) => {
    const selectedBank = fiList.find((item: any) => item.name === values.bankName)
    values.selectedBank = selectedBank;
    updateLenderHandler(values)
  }

  const changeBankHandler = (value: any) => {
    let fiItem = fiList.find(item => item.name == value);
    setHubList(fiItem.hubDetails)
  }

  const changeHubHandler = (value: any) => {
    let selectedHub = hubList.find(item => item.hubName == value);
    addLenderForm.setFieldsValue({
      pinCode: selectedHub?.pinCode
    })
    setSelectedHub(selectedHub)
  }

  return (
    <>
      <Form
        name="basic"
        size="large"
        layout="vertical"
        form={addLenderForm}
        autoComplete="off"
        onFinish={formData ? updateLender : addLender}
        initialValues={{ ...formData }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Bank Name"
              rules={[
                { required: true, message: "Please enter bank name" },
              ]}
              name="bankName"
            >
              <Select
                showSearch
                className="custom-select"
                placeholder="Bank Name"
                optionFilterProp="children"
                onChange={(value, option) => changeBankHandler(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {fiList.map((item: any, i: number) => (
                  <Option key={i} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Hub"
              rules={[{ required: true, message: "Please enter hub" }]}
              name="hub"
            >
              {/* <Input className="custom-input" placeholder="Please enter hub" /> */}

              <Select
                showSearch
                className="custom-select"
                placeholder="Bank Name"
                optionFilterProp="children"
                onChange={(value, option) => changeHubHandler(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {hubList.map((item: any, i: number) => (
                  <Option key={i} value={item.hubName}>
                    {item.hubName}
                  </Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item
              label="Product"
              rules={[{ required: true, message: "Please select product" }]}
              name="products"
            >
              {/* <Input className="custom-input" placeholder="Please enter product" /> */}

              <Select
                showSearch
                mode="tags"
                className="custom-select-multiple"
                placeholder="Product"
                maxTagCount='responsive'
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {products?.map((item: any, i: number) => (
                  <Option key={i} value={item.loanProduct}>
                    {item.loanProduct}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Pincode"
              rules={[
                { required: true, message: "Please enter pincode" },
                {
                  pattern: /^[1-9][0-9]{5}$/,
                  message: "Please enter valid pincode",
                },
              ]}
              name="pinCode"
            >
              <Input
                readOnly
                className="custom-input"
                placeholder="Please enter pincode"
              />
            </Form.Item>

            <Form.Item
              label="Status"
              name="active"
              valuePropName="checked"
              initialValue
              rules={[{ required: true, message: "Please set status" }]}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col className="add-lender-btn" span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {formData ? 'Update' : 'Add'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}


const ViewLender: React.FunctionComponent<any> = ({ lender }) => {
  return (
    <div style={{ padding: '20px 0px' }}>
      <Row>
        <Col span={12}>
          <Title style={{ marginBottom: '0px' }} level={5}>Bank Name</Title>
          <Text>{lender.branchName}</Text>
        </Col>
        <Col span={12}>
          <Title style={{ marginBottom: '0px' }} level={5}>Hub</Title>
          <Text>{lender.hubName}</Text>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={12}>
          <Title style={{ marginBottom: '0px' }} level={5}>Products</Title>
          <Text>{lender.products?.join(', ')}</Text>
        </Col>
        <Col span={12}>
          <Title style={{ marginBottom: '0px' }} level={5}>Status</Title>
          <Text>{lender.active ? 'Active' : 'Inactive'}</Text>
        </Col>
      </Row>
    </div>
  )
}
