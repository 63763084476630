import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  Input,
  Select,
} from "antd";
import bankList from "../../../Lead/RequiredDocuments/banklist.json";
import bankListForCreditSaison from "../../../Lead/RequiredDocuments/banklistForCreditSaison.json";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IBankStatementV2 {
  doc?: any;
  index?: any;
  bankList?: any;
}

const BankStatementV2: React.FunctionComponent<IBankStatementV2> = (
  props: IBankStatementV2
) => {
  const { doc, index } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [backPassword, setBankPassword] = useState("");
  const [bankId, setBankId] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const {appColors}: any = useSelector((state: RootState) => state.common);
  console.log("::Doc", doc);

  const setAccountNumber1 = (val) => {
    const regex = /^[0-9]*$/;
    if (regex.test(val)) {
      setAccountNumber(val);
    } else {
    }
  };

  useEffect(() => {
    if (doc?.documentDetails) {
      updateData(doc?.documentDetails?.documentAdditionalInfoDto);
    }
  }, [doc]);
  const updateData = (req) => {
    setBankName(req?.bankId);
    //setSelectValue(req?.selectValue);
    setAccountNumber(req?.accountNumber);
    if (req?.filePassword) {
      const ddlValue = "YES";
      setFilePassword(ddlValue);
    } else {
      if (req?.bankId) {
        const ddlValue = "NO";
        setFilePassword(ddlValue);
      }
    }
    if (req?.bankId) {
      setBankPassword(req?.filePassword);
    } else {
      setBankPassword('');
    }
    if (!req?.bankId) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  };

  const setFilePassword = (val) => {
    if (val === "NO") {
      setBankPassword("");
    }
    setSelectValue(val);
  };
  const getDisableBtn = () => {
    let isDisable: any = false;
    isDisable = !bankName && !accountNumber && !backPassword;
    return isDisable;
  };
  const onClickOnSave = () => {
    const payload = {
      documentAdditionalInfo: {
        documentApplicantType: doc?.documentDetails?.documentApplicantType,
        docOrder: doc?.documentDetails?.docOrder,
        fileSize: doc?.documentDetails?.fileSize,
        documentTypeId: doc?.documentTypeId,
        bankId: bankName,
        filePassword: selectValue === "NO" ? null : backPassword,
      },
    };
    const businessID = doc?.documentDetails?.businessDocumentId;
    API_SERVICE.updateDocumentBankDetail(businessID, payload)
      .then(({ data }) => {
        if (data?.payload) {
          doc.documentDetails = data?.payload;
          updateData(data?.payload?.documentAdditionalInfoDto);
        }
      })
      .catch((e) => e);
  };
  const getBankNameFromList = (bankName) => {
    let fullBankName = "";

    props.bankList &&
      props.bankList.filter((items) => {
        if (items.value === bankName) {
          fullBankName = items.label;
        }
      });
    return fullBankName;
  };

  return (
    <>
      {isEditable ? (
        <>
          <Row
            key={`${doc?.documentType}_${index + 1}`}
            className="other-detail"
            style={{
              background: "#DFE0E680",
            }}
          >
            <Col span={1}></Col>
            <Col
              span={8}
              style={{
                textOverflow: "ellipsis",
                flexWrap: "nowrap",
                overflow: "hidden",
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Tooltip title="Bank Name">
                <Select
                  placeholder="Search Or Select Bank Name"
                  className="custom-select"
                  options={props.bankList}
                  showSearch
                  disabled={isEditable}
                  value={bankName}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    console.log("::e", e);
                    setBankName(e);
                  }}
                />
              </Tooltip>
            </Col>
            <Col
              span={6}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Tooltip title="Is file password protected?">
                <Select
                  className="custom-select"
                  placeholder="Is file password protected?"
                  showSearch
                  value={selectValue}
                  disabled={isEditable}
                  onChange={(e) => {
                    console.log("abc", e);
                    setFilePassword(e);
                  }}
                  options={[
                    {
                      value: "YES",
                      label: "YES",
                    },
                    {
                      value: "NO",
                      label: "NO",
                    },
                  ]}
                />
              </Tooltip>
            </Col>
            <Col
              span={5}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              {selectValue === "YES" ||
              selectValue === "Is file password protected?" ? (
                <Tooltip title="File Password">
                  <Input
                    className="custom-input"
                    max={100}
                    min={0}
                    value={backPassword}
                    disabled={isEditable}
                    type="password"
                    onChange={(e) => setBankPassword(e?.target?.value)}
                    placeholder="Password"
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Col>
            <Col
              span={2}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Button
                size="large"
                className="button-submit save-btn dynamic-btn-primary"
                style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
                type="primary"
                htmlType="submit"
                onClick={() => setIsEditable(false)}
              >
                Edit
              </Button>
            </Col>
            <Col span={1}></Col>
          </Row>
        </>
      ) : (
        <>
          <Row
            key={`${doc?.documentType}_${index + 1}`}
            className="other-detail"
            style={{
              background: "#DFE0E680",
            }}
          >
            <Col span={1}></Col>
            <Col
              span={8}
              style={{
                textOverflow: "ellipsis",
                flexWrap: "nowrap",
                overflow: "hidden",
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Tooltip title="Bank Name">
                <Select
                  placeholder="Search Or Select Bank Name"
                  className="custom-select"
                  options={props.bankList}
                  showSearch
                  value={bankName}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    console.log("::e", e);
                    setBankName(e);
                  }}
                />
              </Tooltip>
            </Col>
            <Col
              span={6}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Tooltip title="Is file password protected?">
                <Select
                  className="custom-select"
                  placeholder="Is file password protected?"
                  showSearch
                  value={selectValue}
                  onChange={(e) => {
                    console.log("abc", e);
                    setFilePassword(e);
                  }}
                  options={[
                    {
                      value: "YES",
                      label: "YES",
                    },
                    {
                      value: "NO",
                      label: "NO",
                    },
                  ]}
                />
              </Tooltip>
            </Col>
            <Col
              span={5}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              {selectValue !== 'NO' ? (
                <Tooltip title="File Password">
                  <Input
                    className="custom-input"
                    max={100}
                    min={0}
                    value={backPassword}
                    onChange={(e) => setBankPassword(e?.target?.value)}
                    placeholder="Password"
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Col>
            <Col
              span={2}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Button
                size="large"
                className="button-submit save-btn dynamic-btn-primary"
                style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
                type="primary"
                htmlType="submit"
                disabled={getDisableBtn()}
                onClick={onClickOnSave}
              >
                Save
              </Button>
            </Col>
            <Col span={1}></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BankStatementV2;
