import { Button, notification, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { DownOutlined, UpOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { downloadDocument, getEnumList } from "shared/services/Utility";
import passIcon from "../../../assets/images/pass.svg";
import failIcon from "../../../assets/images/fell.svg";
import React from "react";
import "./decisioningAudit.scss"
import { ColumnsType } from "antd/es/table";
import AppLoading from "../AppLoading/AppLoading";

const { Text } = Typography;

interface IPublish {
  currentPublishedId: any;
  decisionData: any;
  outputData: any;
}

const Publish: React.FunctionComponent<IPublish> = (props: IPublish) => {
  const { currentPublishedId, decisionData, outputData } = props;
  const [tmpColumns, setTmpColumns] = useState<any>();
  const [publishData, setPublishData] = useState([] as any);
  const [PublishDetails, setPublishDetails] = useState("" as any);
  const [isLoading, setIsLoading] = useState(false);
  const [eligibleLendersArr, setEligibleLendersArr] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const configArr: any = [
    {
      key: "TRUE",
      value: "True",
    },
    {
      key: "FALSE",
      value: "False",
    },
  ];

  useEffect(() => {
    getPublishList();
  }, []);

  const getPublishList = () => {
    setIsLoading(true);
    if (currentPublishedId == null) {
      return;
    }
    API_SERVICE.getScorecardPublisList(currentPublishedId)
      .then(({ data }) => {
        setPublishDetails(data?.payload);
        listAllTheFinanciers(data?.payload?.publishedPayload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const listAllTheFinanciers = (publishData: any) => {
    API_SERVICE.listAllTheFinanciers()
      .then(({ data }) => {
        const displayNameList = data?.payload?.map((listVal: any) => {
          return {
            key: listVal?.businessInfoId,
            value: listVal?.businessName,
          };
        });
        setEligibleLendersArr(displayNameList);
        columnsAndDataDisplay(publishData, displayNameList);
      })
      .catch((e: any) => API_SERVICE.handleErrors(e))
      .finally(() => setIsLoading(false));
  };

  const columnsAndDataDisplay = (
    data: any,
    eligibleLendersArrTmp: any = null
  ) => {
    const eligibleLenders =
      eligibleLendersArrTmp == null
        ? eligibleLendersArr
        : eligibleLendersArrTmp;
    const columns: ColumnsType<any> = [
      {
        title: "Rule Name",
        key: "ruleName",
        dataIndex: ["ruleName"],
        width: 200,
        fixed: "left",
        render: (value: any) => {
          let tmpValue = value;
          if (decisionData?.decisioningMasterType == "OFFER_DECISION") {
            const rules = value?.split("-");
            const eligibleName: any = eligibleLenders?.find(
              (item: any) => item?.key == rules?.at(0)
            );
            const purpose = getEnumList("DECISIONING_PURPOSE")?.find(
              (item: any) => item?.key == rules?.at(-1)
            );
            tmpValue =
              (eligibleName?.value ?? "") + " - " + (purpose?.value ?? "");
          }
          return <Text>{tmpValue}</Text>;
        },
      },
    ];

    data?.conditionMetadata?.map((item: any) => {
      columns.push({
        title: <div>Condition</div>,
        children: [
          {
            title: item?.conditionName,
            children: [
              {
                title: item?.expression,
                key: item?.conditionId,
                width: 500,
                ellipsis: true,
                dataIndex: `conditionId_${item?.conditionId}`,
                render: (value: any, record: any, index: number) => {
                  let tableIndex = tableData?.findIndex(
                    (item: any) => item?.ruleName == record?.ruleName
                  );
                  const isPass = outputData.conditionEvaluationResults?.some(
                    (res: any) =>
                      res?.ruleMetaDataId == tableIndex &&
                      res?.passedConditionResultData?.some(
                        (passed: any) =>
                          passed?.conditionMetadataId == item?.conditionId
                      )
                  );
                  return (
                    <div className="content-sb">
                      <Text>{value}</Text>
                      <img
                        src={
                          isPass || (value ?? "") == "" ? passIcon : failIcon
                        }
                        alt=""
                      />
                    </div>
                  );
                },
              },
            ],
          },
        ],
      });
    });

    data?.actionMetadata?.map((item: any) => {
      const displayNameListData = item?.categoryInfoList?.map((item: any) => {
        return {
          key: item.id,
          value: `${item.categoryName},${item?.weightage}%`,
        };
      });
      columns.push({
        title: <div>Action</div>,
        children: [
          {
            title: item?.actionName,
            children: [
              {
                title: item?.description?.replaceAll(",", ", "),
                key: item?.actionId,
                dataIndex: `actionId_${item?.actionId}`,
                render: (value: any, record: any) => {
                  if (item?.expression == "SET_CONDITIONAL_CONFIG_VALUE") {
                    let configName = configArr?.find(
                      (item: any) => item?.key == value
                    );
                    return <Text>{configName?.value}</Text>;
                  } else if (item?.expression == "ELIGIBLE_LENDERS") {
                    let eligibleName: any = eligibleLenders?.find(
                      (item: any) => item?.key == value
                    );
                    return <Text>{eligibleName?.value}</Text>;
                  } else {
                    let categoryName = displayNameListData?.find(
                      (item: any) => item?.key == value
                    );

                    const cat = categoryName?.value
                      ? `${categoryName?.value},${record?.scoreOrReject}`
                      : "";
                    return (
                      <Text>
                        {item?.categoryInfoList?.length > 0 ? cat : value}
                      </Text>
                    );
                  }
                },
              },
            ],
          },
        ],
      });
    });

    const tableData: any = [];
    data?.ruleMetadata?.map((item: any) => {
      let getRule = { ruleName: item?.ruleName };
      item?.decisioningConditions?.map((item: any) => {
        const getValue = JSON.parse(
          `{"conditionId_${item?.conditionId}": "${item?.conditionParamterValues}"}`
        );
        getRule = { ...getRule, ...getValue };
      });

      item.decisioningActions?.map((item: any) => {
        let getValue: any;
        if (item?.categoryId?.length > 0) {
          getValue = JSON.parse(
            `{"actionId_${item?.actionId}": "${item?.categoryId}","scoreOrReject": "${item?.scoreOrReject}"}`
          );
        } else {
          getValue = JSON.parse(
            `{"actionId_${item?.actionId}": "${item?.actionParamterValues}"}`
          );
        }
        getRule = { ...getRule, ...getValue };
      });

      tableData.push(getRule);
    });

    const totalScore =
      outputData?.scorecardOutputResponse?.summingScore?.totalScore;
    const testedStatus =
      outputData?.scorecardOutputResponse?.summingScore?.status;

    columns.push({
      title: (
        <>
          {totalScore > 0 ? (
            <div style={{ textAlign: "center", display: "grid" }}>
              Total Score <br />
              <span style={{ fontSize: "18px" }}>{totalScore}</span>
              <span>Status: {testedStatus}</span>
            </div>
          ) : (
            ""
          )}
        </>
      ),
      key: "ac",
      dataIndex: "ac",
      width: 130,
      fixed: "right",
      render: (_: any, record: any, index: number) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={
                outputData?.ruleNames?.includes(record?.ruleName?.trim())
                  ? passIcon
                  : failIcon
              }
              alt=""
            />
          </div>
        );
      },
    });

    setPublishData(tableData);
    setTmpColumns(columns);
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    setPageNo(current);
    setPageSize(pageSize);
  };

  return (
    <>
      <div style={{ padding: "5px 0 16px 12px" }}>
          Publish Version: {PublishDetails?.publishVersion}
      </div>
      <Table
        columns={tmpColumns}
        className="dynamic-pagination-checkbox publish-table-column-width"
        style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
        size="small"
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          // position: ["bottomCenter"],
          current: pageNo,
          pageSize: pageSize,
          total: publishData?.length,
        }}
        scroll={{ x: "max-content" }}
        dataSource={publishData}
      />
      {isLoading && <AppLoading/>}
    </>
  );
};

export default Publish;
