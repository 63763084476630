function getUser() {
    let user = JSON.parse(localStorage.getItem('user') as string);
    return user;
}
function getUserDetails() {
    return getUser().userDetails;
}
function getUserId() {
    let userDetails = getUserDetails();
    return userDetails.userId;
}

function getUserFullName() {
    let userDetails = getUserDetails();
    return userDetails.fullName;
}

function getBusinessId() {
    let userDetails = getUserDetails();
    return userDetails.businessId;
}



const SESSION_SERVICE = {
    getUser,
    getUserDetails,
    getUserId,
    getUserFullName
}
export default SESSION_SERVICE;