import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Popconfirm, Row, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";
import { BsCardImage } from "react-icons/bs";
import { FaEye, FaPen, FaPlus, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { hasUserRole } from "shared/services/Utility";
import "./greeting-cards-index.scss";
import { Image } from 'antd';
import 'antd/dist/antd.css';
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
type Props = {};

const GreetingCards: React.FunctionComponent<Props> = () => {

    const userRolePermission = hasUserRole();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [allGreetings, setAllGreetings] = useState<"" | any>([]);
    const [visible, setVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchValue, setSearchValue] = useState(null as any);
    const [totalCount, setTotalCount] = useState(0);
    const {appColors}: any = useSelector((state: RootState) => state.common);

    const addGreetingHandler = () => {
        history.push("/greeting-cards/AddGreetingCard");
    };

    const goToViewGreetingCardDetails = (record: any) => {
        history.push(`/greeting-cards/view-greeting-card-details/${record.busCommId}`);
    }

    const goToEditGreetingcard = (record: any) => {
        history.push(`/greeting-cards/edit-greeting-card/${record.busCommId}`);
    }

    useEffect(() => {
        setCurrentPage(1)
        getGreetingList(1, pageSize);
    }, []);

    useEffect(() => {

    },[currentPage])

    const getGreetingList = (pageNo: number = 1, pageSize: number = 10, searchVal: any = null) => {
        setLoading(true);
        const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
        API_SERVICE.getGreetings(params)
            .then(({ data }: any) => {
                if (data.payload.content.length > 0) {
                    setAllGreetings(data?.payload?.content)
                    setTotalCount(data?.payload?.totalElements);
                } else {
                    setAllGreetings([]);
                    setTotalCount(0);
                    notification.error({ message: "No Greetings Found." });
                }
            })
            .catch((error) => { })
            .finally(() => {
                setLoading(false);
                setFlag(true);
            });
        if (allGreetings.length !== 0) {
        }
    };

    const columns: any = [
        {
            title: "Greeting",
            dataIndex: "greeting",
            key: "greeting",
            render: (_: any, record: any) => {
                return <Space className="action-button" size="middle">
                    <Image
                        preview={false}
                        src={
                            "data:image/" +
                            record?.documentDetails[0]?.fileExtn +
                            ";base64," +
                            record?.documentDetails[0]?.documentDetails
                        }
                        width="100px"
                        height="40px"
                        alt="Greetings"
                    />
                </Space>
            }
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Created",
            dataIndex: "creationDate",
            key: "date",
            render: (creationDate: any, record: any) => (
                <span>
                    {moment(new Date(creationDate))
                        .locale("en")
                        .format("DD/MM/YYYY, hh:mm A")}
                </span>
            ),
        },
        {
            title: "Status",
            dataIndex: "active",
            key: "active",
            render: (_: any, record: any) => (
                <Space className="action-button" size="middle">
                    <Switch
                        style={{'--bgColor': appColors?.appPrimaryColor ?? ""}as any}
                        checked={record.active}
                        onChange={(event) => onChangeStatus(event, record)}
                    />
                </Space>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            fixed: 'right',
            render: (_: any, record: any) => (
                <Space className="action-button" size="middle">
                    <Button
                        className="blue"
                        size="small"
                        type="primary"
                        shape="circle"
                        icon={<FaEye />}
                        onClick={() => goToViewGreetingCardDetails(record)}
                    />
                    <Button
                        className="green"
                        size="small"
                        type="primary"
                        shape="circle"
                        icon={<FaPen />}
                        disabled={userRolePermission["ReadOnly"]}
                        hidden={
                            !(
                                userRolePermission["UpdateLead"] ||
                                userRolePermission["UpdateOperations"] ||
                                userRolePermission["Admin"]
                            )
                        }
                        onClick={() => goToEditGreetingcard(record)}
                    />
                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={(event) => handleDelete(event, record)}
                        okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
                        cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
                    >
                        <Space
                            className="action-button"
                            size="middle"
                            style={{ marginLeft: "1%" }}
                        >
                            <Button
                                className="pink"
                                size="small"
                                type="primary"
                                shape="circle"
                                disabled={userRolePermission["ReadOnly"]}
                                hidden={
                                    !(
                                        userRolePermission["UpdateLead"] ||
                                        userRolePermission["UpdateOperations"] ||
                                        userRolePermission["Admin"]
                                    )
                                }
                                onClick={() => handleDelete}
                                icon={<AiFillDelete />}
                            />
                        </Space>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const onChangeStatus = (event: any, item: any) => {
        item.status = event;
        const id = item.busCommId;
        setLoading(true);
        API_SERVICE.updateOffers(
          {
            active: item.status,
            type: "GREETING",
            title: item.title,
            description: item.description,
          },
          id
        )
          .then(({ data }) => {
            setLoading(false);
            if (data) {
              notification.success({ message: data.message });
              getGreetingList(currentPage,pageSize,searchValue);
            }
          })
          .catch((e: any) => {
            setLoading(false);
            notification.error({ message: API_SERVICE.handleErrors(e) });
          });
    }

    const handleDelete = (event: any, item: any) => {
        const id = item.busCommId;
        API_SERVICE.getAPIForDeleteDataInList(id)
            .then(({ data }) => {
                setLoading(false);
                if (data) {
                    notification.success({ message: data.message });
                    let page = null as any;
                    if((totalCount/10) === parseFloat((currentPage-1)+"."+1)){
                        setCurrentPage(1);
                        page = 1;
                    }else{
                        setCurrentPage(currentPage);
                        page = currentPage;
                    }
                    getGreetingList(page,pageSize,searchValue);
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const handlePaginationChange = (pagination) => {
        getGreetingList(pagination?.current, pagination?.pageSize, searchValue)
        setCurrentPage(pagination?.current);
        setPageSize(pagination?.pageSize)
    }

    const searchLeadHandler = (event: any) => {
        setCurrentPage(1);
        setSearchValue(event.target.value);
        const params = {
            name: event.target.value,
        };
        getGreetingList(1, pageSize, event.target.value);
    };

    return (
        <>
            <div className="content-box">

                <Title level={3}>Greeting Cards</Title>

                <Row>

                    <Col className="filter-bar left" span={18}>
                        <Input
                            size="large"
                            className="search-box"
                            placeholder="Search"
                            onChange={debounce(searchLeadHandler, 600)}
                            allowClear
                            prefix={<FaSearch />}
                        />

                    </Col>
                    <Col span={6} className="filter-bar filter-bar-right">
                        <Button
                            style={{ marginLeft: "10px" }}
                            type="primary"
                            className="refresh-button"
                            onClick={() => {
                                getGreetingList(1, pageSize);
                            }}
                            shape="round"
                            icon={<ReloadOutlined />}
                            size={"middle"}
                        >
                        </Button>
                        <Button
                            type="primary"
                            className="add-button"
                            onClick={addGreetingHandler}
                            shape="round"
                            icon={<FaPlus />}
                            size={"middle"}
                            disabled={!(userRolePermission["Admin"] ||  userRolePermission["CreateOperations"])}
                            hidden={
                                !(
                                    userRolePermission["CreateLead"] ||
                                    userRolePermission["Admin"]
                                )
                            }
                        >
                            Add Greeting
                        </Button>

                    </Col>
                </Row>

                <Table
                    loading={loading}
                    // pagination={true}
                    size="small"
                    columns={columns}
                    dataSource={allGreetings}
                    onChange={handlePaginationChange}
                    pagination={
                        {
                            current: currentPage,
                            pageSize: pageSize,
                            total: totalCount,
                        }
                    }
                />



            </div>
        </>
    )

}

export default GreetingCards;