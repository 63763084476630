import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const decAuditSlice = createSlice({
    name: "DecAuditReducer",
    initialState: { pageNo: 1, pageSize: 10, searchParam: null },
    reducers: {
        setPageNo: (state, action: PayloadAction<number>) => {
            state.pageNo = action.payload;
        },
        setPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        setSearchParam: (state, action: PayloadAction<any>) => {
            state.searchParam = action.payload;
        }
    }
});

export const { setPageNo, setPageSize, setSearchParam } = decAuditSlice.actions;
export default decAuditSlice.reducer;