import { Collapse } from 'antd';
import React from 'react';

const TermsDetails: React.FC = () => {
    const { Panel } = Collapse;
    const termsDetails = {
        definitionsDetails: <p >
            "Application or app." shall mean oneinfinity, the mobile application created, developed and designed by the company For providing the services.
            "Lender" shall mean non-banking financial company or banks with whom the company has tied up for loan sanction, Which would sanction, process and grant the loan to the customer(s), through the platform.
            "Company" or "We" shall mean one arc labs private limited, a company incorporated under the companies act, 2013
            "Customer(s)" or "You" or "End-users" shall mean any person who accesses, downloads, uses, views the platform and The services.
            "Loan" shall mean the loan that you may apply for through the platform and which is sanctioned and granted by Lender, subject to the applicable terms and conditions of the loan agreement.
            "Loan agreement" shall mean the loan agreement to be executed between lender and the customer(s) for granting the Loan whether in physical or electronic form as may be applicable from time-to-time.
            "Online stores" shall mean windows store, android google play, ios app store or any other online store or portal Where the app will be made available by the company to the end-users, from time to time.
            "Outstanding amount(s)" shall mean the loan, interests and charges due and payable by you to lender, on respective Due date(s).
            "Platform" shall mean the app and the website collectively.
            "Services" shall mean the services of granting, sanctioning, lending of short term loans, through the platform by Lender.
            "Third party platforms" shall mean social networking platforms, such as facebook, linkedin and other similar Platforms.
            "User data" shall mean any data, information, documents or materials submitted with the company prior to or during The use of the services.
            "Website" shall mean www.Oneinfinity.In, managed and operated by the company for the provision of services.
        </p>,
        servicesDetails: <p>
            Through the services, you may apply for the loan, subject to the fulfilment of the eligibility criteria laid down in The mobile app. You understand that the company has been appointed by lender to collect, authenticate, track your Location, verify and confirm the user data, documents and details as may be required by lender to sanction the loan. Lender authorizes the company to collect and store the user data through the internet / website / mobile application Form ("Mobile application form") available on the platform. In order to avail the services, you are required to Register with the company by logging in through your third party platforms ("User account"). During the application Process, you shall be required to share and upload the user data on the mobile application form. User data shall Include personal information including but not limited to your name, e-mail address, gender, date of birth, mobile Number, passwords, photograph, mobile phone information including contact numbers, sms and browsing history, data And login-in credentials of third party platforms, financial information such as bank documents, salary slips, bank Statements, pan card, bank account no., data from credit information companies, data required for know your customer Compliances, requirement and other relevant details ("Personal information"). You agree that the personal Information shall always be accurate, correct and complete. As part of the services, you authorize us to import your Details and personal information dispersed over third party platforms. You understand and acknowledge that we may Periodically request for updates on such personal information and we may receive such updated information from third Party platforms.
            All transactions undertaken on your behalf by the company will be on the basis of your express instructions/consent And will be strictly on a non-discretionary basis. You also authorise the company to get your credit information Report from one or more credit information companies as decided by the company from time to time. Once you verify And upload the user data and/or other documents and details in the mobile application form, the company shall Process the same. Upon the completion of the document verification by the company, the loan may be sanctioned by Lender to you, subject to fitting eligibility criteria and other conditions set forth by lender for sanctioning the Loan. Thereafter, you are required to fill and upload the ecs/nach mandate form/cheque or any other document as may Be required by lender . The company may collect the physical documents including signatures on those documents Required for sanctioning and processing the loan. Upon the collection of documents by the company, lender shall Disburse the loan subject to the terms and conditions of the loan agreement.
            The sanctioned loan shall be disbursed as per the mode provided in the mobile application form. You are required to Repay the outstanding amount(s) to lender, on the respective due date(s) mentioned in the mobile application form.
            You understand and acknowledge that the company reserves the right to track your location ("Track") during the Provision of services, and also in the event that you stop, cease, discontinue to use or avail the services, through Deletion or uninstallation of mobile app or otherwise, till the event that your obligations to pay the outstanding Amount(s) to lender exist. Deletion, uninstallation, discontinuation of our services, shall not release you from the Responsibility, obligation and liability to repay the outstanding amount(s).
            You understand and acknowledge that you shall be solely responsible for all the activities that occur under your User account while availing the services. You undertake that the company shall not be responsible and liable for any Claims, damages, disputes arising out of use or misuse of the services. By usage of the services, you shall be Solely responsible for maintaining the confidentiality of the user account and for all other related activities Under your user account. The company reserves the right to accept or reject your registration for the services Without obligation of explanation.
            You understand and acknowledge that, you are solely responsible for the capability of the electronic devices and the Internet connection, you chose to run the platform. The platformâ€™s operation or the services on your electronic Device is subject to availability of hardware, software specifications, internet connection and other features and Specifications, required from time to time.
            The user data provided during the registration is stored by the company for your convenience. You are not required To log-in to your user account, every time, to use or access the platform. You understand and acknowledge that by Accepting these terms, you authorize us to track, fetch and use the user data, including but not limited to your Personal information, for the purpose of authentication and any updates with regards to your credentials.
        </p>,
        licenseDetails: <p>
            - License to use the app:in order to use the services, you are required to download and install the app. For This purpose, you represent and warrant that you are of the age of majority as per the applicable laws to Which you are subject to and are competent to understand, enter into, and comply with these terms. The Company grants you a limited, non-exclusive, non-transferable, non-sub-licensable and revocable right to Download, install and use the app. The app. Is licensed and not sold to you and shall only be used as per These terms.
            - Scope of license:you may install, download, access or use the app through the online stores on/from mobile Phones, tablets or any other electronic devices.
            - Maintenance & support: you acknowledge that while the company may, at its sole discretion, provide Maintenance and support for the app from time to time, the company shall have no specific obligation Whatsoever to furnish such services to you.
            - Updates/upgrades: we may launch new updates/upgrades for the app., you may subscribe to the same through the Online stores. In the event, you choose not to update/upgrade the app, certain features or functionality Shall not be accessible to you.
        </p>,
        restrictionsDetails: <p>
            You agree not to:
            - Use the platform or the services for committing fraud, embezzlement, money laundering, or for any unlawful And/or illegal purposes;
            - To reproduce, duplicate, copy, sell, resell or exploit any portion of the app;
            - Upload, post, email, transmit or otherwise make available any content that is unlawful, harmful, Threatening, abusive, harassing, torturous, defamatory, vulgar, obscene, libelous, invasive of anotherâ€™s Privacy, hateful, or racially, ethnically or otherwise objectionable through the platform;
            - Use the platform to harm or injure any third party;
            - Impersonate any person or entity, on the platform;
            - Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted Through the app;
            - Upload, post, email, transmit or otherwise make available any content that you do not have a right to make Available under any law or under contractual or fiduciary relationships (such as inside information, Proprietary and confidential information learned or disclosed as part of employment relationships or under Nondisclosure agreements);
            - Upload, post, email, transmit or otherwise make available on the platform, any content that infringes any Patent, trademark, trade secret, copyright or other proprietary rights of any party;
            - Upload, post, email, transmit or otherwise make available on the platform, any unsolicited or unauthorized Advertising, promotional materials, "Junk mail," "Spam", "Chain letters," "Pyramid schemes," or any other Form of solicitation;
            - Upload, post, email, transmit or otherwise make available on the platform, any material that contains Software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the Functionality of any computer software or hardware or telecommunications equipment;
            - Disrupt the normal flow of or otherwise act in a manner that negatively affects other userâ€™s ability to Engage in real time exchanges;
            - Interfere with or disrupt the platform or servers or networks connected to the platform, or disobey any Requirements, procedures, policies or regulations of networks connected to the platform;
            - Intentionally or unintentionally violate any applicable local, state, national or international laws and any Regulations having the force of law.
        </p>,
        contentAvailableDetials: <p>
            You acknowledge that the company makes no representations or warranties about the material, data, and
            information, Such as data files, text, facts and figures, computer software, code, audio files or other
            sounds, photographs, Videos, or other images (collectively, the "Content") which you may have access to
            as part of the services, or Through your use of the platform. Under no circumstances, shall the company be
            liable in any way for any content, Including, but not limited to any infringing content, any errors or omissions
            in content, or for any loss or damage Of any kind incurred as a result of the use of any content posted, transmitted,
            linked from, or otherwise accessible Through or made available via the platform. The content on the platform
            should not be regarded as an offer, Solicitation, invitation, advice or recommendation to buy or sell investments,
            securities or any other instrument or Financial products / schemes of the company (including its affiliates), unless
            expressly covered in these terms.
        </p>,
        proprietaryRightsofTheCompanyDetails: <p>
            You understand, acknowledge and agree that the company is the sole owner of all rights, title and interest, Including any and all intellectual property rights in the content, platform, services, logos, trade names, brand Names, designs and any necessary software used in connection with the platform.
            There may be proprietary logos, service marks and trademarks found on the platform whether owned/used by the company Or otherwise. By displaying them on the platform, the company is not granting you any license to utilize the Proprietary logos, service marks, or trademarks. Any unauthorized use of the same may violate applicable Intellectual property laws.
            You understand and acknowledge that the platform is owned by the company. Nothing under these terms shall be deemed To be a transfer in ownership, rights, title, from the company to you or any third party, in the platform. You are Entitled to avail the services offered by the company during the validity of your registration with the company.
        </p>,
        linksToThirdPartySitesDetails: <p>
            The platform may contain links to other websites owned and operated by third parties who are not related to the Platform ("Linked websites"). The linked websites are not under the control of the company and the company shall not Be responsible for the content of any linked websites or any hyperlink contained in a linked website and makes no Representation or warranty with respect to the content of any such third party sites.
            The platform provides these links to you as a convenience only and the inclusion of any link does not imply any Endorsement of the linked website by the company. Your access or use of such linked website is entirely at your own Risk. The company shall not be a party to any transaction between you and the linked website. Your use of a linked Website is subject to these terms and conditions of that respective linked website.
            The platform may also contain third party advertisements, if any. The display of such advertisements does not in any Way imply an endorsement or recommendation by/of the relevant advertiser, its products or services. You shall Independently refer to the relevant advertiser for all information regarding the advertisement and its products And/or services. The company accepts no responsibility for any interaction between you and the relevant third party And is released from any liability arising out of or in any way connected with such interaction.
        </p>,
        ancillaryServicesDetails: <p>
            You may get access to chat rooms, blogs, feedbacks, reviews and other features ("Ancillary services")
            that are/may Be offered from time to time on the platform and may be operated by us or by a third party
            on our behalf. You shall Not (nor cause any third party to) use these ancillary services to perform
            any illegal activities (including without Limitation defaming, abusing, harassing, stalking, threatening,
            promoting racism, or otherwise violating the legal Rights, such as rights of privacy, of others) or immoral
            activities, falsely stating or otherwise misrepresenting Your affiliation with a person or entity. Additionally,
            the platform may contain advice/opinions and statements of Various professionals/ experts/ analysts, etc. The
            company does not endorse the accuracy, reliability of any such Advices/opinions/ and statements. You may rely
            on these, at your sole risk and cost. You shall be responsible for Independently verifying and evaluating the
            accuracy, completeness, reliability and usefulness of any opinions, Services, statements or other information
            provided on the platform. All information or details provided on the Platform shall not be interpreted or
            relied upon as legal, accounting, tax, financial, investment or other Professional advice, or as advice on
            specific facts or matters. The company may, at its discretion, update, edit, Alter and/or remove any information
            in whole or in part that may be available on the platform and shall not be Responsible or liable for any subsequent
            action or claim, resulting in any loss, damage and or liability. Nothing Contained herein is to be construed as
            a recommendation to use any product or process, and the company makes no Representation or warranty, express
            or implied that, the use thereof will not infringe any patent, or otherwise.
        </p>,
        terminationDeatils: <p>
            The company reserves its rights to terminate these terms in the event:
            - You breach any provision of these terms;
            - The company is required to do so under law; or
            - The company chooses to discontinue the services being offered or discontinue to operate the platform;
            - The license granted to use the app expires;
            - Of non-payment of outstanding amount(s).
            Upon termination of these terms, the rights and licenses granted to you under these terms shall cease to
            exist, and You must forthwith stop using the platform and the services and repay the outstanding amount(s).
            Notwithstanding Anything contained in these terms or otherwise, the termination of these terms for any
            reason whatsoever, shall not Affect your obligations, including but not limited to repayment of the outstanding amount(s).
        </p>,
        disclaimerofWarrantiesDetails: <p>
            You expressly understand and agree that
            Your use of the services and the platform is at your sole risk. The services and the platform are provided
            on an "As Is" and "As available" basis. The company expressly disclaims all warranties of any kind, whether
            express or Implied, including, but not limited to the implied warranties of merchantability, fitness for
            a particular purpose And non-infringement. Any material downloaded or otherwise obtained through the access
            or use of the platform, is at Your own discretion and risk and that you will be solely responsible for any
            damage to your computer system, Electronic data or loss of data that results from the download of any such
            material. No advice or information, Whether verbal or written, obtained by you from the company, for the
            services or through the platform shall create Any warranty not expressly stated in these terms. The services
            are intended for personal, non-commercial use. You Shall be solely responsible for the use, misuse, improper
            usage of the services and the platform. The company shall Not be liable for any damages accruing out of the
            use of the services which have not been expressly stipulated under These terms. The company makes no warranty,
            including implied warranty, and expressly disclaims any obligation, That: (a) the contents are and will be complete,
            exhaustive, accurate or suitable to your requirements; (b) the Platform or the services will meet your requirements
            or will be available on an uninterrupted, timely, secure, or Error-free basis; (c) the results that may be
            obtained from the use of the platform or services will be accurate or Reliable.
        </p>,
        indemnityDetails: <p>
            You agree to indemnify and hold the company, and its subsidiaries, affiliates, officers, agents, co-branders or
            Other partners, and employees, harmless from any claim or demand, including attorneysâ€™ fees, made by any third
            party Due to or arising out of (I) your violation of these terms; (ii) your violation of any rights of other users
            of the Platform; (iii) your use or misuse of the platform or the services; (iv) your violation of applicable laws.
        </p>,
        limitationsofLiabilityDeatils: <p>
            You expressly understand and agree that the company, including its directors, officers, employees, representatives Or the service provider, shall not be liable for any direct, indirect, incidental, special, consequential or Exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other Intangible losses (even if the company has been advised of the possibility of such damages), resulting from; (a) use Or the inability to avail the services (b) inability to use the platform (c) failure or delay in providing the Services or access to the platform (d) any performance or non-performance by the company (e) any damages to or Viruses that may infect your electronic devices or other property as the result of your access to the platform or Your downloading of any content from the platform and (f) server failure or otherwise or in any way relating to the Services.
        </p>,
        forceMajeure: <p>
            Without limiting the foregoing, under no circumstances shall the company be held liable for any damage, loss, loss Of services of platform, due to deficiency in provision of the services resulting directly or indirectly from acts Of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures, Computer equipment failures, telecommunication equipment failures, change in applicable regulations, including Reserve bank of india regulations, or any other government regulations, floods, storms, electrical failure, civil Disturbances, riots.
        </p>,
        privacyPolicyDetails: <p>
            The personal information collected/shared/uploaded for the provision of services has been exhaustively covered in Our privacy policy ("Privacy policy"). Our privacy policy is available at here.
        </p>,
        changeDetails: <p>
            The company reserves the right to modify, change, substitute, remove, suspend or update these terms or any Information thereof at any time by posting the updated terms on the platform. Such changes shall be effective Immediately upon such posting. Continued use of the services or the platform, subsequent to making the changes, Shall be deemed to be your acceptance of the revised terms.
        </p>,
        choiceofLawAndJurisdictionDetils: <p>
            This agreement shall be construed and governed by the laws of india without regard to principles of conflict of Laws. Parties further agree that the courts in mumbai, maharashtra, india shall have an exclusive jurisdiction over Such disputes.
        </p>,
        miscellaneousDeatils: <p>
            Entire understanding: these terms, along with the loan agreement, mobile application form and privacy policy, Constitute the entire understanding between you and the company with relation to the services. Waiver:the failure of The company to exercise or enforce any right or provision of these terms shall not constitute a waiver of such right Or provision. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising Out of or related to availing of the services or these terms must be filed within one (1) year after such claim or Cause of action arose or be forever barred. Severability: if any provision of these terms is found by a court of Competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect To the partiesâ€™ intentions as reflected in the provision, and the other provisions of these terms shall remain in Full force and effect.
        </p>,
        violationsDeatails: <p>
            Please report any violations or grievances with relation to these terms to the company at info@oneinfinity.In
        </p>
    }
    return (
        <Collapse>
            <Panel header="Definitions" key="1">
                {termsDetails?.definitionsDetails}
            </Panel>
            <Panel header="Services" key="2">
                {termsDetails.servicesDetails}
            </Panel>
            <Panel header="License" key="3">
                {termsDetails?.licenseDetails}
            </Panel>
            <Panel header="Restrictions" key="4">
                {termsDetails?.restrictionsDetails}
            </Panel>
            <Panel header="Content Available" key="5">
                {termsDetails?.contentAvailableDetials}
            </Panel>
            <Panel header="Proprietary Rights of The Company" key="6">
                {termsDetails?.proprietaryRightsofTheCompanyDetails}
            </Panel>
            <Panel header="Links to Third Party Sites" key="7">
                {termsDetails?.linksToThirdPartySitesDetails}
            </Panel>
            <Panel header="Ancillary Services" key="8">
                {termsDetails?.ancillaryServicesDetails}
            </Panel>
            <Panel header="Termination" key="9">
                {termsDetails?.terminationDeatils}
            </Panel>
            <Panel header="Disclaimer of Warranties" key="10">
                {termsDetails?.disclaimerofWarrantiesDetails}
            </Panel>
            <Panel header="Indemnity" key="11">
                {termsDetails?.indemnityDetails}
            </Panel>
            <Panel header="Limitations of Liability" key="12">
                {termsDetails?.limitationsofLiabilityDeatils}
            </Panel>
            <Panel header="Force Majeure" key="13">
                {termsDetails?.forceMajeure}
            </Panel>
            <Panel header="Privacy Policy" key="14">
                {termsDetails?.privacyPolicyDetails}
            </Panel>
            <Panel header="Change" key="15">
                {termsDetails?.changeDetails}
            </Panel>
            <Panel header="Choice of Law and Jurisdiction" key="16">
                {termsDetails?.choiceofLawAndJurisdictionDetils}
            </Panel>
            <Panel header="Miscellaneous" key="17">
                {termsDetails?.miscellaneousDeatils}
            </Panel>
            <Panel header="Violations" key="18">
                {termsDetails?.violationsDeatails}
            </Panel>
        </Collapse>
    )
}

export default TermsDetails;