import { Button, Table, Typography, notification } from "antd";
import { ColumnsType } from "antd/lib/table";
import arrowLeft from "../../../../../assets/images/arrow_left.svg";
import { ReactComponent as ArrowLeft } from "../../../../../assets/images/arrow_left.svg";
import wrong from "../../../../../assets/images/wrong.svg";
import apporved from "../../../../../assets/images/emptyApproved.svg";
import blueApporved from "../../../../../assets/images/emptyBlueApporved.svg";
import API_SERVICE from "shared/services/api-service";
import { useEffect, useState } from "react";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { getEnumValue } from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IVasOutput {
  setLeadViewType: any;
  leadData: any;
}

const { Title } = Typography;

const VASOutput: React.FC<IVasOutput> = (props: IVasOutput) => {
  const { setLeadViewType, leadData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [vasStatusData, setVasStatusData] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    getVASActionStatus();
  }, []);

  const getVASActionStatus = () => {
    setIsLoading(true);
    API_SERVICE.getVASActionStatus(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        const tmpData = [
          {
            vas: getEnumValue("MASTER_PRODUCT_VAS_SERVICES", "BANK_STATEMENT_ANALYSIS"),
            status: data?.payload?.vasTypeAndStatusMap?.BANK_STATEMENT_ANALYSIS,
          },
          {
            vas: getEnumValue("MASTER_PRODUCT_VAS_SERVICES", "GET_BUREAU"),
            status: data?.payload?.vasTypeAndStatusMap?.GET_BUREAU?.status,
            color: data?.payload?.vasTypeAndStatusMap?.GET_BUREAU?.color
          },
        ];
        setVasStatusData(tmpData);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "VAS",
      dataIndex: "vas",
      key: "vas",
      render: (rec: any) => (
        <span>{getEnumValue("MASTER_PRODUCT_VAS_SERVICES", rec)}</span>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (rec: any, _: any) => {
        return rec == "Expired" || rec == "Incompleted" ? (
          <span style={{ color: _?.color ? _?.color : "#E12121" }}>
            <img src={wrong} alt="" width={20} /> {rec}
          </span>
        ) : rec == "Completed" ? (
          <span style={{ color: _?.color ? _?.color : "#20A74E" }}>
            <img src={apporved} alt="" width={20} /> {rec}
          </span>
        ) : rec == "Initiated" ? (
          <span style={{ color: _?.color ? _?.color : "#273896" }}>
            <img src={blueApporved} alt="" width={20} /> {rec}
          </span>
        ) : (
          <span style={{ color: _?.color ? _?.color : "#E12121" }}>{rec ?? "--"}</span>
        );
      },
    },
  ];

  const btnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };

  return (
    <>
      <div
        className="heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "1.1rem",
        }}
      >
        <Title level={5} style={{ marginTop: "15px" }}>
          VAS Output
        </Title>
        <Button
          onClick={() => setLeadViewType("LeadDetailNewTabs")}
          className="back-btn"
          style={btnPrimaryStyles}
          icon={<ArrowLeft width={20} />}
        >
          Back
        </Button>
      </div>
      <div style={{ width: "100%" }}>
        <Table
          columns={columns}
          dataSource={vasStatusData}
          size="small"
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </div>
      {isLoading && <AppLoading />}
    </>
  );
};

export default VASOutput;
