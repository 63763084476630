import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import "../../../LeadNew/LeadDetail/LenderPrivacyPolicy/LenderPrivacyPolicy.scss";
import { getEnumList, numberWithCommas } from "shared/services/Utility";
import iconAction from "../../../../../assets/images/action.svg";
import iconPlus from "../../../../../assets/images/add_round.svg";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

interface IPrivacyPolicyPanel {
  lender: any;
  productPlans?: any;
  lenderIndex?: any;
  updateCase: any;
  showAdd: boolean;
  onClickAdd?: React.MouseEventHandler<HTMLElement>;
}

const PrivacyPolicyPanel: React.FC<IPrivacyPolicyPanel> = (
  props: IPrivacyPolicyPanel
) => {
  const { lender, productPlans, lenderIndex, updateCase, showAdd, onClickAdd } =
    props;
  const [policyStatusKey, setPolicyStatusKey] = useState();
  const [errorCoverTenure, setErrorCoverTenure] = useState(false);
  const [errorPremiumTenure, setErrorPremiumTenure] = useState(false);
  const [formPrivacyPolicy] = Form.useForm();

  const withdrawnReasons = getEnumList("INSURANCE_WITHDRAWN_REASON");
  const frequencyPaymentList = getEnumList("FREQUENCY_OF_PAYMENT");
  const dateFormat = "DD/MM/YYYY";

  const policyStatusList = [
    {
      key: "POLICY_ISSUED",
      value: "Policy Issued",
    },
    {
      key: "UNDERWRITING_REJECT",
      value: "Underwriting Reject",
    },
    {
      key: "COUNTER_OFFER",
      value: "Counter Offer",
    },
    {
      key: "WITHDRAWN",
      value: "Withdrawn",
    },
  ];

  useEffect(() => {
    setFormData();
  }, [lender]);

  const setFormData = () => {
    if (["LOGGED"].includes(lender?.status)) {
      return;
    }

    const insuranceDetails = lender?.insuranceDetails;
    if (!insuranceDetails) {
      return;
    }

    const iDate = insuranceDetails.policyIssuedDate;
    const eDate = insuranceDetails.policyEndDate;

    formPrivacyPolicy.setFieldsValue({
      productPlanId: insuranceDetails.productPlanId,
      policyIssuedDate: iDate && moment(iDate, dateFormat),
      policyEndDate: eDate && moment(eDate, dateFormat),
      grossPremiumAmount: insuranceDetails.grossPremiumAmount,
      frequencyOfPayment: insuranceDetails.frequencyOfPayment,
      premiumTenureYear: insuranceDetails.premiumTenure?.years,
      premiumTenureMonth: insuranceDetails.premiumTenure?.months,
      maturitySumInsured: insuranceDetails.maturitySumInsured,
      coverTenureYear: insuranceDetails.coverTenure?.years,
      coverTenureMonth: insuranceDetails.coverTenure?.months,
      counterOfferPremium: insuranceDetails.counterOfferPremium,
      policyNumber: insuranceDetails.policyNumber,
      proposalApplicationNumber: insuranceDetails.proposalApplicationNumber,
      policyStatus: insuranceDetails.policyStatus,
      policyStatusComments: insuranceDetails.policyStatusComments,
    });
    setPolicyStatusKey(insuranceDetails?.policyStatus);
  };

  const onFinish = (values: any) => {

    const yearcover = formPrivacyPolicy.getFieldValue("coverTenureYear");
    const monthcover = formPrivacyPolicy.getFieldValue("coverTenureMonth");
    const isErrorcover = (monthcover == 0 && yearcover == 0);
    setErrorCoverTenure(isErrorcover)

    const yearpremium = formPrivacyPolicy.getFieldValue("premiumTenureYear");
    const monthpremium = formPrivacyPolicy.getFieldValue("premiumTenureMonth");
    const isErrorpremium = (monthpremium == 0 && yearpremium == 0);
    setErrorPremiumTenure(isErrorpremium)

    if(values?.policyStatus == "POLICY_ISSUED" && (isErrorcover || isErrorpremium || errorCoverTenure || errorPremiumTenure)) {
      return;
    }    

    const plan = productPlans?.find(
      (plan: any) => plan?.productPlanId === values?.productPlanId
    );
    const insuranceDetails = {
      ...values,
      productPlanName: plan?.productPlanName,
      policyEndDate: values?.policyEndDate
        ? moment(values?.policyEndDate).format(dateFormat).toString()
        : "",
      policyIssuedDate: values?.policyIssuedDate
        ? moment(values?.policyIssuedDate).format(dateFormat).toString()
        : "",
      premiumTenure: {
        months: values?.premiumTenureMonth,
        years: values?.premiumTenureYear,
      },
      coverTenure: {
        months: values?.coverTenureMonth,
        years: values?.coverTenureYear,
      },
    };
    const tmpLender = {
      insuranceDetails: { insuranceDetails: [insuranceDetails] },
      comments: "",
      status: values?.policyStatus,
      lenderActivityId: lender?.lenderActivityId,
      lenderInfoId: lender?.lenderInfoId,
      lenderName: lender?.lenderName,
    };
    updateCase(tmpLender);
  };

  const renderActionBtn = () => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          type="text"
          style={{ backgroundColor: "transparent", border: "none" }}
          icon={<img src={iconPlus} alt="" height={20} width={20} />}
          hidden={!showAdd}
          onClick={onClickAdd}
        />
        <Form.Item name="action" className="" style={{ marginBottom: "0px" }}>
          <Button
            type="text"
            htmlType="submit"
            style={{ backgroundColor: "transparent", border: "none" }}
            icon={<img src={iconAction} alt="" height={20} width={20} />}
          />
        </Form.Item>
      </div>
    );
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      moment().add(-5, "years") >= current ||
      moment().add(5, "years") <= current
    );
  };

  const disabledEndDate: RangePickerProps["disabledDate"] = (current) => {
    const idate = formPrivacyPolicy.getFieldValue("policyIssuedDate");
    if (idate != null) {
      return current?.isBefore(moment(idate));
    }
    return !(current && current > moment().endOf("day"));
  };

  const setEndDate = () => {
    const idate = formPrivacyPolicy.getFieldValue("policyIssuedDate");
    const year = formPrivacyPolicy.getFieldValue("coverTenureYear");
    const month = formPrivacyPolicy.getFieldValue("coverTenureMonth");
    const isError = (month == 0 && year == 0);
    setErrorCoverTenure(isError)
    if (idate != null && !isError) {
      formPrivacyPolicy.setFieldsValue({
        policyEndDate: moment(idate).add(year, "years").add(month, "months").subtract(1, "day"),
      });
    }
  };

  const changePremium = () => {
    const year = formPrivacyPolicy.getFieldValue("premiumTenureYear");
    const month = formPrivacyPolicy.getFieldValue("premiumTenureMonth");
    const isError = (month == 0 && year == 0);
    setErrorPremiumTenure(isError)
  };

  const renderPrivacyPolicySelectFields = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`policyNumber`}
            className="decision-item privacy-policy-label"
            label="Policy No."
          >
            <Input className="custom-input" />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <span className="field-lable privacy-policy-label">
            <span className="star">*</span> Cover Tenure(In Years-Months)
          </span>
          <div style={{ display: "flex", gap: "6px" }}>
            <Form.Item
              name={`coverTenureYear`}
              className="decision-item privacy-policy-label"
              style={{ flex: 1 }}
              rules={[{ required: true, message: "Please enter year" }
                , {
                  validator: (_, value) => {
                    if (value > 100) {
                      return Promise.reject(new Error("Please enter years upto 100"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                className="custom-input-number"
                onBlur={() => setEndDate()}
              />
            </Form.Item>
            <Form.Item
              className="decision-item privacy-policy-label"
              name={`coverTenureMonth`}
              style={{ flex: 1 }}
              rules={[{ required: true, message: "Please enter month" }]}
            >
              <Select className="custom-select" onChange={() => setEndDate()}>
                {Array.from(Array(12).keys()).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {errorCoverTenure &&
            <div style={{fontSize:'10px', color:'red', marginTop:"-6px"}}>
              Min 1 month required
            </div>
          }
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`policyIssuedDate`}
            label="Policy Issue Date"
            className="decision-item privacy-policy-label"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{
                width: "98%",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              format={dateFormat}
              disabledDate={disabledDate}
              className="custom-input"
              onChange={() => setEndDate()}
              // placeholder="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`policyEndDate`}
            label="Policy End Date"
            className="decision-item privacy-policy-label"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{
                width: "98%",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              format={dateFormat}
              className="custom-input"
              disabledDate={disabledEndDate}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`grossPremiumAmount`}
            className="decision-item privacy-policy-label"
            label="Gross Premium Amount"
            rules={[{ required: true }]}
          >
            <InputNumber
              className="custom-input-number"
              disabled={lender?.insuranceDetails?.grossPremiumAmount}
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            className="decision-item privacy-policy-label"
            label="Frequency Of Payment"
            name={`frequencyOfPayment`}
            rules={[{ required: true }]}
          >
            <Select className="custom-select">
              {frequencyPaymentList?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <span className="field-lable privacy-policy-label">
            <span className="star">*</span> Premium Tenure(In Years-Months)
          </span>
          <div style={{ display: "flex", gap: "6px" }}>
            <Form.Item
              name={`premiumTenureYear`}
              className="decision-item privacy-policy-label"
              style={{ flex: 1 }}
              rules={[{ required: true, message: "Please enter year" }
                , {
                  validator: (_, value) => {
                    if (value > 100) {
                      return Promise.reject(new Error("Please enter years upto 100"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber className="custom-input-number" onBlur={() => changePremium()}/>
            </Form.Item>
            <Form.Item
              className="decision-item privacy-policy-label"
              name={`premiumTenureMonth`}
              style={{ flex: 1 }}
              rules={[{ required: true, message: "Please select month" }]}
            >
              <Select className="custom-select" onChange={() => changePremium()}>
                {Array.from(Array(12).keys()).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {errorPremiumTenure &&
            <div style={{fontSize:'10px', color:'red', marginTop:"-6px"}}>
              Min 1 month required
            </div>
          }
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`maturitySumInsured`}
            className="decision-item privacy-policy-label"
            label="Maturity/Sum Insured"
          >
            <InputNumber
              className="custom-input-number"
              name="maturitySumInsured"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        </Col>
      </>
    );
  };

  const renderRejectedView = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`proposalApplicationNumber`}
            className="decision-item privacy-policy-label"
            label="Proposal Application No."
          >
            <Input className="custom-input" />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} xl={18}>
          <div className="rejected-wrapper">
            <Form.Item
              name={`policyStatusComments`}
              label="Rejection Reason"
              className="privacy-policy-form-item"
            >
              <TextArea className="custom-textarea" rows={1} />
            </Form.Item>
          </div>
        </Col>
      </>
    );
  };

  const renderWithdraw = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`proposalApplicationNumber`}
            className="decision-item privacy-policy-label"
            label="Proposal Application No."
          >
            <Input className="custom-input" />
          </Form.Item>
        </Col>
        <Col span={6} xs={24} xl={6}>
          <div className="rejected-wrapper">
            <Form.Item
              name={`policyStatusComments`}
              label="Withdrawn Reason"
              className="privacy-policy-form-item"
            >
              <Select
                className="custom-select"
                showSearch
                placeholder="Select Reason"
                optionFilterProp="children"
              >
                {withdrawnReasons?.map((item) => (
                  <Select.Option key={item?.key} value={item?.key}>
                    {item?.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </>
    );
  };

  const renderCounterOffer = () => {
    return (
      <>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            name={`proposalApplicationNumber`}
            className="decision-item privacy-policy-label"
            label="Proposal Application No."
          >
            <Input className="custom-input" />
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            className="decision-item privacy-policy-label"
            label="Frequency Of Payment"
            name={`frequencyOfPayment`}
          >
            <Select className="custom-select">
              {frequencyPaymentList?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} xs={12} xl={6}>
          <Form.Item
            className="decision-item privacy-policy-label"
            label="Counter Offer Premium"
            name={`counterOfferPremium`}
          >
            <InputNumber
              className="custom-input-number"
              name="counterOfferPremium"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        </Col>
      </>
    );
  };

  const renderPrivacyPanel = () => {
    return (
      <li key={lenderIndex} style={{ marginTop: "-0px" }}>
        <Form form={formPrivacyPolicy} layout="vertical" onFinish={onFinish}>
          <div className="lender-privacy-policy-header">
            {lender?.lenderName}
            {renderActionBtn()}
          </div>
          <div className="lender-privacy-policy-main-div">
            <Row gutter={[6, 6]} style={{ rowGap: "1px" }}>
              <Col span={6} xs={12} xl={6}>
                <Form.Item
                  className="decision-item privacy-policy-label"
                  label="Product Plan"
                  name={`productPlanId`}
                  rules={[{ required: true }]}
                >
                  <Select className="custom-select">
                    {productPlans?.map((item) => (
                      <Select.Option
                        key={item?.productPlanId}
                        value={item?.productPlanId}
                      >
                        {item?.productPlanName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} xs={12} xl={6}>
                <Form.Item
                  className="decision-item privacy-policy-label"
                  label="Policy Status"
                  name={`policyStatus`}
                  rules={[{ required: true }]}
                >
                  <Select
                    className="custom-select"
                    onSelect={(ev: any) => setPolicyStatusKey(ev)}
                  >
                    {policyStatusList?.map((item) => (
                      <Select.Option key={item?.key} value={item?.key}>
                        {item?.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {policyStatusKey === "POLICY_ISSUED" &&
                renderPrivacyPolicySelectFields()}
              {policyStatusKey === "UNDERWRITING_REJECT" &&
                renderRejectedView()}
              {policyStatusKey === "WITHDRAWN" && renderWithdraw()}
              {policyStatusKey === "COUNTER_OFFER" && renderCounterOffer()}
            </Row>
          </div>
        </Form>
      </li>
    );
  };

  return <>{renderPrivacyPanel()}</>;
};
export default PrivacyPolicyPanel;
