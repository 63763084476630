 import { Button, Card, Col, Collapse, Modal, Row, Select, Space, TabPaneProps, Tabs, TabsProps, Typography } from "antd";
import React, { useEffect, useState } from "react";
// import validator from "@rjsf/validator-ajv8";
// import Input from "antd/lib/input/Input";
// import FormJson from "@rjsf/core";
// @ts-ignore
import {Form} from 'react-formio';
import 'formiojs/dist/formio.builder.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import jsonForm from './JSONSchema.json'
import './poc.scss'
import { ArrayFieldTemplateItemType, ArrayFieldTemplateProps, FieldTemplateProps, getSubmitButtonOptions, RJSFSchema, SubmitButtonProps } from "@rjsf/utils";
type Props = {};


const POC: React.FunctionComponent<Props> = () => {
    const handleSubmit=(data)=>  {
      console.log(data);
    }
    // const [activeTab,setActiveTab] = useState(0);
    // const MadTextWidget = (props:any) => {

    //     var valueHolder = "";        
    //     const { value, onChange} = props;
    //     const handleChange = evt => {
    //       valueHolder = evt.target.value;
    //     }
    //     const manageOnClick = (e) => {
    //       console.log(valueHolder);
    //     }
        
    //     return (
    //       <>
    //       <Input 
    //         className="ant-input ant-input-status-success custom-input"
    //         value={value}
    //         // required={props.required}
    //         onChange={handleChange} />
    //         <Button
    //           size="small"
    //           type="primary"
    //           onClick={manageOnClick}
    //           style={{ width: "40%" }}
    //         >
    //           Search
    //         </Button>
    //         <Select>
    //           <Select.Option > </Select.Option>
    //         </Select>
    //       </>
    //     );
    //   };
    
    //   function ArrayFieldItemTemplate(props: ArrayFieldTemplateItemType) {
    //     const { children, className } = props;
    //     console.log(children);
    
    //     const [isModalOpen, setIsModalOpen] = useState(false);
    
    //       const showModal = () => {
    //         setIsModalOpen(true);
    //       };
        
    //       const handleOk = () => {
    //         setIsModalOpen(false);
    //       };
        
    //       const handleCancel = () => {
    //         setIsModalOpen(false);
    //       };
      
    
    
    //     return (
    //       <>
    //       {/* <Collapse defaultActiveKey={['1']}>
    //          <Panel header="This is panel header 1" key="1">{children}</Panel>
    //        </Collapse> */}
    //         <Button type="primary" onClick={showModal}>
    //         Open Modal
    //       </Button>
    //         <Modal width={1000} title="Basic Modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
    //           {children}
    //       </Modal>
          
    //       </>
    //     );
    //   }
    
    //   const CustomArrayWidget = (props:any) => {
    
    //     var valueHolder = "";        
    //     const { value, onChange} = props;
    //     console.log(props);
    //     const handleChange = evt => {
    //       valueHolder = evt.target.value;
    //     }
    //     const manageOnClick = (e) => {
    //       console.log("Props",props);
    //     }
    //     return (
    //       <>
    //         <Button onClick={manageOnClick}>1</Button>
    //       </>
    //     );
    //   };
    
    //   function CustomFieldTemplate(props: FieldTemplateProps) {
    //     const {id, classNames, style, label, help, required, description, errors, children} = props;
    //     return (
    //       <div className={classNames} style={style}>
    //         <label htmlFor={id}>{label}{required ? "*" : null}</label>
    //         {description}
    //         {children}
    //         {errors}
    //         {help}
    //       </div>
    //     );
    //   }
    //   function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
    //     return (
    //       <div>
    //         {props.items.map(element => element.children)}
    //         {props.canAdd && <button type="button" onClick={props.onAddClick}></button>}
    //       </div>
    //     );
    //   }
    //   function SubmitButton(props: SubmitButtonProps) {
    //     const { uiSchema } = props;
    //     const { norender } = getSubmitButtonOptions(uiSchema);
    //     if (norender) {
    //       return null;
    //     }
    //     return (
    //       <button type="submit">
    //         {/* <FormattedMessage defaultMessage="Okay" /> */}
    //         NEXT
    //       </button>
    //     );
    //   };
    
    //   const widgets = {
    //     madTextWidget: MadTextWidget,
    //     customArrayWidget : CustomArrayWidget,
    //   }
    //   const templates = {
    //     ArrayFieldItemTemplate:ArrayFieldItemTemplate,
    //     FieldTemplate : CustomFieldTemplate,
    //     ButtonTemplates : {
    //         SubmitButton : SubmitButton
    //     }
    //     //ArrayFieldTemplate:ArrayFieldTemplate
    //   }
    //   const uiSchema = {}

    //   const schema = {
    //     Section1 : {
    //         "title": "A registration form",
    //         "description": "A simple form example.",
    //         "type": "object",
    //         "required": [
    //           "firstName",
    //           "lastName"
    //         ],
    //         "properties": {
    //           "firstName": {
    //             "type": "string",
    //             "title": "First name",
    //             "default": "Chuck"
    //           },
    //           "lastName": {
    //             "type": "string",
    //             "title": "Last name"
    //           },
    //         }
    //       },
    //     Section2 : {
    //         "title": "A registration form",
    //         "description": "A simple form example.",
    //         "type": "object",
    //         "required": [
    //         ],
    //         "properties": {
    //           "age": {
    //             "type": "integer",
    //             "title": "Age"
    //           },
    //           "bio": {
    //             "type": "string",
    //             "title": "Bio"
    //           },
    //         }
    //       },
    //     Section3 : {
    //         "title": "A registration form",
    //         "description": "A simple form example.",
    //         "type": "object",
    //         "required": [
    //           "firstName",
    //           "lastName"
    //         ],
    //         "properties": {
    //           "password": {
    //             "type": "string",
    //             "title": "Password",
    //             "minLength": 3
    //           },
    //           "telephone": {
    //             "type": "string",
    //             "title": "Telephone",
    //             "minLength": 10
    //           }
    //         }
    //       }
    // }
    
    // const convertSchemaToTabArray = (schemaArray) => {
    //     let tabArray:any= [];
       
    //     return tabArray;
    // }

    return (
    // <>
    //     {/* <FormJson
    //             uiSchema={uiSchema}
    //               schema={schema}
    //               widgets={widgets}
    //               validator={validator}
    //               templates={templates}
    //               onChange={(e) => {
    //                 //console.log("OnChanges", e);
    //               }}
    //               onSubmit={() => {
    //                 console.log("onSubmit");
    //               }}
    //               onError={() => {
    //                 console.log("onError");
    //               }}
    //             /> */}
    //          <Tabs
    //     defaultActiveKey="0"
    //     activeKey={`${activeTab}`}
    //     style={{ marginBottom: 32 }} >
    //       {   Object.keys(schema).map((keyVal,index) => (
    //         <Tabs.TabPane tab={`TAB - ${index}`} key={index}>
    //             {keyVal}
    //             <FormJson
    //             uiSchema={uiSchema}
    //               schema={schema[keyVal]}
    //               widgets={widgets}
    //               validator={validator}
    //               templates={templates}
    //               onChange={(e) => {
    //                 //console.log("OnChanges", e);
    //               }}
    //               onSubmit={({formData}, e) => {
    //                 console.log("formData",formData);
    //                 console.log("e",e);
    //                 setActiveTab(activeTab+1);
    //               }}
    //               onError={() => {
    //                 console.log("onError");
    //               }}
    //             />
    //         </Tabs.TabPane>
    //         ))
            
    //     }        
    //     </Tabs>
    // </>
    <>
    <div style={{margin:"auto",padding:"10px"}}>
    <Card  style={{
      width: 1000,
      display:"flex",
      alignItems:"center"
    }}> 
     <div style={{maxWidth: '1000px', margin: '50px'}}>
      <h1 style={{ display:"flex",
      alignItems:"center"}}>Demo Form</h1>
        {/* <Form form={JSON} onSubmit={handleSubmit}/> */}
        
        <Form form={jsonForm} onSubmit={handleSubmit} />
      </div>
    </Card>
    </div>
    </>
    )

}

export default POC;