import { ReloadOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, notification } from "antd";
import { useState } from "react";
import { getUserDetails } from "shared/services/Utility";
import API_SERVICE from "shared/services/api-service";
import apporved from "../../../assets/images/emptyApproved.svg";
import redApporved from "../../../assets/images/emptyRedApproved.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IGetBureau {
  leadData: any;
  getBureauScore: any;
  genrateBureauScore: any;
  bureauColor: any;
  consetObj: any;
  bureauScoreStatus: any;
  setShowApplicantInfo: any;
  isApplicantModal: any;
  setIsApplicantModal: any;
}

const GetBureau: React.FC<IGetBureau> = (props: IGetBureau) => {
  const {
    leadData,
    getBureauScore,
    genrateBureauScore,
    bureauColor,
    bureauScoreStatus,
    consetObj,
    isApplicantModal,
    setIsApplicantModal,
    setShowApplicantInfo,
  } = props;

  const {appColors}: any = useSelector((state: RootState) => state.common);

  const btnPrimaryStyles : any = { '--btnColor' :appColors?.appPrimaryColor ?? "" };
  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <>
      <p>
        <span style={{ fontSize: "20px" }}>VAS Services</span>
        <span style={{ float: "right" }}>
          <Tooltip title="Refresh">
            <Button
              type="primary"
              className="refresh-btn dynamic-btn-primary"
              style={btnPrimaryStyles}
              onClick={() => {
                getBureauScore();
              }}
              shape="round"
              icon={<ReloadOutlined />}
              size={"small"}
            ></Button>
          </Tooltip>
        </span>
      </p>

      {consetObj?.hasOwnProperty("consentDate") &&
      bureauScoreStatus == "INITIATED" ? (
        <>
          <div className="success-bureau consent-bureau">
            <div style={{ padding: "8px" }}>
              <Button
                type="primary"
                size="small"
                className="retry-btn"
                onClick={() => genrateBureauScore()}
              >
                Retry
              </Button>
              <span style={{ fontSize: "17px", fontWeight: "400" }}>
               Credit Analysis
              </span>
              <p style={{ fontSize: "12px" }}>
                Consent Received: {consetObj?.consentDate}
              </p>
            </div>
          </div>
        </>
      ) : bureauScoreStatus == "COMPLETED" ? (
        <div
          className={
            bureauColor == "RED"
              ? "success-bureau bureau-color"
              : "success-bureau"
          }
        >
          <div style={{ padding: "8px" }}>
            <span style={{ float: "right", marginTop: "8px" }}>
              <img src={bureauColor == "RED" ? redApporved : apporved} alt="" />
            </span>
            <span style={{ fontSize: "17px", fontWeight: "400" }}>
              Credit Analysis
            </span>
            <p style={{ fontSize: "12px" }}>
              Consent Received: {consetObj?.consentDate}
            </p>
          </div>
        </div>
      ) : bureauScoreStatus == "INITIATED" ? (
        <div className="request-bureau" style={customStyles}>
          <div style={{ padding: "9px" }}>
            <p style={{ fontSize: "17px", fontWeight: "400", float: "left" }}>
              Credit Analysis
            </p>
            <div style={{ float: "right" }}>
              <span style={{ fontSize: "15px" }}>Request-Initiated </span>
              <br />
              <Button
                type="primary"
                size="small"
                className="retry-btn dynamic-btn-primary"
                style={btnPrimaryStyles}
                onClick={() => setIsApplicantModal(true)}
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Button
            type="primary"
            className="next-btn dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={() => setIsApplicantModal(true)}
          >
            Get Credit Analysis
          </Button>
        </>
      )}
      <Modal
        centered
        title=""
        style={{ marginBottom: "100px" }}
        width={350}
        footer={null}
        closable={false}
        visible={isApplicantModal}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          Please ensure that the details entered in the lead are the actual
          details of the borrower associated with the Credit score records.
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            size="large"
            className="modal-btn dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={() => setShowApplicantInfo(true)}
          >
            Yes
          </Button>
          <Button
            className="modal-btn dynamic-btn-default"
            size="large"
            style={btnDefaultBorderStyle}
            onClick={() => setIsApplicantModal(false)}
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default GetBureau;
