import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Form,
  notification,
  Switch,
  Popconfirm,
} from "antd";
import { FaSearch, FaPlus, FaEye, FaPen } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import type { ColumnsType } from 'antd/es/table';

import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { hasUserRole } from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { useDispatch } from "react-redux/es/exports";
import { setEmployeesPageNo, setEmployeesPageSize, setEmployeesSearchParam } from "shared/redux/employees-reducer";
import blackRefreshIcon from "../../../assets/images/blackReferesh.svg";
import {ReactComponent as IconEyeBorder} from "../../../assets/images/eye_border.svg";
import {ReactComponent as IconEditBorder} from  "../../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../../assets/images/delete_border.svg";
import { setScreenHeader } from "shared/redux/common-reducer";

const { Title } = Typography;
type Props = {};

const EmployeePage: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const [allEmployees, setAllEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [pages, setPagination] = useState();
  // const [pagesize, setPageSize] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  //const [search, setSearch] = useState();
  //const [searchValue, setSearchValue] = useState(null as any);
  //const [currentPage, setCurrentPage] = useState(1);
  //const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();

  const employees: any = useSelector((state: RootState) => state.employees);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const columns: ColumnsType<any> = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Mobile No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Email ID",
      dataIndex: "emailId",
      key: "emailId",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },

    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: any) => (
        <Switch
          style={customStyles}
          checked={record.active}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: 'center',
      width: 140,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon"
            style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEyeBorder />}
            onClick={() => goToLeadDetails(record)}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"
            icon={<IconEditBorder />}
            onClick={() => goToEdit(record)}
            hidden={
              !(
                userRolePermission["UpdateUser"] || userRolePermission["Admin"]
              )
            }
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={(event) => handleDelete(event, record)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
          >
             <Button
              style={{backgroundColor:"transparent", border:'none'}}
              size="small"
              type="text"
                icon={<IconDeleteBorder width={24} height={24} />}
                hidden={
                  !(
                    userRolePermission["UpdateUser"] ||
                    userRolePermission["Admin"]
                  )
                }
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Manage Employees"}));
    form.setFieldsValue({
      searchBox: employees.searchParam
    });
    getAllEmployees(employees.pageNo, employees.pageSize, employees.searchParam);
  }, []);

  const onChangeStatus = (event: any, item: any) => {
    item.active = event;
    setLoading(true);
    API_SERVICE.statusChange(item.userId, item.active)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          getAllEmployees(employees.pageNo, employees.pageSize, employees.searchParam);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleDelete = (event: any, item: any) => {
    item.active = false;
    setLoading(true);
    API_SERVICE.statusChange(item.userId, item.active)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          let page = null as any;
          if ((totalCount / 10) === parseFloat((employees.pageNo - 1) + "." + 1)) {
            //setCurrentPage(1);
            dispatch(setEmployeesPageNo(1));
            page = 1;
          } else {
            //setCurrentPage(employees.pageNo);
            dispatch(setEmployeesPageNo(employees.pageNo));
            page = employees.pageNo;
          }
          getAllEmployees(page, employees.pageSize, employees.searchParam);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllEmployees = (pageNo: number = 1, pageSize: any = null, searchVal: any = null) => {
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    setLoading(true);
    API_SERVICE.getEmployees(params)
      .then(({ data }) => {
        mapLeadsData(data);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data?.payload?.totalElements ?? 0);
    setAllEmployees(data?.payload?.content ?? []);
    setApiCount(1);
  };

  const addHandler = () => {
    history.push("/employees/add-employee");
  };

  const goToLeadDetails = (item: any) => {
    history.push({
      pathname: `/employees/${item.userId}`,
      state: { fullName: item.fullName },
    });
  };

  const goToEdit = (item: any) => {
    history.push({
      pathname: `/employees/edit/${item.userId}`,
      state: { fullName: item.fullName },
    });
  };

  const handleSearch = (event: any) => {
    //setCurrentPage(1);
    //setSearchValue(event.target.value);
    dispatch(setEmployeesPageNo(1));
    dispatch(setEmployeesSearchParam(event.target.value));
    getAllEmployees(1, employees.pageSize, event.target.value);
  };

  const handlePaginationChange = (pagination) => {
    let page = (pagination?.pageSize !== employees.pageSize) ? 1 : pagination.current;
    //setCurrentPage(page);
    //setPageSize(pagination.pageSize);
    dispatch(setEmployeesPageNo(page));
    dispatch(setEmployeesPageSize(pagination.pageSize));
    setTotal(pagination.total);
    getAllEmployees(page, pagination.pageSize, employees.searchParam);
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      <Form form={form}>
        <Row>
          <Col className="filter-bar" span={16}>
            <Form.Item name="searchBox">
              <Input
                size="large"
                className="search-box"
                placeholder="Search"
                allowClear
                prefix={<FaSearch />}
                onChange={debounce(handleSearch, 1000)}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="filter-bar filter-bar-right">
            <Button
              style={{ marginLeft: "80px" }}
              type="primary"
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: ""
                })
                //setSearchValue("");
                dispatch(setEmployeesSearchParam(""));
                dispatch(setEmployeesPageNo(1));
                getAllEmployees(1, employees.pageSize, "");
              }}
              shape="round"
              //icon={<ReloadOutlined />}
              size={"middle"}
            ><img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
            <Button
              type="primary"
              className="add-button dynamic-btn-primary"
              style={btnPrimaryStyles}
              onClick={addHandler}
              shape="round"
              icon={<FaPlus />}
              size={"middle"}
              disabled={!(userRolePermission["Admin"] || userRolePermission["CreateUser"])}
              hidden={
                !(
                  userRolePermission["CreateUser"] || userRolePermission["Admin"]
                )
              }
            >
              Add Employee
            </Button>
          </Col>
        </Row>
      </Form>
      <Row></Row>
      <Row>
        <Col span={24}>
          <Table
            className="dynamic-pagination-checkbox"
            style={customStyles}
            loading={loading}
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={allEmployees}
            size="small"
            onChange={handlePaginationChange}
            pagination={{
              current: employees.pageNo,
              pageSize: employees.pageSize,
              total: totalCount, // total count returned from backend
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmployeePage;
