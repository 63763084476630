import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import {ReactComponent as AddFileGray} from "../../assets/images/upload-file-gray.svg";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import type { UploadProps } from "antd/es/upload/interface";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

const AddNotice: React.FC = () => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([] as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Notices",
        backScreenPath: "/notices",
        screenTitle: "Add New Notice",
      })
    );
  }, []);

  const uploadProps: UploadProps = {
    listType: "text",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    fileList,
  };

  const beforeUpload = (file) => {
    if((file.size / 1024 / 1024) > 10.0) {
      notification.error({ message: 'Please upload .pdf file with size upto 10 MBs only' })
      setFileList(null);
      return;
    }
    let uploadExt = ['application/pdf']
    if (!uploadExt.includes(file.type)) {
      notification.error({ message: 'Please upload .pdf files only' })
      setFileList(null);
      return;
    }
    else {
      setFileList([file]);
      return false;
    }
  }

  const uploadDocumentHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          setImageUrl(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onUpload = useCallback((acceptedFiles: any) => {
    let uploadExt = ['application/pdf']
    if (!uploadExt.includes(acceptedFiles?.file?.type)) {
      setFileList(null);
      return;
    } else {
      setUploadedFile(acceptedFiles.file);
      uploadDocumentHandler(acceptedFiles.file);
    }

  }, []);

  const createPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }
    const data = {
      type: "NOTICE",
      title: formData.title,
      description: formData.description,
      documentDetail: [{
        documentName: formData?.Attachment?.file?.name,
        documentURL: imageUrl,
      }],
    };
    return data;
  };
 
  const onFinish = (values: any) => {

    const payload = createPayload(values);
    if (!fileList) {
      notification.error({ message: "please select notice document" });
      return;
    }
    API_SERVICE.addOfferOrNotice(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/notices");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <div className="add-notice-wrapper content-box">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          phoneCode: "+91",
          profession: "Builder",
          gender: "M",
        }}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Form.Item
              name="Attachment"
              label="Please upload (.pdf only)"
              rules={[
                {
                  required: true,
                  message: "Please Select Attachment ",
                },
              ]}
            >
              <Upload
                className="upload-wrapper"
                listType="picture"
                accept=".pdf"
                beforeUpload={beforeUpload}
                onChange={onUpload}
                {...uploadProps}
                maxCount={1}

              >
                <AddFileGray />
                Upload Attachment
              </Upload>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter Title",
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter title"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea
                className="custom-textarea"
                rows={8}
                placeholder="Please Enter Description"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {/* <Divider/> */}
          <Col>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddNotice;
