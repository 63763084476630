import React, { useEffect, useState } from "react";
import { Divider, Tag, Input, Typography } from "antd";
import { ReactComponent as ArrowRight } from "../../../../../assets/images/arrow_right.svg";
import moment from "moment";
import "./History.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";


const { TextArea } = Input;
const { Text } = Typography;
const { Title } = Typography;
interface IHistory {
  historyDetails?: any;
}

const History: React.FC<IHistory> = (props: IHistory) => {
  const { historyDetails } = props;

  const [leadHistory, setLeadHistory] = useState(null as any);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    setSortingHistory(historyDetails);
  }, [historyDetails]);

  const setSortingHistory = (historyDetails) => {
    let history: any = [];
    for (let key in historyDetails) {
      history = [...history, ...historyDetails[key]];
    }
    const sortedHistory = history.sort((a, b) =>
      a.statusChangedOn < b.statusChangedOn ? 1 : -1
    );
    setLeadHistory(sortedHistory);
  };

  const logoPrimaryStyles : any = {'--bgColor' :appColors?.appPrimaryColor ?? ""};
  const tagPrimaryLightStyle : any = {'--tagBgColor': appColors?.appPrimaryLightColor ?? ""};

  const renderValue = (key: string, value: any) => {
    if (value != null && typeof value === "object") {
      console.log("object: ", `${key}: ${value}`);
      return renderObj(key, value);
    }

    if (value == null || !key) {
      return <></>;
    }

    console.log("renderValue: ", `${key}: ${value}`);
    return (
      <div>
        {!isNaN(parseInt(key)) ? (
          <Text>{`${value}`}</Text>
        ) : (
          <Text>{`${key}: ${value}`}</Text>
        )}
      </div>
    );
  };
  const renderObj = (title, res) => {
    if (res == null) {
      return <></>;
    }

    // if (typeof res === 'string') {}
    if (["string", "number", "boolean"].includes(typeof res)) {
      const tmpTitle = (title?.length ?? 0) == 0 ? "Response" : title;
      return <div>{renderValue(tmpTitle, res)}</div>;
    }

    // console.log("renderObj: ", title, " --- ", res);
    const data = Object.keys(res);
    return (
      <div>
        <Text style={{ fontWeight: "bold", color: "black" }}> {!isNaN(parseInt(title)) ? '' : title} </Text>
        {data?.map((key: any) => {
          let value = res[key];
          if (Array.isArray(value)) {
            value = res[key]?.join(", ");
          }
          return renderValue(key, value);
        })}
      </div>
    );
  };
  const renderStatusupdate = (statusUpdate: any) => {
    console.log("statusUpdate: ", statusUpdate);
    let obj = statusUpdate;
    try {
      if (typeof statusUpdate === "string") {
        obj = JSON.parse(statusUpdate ?? "{}");
      }
    } catch (e) {
      obj = {};
    }

    console.log("obj: ", obj);

    // if (obj?.response) {
    //   let res = obj?.response;
    //   try {
    //     res = JSON.parse(obj?.response ?? "{}");
    //   } catch (error) { }
    //   console.log("res: ", res);
    //   return <div style={{ marginTop: '0px' }}> {renderObj("", res)}  </div>
    // } else {
    const data = Object.keys(obj);
    return (
      <div style={{ marginTop: "0px" }}>
        {data.map((key: any, index: any) => {
          // console.log("key --- : ", key, " -- ", obj[key])
          let res = obj[key];
          try {
            res = JSON.parse(obj?.[key] ?? "{}");
          } catch (error) {}
          return <div key={index}> {renderObj(key, res)} </div>;
        })}
      </div>
    );
    // }

    return <></>;
  };

  return (
    <>
      <div>
        <Divider style={{ marginBottom: "5px", marginTop: "10px" }} />
        <div className="timeline">
          {leadHistory?.map((item: any, i: number) => (
            <>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <div>
                  <p className="history-logo" style={logoPrimaryStyles}>
                    {item.changedByUser.fullName.charAt(0) +
                      item.changedByUser.fullName.charAt(1).toUpperCase()}
                  </p>
                </div>
                <div
                  style={{ display: "grid", width: "100%", marginLeft: "10px" }}
                >
                  <div className="history-header-name" style={logoPrimaryStyles}>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginBottom: "0px",
                        }}
                      >
                        {item.changedByUser.fullName
                          ? item.changedByUser.fullName
                          : ""}{" "}
                        {item?.dispositions?.[`${"Disposition 1"}`] || item?.dispositions?.[`${"Disposition 2"}`] ? (
                          <span> added a comment </span>
                        ) : (
                          <>
                            changed the Status:{" "}
                            <span className="history-status-name"  style={{'--bgColor' :appColors?.appSecondaryColor ?? ""}as any}>
                              {item.currentStatus ? item.currentStatus : ""}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      {item.statusChangedOn && (
                        <span className="history-date">
                          {moment(item.statusChangedOn)
                            .locale("en")
                            .format("DD MMM YYYY hh:mm A")}
                        </span>
                      )}
                    </div>
                  </div>

                  <div style={{ display: "grid" }}>
                    <div>
                      {item?.previousStage?.name && (
                        <>
                          <Tag className="history-tag" style={tagPrimaryLightStyle}>
                            {item?.previousStage?.name} {" "}
                            {item?.previousStage?.step?.name &&
                              ('/ ') + item?.previousStage?.step?.name}
                          </Tag>

                        </>
                      )}
                      {item?.previousStage?.name &&
                        item?.currentStage?.name && <ArrowRight />}
                      {item?.currentStage?.name && (
                        <>
                          <Tag className="history-tag" style={tagPrimaryLightStyle}>
                            {item?.currentStage?.name} {" "}
                            {item?.currentStage?.step?.name &&
                              ('/ ') + item?.currentStage?.step?.name}
                          </Tag>

                        </>
                      )}
                    </div>

                    {item?.dispositions?.[`${"Disposition 1"}`] && (
                      <span className="history-sub-details">
                        Disposition 1:{" "}
                        {item?.dispositions?.[`${"Disposition 1"}`]}
                      </span>
                    )}
                    {item?.dispositions?.[`${"Disposition 2"}`] && (
                      <span className="history-sub-details">
                        Disposition 2:{" "}
                        {item?.dispositions?.[`${"Disposition 2"}`]}
                      </span>
                    )}
                    {(item?.comments || item?.statusUpdate) && (
                      <span className="history-sub-details">
                        Message : <p dangerouslySetInnerHTML={{ __html:  item?.comments && item?.comments.replaceAll('\n', '</br>') }} />
                        {item?.statusUpdate && item?.statusUpdate !== "null" &&
                                  renderStatusupdate(item?.statusUpdate)}
                      </span>
                      
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
export default History;
