import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getEnumList } from "shared/services/Utility";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Form,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Modal,
  notification,
} from "antd";
import { PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";
import API_SERVICE from "shared/services/api-service";
import LeadDetailNew from "../LeadDetail/LeadDetailNew";
import { debounce } from "lodash";
import rightArrow from "../../../../assets/images/ph_arrow-right.svg";
import FormInput from "components/FormInput/FormInput";
import FormRadio from "components/FormRadio/FormRadioStart";
//import AddLeadV1 from "./AddLeadV1";
import "../AddLeadNew/AddLeanNew.scss";
import "../AddLeadNew/ProductRadioStart.scss";
import "../../../../components/FormRadio/FormRadioStart.scss";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "components/FormSelect/FormSelect";
import "../LeadDetail/EditLeadDetailNew.scss";
import { RootState } from "shared/redux/store";

export type viewTypeScreen = "createUser" | "loanTypeList" | "manageLead";

interface IEditDetailsNew {
  leadData: any;
  setIsEditDetails?: any;
  isEditDetails?: any;
  getLeadById?: any;
  setLeadData?: any;
}

const EditDetailsNew: React.FC<IEditDetailsNew> = (props: IEditDetailsNew) => {
  const {
    leadData,
    setIsEditDetails,
    isEditDetails,
    getLeadById,
    setLeadData,
  } = props;

  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null as any);
  const [searchedCustomers, setSearchedCustomers] = useState([] as any);
  const [allConnectors, setAllConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(null as any);
  const [viewModel, setViewModel] = useState(false);
  const [assignToBackOfficeData, setAssignToBackOfficeData] = useState(
    [] as any
  );
  const [selectedBackOfficeUser, setSelectedBackOfficeUser] = useState(
    null as any
  );
  const [isClearBackOfficer, setIsClearBackOficer] = useState(false);
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [selectedApplicationType, setSelectedApplicationType] = useState(leadData?.sourceOfCase ?? "");
  const [isDisableSelectProduct, setIsDisableSelectProduct] = useState(true);
  const [caseId, setCaseId] = useState("");
  const history = useHistory();
  const [editLeadForm] = Form.useForm();
  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const [selectedLeadApplicationType, setSelectedLeadApplicationType] =
    useState("");
  const [applicantTypesBusinessValue, setApplicantTypesBusinessValue] =
    useState("");
  const [applicantTypesIndividualValue, setApplicantTypesIndividualValue] =
    useState("");
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    setCaseId(leadData?.caseDetails?.caseId);
    setIsDisableSelectProduct(false);
    editLeadForm.setFieldsValue({
      // applicantType: leadData?.applicationType,
      //selectType: leadData?.sourceOfCase,
      // businessType: leadData?.applicationSubType,
      // mobileNumber: leadData?.contactDetails?.mobileNumber,
      // applicantCustomerName: leadData?.contactDetails?.firstName,
      assignTo: leadData?.caseDetails?.assignedToUserDetails?.fullName,
      selectType: leadData?.sourceOfCase,
      assignToBackOffice:
        leadData?.caseDetails?.backOfficeUserDetails?.fullName,
      tentativePayout:
        leadData.caseDetails.loanDetails.expectedInterestRate ?? 0,
      connectorName: leadData?.sourceOfCase == "CONNECTOR" ? 
        leadData?.caseDetails?.connectorDetails?.userDetails?.fullName : "",
    });
    dispatch(
      setScreenHeader({
        screenTitle: "Edit Lead",
      })
    );
  }, []);

  const onFinishForm = (values: any) => {
    const selfPayload = {
      loanType: leadData?.loanType,
      loanProductId: leadData?.loanProductId,
      sourceOfCase: selectedApplicationType ?? leadData?.sourceOfCase,
      assignToUserId: selectedAssignTo?.userId ?? leadData?.assignedToUserId,
      backOfficeUserId: selectedBackOfficeUser?.userId
        ? selectedBackOfficeUser?.userId
        : leadData?.caseDetails?.backOfficeUserDetails?.userId,
      assignToBackOffice: selectedBackOfficeUser?.userId,
      commissionDetail: {
        connectorName: values?.connector,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId:
        selectedConnector?.userDetails?.userId ??
        leadData?.caseDetails?.connectorDetails?.userDetails?.userId,
      customerId: selectedCustomer?.customerId || "",
      contactDetails: {
        gender: leadData?.contactDetails?.gender,
        firstName: leadData?.contactDetails?.firstName,
        middleName: leadData?.contactDetails?.middleName,
        lastName: leadData?.contactDetails?.lastName,
        dateOfBirth: leadData?.contactDetails?.dateOfBirth,
        emailId: leadData?.contactDetails?.emailId,
        mobileNumber: leadData?.contactDetails?.mobileNumber,
        pan: leadData?.contactDetails?.pan,
      },
      applicationType: leadData?.applicationTypeKey
        ? leadData?.applicationTypeKey
        : selectedLeadApplicationType,
      applicationSubType:
        selectedLeadApplicationType === "BUSINESS"
          ? values?.businessType
          : values?.individualType,
      entityOrEmployerName: "",
      loanDetails: {
        expectedInterestRate: values?.tentativePayout,
        loanAmount: leadData?.loanDetails?.loanAmount,
        loanTenureInYears: leadData?.loanDetails?.loanTenureInYears,
        monthlyInHandSalary: leadData?.loanDetails?.monthlyInHandSalary,
        currentEmis: leadData?.loanDetails?.currentEmis,
        educationLoanDetails: {
          admissionStatus: null,
          placeOfStudy: null,
          timeOfStudy: "",
        },
      },
    };
    editLeadNewDetails(selfPayload);
  };

  const editLeadNewDetails = (payload: any) => {
    API_SERVICE.editLead(leadData.caseDetails.caseId, payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsEditDetails(false);
          getLeadById();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSelectExistingCustomer = (event: any) => {
    const customers = [...searchedCustomers];
    const customer = customers.find(
      (item: any) => item?.customerId === event?.props?.children
    );
    setSelectedCustomer(customer);
  };

  const onSearchExistingCustomer = (e: any) => {
    API_SERVICE.searchCustomers(e).then(({ data }: any) => {
      setSearchedCustomers(data?.payload ?? []);
      editLeadForm.setFieldsValue({
        selfCustomerName: data?.payload?.at(0)?.contactDetails?.firstName,
      });
    });
  };

  const onSearchConnector = (e: any) => {
    const params = {
      name: e,
    };
    API_SERVICE.getConnector(params).then(({ data }: any) => {
      if (data.payload.content.length > 0) {
        const tmpConnectors = data.payload?.content?.filter(
          (item: any) => item?.userDetails?.active
        );
        setAllConnectors(tmpConnectors ?? []);
      }
    });
  };

  const onSelectConnector = (event: any) => {
    const connector = [...allConnectors];
    const connectorItem: any = connector.find(
      (item: any) => item.userDetails.userId === event?.props?.children
    );
    setSelectedConnector(connectorItem);
    editLeadForm.setFieldsValue({
      tentativePayout: connectorItem?.payoutPercentage,
    });
  };

  const searchAssignToBackOffice = (ev: any) => {
    if (!ev) {
      setIsClearBackOficer(true);
    }
    const params = {
      searchParam: ev,
      department: "Back Office",
    };
    API_SERVICE.getUsersByDepartment(params).then(({ data }) => {
      if (data) {
        setAssignToBackOfficeData(data.payload);
      }
    });
  };

  const selectBackOfficeUser = (item, opt) => {
    // setSelectedBackOfiiceUserId()
    setIsClearBackOficer(false);
    setSelectedBackOfficeUser(opt);
    console.log("opttt", opt);
  };

  const searchAssignTo = (ev: any) => {
    const params = {
      name: ev,
    };
    API_SERVICE.getUsersWithRole(params).then(({ data }) => {
      if (data) {
        setAssignToData(data.payload);
      }
    });
  };

  const valueExistingConnector = (connector) => {
    return (
      <span key={connector?.userDetails?.fullName}>
        {connector?.userDetails?.userId}
      </span>
    );
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  const onChangeSelectType = (e: RadioChangeEvent) => {
    setSelectedApplicationType(e?.target?.value);
  };

  const optionListSelfOrConnector = [
    {
      key: "SELF",
      value: "Self",
    },
    {
      key: "CONNECTOR",
      value: "Connector",
    },
  ];

  const renderConnectorForm = () => {
    return (
      <>
        <Form.Item
          className="custom-option-list"
          name="connectorName"
          label="Connector Name"
          rules={[
            {
              required: (selectedApplicationType || leadData?.sourceOfCase) == "CONNECTOR",
              message: "Please select connector",
            },
          ]}
        >
          <AutoComplete
            className="custom-auto-compleat"
            placeholder="Search connector"
            dropdownClassName="custom-option-list"
            onSelect={onSelectConnector}
            onSearch={onSearchConnector}
          >
            {allConnectors?.map((connector: any) => (
              <Select.Option
                key={connector.userDetails.userId}
                {...connector}
                value={valueExistingConnector(connector)}
              >
                <div>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={16}>
                      <span className="name">
                        {connector?.userDetails?.fullName}
                      </span>
                      <br />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={8}>
                      <span>
                        {connector?.userDetails?.phoneNumber.replace(
                          /\d(?=\d{4})/g,
                          "*"
                        )}
                      </span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                    <Col className="right" span={16}>
                      <span className="city">{connector?.address?.city}</span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                  </Row>
                </div>
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <FormInput
          name="tentativePayout"
          label="Tentative Payout %"
          placeholder="Enter payout %"
          removeDefaultCss={true}
          rules={[
            {
              required: false,
              message: "Please enter tentative payout %",
            },
            {
              pattern: /^\d*(\.)?(\d{0,2})?$/,
              message: "Please enter valid payout %",
            },
          ]}
        />
      </>
    );
  };

  const createUser = () => {
    const customStylesRadio: any = { '--bgColor': appColors?.appSecondaryColor ?? "" };
    return (
      <>
        <Form
          layout="vertical"
          onFinish={onFinishForm}
          form={editLeadForm}
          initialValues={{
            assignTo: userDetails?.userDetails?.fullName,
          }}
        >
          <Card className="edit-lead-card-new" bordered={false}>
            <div
              className=""
              style={{ padding: "5px", display: "table-caption" }}
            >
              <FormRadio
                label="Select"
                isRequired
                name="selectType"
                options={optionListSelfOrConnector}
                onChange={onChangeSelectType}
                classNameRadio="custom-radio-new"
                style={customStylesRadio}
                disabled={leadData?.sourceOfCase == "CONNECTOR"}
              />
              {(selectedApplicationType || leadData?.sourceOfCase) == "CONNECTOR"
                ? renderConnectorForm()
                : ""}
              <Form.Item
                label="Assign to sales user"
                name="assignTo"
                rules={[
                  {
                    required: true,
                    message: "Please select assign to sales user",
                  },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignTo, 1000)}
                  placeholder="Search assign to sales user"
                  dropdownClassName="custom-option-list"
                  onSelect={(item, opt) => {
                    setSelectedAssignTo(opt);
                    console.log("opet", opt);
                  }}
                >
                  {assignToData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.address?.city && item?.address?.city}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <Form.Item
                //   className="field-bg"
                label="Assign to Back office User"
                name="assignToBackOffice"               
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignToBackOffice, 1000)}
                  placeholder="Search assign to back office"
                  dropdownClassName="custom-option-list"
                  onSelect={selectBackOfficeUser}
                >
                  {assignToBackOfficeData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.designation && item?.designation}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>

              {/* <br /> */}

              <Form.Item style={{ marginTop: "30px" }}>
                <Space>
                  <Button
                    size="large"
                    className="button-submit dynamic-btn-default"
                    style={btnDefaultBorderStyle}
                    // htmlType="button"
                    onClick={() => setViewModel(true)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    className="button-submit dynamic-btn-primary"
                    style={btnPrimaryStyles}
                    type="primary"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Card>
        </Form>
      </>
    );
  };

  const renderModel = () => {
    return (
      <Modal
        width={300}
        visible={viewModel}
        closable={false}
        maskClosable={false}
        keyboard={false}
        footer={false}
        centered
      >
        <p className="msg">
          You will lose you entered data <br />
          if you wants to go Back
        </p>
        <div className="common-button">
          <Button
            size="large"
            className="button-submit dynamic-btn-default"
            style={btnDefaultBorderStyle}
            type="default"
            onClick={() => setViewModel(false)}
          >
            Cancel
          </Button>
          <Button
            size="large"
            className="button-submit dynamic-btn-primary"
            style={btnPrimaryStyles}
            type="primary"
            htmlType="submit"
            onClick={() => setIsEditDetails(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    );
  };

  return (
    <div className="">
      {createUser()}
      {renderModel()}
      {loading && <AppLoading />}
    </div>
  );
};
export default EditDetailsNew;
