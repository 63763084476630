import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import add from "../../../assets/images/add.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Option } = Select;
const { Title, Text } = Typography;

type Props = {};

const AddLender: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [fiList, setFiList] = useState([] as any);
  const [pIDetailList, setPIDetailList] = useState([] as any);
  const [hubList, setHubList] = useState([] as any);
  const [products, setProducts] = useState([] as any);
  const [regionalDSAID, setRegionalDSAID] = useState([""]);
  const [selectedDSAId, setSelectedDSAId] = useState(1);
  const [showDSAIdView, setShowDSAIdView] = useState(false);
  const [routingMode, setRoutingMode] = useState("API");
  const [selectedLenderId, setSelectedLenderId] = useState(undefined);
  const [selectedLoanProductCode, setSelectedLoanProductCode] =
    useState<any>(undefined);
  const [selectedHub, setSelectedHub] = useState(null as any);
  const [productList, setProductsList] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [consentRequired, setConsentRequired] = useState(true);
  const [consentType, setConsentType] = useState('GLOBAL') as any;
  const [addLenderForm] = Form.useForm() as any;
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Lender",
        backScreenPath: "/lenders",
        screenTitle: "Add New Lender",
      })
    );
  }, []);

  const addLender = (values: any) => {
    if (routingMode == "API") {
      updatePartnerIdentificationDetails(values);
    } else {
      addLenderHandler(values);
    }
  };

  const addLenderHandler = (values: any) => {
    const selectedBank = fiList.find(
      (item: any) => item.financierName === values.bankName
    );
      const payload = {
      active: values.status,
      consentType     : (consentType==="Noconsent"?null:consentType),
      consentRequired : (consentType==="Noconsent"?false:true),
      finInfoId: selectedBank.fiId,
      hub: values.hub,
      name: values.bankName,
      products: [selectedLoanProductCode.loanProductName],
      productId: selectedLoanProductCode.loanProductId,
      pinCode: values.pinCode,
      city: values.city,
      submissionType: routingMode,
    };
    API_SERVICE.addLender(payload)
      .then(({ data }) => {
        if (data) {
          if (data.message) {
            notification.success({ message: data.message });
          }
          const tmpList = [...productList];
          tmpList.push({
            ProductName: selectedLoanProductCode.loanProductName,
            RoutingMode: routingMode,
          });
          setProductsList(tmpList);
          const tmpReset = ["product",
            "apikey",
            "apiid",
            "copyconfiguration",
            "hub",
            "pinCode",
            "city",
          ]
          pIDetailList?.map((item) => {
            tmpReset.push(item.key);
          })
          addLenderForm.resetFields(tmpReset);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  useEffect(() => {
    getFiList();
  }, []);

  const getFiList = () => {
    API_SERVICE.getFiList({ pageSize: 500, financierCategory:"LENDER" }).then(({ data }) => {
      if (data?.payload.content) {
        setFiList(data.payload.content);
        setLoading(false);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const updatePartnerIdentificationDetails = (values) => {
    const keys = Object.keys(values);
    const filterDataObject = [] as any;

    pIDetailList?.map((data) => {
      let filterData = keys.filter((item) => item === data?.key);
      filterDataObject.push({
        key: filterData[0],
        value: values[filterData[0]],
      });
    });

    const params = {
      lenderId: selectedLenderId,
      productIds: [selectedLoanProductCode.loanProductId],
      partnerIdentificationKeyDetail: {
        partnerIdentificationDetails: filterDataObject,
      },
    };

    API_SERVICE.updatePartnerIdentificationDetails(
      selectedLenderId,
      params
    ).then(({ data }) => {
      addLenderHandler(values);
    }).catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
  };

  const getPartnerIdentificationDetails = (lenderId, loanProductCode) => {
    API_SERVICE.getPartnerIdentificationDetails(
      lenderId,
      loanProductCode.loanProductId
    ).then(({ data }) => {
      setPIDetailList(data?.payload?.partnerIdentificationDetails ?? []);
    });
  };

  const changeBankHandler = (value: any) => {
    let fiItem = fiList.find((item) => item.financierName == value);
    setHubList(fiItem.hubDetails);
    setProducts(fiItem?.lenderLoanDefinition?.allLoanTypes);
    setSelectedLenderId(fiItem.fiId);
    setPIDetailList([]);
    setSelectedLoanProductCode(undefined);
    // if (selectedLoanProductCode) {
    //   getPartnerIdentificationDetails(fiItem.fiId, selectedLoanProductCode);
    // }
    addLenderForm.setFieldsValue({
      product: null
    })
  };

  const changeProductHandler = (value: any) => {
    const productFiltered = products?.filter(
      (item) => item.loanProductId == value
    );
    if ((productFiltered?.length ?? 0) > 0) {
      setSelectedLoanProductCode(productFiltered[0]);
      if (selectedLenderId) {
        getPartnerIdentificationDetails(selectedLenderId, productFiltered[0]);
      }
    }
  };

  const changeHubHandler = (value: any) => {
    let selectedHub = hubList.find((item) => item.hubName == value);
    addLenderForm.setFieldsValue({
      pinCode: selectedHub?.pinCode,
    });
    setSelectedHub(selectedHub);
  };

  const actionAdd = () => {
    setRegionalDSAID([...regionalDSAID, ""]);
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "ProductName",
      key: "ProductName",
      render: (text) => <p style={{ color: "blue" }}>{text}</p>,
    },
    {
      title: "Routing Mode",
      dataIndex: "RoutingMode",
      key: "RoutingMode",
    },
  ];

  const actionSkip = () => {
    setShowDSAIdView(false);
  };

  const btnRoundRadioStyles: any = {'--borderColor': appColors?.appPrimaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};

  const renderDSAIdView = () => {
    return (
      <>
        <Form.Item name="dsaId" style={{ marginTop: "5px" }}>
          <Radio.Group        
            name="dsaId"
            onChange={(ev) => {
              setSelectedDSAId(ev.target.value);
            }}
            // value={selectedDSAId}
            defaultValue={selectedDSAId}
          >
            <Radio value={1}>{"Global DSA ID"}</Radio>
            <Radio value={2}>{"Regional DSA ID"}</Radio>
          </Radio.Group>
        </Form.Item>

        <Text style={{ paddingTop: "20px", fontSize: "14px" }}>
          {selectedDSAId == 1
            ? "Do you have a lender issued DSA ID? if Yes, please enter the details below."
            : "Do you have DSA ID? if Yes, select one"}
        </Text>

        {selectedDSAId == 1 ? (
          <Form.Item
            className="field-bg-border"
            name="globalDsaId"
            style={{ marginTop: "5px" }}
            rules={[{ required: true, message: "Please enter Global DSA ID" }]}
          >
            <Input className="custom-input" placeholder="Enter Global DSA ID" />
          </Form.Item>
        ) : (
          regionalDSAID?.map((item, index) => {
            return (
              <Row gutter={[8, 8]}>
                <Col span={9}>
                  <Form.Item
                    className="field-bg-border"
                    name={`hubName-${index}`}
                    rules={[
                      { required: true, message: "Please enter hub name" },
                    ]}
                  >
                    <Select
                      showSearch
                      className="custom-select"
                      placeholder="Hub Name"
                      optionFilterProp="children"
                      onChange={(value, option) => changeHubHandler(value)}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                    >
                      {hubList?.map((item: any, i: number) => (
                        <Option key={i} value={item.hubName}>
                          {item.hubName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={13}>
                  <Form.Item
                    className="field-bg-border"
                    name={`regionalDsaid-${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Regional DSA ID",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input-number"
                      name="payoutAmount"
                      placeholder="Enter Regional DSA ID"
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    className="btn-add"
                    hidden={index != 0}
                    onClick={actionAdd}
                  >
                    <img src={add} alt="" width={20} />
                  </Button>
                </Col>
              </Row>
            );
          })
        )}

        <Form.Item style={{ marginTop: "50px" }}>
          <Space className="buttons">
            <Button
              size="large"
              //   loading={loading}
              type="primary"
              htmlType="submit"
            >
              Continue
            </Button>
            <Button size="large" htmlType="button" onClick={() => actionSkip()}>
              Skip
            </Button>
          </Space>
        </Form.Item>
      </>
    );
  };

  const renderOther = () => {
    return (
      <>
        <Form.Item
          className="field-bg-border"
          name="product"
          label="Product"
          rules={[{ required: true, message: "Please enter product name" }]}
        >
          <Select
            showSearch
            className="custom-select"
            placeholder="Product"
            optionFilterProp="children"
            onChange={(value, option) => changeProductHandler(value)}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(input?.toLowerCase())
            }
          >
            {products?.map((product: any, i: number) => (
              <Option key={i} value={product.loanProductId}>
                {product.loanProductName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="routingMode"
          style={{ marginTop: "5px" }}
          label="Select Case Routing Mode"
        >
          <Radio.Group
            defaultValue={"API"}
            className="dynamic-radio"
            style={btnRoundRadioStyles}
            onChange={(e) =>{
              setRoutingMode(e?.target?.value)
              setConsentType(e?.target?.value==="API"?'GLOBAL':"Noconsent")
            }
            }
            name="routingMode"
          >
            <Radio value={"API"} checked>
              API
            </Radio>
            <Radio value={"Automation"}>Automation</Radio>
            <Radio value={"Login"}>Login</Radio>
            <Radio value={"Weblink"}>Weblink</Radio>
          </Radio.Group>
        </Form.Item>

        {routingMode == "API" ? (
          <>
            {pIDetailList?.map((item) => {
              return (
                <Form.Item
                  className="field-bg-border"
                  name={item.key}
                  label={item.key}
                  rules={[
                    { required: true, message: `Please enter ${item.key}` },
                  ]}
                >
                  <Input
                    placeholder={`Enter ${item.key}`}
                    className="custom-input"
                  />
                </Form.Item>
              );
            })}

            {/* <Form.Item
              className="field-bg-border"
              name="copyconfiguration"
              label="Copy Configuration From"
            >
              <Select
                showSearch
                className="custom-select"
                placeholder="Select Copy Configuration"
              >
                {productList.map((item: any, i: number) => (
                  <Option key={i} value={item.ProductName}>
                    {item.ProductName} - {item.RoutingMode}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
          </>
        ) : (
          <>
            <Form.Item
              className="field-bg-border"
              label="Hub"
              rules={[{ required: true, message: "Please enter hub" }]}
              name="hub"
            >
              <Select
                showSearch
                className="custom-select"
                placeholder="Hub"
                optionFilterProp="children"
                onChange={(value, option) => changeHubHandler(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {hubList?.map((item: any, i: number) => (
                  <Option key={i} value={item.hubName}>
                    {item.hubName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="field-bg-border"
              name="pinCode"
              label="Pin Code"
            >
              <Input
                readOnly
                className="custom-input"
                placeholder="Please enter pincode"
              />
            </Form.Item>
            <Form.Item
              className="field-bg-border"
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter city" }]}
            >
              <Input placeholder="Please enter city" className="custom-input" />
            </Form.Item>
          </>
        )}
       {/*<Form.Item
          initialValue={true}
          name="consent"
          label="Consent"
          style={{ color: "green" }}
        >
          <Switch onChange={handleConsent}/>
      {consentRequired && (*/}
          <Radio.Group
           //defaultValue={routingMode == "API" ? "GLOBAL": "Noconsent"}
            value={consentType}
            className="dynamic-radio"
            style={btnRoundRadioStyles}
            onChange= { (e) => {
                                setConsentType(e?.target?.value) 
                                console.log(e?.target?.value)
                                setConsentRequired(e?.target?.value=="Noconsent")
                              
                      }}
            name="consentType">
            <Radio value = {"Noconsent"}>No Consent</Radio>
            <Radio value = {"GLOBAL"}>Global</Radio>
            <Radio value = {"INDIVIDUAL"}>Individual</Radio>
          </Radio.Group>
        {/*  )          
          }
        </Form.Item>*/}

        <Form.Item
          initialValue={true}
          name="status"
          label="Status"
          style={{ color: "green" }}
        >
          <Switch defaultChecked style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}/>
        </Form.Item>

        <Form.Item style={{ marginTop: "50px" }}>
          <Space className="buttons">
            <Button
              size="large"
              //   loading={loading}
              type="primary"
              htmlType="submit"
              className="dynamic-btn-primary"
              style={btnPrimaryStyles}
            >
              Add
            </Button>
            <Button
            className="dynamic-btn-default"
            style={btnDefaultBorderStyle}
              size="large"
              htmlType="button"
              onClick={() => addLenderForm.resetFields()}
            >
              Clear
            </Button>
            <Button
               className="dynamic-btn-default"
               style={btnDefaultBorderStyle}
              size="large"
              htmlType="button"
              onClick={() => history.goBack()}
            >
              Finish
            </Button>
          </Space>
        </Form.Item>
      </>
    );
  };

  return (
    <div className="content-box">
      <Form
        size="large"
        layout="vertical"
        form={addLenderForm}
        autoComplete="off"
        onFinish={addLender}
      // initialValues={{ ...formData }}
      >
        <Row gutter={[64, 16]}>
          <Col span={10}>
            <Form.Item
              className="field-bg-border"
              name="bankName"
              label="Lender Name"
              rules={[{ required: true, message: "Please enter bank name" }]}
            >
              <Select
                showSearch
                className="custom-select"
                loading={loading}
                placeholder="Lender Name"
                optionFilterProp="children"
                onChange={(value, option) => changeBankHandler(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {fiList?.sort(function (a: any, b: any) {
                  if (a.financierName.toLowerCase() < b.financierName.toLowerCase()) return -1;
                  if (a.financierName.toLowerCase() > b.financierName.toLowerCase()) return 1;
                  return 0;
                })
                  ?.map((item: any, i: number) => (
                    <Option key={i} value={item.financierName}>
                      {item.financierName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {showDSAIdView ? renderDSAIdView() : renderOther()}
          </Col>

          <Col span={14}>
            {!showDSAIdView && (
              <Card className="Added-Product-List">
                <Title level={3}>Added Product List</Title>

                <Table
                  scroll={{ y: 300 }}
                  bordered={false}
                  pagination={false}
                  dataSource={productList}
                  columns={columns}
                ></Table>
              </Card>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddLender;
