import { CloseCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Col, Row, Steps } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import MapImportBulkUpload from "./AllBulkUploadSteps/map-import-bulkupload";
import StartImportBulkUpload from "./AllBulkUploadSteps/start-import-bulkupload";
import SuccessImportBulkUpload from "./AllBulkUploadSteps/success-import-bulkupload";
import UploadImportBulkUpload from "./AllBulkUploadSteps/upload-import-bulkupload";
import 'antd/dist/antd.css';
import circle from "../../../assets/images/payouts/stepCircle.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";


type Props = {};
const { Step } = Steps;

const ImportBulUploadStep: React.FunctionComponent<{setBulkUploadExecuteData : any, setFileName : any, setStatus: any}> = (props) => {


    const [activeStepKey, setActiveStepKey] = useState(0);
    const [bulkUploadEcecuteData, setBulkUploadExecuteData] = useState([] as any);
    const [fileName, setFileName] = useState(null as any);
    const [status, setStatus] = useState("SUCCESS");
    const { appColors }: any = useSelector((state: RootState) => state.common);
    // const [showStep, setShowStep] = useState(true);
    const onChangeImportStep = (key: any) => {
        // setActiveStepKey(key);
    }
   const customStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };
    return (
        <>

            <div className="content-box">
                <div>
                    <Row>
                        <Col span={3}><label>{" Import files "}</label></Col>
                        <Col span={18}>
                              <Steps current={activeStepKey} progressDot
                                 onChange={onChangeImportStep}
                                 size={"small"}
                                 className="dynamic-Step"
                                 style={customStyles}
                            >
                                <Step title="UPLOAD"/>
                                {/* <Step title="UPLOAD" /> */}
                                <Step title="MAP" />
                                <Step title={status.replace('_'," ")} />
                            </Steps>
                        </Col>
                        <Col span={3}> <label>{" step " + (activeStepKey ) + " of 3"}</label></Col>
                    </Row>
                </div>
            </div>


            <div style={{ marginTop: "50px" }}>
                <Content>
                    {activeStepKey === 0 && <StartImportBulkUpload setActiveStepKey={setActiveStepKey} setFileName={setFileName}/>}
                    {/* {activeStepKey === 1 && <UploadImportBulkUpload setActiveStepKey={setActiveStepKey} fileName={fileName}/>} */}
                    {activeStepKey === 2 && <MapImportBulkUpload setActiveStepKey={setActiveStepKey} setBulkUploadExecuteData={setBulkUploadExecuteData} setStatus={setStatus}/>}
                    {activeStepKey === 3 && <SuccessImportBulkUpload setActiveStepKey={setActiveStepKey} bulkUploadEcecuteData={bulkUploadEcecuteData}/>}
                </Content>
            </div>

            
        </>
    );
}

export default ImportBulUploadStep;