import * as React from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  notification,
  Card,
  Typography,
  Row,
  Col,
  Button,
  Checkbox,
} from "antd";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import iconRight from "../../assets/images/tickRight.svg";
// import LoginForm from "./LoginForm";

import "./template.scss";
import { useTranslation } from "react-i18next";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "shared/services/api-service";

import Logo from "assets/images/logo.png";

const { Title, Paragraph } = Typography;

interface Props {
  location: any;
}
export default function Login({ location }: Props) {
  let history = useHistory();
  const { t } = useTranslation();
  const [fields, setFields] = useState([] as any[]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [consentSubmitted, setConsentSubmitted] = useState(false);

  const { caseID } = useParams() as any;
  const { from } = { from: { pathname: "/dashboard" } };

  const getConsert = () => {
    API_SERVICE.moneyWideConsent(caseID).then(({ data }) => {
      if (data) {
        if(data?.statusCode === 200) {
          setConsentSubmitted(true);
        }
      }
    }).catch((e) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
  };
  useEffect(() => {
    API_SERVICE.moneyWideCheckConsent(caseID).then(({ data }) => {
      if (data) {
        if(data?.payload) {
          setConsentSubmitted(true);
        }
      }
    })
  })

  const onChangeCoApplicantDetails = (e) => {
    setBtnDisable(!e?.target?.checked);
  };
  return (
    <>
      <div className="moneyWideTemplate" style={{ minHeight: consentSubmitted ? '70%' : '100%', }}>
        <div className="login-wrapper">
          {/* <div className="logo">
            <img src={Logo} alt="Logo" />
          </div> */}
          <>
            
              {consentSubmitted ? (
                <Card className="custom-app-card">
                <div className="lastScreen">
                <img src={iconRight} className="rightTickIcon" alt="" />
                  <span className="subLabel">Thank you for your consent!</span>
                </div>
                </Card>
              ) : (
              //   <Card className="custom-app-card1">
              //   <div
              //   style={{
              //     padding: "5px",
              //     height: "62vh",
              //     overflow: "auto",
              //   }}
              // >
              //   <Title level={5}>TERMS OF USE (FOR CUSTOMERS)</Title>
              //   <Paragraph>
              //     <pre
              //       style={{
              //         wordBreak: "break-word",
              //         backgroundColor: "transparent",
              //         border: "none",
              //       }}
              //     >
              //       TERMS OF USE (FOR CUSTOMERS) This document is a
              //       computer-generated electronic record published in terms of
              //       Rule 3 of the Information Technology (Intermediaries
              //       Guidelines) Rules, 2011 read with Information Technology
              //       Act, 2000 and does not require any physical or digital
              //       signature. This document applies to the access and use of
              //       the website (www.OneInfinity.in), software, mobile app,
              //       technology platform, telecom network and any such
              //       facilitation resources as may be offered (“OneInfinity
              //       Platform”). The OneInfinity Platform is owned by One Arc
              //       Labs Private Limited, a company incorporated under the
              //       Companies Act, 2013 and having its registered office at 120,
              //       Damji Shamji Udyog Bhavan, Veera Desai Road, Andheri West,
              //       Mumbai 400053 (”OneInfinity”). The OneInfinity Platform acts
              //       as in intermediary marketplace platform which offers and
              //       sells, various financial products such as credit cards,
              //       personal loans, SME loans, home loans, LAP, insurance
              //       products, etc and any such products, built and/or offered by
              //       partner banks and institutions/NBFCs, (“Financial Products”)
              //       to Platform users from time to time. These Platform related
              //       terms (“Terms”) are applicable to you (hereinafter referred
              //       to as “You” or “Your” or “Yourself”) when You use or access
              //       the OneInfinity Platform or any part thereof in anyway,
              //       including, without limitation, using any information,
              //       content, services, etc., available therein, and You agree to
              //       and be bound by them. If You do not agree or accept any of
              //       the Terms herein, please do not access/use the OneInfinity
              //       Platform in any way whatsoever. In order to access/use the
              //       OneInfinity Platform and to avail the Financial Products
              //       therefrom, You are required to first complete the
              //       registration process as provided on the OneInfinity
              //       Platform. BY CLICKING THE ACCEPTANCE BUTTON OR BY ACCESSING,
              //       USING OR INSTALLING ANY PART OF THE ONEINFINITY PLATFORM,
              //       YOU, EXPRESSLY AGREE TO AND FULLY CONSENT TO BE BOUND BY
              //       THESE TERMS. IF YOU DO NOT AGREE TO ANY OF THE TERMS HEREOF,
              //       PLEASE DO NOT ACCESS/USE ANY PART OF THE ONEINFINITY
              //       PLATFORM. You acknowledge that You have fully read as well
              //       as fully understood all the terms of these Terms and fully
              //       agree to be bound by them as well as the additional terms,
              //       if any, including but not limited to Our Privacy Policy,
              //       accessible at the link
              //       [https://www.OneInfinity.in/privacy-policy], which is deemed
              //       incorporated in these Terms by reference and which forms an
              //       integral part thereof. GENERAL 1.1 By accessing the
              //       OneInfinity Platform and by creating an account with
              //       OneInfinity, You are accepting these Terms and You represent
              //       and warrant that You have all the right, authority, and
              //       capacity to enter into these Terms. 1.2 In order to use the
              //       OneInfinity Platform and avail the Financial Products and
              //       other services as available therefrom, You must register
              //       with OneInfinity by creating Your personal account on the
              //       Platform by providing certain personal and non-personal
              //       information about Yourself as prompted by the OneInfinity
              //       Platform’s registration form. Apart from taking personal
              //       information that directly identifies You. You represent and
              //       warrant that: (a) all required registration as well as
              //       personal information You submit shall always be true and
              //       accurate; and (b) You will maintain the accuracy of such
              //       information; else be liable for any risks and consequences
              //       arising therefrom. Without limiting the generality of the
              //       foregoing, You agree to provide OneInfinity with any
              //       identification documents which OneInfinity might request
              //       from You from time to time for the purposes of verifying
              //       Your identity. If You provide any information that is
              //       untrue, inaccurate, not updated or incomplete (or becomes
              //       untrue, inaccurate, not updated or incomplete), or
              //       OneInfinity has reasonable grounds to suspect that such
              //       information is untrue, inaccurate, not updated or
              //       incomplete, OneInfinity shall have the right to suspend or
              //       terminate Your account and/or refuse any and all current or
              //       future use of the OneInfinity Platform (or any part
              //       thereof). 1.3 You may delete Your account at any time, for
              //       any reason, by emailing OneInfinity at info@OneInfinity.in.
              //       You are solely responsible for maintaining the
              //       confidentiality of Your OneInfinity Platform account login
              //       information and are fully responsible for any and all
              //       activities that take place under Your said account. You
              //       agree to immediately notify OneInfinity of any unauthorized
              //       use or suspected unauthorized use or disclosure of Your said
              //       account or any other breach of security thereof. We cannot
              //       and will not be held liable for any loss or damage arising
              //       from Your failure to comply with the above requirements and
              //       for any unauthorized use or disclosure of Your account
              //       details. 1.4 You shall create only one account with
              //       OneInfinity and shall not directly or indirectly create more
              //       than one account. 1.5 You further represent and warrant that
              //       You are of at least 18 years of age in order to be competent
              //       to contract and enter into these Terms and abide by them.
              //       1.6 Subject to the terms of these Terms, OneInfinity grants
              //       You a non-transferable and non-exclusive, license to use the
              //       OneInfinity Platform only for Your personal and
              //       non-commercial use and for the purposes as envisaged under
              //       these Terms. 1.7 The rights granted to You in these Terms
              //       are subject to the following restrictions: (a) You shall not
              //       license, sell, rent, lease, transfer, assign, distribute,
              //       customize, or otherwise commercially exploit the OneInfinity
              //       Platform, or otherwise host or sub-license the same or any
              //       part thereof; (b) You shall not modify, make derivative
              //       works of, adapt, translate, disassemble, reverse engineer or
              //       decompile any part of the OneInfinity Platform, or otherwise
              //       attempt to discover the source code therein or any part
              //       thereof; (c) You shall not access the OneInfinity Platform
              //       in order to build or promote a similar or competitive
              //       website/platform; (d) You shall not infringe or violate the
              //       Intellectual Property Rights (as defined later) in the
              //       OneInfinity Platform in any way whatsoever; and (e) You
              //       shall not copy, reproduce, distribute, republish, download,
              //       display, post or transmit the OneInfinity Platform or any
              //       part thereof in any form or by any means. 1.8 Any future
              //       release, update or other addition to functionality of the
              //       OneInfinity Platform shall be at OneInfinity’s sole
              //       discretion and shall always be subject to the terms of these
              //       Terms and as may be amended from time to time. You agree
              //       that OneInfinity will not be liable to You or to any third
              //       party for any such future release, update, or other addition
              //       to functionality or for any modification, suspension or
              //       discontinuance of the OneInfinity Platform or any part
              //       thereof. 1.9 All Intellectual Property Rights and other
              //       proprietary rights in and to the OneInfinity Platform vests
              //       with OneInfinity and/or its licensors. The provision of the
              //       OneInfinity Platform does not transfer to You or to any
              //       third party, any rights, title and/or interest in or to such
              //       Intellectual Property Rights. 1.10 The OneInfinity Platform
              //       may use third party vendors and service providers and their
              //       payments gateways for accepting payments therefrom on the
              //       OneInfinity Platform. You hereby authorize OneInfinity and
              //       the said third party service providers to run all accepted
              //       payment authorizations provided by You, to store your
              //       payment card and banking and other financial details and to
              //       charge Your payment card/account for the services fees and
              //       any other amounts owed under these Terms. To the extent
              //       permitted by applicable law and subject to OneInfinity’s
              //       Privacy Policy accessible at the link
              //       [https://www.OneInfinity.in/privacy-policy], You acknowledge
              //       and agree that OneInfinity may use the said third party
              //       vendors and service providers to process payments and manage
              //       Your payment method information. By providing the payment
              //       method information, You represent, warrant and covenant that
              //       You: (a) are legally authorized to provide such information;
              //       and (b) legally authorized to make payments using the
              //       payment methods available on the OneInfinity Platform. When
              //       You authorize a payment using any acceptable payment method
              //       via the OneInfinity Platform, You further represent and
              //       warrant that there are or will be sufficient funds or credit
              //       available to complete the payment using the designated
              //       payment method. To the extent that any amounts owed under
              //       these Terms cannot be collected from Your payment method(s),
              //       You will be solely responsible for paying such amounts by
              //       other means. 1.11 By making use of the OneInfinity Platform,
              //       and furnishing Your personal details, You hereby agree that
              //       You are interested in knowing more or availing and/or
              //       purchasing various Financial Products and availing various
              //       services, offers, campaigns or other promotional material
              //       that OneInfinity or any other third party may
              //       offer/provide/share/send You from time to time through any
              //       means, including but not limited to telephone, SMS, e-mail,
              //       Whatsapp or any other messaging service/mobile application
              //       or any other physical, electronic or digital means/mode .
              //       You hereby agree that OneInfinity may contact You either
              //       electronically or through phone, to understand Your interest
              //       in such selected products and services and to fulfil Your
              //       requirements or complete Your application. Further, You also
              //       expressly agree and authorize OneInfinity and its partners,
              //       service providers, advisors, vendors and other third parties
              //       to contact You for the purpose of offering or inviting Your
              //       interest in availing various other products/services offered
              //       by third parties, or for sending other marketing campaigns,
              //       offers, or any other information either on the OneInfinity
              //       Platform or through various communication modes. You agree
              //       and consent to receive communications relating to all of the
              //       foregoing and expressly waive any registration or preference
              //       made under DND/NCPR list under the applicable TRAI
              //       regulations in force. 1.12 You agree and acknowledge that
              //       for undertaking any financial transaction through the
              //       OneInfinity Platform, OneInfinity may undertake OneInfinity
              //       Platform’s client/customer/user’s due diligence measures and
              //       seek mandatory information required for Know-Your-Customer
              //       (“KYC”) purpose which, as a customer, You are obliged to
              //       give, while facilitating Your request for availing any of
              //       the Financial Products in accordance with applicable law and
              //       regulations. OneInfinity may obtain sufficient information
              //       to establish, to its satisfaction or its partner
              //       banks/financial institutions/NBFCs, the identity of each
              //       client/customer/user, and to ascertain the purpose of the
              //       intended nature of the relationship between You and them.
              //       You agree and acknowledge that OneInfinity can undertake
              //       enhanced due diligence measures (including any
              //       documentation), to satisfy itself relating to such due
              //       diligence requirements in line with the requirements and
              //       obligations under applicable laws and regulations. 1.13 You
              //       agree and authorise OneInfinity to share Your personal
              //       information with the credit bureau or banks, financial
              //       institutions or NBFCs for the purpose of assessment of Your
              //       eligibility for the Financial Products and services offered,
              //       directly or indirectly, on the OneInfinity Platform. The
              //       usage of the OneInfinity Platform may also require You to
              //       provide consent for keying in your personal information
              //       (including but not limited to any personal data or sensitive
              //       personal data as defined under applicable law) or to
              //       authorize OneInfinity to derive Your data/information from
              //       any source or public registry, as may be necessary to
              //       complete Your profile or Your KYC application on the
              //       OneInfinity Platform, conduct due diligence on You, assess
              //       Your eligibility for the Financial Products and services,
              //       undertaking KYC checks and/or to process Your application
              //       through the OneInfinity Platform. Your personal information
              //       may also be used or shared with third parties, including but
              //       not limited to OneInfinity’s vendors, service providers,
              //       analytics and research partners in India and abroad, with
              //       the sole objective of making Your experience on the
              //       OneInfinity Platform better, faster, friction-less and
              //       paperless to the extent possible. However, OneInfinity shall
              //       adhere to best industry practices, including information
              //       security, data protection and privacy law while processing
              //       such applications. However, OneInfinity shall not be liable
              //       to You against any liability or claims which may arise out
              //       of such transactions as any such personal information is
              //       being collected, used, processed and shared with Your
              //       consent as agreed under OneInfinity’s Privacy Policy at the
              //       link https://www.OneInfinity.in/privacy-policy 1.14 You
              //       shall not sell or license the OneInfinity Platform or any
              //       content/information or software associated with or derived
              //       from it or use any bot, robot, spider, other automatic
              //       device, or manual process to monitor or copy the OneInfinity
              //       Platform and any of its content or interfere or disrupt the
              //       functionality of the OneInfinity Platform; or take any
              //       action that imposes an unreasonably or disproportionately
              //       large load on OneInfinity’s infrastructure/network or use
              //       any device, or interfere or attempt to interfere with the
              //       OneInfinity Platform or forge headers or manipulate
              //       identifiers or other data in order to disguise the origin of
              //       any content transmitted through the OneInfinity Platform.
              //       1.15 You may only use the OneInfinity Platform to search for
              //       various Financial Products and related service as may be
              //       displayed on it from time to time and You will not use the
              //       OneInfinity Platform to make a fraudulent application for
              //       any Financial Product listed on the OneInfinity Platform.
              //       You agree not to use the OneInfinity Platform for any
              //       purpose that is unlawful, illegal or forbidden by these
              //       Terms, or any applicable laws. OneInfinity may, at its sole
              //       discretion, at any time and without advance notice or
              //       liability, suspend, terminate or restrict Your access to all
              //       or any component of the OneInfinity Platform. 1.16 You are
              //       prohibited from posting or transmitting to or through this
              //       OneInfinity Platform: (i) any unlawful, threatening,
              //       libellous, defamatory, obscene, pornographic, or other
              //       material or content that would violate rights of publicity
              //       and/or privacy or that would violate any law; (ii) any
              //       commercial material or content (including, but not limited
              //       to, solicitation of funds, advertising, or marketing of any
              //       good or services); and (iii) any material or content that
              //       infringes, misappropriates or violates any copyright,
              //       trademark, patent right or other proprietary rights of any
              //       third party. You shall be solely liable for any damages
              //       resulting from any violation of the foregoing restrictions,
              //       or any other harm resulting from Your posting of content on
              //       the OneInfinity Platform. PROPRIETARY RIGHTS 2.1
              //       “Intellectual Property Rights” shall means all copyrights,
              //       trademarks, patents and other intellectual property rights
              //       recognized by the laws of any country, whether registered or
              //       not. 2.2 OneInfinity and its licensors retain all right,
              //       title, and interest in and to all Intellectual Property
              //       Rights related in and to the OneInfinity Platform. The
              //       OneInfinity logos and names are OneInfinity’s trademarks.
              //       Nothing in these Terms grants You a right to use any of
              //       OneInfinity’s trademarks. All other product names, company
              //       names, trademarks, logos, and symbols on the OneInfinity
              //       Platform may be the trademarks of their respective owners.
              //       2.3 Nothing in these Terms grants You any kind of license or
              //       confers any right to download, copy, create derivative
              //       works, modify, reverse engineer, reverse assemble, decompile
              //       or otherwise attempt to discover any source code, or sell,
              //       assign, sub-license, grant a security interest in or
              //       otherwise transfer any right in the OneInfinity Platform.
              //       Further, You have no right whatsoever to remove, obscure, or
              //       alter any proprietary rights notices (including trademark
              //       and copyright notices), which may be affixed to or contained
              //       within the OneInfinity Platform. 2.4 You further acknowledge
              //       that the OneInfinity Platform may contain certain
              //       information which is proprietary and/or designated
              //       confidential by OneInfinity and that You shall not disclose
              //       or share such information with any third party without
              //       OneInfinity’s prior written consent. THIRD PARTY
              //       LINKS/OFFERS The OneInfinity Platform may provide links to
              //       third party websites, products, services and resources
              //       (“Third Party Data”). Since OneInfinity has no control over
              //       such Third Party Date, You acknowledge and agree that
              //       OneInfinity is not responsible or liable for any such Third
              //       Party Data, directly or indirectly, for any damage or loss
              //       caused or alleged to be caused by or in connection with use
              //       of or reliance on any such Third Party Data. OneInfinity
              //       provides Third Party Data only as a convenience to You and
              //       does not review, approve, monitor, endorse, warrant or make
              //       any representations with respect to the same. Your
              //       interaction with any third party relying on such Third Party
              //       Data accessed through the OneInfinity Platform is at Your
              //       own risk, and OneInfinity will have no liability with
              //       respect to the acts, omissions, errors, representations,
              //       warranties, breaches or negligence of any such third parties
              //       or for any personal injuries, death, property damage, or
              //       other damages or expenses resulting from Your interactions
              //       with them. You should make all prior investigation You feel
              //       appropriate before proceeding with any transaction in
              //       connection with such Third Party Data. OUR PARTNERS 4.1
              //       Display of various Financial Products and services offered
              //       by third parties on the OneInfinity Platform does not in any
              //       way imply, suggest, or constitute any sponsorship,
              //       endorsement, recommendation, opinion, advice or approval of
              //       OneInfinity in favour/against such third parties or their
              //       products/services. You agree that OneInfinity is in no way
              //       responsible for the accuracy, timeliness or completeness of
              //       information it may obtain from these third parties.
              //       OneInfinity is only performing the role of an intermediary/
              //       marketplace/ facilitator to enable distribution of such
              //       products and services offered by OneInfinity’s partners
              //       (lenders, credit card companies, mutual fund companies) to
              //       You. Your interaction with any third party accessed through
              //       the OneInfinity Platform is at Your own risk, and
              //       OneInfinity will have no liability with respect to the acts,
              //       omissions, errors, representations, warranties, breaches or
              //       negligence of any such third parties or for any personal
              //       injuries, death, property damage, or other damages or
              //       expenses resulting from Your interactions with them. You
              //       should make all prior investigation You feel appropriate
              //       before proceeding with any transaction in connection with
              //       such third parties. 4.2 You agree and acknowledge that the
              //       conferment of any Financial Product to You shall be at the
              //       sole discretion of OneInfinity’s aforesaid partners while
              //       making any application through the OneInfinity Platform ;
              //       OneInfinity shall not be held liable for any delay,
              //       rejection or approval of any application made through the
              //       OneInfinity Platform. DISCLAIMER 5.1 We make no warranties
              //       regarding the OneInfinity Platform and/or any content and
              //       products and services made available therefrom and each of
              //       the foregoing is provided on an “as is” and “as available”
              //       basis. OneInfinity expressly disclaims all warranties of any
              //       kind, whether express or implied, including, but not limited
              //       to, the implied warranties of merchantability, fitness for a
              //       particular purpose, title, non-infringement, security and
              //       accuracy, as well as all warranties arising by usage of
              //       trade, course of dealing, or course of performance.
              //       OneInfinity makes no warranty, and expressly disclaims any
              //       obligation, that: (a) the content of the OneInfinity
              //       Platform will be up-to-date, complete, comprehensive,
              //       accurate or applicable to Your circumstances; (b) the
              //       OneInfinity Platform will meet Your requirements or will be
              //       available on an uninterrupted, timely, secure, or error-free
              //       basis; (c) the results/outcome that may be obtained from the
              //       use of the OneInfinity Platform will be accurate or
              //       reliable; or (d) the quality of any products, services,
              //       information, or other material obtained by You through the
              //       OneInfinity Platform will meet Your expectations. 5.2 Some
              //       of the content on the OneInfinity Platform is aimed at
              //       spreading general awareness about various financial products
              //       and ancillary services but does not constitute any
              //       investment advice, and the same should not be construed in
              //       any way whatsoever as any solicitation, endorsement,
              //       advertising, marketing or promotion of such products and
              //       services by OneInfinity. These contents and/or information
              //       are derived from publicly available sources and OneInfinity
              //       cannot verify or confirm the genuineness, authenticity or
              //       veracity of such content and information. You should make
              //       all prior investigation You feel appropriate before
              //       proceeding to avail any such products and services. 5.3
              //       Notwithstanding anything contained in this Clause 5, the
              //       OneInfinity Platform reserves the right to conduct
              //       additional verification for security or other reasons if it
              //       is not satisfied with the creditability of Your transaction.
              //       5.4 All payments made on or through the OneInfinity Platform
              //       are managed by third party vendors and service providers.
              //       Use of the payment facilities provided by them through the
              //       OneInfinity Platform shall not render the OneInfinity
              //       Platform liable or responsible for the non-delivery,
              //       non-receipt, non-payment, damage, breach of representations
              //       and warranties, non-provision of after sales or warranty
              //       services or fraud as regards any of the products or services
              //       listed and made available on and through the OneInfinity
              //       Platform. The OneInfinity Platform shall not be responsible
              //       for any damages, interests or claims arising from not
              //       processing of Your transaction. To clarify, OneInfinity will
              //       not be liable to You if You are unable to complete a
              //       transaction as a result of any limit by applicable law or
              //       fault of such third party vendors/service providers or the
              //       banl/ financial institution/ NBFCs, or if they fail to honor
              //       any credit or debit to or from an account associated with
              //       such payment method. 5.5 You hereby agree to provide
              //       accurate information, such as credit/debit information for
              //       purchasing any Financial Product or availing any service on
              //       or through the OneInfinity Platform. You further warrant
              //       that You shall not use payment information or instrument
              //       that is not lawfully owned by You. In addition to these
              //       Terms, the terms and conditions of the bank/ financial
              //       institution/ NBFC shall also be applicable to You.
              //       OneInfinity disclaims any liability arising out of declining
              //       of payment by such bank/ financial institution/ NBFCs. 5.6
              //       No deliveries of any of the Financial Products or services
              //       shall be made outside the territorial boundaries of India.
              //       5.7 Your interactions with any Platform users are solely
              //       between You and such user and We will not be responsible for
              //       any loss or damage incurred as a result of any such
              //       interactions. In case of any dispute between You and any
              //       such user, We are under no obligation to be involved with
              //       the same; however, We may use commercially reasonable
              //       efforts to try to resolve any such disputes. LIMITATION OF
              //       LIABILITY In no event shall OneInfinity (including its
              //       officers, directors, employees, representatives, affiliates,
              //       successors, assigns, vendors and service providers) will be
              //       responsible or liable to You or any third party for (a) any
              //       injury, death, loss, claim, act of god, accident, delay, or
              //       (b) lost profit or any indirect, special, exemplary,
              //       punitive, incidental or consequential damages of any kind,
              //       whether based in contract, tort, strict liability or
              //       otherwise, that arise out of or is in any way connected with
              //       (i) these Terms or any failure or delay, including without
              //       limitation, the use of or inability to use the OneInfinity
              //       Platform or any part thereof or availing of any
              //       products/services therefrom, or (ii) any use of the
              //       OneInfinity Platform or any of its content, or (iii) any
              //       interactions or transactions of You with, or act or omission
              //       of You in relation to any Platform users, or (iv) the
              //       performance or non-performance by OneInfinity or any of its
              //       vendors/service provider, even if OneInfinity has been
              //       advised of the possibility of damages to such parties or any
              //       other party, or (v) any damage to or viruses that may infect
              //       your device. INDEMNITY You agree to indemnify and hold
              //       OneInfinity (and its officers, directors, agents, vendors,
              //       service providers and employees) (“Indemnified Parties“)
              //       harmless from and against any claims, causes of action,
              //       demands, recoveries, losses, damages, fines, penalties or
              //       other costs or expenses of any kind or nature, including
              //       reasonable attorneys’ fees, made by any third party against
              //       the Indemnified Parties or imposed against the Indemnified
              //       Parties or arising out of or related to (i) Your breach of
              //       any of these Terms the Privacy Policy and/or any of
              //       OneInfinity’s other policies, or (ii) Your violation of any
              //       applicable law, rules or regulations, or (iii) Your
              //       violation of the rights of a third party, or (iv) Your
              //       unauthorized, improper, illegal or wrongful use of the
              //       OneInfinity Platform, or (v) Your interaction with any other
              //       Platform user. This indemnification obligation will survive
              //       the expiry or termination of these Terms. TERM AND
              //       TERMINATION These Terms will remain in full force and effect
              //       while You use the OneInfinity Platform and have an account
              //       with OneInfinity. OneInfinity may (a) suspend Your right to
              //       use the OneInfinity Platform, whether in part or in full, or
              //       (b) terminate these Terms, at any time for any reason if You
              //       violate any provisions of these Terms. Upon termination of
              //       these Terms, Your OneInfinity Platform account and right to
              //       access and use the OneInfinity Platform will terminate
              //       immediately. OneInfinity reserves its right to continue to
              //       use Your content/information to the extent permitted under
              //       applicable law and as stated in OneInfinity’s Privacy Policy
              //       available at the link
              //       [https://www.OneInfinity.in/privacy-policy]. OneInfinity
              //       will not have any liability whatsoever to You for any such
              //       termination. WAIVER Any failure or delay by OneInfinity to
              //       enforce or exercise any provision of these Terms, or any
              //       related right, shall not constitute a waiver by OneInfinity
              //       of that provision or right. The exercise of one or more of
              //       OneInfinity’s rights hereunder shall not be a waiver of, or
              //       preclude the exercise of, any rights or remedies available
              //       to OneInfinity under these Terms or in law or at equity. Any
              //       waiver of any provision shall only be effective if made in
              //       writing and executed by a duly authorized officer of
              //       OneInfinity. FORCE MAJEURE If the performance of the
              //       OneInfinity Platform is prevented, restricted, hindered,
              //       delayed or interfered for any reason not in the reasonable
              //       control of OneInfinity, including but not limited to labour
              //       disputes, strikes, acts of God, floods, lightning, severe
              //       weather, shortages of materials, rationing, inducement of
              //       any virus, malware, trojan or other disruptive mechanisms,
              //       any event of hacking or illegal usage of the OneInfinity
              //       Platform, utility or communication failures, earthquakes,
              //       war, revolution, acts of terrorism, civil commotion, acts of
              //       public enemies, blockade, embargo, epidemic, pandemic or any
              //       law, order, proclamation, regulation, ordinance, demand or
              //       requirement having legal effect of any government or any
              //       judicial authority or representative of any such government,
              //       or any other act whatsoever, whether similar or dissimilar
              //       to those referred to in this Clause 10, then OneInfinity
              //       shall be excused and discharged from such performance to the
              //       extent of and during the period of such force majeure event,
              //       and such non-performance shall, in no manner whosoever,
              //       amount to a breach by OneInfinity of its obligations herein
              //       or incur any legal liability on OneInfinity. ASSIGNMENT You
              //       shall not assign or otherwise transfer any of Your rights or
              //       obligations under these Terms. OneInfinity shall have the
              //       right to assign its rights and duties under these Terms
              //       without without any notice to You GOVERNING LAW AND
              //       JURISDICTION The terms of this Agreement shall be subject to
              //       and governed by the laws of India and the courts at Mumbai,
              //       India shall have exclusive jurisdiction over all matters
              //       arising out of or relating to this Agreement. AMENDMENTS
              //       These Terms is subject to occasional revision, and if
              //       OneInfinity makes any substantial changes, OneInfinity may
              //       (or may not) notify You by sending You an e-mail to the last
              //       e-mail address You provided to OneInfinity (if any) and/or
              //       by prominently posting notice of the changes on the
              //       OneInfinity Platform. Any changes to these Terms will be
              //       effective immediately upon such notification/posting.
              //       Continued use of the OneInfinity Platform following notice
              //       of such changes shall indicate Your express acknowledgement
              //       of such changes and agreement to be bound by the terms and
              //       conditions of such changes. Upon any change, OneInfinity
              //       will notify the updated Terms on the OneInfinity Platform or
              //       by other means. GRIEVANCE OFFICER In accordance with
              //       Information Technology Act, 2000 and rules made there under,
              //       the name and contact details of the Grievance Officer are
              //       provided below: Name: Address: Email: info@OneInfinity.in
              //       Phone No.: 022- (between 10 AM to 6 PM on weekdays) CONTACT
              //       US Please contact Us by email on info@OneInfinity.in for any
              //       questions or comments regarding these Terms or pertaining to
              //       the OneInfinity Platform. Last Updated on: August 29th,
              //       2022.
              //     </pre>
              //   </Paragraph>
              // </div>
              // </Card>
              <div className="ifameClass">
                <iframe src="https://oneinfinity.in/terms-conditions" height="100%" width="100%"></iframe>    
                </div>
              )}

            
          </>
          {/* <div style={{ width:'500px', margin: 'auto' }}> */}
          {!consentSubmitted && (
            <div className="bottomSection">
          <Row justify={"space-around"} className="ConfirmationLink" style={{ width: '100%' }}>
            <Col span={18} style={{ width: '100%', margin: 'auto' }}>
              <Checkbox onChange={onChangeCoApplicantDetails} className="" style={{ fontSize: '1rem' }}>
                I agree with the <b>Terms and Conditions</b>
              </Checkbox>
            </Col>
          </Row>
          <Row justify={"space-around"} className="SubmitButton" style={{ width: '100%' }}>
            <Col span={18} style={{ width: '100%', margin: 'auto' }}>
              <Button
                className={btnDisable ? "btn-disable btn-success" : ""}
                disabled={btnDisable}
                onClick={getConsert}
                style={{ width: '100%' }}
              >
                I Agree
              </Button>
            </Col>
          </Row>
          </div>
          )}
          <Row justify={"space-around"} style={{ width: '100%' }} />
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
