import React, { useEffect, useState } from "react";
import { Button, Form, List, Space, notification } from "antd";
import {
  PHONE_NUMBER_REGEX,
  PINCODE_REGAX,
  STRING_ONLY,
} from "shared/constants/AppConstants";
import FormDOB from "components/FormDOB/FormDOB";
import moment from "moment";
import FormCheckbox from "components/FormCheckbox/FormCheckbox";
import FormInput from "components/FormInput/FormInput";
import { disableDate } from "shared/services/Utility";
import FormInputPan from "components/FormInput/FormInputPan";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IApplicantInformation {
  setShowApplicantInfo: any;
  setIsApplicantModal: any;
  leadData: any;
  isGetBureauScore: any;
  getBureauScore?: any;
  genrateBureauScore: any;
  getLeadById: any;
}

const ApplicantInformation: React.FC<IApplicantInformation> = (
  props: IApplicantInformation
) => {
  const {
    setShowApplicantInfo,
    setIsApplicantModal,
    leadData,
    isGetBureauScore,
    getBureauScore,
    genrateBureauScore,
    getLeadById,
  } = props;

  const [pan, setPan] = useState("");
  const [panData, setPanData] = useState({}) as any;
  const [applicantInfoForm] = Form.useForm() as any;
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const additionalFormData = leadData?.dsaCaseAdditionalAttributes;

  useEffect(() => {
    console.log("leadData ----->", leadData);
    if (leadData?.productVersion == "V2") {
      applicantInfoForm.setFieldsValue({
        pan:
          additionalFormData?.applicantPan == "PENDING"
            ? ""
            : additionalFormData?.applicantPan,
        name: additionalFormData?.applicantName ?? "",
        mobileNumber: additionalFormData?.mobileNumber.startsWith("1")
          ? ""
          : additionalFormData?.mobileNumber,
        dateOfBirth:
          additionalFormData?.dateOfBirth &&
          moment(additionalFormData?.dateOfBirth),
        email: additionalFormData?.email ?? "",
        pinCode: additionalFormData?.pincode ?? "",
        addressLine1: additionalFormData?.addressLine1 ?? "",
        city: additionalFormData?.city ?? "",
        state: additionalFormData?.state ?? "",
      });
      setPan(additionalFormData?.applicantPan);
    } else {
      const { contactDetails, address } = leadData;
      applicantInfoForm.setFieldsValue({
        pan: contactDetails?.pan == "PENDING" ? "" : contactDetails?.pan,
        name: contactDetails?.firstName ?? "",
        mobileNumber: contactDetails?.mobileNumber.startsWith("1")
          ? ""
          : contactDetails?.mobileNumber,
        dateOfBirth:
          contactDetails?.dateOfBirth && moment(contactDetails.dateOfBirth),
        email: contactDetails?.emailId ?? "",
        pinCode: address?.pinCode ?? "",
        addressLine1: address?.address ?? "",
        city: address?.city ?? "",
        state: address?.state ?? "",
      });
      setPan(contactDetails?.pan == "PENDING" ? "" : contactDetails?.pan);
    }
    console.log("pandata", panData);
  }, []);

  const checkPincode = (e: any) => {
    const { value: nextValue } = e.target;
    const params = {
      pin_code: nextValue,
    };
    if (nextValue?.length == 6) {
      API_SERVICE.checkPincode(params).then(({ data }) => {
        if (data) {
          applicantInfoForm.setFieldsValue({
            city: data?.payload?.city ?? "",
            state: data?.payload?.state ?? "",
          });
        }
      });
    }
  };

  const handleGetConsetUserInfo = (formData: any) => {
    const payload = {
      address: formData?.addressLine1,
      caseId: leadData?.caseDetails?.caseId,
      city: formData?.city,
      dob:moment(formData?.dateOfBirth).format("YYYY-MM-DD"),
      email: formData?.email,
      mobileNumber: formData?.mobileNumber,
      name: formData?.name,
      panId: formData?.pan,
      pincode: formData?.pinCode,
      state: formData?.state,
    };
    console.log("applicant ----", payload);
    API_SERVICE.getConsentInfo(payload)
      .then(({ data }) => {
        if (data) {
          setShowApplicantInfo(false);
          setIsApplicantModal(false);
          if (data?.payload) {
            genrateBureauScore(formData?.pan);
          }
          if (isGetBureauScore) {
            getBureauScore(formData?.pan);
          }
          getLeadById();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

    const onFinish = (values: any) => {
        if ((panData?.payload?.fullName?.length ?? 0) == 0 ||
            panData?.payload?.fullName == applicantInfoForm.getFieldValue("name").trim()) {
            handleGetConsetUserInfo(values);
        } else {
            notification.error({ message: "Please Enter correct details" })
        }
    };

    const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",width: "130px"};
    const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width: "130px"};
    const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  const column = [
    <FormInputPan
      name={"pan"}
      label="PAN"
      isDisabled={(additionalFormData?.applicantPan?.length ?? 0) > 0}
      placeholder="Enter your PAN"
      showVerification
      value={pan}
      callBack={(data?: any) => {
        setPanData(data);
        if ((data?.payload?.fullName?.length ?? 0) != 0) {
          applicantInfoForm.setFieldsValue({
            name        :   data?.payload?.fullName,
          });
        }
      }}
    />,
    <FormInput
      name="name"
      placeholder="Enter your Name"
      isDisabled={(additionalFormData?.applicantName?.length ?? 0) > 0}
      label="Name"
      rules={[
        { required: true, message: "Please enter name" },
        {
          pattern: STRING_ONLY,
          message: "Please enter valid name",
        },
      ]}
    />,
    <FormInput
      name="mobileNumber"
      label="Mobile Number"
      placeholder="Enter your Mobile Number"
      prefix="+91"
      isDisabled={
        additionalFormData?.mobileNumber?.startsWith("1")
          ? false
          : (additionalFormData?.mobileNumber?.length ?? 0) > 0
      }
      rules={[
        {
          required: true,
          message: "Please enter mobile number",
        },
        {
          pattern: PHONE_NUMBER_REGEX,
          message: "Please enter valid mobile number",
        },
      ]}
    />,
    <FormInput
      name="email"
      label="Email"
      placeholder="Enter your Email"
      isDisabled={(additionalFormData?.email?.length ?? 0) > 0}
      rules={[
        {
          required: true,
          message: "Please enter email",
        },
        { type: "email", message: "Please enter valid email" },
      ]}
    />,
    <FormDOB
      form={applicantInfoForm}
      disableDate={disableDate}
      label="Date of Birth (YYYY-MM-DD)"
      placeholder="Enter your Date of Birth"
      name="dateOfBirth"
      isRequired={true}
      isDisabled={(additionalFormData?.dateOfBirth?.length ?? 0) > 0}
    />,
    <FormInput
      name="pinCode"
      label="Pin Code"
      placeholder="Enter your Pin Code"
      isDisabled={additionalFormData?.pincode?.length > 0}
      onChange={(e: any) => checkPincode(e)}
      rules={[
        {
          required: true,
          message: "Please enter pin code",
        },
        {
          pattern: PINCODE_REGAX,
          message: "Please enter valid pincode",
        },
      ]}
    />,
    <FormInput
      name="addressLine1"
      label="Address Line 1"
      placeholder="Enter your Address Line 1"
      isDisabled={(additionalFormData?.addressLine1?.length ?? 0) > 0}
      rules={[
        {
          required: true,
          message: "Please enter address line 1",
        },
      ]}
    />,
    <FormInput
      name="city"
      label="City"
      placeholder="Enter your City"
      isDisabled={(additionalFormData?.city?.length ?? 0) > 0}
      rules={[
        {
          required: true,
          message: "Please enter city",
        },
      ]}
    />,
    <FormInput
      name="state"
      label="State"
      placeholder="Enter your State"
      isDisabled={(additionalFormData?.state?.length ?? 0) > 0}
      rules={[
        {
          required: true,
          message: "Please enter state",
        },
        {
          pattern: STRING_ONLY,
          message: "Please enter valid state",
        },
      ]}
    />,
  ];

  return (
    <div style={{ width: "90%" }} className="content-box">
      <p style={{ fontWeight: "300" }}>*Required For Bureau Request</p>
      <Form
        form={applicantInfoForm}
        layout="vertical"
        onFinish={onFinish}
        name="applicantInfoForm"
      >
        <List
          grid={{ gutter: 5, column: 2, xs: 1 }}
          dataSource={column}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <FormCheckbox
           className={'dynamic-pagination-checkbox'} 
           style={customStyles}
          name={"applicant terms"}
          isRequired
          msgRequired="Please check applicant information terms"
          textElement={
            <div>
              I confirm that the Applicant has been informed about the following
              credit Bureau Disclaimer. “I hereby appoint Oneinfinity as my
              authorised representative to receive my credit information from
              Experian or such credit bureaus.” I have also ensured that the
              details entered in the Borrower Case are actual details of the
              borrower associated with the Credit Bureau records.
            </div>
          }
        />
        <Space>
          <Form.Item>
            <Button
              className="next-btn dynamic-btn-default"
              style={btnDefaultBorderStyle}
              onClick={() => {
                setShowApplicantInfo(false);
                setIsApplicantModal(false);
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="next-btn dynamic-btn-primary"
              type="primary"
              htmlType="submit"
              style={btnPrimaryStyles}
            >
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};

export default ApplicantInformation;
