import { Button, Divider, Form, Radio, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import "../CloseCase/CloseCase.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

interface ICloseCase {
  leadData?: any;
  setLeadViewType?: any;
}

const CloseCase: React.FC<ICloseCase> = (props: ICloseCase) => {
  const { leadData, setLeadViewType } = props;
  const [selectedRejectReasonsKey, setSelectedRejectReasonsKey] = useState(
    null as any
  );
  const [selectedRejectReasons, setSelectedRejectReasons] = useState(
    null as any
  );
  const [rejectReasons, setRejectReasons] = useState([] as any);
  const [loadingCloseCase, setLoadingCloseCase] = useState(false);
  const [caseStatus, setCaseStatus] = useState([] as any);
  const [closeCaseForm] = Form.useForm();
  const history = useHistory();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const caseStatusList = enumValues.find(
        (item) => item.propertyName === "CASE_STATUS"
      );
      const rejectedReasonList = enumValues.find(
        (item) => item.propertyName === "REJECTION_REASON"
      );
      const displayRejectedList =
        rejectedReasonList?.keyAndDisplayValueList?.filter((item: any) => {
          return (
            item?.key != "LOAN_SIZE_TOO_LARGE" &&
            item?.key != "INTEREST_RATE_MISMATCH" &&
            item?.key != "NOT_INTERESTED" &&
            item?.key != "NOT_CONTACTABLE" &&
            item?.key != "MISSING_INFORMATION" &&
            item?.key != "LOAN_CANCELED" &&
            item?.key != "LOAN_PRODUCT_NOT_SUPPORTED" &&
            item?.key != "TECHNICAL_ERROR"
          );
        });
      setRejectReasons(displayRejectedList);
      setCaseStatus(caseStatusList);
    }
    closeCaseForm.setFieldsValue({
      reason: leadData?.caseDetails?.rejectionReason,
    });
  }, []);

  const onChangeRejectionReason = (selectedValue) => {
    setSelectedRejectReasonsKey(selectedValue.key);
    setSelectedRejectReasons(selectedValue.value);
  };

  const closeCaseHandler = (value: any) => {
    setLoadingCloseCase(true);
    const caseId = leadData.caseDetails.caseId;
    const closeStatus = caseStatus.keyAndDisplayValueList.find(
      (item) => item.key === "CLOSED"
    );
    const payload = {
      comments: selectedRejectReasons,
      status: closeStatus.key,
      rejectionReason: selectedRejectReasonsKey,
    };
    if (selectedRejectReasonsKey) {
      API_SERVICE.closeCase(caseId, payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            history.push("/lead");
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
        .finally(() => {
          setLoadingCloseCase(false);
        });
    } else {
      notification.error({ message: "Please Select Reason" });
      setLoadingCloseCase(false);
    }
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",marginLeft: "15px" };
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",marginLeft: "20px" };
  const btnRadioStyles: any = { '--btnRoundRadio': appColors?.appPrimaryColor ?? "" };

  return (
    <>
      <div className="close-case-box">
        <div className="heading">
          <Title level={5} style={{ marginTop: "15px", marginLeft: "15px" }}>
            Select Reason for Close Case
          </Title>
          <Divider style={{ marginBottom: "5px", marginTop: "10px" }} />
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
          <Form
            form={closeCaseForm}
            layout="horizontal"
            onFinish={closeCaseHandler}
            // initialValues={{ reason: selectedRejectReasonsKey }}
          >
            <Form.Item
              name="reason"
              rules={[{ required: true, message: "Please enter comments" }]}
            >
              <Radio.Group
                style={{ display: "grid", padding: "20px", gap: "10px" }}
                className=""
                name="reason"
              >
                {rejectReasons?.map((item) => {
                  return (
                    <Radio
                      key={item.key}
                      value={item.key}
                      className="case-radio"
                      style={btnRadioStyles}
                      onChange={() => {
                        onChangeRejectionReason(item);
                      }}
                    >
                      {item.value}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
            <Button
              className="dynamic-btn-primary"
              style={btnPrimaryStyles}
              loading={loadingCloseCase}
              type="primary"
              htmlType="submit"         
            >
              Submit
            </Button>
            <Button
              className="dynamic-btn-default"
              style={btnDefaultBorderStyle}
              type="default"
              onClick={() => setLeadViewType("LeadDetailNewTabs")}
            >
              Cancel
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};
export default CloseCase;
