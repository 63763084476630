import React, { useState, useEffect } from "react";
import { Button, Space } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
interface ABCLBreNoOffers {
  data: any;
  responseJSONSchema: any;
  selectedLander: any;
  leadData: any;
  handleFormSubmit: any;
  setCloseConfirmation: any;
}
const ABCLBreNoOffer: React.FunctionComponent<ABCLBreNoOffers> = ({
  data,
  responseJSONSchema,
  selectedLander,
  leadData,
  handleFormSubmit,
  setCloseConfirmation
}) => {

  const {appColors}: any = useSelector((state: RootState) => state.common);

  const handleSubmitReq = () => {
    handleFormSubmit({ data: {} })
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: "column",
        height: "250px",
      }}
    >
      <div
        style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: 'column',
            fontSize: "1.2rem",
            alignItems: "center",
        }}
      >
        <div style={{ fontSize: '24px', fontWeight: '350' }}>Sorry, No offer available.</div>
        <div style={{ fontSize: '24px', fontWeight: '350' }}>Please retry after a month</div>
      </div>
      {/* buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBlock: "10px",
        }}
      >
        <Space className="">
          <Button
            htmlType="button"
            className="dynamic-btn-default"
            style={{
              '--borderColor': appColors?.appSecondaryColor ?? "",
              width: '155px',
              height: '52px',
              borderRadius: '10px'
            } as any}
            onClick={() => {
              setCloseConfirmation(true);
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default ABCLBreNoOffer;
