import React, { useState, useEffect } from "react";
import { Button, Space } from "antd";
import ReactTimerLoader from "../ABCLScreens/ReactTimerLoader";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
interface InCredRetryScreens {
  loading: any;
}
const InCredLoadingScreen: React.FunctionComponent<InCredRetryScreens> = ({
  loading,
}) => {
  const { appColors }: any = useSelector((state: RootState) => state.common);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "300px",
      }}
    >
      {loading && <ReactTimerLoader removeTime={true} />}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          fontSize: "20px",
          fontWeight: "350",
          alignItems: "center",
        }}
      >
        {/* Please wait while we fetch potential 
offer from the lender. This may 
take some time. */}
        <div
          style={{
            fontSize: "20px",
            fontWeight: "350",
            alignItems: "center",
          }}
        >
          Please wait while we fetch potential
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "350",
            alignItems: "center",
          }}
        >
          offer from the lender.
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "350",
            alignItems: "center",
          }}
        >
          This may take some time.
        </div>
      </div>
    </div>
  );
};

export default InCredLoadingScreen;
