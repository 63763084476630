import { Tabs } from "antd";
import { useEffect, useState } from "react";
import TermsNConditions from "./termsNConditions";
import PrivacyPolicy from "./privacyPolicy";
import FAQ from "./faq";
import About from "./about";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import "./help.scss";
import { RootState } from "shared/redux/store";

const { TabPane } = Tabs;
type Props = { location: any };

const Help: React.FunctionComponent<Props> = (props) => {
  const [activeKey, setActiveKey] = useState("1");
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();
  const linkFrom = props?.location?.state || "/";


  useEffect(() => {
    if (linkFrom === "fromFolatingButton") {
      setActiveKey("3")
    }
    dispatch(setScreenHeader({}));
  }, []);

  const onTabChange = (key: any) => {
    setActiveKey(key);
  };

  return (
    <div className="content-box btn-box">
      <div className="btn-box help-tab-box">
        <Tabs
          activeKey={activeKey}
          onChange={onTabChange}
          type="card"
          className="custom-app-tab"
        >
          <TabPane tab="Terms & Conditions" key="1">
            <TermsNConditions />
          </TabPane>
          <TabPane tab="Privacy Policy" key="2">
            <PrivacyPolicy />
          </TabPane>
          <TabPane tab="FAQ" key="3">
            <FAQ />
          </TabPane>
          <TabPane tab="About" key="4">
            <About />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Help;
