import { Button, Col, Form, notification, Row, Space, Typography } from 'antd';
import { GoogleApiWrapper } from 'google-maps-react';
import React, { useRef } from 'react'
import { useState } from 'react';
import API_SERVICE from 'shared/services/api-service';
import SESSION_SERVICE from 'shared/services/session-service';
import { useEffect } from "react";
import { MAP_KEY } from 'shared/constants/AppConstants';
import moment from 'moment';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenHeader } from 'shared/redux/common-reducer';
import { RootState } from 'shared/redux/store';
import "./attendance.scss";
import VectorBlue from "../../../src/assets/images/Vector_Blue.svg"
import VectorPink from "../../../src/assets/images/Vector_Pink.svg"
import CheckIn from "../../../src/assets/images/CheckIn.svg"
import CheckOut from "../../../src/assets/images/CheckOut.svg"

const AnyReactComponent = ({ text, lat, lng }) => <div>{text}</div>;
// import moment from "moment";

const { Text } = Typography;

type Props = {}

const Attendance: React.FunctionComponent<Props> = (props) => {

    <script src="https://maps.googleapis.com/maps/api/geocode/json?latlng"></script>

    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState({} as any);
    const [currentPosition, setCurrentPosition] = useState({} as any);
    const uId = SESSION_SERVICE.getUserId();
    const [checkInLoading, setCheckInLoading] = useState(false)
    const [checkOutLoading, setCheckOutLoading] = useState(false)
    const [loginUserData, setLoginUserData] = useState({} as any);
    const currentDate = new Date().toLocaleDateString();
    const date = moment(new Date()).locale("en").format("YYYY-MM-DD")
    const [currentAddress, setCurrentAddress] = useState([] as any);   
    const {appColors}: any = useSelector((state: RootState) => state.common);
    const latitude = "22.8196352";
    const longitude = "72.6073344";

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setScreenHeader({}));
        getAttendanceById();
    }, []);

    useEffect(() => {
        getCurrentLocation();
    },[currentAddress,currentPosition])

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setCurrentPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    zoom: 12
                });
            });
        }
    }

    const getAttendanceById = () => {
        const params = {
            beginDate: date,
            endDate: date,
            sortDir: "desc",
            userId: uId,
        }
        API_SERVICE.getAttendanceList(params)
            .then(({ data }) => {
                if (data) {
                    setLoginUserData(data?.payload?.content[0]);
                    setCurrentAddress(data?.payload?.content[0]?.checkOutLocation?.placeName ?? data?.payload?.content[0]?.checkInLocation?.placeName);
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    }

    const createPayload = (type: any, currentAddress: any) => {
        let userData = localStorage.getItem("user") as any;
        if (userData) {
            userData = JSON.parse(userData);
        }
        let address = currentAddress.toString();
        const data = {
            attendanceType: type,
            mapLocation: {
                latitude: currentPosition?.lat + "° N",
                longitude: currentPosition?.lng + "° E",
                placeName: address,
            }
        };
        return data;
    };

    function getUserAddressBy(lat, lng, type) {
        var xhttp = new XMLHttpRequest();
        xhttp.open('GET', "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&sensor=true&key=" + MAP_KEY);
        xhttp.send();
        xhttp.onreadystatechange = processRequest;

        function processRequest(e) {            
            if (e.target.readyState == 4 && e.target.status == 200) {
                var response = JSON.parse(e.target.responseText);
                let address = response?.results[0]?.formatted_address;
                setCurrentAddress(address);
                if (address) {
                    if (type === "CHECK_IN") {
                        checkInUser(type, address);
                    } else {
                        checkoutUser(type, address)
                    }
                }
            }
        }
    }

    const onClickCheckIn = () => {
        let type = "CHECK_IN"
        if (currentPosition != " " && currentPosition != " ") {
            setCheckInLoading(true)
            getUserAddressBy(currentPosition?.lat, currentPosition?.lng, type);
        }
    }

    const checkInUser = (type: any, address: any) => {
        const payload = createPayload(type, address);
        API_SERVICE.attendanceUser(payload)
            .then(({ data }) => {
                if (data) {
                    notification.success({ message: "You Are CheckIn Successfully..." });
                    getAttendanceById();
                    // getCurrentLocation();
                }
            })
            .catch((e: any) => {
                setCheckInLoading(false)
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
            setCheckInLoading(false)
    }

    
    const onClickCheckOut = () => {
        let type = "CHECK_OUT"
        if (currentPosition != " ") {
            setCheckOutLoading(true)
            getUserAddressBy(currentPosition?.lat, currentPosition?.lng, type);
        }
    }

    const checkoutUser = (type: any, address: any) => {
        const payload = createPayload(type, address);
        API_SERVICE.attendanceUser(payload)
            .then(({ data }) => {
                if (data) {
                    localStorage.setItem("isCheckOut", "true");
                    notification.success({ message: "You Are CheckOut Successfully..." });
                    // getCurrentLocation();
                    getAttendanceById();
                }
            })
            .catch((e: any) => {
                setCheckOutLoading(false)
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
            setCheckOutLoading(false)
    }

    const rendercheckOutDtls = () =>{
    return(
       <Space style={{  display: 'grid',backgroundColor: "white", padding: '10px', marginRight: "-10px", marginTop: "10px", borderRadius:"10px" }}>
            <div className='maindiv'>
                <div className='maindivLeftOut'
                    style={btnDefaultBorderStyle}>
                    <img src={CheckOut} style={{marginTop:'10px', paddingTop:'10px'}}/>
                    </div>
                <div className='maindivRight'>
                    
                    <p className='check'>
                        Check Out : <b>{loginUserData?.checkOutTime} </b> 
                        &emsp; Date : <b>{loginUserData?.checkOutDate}</b>
                    </p>
                    {currentAddress?.length > 0 ?
                        <Text>Location : {currentAddress}</Text>
                        : <Text></Text>
                    }
                </div>
            </div>                
        </Space>
    )
    }

    const renderCheckInDtls =()=>{
        return(
            <Space style={{  display: 'grid',backgroundColor: "white", padding: '10px', marginRight: "-10px", marginTop: "10px", borderRadius:"10px" }}>
                <Text>Attendance Detail</Text>
                <div className='maindiv'>
                    <div 
                        className='maindivLeftIn'
                        style={btnPrimaryStyles}
                    >
                        <img src={CheckIn} style={{marginTop:'10px'}}/>
                        {/* <p style={{marginTop:"10px", marginBottom:"0px", fontSize:"18px"}}>
                            CH
                        </p>
                        <p style={{fontSize:"18px", marginBottom:"0px",}}>
                            IN
                        </p> */}
                    </div>
                    <div 
                        className='maindivRight'
                    >
                       <p className='check'> 
                            {/* Check In : <b>{new Date(loginUserData?.checkInTimestamp).toLocaleTimeString()} </b> 
                            &emsp; Date : <b>{new Date(loginUserData?.checkInTimestamp).toLocaleDateString()}</b> */}
                             Check In : <b>{loginUserData?.checkInTime} </b> 
                             &emsp; Date : <b>{loginUserData?.checkInDate}</b>
                        </p>
                        {currentAddress?.length > 0 ?
                            <Text>Location : {currentAddress}</Text>
                            : <Text></Text>
                        }
                    </div>
                </div>                
            </Space>
        )
    }

    const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
    const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};

    return (
        <div className='content-box'>
        <Row gutter={[24, 24]}>
            <Col span={14} >

                <div style={{ height: '500px', width: '100%'}}>

                    {currentPosition &&
                        (
                            <iframe
                                aria-hidden="false"
                                frameBorder="0"
                                style={{ height: "500px", width: "100%",  border:"1px dashed grey"}}
                                src={"https://maps.google.com/maps?q=" + currentPosition?.lat + "," + currentPosition?.lng + "&width=100&height=600&hl=en;z=10&ie=UTF8&iwloc=B&output=embed"}
                                // src={"https://maps.google.com/maps?q=" + currentPosition?.lat + "," + currentPosition?.lng + "&width=100&height=600&hl=en;z=10&ie=UTF8&iwloc=B&output=embed"}
                            >
                            </iframe>) 
                    }

                </div>
            </Col>

            <Col span={9} >
                <Space style={{ display: 'grid', backgroundColor: 'white', padding: '10px', marginRight: "-10px" }}>
                    <Text> Location </Text>
                    {currentAddress?.length > 0 ?
                        <Text>{currentAddress}</Text>
                        : <Text></Text>
                    }
                    <Space className="buttons">
                        <Form.Item>
                        <Button
                            className='blueButtonCheckIn'
                            style={btnPrimaryStyles}
                            size="large"
                            type="primary"
                            htmlType="button"
                            onClick={onClickCheckIn}
                            loading={checkInLoading}
                            disabled={currentDate === new Date(loginUserData?.checkInTimestamp).toLocaleDateString()}
                        >
                            Check In
                        </Button>
                        </Form.Item>
                        <Form.Item>
                        <Button
                            className='pinkButtonCheckOut'
                            style={btnDefaultBorderStyle}
                            size="large"
                            type="primary"
                            htmlType="button"
                            loading={checkOutLoading}
                            onClick={onClickCheckOut}
                            disabled={currentDate !== new Date(loginUserData?.checkInTimestamp).toLocaleDateString() || currentDate === new Date(loginUserData?.checkOutTimestamp).toLocaleDateString()}
                        >
                            Check Out
                        </Button>
                        </Form.Item>
                    </Space>
                    {(currentDate === new Date(loginUserData?.checkInTimestamp).toLocaleDateString() && currentDate != new Date(loginUserData?.checkOutTimestamp).toLocaleDateString()) ? (
                    <div style={{textAlign:"center", color:"rgba(39, 56, 150, 1)"}}>
                        <img src ={ VectorBlue}/> Check In Marked Successfully
                    </div>
                    ):
                    (currentDate === new Date(loginUserData?.checkInTimestamp).toLocaleDateString() && currentDate === new Date(loginUserData?.checkOutTimestamp).toLocaleDateString())? (
                        <div style={{textAlign:"center", color:"rgba(199, 28, 142, 1)"}}>
                        <img src ={ VectorPink}/> Check Out Marked Successfully
                    </div>
                    ):(
                        <div></div>
                    )
                    }                   
                </Space>
                {currentDate === new Date(loginUserData?.checkInTimestamp).toLocaleDateString() && renderCheckInDtls()}
                {(currentDate === new Date(loginUserData?.checkInTimestamp).toLocaleDateString() && currentDate === new Date(loginUserData?.checkOutTimestamp).toLocaleDateString()) && rendercheckOutDtls()}
            </Col>
        </Row>
        {/* <>
        
        </> */}
        </div>
    )
};

export default GoogleApiWrapper({
    apiKey: MAP_KEY
})(Attendance);