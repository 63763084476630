import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getEnumList } from "shared/services/Utility";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Form,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Modal,
  notification,
  Radio,
} from "antd";
import { PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";
import API_SERVICE from "shared/services/api-service";
import { debounce } from "lodash";
import rightArrow from "../../../../assets/images/ph_arrow-right.svg";
import {ReactComponent as IconBil} from "../../../../assets/images/dashboard/bil.svg";
import {ReactComponent as IconSmallBil} from "../../../../assets/images/dashboard/small_bil.svg";
import {ReactComponent as IconHomeLoan} from "../../../../assets/images/dashboard/home_loan.svg";
import {ReactComponent as IconLap} from "../../../../assets/images/dashboard/lap.svg";
import {ReactComponent as IconCommercialPurchase} from "../../../../assets/images/dashboard/commercial_purchase.svg";
import {ReactComponent as IconCreditCard} from "../../../../assets/images/dashboard/credit_card.svg";
import {ReactComponent as IconLifeInsurance} from "../../../../assets/images/dashboard/life_insurance.svg";
import {ReactComponent as IconServiceOrderFinancing} from "../../../../assets/images/dashboard/service_order_financing.svg";
import {ReactComponent as IconUnsecureOverdraft} from "../../../../assets/images/dashboard/unsecure_overdraft.svg";
import {ReactComponent as IconWorkingCapital} from "../../../../assets/images/dashboard/working_capital.svg";
import {ReactComponent as IconSupplyChainFinance} from "../../../../assets/images/dashboard/supply_chain_finance.svg";
import {ReactComponent as IconLas} from "../../../../assets/images/dashboard/las.svg";
import {ReactComponent as IconRealty} from "../../../../assets/images/dashboard/realty.svg";
import {ReactComponent as IconPL} from "../../../../assets/images/dashboard/personal_loan.svg";
import {ReactComponent as IconEL} from "../../../../assets/images/dashboard/education_loan.svg";
import {ReactComponent as Iconbnpl} from "../../../../assets/images/dashboard/bnpl.svg";
import {ReactComponent as IconAddConnector} from "../../../../assets/images/dashboard/add_connector.svg";
import {ReactComponent as IconHealthInsurance} from "../../../../assets/images/dashboard/health_insurance.svg";
import {ReactComponent as IconTwoInsurance} from "../../../../assets/images/dashboard/two_insurance.svg";
import {ReactComponent as IconFourInsurance} from "../../../../assets/images/dashboard/four_insurance.svg";
import {ReactComponent as IconAutoLoan} from "../../../../assets/images/dashboard/auto_loan.svg";
import {ReactComponent as IconMicroLAP} from "../../../../assets/images/dashboard/micro-lap.svg";
import {ReactComponent as IconNewProduct} from "../../../../assets/images/dashboard/new-product.svg";
import FormInput from "components/FormInput/FormInput";
import FormRadio from "components/FormRadio/FormRadioStart";
import AddLeadV1 from "./AddLeadV1";
import "./AddLeanNew.scss";
import "./ProductRadioStart.scss";
import "../../../../components/FormRadio/FormRadioStart.scss";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import FormSelect from "components/FormSelect/FormSelect";
import { RootState } from "shared/redux/store";

export type viewTypeScreen = "createUser" | "loanTypeList" | "manageLead";

interface IAddLeadNew {
  isEditV2?: any;
  setIsEditV2?: any;
  editData?: any;
  editLead: any;
}

const AddLeadNew: React.FC<IAddLeadNew> = (props: IAddLeadNew) => {
  const { isEditV2, setIsEditV2, editData, editLead } = props;
  const [viewType, setViewType] = useState<viewTypeScreen>("createUser");
  const [selectedApplicationType, setSelectedApplicationType] =
    useState("SELF");
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null as any);
  const [searchedCustomers, setSearchedCustomers] = useState([] as any);
  const [allConnectors, setAllConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(null as any);
  const [userData, setUserData] = useState({} as any);
  const [viewModel, setViewModel] = useState(false);
  const [assignToBackOfficeData, setAssignToBackOfficeData] = useState(
    [] as any
  );
  const [selectedBackOfficeUser, setSelectedBackOfficeUser] = useState(
    null as any
  );
  const [isClearBackOfficer, setIsClearBackOficer] = useState(false);
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [isDisableSelectProduct, setIsDisableSelectProduct] = useState(true);
  const [loanType, setLoanType] = useState("");
  const [insuranceCaseOnline, setInsuranceCaseOnline] = useState(undefined as boolean | undefined);
  const [productVersion, setProductVersion] = useState("");
  const [showCaseOption, setShowCaseOption] = useState(false);
  const [caseId, setCaseId] = useState("");
  const history = useHistory();
  const [addLeadForm] = Form.useForm();
  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  const [selectedLeadApplicationType, setSelectedLeadApplicationType] =
    useState("");
  const [applicantTypesBusinessValue, setApplicantTypesBusinessValue] =
    useState("");
  const [applicantTypesIndividualValue, setApplicantTypesIndividualValue] =
    useState("");
  const [individualTypes, setIndividualType] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [applicantTypes, setApplicantType] = useState([]);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);

  const dispatch = useDispatch();

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  useEffect(() => {
    getAllTypes();
    setEnumValues();
    if (isEditV2) {
      setCaseId(editData?.caseDetails?.caseId);
      setIsDisableSelectProduct(false);
      addLeadForm.setFieldsValue({
        applicantType: editData?.applicationType,
        selectType: editData?.sourceOfCase,
        businessType: editData?.applicationSubTypeKey,
        selfPhoneNumber: editData?.contactDetails?.mobileNumber,
        selfCustomerName: editData?.contactDetails?.firstName,
        assignTo:
         editData?.caseDetails?.assignedToUserDetails?.firstName ?? editData?.caseDetails?.connectorDetails?.userDetails?.firstName,
        assignToBackOffice:
          editData?.caseDetails?.backOfficeUserDetails?.fullName,
      });
    } else {
      dispatch(
        setScreenHeader({
          backScreenTitle: "Lead",
          backScreenPath: "/lead",
          screenTitle: "Add Lead",
        })
      );
      addLeadForm.setFieldsValue({
        productRadio: "YES",
        selectType: "SELF",
      });
    }
  }, []);

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredBusinessType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      if (filteredIndivType) {
        setIndividualType(filteredIndivType.keyAndDisplayValueList);
      }
      if (filteredBusinessType) {
        setBusinessTypes(filteredBusinessType.keyAndDisplayValueList);
      }
      if (filteredApplicationType) {
        setApplicantType(filteredApplicationType.keyAndDisplayValueList);
      }
    }
  };
  const onChangeSelectType = (e: RadioChangeEvent) => {
    setSelectedApplicationType(e?.target?.value);
  };
  const optionListBtn = [
    {
      key: "YES",
      value: "Yes",
    },
    {
      key: "NO",
      value: "No",
    },
  ];
  const optionListSelfOrConnector = [
    {
      key: "SELF",
      value: "Self",
    },
    {
      key: "CONNECTOR",
      value: "Connector",
    },
  ];

  const onFinishForm = (values: any) => {
    const selfPayload = {
      selectType: values?.selectType,
      selfAssignTo: values?.selfAssignTo,
      selfAssignToBackOffice: values?.selfAssignToBackOffice,
      productRadio: values?.productRadio,
      selfCustomerName: values?.selfCustomerName,
      selfPhoneNumber: values?.selfPhoneNumber,
      assignTo: selectedAssignTo?.userId ?? userDetails?.userDetails?.userId,
      assignToBackOffice: selectedBackOfficeUser?.userId
        ? selectedBackOfficeUser?.userId
        : "",
      connectorCustomerName: values?.connectorCustomerName,
      connectorId:
        selectedConnector?.userDetails?.userId ??
        userDetails?.userDetails?.userId,
      mobileNumber: values?.mobileNumber,
      searchCustomer: values?.searchCustomer,
      tentativePayout: values?.tentativePayout,
      loanType: values?.loanType,
      applicantType: selectedLeadApplicationType,
      individualType: applicantTypesIndividualValue
        ? applicantTypesIndividualValue
        : "",
      businessType: applicantTypesBusinessValue
        ? applicantTypesBusinessValue
        : "",
    };
    continueToProductScreen(selfPayload);
  };

  const onSelectExistingCustomer = (event: any) => {
    const customers = [...searchedCustomers];
    const customer = customers.find(
      (item: any) => item?.customerId === event?.props?.children
    );
    setSelectedCustomer(customer);
  };

  const onSearchExistingCustomer = (e: any) => {
    API_SERVICE.searchCustomers(e).then(({ data }: any) => {
      setSearchedCustomers(data?.payload ?? []);
      addLeadForm.setFieldsValue({
        selfCustomerName: data?.payload?.at(0)?.contactDetails?.firstName,
      });
    });
  };

  const onSearchConnector = (e: any) => {
    const params = {
      name: e,
    };
    API_SERVICE.getConnector(params).then(({ data }: any) => {
      if (data.payload.content.length > 0) {
        const tmpConnectors = data.payload?.content?.filter(
          (item: any) => item?.userDetails?.active
        );
        setAllConnectors(tmpConnectors ?? []);
      }
    });
  };

  const onSelectConnector = (event: any) => {
    const connector = [...allConnectors];
    const connectorItem: any = connector.find(
      (item: any) => item.userDetails.userId === event?.props?.children
    );
    setSelectedConnector(connectorItem);
    addLeadForm.setFieldsValue({
      tentativePayout: connectorItem?.payoutPercentage,
    });
  };

  const searchAssignToBackOffice = (ev: any) => {
    if (!ev) {
      setIsClearBackOficer(true);
    }
    const params = {
      searchParam: ev,
      department: "Back Office",
    };
    API_SERVICE.getUsersByDepartment(params).then(({ data }) => {
      if (data) {
        setAssignToBackOfficeData(data.payload);
      }
    });
  };

  const selectBackOfficeUser = (item, opt) => {
    // setSelectedBackOfiiceUserId()
    setIsClearBackOficer(false);
    setSelectedBackOfficeUser(opt);
  };

  const searchAssignTo = (ev: any) => {
    const params = {
      name: ev,
    };
    API_SERVICE.getUsersWithRole(params).then(({ data }) => {
      if (data) {
        setAssignToData(data.payload);
      }
    });
  };

  const valueExistingConnector = (connector) => {
    return (
      <span key={connector?.userDetails?.fullName}>
        {connector?.userDetails?.userId}
      </span>
    );
  };

  const handleProductValue = (e: RadioChangeEvent) => {
    console.log(e?.target?.value);
  };

  const goToManageLeads = (values: any) => {
    setLoading(true);
    const params = {
      caseId: caseId,
      loanProductId: values?.loanType,
      insuranceCaseOnline: values?.insuranceCaseOnline,
    }
    API_SERVICE.updateLoanProductandStartFlowable(params)
      .then(({ data }) => {
        setProductVersion(data?.payload?.productVersion);
        setUserData(data?.payload);
        setViewType("manageLead");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const continueToProductScreen = (userData: any) => {
    const payload = {
      sourceOfCase: userData?.selectType,
      assignToUserId: userData?.assignTo,
      backOfficeUserId: userData?.assignToBackOffice,
      assignToBackOffice:
        userData?.caseDetails?.backOfficeUserDetails?.fullName,
      commissionDetail: {
        connectorName:
          userData?.selectType != "SELF"
            ? userData?.selfCustomerName
            : userData?.connectorCustomerName,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: userData?.connectorId,
      customerId: selectedCustomer?.customerId || "",
      contactDetails: {
        gender: "M",
        firstName: userData?.selfCustomerName,
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        emailId: "",
        mobileNumber: userData?.selfPhoneNumber,
        pan: "",
      },
      applicationType: userData?.applicantType,
      applicationSubType:
        userData.applicantType === "BUSINESS"
          ? userData.businessType
          : userData.individualType,
      entityOrEmployerName: "",
      loanDetails: {
        expectedInterestRate: userData?.tentativePayout,
        loanAmount: null,
        loanTenureInYears: null,
        monthlyInHandSalary: null,
        currentEmis: null,
        educationLoanDetails: {
          admissionStatus: null,
          placeOfStudy: null,
          timeOfStudy: "",
        },
      },
    };
    setLoading(true);
    API_SERVICE.addCreateUserDetails(payload)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        setCaseId(data?.payload?.caseDetails?.caseId);
        setViewType("loanTypeList");
        setViewModel(false);
        setIsDisableSelectProduct(false);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const onChangeApplicationType = (e: any) => {
    setSelectedLeadApplicationType(e);
  };

  const onChangeIndividualType = (e: any) => {
    setApplicantTypesIndividualValue(e);
  };

  const onChangeBusinessType = (e: any) => {
    setApplicantTypesBusinessValue(e);
  };

  const renderSelfForm = () => {
    return (
      <>
        <FormSelect
          name="applicantType"
          label="Applicant Type"
          placeholder="Select Applicant Types"
          options={applicantTypes}
          onSelect={onChangeApplicationType}
          removeDefaultCss
          isRequired
        />

        {selectedLeadApplicationType === "INDIVIDUAL" ? (
          <FormSelect
            name="individualType"
            label="Individual Type"
            placeholder="Select Individual Types"
            options={individualTypes}
            onSelect={onChangeIndividualType}
            removeDefaultCss
            isRequired
          />
        ) : (
          <FormSelect
            name="businessType"
            label="Business Type"
            placeholder="Select Business Types"
            options={businessTypes}
            onSelect={onChangeBusinessType}
            removeDefaultCss
            isRequired
          />
        )}

        <FormInput
          name="selfPhoneNumber"
          label="Customer Mobile No"
          placeholder="Enter Mobile Number"
          removeDefaultCss
          onChange={(e: any) => {
            if (e?.target?.value?.length == 10) {
              onSearchExistingCustomer(e?.target?.value);
            }
          }}
          rules={[
            {
              required: true,
              message: "Please enter mobile number",
            },
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid Mobile number",
            },
          ]}
        />
        <FormInput
          name="selfCustomerName"
          label="Customer Name"
          placeholder="Enter Customer name"
          removeDefaultCss
          isRequired
        />
      </>
    );
  };

  const renderConnectorForm = () => {
    return (
      <>
        <Form.Item
          className="custom-option-list"
          name="connector"
          label="Connector Name"
          rules={[
            {
              required: true,
              message: "Please select connector",
            },
          ]}
        >
          <AutoComplete
            className="custom-auto-compleat"
            placeholder="Search connector"
            dropdownClassName="custom-option-list"
            onSelect={onSelectConnector}
            onSearch={onSearchConnector}
          >
            {allConnectors?.map((connector: any) => (
              <Select.Option
                key={connector.userDetails.userId}
                {...connector}
                value={valueExistingConnector(connector)}
              >
                <div>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={16}>
                      <span className="name">
                        {connector?.userDetails?.fullName}
                      </span>
                      <br />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={8}>
                      <span>
                        {connector?.userDetails?.phoneNumber.replace(
                          /\d(?=\d{4})/g,
                          "*"
                        )}
                      </span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                    <Col className="right" span={16}>
                      <span className="city">{connector?.address?.city}</span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                  </Row>
                </div>
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <FormInput
          name="tentativePayout"
          label="Tentative Payout %"
          placeholder="Enter payout %"
          removeDefaultCss={true}
          rules={[
            {
              required: false,
              message: "Please enter tentative payout %",
            },
            {
              pattern: /^\d*(\.)?(\d{0,2})?$/,
              message: "Please enter valid payout %",
            },
          ]}
        />
      </>
    );
  };

  const setIcon = (Icon: any, strokeFill: boolean = false) => {
    return (
      <Icon
        height={17}
        width={17}
        className={strokeFill ? "stroke-fill" : ""}
      />
    );
  };

  const getLoanIcon = (name: string) => {
    if (name == "Business Installment Loan") {
      return setIcon(IconBil);
    } else if (name == "Small Business Loan") {
      return setIcon(IconSmallBil, true);
    } else if (name == "Home loan") {
      return setIcon(IconHomeLoan, true);
    } else if (name == "Loan Against Property") {
      return setIcon(IconLap);
    } else if (name == "Commercial Purchase") {
      return setIcon(IconCommercialPurchase, true);
    } else if (name == "Credit Card") {
      return setIcon(IconCreditCard, true);
    } else if (name == "Life Insurance Policy") {
      return setIcon(IconLifeInsurance);
    } else if (name == "Service order financing" || name == "Service order  financing") {
      return setIcon(IconServiceOrderFinancing, true);
    } else if (name == "Unsecured Overdraft") {
      return setIcon(IconUnsecureOverdraft);
    } else if (name == "Working Capital Overdraft") {
      return setIcon(IconWorkingCapital);
    } else if (name == "Supply Chain Finance") {
      return setIcon(IconSupplyChainFinance, true);
    } else if (name == "Loan Against Securities") {
      return setIcon(IconLas, true);
    } else if (name == "Realty") {
      return setIcon(IconRealty, true);
    } else if (name == "Personal Loan") {
      return setIcon(IconPL);
    } else if (name == "Education Loan") {
      return setIcon(IconEL, true);
    } else if (name == "Buy Now Pay Later") {
      return setIcon(Iconbnpl, true);
    } else if (name == "Auto loan") {
      return setIcon(IconAutoLoan, true);
    } else if (name == "Two Wheeler Insurance") {
      return setIcon(IconTwoInsurance, true);
    } else if (name == "Four Wheeler Insurance") {
      return setIcon(IconFourInsurance, true);
    } else if (name == "Health Insurance") {
      return setIcon(IconHealthInsurance);
    } else if (name == "Micro LAP") {
      return setIcon(IconMicroLAP);
    }
    return setIcon(IconNewProduct, true);
  };

  const selectProduct = () => {
    const customStylesRadio: any = { '--color': appColors?.appPrimaryColor ?? "" };
    const customStylesProduct: any = {
      "--appPrimary": appColors?.appPrimaryColor ?? ""
    }; 

    return (
      <>
        {renderSelectCaseOption()}
        {/* {isDisableSelectProduct && <div className="disabled-view" />} */}
        <Form layout="vertical" 
          onFinish={(values: any) => {
            if(tenantInfo?.businessProductType === "INSURANCE"){
              setLoanType(values?.loanType);
              setShowCaseOption(true);
            } else {
              goToManageLeads(values)
            }
          }}
          >
          <Card
            className="custom-app-card lead-card"
            bordered={false}
            style={{
              background: "white",
            }}
          >
            <span className="lead-card-header">
              <h4 style={{color:isDisableSelectProduct?"gray":"black"}}>Select Product</h4>
            </span>
            <div className="lead-loanType">
              {/* <FormRadio
                name="loanType"
                options={getEnumList("LOAN_TYPE")}
                classNameRadio="product-radio"
                style={customStylesRadio}
                isIcon={true}
              /> */}
              <Form.Item name={"loanType"}>
                <Radio.Group
                  className={"product-radio"}
                  style={customStylesRadio}
                  disabled={isDisableSelectProduct}
                >
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                    <Space direction="vertical">
                      {getEnumList("LOAN_TYPE").map((option: any, i: number) => {
                        return (
                          <Radio key={i} value={option.key} className="custome-radio" style={customStylesProduct}>
                            {getLoanIcon(option.value)}{" "}
                            {option.value}
                          </Radio>
                        );
                      })}
                    </Space>
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
            <div style={{ display: "flex", gap: "10px", marginTop: "30px" }}>
              <Button
                size="large"
                className="button-submit dynamic-btn-default"
                style={customStylesBtn}
                disabled={isDisableSelectProduct}
                type="default"
                onClick={() => setViewModel(true)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="button-submit dynamic-btn-primary"
                style={customStylesBtnPrimary}
                disabled={isDisableSelectProduct}
                type="primary"
                htmlType="submit"
              >
                Continue
              </Button>
            </div>
          </Card>
        </Form>
      </>
    );
  };

  const createUser = () => {
    const customStylesRadio: any = { '--bgColor': appColors?.appSecondaryColor ?? "" };
    return (
      <>
        {(isEditV2 || !isDisableSelectProduct) && (
          <div className="disabled-view" />
        )}
        <Form
          layout="vertical"
          onFinish={onFinishForm}
          form={addLeadForm}
          initialValues={{
            assignTo: userDetails?.userDetails?.fullName,
          }}
        >
          <Card className="custom-app-card lead-card" bordered={false}>
            <span className="lead-card-header">
              <h4>{isEditV2 ? "Applicant Details" : "Applicant Details"}</h4>
            </span>
            <div className="form-box">
              <FormRadio
                label="Select"
                name="selectType"
                options={optionListSelfOrConnector}
                classNameRadio="custom-radio-new"
                style={customStylesRadio}
                onChange={onChangeSelectType}
                defaultValue={selectedApplicationType}
              />
              {selectedApplicationType !== "SELF" && renderConnectorForm()}
              {renderSelfForm()}
              <Form.Item
                label="Assign to sales user"
                name="assignTo"
                rules={[
                  {
                    required: true,
                    message: "Please select assign to sales user",
                  },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignTo, 1000)}
                  placeholder="Search assign to sales user"
                  dropdownClassName="custom-option-list"
                  onSelect={(item, opt) => {
                    setSelectedAssignTo(opt);
                  }}
                >
                  {assignToData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.address?.city && item?.address?.city}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <Form.Item
                //   className="field-bg"
                label="Assign to back office"
                name="assignToBackOffice"
                rules={[
                  {
                    required: false,
                    message: "Please select assign to back office",
                  },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignToBackOffice, 1000)}
                  placeholder="Search assign to back office"
                  dropdownClassName="custom-option-list"
                  onSelect={selectBackOfficeUser}
                >
                  {assignToBackOfficeData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.designation && item?.designation}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <FormRadio
                label="Do I know my product?"
                name="productRadio"
                options={optionListBtn}
                classNameRadio="custom-radio-new"
                style={customStylesRadio}
                onChange={handleProductValue}
                defaultValue={"YES"}
              />

              <Form.Item style={{ marginTop: "30px" }}>
                <Space>
                  <Button
                    size="large"
                    className="button-submit dynamic-btn-default"
                    style={customStylesBtn}
                    // htmlType="button"
                    onClick={() => setViewModel(true)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    className="button-submit dynamic-btn-primary"
                    style={customStylesBtnPrimary}
                    type="primary"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Card>
        </Form>
      </>
    );
  };

  const renderCreateUser = () => {
    return (
      <>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Row
              gutter={[24, 24]}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Col span={10} xs={24} xl={10}>{createUser()}</Col>
              <Col
                span={4}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  <img
                    src={rightArrow}
                    alt=""
                  // style={{ marginTop: "270px", marginLeft: "-99px" }}
                  />
                </span>
              </Col>
              <Col span={10} xs={24} xl={10}>{selectProduct()}</Col>
            </Row>
          </div>
        </div>
        {renderModel()}
      </>
    );
  };

  const renderModel = () => {
    return (
      <Modal
        width={300}
        visible={viewModel}
        closable={false}
        maskClosable={false}
        keyboard={false}
        footer={false}
        centered
      >
        <p className="msg">
          You will lose you entered data <br />
          if you wants to go Back
        </p>
        <div className="common-button">
          <Button
            size="large"
            className="button-submit dynamic-btn-default"
            style={customStylesBtn}
            type="default"
            onClick={() => setViewModel(false)}
          >
            Cancel
          </Button>
          <Button
            size="large"
            className="button-submit dynamic-btn-primary"
            style={customStylesBtnPrimary}
            type="primary"
            htmlType="submit"
            onClick={() => history.goBack()}
          >
            OK
          </Button>
        </div>
      </Modal>
    );
  };

  const renderSelectCaseOption = () => {
    return (
      <Modal
        centered
        width={350}
        footer={null}
        visible={showCaseOption}
        closable={false}
      >
        <div style={{ display: "grid", gap: "20px", marginTop: "20px" }}>
          <span>Select the Option</span>
          <Radio.Group
            className="dynamic-radio"
            style={{"--borderColor": appColors?.appPrimaryColor ?? ""} as any}
            onChange={(e) => {
              setInsuranceCaseOnline(e?.target?.value === "API" ? true : false);
            }}
            name="applicantType"
          >
            <Space direction="vertical">
              <Radio value={"API"} disabled>{"Send Case via API"}</Radio>
              <Radio value={"OFFLINE"}>{"Update Status of an Offline Case"}</Radio>
            </Space>
          </Radio.Group>
          <Space style={{ marginBottom: "20px" }}>
            <Button
              size="large"
              className="button-submit dynamic-btn-primary"
              style={customStylesBtnPrimary}
              loading={loading}
              disabled={insuranceCaseOnline == undefined}
              type="primary"
              onClick={() => {
                goToManageLeads({ loanType: loanType, insuranceCaseOnline: insuranceCaseOnline });
              }}
            >
              Submit
            </Button>
            <Button
              size="large"
              className="button-submit dynamic-btn-default"
              style={customStylesBtn}
              htmlType="button"
              onClick={() => setShowCaseOption(false)}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Modal>
    );
  };

  const renderView = () => {
    if (viewType == "manageLead") {
      if (productVersion == "V2") {
        return history.push(`/lead/details/${caseId}`);
      } else {
        return <AddLeadV1 setViewType={setViewType} userData={userData} />;
      }
    } else {
      return renderCreateUser();
    }
  };
  const customStylesBtn: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  const customStylesBtnPrimary:any = {'--btnColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box" style={{ paddingBlock: "20px" }}>
      <>{renderView()}</>
      {loading && <AppLoading />}
    </div>
  );
};
export default AddLeadNew;
