import React from 'react'

import AddLeadForm from 'shared/components/AddLeadForm/AddLeadForm';

type Props = {}

const AddLead: React.FunctionComponent<Props> = () => {
    return(
        <AddLeadForm/>
    )
}

export default AddLead;