import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DesktopLayout from 'shared/components/layout/Desktop/DesktopLayout';
import LeadDetails from 'shared/components/Lead/LeadDetails';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import AddConnectors from './Connector/AddConnector';
import ViewConnector from './Connector/ViewConnector';
import Connectors from './Connector/Connector';
import Dashboard from './Dashboard/Dashboard';
import Employees from './Employees/Employees';
import AddLead from './Lead/AddLead';
import Lead from './Lead/Lead';
import loanproduct from "./loan-product/loan-product"
import banner from "./banner/banner"
import notification from "./notification/notification"
import designation from "./designation/designation"
import department from "./department/department"
import location from "./location/location"
// import offers from "./Offers/offers"
import notices from "./Notices/notices"
import addnotice from "./Notices/addNotice"
import AddOffer from './Offers/addOffer';
import viewNotice from './Notices/viewNotice';
import editOffer from './Offers/editOffer';
import viewOffer from './Offers/viewOffer';
import editNotice from './Notices/editNotice';
import Lenders from './Lenders/Lenders';
import Insurer from './Insurer/Insurer';

import AppContent from "./app-content/app-content"
import AddEmployee from './Employe/addEmployees';
import ViewEmployee from './Employees/ViewEmployee';
import EditEmployee from './Employees/EditEmployee';
import Permissions from './Permission/Permissions';
import EditPermissions from './Permission/EditPermission';
import EditLead from './Lead/EditLead';
import NotificationBar from './noification-bar/notification-bar';
import ViewProfile from './Profile/ViewProfile';
import Reports from './Reports/reports';
import PayoutRequest from './PayoutRequest/payout_request';
import EditConnector from './Connector/edit-connectors/editConnector';
import { getUser, hasUserRole } from "shared/services/Utility";
import Offers from './Offers/offers';
import Attendance from './attendance/attendance';
import Help from './help/Help';
import ImportBulUpload from './Lead/bulkUpload/Import_bulkUpload';
import ImportBulUploadStep from './Lead/bulkUpload/bulk_upload_start';
import GreetingCards from './greeting-cards/greeting-crads';
import ViewGreetingCardDetails from './greeting-cards/view-greeting-card-details';
import DecisioningAudit from 'shared/components/DecisioningAudit/DecisioningAudit';
import VisitingCards from './Visiting-cards/visiting-crads';
import AddGreetingCards from './greeting-cards/AddGreetingCard';
import EditGreetingCards from './greeting-cards/edit-greeting-card';
import AddLender from './Lenders/AddLender/AddLender';
import AddInsurer from './Insurer/AddInsurer';
import EditLender from './Lenders/EditLender';
import MoneyWideTemplete from 'components/MoneyWide/templet';
//import NewLeadPage from 'shared/components/Lead/Lead';
import POC from './Connector/POC/poc';
import AddLeadNew from '../shared/components/LeadNew/AddLeadNew/AddLeadNew';
import LeadDetailNew from 'shared/components/LeadNew/LeadDetail/LeadDetailNew';
import AppLayout from 'shared/components/layout/AppLayout';
import BulkUpdateImport from './Lead/BulkUpdate/BulkUpdateImport';
import BulUpdateStepImport from './Lead/BulkUpdate/BulkUpdateStart';
import EditInsurer from './Insurer/EditInsurer';



type Props = {};

const userRolePermission = hasUserRole();

const Layout: React.FunctionComponent<Props> = () => {
    // const AppLayout = DesktopLayout;
    console.log("userRolePermission: ", userRolePermission);

    const isAdmin = userRolePermission["Admin"];
    const isReadOnly = userRolePermission["ReadOnly"];
    const isCreateLead = userRolePermission["CreateLead"];
    const isUpdateLead = userRolePermission["UpdateLead"];
    const isUpdateOperations = userRolePermission["UpdateOperations"];
    const isCreateOperations = userRolePermission["CreateOperations"];
    const isCreateUser = userRolePermission["CreateUser"];
    const isUpdateUser = userRolePermission["UpdateUser"];
    const isReports = userRolePermission["Reports"] || userRolePermission["UserLevelReports"];
    const isViewPayout = userRolePermission["ViewPayout"];
    const isAssignConnector = userRolePermission["AssignConnector"]
    const isUpdateConnector = userRolePermission["UpdateConnector"]
    const isApprovePayout = userRolePermission["ApprovePayout"]
    return (
        <AuthConsumer>
            {({ isAuth, apiCreds }) => {
                return isAuth ? (
                    <AppLayout>
                        <Switch>
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path="/dashboard" component={Dashboard} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path="/lead" component={Lead} />
                            }
                            {/*(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path="/new-lead" component={NewLeadPage} />*/
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path={"/lead/add-lead-old"} component={AddLead} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path={"/lead/add-lead"} component={AddLeadNew} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path={"/lead/details/:id"} component={LeadDetails} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path={"/lead/edit/:id"} component={AddLead} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isAssignConnector || isUpdateConnector) &&
                                <Route exact path="/connectors" component={Connectors} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path="/connectors/add-connector" component={AddConnectors} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path={"/connectors/:id"} component={ViewConnector} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path={"/connectors/connector-edit/:id"} component={EditConnector} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isViewPayout) &&
                                <Route exact path="/payout-request" component={PayoutRequest} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path="/lead/bulkUpload" component={ImportBulUpload} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead) &&
                                <Route exact path="/lead/bulkUpload/import-bulkupload" component={ImportBulUploadStep} />
                            }
                            {(isAdmin || isApprovePayout || getUser()?.userDetails?.department?.toLowerCase() == "Back Office") &&
                                <Route exact path="/lead/bulkUpdate" component={BulkUpdateImport} />
                            }
                            {(isAdmin || isApprovePayout || getUser()?.userDetails?.department?.toLowerCase() == "Back Office") &&
                                <Route exact path="/lead/bulkUpdate/import-bulkUpdate" component={BulUpdateStepImport} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path="/offers" component={Offers} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path="/offers/add-offer" component={AddOffer} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path={"/offers/edit-offer/:id"} component={editOffer} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path={"/offers/view-offer/:id"} component={viewOffer} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path="/notices" component={notices} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path="/notices/add-notice" component={addnotice} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path={"/notices/edit-notice/:id"} component={editNotice} />
                            }
                            {(isAdmin || isReadOnly || isCreateLead || isUpdateLead || isUpdateOperations || isCreateOperations) &&
                                <Route exact path={"/notices/view-notice/:id"} component={viewNotice} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path="/employees" component={Employees} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path={"/employees/add-employee"} component={AddEmployee} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path="/employee/add-employee" component={AddEmployee} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path={"/employees/:id"} component={ViewEmployee} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path={"/employees/edit/:id"} component={EditEmployee} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path="/location" component={location} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path="/department" component={department} />
                            }
                            {(isAdmin || isCreateUser || isUpdateUser) &&
                                <Route exact path="/designation" component={designation} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/load-product" component={loanproduct} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/lenders" component={Lenders} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/lender/add" component={AddLender} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/lender/edit" component={EditLender} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/insurer" component={Insurer} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/insurer/add" component={AddInsurer} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/insurer/edit" component={EditInsurer} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/insurer/view" component={EditInsurer} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/app-content" component={AppContent} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/greeting-cards" component={GreetingCards} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/greeting-cards/AddGreetingCard" component={AddGreetingCards} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/greeting-cards/view-greeting-card-details/:id" component={ViewGreetingCardDetails} />
                            }
                            {(isAdmin || isCreateOperations || isUpdateOperations) &&
                                <Route exact path="/greeting-cards/edit-greeting-card/:id" component={EditGreetingCards} />
                            }
                            {(isAdmin || isReports) &&
                                <Route exact path={"/report"} component={Reports} />
                            }
                            {(isAdmin) &&
                                <Route exact path={"/permissions"} component={Permissions} />
                            }
                            {(isAdmin) &&
                                <Route exact path={"/permissions/edit-permission/:id"} component={EditPermissions} />
                            }
                            {(isAdmin) &&
                                <Route exact path={"/decisioning-audit"} component={DecisioningAudit} />
                            }
                            <Route exact path="/visiting-cards" component={VisitingCards} />
                            <Route exact path="/notificationbar" component={NotificationBar} />
                            <Route exact path="/banners" component={banner} />
                            <Route exact path="/notification" component={notification} />
                            <Route exact path={"/view-profile"} component={ViewProfile} />
                            <Route exact path={"/attendance"} component={Attendance} />
                            <Route exact path={"/help"} component={Help} />
                            <Route exact path={"/poc"} component={POC} />
                            {/* <Route exact path="/tnc/:caseID" component={MoneyWideTemplete} /> */}

                            <Redirect to={{ pathname: "/dashboard" }} />

                        </Switch>
                    </AppLayout>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
                );
            }}
        </AuthConsumer>
    );
};

export default Layout;
