import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Tabs,
  notification
} from "antd";
import API_SERVICE from "shared/services/api-service";
// import "./index.scss";
import { Link, useHistory, useParams } from "react-router-dom";
import ConnectorKycDetails from "./ConnectorKycDetails";
import ConnectorOtherDetails from "./ConnectorOtherDetails";
import ConnectorPayoutDetails from "./ConnectorpayoutDetails";
import ConnectorBasicDetails from "./connectorBasicDetails";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { TabPane } = Tabs;
const { Title, Text } = Typography;
type Props = {};

const EditConnector: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [connector, setConnector] = useState({} as any);
  const [activeKey, setActiveKey] = useState('1');
  const [disableValue, setDisableValue] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  let { id } = useParams() as any;
  if (!id) {
    history.goBack();
  }


  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Connector",
        backScreenPath: "/connectors",
        screenTitle: `${(connector && connector.basicDetails) ? (connector.basicDetails.name) : ""}`,
      })
    );
  }, [connector]);


  const onTabChange = (key: any) => {
    setActiveKey(key);
  };


  return (
    <>
      <div className="content-box">
        <Row>
          <Col className="btn-box" span={24} >
            <Tabs activeKey={activeKey} onChange={onTabChange} type="card" className="custom-app-tab dynamic-tabs"  style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}>
              <TabPane tab="Basic Details" key="1">
                <ConnectorBasicDetails connector={connector} setConnector={setConnector} disableValue={disableValue} />
              </TabPane>
              <TabPane tab="KYC Details" key="2">
                <ConnectorKycDetails connector={connector} setConnector={setConnector} disableValue={disableValue} />
              </TabPane>
              {(id === connector?.basicDetails?.ownerConnectorUserId || connector?.basicDetails?.connectorAdditionalInfo?.canAddBankInfo) &&
                <TabPane tab="Payout Details" key="3">
                  <ConnectorPayoutDetails connector={connector} setConnector={setConnector} disableValue={disableValue} />
                </TabPane>
              }
              <TabPane tab="Other Details" key="4">
                <ConnectorOtherDetails connector={connector} setConnector={setConnector} disableValue={disableValue} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditConnector;
