import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  notification,
  Space,
  Slider,
  Select,
  Modal,
} from "antd";
import "./offerDetail.scss";
import { numberWithCommas } from "shared/services/Utility";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
interface ABCLOfferDetail {
  data?: any;
  responseJSONSchema: any;
  selectedLander: any;
  leadData: any;
  handleFormSubmit: any;
  setCloseConfirmation: any;
}
const ABCLOfferDetails: React.FunctionComponent<ABCLOfferDetail> = (props) => {
  const [loanData, setLoanData] = useState({});
  const [loanId, setLoanID] = useState();

  const [minAmount, setMinAmount] = useState<any>(0);
  const [maxAmount, setMaxAmount] = useState<any>(100);

  // const [maxTenure, setMaxTenure] = useState(0);
  // const [minTenure, setMinTenure] = useState(100);
  const [tenureList, setTenureList] = useState<any>();

  const [requestedAmount, setRequestedAmount] = useState<any>(0);
  const [requestedTenure, setRequestedTenure] = useState<any>(0);

  const [processingFees, setProcessingFees] = useState<any>(0);
  const [gst, setGst] = useState(0);
  const [disbursedAmount, setDisbursedAmount] = useState<any>(0);

  const [isOfferDeclined, setIsOfferDeclined] = useState(false);

  const [isValuesUpdated, setIsValuesUpdated] = useState(false);

  const [offerMetaData, setOfferMetaData] = useState<any>([]);
  const [offerMetaDataTenure, setOfferMetaDataTenure] = useState<any>([]);
  const [roi, setRoi] = useState(0);
  const [emi, setEMI] = useState<any>(0);
  const [offerID, setOfferID] = useState("");

  const {appColors}: any = useSelector((state: RootState) => state.common);
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "", paddingInline: "30px"};
  const btnDefaultStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};

  useEffect(() => {
    console.log("props.responseJSONSchema", props);
    API_SERVICE.getOffersForABCL(
      props.selectedLander?.lenderInfoId,
      props.leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          console.log('data', data);
          const OfferData = JSON.parse(data.payload);
          // const OfferData = JSON.parse(props.responseJSONSchema)
          if (OfferData.data.offers[0].slabs.length > 0) {
            console.log("OfferData", OfferData[0]);
            console.log("OfferData.lead", props.leadData);
            // setGst(OfferData[0].gst);
            // setRoi(OfferData[0].interest);
            // setOfferID(OfferData[0].offerID);
            // setOfferMetaData(OfferData[0].offerMetadata);
            // updateValues(OfferData[0].offerMetadata);
            // console.log("data", OfferData);

            //setGst(OfferData.data.dataRequired.gst);
            //setRoi(OfferData[0].interest);
            setOfferID(OfferData.data.offers[0].offerID);
            setOfferMetaData(OfferData.data.offers[0].slabs);
            updateValues(OfferData.data.offers[0].slabs);
            console.log("data", OfferData);

          }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
    // const OfferData1 = {
    //   offerID: "b99827dc-07cc-4b6e-a9b7-c95eadd8eac9",
    //   amount: 484040,
    //   maxAmount: 484040,
    //   minAmount: 10000,
    //   interest: 20,
    //   processingFee: 3,
    //   processingFeeType: "PERC",
    //   gst: 18,
    //   createdAt: "2024-02-08T07:11:36.806401Z",
    //   lenderName: "Aditya Birla Finance Limited",
    //   tenure: 36,
    //   maxTenure: 36,
    //   minTenure: 12,
    //   method: "rb",
    //   lenderID: "c1a55e53-4701-49a1-923c-3cf32a66b5ec",
    //   lenderLogoURL:
    //     "https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/abfl_logo.jpeg",
    //   status: "ACTIVE",
    //   offerType: "booster",
    //   isAdvanceEMI: false,
    //   recommended: false,
    //   offerMetadata: [
    //     {
    //       maxAmount: 200000,
    //       minAmount: 100000,
    //       tenure: 12,
    //       processingFee: 2.75,
    //     },
    //     {
    //       maxAmount: 200000,
    //       minAmount: 100000,
    //       tenure: 18,
    //       processingFee: 2.75,
    //     },
    //     {
    //       maxAmount: 200000,
    //       minAmount: 100000,
    //       tenure: 24,
    //       processingFee: 2.75,
    //     },
    //     {
    //       maxAmount: 200000,
    //       minAmount: 100000,
    //       tenure: 36,
    //       processingFee: 2.75,
    //     },
    //     {
    //       maxAmount: 217000,
    //       minAmount: 200001,
    //       tenure: 12,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 311000,
    //       minAmount: 200001,
    //       tenure: 18,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 396000,
    //       minAmount: 200001,
    //       tenure: 24,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 484040,
    //       minAmount: 200001,
    //       tenure: 36,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 217000,
    //       minAmount: 217000,
    //       tenure: 12,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 311000,
    //       minAmount: 311000,
    //       tenure: 18,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 396000,
    //       minAmount: 396000,
    //       tenure: 24,
    //       processingFee: 3,
    //     },
    //     {
    //       maxAmount: 484040,
    //       minAmount: 484040,
    //       tenure: 36,
    //       processingFee: 3,
    //     },
    //   ],
    // };
  }, []);

  const updateValues = (offer: any) => {
    const sortData = offer.filter((item) => item.maxAmount > 0).sort(
      (a: { maxAmount: number }, b: { maxAmount: number }) =>
        a.maxAmount - b.maxAmount
    );
    const onlyMax = [
      ...Array.from(
        new Set(
          sortData
            .map((item: { maxAmount: any }) => item.maxAmount)
            .sort((a: number, b: number) => a - b)
        )
      ),
    ];
    const onlyMin = [
      ...Array.from(
        new Set(
          sortData
            .map((item: { minAmount: any }) => item.minAmount)
            .sort((a: number, b: number) => a - b)
        )
      ),
    ];
    const onlyTenure = [
      ...Array.from(
        new Set(sortData.map((item) => item.tenure).sort((a, b) => a - b))
      ),
    ];
    setMaxAmount(onlyMax.at(-1));
    setMinAmount(onlyMin.at(0));
    setRequestedAmount(onlyMax.at(-1));
    onChangeRequestedAmt(onlyMax.at(-1), offer);

    // onChangeTenure(onlyTenure[0]);
    // setRequestedTenure(onlyTenure[0]);
    // setTenureList(onlyTenure);
    // const processingData = sortData.find((item) => {
    //   if (item.tenure === onlyTenure[0]) {
    //     return item.processingFee;
    //   }
    // });

    // const roi = sortData.find((item) => {
    //   if (item.tenure === onlyTenure[0]) {
    //     return item.gst;
    //   }
    // });

    // const gst = sortData.find((item) => {
    //   if (item.tenure === onlyTenure[0]) {
    //     return item.interest;
    //   }
    // });

    // console.log('processingData', processingData);
    // setProcessingFees(processingData.processingFee);
    // setGst(gst?.gst);
    // setRoi(roi?.interest);
  };

  const updateTenureValues = (offer: any) => {
    const sortData = offer.filter((item) => item.maxAmount > 0).sort(
      (a: { maxAmount: number }, b: { maxAmount: number }) =>
        a.maxAmount - b.maxAmount
    );
    // const onlyMax = [
    //   ...Array.from(
    //     new Set(
    //       sortData
    //         .map((item: { maxAmount: any }) => item.maxAmount)
    //         .sort((a: number, b: number) => a - b)
    //     )
    //   ),
    // ];
    // const onlyMin = [
    //   ...Array.from(
    //     new Set(
    //       sortData
    //         .map((item: { minAmount: any }) => item.minAmount)
    //         .sort((a: number, b: number) => a - b)
    //     )
    //   ),
    // ];
    const onlyTenure = [
      ...Array.from(
        new Set(sortData.map((item) => item.tenure).sort((a, b) => a - b))
      ),
    ];
    const onlyProcessingFees = [
      ...Array.from(
        new Set(
          sortData.map((item) => item.processingFee).sort((a, b) => a - b)
        )
      ),
    ];
    // setMaxAmount(onlyMax.at(-1));
    // setMinAmount(onlyMin.at(0));
    setRequestedTenure(onlyTenure[0]);
    onChangeTenure(onlyTenure[0]);
    console.log("offerMetaDataTenure", offerMetaDataTenure);
    // const processingData = offerMetaDataTenure.find((item) => {
    //   if (item.tenure === onlyTenure[0]) {
    //     return item.processingFee;
    //   }
    // });
    // setProcessingFees(processingData.processingFee);
    setTenureList(onlyTenure);
  };
  // #region
  //
  const silderMarkRequestedAmount = {
    [minAmount]: {
      style: {
        transform: "translateX(0%)",
        width: "max-content",
      },
      label: <strong>Min: ₹ {numberWithCommas(minAmount)} </strong>,
    },
    [maxAmount]: {
      style: {
        transform: "translateX(-100%)",
        width: "max-content",
      },
      label: <strong>Max: ₹ {numberWithCommas(maxAmount)}</strong>,
    },
  };
  
  const onChangeRequestedAmt = (value: any, offerData: any = null) => {
    if (offerData) {
      const updatedData = offerData.filter((item) => {
        if (item.maxAmount >= value && value >= item.minAmount) {
          return item;
        }
      });
      setIsValuesUpdated(true);
      console.log("updatedData", updatedData);
  
      // const processingData = updatedData.find((item) => {
      //   if (item.tenure === requestedTenure) {
      //     return item.processingFee;
      //   }
      // });
      // setProcessingFees(processingData?.processingFee);
      setOfferMetaDataTenure(updatedData);
      updateTenureValues(updatedData);
      setRequestedAmount(value);
      // calculateDisburesamt();
    } else {
      const updatedData = offerMetaData.filter((item) => {
        if (item.maxAmount >= value && value >= item.minAmount) {
          return item;
        }
      });
      setIsValuesUpdated(true);
      console.log("updatedData", updatedData);
  
      // const processingData = updatedData.find((item) => {
      //   if (item.tenure === requestedTenure) {
      //     return item.processingFee;
      //   }
      // });
      // setProcessingFees(processingData?.processingFee);
      setOfferMetaDataTenure(updatedData);
      updateTenureValues(updatedData);
      setRequestedAmount(value);
      // calculateDisburesamt();
    }
  };
  const onChangeTenure = (value: any) => {
    console.log('value', value);
    console.log("offerMetaDataTenure", offerMetaDataTenure);
    // offerMetaDataTenure.some((item) => {
    //   if (item.tenure === value) {
    //     setProcessingFees(item.processingFee);
    //   }
    // });
    // const processingData = offerMetaDataTenure.find((item) => {
    //   if (item.tenure === value) {
    //     return item.processingFee;
    //   }
    // });
    // setProcessingFees(processingData.processingFee);
    setRequestedTenure(value);
    // calculateDisburesamt()
  };
  useEffect(() => {
    console.log('offerMetaDataTenure', offerMetaDataTenure);
    const processingData = offerMetaDataTenure.find((item) => {
      if (item.tenure === requestedTenure) {
        return item.processingFee;
      }
    });

    const gst = offerMetaDataTenure.find((item) => {
      if (item.tenure === requestedTenure) {
        return item.gst;
      }
    });

    const roi = offerMetaDataTenure.find((item) => {
      if (item.tenure === requestedTenure) {
        return item.interest;
      }
    });
    console.log('processingData', processingData);
    setProcessingFees(processingData?.processingFee);
    setGst(gst?.gst);
    setRoi(roi?.interest);
  }, [requestedTenure, offerMetaDataTenure]);
  useCallback(() => {}, []);
  const calculateDisburesamt = useEffect(() => {
    const finalProcessingAmt = Math.round((requestedAmount * processingFees) / 100);
    const finalGstAmt = Math.round((finalProcessingAmt * gst) / 100);
    const disbursedValue = Math.round((
      requestedAmount -
      (finalProcessingAmt + finalGstAmt)
    )).toFixed(2);
    setDisbursedAmount(disbursedValue);
    calculateHomeLoanEmi(roi, requestedTenure, requestedAmount);
  }, [requestedTenure, requestedAmount, processingFees, gst, roi]);

  //Calculate ROI
  const calculateHomeLoanEmi = (roi, loanTenure, loanAmount) => {
    const monthlyInterestRate = roi / 100 / 12;
    const totalPayments = loanTenure;
    const presentValue = -1 * loanAmount;

    const emi =
      (monthlyInterestRate * presentValue) /
      (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));

    // Assuming you want to round off the result
    const roundedEmi = Math.round(emi * 100) / 100;
    setEMI(Math.abs(roundedEmi));
    return roundedEmi;
  };
  //submit data reque.
  const handleSubmitReq = () => {
    const data = {
      amount: requestedAmount,
      tenure: requestedTenure,
      offerID: offerID,
      interest: roi,
      processingFee: processingFees,
    };
    props.handleFormSubmit({
      data: { offerDetails: data },
    });
  };

  const dummyData = {
    offerID: "b99827dc-07cc-4b6e-a9b7-c95eadd8eac9",
    amount: 484040,
    maxAmount: 484040,
    minAmount: 10000,
    interest: 20,
    processingFee: 3,
    processingFeeType: "PERC",
    gst: 18,
    createdAt: "2024-02-08T07:11:36.806401Z",
    lenderName: "Aditya Birla Finance Limited",
    tenure: 36,
    maxTenure: 36,
    minTenure: 12,
    method: "rb",
    lenderID: "c1a55e53-4701-49a1-923c-3cf32a66b5ec",
    lenderLogoURL:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/assets/abfl_logo.jpeg",
    status: "ACTIVE",
    offerType: "booster",
    isAdvanceEMI: false,
    recommended: false,
    offerMetadata: [
      {
        maxAmount: 200000,
        minAmount: 100000,
        tenure: 12,
        processingFee: 2.75,
      },
      {
        maxAmount: 200000,
        minAmount: 100000,
        tenure: 18,
        processingFee: 2.75,
      },
      {
        maxAmount: 200000,
        minAmount: 100000,
        tenure: 24,
        processingFee: 2.75,
      },
      {
        maxAmount: 200000,
        minAmount: 100000,
        tenure: 36,
        processingFee: 2.75,
      },
      {
        maxAmount: 217000,
        minAmount: 200001,
        tenure: 12,
        processingFee: 3,
      },
      {
        maxAmount: 311000,
        minAmount: 200001,
        tenure: 18,
        processingFee: 3,
      },
      {
        maxAmount: 396000,
        minAmount: 200001,
        tenure: 24,
        processingFee: 3,
      },
      {
        maxAmount: 484040,
        minAmount: 200001,
        tenure: 36,
        processingFee: 3,
      },
      {
        maxAmount: 217000,
        minAmount: 217000,
        tenure: 12,
        processingFee: 3,
      },
      {
        maxAmount: 311000,
        minAmount: 311000,
        tenure: 18,
        processingFee: 3,
      },
      {
        maxAmount: 396000,
        minAmount: 396000,
        tenure: 24,
        processingFee: 3,
      },
      {
        maxAmount: 484040,
        minAmount: 484040,
        tenure: 36,
        processingFee: 3,
      },
    ],
  };
  // const onChangeEmiCalculations = () => {
  //   setIsOfferLoading(true);
  //   const payload = {
  //     reqAmount: requestedAmount,
  //     reqTenure: requestedTenure,
  //     stLoanId: loanId,
  //   };
  //   API_SERVICE.getUpdatedICICIEmiCal(
  //     props.selectedLander?.lenderInfoId,
  //     props.leadData?.caseDetails?.caseId,
  //     payload
  //   )
  //     .then(({ data }) => {
  //       if (data) {
  //         console.log("data", JSON.parse(data?.payload));
  //         const updateData = JSON.parse(data?.payload);
  //         if (updateData) {
  //           setLoanEligiblityDetails(updateData);
  //           setLoanStatus(updateData.eligibilityDtls[0].status);
  //           // setApplicantData(data?.payload?.applicants);
  //           setLoanData(dummyData);
  //           setRequestedAmount(
  //             parseInt(updateData.eligibilityDtls[0].expectedLoanAmt)
  //           );
  //           setRequestedTenure(
  //             parseInt(updateData.eligibilityDtls[0].expectedTenure)
  //           );

  //           setMaxTenure(parseInt(updateData.eligibilityDtls[0].maxTenure));
  //           setMinTenure(parseInt(updateData.eligibilityDtls[0].minTenure));

  //           if (
  //             updateData.eligibilityDtls[0].minLoanAmt
  //               .toLowerCase()
  //               .includes("e")
  //           ) {
  //             const updatedData = Number(
  //               updateData.eligibilityDtls[0].minLoanAmt
  //             ).toLocaleString("fullwide", { useGrouping: false });
  //             setMinAmount(parseInt(updatedData));
  //           } else {
  //             setMinAmount(parseInt(updateData.eligibilityDtls[0].minLoanAmt));
  //           }
  //           if (
  //             updateData.eligibilityDtls[0].maxLoanAmt
  //               .toLowerCase()
  //               .includes("e")
  //           ) {
  //             const updatedData = Number(
  //               updateData.eligibilityDtls[0].maxLoanAmt
  //             ).toLocaleString("fullwide", { useGrouping: false });
  //             setMaxAmount(parseInt(updatedData));
  //           } else {
  //             setMaxAmount(parseInt(updateData.eligibilityDtls[0].maxLoanAmt));
  //           }

  //           //
  //           setMonthlyEmi(parseInt(updateData.eligibilityDtls[0].emi));
  //           // setInterestRate(parseFloat(updateData.eligibilityDtls[0].rate));
  //           setLoginFees(parseInt(updateData.eligibilityDtls[0].initialFee));
  //           setOfferedLoanAmount(
  //             parseInt(updateData.eligibilityDtls[0].expectedLoanAmt)
  //           );
  //           //
  //         }
  //         setIsValuesUpdated(false);
  //         setIsOfferLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       notification.error({ message: API_SERVICE.handleErrors(error) });
  //     });
  // };
  // #endregion
  return (
    <div className="offer-details">
      {/* / */}
      <div>
        <div style={{ width: "100%" }} className="main-section">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              color: "#273896",
            }}
          >
            <span style={{ fontSize: "19px", fontWeight: "bold" }}>You have an offer of</span>
            <span style={{ fontSize: "33px", fontWeight: "bolder" }}>
            ₹ {numberWithCommas(requestedAmount)}
            </span>
          </div>
        </div>
        <div id="slider" className="slider-amt">
          <Slider
            marks={silderMarkRequestedAmount}
            min={minAmount}
            max={maxAmount}
            defaultValue={requestedAmount}
            value={requestedAmount}
            trackStyle={{ backgroundColor: "#273896" }}
            handleStyle={{ border: "solid 2px #273896" }}
            onChange={(val) => onChangeRequestedAmt(val)}
            // railStyle={{

            // }}
            style={{
              backgroundColor: "transparent",
            }}
          />
        </div>
        <div
          id="rTenure"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
          className="req-tenure"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span className="req-tenure-val">Tenure</span>
            <Select
              className="custom-input tenure-select"
              style={{ color: "black", width: "180px" }}
              value={`${requestedTenure} Months`}
              key={`${requestedTenure} Months`}
              onChange={(e) => onChangeTenure(e)}
            >
              {tenureList?.map((item) => {
                return <Select.Option value={item}> {`${item} Months`} </Select.Option>;
              })}
            </Select>
          </div>
        </div>
        <div id="emiCal" className="emival">
          <span className="emiText">EMI</span>
          <span className="emiValue">
            <span className="emiValue-amt">₹ {numberWithCommas(emi.toFixed(0))} &nbsp; </span>
            <span className="emiValue-roi">@ {roi}%</span>
          </span>
          <span className="emiText">Interest p.a.</span>
        </div>
        <div className="amt-breackdown">
          <div className="amt-breackdown-header">
            <span>Disbursal Amount Breakdown</span>
          </div>
          <div className="amt-details">
            <div className="amt-detail-row">
              <span>Loan Amount</span>
              <span>₹ {numberWithCommas(requestedAmount)}</span>
            </div>
            <div className="amt-detail-row">
              <span>Processing Fee @ {numberWithCommas(processingFees)}%</span>
              <span>
                {isNaN(processingFees)
                  ? " -"
                  : `- ₹ ${numberWithCommas(
                      ((requestedAmount * processingFees) / 100).toFixed(0)
                    )}`}
              </span>
            </div>
            <div className="amt-detail-row">
              <span>GST @ {gst?.toFixed(0)}% </span>
              <span> - ₹{numberWithCommas(
                          (
                            Math.round((((requestedAmount * processingFees) / 100) * gst) /
                            100)
                          )
                        )}</span>
            </div>
          </div>
          <div className="line-dotet"></div>
          <div className="final-amt">
            <span>Total Disbursal Amount</span>
            <span>₹{numberWithCommas(Math.round(disbursedAmount))}</span>
          </div>
        </div>
      </div>
      {/*  */}

      <div>
        {loanData ? (
          <>
            {/* {loanStatus === "N" && (
              <div
                className="py-3 mt-2"
                style={{
                  backgroundColor: "rgb(251 209 209)",
                }}
              >
                <Row>
                  <div className="ml-2" style={{ marginLeft: "10px" }}>
                    You are not eligible for this offer. Please update amount
                    and tenure to get valid Offer.
                  </div>
                </Row>
              </div>
            )}
            <Row>
              <Col span={12}>
                <div style={{ padding: "5px" }}>
                  <Row style={{ alignItems: "center", margin: "0px 6px" }}>
                    <Col span={12}>
                      <span>Requested Amount</span>
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        className="custom-input"
                        value={requestedAmount}
                        formatter={(value) => numberWithCommas(value)}
                        style={{ color: "black" }}
                        min={minAmount}
                        max={maxAmount}
                        controls={false}
                        //   disabled={items.verified}
                        onChange={(value) => onChangeRequestedAmt(value)}
                        placeholder="Requested Amount"
                      />
                    </Col>
                  </Row>
                  <Slider
                    marks={silderMarkRequestedAmount}
                    min={minAmount}
                    max={maxAmount}
                    defaultValue={requestedAmount}
                    value={requestedAmount}
                    trackStyle={{ backgroundColor: "#273896" }}
                    handleStyle={{ border: "solid 2px #273896" }}
                    onChange={(val) => onChangeRequestedAmt(val)}
                    style={{
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ padding: "5px" }}>
                  <Row style={{ alignItems: "center", margin: "0px 6px" }}>
                    <Col span={10}>
                      <span>Requested Tenure (Months)</span>
                    </Col>
                    <Col span={2} />
                    <Col span={12}>
                      <Select
                        className="custom-input"
                        style={{ color: "black" }}
                        value={requestedTenure}
                        onChange={(e) => onChangeTenure(e)}
                      >
                        {tenureList?.map((item) => {
                          return (
                            <Select.Option value={item}> {item} </Select.Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>EMI</label>
                      <span style={{ fontWeight: "bolder" }}>₹ {emi}</span>
                      <label>
                        <span style={{ fontWeight: "bolder" }}>@ {roi} %</span>{" "}
                        Interest p.a.
                      </label>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
            <div
              className="py-3 mt-2"
              style={{
                backgroundColor: "#F5F5F5",
              }}
            >
              <Row>
                <Col span={24}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span
                      style={{
                        color: "#273896",
                        textAlign: "center",
                        fontFamily: "Avenir LT Std",
                        fontSize: "17px",
                        fontStyle: "normal",
                        fontWeight: "750",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                      }}
                    >
                      Your Final Loan Offer: ₹
                      {numberWithCommas(requestedAmount)}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className="mb-1 mt-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontFamily: "Avenir LT Std",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                      }}
                    >
                      Dear Customer you are eligible upto above amount based on
                      your income details
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mt-3">
                    <Row>
                      <Col
                        span={8}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Processing Fees: @
                        {isNaN(processingFees)
                          ? " -"
                          : `${numberWithCommas(
                              processingFees
                            )}%  ₹ ${numberWithCommas(
                              (
                                (requestedAmount * processingFees) /
                                100
                              ).toFixed(2)
                            )}`}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        GST:{" "}
                        {`${gst.toFixed(2)}%  ₹${numberWithCommas(
                          (
                            (((requestedAmount * processingFees) / 100) * gst) /
                            100
                          ).toFixed(2)
                        )}`}
                      </Col>
                      <Col
                        span={8}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Total Disbursed Amount: ₹
                        {isNaN(disbursedAmount)
                          ? " -"
                          : numberWithCommas(disbursedAmount)}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div> */}
          </>
        ) : (
          "Loading"
        )}
        {
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "auto",
            }}
          >
            <Modal
              width={600}
              visible={isOfferDeclined}
              // onCancel={() => onCancelPopup()}
              closable={false}
              footer={null}
              title=""
              maskClosable={true}
            >
              {/* <Row> */}
              <div style={{ margin: "10px", textAlign: "center" }}>
                Are you sure, You do not want to avail the Offer Generated?
              </div>
              {/* </Row> */}
              {/* <Row> */}
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <Button
                    className="dynamic-btn-default"
                    style={btnDefaultStyle}
                    htmlType="button"
                    onClick={() => {
                      // setShowAdditionalFields(false);
                      setIsOfferDeclined(false);
                    }}
                  >
                    Back to edit
                  </Button>
                </div>

                <div style={{ padding: "10px" }}>
                  <Button
                    size="middle"
                    className="dynamic-btn-primary"
                    style={btnPrimaryStyles}
                    type="primary"
                    htmlType="button"
                    // onClick={() => handleSubmitReq(false)}
                  >
                    I Confirm
                  </Button>
                </div>
              </div>
              {/* </Row> */}
            </Modal>
          </div>
        }

        {/* buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBlock: "15px",
          }}
        >
          <Space className="">
            {/* <Button
              htmlType="button"
              onClick={() => {
                props.setCloseConfirmation(true);
              }}
            >
              Close
            </Button> */}
            <Button
              size="middle"
              className="btn-offer dynamic-btn-primary"
              style={btnPrimaryStyles}
              type="primary"
              // disabled={loanStatus !== "Y" || isValuesUpdated}
              htmlType="button"
              onClick={() => handleSubmitReq()}
            >
              Accept Offer 
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ABCLOfferDetails;
