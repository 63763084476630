
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, notification, Input, Row, Space, Modal, Popconfirm, Divider } from 'antd';
import API_SERVICE from 'shared/services/api-service';

import {ReactComponent as FileBorder} from "../../../assets/images/file-border.svg";
import {ReactComponent as DownloadBlack} from "../../../assets/images/download-black.svg";
import { MdDelete } from 'react-icons/md';
import { base64ToArrayBuffer, saveByteArray } from 'shared/services/Utility';
import { setNotificationCount } from 'shared/redux/notificationCount-reducer';
import UploadDocument from '../Lead/UploadDocument/UploadDocument';
import {ReactComponent as UploadFile} from "../../../assets/images/upload-file.svg";
import {ReactComponent as ZipIcon} from "../../../assets/images/zip.svg";
import './index.scss';
import { RootState } from 'shared/redux/store';

const { TextArea } = Input;

const DocumentationList: React.FunctionComponent<any> = ({ leadData, lenderDocumentHandler }) => {

    const [uploadDocument, setUploadDocument] = useState(false);
    const [documentList, setDocumentList] = useState([] as any);
     const [userComment, setUserComment] = useState(null as any);
    const [documentSubTypeList, setDocumentSubTypeList] = useState([] as any);
    const dispatch = useDispatch();
    const {appColors}: any = useSelector((state: RootState) => state.common);

    useEffect(() => {
        setEnums();
        getDocumentList();
    }, [])

    const setEnums = () => {
        let enumValues = localStorage.getItem('enumValues') as any;
        if (enumValues) {
            enumValues = JSON.parse(enumValues)
            const docList = enumValues.find((item: any) => item.propertyName === 'DOCUMENT_LIST');
            setDocumentSubTypeList(docList?.keyAndDisplayValueList);
        }
    }

    const getDocumentList = () => {
        const documentSetId = leadData?.caseDetails?.documentSetId;
        API_SERVICE.getDocumentList(documentSetId).then(({ data }) => {
            if (data) {
                setDocumentList(data.payload);
            }
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }

    const getUnreadNotificationCnt = () => {
        API_SERVICE.getUnreadNotificationCnt()
          .then(({ data }) => {
            dispatch(setNotificationCount(data?.payload?.unReadCount));
          })
          .catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          });
      };

    const onDelete = (doc: any) => {
        API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
            if (data) {
                getDocumentList();
            }
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }

    const downloadDocument = (doc: any) => {
        API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
            let bytesData = base64ToArrayBuffer(data.payload.documentDetails)
            saveByteArray([bytesData], data.payload.documentName)
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }

    const downloadAllDocument = () => {
        const documentSetId = leadData?.caseDetails?.documentSetId;
        notification.success({message:"Your document Zip download is in process and will take some time to complete. Please check the notification inbox for the link to download once ready"}) 
        API_SERVICE.downloadAllDocument(documentSetId)
        .then(({ data }) => {
            getUnreadNotificationCnt()
        }).catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        })
    }
    
    const getType = (type) => {
        const filteredEmp = documentSubTypeList?.filter(item => item.key === type);
        if ((filteredEmp?.length ?? 0) > 0) {
            return filteredEmp[0].key;
            // return filteredEmp[0].value;
        }
        return type
    }

    const customStyles: any = {'--fileIconColor': appColors?.appPrimaryColor ?? ""};
    const btnPrimaryStyles : any = { '--btnColor' :appColors?.appPrimaryColor ?? "" };

    return (
        <div className = 'upload-document-wrapper'>
            <div className='upload-file'>
                <UploadFile  style={customStyles}/>
                <Button 
                    type="text" 
                    className={documentList.length > 0 ?'download-btn':'upload-btn'} 
                    onClick={() => setUploadDocument(true)}>{/*className='download-btn'*/}
                        Upload New
                </Button>
                {
                    documentList.length > 0 &&
                    <>
                        <Divider className = "custom-divider-hozntl" type = "vertical" orientation="left" orientationMargin="0"/>
                        <ZipIcon style={customStyles}/>
                        <Button type="text" className='download-btn' onClick={()=>{downloadAllDocument()}}>
                                Download All
                        </Button>
                    </>
                }
            </div>
            {
                uploadDocument &&
                <Modal
                    title="Upload New Document:1"
                    centered
                    width={400}
                    onCancel={() => { setUploadDocument(false); }}
                    footer={null}
                    visible={uploadDocument}>

                    <UploadDocument
                        loanProductId={leadData?.loanProductId}
                        documentSetId={leadData?.caseDetails?.documentSetId}
                        individualType={leadData?.applicationSubType}
                        documentList={documentList}
                        callBack={(isUpload) => {
                            setUploadDocument(false);
                            if (isUpload) {
                                getDocumentList();
                            }
                        }} />
                </Modal>
            }
            <Row>
                <div className='doc-list'>
                    <ul>
                        {documentList?.map((doc: any, i: number) => <li key={i}>
                            <div className="doc-details">
                                <FileBorder  style={customStyles}/>
                                <span>
                                    {doc.documentName}<br />
                                    <span className='type'>{getType(doc.documentSubType ?? doc.documentType)}</span>
                                </span>
                            </div>
                            <div className="doc-action">
                                <Space>
                                    <Popconfirm
                                        placement="topRight"
                                        title="This will delete the file from case level and you will not be able to map this file with new lenders going forward. This will NOT impact the mapping of this document with any lenders that you may have done before this?"
                                        onConfirm={() => onDelete(doc)}
                                        okText="Yes"
                                        cancelText="No"
                                        className='remove'
                                        overlayClassName='deletePopup'
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
                                    >
                                        <span className='remove'>
                                            <MdDelete />
                                        </span>
                                    </Popconfirm>
                                    <span onClick={() => downloadDocument(doc)} className='remove'>
                                        <DownloadBlack />
                                    </span>
                                </Space>
                            </div>
                        </li>)}
                    </ul>
                </div>
               {/* <Button type="text" className='upload-btn' onClick={() => setUploadDocument(true)} icon={<FaFileMedical />}>
                    Upload New document
                </Button>*/}
                <TextArea placeholder='Comment' showCount maxLength={500} className="custom-textarea" rows={3} onChange={(e) => setUserComment(e.target.value)} />
                <Button type="primary" className='next-btn dynamic-btn-primary' style={btnPrimaryStyles} onClick={() => { lenderDocumentHandler(userComment) }}>
                    Next Step
                </Button>
            </Row>
        </div >
    )
}

export default DocumentationList;