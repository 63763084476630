import React, { useState } from "react";
import "./details.css"
import CustomButton from "../buttons/CustomButton";
import phone from "../../assets/images/phone.svg";
import logo1 from "../../assets/images/IIF-logo1.png";
import logo2 from "../../assets/images/IIF-logo2.png";
import logo3 from "../../assets/images/OneInfinity.png";
import { Link } from 'react-router-dom';
import NumberInput from "../numberinput/NumberInput";
import parameters from "shared/parameters";
import API_SERVICE from "shared/services/api-service";

const VerifyDetails = () => {
  const mobileNumber = localStorage.getItem("user-mobile");
  const [error, setError] = useState();
  const [token, setToken] = useState();
  const [inputValue, setInputValue] = useState({
    firstDigit: "",
    secondDigit: "",
    thirdDigit: "",
    fourthDigit: "",
    fifthDigit: "",
    sixthDigit: ""
  });

  const { firstDigit, secondDigit, thirdDigit, fourthDigit,fifthDigit,sixthDigit } = inputValue;

  const handleChange = (e) => {
    const value = e.target.value;

    setInputValue({
      ...inputValue,
      [e.target.name]: value,
    });
  };

  const submitHandler = async () => {
    if (
      inputValue.firstDigit.trim().length === 0 ||
      inputValue.secondDigit.trim().length === 0 ||
      inputValue.thirdDigit.trim().length === 0 ||
      inputValue.fourthDigit.trim().length === 0
    ) {
      setError("Invalid Input");
    }else{

      const response = await fetch(
        `${parameters.ApiUrlOne}free/login/${API_SERVICE.get_BUSINESS_ID}`,
        {
          method: "POST",
          body: JSON.stringify(inputValue),
          headers: {
            "Content-type": "application/Json",
          },
        }
      );
      setToken(inputValue);
    }


  };

  return (
    <div>
      <div className="outer-form" style={{ marginBottom: "2rem" }}>
        <div style={{ height: 10 }}></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img style={{}} src={logo1} alt="" />
          <img style={{}} src={logo2} alt="" />
          <img style={{}} src={logo3} alt="" />
        </div>
        <div className="inner-form" style={{ height: "480px", width: "500px"}}>
          <h3
            className="title"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(39, 56, 150, 0.12)",
                borderRadius: "50%",
                height: "112px",
                width: "112px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "45%" }} src={phone} alt="" />
            </div>
          </h3>
          <span style={{ fontSize: "1.5rem" }} className="title1">Verify Details</span>
          <div  className="title11">
            <p>Enter the OTP sent to {mobileNumber}</p>
          </div>
          <Link to="/confirm-mobile" className="title111"
            style={{
              textDecoration: "none",
              marginTop: "-0.1rem",
              marginBottom: "5px",
              color: "#C71C8E",
              fontWeight: "700",
              fontSize: "1rem",
            }}
          >
            Change Mobile Number
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0 1rem",
              marginTop: "1rem"
            }}
          >
            <NumberInput
              type="Number"
              value={firstDigit}
              label="firstDigit"
              name="firstDigit"
              onChange={handleChange}
            />
            <NumberInput
              type="Number"
              value={secondDigit}
              label="secondDigit"
              name="secondDigit"
              onChange={handleChange}
            />
            <NumberInput
              type="Number"
              value={thirdDigit}
              label="thirdDigit"
              name="thirdDigit"
              onChange={handleChange}
            />
            <NumberInput
              type="Number"
              value={fourthDigit}
              label="fourthDigit"
              name="fourthDigit"
              onChange={handleChange}
            />
            <NumberInput
              type="Number"
              value={fifthDigit}
              label="fifthDigit"
              name="fifthDigit"
              onChange={handleChange}
            />
            <NumberInput
              type="Number"
              value={sixthDigit}
              label="sixthDigit"
              name="sixthDigit"
              onChange={handleChange}
            />
          </div>
          {error && <p className="error-text">{error}</p>}
          <div className="title11">
            <div>
              <p >
                Didn’t receive the OTP?{" "}
                <span style={{ color: "#C71C8E" }}>RESEND OTP</span>
              </p>
            </div>
            <CustomButton name="Continue"  onSubmit={submitHandler} />
            {token && <link to="/password"></link>}
          </div>
        </div>
      </div>
      <link to="/password"></link>
      <span className="back"  >Go Back To Login</span>
    </div>
  );
};

export default VerifyDetails;
