import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  InputNumber,
  Space,
} from "antd";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
const { Panel } = Collapse;

interface IiciciOtpScreen {
  data: any;
  selectedLander: any;
  leadData: any;
  handleFormSubmit: any;
}
const ICICIOtpFlowableScreen: React.FunctionComponent<IiciciOtpScreen> = (
  props: IiciciOtpScreen
) => {
  const [applicantData, setApplicantData] = useState([]);
  const [resendLinkTriggerd, setResendLinkTriggerd] = useState(false);
  const [resendLinkTriggerdRefID, setResendLinkTriggerdRefID] = useState();

  const [isOtpTrigger, setIsOtpTrigger] = useState(false);
  const [isOtpTriggerRefID, setIsOtpTriggerRefID] = useState();
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "", paddingInline: "30px"};

  const getDataForOtpVerification = () => {
    //otp verification API
    API_SERVICE.getOtpVerificationDetails(
      props.selectedLander?.lenderInfoId,
      props.leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          setApplicantData(data?.payload?.applicants);
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };
  useEffect(() => {
    getDataForOtpVerification();
  }, [props]);
  const verifyOtp = (value) => {
    setIsOtpTrigger(true);
    setIsOtpTriggerRefID(value.refNo);
    const payload = { ...value };
    API_SERVICE.updateVerifyOtpICICI(
      props.selectedLander?.lenderInfoId,
      props.leadData?.caseDetails?.caseId,
      payload
    )
      .then(({ data }) => {
        if (data) {
          if (data?.payload) {
            setIsOtpTrigger(false);
            if (data?.payload.verified) {
              const updatedData: any = applicantData.map((items: any) => {
                if (items?.refNo === data?.payload?.refNo) {
                  items.verified = data?.payload?.verified;
                }
                return items;
              });
              setApplicantData(updatedData);
            } else {
              notification.error({ message: "Please enter valid OTP." });
            }
          }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const reTriggerOtp = (values) => {
    setResendLinkTriggerd(true);
    setResendLinkTriggerdRefID(values.refNo);
    const payload = { ...values, otp: "", resend: true };
    API_SERVICE.updateRetrigerVerifyOtpICICI(
      props.selectedLander?.lenderInfoId,
      props.leadData?.caseDetails?.caseId,
      payload
    )
      .then(({ data }) => {
        if (data) {
          if (data?.payload) {
            setResendLinkTriggerd(false);
            const updatedData: any = applicantData.map((items: any) => {
              if (items?.phone === data?.payload?.phone) {
                items.refNo = data?.payload?.refNo;
              }
              return items;
            });
            notification.success({ message: "Otp Retrigger successfully" });
            setApplicantData(updatedData);
          }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const otpDetails = (value: any, refNo: any) => {
    const data: any = applicantData.map((items: any) => {
      if (items?.refNo === refNo) {
        items.otp = value;
      }
      return items;
    });
    setApplicantData(data);
  };
  const verifyButton = (items) => {
    if (items?.verified) {
      return (
        <span style={{ color: "green" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M16.6252 5.54166L7.12516 15.0417L2.771 10.6875L3.88725 9.57125L7.12516 12.8012L15.5089 4.42542L16.6252 5.54166Z"
              fill="#20A74E"
            />
          </svg>
          Verified
        </span>
      );
    } else {
      return (
        <Button
          size="middle"
          className="dynamic-btn-primary"
          style={btnPrimaryStyles}
          disabled={!items?.otp}
          loading={isOtpTrigger && isOtpTriggerRefID === items.refNo}
          type="primary"
          htmlType="button"
          onClick={() => verifyOtp(items)}
        >
          Verify
        </Button>
      );
    }
  };
  const getTable = () => {
    return (
      <>
        <div>
          <Row key="key" className={""}>
            <Col span={2} className="doc-checkbox">
              das
            </Col>
            <Col span={2} className="doc-checkbox">
              das
            </Col>
          </Row>
        </div>
      </>
    );
  };
  const resentOtpLink = (items) => {
    if (!items.verified) {
      return (
        <span
          style={{
            color: resendLinkTriggerd && resendLinkTriggerdRefID === items.refNo ? "black" : "blue",
            cursor: "pointer",
          }}
          onClick={() => reTriggerOtp(items)}
        >
          Resend
        </span>
      );
    } else {
      return <span>Resend</span>;
    }
  };
  return (
    <div>
      <Row style={{ background: "rgba(235, 238, 243, 0.68)", padding: "10px" }}>
        {/* <Col span={2} style={{ paddingInlineStart: "41px" }}>
          <Checkbox
            checked={false}
            // onChange={false}
          />
        </Col> */}
        <Col span={6}>{"Applicant Name"}</Col>
        <Col span={3} style={{ textAlign: "center" }}>
          {"Mobile Number"}
        </Col>
        <Col span={5}>{"Enter OTP"}</Col>
        <Col span={3} style={{ textAlign: "center" }}>
          {"Status"}
        </Col>
        <Col span={7} style={{ textAlign: "center" }}>
          {"Actions"}
        </Col>
      </Row>
      {applicantData.map((items: any, index: any) => {
        return (
          <div className="mt-2" key={`key_${index}`}>
            <Row style={{ padding: "10px" }}>
              {/* <Col span={2} style={{ paddingInlineStart: "41px" }}>
          <Checkbox
            checked={false}
            // onChange={false}
          />
        </Col> */}
              <Col span={6} style={{ margin: "auto" }}>
                {items?.name}
              </Col>
              <Col span={3} style={{ textAlign: "center", margin: "auto" }}>
                {items?.phone}
              </Col>
              <Col span={5} style={{ margin: "auto" }}>
                <div>
                  <InputNumber
                    className="custom-input"
                    value={items.otp}
                    style={{ color: "black" }}
                    disabled={items.verified}
                    controls={false}
                    maxLength={10}
                    onChange={(value) => otpDetails(value, items?.refNo)}
                    placeholder="Enter OTP"
                  />
                </div>
              </Col>
              <Col span={3} style={{ margin: "auto", textAlign: "center" }}>
                <div>
                  <span>{verifyButton(items)}</span>
                </div>
              </Col>
              <Col span={7} style={{ margin: "auto", textAlign: "center" }}>
                {resentOtpLink(items)}
              </Col>
            </Row>
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBlock: "10px",
        }}
      >
        <Space className="">
          <Button
            size="middle"
            className="dynamic-btn-primary"
            style={btnPrimaryStyles}
            disabled={
              applicantData.filter((item: any) => !item?.verified).length > 0
            }
            type="primary"
            htmlType="button"
            onClick={() =>
              props.handleFormSubmit({ data: { applicants: applicantData } })
            }
          >
            Next
          </Button>
        </Space>
        {/* <Button
          disabled={disableNextBtn}
          onClick={() => onClickNext(tmpdocumentList1)}
        >
          Next
        </Button> */}
      </div>
    </div>
  );
};

export default ICICIOtpFlowableScreen;
