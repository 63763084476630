import React from 'react'

import LeadPage from 'shared/components/Lead/Lead';

type Props = {}

const Lead: React.FunctionComponent<Props> = () => {
    return(
        <LeadPage/>
    )
}

export default Lead;