import { configureStore } from "@reduxjs/toolkit";
import connectorReducer from "./connector-reducer";
import bulkUpdateReducer from "./bulkUpdate-reducer";
import leadReducer from "./lead-reducer";
import employeesReducer from "./employees-reducer";
import lendersReducer from "./lenders-reducer";
import insurerReducer from "./Insurer-reducer";
import permissionsReducer from "./permissions-reducer";
import notificationReducer from "./notification-reducer";
import notificationCountReducer from "./notificationCount-reducer";
import payoutReducer from "./payout-reducer";
import commonReducer from "./common-reducer";
import tenantReducer from "./tenant-reducer";
import decisioningAuditReducer from "./decisioning-audit-reducer";
import bulkUploadReducer from "./bulkUpload-reducer";

const store = configureStore({
  reducer: {
    common: commonReducer,
    tenant: tenantReducer,
    connector: connectorReducer,
    bulkUpdate: bulkUpdateReducer,
    lead: leadReducer,
    bulkUpload: bulkUploadReducer,
    employees: employeesReducer,
    lenders: lendersReducer,
    insurer: insurerReducer,
    permission: permissionsReducer,
    payoutReq: payoutReducer,
    notification: notificationReducer,
    notificationCount: notificationCountReducer,
    decisioningAudit: decisioningAuditReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
