import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Space, Table, Tabs, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import info from "../../../../assets/images/info.svg";
import {ReactComponent as IconInfo} from "../../../../assets/images/info.svg";
import API_SERVICE from "shared/services/api-service";
import passIcon from "../../../../assets/images/pass.svg";
import failIcon from "../../../../assets/images/fail.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IDecisioning {
  setIsLoading: any;
  leadData: any;
  getTaskData: any;
  setIsListDecisioning: any;
  isListDecisioning: any;
  submitTask: any;
}

const Decisioning: React.FC<IDecisioning> = (props: IDecisioning) => {
  const {
    setIsLoading,
    leadData,
    getTaskData,
    setIsListDecisioning,
    isListDecisioning,
    submitTask,
  } = props;
  const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
  const [selectedLenderExecutionDataList, setSelectedLenderExecutionDataList] =
    useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const [decisioningnTabkey, setDecisioningTabkey] =
    useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);

  useEffect(() => {
    if (isListDecisioning) {
      listDecisioning();
      setIsListDecisioning(false);
    }
  }, [isListDecisioning]);

  const listDecisioning = () => {
    setIsLoading(true);
    //change true
    let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
      return item?.journeyId
    })
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, true, '', tmpId)
      .then(({ data }) => {
        setDecisioningTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId)
        setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const getNextTask = (journeyId: string) => {
    setIsLoading(true);
    const payload = {
      processInstanceId: leadData?.processInstanceId,
      gotoStep: "decisioningStage",
    };
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        if (
          data?.payload?.taskDefinitionKey == "decisioning_output_lender_list"
        ) {
          const tmp = selectedLenderExecutionDataList?.map((lender: any) => {
            return {
              decisioningPurpose: lender?.decisioningPurpose,
              lenderId: lender?.lenderInfoId,
            };
          });
          submitTask({
            taskId: data?.payload?.taskId,
            formData: JSON.stringify({
              caseId: leadData?.caseDetails?.caseId,
              selectedLenderExecutionDataList: tmp,
              journeyId: journeyId
            }),
          });
        } else {
          setIsLoading(false);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const actionContinue = (journeyId: any) => {
    setSelectedLenderExecutionDataList([]);
    if (getTaskData?.taskDefinitionKey == "decisioning_output_lender_list") {
      const tmp = selectedLenderExecutionDataList?.map((lender: any) => {
        return {
          decisioningPurpose: lender?.decisioningPurpose,
          lenderId: lender?.lenderInfoId,
        };
      });
      const payload = {
        taskId: getTaskData?.taskId,
        formData: JSON.stringify({
          caseId: leadData?.caseDetails?.caseId,
          selectedLenderExecutionDataList: tmp,
          journeyId: journeyId
        }),
      };
      submitTask(payload);
    } else {
      getNextTask(journeyId);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <Tooltip
          title="select lender to calculate offer"
          placement="topLeft"
          color={appColors?.appPrimaryColor ?? "#1A3175"} 
        >
          <p className="border-edit-icon"><IconInfo style={{'--borderColor': appColors?.appPrimaryColor ?? ''}as any}/></p>

        </Tooltip>
      ),
      dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderAvailableInOfferDecision &&
              record?.lenderOfferExcelPublished &&
              record?.gatingPassed && (
                <Form.Item name="lenderOfferExcelPublished">
                  <Checkbox
                    checked={selectedLenderExecutionDataList?.some(
                      (item) =>
                        `${item?.lenderInfoId}-${item?.decisioningPurpose}` ==
                        `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                    )}
                    onChange={(e: any) => {
                      let tmpArr: any = [...selectedLenderExecutionDataList];
                      if (
                        selectedLenderExecutionDataList?.some(
                          (item) =>
                            `${item?.lenderInfoId}-${item?.decisioningPurpose}` ==
                            `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                        )
                      ) {
                        tmpArr = selectedLenderExecutionDataList?.filter(
                          (item: any) =>
                            `${item?.lenderInfoId}-${item?.decisioningPurpose}` !=
                            `${record?.lenderInfoId}-${record?.decisioningPurpose}`
                        );
                      } else {
                        tmpArr.push({
                          lenderInfoId: record?.lenderInfoId,
                          decisioningPurpose: record?.decisioningPurpose,
                        });
                      }
                      setSelectedLenderExecutionDataList(tmpArr);
                    }}
                  />
                </Form.Item>
              )}
          </>
        );
      },
    },
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
    },
    {
      title: "Gating Eligibility Passed",
      dataIndex: "gatingPassed",
      key: "gatingPassed",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.gatingPassed ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: "Scorecard Available",
      dataIndex: "lenderScorecardAvailable",
      key: "lenderScorecardAvailable",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderScorecardAvailable ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: "Offer Decision Configuration",
      dataIndex: "lenderAvailableInOfferDecision",
      key: "lenderAvailableInOfferDecision",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderAvailableInOfferDecision ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
    {
      title: "Offer Calculation Configuration",
      dataIndex: "lenderOfferExcelPublished",
      key: "lenderOfferExcelPublished",
      align: "center",
      render: (_: any, record: any) => {
        return (
          <>
            {record?.lenderOfferExcelPublished ? (
              <img src={passIcon} alt="" />
            ) : (
              <img src={failIcon} alt="" />
            )}
          </>
        );
      },
    },
  ];

  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  return (
    <>
      <Tabs
        className="lender-sub-tabs"
        style={{'--borderColor': appColors?.appPrimaryColor ?? ""}as any}
        size="small"
        activeKey={decisioningnTabkey}
        onChange={(key) => { setDecisioningTabkey(key); setSelectedLenderExecutionDataList([]) }}
      >
        {leadData?.journeyConfigResponseList?.map((data: any) => {
          let tmpFindData = descisioningDetail?.find((item: any) =>
            item?.journeyId == data?.journeyId
          );
          return (
            <Tabs.TabPane
              key={data?.journeyId}
              tab={data?.journeyName}
            >
              <div className="tab-Userdetails">
                <Space className="userdetails">
                  <p style={{ color: `${tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning ? "red" : ""}` }}>Date/Time: {tmpFindData?.executionTime}</p>
                  {tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning && (
                    <Tooltip
                      title="Something has been changed please edit the case"
                      placement="topLeft"
                      color="#1A3175"
                    >
                      <img src={info} style={{ marginBottom: "8px" }} />
                    </Tooltip>
                  )}
                  <p>Executed By: {tmpFindData?.executedBy}</p>
                </Space>
              </div>
              <Table
                columns={columns}
                className="dynamic-pagination-checkbox"
                style={customStyles}
                dataSource={tmpFindData?.decisioningDataListResponseList ?? []}
                size="small"
                pagination={{ showSizeChanger: true }}
                scroll={{ x: "max-content" }}
              />
              <Button
                type="primary"
                className="dynamic-btn-primary"
                style={{'--btnColor' :appColors?.appPrimaryColor ?? "", marginBottom: "15px", marginLeft: "15px"}as any}
                size="large"
                htmlType="submit"
                disabled={decisioningnTabkey !== tmpFindData?.journeyId}
                onClick={() => actionContinue(tmpFindData?.journeyId)}
              >
                Continue
              </Button>
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </>
  );
};

export default Decisioning;
