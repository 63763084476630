import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IScreenHeader {
  backScreenTitle?: string;
  backScreenPath?: string;
  screenTitle?: string;
}

const screenHeader: IScreenHeader = {
  backScreenTitle: "",
  backScreenPath: "",
  screenTitle: "",
};

export const commonSlice = createSlice({
  name: "CommonReducer",
  initialState: { screenHeader, lenderRequiredFields: [], isMobile: false, appColors:{} as any },
  reducers: {
    setScreenHeader: (state, action: PayloadAction<IScreenHeader>) => {
      state.screenHeader.screenTitle = action?.payload?.screenTitle;
      state.screenHeader.backScreenPath = action?.payload?.backScreenPath;
      state.screenHeader.backScreenTitle = action?.payload?.backScreenTitle;
    },
    setLenderRequiredField: (state, action: PayloadAction<any>) => {
      state.lenderRequiredFields = action?.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setAppColors : (state,action: PayloadAction<Object>) =>{
      state.appColors = action?.payload;
    }
  },
});

export const { setScreenHeader, setLenderRequiredField, setIsMobile, setAppColors } = commonSlice.actions;
export default commonSlice.reducer;
