import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row, notification } from "antd";
import { DownOutlined, UpOutlined, EditOutlined } from "@ant-design/icons";
import LeadDetailNewTabs from "./LeadDetailNewTabs";
import History from "./History/History";
import API_SERVICE from "shared/services/api-service";
import activity from "../../../../assets/images/activity.svg";
import { getUserDetails, numberWithCommas } from "shared/services/Utility";
import ListLeadDetails from "./LenderDisbursed/ListLeadDetails";
import CloseCase from "./CloseCase/CloseCase";
import "./LeadDetailNew.scss";
import AdditionalField from "shared/components/Lead/AdditionalField/AdditionalField";
import parameters from "shared/parameters";
import RequiredDocuments from "shared/components/Lead/RequiredDocuments/RequiredDocuments";
import ApplicantInformation from "shared/components/Lead/ApplicantInformation/ApplicantInformation";
import EditDetailsNew from "./EditDetailsNew";
import VASOutput from "./VAS/VASOutput";
import whatsappIcon from "../../../../assets/images/whatsappIcon.svg";
import {ReactComponent as IconEditBorder} from "../../../../assets/images/edit_border.svg";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

export type leadViewTypeScreen =
  | "mainLead"
  | "decisioningOutput"
  | "VasOutput"
  | "LeadDetailNewTabs"
  | "closeCase";

interface ILeadDetail {
  leadData: any;
  getLeadById: any;
  setLeadData: any;
  getRedirectURLLink?: any;
  getCashETriggerSMSLink?: any;
  getDownloadLetterForICICI?: any;
  lenderLoginHandler?: any;
  lenderDecisionHandler?: any;
  lenderNextHandler?: any;
  loading?: any;
}

const LeadDetailNew: React.FC<ILeadDetail> = (props: ILeadDetail) => {
  const {
    leadData,
    getLeadById,
    setLeadData,
    getRedirectURLLink,
    getCashETriggerSMSLink,
    getDownloadLetterForICICI,
    lenderLoginHandler,
    lenderDecisionHandler,
    lenderNextHandler,
    loading,
  } = props;
  const [leadViewType, setLeadViewType] =
    useState<leadViewTypeScreen>("mainLead");
  const [customUI, setCustomUI] = useState(false);
  const [currLenderId, setCurrLenderId] = useState("");
  const [currLoanProductId, setCurrLoanProductId] = useState("");
  const [currLeadId, setCurrLeadId] = useState("");
  const [selectedLander, setSelectedLander] = useState<any>({});
  const [additionalFields, setAdditionalFields] = useState([]);
  const [callbackAdditionalField, setCallbackAdditionalField] = useState(
    {} as any
  );
  const [faircentStatus, setFaircentStatus] = useState("");
  const [taskDetails, setTaskDetails] = useState({
    taskId: "",
    taskDefinitionKey: "",
    taskName: "",
  } as any);
  const [formData, setFormData] = useState({});
  const [isLastTask, setIsLastTask] = useState(false);
  const [selectedLenderList, setSelectedLenderList] = useState([] as any);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lenderStatus, setLenderStatus] = useState([] as any);
  const [caseStatus, setCaseStatus] = useState([] as any);
  const [isShowFullDetails, setIshowFullDetails] = useState(true);
  const [historyDetails, setHistoryDetails] = useState({} as any);
  const [isDecisioning, setIsDecisioning] = useState(false);
  const [isDecisioningTabAvailable, setIsDecisioningTabAvailable] = useState(false);
  const [isShowBorrowerFullDetails, setIshowBorrowerFullDetails] =
    useState(true);
  const [callbackReqDoc, setCallbackReqDoc] = useState({});
  const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showRequirementDocs, setShowRequirementDocs] = useState(false);
  const [isApplicantModal, setIsApplicantModal] = useState(false);
  const [showApplicantInfo, setShowApplicantInfo] = useState(false);
  const [isShowDocument, setIsShowDocument] = useState(false);
  const [isLoginSubmit, setIsLoginSubmit] = useState(false);
  const [isShowConnectorFullDetails, setIshowConnectorFullDetails] =
    useState(true);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);

  useEffect(() => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const lenderStatusList = enumValues.find(
        (item) => item.propertyName === "LENDER_STATUS"
      );
      const caseStatusList = enumValues.find(
        (item) => item.propertyName === "CASE_STATUS"
      );
      setLenderStatus(lenderStatusList);
      setCaseStatus(caseStatusList);
    }
    setSelectedLenderList(
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails
    );
    setHistoryDetails(leadData?.caseDetails?.caseHistoryHolder);
    setIsLoading(false);
  }, [leadData]);

  const genrateBureauScore = (pan?: any) => {
    setIsLoading(true);
    let payload = {
      caseId: leadData?.caseDetails?.caseId,
      panId: pan || leadData?.contactDetails?.pan,
      userId: getUserDetails()?.userId,
    };
    API_SERVICE.genrateBureauScore(payload)
      .then(() => { })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDecisioning) {
      listDecisioning();
    }
    setIsDecisioning(false);
  }, [isDecisioning]);

  const listDecisioning = () => {
    setIsLoading(true);
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, false)
      .then(({ data }) => {
        setListDescisioningDetail(data?.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const lenderDisbursedHandler = (values: any) => {
    let lStatusDisbursed = lenderStatus.keyAndDisplayValueList.find(
      (item) => item.key === "DISBURSED"
    );
    let cStatusDisbursed = caseStatus.keyAndDisplayValueList.find(
      (item) => item.key === "DISBURSED"
    );

    let lenderDetails = [] as any;
    values.lenders.map((item) => {
      lenderDetails.push({
        branchName: item.branchName,
        comments: values?.comment ?? "",
        disbursedAmount: item?.disbursedData?.disbursedAmount,
        lenderInfoId: item.lenderInfoId,
        insuranceAmount: item.insuranceAmount,
        hubName: item.hubName,
        lenderActivityId: item.lenderActivityId,
        interestRate: item.interestRate,
        lenderName: item.lenderName,
        rmName: item.rmName,
        sanctionedAmount: item.sanctionedAmount,
        status: lStatusDisbursed.key,
        disbursalLenderInfoEntity: item.disbursalLenderInfoEntity,
        payoutDetails: item.payoutDetails,
        payoutInfoDetails: item.payoutInfoDetails,
        payoutInfoEntities: item.payoutInfoEntities,
      });
    });
    const payload = {
      comments: values?.comment ?? "",
      lenderDetails,
    };
    updateCase(payload, cStatusDisbursed, false);
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    str = str?.replaceAll(
      /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
      parameters.ApiUrlOne
    );
    str = str.replaceAll(
      /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
      parameters.ApiUrlTwo
    );
    str = str?.replaceAll(
      /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
      parameters.ApiUrlThree
    );
    return JSON.parse(str);
  };

  const updateCase = (payload, status, isCallStartProcess: boolean) => {
    setIsLoading(true);
    setLoadingUpdate(false);
    API_SERVICE.updateCase(
      leadData?.caseDetails?.caseId,
      status.key,
      payload,
      leadData?.loanProductId
    )
      .then(({ data }) => {
        if (data) {
          getLeadById();
          notification.success({ message: data.message });
          const findCurrentLender =
            data?.payload?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
              (item: any) =>
                item?.lenderInfoId ==
                payload?.lenderDetails?.at(0)?.lenderInfoId
            );
          if (
            findCurrentLender?.submissionType == "API" &&
            isCallStartProcess
          ) {
            getLenderAdditonalField(findCurrentLender);
          } else if ((status?.key == "LENDER_SELECTION") || (status?.key == "INSURER_POLICY_SELECTION")) {
            const lenderDetails = payload?.lenderDetails?.map((item: any) => {
              return { ...item, status: "LOGGED" };
            });
            const payload1 = {
              comments: null,
              lenderDetails: lenderDetails,
            };
            setIsLoading(true);
            setLoadingUpdate(false);
            setTimeout(() => {
              updateCase(payload1, { key: tenantInfo?.businessProductType === "INSURANCE" ? "POLICY_LOGGED" : "LOGGED" }, false);
            }, 1000);
          } else {
            setIsLoading(false);
          }
          if (status?.key == "LOGGED" || status?.key == "POLICY_LOGGED") {
            setIsLoginSubmit(true);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  const getLenderAdditonalField = (currentLender: any) => {
    setIsLoading(true);
    setCurrLenderId(currentLender?.lenderInfoId);
    API_SERVICE.getLenderAdditonalField(
      currentLender?.lenderInfoId,
      leadData?.caseDetails?.caseId,
      leadData?.loanProductId
    )
      .then(({ data }) => {
        if (data?.payload?.workflowType === "FLOWABLE") {
          setAdditionalFields([]);
          setSelectedLander(currentLender);
          setShowAdditionalFields(true);
          // setIsLastTask();
          setCustomUI(data?.payload?.taskDetail?.customUI ?? false);
          console.log(data?.payload?.taskDetail?.customUI);
          setIsLastTask(data?.payload?.taskDetail?.lastStep);
          setFormData(
            getJSONSchema(
              data?.payload?.taskDetail?.jsonSchema
                .replaceAll("console.log(value);", "")
                .replaceAll(`"required": true`, `"required": false`)
            )
          );
          setTaskDetails({
            taskName: data?.payload?.taskDetail?.taskName,
            taskDefinitionKey: data?.payload?.taskDetail?.taskDefinitionKey,
            taskId: data?.payload?.taskDetail?.taskId,
            responseToRender: data?.payload?.taskDetail?.responseToRender,
          });
        } else {
          if ((data?.payload?.lenderFieldValues?.length ?? 0) > 0) {
            setAdditionalFields(data?.payload?.lenderFieldValues);
            // setAdditionalFields(dummyAdditionalFieldData);
            setSelectedLander(currentLender);
            setShowAdditionalFields(true);
            // setShowRequirementDocs(true);
          } else {
            // setCheckedLeader(true, currentLender.lenderInfoId);
          }
        }
      })
      .catch((e: any) => {
        if (currentLender?.lenderName?.toLowerCase()?.includes("kreditbee")) {
          // setCheckedLeader(true, currentLender.lenderInfoId);
          notification.success({ message: "Lead updated successfully" });
          // skippedLoginStatus();
        } else {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          // setCheckedLeader(true, currentLender.lenderInfoId);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDocumentScreen = () => {
    setLeadViewType("LeadDetailNewTabs");
    setIsShowDocument(true);
  };

  const handleDecisionOutPutScreen = () => {
    setLeadViewType("decisioningOutput");
    setIsShowDocument(false);
  };

  const handleVasOutPutScreen = () => {
    setLeadViewType("VasOutput");
    setIsShowDocument(false);
  };

  const handleCloseCaseScreen = () => {
    setLeadViewType("closeCase");
    setIsShowDocument(false);
  };

  const renderLeadViewTypeScreen = () => {
    if (leadViewType == "decisioningOutput") {
      return (
        <ListLeadDetails
          leadData={leadData}
          setLeadViewType={setLeadViewType}
        />
      );
    } else if (leadViewType == "closeCase") {
      return (
        <CloseCase leadData={leadData} setLeadViewType={setLeadViewType} />
      );
    } else if (leadViewType == "VasOutput") {
      return (
        <VASOutput setLeadViewType={setLeadViewType} leadData={leadData} />
      );
    } else {
      return (
        <LeadDetailNewTabs
          leadData={leadData}
          selectedLenderList={selectedLenderList}
          lenderDisbursedHandler={lenderDisbursedHandler}
          getRedirectURLLink={getRedirectURLLink}
          getCashETriggerSMSLink={getCashETriggerSMSLink}
          getDownloadLetterForICICI={getDownloadLetterForICICI}
          loadingUpdate={loadingUpdate}
          updateCase={updateCase}
          getJSONSchema={getJSONSchema}
          callbackReqDoc={callbackReqDoc}
          setCallbackReqDoc={setCallbackReqDoc}
          callbackAdditionalField={callbackAdditionalField}
          setShowRequirementDocs={setShowRequirementDocs}
          setSelectedLander={setSelectedLander}
          setAdditionalFields={setAdditionalFields}
          setCurrLenderId={setCurrLenderId}
          setCurrLeadId={setCurrLeadId}
          setShowAdditionalFields={setShowAdditionalFields}
          genrateBureauScore={genrateBureauScore}
          setShowApplicantInfo={setShowApplicantInfo}
          setIsApplicantModal={setIsApplicantModal}
          isApplicantModal={isApplicantModal}
          lenderDecisionHandler={lenderDecisionHandler}
          isShowDocument={isShowDocument}
          setIsShowDocument={setIsShowDocument}
          getLeadById={getLeadById}
          setIsLoginSubmit={setIsLoginSubmit}
          isLoginSubmit={isLoginSubmit}
          setLeadViewType={setLeadViewType}
          getLenderAdditonalField={getLenderAdditonalField}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          lenderNextHandler={lenderNextHandler}
          loading={loading}
          setIsDecisioningTabAvailable={setIsDecisioningTabAvailable}
          isDecisioningTabAvailable={isDecisioningTabAvailable}
        />
      );
    }
  };

  const renderAdditionalField = () => (
    <AdditionalField
      setCustomUI={setCustomUI}
      setLeadData={setLeadData}
      currLenderId={currLenderId}
      currLoanProductId={currLoanProductId}
      currLeadId={leadData?.caseDetails?.caseId}
      customUI={customUI}
      selectedLander={selectedLander}
      leadData={leadData}
      additionalFields={additionalFields ? additionalFields : []}
      setShowAdditionalFields={setShowAdditionalFields}
      callbackAdditionalField={setCallbackAdditionalField}
      setFaircentStatus={setFaircentStatus}
      taskDetails={taskDetails}
      formData={formData ?? {}}
      setIsLastTask={setIsLastTask}
      isLastTask={isLastTask}
      setAdditionalFields={setAdditionalFields}
      setTaskDetails={setTaskDetails}
      getLeadById={getLeadById}
      setCallbackReqDoc={setCallbackReqDoc}
      callBackRejected={(callBackData) => {
        getLeadById();
        const status = callBackData?.status ?? "";
        if (status.length > 0 && ["rejected"].includes(status.toLowerCase())) {
          setTimeout(() => {
            setShowRequirementDocs(false);
            // setLanderDecisionActive(true);
            // setLoading(false);
            notification.error({ message: callBackData?.Message });
          });
        }
      }}
      callBack={() => {
        getLeadById();
      }}
      skippedLoginStatus={() => { }}
    />
  );

  const renderRequireDocuments = () => (
    <div className="content-box">
      <RequiredDocuments
        selectedLander={selectedLander}
        leadData={leadData}
        setShowRequirementDocs={setShowRequirementDocs}
        callBackDoc={(data) => {
          console.log("callBackDoc ---- ", data);
          setCallbackReqDoc(data);
          if (selectedLander?.lenderName?.toLowerCase()?.includes("fibe")) {
            getLeadById();
            // const status =
            //   (callbackAdditionalField as any)?.earlySalaryResponse?.status ?? "";
            // if (status.length > 0 && !["Suspended", "Success"].includes(status)) {
            //   setTimeout(() => {
            //     // setLoading(false);
            //   }, 1000);
            // }
          } else {
            const lender =
              leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
                (lItem) => lItem.lenderInfoId === data.lenderInfoId
              );
            if (lender && lender?.status === "LOGGED") {
              // skippedLoginStatus();
            } else {
              lenderLoginHandler({
                comment: null,
                assignedLenderFiltered: [selectedLander],
              });
            }
          }
        }}
      />
    </div>
  );

  const renderCustomerDetials = () => { 
    const customStyles: any = {'--bgColor': appColors?.appSecondaryColor ?? ""}; 
    return(
    <Col xs={24} lg={6} span={6} className="col-border-right-3">
      <div className="col-border-bottom-3" style={{ padding: "8px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ marginTop: "5px" }}>Customer Details</p>
          <div style={{ float: "right" }}>
            <Button style={{ marginRight: "15px" }}
              size="small"
              hidden={!leadData?.isWhatsAppEnabled}
              className="whatsapp-icons"

              onClick={() => {
                handleClick();
              }}
              icon={
                <img
                  src={whatsappIcon}
                  alt=""
                  width={24}
                  height={24}
                  style={{ marginTop: "-4px" }}
                />
              }
            />
            <Button
              type="text"
              size="small"
              className="details-icons"
              onClick={() => setIshowFullDetails(!isShowFullDetails)}
            >
              {isShowFullDetails ? <DownOutlined /> : <UpOutlined />}
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <div className="name-logo" style={customStyles}>
              <span className="logo-header">
                {leadData?.contactDetails?.firstName
                  ?.split("")
                  ?.at(0)
                  ?.toUpperCase()}
              </span>
            </div>
          </div>
          <div className="customer-details">
            <p style={{ fontSize: "17px", marginBottom: "5px" }}>
              {leadData?.contactDetails?.firstName}
            </p>
            {isShowFullDetails && (
              <>
                <span className="item">
                  Mobile No : {leadData?.contactDetails?.mobileNumber}
                </span>
                <span className="item">
                  Applicant Type : {leadData?.applicationType}
                </span>
                {leadData?.applicationTypeKey === "BUSINESS" ? (
                  <span className="item">
                    Business Type : {leadData?.applicationSubType}
                  </span>
                ) : (
                  <span className="item">
                    Individual Type : {leadData?.applicationSubType}
                  </span>
                )}
                <span className="item">Loan Type: {leadData?.loanType}</span>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Connector Details Start */}
      <div className="col-border-bottom-3" style={{ padding: "8px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <span style={{ fontSize: "16px" }}>Connector Details</span>
          <div style={{ float: "right" }}>
            <Button
              type="text"
              size="small"
              className="borrower-icon"
              onClick={() =>
                setIshowConnectorFullDetails(!isShowConnectorFullDetails)
              }
            >
              {isShowConnectorFullDetails ? <DownOutlined /> : <UpOutlined />}
            </Button>
            <Button
              className="custome-btn-icon-secondary"
              style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
              type="text"
              size="small"
              // style={{ background: "none", marginTop: "-8px" }}
              onClick={() => setIsEditDetails(true)}
              icon={<IconEditBorder />}
            />
          </div>
        </div>
        <div className="customer-details">
          {isShowConnectorFullDetails && (
            <>
              <span className="item">
                Connector Name:{" "}
                {leadData?.sourceOfCase == "CONNECTOR" ? leadData?.caseDetails?.connectorDetails?.userDetails?.fullName : ""}
              </span>
              <span className="item">
                Tentative Payout %:{" "}
                {leadData?.caseDetails?.loanDetails?.expectedInterestRate ?? 0}
              </span>
              <span className="item">
                Assign To:{" "}
                {leadData?.caseDetails?.assignedToUserDetails?.fullName}
              </span>
              <span className="item">
                Assign To Back Office:{" "}
                {leadData?.caseDetails?.backOfficeUserDetails?.fullName}
              </span>
            </>
          )}
        </div>
      </div>
      {/* Connector Details End */}
      {/* <div className="col-border-bottom-3">
        {(leadData?.caseDetails?.loanDetails?.loanAmount ?? 0) > 0 && (
          <div style={{ padding: "8px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "16px" }}>Borrower Case Details</span>
              <Button
                type="text"
                size="small"
                className="borrower-icon"
                onClick={() =>
                  setIshowBorrowerFullDetails(!isShowBorrowerFullDetails)
                }
              >
                {isShowBorrowerFullDetails ? <DownOutlined /> : <UpOutlined />}
              </Button>
            </div>
            <div className="customer-details">
              {isShowBorrowerFullDetails && (
                <>
                  <span className="item">
                    Loan Amount : Rs.
                    {numberWithCommas(
                      leadData?.caseDetails?.loanDetails?.loanAmount
                    )}
                  </span>
                  <span className="item">
                    Loan Tenure :{" "}
                    {leadData?.caseDetails?.loanDetails?.loanTenureInYears} Years
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </div> */}
    </Col>
  )};

  const handleClick = () => {


    API_SERVICE.getWhatsAppDocumentValidate(leadData.caseDetails.caseId)
      .then(({ data }) => {

        if (data.payload.validToSend) {
          setIsModalWhatsappVisible(true);
        }
        else {
          // setIsModalWhatsappVisible(false)
          // notification.error({ message: data.payload.message })
          setIsModalWhatsappVisible(false);
          setSuccessMessage(data.payload.message);
          setIsSuccessModalVisible(true);

        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  }

  const handleModalOk = () => {
    setIsModalWhatsappVisible(false);
    API_SERVICE.sendWhatsAppMessage(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        setIsModalWhatsappVisible(false);
        if (data.payload) {
          notification.success({ message: data.payload })
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const handleModalCancel = () => {
    setIsModalWhatsappVisible(false);
  };
  const handleModalmessage = () => {
    setIsSuccessModalVisible(false);
  }
  const whatsapp = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isModalWhatsappVisible}
        footer={null}
        closable={false}
      >
        <div
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "35px",
              marginLeft: "5px",
              fontSize: "medium",
            }}
          >
            Is {leadData?.contactDetails?.mobileNumber} the valid contact number? <br />
            if not, please update lead details.
          </p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              className="dynamic-btn-primary"
              type="primary"
              style={{'--btnColor': appColors?.appPrimaryColor ?? "",width: "155px", height: "40px", borderRadius: "8px"}as any}
              onClick={handleModalOk}
            >
              Confirm
            </Button>
            <Button
              className="dynamic-btn-default"
              type="default"
              style={{'--borderColor': appColors?.appSecondaryColor ?? "",width: "155px", height: "40px", borderRadius: "8px"}as any}
              onClick={handleModalCancel}
            >
              Back
            </Button>
          </div>
        </div>
      </Modal>
    );
  };


  const message = () => {
    return (<Modal
      centered
      width={450}
      visible={isSuccessModalVisible}

      footer={null}
      closable={false}
    > <p
      style={{
        textAlign: "center",
        marginTop: "30px",
        marginBottom: "15px",
        marginLeft: "5px",
        fontSize: "medium",
      }}
    >{successMessage}
      </p>
      <Button
        type="default"
        className="dynamic-btn-default"
        style={{'--borderColor': appColors?.appSecondaryColor ?? "",width: "155px", height: "40px", borderRadius: "8px", margin: "20px",marginLeft: "110px"}as any}
        onClick={handleModalmessage}
      >
        OK
      </Button>

    </Modal>
    )
  }




  const renderTabsView = () => (
    <Col xs={24} lg={14} span={14} className="col-border-right-3">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // overflow: "auto",
        }}
      >
        <div style={{ width: "100%" }}>{renderLeadViewTypeScreen()}</div>
      </div>
      <History historyDetails={historyDetails} />
    </Col>
  );

  const renderActivity = () => (
    <Col xs={24} lg={4} span={4}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <h5 style={{ marginTop: "15px" }}>
            <span>
              <img src={activity} alt="" style={{ marginTop: "-5px" }} />
            </span>{" "}
            Activity
          </h5>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Button
          type="text"
          className="activity-btn"
          onClick={handleDocumentScreen}
          style={{ background: "none" }}
        >
          Documents
        </Button>
        <Divider style={{ marginTop: "6px", marginBottom: "8px" }} />
        <Button
          type="text"
          className="activity-btn"
          onClick={handleCloseCaseScreen}
          style={{ background: "none" }}
        >
          Close Case
        </Button>
        <Divider style={{ marginTop: "6px", marginBottom: "8px" }} />
        {isDecisioningTabAvailable &&
        <>
        <Button
          type="text"
          className="activity-btn"
          onClick={handleDecisionOutPutScreen}
          style={{ background: "none" }}
        >
          Decisioning Output
        </Button>
        <Divider style={{ marginTop: "6px", marginBottom: "8px" }} />
        </>
        }
        {tenantInfo?.businessProductType !== "INSURANCE" &&
        <>
        <Button
          type="text"
          className="activity-btn"
          onClick={handleVasOutPutScreen}
          style={{ background: "none" }}
        >
          VAS Output
        </Button>
        <Divider style={{ marginTop: "6px", marginBottom: "8px" }} />
        </>
        }
        <Button
          type="text"
          hidden={!leadData?.isWhatsAppEnabled}
          className="activity-btn"
          onClick={() => {
            handleClick();
          }}
          style={{ background: "none" }}
          icon={
            <img
              src={whatsappIcon}
              alt=""
              width={18}
              height={24}
              style={{ marginTop: "-2px", marginRight: "2px" }}
            />}
        > Share on Whatsapp
        </Button>
      </div>
    </Col>
  );

  const renderApplicantInfo = () => (
    <ApplicantInformation
      isGetBureauScore={false}
      setShowApplicantInfo={setShowApplicantInfo}
      setIsApplicantModal={setIsApplicantModal}
      leadData={leadData}
      genrateBureauScore={genrateBureauScore}
      getLeadById={getLeadById}
    />
  );

  const renderCaseDetials = () => (
    <div style={{ marginTop: "3px" }}>
      <div className="manage-form">
        {isEditDetails ? (
          <>
            <EditDetailsNew
              leadData={leadData}
              setIsEditDetails={setIsEditDetails}
              isEditDetails={isEditDetails}
              getLeadById={getLeadById}
              setLeadData={setLeadData}
            />
          </>
        ) : (
          <>
            <Row style={{ background: "#fff" }}>
              {renderCustomerDetials()}
              {renderTabsView()}
              {renderActivity()}
            </Row>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>{whatsapp()}
      {message()}
      {showAdditionalFields
        ? renderAdditionalField()
        : showRequirementDocs
          ? renderRequireDocuments()
          : showApplicantInfo
            ? renderApplicantInfo()
            : renderCaseDetials()}
      {isLoading && <AppLoading />}
    </>
  );
};
export default LeadDetailNew;
