import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const lendersSlice = createSlice({
  name: "LendersReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setLendersPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setLendersPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLendersSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLendersPageNo, setLendersPageSize, setLendersSearchParam } = lendersSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default lendersSlice.reducer;