import { CloseOutlined } from "@ant-design/icons";
import { Button, Input, message, notification, Progress, Space, Typography } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import greeting from "../../assets/images/payouts/addFile.svg";
import type { UploadProps } from "antd/es/upload/interface";
import Compressor from 'compressorjs';

const { Title, Text } = Typography;
type Props = {};

const AddGreetingCards: React.FunctionComponent<Props> = () => {

    const [selectedGreetingFileList, setSelectedGreetingFileList] = useState([] as any);
    const [selectMultipleFiles, setSelectMultipleFiles] = useState([] as any);
    const [title, setTitle] = useState(null as any);
    const [documentDetail, setDocumentDetail] = useState([] as any);
    const [fileList, setFileList] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
    }, [documentDetail]);

    const beforeUpload = (file) => {
        let uploadExt = ['image/jpeg', 'image/png', "image/jpg"]
        if (!uploadExt.includes(file.type)) {
            notification.error({ message: 'Please upload valid image' })
            return;
        }
    }

    const changeUploadFile = (fileList: any) => {

        const tmpList: any = [];
        let filterCounter = fileList?.fileList?.length ?? 0;
        let fileCounter = null as any;
        let files = [] as any;
        let tempFileArray = [] as any;

        

        if (filterCounter >= 0) {

            files = fileList?.fileList?.filter(
                (item: any) => {
                    let uploadExt = ['image/jpeg', 'image/png', "image/jpg"]
                    return item?.size < 9437184 && uploadExt.includes(item.type);
                }
            )
            tempFileArray = [...selectedGreetingFileList, ...files];
            setSelectedGreetingFileList(tempFileArray);
        }
        fileCounter = tempFileArray.length ?? 0;

        let counter = tempFileArray?.length ?? 0;

        tempFileArray?.map((file) => {

            let reader = new FileReader();
            reader.readAsDataURL(file?.originFileObj);

            reader.onload = () => {
                counter = counter - 1;
                tmpList.push(reader.result);
                if (counter <= 0) {
                    setSelectMultipleFiles(tmpList);
                }
                return true;
            }
        });
    }

    const createPayload = (temp) => {
        let userData = localStorage.getItem("user") as any;
        if (userData) {
            userData = JSON.parse(userData);
        }
        const data = {
            type: "GREETING",
            title: title,
            description: " ",
            documentDetail: temp,
        };
        return data;
    };

    const onFinish = () => {

        if (!selectedGreetingFileList || selectedGreetingFileList.length === 0) {
            notification.error({ message: "Please Select File." })
            return;
        }


        if (!title || title === null) {
            notification.error({ message: "please enter title" });
            return;
        }

        if (selectedGreetingFileList?.length ?? 0 > 0) {
            const fileArray = Object.entries(selectedGreetingFileList);
            var temporiginObject = [] as any;
            let tempArray = [] as any;

            fileArray.map((file) => {
                temporiginObject = [...tempArray, file[1]];
                tempArray = temporiginObject

            });

            let temp = [] as any;
            let counter = tempArray?.length ?? 0;

            tempArray.map((file) => {

                let name = file?.name;
                new Compressor(file?.originFileObj, {
                    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {

                        const formData = new FormData();
                        formData.append("file", compressedResult, name);
                        setIsLoading(true);
                        API_SERVICE.fileUpload(formData)
                            .then(({ data }) => {

                                counter = (counter - 1);
                                if (data) {
                                    temp.push(
                                        { documentName: name, documentURL: data }
                                    );

                                    if (counter <= 0) {
                                        setDocumentDetail(temp);
                                        submitGreetings(temp);
                                    }
                                }
                            })
                            .catch((e: any) => {
                                counter = (counter - 1);
                                notification.error({ message: API_SERVICE.handleErrors(e) });
                            });
                    },

                });


            });

        } else {
            let temp = [] as any;
            submitGreetings(temp);
        }
    }
    const submitGreetings = (temp) => {

        const payload = createPayload(temp);

        if (!documentDetail) return;
        setDocumentDetail(null);
        API_SERVICE.addOfferOrNotice(payload)
            .then(({ data }) => {
                if (data) {

                    notification.success({ message: data.message });
                    history.push("/greeting-cards");
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    }

    const onCancelClick = () => {
        history.push("/greeting-cards");
    }

    const uploadProps: UploadProps = {
        fileList,
        onDrop: (item) => {
            let uploadExt = ['image/jpeg', 'image/png', "image/jpg"];

            for (let i = 0; i < item.dataTransfer.files.length; i++) {

                if (!uploadExt.includes(item.dataTransfer.files[i].type)) {
                    notification.error({ message: 'Please droped valid image' })
                    return;
                }

                if (item.dataTransfer.files[i].size > 9437184) {
                    notification.error({ message: 'Please upload image size less than 10 MB' })
                    return;
                }
            }
        },
    };

    return (
        <>
            <Title level={3}>
                <Link className="link" to={"/greeting-cards"}>
                    Greeting Crad
                </Link>{" "}
                &nbsp; &gt; &nbsp; Add Greeting Card
            </Title>

            <div style={{ width: "100%" }}>
                <div style={{}} className="dragger-greeting">

                    <div style={{ display: "flex", justifyContent: "center", margin: "20px", height: "200px", }}>

                        <Dragger

                            style={{ width: "600px", height: "400px", borderRadius: "20px" }}
                            accept=".png, .jpg, .jpeg"
                            showUploadList={false}
                            multiple={true}
                            name="addGreetingUpload"
                            maxCount={5}
                            onChange={changeUploadFile}
                            {...uploadProps}
                            beforeUpload={beforeUpload}
                            // onDrop={onFileDrop}
                        >
                            <div style={{ marginTop: "-60px" }}>
                                {selectedGreetingFileList?.length ?? 0 > 0 ?
                                    (
                                        <>
                                            <Progress
                                                style={{ width: "70%" }}
                                                percent={100}
                                                strokeColor="purple"
                                            />
                                            <div className="imageClass" style={{ marginTop: "10px", marginLeft: "10px", height: "50px" }}>

                                                {selectMultipleFiles?.map((file, index) => {
                                                    return (<>

                                                        <img src={file} alt="Greeting Image" key={index}
                                                            style={{ height: "100px", width: "100px", marginRight: "10px" }}
                                                        />


                                                        {/* <Button
                                                            style={{}}
                                                            icon={<CloseOutlined />}
                                                        >

                                                        </Button> */}

                                                    </>
                                                    )
                                                })}

                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <div>
                                            <Space>
                                                <img src={greeting} alt="" width={40} />
                                            </Space>
                                            <div style={{ display: "flex", justifyContent: "center" }}><Text> Drag & Drop your file here </Text></div>
                                        </div>
                                    )
                                }
                            </div>

                        </Dragger>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", }}>
                        <Input
                            style={{ width: "600px", }}
                            name="greetingFileTitle"
                            className="field-bg"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => { setTitle(e.target.value) }}
                        />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
                        <Button
                            className="bulk-upload-btn"
                            style={{ marginRight: "10px" }}
                            onClick={onCancelClick}
                        >Cancel</Button>

                        <Button
                            // disabled={disbaleButton}
                            className="bulk-upload-btn"
                            style={{ marginLeft: "10px" }}
                            type="primary"
                            onClick={onFinish}
                            loading={isLoading}
                        >Submit</Button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddGreetingCards