import React, { useState, useEffect } from "react";
import { Button, Space } from "antd";
import ReactTimerLoader from "./ReactTimerLoader";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
interface ABCLBreResult {
  data: any;
  responseJSONSchema: any;
  selectedLander: any;
  leadData: any;
  handleFormSubmit: any;
  setCloseConfirmation: any;
  loading: any
}
const ABCLBreResults: React.FunctionComponent<ABCLBreResult> = ({
  data,
  responseJSONSchema,
  selectedLander,
  leadData,
  handleFormSubmit,
  setCloseConfirmation,
  loading
}) => {
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const handleSubmitReq = () => {
    handleFormSubmit({ data: {} })
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: "column",
        height: "200px",
      }}
    >
      {loading && <ReactTimerLoader />}
      
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "24px",
          fontWeight: '350',
          alignItems: "center",
        }}
      >
        BRE Result is in Process
      </div>
      {/* buttons */}
      {!loading && (
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBlock: "10px",
        }}
      >
        <Space className="">
          <Button
            size="middle"
            className="dynamic-btn-primary"
            style={{ '--btnColor' :appColors?.appPrimaryColor ?? "", paddingInline: "30px", width: '155px', height: '52px', borderRadius: '10px' } as any}
            type="primary"
            disabled={loading}
            htmlType="button"
            onClick={() => handleSubmitReq()}
          >
            Retry
          </Button>
          <Button
            htmlType="button"
            className="dynamic-btn-default"
            style={{ '--borderColor': appColors?.appSecondaryColor ?? "", paddingInline: "30px", width: '155px', height: '52px', borderRadius: '10px' } as any}
            onClick={() => {
              setCloseConfirmation(true);
            }}
          >
            Close
          </Button>
        </Space>
      </div>
      )}
    </div>
  );
};

export default ABCLBreResults;
