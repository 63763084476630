import React from "react";
import { Form, Radio, Space } from "antd";
import homeBlack from "../../assets/images/home-black.svg";
import "./FormRadioStart.scss";

interface IFormRadio {
  name?: string;
  isRequired?: boolean;
  value?: any;
  options: any;
  defaultValue?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined | any;
  // onChange?:
  // | ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void)
  // | undefined;
  classNameRadio?: string;
  label?: string;
  isIcon?: boolean;
  style?: any;
  disabled?: boolean;
}

const FormRadio = (props: IFormRadio) => {
  const {
    name,
    value,
    onChange,
    options,
    classNameRadio,
    label,
    defaultValue,
    isIcon,
    style,
    disabled
  } = props;
  return (
    <>
      <Form.Item label={label} name={name}>
        <Radio.Group
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          className={classNameRadio}
          style={style}
          disabled={disabled}
        >
          {isIcon ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              <Space direction="vertical">
                {options.map((option: any, i: number) => {
                  return (
                    <Radio key={i} value={option.key}>
                      <img
                        src={homeBlack}
                        alt=""
                        style={{
                          height: "17px",
                          width: "16px",
                          justifyContent: "center",
                        }}
                      />{" "}
                      {option.value}
                    </Radio>
                  );
                })}
              </Space>
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {options.map((option: any, i: number) => {
                return (
                  <Radio.Button key={i} value={option.key}>
                    {option.value}
                  </Radio.Button>
                );
              })}
            </div>
          )}
        </Radio.Group>
      </Form.Item>
    </>
  );
};
export default FormRadio;
