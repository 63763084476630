import React, { useState, useEffect } from 'react';
import './loader.scss';

const ReactTimerLoader = ({ removeTime = false }) => {
  const [progress, setProgress] = useState(60);
  const [minute, setMinute] = useState(60);
  const totalTime = 60000; // Total time in milliseconds (5 seconds in this example)
  const interval = 1000; // Update interval in milliseconds

  useEffect(() => {
    const steps = totalTime / interval;

    let currentStep = 1;
    const timer = setInterval(() => {
      const newProgress = (steps - currentStep);
      currentStep++;
      setProgress(newProgress);

      if (currentStep === steps) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setMinute((prevSecond) => prevSecond - 1);
  //   }, 1000);
  //   if (minute === 0) {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setMinute((prevMinute) => {
        if (prevMinute <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevMinute - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <div className="round-progress-bar-container">
      <svg className="round-progress-bar" viewBox="0 0 100 100">
        <circle
          className="progress-bar-background"
          cx="50"
          cy="50"
          r="45"
        />
        <circle
          className="progress-bar"
          cx="50"
          cy="50"
          r="45"
          style={{
            strokeDasharray: 283,
            strokeDashoffset: 283 - (283 * (minute)) / 60,
            // strokeDashoffset: 283,
          }}
        />
      {!removeTime && 
        <text x="50" y="50" className="progress-text" textAnchor="middle" dy=".3em">
          00 : {minute < 10 ? `0${minute}` : minute}
        </text>
        }
      </svg>
    </div>
  );
};

export default ReactTimerLoader;
