import { Button, Card, Col, Divider, Form, Input, Radio, Row, Select, Space, Switch, notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react"
import API_SERVICE from "shared/services/api-service";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "../index.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const ConnectorOtherDetails: React.FunctionComponent<{ connector: any, disableValue: any, setConnector: any }> = (props) => {
    let { id } = useParams() as any;
    const history = useHistory();
    const [otherDetailsForm] = Form.useForm() as any;
    const [onBoardingStatusList, setOnBoardingStatusList] = useState([]);
    const [payoutTypes, setPayoutTypes] = useState([]) as any;
    const [rejectionReasons, setRejectionReasons] = useState([]);
    const [isRejected, setRejected] = useState(false);
    const [isFixedPayout, setIsFixedPayout] = useState(false);
    const [disablefield, setDisableField] = useState(false);
    const [conectorLevels, setConectorLevels] = useState([]);

    const [selectedRejectionReason, setSelectedRejectionReason] = useState(null as any);
    const [onboardingStatus, setOnboardingStatus] = useState(null as any);
    const {appColors}: any = useSelector((state: RootState) => state.common);

    let userDetails = localStorage.getItem("user") as any;
    if (userDetails) {
        userDetails = JSON.parse(userDetails);
    }

    useEffect(() => {
        if (props?.disableValue === true) {
            setDisableField(true);
        }
    }, []);

    useEffect(() => {
        getAllTypes();
        if (props?.connector?.otherDetails) {
            if ("FIXED" === props?.connector?.otherDetails?.choosePayout) {
                setIsFixedPayout(true);
            }
            otherDetailsForm.setFieldsValue({
                bureauRequestAllowed: (props?.connector?.otherDetails?.bureauRequestAllowed ? props?.connector?.otherDetails?.bureauRequestAllowed : 0),
                choosePayout: (props?.connector?.otherDetails?.choosePayout ? props.connector.otherDetails.choosePayout : "VARIABLE"),
                createdBy: props?.connector?.otherDetails?.createdBy,
                createdTime: props?.connector?.otherDetails?.createdTime,
                currentStatus: JSON.parse(props.connector.currentStatus),
                lastUpdateBy: props?.connector?.otherDetails?.lastUpdateBy,
                lastUpdatedTime: props?.connector?.otherDetails?.lastUpdatedTime,
                payoutPercentage: props?.connector?.otherDetails?.payoutPercentage,
                rejectionReason: props?.connector?.otherDetails?.rejectionReason,
                selectConnectorLevel: (props?.connector?.otherDetails?.selectConnectorLevel ? props?.connector?.otherDetails?.selectConnectorLevel : "SILVER"),
                status: (props?.connector?.otherDetails?.status ? props?.connector?.otherDetails?.status : "APPROVED"),
                // showDecisioningOutput: JSON.parse(props?.connector?.basicDetails?.connectorAdditionalInfo?.showDecisioningOutput),
            });
            if (props?.connector?.otherDetails?.status === "APPROVED") {
                setOnboardingStatus("APPROVED");
                setSelectedRejectionReason(null);
                setRejected(false);
            } else if (props?.connector?.otherDetails?.status === "REJECTED") {
                setOnboardingStatus("REJECTED");
                setSelectedRejectionReason(props?.connector?.otherDetails?.rejectionReason);
                setRejected(true);
            }

            if ("VARIABLE" === (props?.connector?.otherDetails?.choosePayout)) {
                setIsFixedPayout(false);
            }
        } else {
            otherDetailsForm.setFieldsValue({
                bureauRequestAllowed: 0,
                choosePayout: "VARIABLE",
                currentStatus: true,
                selectConnectorLevel: "SILVER",
                status: "APPROVED"
            });
        }
    }, [props.connector]);

    const getAllTypes = () => {
        API_SERVICE.getTypes()
            .then(({ data }) => {
                let filteredRejectionReason = data.find(
                    (item: any) => item.propertyName == "REJECTION_REASON"
                );
                setRejectionReasons(filteredRejectionReason.keyAndDisplayValueList);

                let filteredPayoutTypes = data.find(
                    (item: any) => item.propertyName == "PAYOUT_TYPE"
                );
                setPayoutTypes(filteredPayoutTypes.keyAndDisplayValueList);

                let filteredConnectorLevel = data.find(
                    (item: any) => item.propertyName == "CONNECTOR_LEVEL"
                );
                setConectorLevels(filteredConnectorLevel.keyAndDisplayValueList);

                let filteredOnBoardingStatus = data.find(
                    (item: any) => item.propertyName == "ONBOARDING_STATUS"
                );
                setOnBoardingStatusList(filteredOnBoardingStatus.keyAndDisplayValueList);
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const onChangeChoosePayout = (e: any) => {
        if ("FIXED" === e.target.value) {
            setIsFixedPayout(true);
        } else {
            setIsFixedPayout(false);
        }
    }

    const onOnboardingStatusChange = (e) => {
        setOnboardingStatus(e.target.value);
        if (e.target.value === "APPROVED") {
            setSelectedRejectionReason(null);
            setRejected(false);
            updateConnectorStatus(e.target.value, null);
        } else if (e.target.value === "REJECTED") {
            setRejected(true);
        }
    }

    const onReasonChange = (values) => {
        setSelectedRejectionReason(values);
        updateConnectorStatus(onboardingStatus, values);
    }

    const buildPayload = (formData: any) => {
        const gender = props?.connector?.basicDetails?.gender;
        const payload = {
            accounts: null,
            basicDetails: {
                ...props?.connector?.basicDetails,
                gender: ("Male" === gender ? "M" : ("Female" === gender ? "F" : "O")),
                connectorAdditionalInfo: {
                    ...props?.connector?.basicDetails?.connectorAdditionalInfo,
                    showDecisioningOutput: formData?.showDecisioningOutput
                }},
            kycDetails: null,
            otherDetails: {
                bureauRequestAllowed: formData.bureauRequestAllowed,
                selectConnectorLevel: formData.selectConnectorLevel,
                choosePayout: formData.choosePayout,
                payoutPercentage: formData.payoutPercentage,
                currentStatus: formData.currentStatus,
                createdBy: userDetails?.userDetails?.userId,
                /*rejectionReasons:null,
                status:null*/
            }
        };
        return payload;
    };

    const updateConnectorStatus = (updatedStatus: string, updatedRejectionReason: any) => {
        const payload = {
            accounts: null,
            basicDetails: null,
            kycDetails: null,
            otherDetails: {
                rejectionReason: updatedStatus === "REJECTED" ? updatedRejectionReason : null,
                status: updatedStatus ? updatedStatus : updatedRejectionReason,
            },
        };
        API_SERVICE.updateConnectorStatus(id, payload)
            .then(({ data }) => {
                if (data) {
                    props.setConnector(data?.payload);
                    notification.success({ message: "Connector status updated Successfully" });
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const onFinish = (values: any) => {
        if (values?.choosePayout == "VARIABLE") {
            notification.warning({ message: "You will not be allowed to disburse cases without entering the variable payout %" })
        }
        if (
            props?.connector?.otherDetails?.bureauRequestAllowed != values?.bureauRequestAllowed ||
            props?.connector?.otherDetails?.choosePayout != values?.choosePayout ||
            props?.connector?.otherDetails?.selectConnectorLevel != values?.selectConnectorLevel ||
            props?.connector?.otherDetails.status != values?.status ||
            props?.connector?.basicDetails?.connectorAdditionalInfo?.showDecisioningOutput != values?.showDecisioningOutput
        ) {
            const payload = buildPayload(values);
            API_SERVICE.updateConnector(id, payload)
                .then(({ data }) => {
                    if (data) {
                        props.setConnector(data?.payload);
                        notification.success({ message: "Connector Updated Successfully" });
                        // history.push("/connectors");
                    }
                })
                .catch((e: any) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                });
        } else {
            notification.error({ message: "No Changes Found" });
        }
    };


  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const btnRadioStyles: any = { '--borderColor': appColors?.appPrimaryColor ?? "" };

    return (
        <Card className="custom-app-card">
            <Form
                layout="vertical"
                form={otherDetailsForm}
                /*initialValues={{
                    choosePayout: (payoutTypes[0].key)
                }}*/
                onFinish={onFinish}
                autoComplete="off"
                name="leadForm"
                disabled={disablefield}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Divider orientation="left" orientationMargin="0">
                            PAN Details
                        </Divider>
                        <Form.Item className="field-bg" label="Select Connector Level" name="selectConnectorLevel"
                            rules={[
                                { required: false, message: "Please select connector level" }
                            ]}
                        >
                            <Select placeholder="Select Connector Level">
                                {conectorLevels?.sort(function (a: any, b: any) {
                                    if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                                    if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                                    return 0;
                                })?.map((conectorLevel: any, i: number) => (
                                    <Select.Option value={conectorLevel.key} key={i}>{conectorLevel.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Divider orientation="left" orientationMargin="0">
                            Choose Payout
                        </Divider>
                        <Form.Item name="choosePayout">
                            <Radio.Group name="choosePayout" onChange={onChangeChoosePayout}  className="dynamic-radio" style={btnRadioStyles} >
                                <Space direction="vertical">
                                    {payoutTypes &&
                                        payoutTypes.map((payoutType: any, i: number) => (
                                            <>
                                                <Radio key={i} value={payoutType.key} checked={payoutTypes[0].key === payoutType.key}>
                                                    {payoutType.value}
                                                </Radio>
                                                {payoutType && isFixedPayout && (payoutTypes[0].key === payoutType.key) ?
                                                    <Form.Item name="payoutPercentage" style={{ margin: "5px 5px 5px 20px" }}
                                                        rules={[
                                                            { required: true, message: "Please enter fixed payout." },
                                                            {
                                                                pattern: /^([0-9]){0,2}([.]){0,1}([0-9]){0,2}?$/,
                                                                message: "Please enter valid fixed payout ",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{ width: "200px" }}
                                                            placeholder="Enter Fixed Payout %"
                                                            disabled={(payoutTypes[1].key === payoutType.key)}
                                                        />
                                                    </Form.Item>
                                                    :
                                                    null
                                                }
                                            </>
                                        ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Row style={{ marginTop: "20px" }}>
                            <Col span={20}>
                                Current Status
                            </Col>
                            <Col span={4}>
                                <Form.Item name="currentStatus" style={{ display: "flex", justifyContent: "end" }}>
                                    <Switch defaultChecked style={{'--bgColor': appColors?.appPrimaryColor ?? ""}as any}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}>
                                Show Decisioning Output
                            </Col>
                            <Col span={4}>
                                <Form.Item name="showDecisioningOutput" style={{ display: "flex", justifyContent: "end" }}>
                                    <Switch defaultChecked={props?.connector?.basicDetails?.connectorAdditionalInfo?.showDecisioningOutput} style={{'--bgColor': appColors?.appPrimaryColor ?? ""}as any}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider orientation="left" orientationMargin="0">
                            Onboarding
                        </Divider>
                        <Form.Item label="Onboarding Status">
                            {((undefined !== props?.connector?.otherDetails?.status && null !== props?.connector?.otherDetails?.status)) ?
                                ("APPROVED" === props?.connector?.otherDetails?.status ?
                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                    :
                                    ("REJECTED" === props?.connector?.otherDetails?.status) ?
                                        <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                        :
                                        <label className="label-verficiation-pending">Verification Pending</label>
                                )
                                :
                                null
                            }
                            <Form.Item name="status" shouldUpdate={true}
                                rules={[
                                    { required: false, message: "Please select onboarding status." },
                                ]}>
                                <Radio.Group onChange={onOnboardingStatusChange}  className="dynamic-radio" style={btnRadioStyles}>
                                    {/* <Radio.Group onChange={(e) => { "REJECTED" === e.target.value ? setRejected(true) : setRejected(false) }}> */}
                                    <Space direction="vertical">
                                        {onBoardingStatusList && onBoardingStatusList.map((onboard: any, i: number) => (
                                            <>
                                                <Radio key={i} value={onboard.key}>{onboard.value}</Radio>
                                                {("REJECTED" === onboard.key && rejectionReasons && isRejected) ?
                                                    <Space direction="vertical" style={{ width: "200px" }}>
                                                        <Form.Item name="rejectionReason" style={{ margin: "5px 5px 5px 20px", width: "100%" }}>
                                                            <Select placeholder="Select Rejection Reason" onChange={onReasonChange} defaultValue={selectedRejectionReason}>
                                                                {rejectionReasons.map((rejectionReason: any, i: number) => (
                                                                    <Select.Option value={rejectionReason.key} key={i}>{rejectionReason.value}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Space>
                                                    :
                                                    null
                                                }
                                            </>
                                        ))}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Divider className="custome-devider-hozntl" type="vertical" style={{ height: "auto" }} />
                    <Col span={10}></Col>
                </Row>
                <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                    <Divider />
                    <Col >
                        <Form.Item>
                            <Button size="large" htmlType="submit" onClick={() => { history.push("/connectors"); }}
                            // loading={loading}
                            className="dynamic-btn-default"
                            style={btnDefaultBorderStyle}
                            >Cancel</Button>
                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item>
                            <Button size="large" type="primary" htmlType="submit"
                            // loading={loading}
                            className="dynamic-btn-primary"
                            style={btnPrimaryStyles}
                            >Save</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default ConnectorOtherDetails;