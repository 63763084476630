import { Button, Divider, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";

interface IConsentDetails {
  show: boolean;
  setShow: any;
}

const ConsentDetails: React.FC<IConsentDetails> = (props: IConsentDetails) => {
  const { show, setShow } = props;

  const renderItem = (title: string, value: string) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>{title}</span>
        <span style={{ fontWeight: "bold" }}>{value}</span>
      </div>
    );
  };

  return (
    <>
      <Modal
        title="Consent Details"
        centered
        width={500}
        closable={false}
        footer={
          <>
            <Button
              style={{
                marginRight: "10px",
                marginBlock: "6px",
                paddingInline: "20px",
              }}
              onClick={() => setShow(false)}
            >
              Close
            </Button>
          </>
        }
        visible={show}
      >
        <div
          style={{
            border: "1px solid lightgray",
            borderRadius: "8px",
            marginBlock: "20px",
            padding: "20px",
          }}
        >
          {renderItem("Time Stamp", "Jan 05, 2024 5:00")}
          <Divider />
          {renderItem("Time Stamp", "Jan 05, 2024 5:00")}
          <Divider />
          {renderItem("Time Stamp", "Jan 05, 2024 5:00")}
        </div>
      </Modal>
    </>
  );
};

export default ConsentDetails;
