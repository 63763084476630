import React, { useState } from "react";
import Logo from "assets/images/logo.png";
import "antd/dist/antd.css";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import classes from "./SignUpFlow.module.css";
import { Button, Form, Input, Card, Image, Row, Col, Checkbox, Select, Progress } from "antd";
import vector from "../../assets/images/Vector.png";
import vector2 from "../../assets/images/Vector2.png";
import eclips4 from "../../assets/images/Ellipse 4.png";
import OtpInput from "react-otp-input";
import { notification } from "antd";
import Parameters from "../../shared/parameters";
import API_SERVICE from "shared/services/api-service";
import ReCAPTCHA from 'react-google-recaptcha';
import checkedGreenCircle from "../../assets/images/checkedGreenCircle.svg";
import checkedGreyCircle from "../../assets/images/checkedGreyCircle.svg";
import { PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";


const ForgotPassword = () => {
  const history = useHistory();

  const mystyle3 = {
    display: "flex",
    justifyContent: "space-around",
    fontSize: "1.5rem",
  };
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [otp, setOtp] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [verifyOtpData, setVerifyOtpData] = useState()
  const [verificationid, setVerificationid] = useState("");
  const [mobileVerifyData, setMobileVerifyData] = useState();
  const [loadingMobile, setLoadingMobile] = useState(false);
  const [mobilescreen, setMobilescreen] = useState(true);
  const [otpscreen, setOtpscreen] = useState(false);
  const [passwordscreen, setPasswordscreen] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState("");
  const [strength, setStrength] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [showCaptchaForConfirmMobile, setShowCaptchaForConfirmMobile] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const [reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile] = useState("");

  const [passwordLength, setPasswordLength] = useState(false)
  const [passwordDigit, setPasswordDigit] = useState(false)
  const [passwordUppercase, setPasswordUppercase] = useState(false)
  const [passwordLowercase, setPasswordLowercase] = useState(false)
  const [passwordSpecialChar, setPasswordSpecialChar] = useState(false)

  const [inputValue, setInputValue] = useState({
    mobileNumber: `+91` + " ",
  });
  const { mobileNumber } = inputValue;
  const [errors, setErrors] = useState({
    length: false,
    digit: false,
    uppercase: false,
    lowercase: false,
    specialChar: false
});

  const handleChange = (e) => {
    const value = e.target.value;

    setInputValue({
      ...inputValue,
      [e.target.name]: value,
    });
    localStorage.setItem("user-mobile", inputValue.mobileNumber);
    setMobileNumberError("");
  };
  const [form] = Form.useForm();

  const submitHandler = async (values) => {
    
    if ((showCaptchaForConfirmMobile && reCaptchaTokenForConfirmMobile.length === 0)){
      notification.error({
        message: "Please check reCaptcha",
      });
      setLoadingMobile(false);
    }
    else{
      if(values.mobileNumber.length===10){
        setLoadingMobile(true)
        API_SERVICE.checkPhone(values.mobileNumber)
          .then(({ data }) => {
            if (data) {
              notification.success({ message: data.message });
              setMobileVerifyData(data.payload)
              setVerificationid(data.payload.id);
              setMobilescreen(false);
              setOtpscreen(true);
              checkCaptchaForResendOtp();
              setToken(data);
            }
          })

          .catch(function (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          })
          .finally(() => {
            setLoadingMobile(false);
          });
  }
  }
    
  };

  const checkCaptchaForConfirmMobile = (e) => {
    if(e.target.value.length===10){
      setLoadingMobile(true)
      setShowCaptchaForConfirmMobile(true);
      const payload = {
        mobileNumber: e.target.value,
        reCaptchaScreenName: "OTP"
      }
      API_SERVICE.checkRecaptchaResponse(payload)
      .then(({ data }) => {
        if (data.payload.captchaRequired===true) {
          setShowCaptchaForConfirmMobile(true);
          setLoadingMobile(false);
        }else{
          setLoadingMobile(false);
          setShowCaptchaForConfirmMobile(false);
        } 
      })
    }
  }

  const submitHandlerResendOtp = async () => {
  if ((showCaptcha && reCaptchaToken.length === 0)){
      notification.error({
        message: "Please check reCaptcha",
      });
    }
    else{
      setShowCaptcha(false);
      setReCaptchaToken("");
      setLoadingMobile(true);
      checkCaptchaForResendOtp();
        API_SERVICE.checkPhone(form.getFieldValue('mobileNumber'))
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setMobileVerifyData(data.payload)
          setVerificationid(data.payload.id);
          setMobilescreen(false);
          setOtpscreen(true);
          setToken(data);
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoadingMobile(false);
      });
    }  
  };

  const checkCaptchaForResendOtp = () => {
    const payload = {
      mobileNumber: form.getFieldValue('mobileNumber'),
      reCaptchaScreenName: "OTP"
    }
    API_SERVICE.checkRecaptchaResponse(payload)
    .then(({ data }) => {
      if (data) {
        setShowCaptcha(data.payload.captchaRequired);
      } 
    })
    .catch(function (e) {
      setShowCaptcha(false);
    })
  }

  const submitOtpHandler = async (values) => {
    if (values.otp == "") {
      notification.error({
        message: "Please Enter OTP",
      });
    }
      else {
      verifyOtp(values.otp);
      setShowCaptcha(true);
    }
  };

  const verifyOtp = (otp) => {
    const data = {
      mobileOtp: otp,
      mobileNumber: form.getFieldValue('mobileNumber'),
      verificationId: mobileVerifyData.id
    }

    if ((showCaptcha && reCaptchaToken.length === 0)) {
      notification.error({
        message: "Please check reCaptcha",
      });
    }
    else {
      API_SERVICE.verifyOtp(data)
        .then(({ data }) => {
          if (data) {
            setVerifyOtpData(data)
            setOtpscreen(false);
            setPasswordscreen(true);
          }
        })
        .catch(function (e) {
          setError(true);
          setShowCaptcha(false);
          setReCaptchaToken("");
          checkCaptchaForResendOtp();
          notification.error({ message: API_SERVICE.handleErrors(e) });
          
        })
        .finally(() => {
          setLoadingMobile(false);
        });
    }
  }

  const handlePassword = async (values) => {
    const data = {
      mobileNumber: form.getFieldValue('mobileNumber'),
      password: values.password
    }
    if(passwordLength && passwordDigit && passwordUppercase && passwordLowercase && passwordSpecialChar){

      API_SERVICE.updatePassword(data)
    .then(({ data }) => {
      if (data) {
        notification.success({ message: data.message });
        history.push('/login')
      }
    })
    .catch(function (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    })
    .finally(() => {
      setLoadingMobile(false);
    });
    }
    
  };

  const changeNumberHandler = () => {
    setMobilescreen(true);
      setOtpscreen(false);
  }

  const handlePasswordChange = (event) => {
    setPasswordLength(event.target.value.length > 7 ? true  : false)
    setPasswordDigit(/\d/.test(event.target.value))
    setPasswordUppercase(/(?=[A-Z])[A-Z]/.test(event.target.value))
    setPasswordLowercase(/(?=[a-z])[a-z]/.test(event.target.value))
    setPasswordSpecialChar(/[$@$!%*#?&]/.test(event.target.value))


    const newPassword = event.target.value;
    setPassword(newPassword);
    evaluateStrength(newPassword);

    setErrors({
        length: newPassword.length >= 8,
        digit: /\d/.test(newPassword),
        uppercase: /[A-Z]/.test(newPassword),
        lowercase: /[a-z]/.test(newPassword),
        specialChar: /[@$!%*?&]/.test(newPassword),
    });
};

const evaluateStrength = (password) => {
  let strengthValue = 0;
  if (password.match(/\d/)) strengthValue += 1;
  if (password.length >= 8) strengthValue += 1;
  if (password.match(/[A-Z]/)) strengthValue += 1;
  if (password.match(/[a-z]/)) strengthValue += 1;
  if (password.match(/[@$!%*?&]/)) strengthValue += 1;
  setStrength(strengthValue);
};

const getProgressBarStatus = () => {
  if (strength === 1) return 'exception';
  if (strength === 2) return 'exception';
  if (strength === 3 || strength === 4) return 'exception';
  if (strength === 5) return 'success';
};

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select>
        <Select.Option value="+91">+91</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className={classes.main}>
      <div className="login">
        <div className="login-wrapper">
          {/* <div className="logo">
            <img src={Logo} alt="Logo" />
          </div> */}
              <Card className={'login-form-wrapper'}>
                {mobilescreen && (
                  <>
                      <Image
                        width="20%"
                        src={eclips4}
                        className={classes.eclips4}
                        preview={false}
                      />
                      <Image
                        width="7%"
                        src={vector}
                        className={classes.vector}
                        preview={false}
                      />
                      <div className={classes.heading}>
                        Confirm Your Mobile Number
                      </div>
                      <div className={classes.heading2}>
                        Please ensure your mobile number matches the mobile
                        number in your profile.
                      </div>
                      <div className="form-wrapper">
                      <Form
                        size="large"
                        form={form}
                        layout="vertical"
                        onFinish={submitHandler}
                        requiredMark={false}
                        initialValues={{ phoneCode: '+91'}}
                      >
                        <Form.Item
                          label="Mobile No."
                          name="mobileNumber"
                          rules={[
                            {
                              required: true,
                              message: "Mobile no is required",
                            },
                            {
                              pattern: PHONE_NUMBER_REGEX,
                              message: "Please enter valid Mobile number",
                            }
                          ]}
                        >
                          <Input className='custom-input-phone' prefix="+91" onBlur={checkCaptchaForConfirmMobile} />
                        </Form.Item>
                        <br/>

                    {showCaptchaForConfirmMobile &&
                      <div className="g-recaptcha">
                        <ReCAPTCHA
                          sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY"
                          onChange={(token) => setReCaptchaTokenForConfirmMobile(token)}
                        />
                      </div>
                    }

                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingMobile}
                            size={"large"}
                            className="full-width"
                          >
                            {/* {t('login.login')} */}
                            Continue
                          </Button>
                        </Form.Item>
                      </Form>
                      </div>
                    <Row justify={"space-around"}>
                      <Col>
                        <Link to="/login">Go Back To Login</Link>
                      </Col>
                    </Row>
                  </>
                )}

            {otpscreen && (
              <Form
                name="normal_login"
                className="login-form"
                onFinish={submitOtpHandler}
                initialValues={{
                  remember: true,
                }}
              >
                <Image
                  width="20%"
                  src={eclips4}
                  className={classes.eclips4}
                  preview={false}
                />
                <Image
                  width="7%"
                  src={vector}
                  className={classes.vector}
                  preview={false}
                />
                <div className={classes.heading}>Verify Details</div>
                <div className={classes.heading2}>
                  Enter the OTP sent to +91 {form.getFieldValue('mobileNumber')}
                </div>
                <a onClick={changeNumberHandler} style={{ color: "#C71C8E" }}></a>
                <Form.Item
                  name="otp"
                  style={{ textAlign: "left" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your otp!",
                    },
                  ]}
                >

                  <Input.Password
                  type="password"
                  className='custom-input-phone'
                    value={otp}
                    onChange={setOtp}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    style={{ borderRadius: '6px' }}
                  />
                </Form.Item>

                {showCaptcha && 
                <div className="g-recaptcha">
                <ReCAPTCHA
                  sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
                  onChange={(token)=>setReCaptchaToken(token)}
                />
                </div>
              }
                <Form.Item>
                  Didn't receive the OTP? &nbsp;
                  <a onClick={submitHandlerResendOtp} style={{ color: "#C71C8E" }}>
                    RESEND OTP
                    <br />
                  </a>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size={"large"}
                    className="full-width"
                    loading={loadingMobile}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            )}

                {passwordscreen && (
                  <Form
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    onFinish={handlePassword}
                    initialValues={{
                      remember: true,
                    }}
                  >
                    
                    <Image
                      width="20%"
                      src={eclips4}
                      className={classes.eclips4}
                      preview={false}
                    />
                    <Image
                      width="7%"
                      src={vector2}
                      className={classes.vector}
                      preview={false}
                    />
                    <div className={classes.heading}>Set Your Password</div>
                <Form.Item
                  name="password"
                  label="Password"
                  style={{ textAlign: "left" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={handlePasswordChange}
                  />
                </Form.Item>

                <ul className={classes.showul}>
                  
                  <li>
                    <Image src={errors.length ? checkedGreenCircle : checkedGreyCircle}
                    preview={false} />
                    <span className={classes.spanmar} >Password length should be greater than 8.</span>
                  </li>

                  <li>
                  <Image src={errors.digit ? checkedGreenCircle : checkedGreyCircle}
                    preview={false} />
                    <span className={classes.spanmar}>Minimum 1 digit in password.</span>
                  </li>
                  <li>
                  <Image src={errors.uppercase ? checkedGreenCircle : checkedGreyCircle}
                    preview={false} />
                    <span className={classes.spanmar}>Minimum 1 uppercase letter in password.</span>
                  </li>
                  <li>
                  <Image src={errors.lowercase ? checkedGreenCircle : checkedGreyCircle}
                    preview={false} />
                    <span className={classes.spanmar}>Minimum 1 lowercase letter in password.</span>
                  </li>
                  <li>
                  <Image src={errors.specialChar ? checkedGreenCircle : checkedGreyCircle}
                    preview={false} />
                    <span className={classes.spanmar}>Minimum 1 special character in password like @,#,$.</span>
                  </li>
                </ul>
                
                {(strength === 1 || strength === 2 || strength === 3 || strength === 4 || strength === 5) && (
                  <>
                    <Form.Item
                      label="" > <span style={{ float: 'left' }}> {(strength === 1 || strength === 2 || strength === 3 || strength === 4 || strength === 5) && 'Password Strength'}</span>

                      <div style={{ float: 'right' }}>
                        <span style={{ color: '#BD0000' }}>{strength === 1 && 'Weak'}</span>
                        <span style={{ color: '#BD0000' }}>{strength === 2 && 'Weak'}</span>
                        <span style={{ color: '#BD0000' }}>{strength === 3 && 'Weak'}</span>
                        <span style={{ color: '#BD0000' }}>{strength === 4 && 'Weak'}</span>
                        <span style={{ color: '#34A853' }}>{strength === 5 && 'Strong'}</span>
                      </div>

                      <span>
                        {(strength === 1 || strength === 2 || strength === 3 || strength === 4 || strength === 5) && <Progress percent={strength * 20} status={getProgressBarStatus()}
                          strokeColor={getProgressBarStatus() === 'exception' ? '#BD0000' :
                            getProgressBarStatus() === 'active' ? '#34A853' :
                              '#34A853'} showInfo={false} />}
                      </span>
                    </Form.Item>
                  </>
                )}

                      <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={["password"]}
                        style={{ textAlign: "left" }}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          onChange={(e) => {
                            setPassword1(e.target.value);
                          }}
                        />
                      </Form.Item>
                    {/* <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Please Check!",
                        },
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Should accept agreement")
                                ),
                        },
                      ]}
                      {...tailFormItemLayout}
                    >
                      <Checkbox defaultChecked={false}>
                        I accept the &nbsp;
                        <font color="#C71C8E">
                          Terms Of Use, Privacy Policy <br />
                        </font>
                        and&nbsp;
                        <font color="#C71C8E">Marketing permissions.</font>
                      </Checkbox>
                    </Form.Item> */}

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        // loading={loading}
                        size={"large"}
                        className="full-width"
                        style={{marginTop:'10px'}}
                      >
                        Continue
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Card>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
