/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Row,
  Select,
  Typography,
  Input,
  Button,
  Space,
  Checkbox,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import iconSave from "../../../../assets/images/right-border.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const LenderSelection: React.FunctionComponent<any> = ({
  leadData,
  lenderNextHandler,
  loadingUpdate,
}) => {
  const [lenders, setLenders] = useState([]);
  const [selectedLenders, setSelectedLenders] = useState([] as any);
  const [checkedLenders, setCheckedLenders] = useState([] as any);
  const [comment, setComment] = useState(null as any);
  const [assignedLenders, setAssignedLenders] = useState(
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails
  );
  const [lenderChanged, setLenderChanged] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const lenderActivityDetails =
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails;
  const checkedLenderList =
    checkedLenders?.length === 0 ? lenderActivityDetails : checkedLenders;

  useEffect(() => {
    getLendersList();
  }, []);

  const getLendersList = () => {
    // "status":"Active", "loanType": leadData?.loanType,
    // "loanProductId": leadData?.loanProductId,
    API_SERVICE.getLenders({
      status: "Active",
      loanProductId: leadData?.loanProductId,
      pageNo: 1,
      pageSize: 200,
    }).then(({ data }) => {
      if (data?.payload?.content) {
        const datas = data?.payload?.content;
        const updateLender = datas.map((item: any) => {
          const fieldNotBorrowed1 = lenderActivityDetails?.filter(
            (field: any) => field?.lenderInfoId === item.lenderInfoId
          );
          // lenderActivityDetails.map((subItem: any) => {
          if (fieldNotBorrowed1) {
            return {
              ...item,
              lenderApplicationId: fieldNotBorrowed1[0]?.lenderApplicationId,
              status: fieldNotBorrowed1[0]?.status,
            };
          } else {
            return { ...item };
          }
          // })
        });
        setLenders(updateLender);
      }
    });
  };

  const onSelectLender = (value: any, option: any) => {
    setSelectedLenders(option);

    let tempSelected1 = option.map((item) => {
      const found = checkedLenders?.find((lender) => {
        return lender.lenderInfoId === item.lenderInfoId;
      });

      if (found) {
        return found;
      } else {
        let lender = item;
        lender.checked = true;
        return lender;
      }
    });
    setCheckedLenders(tempSelected1);

    let tempSelected = option.map((item) => item.branchName);
    const isSameValue = tempSelected.filter((arr1Item) =>
      defaultLender.includes(arr1Item)
    );
    setLenderChanged(isSameValue.length > 0 ? true : false);
  };

  const setupLender = () => {
    const laders = checkedLenders?.filter(
      (lader: any) => lader.checked && (lader?.status?.length ?? 0) == 0
    );
    lenderNextHandler({ comment, selectedLenders: [...laders], update: false });
    // lenderNextHandler({ comment, selectedLenders, update: false });
  };

  const updateLender = () => {
    const laders = checkedLenders?.filter(
      (lader: any) => lader.checked && (lader?.status?.length ?? 0) == 0
    );
    lenderNextHandler({ comment, selectedLenders: [...laders], update: false });
    // lenderNextHandler({ comment, selectedLenders, update: true });
  };

  const onChangeLender = (ev: any) => {
    const lenderActivityDetails1 =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails;
    const checkedLenderList1 =
      checkedLenders?.length === 0 ? lenderActivityDetails1 : checkedLenders;

    const tempLender = checkedLenderList1?.map((item) => {
      if (item.lenderInfoId === ev.target.value) {
        let lender = item;
        lender.checked = ev.target.checked;
        return lender;
      } else {
        return item;
      }
    });

    setCheckedLenders(tempLender);
    setSelectedLenders(tempLender);
  };

  const defaultLenders =
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.map(
      (item) => item.lenderInfoId
    );
  const defaultLender = Array.from(new Set(defaultLenders));

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "" ,'--color': '#fff'};
  const btnRadioStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };

  return (
    <>
      <Row>
        <Title level={5}>Select Lender</Title>

        <Select
          className="custom-multiple-select hide-selected-item"
          showSearch
          mode="multiple"
          tokenSeparators={[","]}
          maxTagCount="responsive"
          placeholder="Select lender"
          defaultValue={[...defaultLender]}
          optionFilterProp="children"
          onChange={(value, option) => onSelectLender(value, option)}
          // filterOption={(input, option) =>
          //   (option!.children as unknown as string)
          //     .toLowerCase()
          //     .includes(input.toLowerCase())
          // }
        >
          {lenders.map((lender: any, i: number) => (
            <Select.Option {...lender} key={i} value={lender.lenderInfoId}>
              {lender.branchName}
            </Select.Option>
          ))}
        </Select>

        <div className="assigned-lender-list" style={{ width: "100%" }}>
          <Space direction="vertical">
            {checkedLenderList?.map((item, i) => {
              const lenderName =
                (item?.lenderName?.length ?? 0) === 0
                  ? item.branchName
                  : item?.lenderName;
              const isDisabled = ["LOGGED", "SANCTIONED", "DISBURSED"].includes(
                item.status
              );
              return (
                <div className="assigned-list" key={i}>
                  <Checkbox
                    className="lender-checkbox dynamic-pagination-checkbox"
                    style={btnRadioStyles}
                    onChange={onChangeLender}
                    value={item?.lenderInfoId}
                    checked={isDisabled ? isDisabled : item?.checked}
                    disabled={isDisabled}
                  >
                    <div className="checkbox-text-div">
                      <span className="checkbox-text">{lenderName}</span>
                      {item?.submissionType?.includes("API") && (
                        <Space>
                          {/* <img src={iconSave} alt="" width={14} height={14} /> */}
                          <span className="api-intergrated-text" style={{"--appSecondary": appColors?.appSecondaryColor ?? ""} as any}>{"API"}</span>
                        </Space>
                      )}
                    </div>
                    <div className="checkbox-text-div">
                      <span className="checkbox-text small">
                        {/* {item.hubName} */}
                      </span>
                      {item?.submissionType?.includes("API") &&
                        (item?.lenderApplicationId?.length ?? 0) > 0 && (
                          <span className="checkbox-text small">{`Lender loan ID: ${item.lenderApplicationId}`}</span>
                        )}
                    </div>
                    {/* <span className="checkbox-text small">{item.hubName}</span> */}
                  </Checkbox>
                </div>
              );
            })}
          </Space>
        </div>

        <div className="comment-box">
          <TextArea
            placeholder="Comment"
            showCount
            maxLength={500}
            className="custom-textarea"
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        {assignedLenders?.length > 0 ? (
          <Button
            type="primary"
            disabled={
              (checkedLenders?.length ?? 0) == 0 ||
              !checkedLenders?.some((lender) => lender.checked)
            }
            className="next-btn dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={updateLender}
            loading={loadingUpdate}
          >
            Next Stage
          </Button>
        ) : (
          <Button
            type="primary"
            disabled={
              (checkedLenders?.length ?? 0) == 0 ||
              !checkedLenders?.some((lender) => lender.checked)
            }
            className="next-btn dynamic-btn-primary"
            onClick={setupLender}
            loading={loadingUpdate}
            style={btnPrimaryStyles}
          >
            Next Stage
          </Button>
        )}
      </Row>
    </>
  );
};

export default LenderSelection;
