import React from "react";

import "./custominput.css";

const CustomInput = ({ value, label, placeholder, type, onChange,name,onfocus }) => {
  const handleChange = (e) => {
    //const { value } = e.target;
    onChange(e);
  };
  const focusChange = (name) =>{
    onfocus(name);
  }

  return (
    <div className="form-div">
      <label className="signin-label" htmlFor={name}>{label}</label>
      <input
        type={type}
        value={value}
        name={name}
        className="customInp"
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={() => focusChange(name)}
      />
    </div>
  );
};

export default CustomInput;
