import { Button, Card, Divider, Form, Spin, notification, Layout, Typography, Row, Col, Collapse } from "antd";
import FormCheckbox from "components/FormCheckbox/FormCheckbox";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import scuess from "../../../assets/images/scuess.svg";
import Logo from "../../../assets/images/OneInfinityLogo.svg";
import PageNotFound from "../../../assets/images/pageNotFound.svg";
import moment from "moment";
import file from "./user_task_consent.json";
import "./Consent.scss";
import PrefrConsentScreen from "../Lead/AdditionalField/FlowableScreens/PrefrScreens/PrefrConsentScreen";
const { Content } = Layout;
const { Title } = Typography;
const { Panel } = Collapse;

const ConsentHDFC: React.FC = () => {
  const [isThankYou, setIsThankYou] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [content, setContent] = useState("");
  const [checkboxList, setCheckboxList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [ip, setIP] = useState("");
  const [taskDetails, setTaskDetails] = useState({} as any);
  const [requiredCount, setRequiredCount] = useState(0);
  const [globalRequiredCount, setGlobalRequiredCount] = useState(0);
  const [consentType, setConsentType] = useState('' as any)
  const [formConsent] = Form.useForm();
  const [formGlobalConsent] = Form.useForm();
  let { uniqueId } = useParams() as any;
  const tcMessage = "I agree to share my information with Oneinfinity";
  const deviceName = window.navigator.platform;
  const [isPrefrLender, setIsPrefrLender] = useState(false);
  const [isDisplayOffer, setIsDisplayOffer] = useState(false);
  const [isErrorScreen, setIsErrorScreen] = useState(false);
  const [extraData, setExtraData] = useState();
  
  const [consentId, setConsentId] = useState("");

  useEffect(() => {
    console.log("Lender: " );
    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
        urlParams[key] = value;
    });
    const cid = urlParams?.id || uniqueId;
    setConsentId(cid);

    handleGetNextTask(cid);
    getIPData();
  }, []);

  const getIPData = async () => {
    // const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    // setIP(res.data.ip);

    API_SERVICE.getIPData()
      .then(({ data }) => {
        console.log("ip: ", data?.ip);
        setIP(data.ip);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {});
  };

  const getTaskName = () => {
    const URL = window.location.pathname;
    if (URL.includes('consent-hdfc')) {
      return 'consent-hdfc';
    } else {
      return 'consent-abcl';
    }  
  }

  const handleGetNextTask = (cid) => {
    setLoading(true);
    const params = {
      taskName: getTaskName(),
      uniqueId: cid,
    };
    API_SERVICE.getNextTaskFree(params)
      .then(({ data }) => {
        if (data?.payload?.taskDefinitionKey === "user_task_take_consent") {
          setTaskDetails({
            taskName: data?.payload?.taskName,
            taskDefinitionKey: data?.payload?.taskDefinitionKey,
            taskId: data?.payload?.taskId         
          });
          setIsPrefrLender(data?.payload?.extraData?.lender === 'PREFR');
          setExtraData(data?.payload?.extraData);
          setContent(data?.payload?.extraData?.data);
          setConsentType(data?.payload?.consentType)
          const checkboxList = data?.payload?.extraData?.checkboxList;
          setCheckboxList(checkboxList);
          const fil = checkboxList?.filter((item: any) => item?.required);
          setRequiredCount(fil?.length);
        } else {
          if (data?.payload?.extraData?.lender !== 'PREFR') {
            setConsentType(data?.payload?.consentType)
            setTaskDetails({
              taskName: data?.payload?.taskName,
              taskDefinitionKey: data?.payload?.taskDefinitionKey,
              taskId: data?.payload?.taskId         
            });
            setIsThankYou(true);
          } else {
            setIsPrefrLender(data?.payload?.extraData?.lender === 'PREFR');
            setExtraData(data?.payload?.extraData);
            setIsDisplayOffer(data?.payload?.extraData?.stage?.toLowerCase() === 'offer');
            setIsErrorScreen(data?.payload?.extraData?.stage?.toLowerCase() === 'offer_rejected');
            setIsThankYou(true);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setPageNotFound(true);
        // setContent(file?.extraData?.data);
        // setCheckboxList(file?.extraData?.checkboxList);
        // const fil = file?.extraData?.checkboxList?.filter(
        //   (item: any) => item?.required
        // );
        // setRequiredCount(fil?.length);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitTask = (values: any) => {
     const formData = {
      ...values,
      ipAddress: ip,
      timeStamp: moment().locale("en").format("MMM DD, YYYY hh:mm"),
      device: deviceName,
      tcMessage:tcMessage,
    };
    let payload = {
      formData: JSON.stringify(formData),
      uniqueId: consentId,
      responseToRender: false,
      taskName: getTaskName(),
      subTaskName: "",
      taskDefinitionKey: taskDetails.taskDefinitionKey,
      taskId: taskDetails.taskId,
      
    };
    setLoading(true);
    API_SERVICE.submitTaskFree(payload)
      .then(({ data }) => {
        if (isPrefrLender) {
          setLoading(true);
          API_SERVICE.updateConsentForPrefrLender(consentId, {}).then((res) => {
            if (res?.data?.payload?.eligible) {
              window.open(res?.data?.payload?.reason, "_self");
            }
            if (!res?.data?.payload?.eligible) {
              setIsErrorScreen(true);
              setIsDisplayOffer(false);
            }
          }).catch((e) => {
            setLoading(false);
            setIsDisplayOffer(true);
          }).finally(() => {
            setTimeout(() => {
            setLoading(false);
            }, 500);
          })
        } else {
            setIsThankYou(true);
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({
          message: API_SERVICE.handleErrors(e),
          style: {
            width: 600,
            maxHeight: "100vh",
            overflow: "auto",
          },
        });
      })
      .finally(() => {
        if (!isPrefrLender) {
          setLoading(false);
        }
      });
  };

  const renderGlobalConsentForm = () =>{
    return (
      <Form form={formGlobalConsent} name="formGlobalConsent" onFinish={submitTask}>
        {/*} {checkboxList?.map((item: any, index: number) => {
          return (*/}
            <div style={{ display: "flex", gap: "10px" }}>
              <FormCheckbox
                name="globalConsentCheckbox"
                isRequired
                //msgRequired={item?.name}
                textElement={<span>{tcMessage}</span>}
                onChange={(value) => {
                    if (value?.target?.checked)
                    {
                      setGlobalRequiredCount(globalRequiredCount + 1);
                    } else {
                      setGlobalRequiredCount(globalRequiredCount - 1);
                    }
                  }
                }
              />
            </div>
         {/*} );
        })}*/}
        <Form.Item>
          <Button
            type="primary"
            className="btn-agree"
            htmlType="submit"
            disabled={(globalRequiredCount === 0) || loading}
          >
            I Agree
          </Button>
        </Form.Item>
      </Form>
    )
  }
  
  const renderConsentForm = () => {
    return (
      <Form form={formConsent} name="formConsent" onFinish={submitTask}>
        {checkboxList?.map((item: any, index: number) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <span style={{ color: "red", minWidth: "6px" }}>
                {item?.required ? "*" : ""}
              </span>
              <FormCheckbox
                name={item?.name}
                isRequired={item?.required}
                msgRequired={item?.name}
                textElement={<span>{item?.message}</span>}
                onChange={(value) => {
                  console.log("formConsent: ", value?.target?.checked);
                  if (item?.required) {
                    if (value?.target?.checked) {
                      setRequiredCount(requiredCount - 1);
                    } else {
                      setRequiredCount(requiredCount + 1);
                    }
                  }
                }}
              />
            </div>
          );
        })}

        <Form.Item>
          <Button
            type="primary"
            className="btn-agree"
            htmlType="submit"
            disabled={(requiredCount > 0) || loading}
          >
            I Agree
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const renderTermsConditions = () => {
    return (
      <>
        {/* <p style={{ fontWeight: "bold", marginTop: "5px" }}>
          Terms & Conditions
        </p> */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </>
    );
  };

  const renderGlobalTermsConditions = () =>{
    return(
      <>
      <Layout className='white'>
        <Content>
            <div className="site-layout-content">
              <Row justify="space-around" gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className="align-items-center">
                <Col xs={{span:24}} md={{span:24}} lg={{span:24}}>
                  <Title level={3} className="pageTitle">Terms & Condition</Title>
                   <Collapse accordion>
                    <Panel header="Definitions" key="1">
                    <div className="privacy-content">
                      <p>"Application or app." shall mean oneinfinity, the mobile application created, developed and designed by the company For providing the services.</p>
                      <p>"Lender" shall mean non-banking financial company or banks with whom the company has tied up for loan sanction, Which would sanction, process and grant the loan to the customer(s), through the platform.</p>
                      <p>"Company" or "We" shall mean INFINITYFLO PRIVATE LIMITED, a company incorporated under the companies act, 2013</p>
                      <p>"Customer(s)" or "You" or "End-users" shall mean any person who accesses, downloads, uses, views the platform and The services.</p>
                      <p>"Loan" shall mean the loan that you may apply for through the platform and which is sanctioned and granted by Lender, subject to the applicable terms and conditions of the loan agreement.</p>
                      <p>"Loan agreement" shall mean the loan agreement to be executed between lender and the customer(s) for granting the Loan whether in physical or electronic form as may be applicable from time-to-time.</p>
                      <p>"Online stores" shall mean windows store, android google play, ios app store or any other online store or portal Where the app will be made available by the company to the end-users, from time to time.</p>
                      <p>"Outstanding amount(s)" shall mean the loan, interests and charges due and payable by you to lender, on respective Due date(s).</p>
                      <p>"Platform" shall mean the app and the website collectively.</p>
                      <p>"Services" shall mean the services of granting, sanctioning, lending of short term loans, through the platform by Lender.</p>
                      <p>"Third party platforms" shall mean social networking platforms, such as facebook, linkedin and other similar Platforms.</p>
                      <p>"User data" shall mean any data, information, documents or materials submitted with the company prior to or during The use of the services.</p>
                      <p>"Website" shall mean www.Oneinfinity.In, managed and operated by the company for the provision of services.</p>
                    </div>
                    </Panel>
                    <Panel header="Services" key="2">  
                    <div className="privacy-content">      
                      <p>
                          Through the services, you may apply for the loan, subject to the fulfilment of the eligibility criteria laid down in
                          The mobile app. You understand that the company has been appointed by lender to collect, authenticate, track your
                          Location, verify and confirm the user data, documents and details as may be required by lender to sanction the loan.
                          Lender authorizes the company to collect and store the user data through the internet / website / mobile application
                          Form ("Mobile application form") available on the platform. In order to avail the services, you are required to
                          Register with the company by logging in through your third party platforms ("User account"). During the application
                          Process, you shall be required to share and upload the user data on the mobile application form. User data shall
                          Include personal information including but not limited to your name, e-mail address, gender, date of birth, mobile
                          Number, passwords, photograph, mobile phone information including contact numbers, sms and browsing history, data
                          And login-in credentials of third party platforms, financial information such as bank documents, salary slips, bank
                          Statements, pan card, bank account no., data from credit information companies, data required for know your customer
                          Compliances, requirement and other relevant details ("Personal information"). You agree that the personal
                          Information shall always be accurate, correct and complete. As part of the services, you authorize us to import your
                          Details and personal information dispersed over third party platforms. You understand and acknowledge that we may
                          Periodically request for updates on such personal information and we may receive such updated information from third
                          Party platforms.
                      </p>
                      
                      <p>
                          All transactions undertaken on your behalf by the company will be on the basis of your express instructions/consent
                          And will be strictly on a non-discretionary basis. You also authorise the company to get your credit information
                          Report from one or more credit information companies as decided by the company from time to time. Once you verify
                          And upload the user data and/or other documents and details in the mobile application form, the company shall
                          Process the same. Upon the completion of the document verification by the company, the loan may be sanctioned by
                          Lender to you, subject to fitting eligibility criteria and other conditions set forth by lender for sanctioning the
                          Loan. Thereafter, you are required to fill and upload the ecs/nach mandate form/cheque or any other document as may
                          Be required by lender . The company may collect the physical documents including signatures on those documents
                          Required for sanctioning and processing the loan. Upon the collection of documents by the company, lender shall
                          Disburse the loan subject to the terms and conditions of the loan agreement.
                      </p>
                      
                      <p>
                          The sanctioned loan shall be disbursed as per the mode provided in the mobile application form. You are required to
                          Repay the outstanding amount(s) to lender, on the respective due date(s) mentioned in the mobile application form.
                      </p>
                      
                      <p>
                          You understand and acknowledge that the company reserves the right to track your location ("Track") during the
                          Provision of services, and also in the event that you stop, cease, discontinue to use or avail the services, through
                          Deletion or uninstallation of mobile app or otherwise, till the event that your obligations to pay the outstanding
                          Amount(s) to lender exist. Deletion, uninstallation, discontinuation of our services, shall not release you from the
                          Responsibility, obligation and liability to repay the outstanding amount(s).
                      </p>
                      
                      <p>
                          You understand and acknowledge that you shall be solely responsible for all the activities that occur under your
                          User account while availing the services. You undertake that the company shall not be responsible and liable for any
                          Claims, damages, disputes arising out of use or misuse of the services. By usage of the services, you shall be
                          Solely responsible for maintaining the confidentiality of the user account and for all other related activities
                          Under your user account. The company reserves the right to accept or reject your registration for the services
                          Without obligation of explanation.
                      </p>
                      
                      <p>
                          You understand and acknowledge that, you are solely responsible for the capability of the electronic devices and the
                          Internet connection, you chose to run the platform. The platformâ€™s operation or the services on your electronic
                          Device is subject to availability of hardware, software specifications, internet connection and other features and
                          Specifications, required from time to time.
                      </p>
                      
                      <p>
                          The user data provided during the registration is stored by the company for your convenience. You are not required
                          To log-in to your user account, every time, to use or access the platform. You understand and acknowledge that by
                          Accepting these terms, you authorize us to track, fetch and use the user data, including but not limited to your
                          Personal information, for the purpose of authentication and any updates with regards to your credentials.
                      </p>
                    </div>
                    </Panel>
                    <Panel header="License" key="3">
                      <div className="privacy-content">
                        <p>
                          - License to use the app:in order to use the services, you are required to download and install the app. For
                          This purpose, you represent and warrant that you are of the age of majority as per the applicable laws to
                          Which you are subject to and are competent to understand, enter into, and comply with these terms. The
                          Company grants you a limited, non-exclusive, non-transferable, non-sub-licensable and revocable right to
                          Download, install and use the app. The app. Is licensed and not sold to you and shall only be used as per
                          These terms.
                        </p>
                        <p>
                          - Scope of license:you may install, download, access or use the app through the online stores on/from mobile
                          Phones, tablets or any other electronic devices.
                        </p>
                        <p>
                            - Maintenance &amp; support: you acknowledge that while the company may, at its sole discretion, provide
                            Maintenance and support for the app from time to time, the company shall have no specific obligation
                            Whatsoever to furnish such services to you.
                        </p>
                
                        <p>
                            - Updates/upgrades: we may launch new updates/upgrades for the app., you may subscribe to the same through the
                            Online stores. In the event, you choose not to update/upgrade the app, certain features or functionality
                            Shall not be accessible to you.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Restrictions" key="4">
                      <div className="privacy-content">
                        <p>You agree not to:</p>
                        <p>
                            - Use the platform or the services for committing fraud, embezzlement, money laundering, or for any unlawful
                            And/or illegal purposes;
                        </p>
                        <p>
                            - To reproduce, duplicate, copy, sell, resell or exploit any portion of the app;
                        </p>
                        <p>
                            - Upload, post, email, transmit or otherwise make available any content that is unlawful, harmful,
                            Threatening, abusive, harassing, torturous, defamatory, vulgar, obscene, libelous, invasive of anotherâ€™s
                            Privacy, hateful, or racially, ethnically or otherwise objectionable through the platform;
                        </p>
                
                        <p>- Use the platform to harm or injure any third party;</p>
                        <p>- Impersonate any person or entity, on the platform;</p>
                        <p>- Forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted
                            Through the app;</p>
                        <p>- Upload, post, email, transmit or otherwise make available any content that you do not have a right to make
                            Available under any law or under contractual or fiduciary relationships (such as inside information,
                            Proprietary and confidential information learned or disclosed as part of employment relationships or under
                            Nondisclosure agreements);</p>
                        <p>- Upload, post, email, transmit or otherwise make available on the platform, any content that infringes any
                            Patent, trademark, trade secret, copyright or other proprietary rights of any party;</p>
                        <p>- Upload, post, email, transmit or otherwise make available on the platform, any unsolicited or unauthorized
                            Advertising, promotional materials, "Junk mail," "Spam", "Chain letters," "Pyramid schemes," or any other
                            Form of solicitation;</p>
                        <p>- Upload, post, email, transmit or otherwise make available on the platform, any material that contains
                            Software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the
                            Functionality of any computer software or hardware or telecommunications equipment;</p>
                        <p>- Disrupt the normal flow of or otherwise act in a manner that negatively affects other userâ€™s ability to
                            Engage in real time exchanges;</p>
                        <p>- Interfere with or disrupt the platform or servers or networks connected to the platform, or disobey any
                            Requirements, procedures, policies or regulations of networks connected to the platform;</p>
                        <p>- Intentionally or unintentionally violate any applicable local, state, national or international laws and any
                            Regulations having the force of law.</p>
                      </div>
                    </Panel>
                    <Panel header="Content Available" key="5">
                      <div className="privacy-content">
                        <p>You acknowledge that the company makes no representations or warranties about the material, data, and information,
                      Such as data files, text, facts and figures, computer software, code, audio files or other sounds, photographs,
                      Videos, or other images (collectively, the "Content") which you may have access to as part of the services, or
                      Through your use of the platform. Under no circumstances, shall the company be liable in any way for any content,
                      Including, but not limited to any infringing content, any errors or omissions in content, or for any loss or damage
                      Of any kind incurred as a result of the use of any content posted, transmitted, linked from, or otherwise accessible
                      Through or made available via the platform. The content on the platform should not be regarded as an offer,
                      Solicitation, invitation, advice or recommendation to buy or sell investments, securities or any other instrument or
                      Financial products / schemes of the company (including its affiliates), unless expressly covered in these terms.</p>
                      </div>
                    </Panel>
                    <Panel header="Proprietary Rights of The Company" key="6">
                      <div className="privacy-content">
                        <p>You understand, acknowledge and agree that the company is the sole owner of all rights, title and interest,
                        Including any and all intellectual property rights in the content, platform, services, logos, trade names, brand
                        Names, designs and any necessary software used in connection with the platform.
                        </p>
                        <p>
                        There may be proprietary logos, service marks and trademarks found on the platform whether owned/used by the company
                        Or otherwise. By displaying them on the platform, the company is not granting you any license to utilize the
                        Proprietary logos, service marks, or trademarks. Any unauthorized use of the same may violate applicable
                        Intellectual property laws.
                        </p>
                        <p>
                        You understand and acknowledge that the platform is owned by the company. Nothing under these terms shall be deemed
                        To be a transfer in ownership, rights, title, from the company to you or any third party, in the platform. You are
                        Entitled to avail the services offered by the company during the validity of your registration with the company.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Our Partners" key="7">
                      <div className="privacy-content">
                        <p>
                        4.1 Display of various Financial Products and services offered by third parties on the 
                        OneInfinity Platform does not in any way imply, suggest, or constitute any 
                        sponsorship, endorsement, recommendation, opinion, advice or approval of 
                        OneInfinity in favour/against such third parties or their products/services. You agree 
                        that OneInfinity is in no way responsible for the accuracy, timeliness or 
                        completeness of information it may obtain from these third parties. OneInfinity is 
                        only performing the role of an intermediary/ marketplace/ facilitator to enable 
                        distribution of such products and services offered by OneInfinity’s partners (lenders, 
                        credit card companies, mutual fund companies) to You. Your interaction with any 
                        third party accessed through the OneInfinity Platform is at Your own risk, and 
                        OneInfinity will have no liability with respect to the acts, omissions, errors, 
                        representations, warranties, breaches or negligence of any such third parties or for 
                        any personal injuries, death, property damage, or other damages or expenses 
                        resulting from Your interactions with them. You should make all prior investigation 
                        You feel appropriate before proceeding with any transaction in connection with such 
                        third parties.
                        </p>
                        <p>
                          4.2 You agree and acknowledge that the conferment of any Financial Product to You 
                          shall be at the sole discretion of OneInfinity’s aforesaid partners while making any 
                          application through the OneInfinity Platform ; OneInfinity shall not be held liable for 
                          any delay, rejection or approval of any application made through the OneInfinity 
                          Platform.
                        </p>
                        <p>
                          4.3 You agree and acknowledge that for undertaking any financial transaction 
                          through the OneInfinity Platform, OneInfinity may undertake OneInfinity Platform’s 
                          client/customer/user’s due diligence measures and seek mandatory information 
                          required for Know-Your-Customer (“KYC”) purpose which, as a customer, You are 
                          obliged to give, while facilitating Your request for availing any of the Financial 
                          Products in accordance with applicable law and regulations. OneInfinity may obtain 
                          sufficient information to establish, to its satisfaction or its partner banks/financial 
                          institutions/NBFCs, the identity of each client/customer/user, and to ascertain the 
                          purpose of the intended nature of the relationship between You and them. You agree
                          and acknowledge that OneInfinity can undertake enhanced due diligence measures 
                          (including any documentation), to satisfy itself relating to such due diligence 
                          requirements in line with the requirements and obligations under applicable laws 
                          and regulations.
                        </p>
                        <p>
                          4.4 You agree and authorise OneInfinity to share Your personal information with the 
                          credit bureau or banks, financial institutions or NBFCs for the purpose of assessment 
                          of Your eligibility for the Financial Products and services offered, directly or 
                          indirectly, on the OneInfinity Platform.
                        </p>
                        <p>
                          4.5 You agree and authorise OneInfinity to share Your personal information with the credit bureau or banks, financial institutions or NBFCs, including Fibe (formerly Early Salary), Moneywide, Kreditbee, InCred, Aditya Birla Capital and Credit Saison India, for the purpose of assessment of Your eligibility for the Financial Products and services offered, directly or indirectly, on the OneInfinity Platform.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Links to Third Party Sites" key="8">
                      <div className="privacy-content">
                      <p>The platform may contain links to other websites owned and operated by third parties who are not related to the
                      Platform ("Linked websites"). The linked websites are not under the control of the company and the company shall not
                      Be responsible for the content of any linked websites or any hyperlink contained in a linked website and makes no
                      Representation or warranty with respect to the content of any such third party sites.
                      </p>
                      <p>
                      The platform provides these links to you as a convenience only and the inclusion of any link does not imply any
                      Endorsement of the linked website by the company. Your access or use of such linked website is entirely at your own
                      Risk. The company shall not be a party to any transaction between you and the linked website. Your use of a linked
                      Website is subject to these terms and conditions of that respective linked website.
                      </p>
                      <p>
                      The platform may also contain third party advertisements, if any. The display of such advertisements does not in any
                      Way imply an endorsement or recommendation by/of the relevant advertiser, its products or services. You shall
                      Independently refer to the relevant advertiser for all information regarding the advertisement and its products
                      And/or services. The company accepts no responsibility for any interaction between you and the relevant third party
                      And is released from any liability arising out of or in any way connected with such interaction.
                      </p>
                      </div>
                    </Panel>
                    <Panel header="Ancillary Services" key="9">
                      <div className="privacy-content">
                      <p>You may get access to chat rooms, blogs, feedbacks, reviews and other features ("Ancillary services") that are/may
                      Be offered from time to time on the platform and may be operated by us or by a third party on our behalf. You shall
                      Not (nor cause any third party to) use these ancillary services to perform any illegal activities (including without
                      Limitation defaming, abusing, harassing, stalking, threatening, promoting racism, or otherwise violating the legal
                      Rights, such as rights of privacy, of others) or immoral activities, falsely stating or otherwise misrepresenting
                      Your affiliation with a person or entity. Additionally, the platform may contain advice/opinions and statements of
                      Various professionals/ experts/ analysts, etc. The company does not endorse the accuracy, reliability of any such
                      Advices/opinions/ and statements. You may rely on these, at your sole risk and cost. You shall be responsible for
                      Independently verifying and evaluating the accuracy, completeness, reliability and usefulness of any opinions,
                      Services, statements or other information provided on the platform. All information or details provided on the
                      Platform shall not be interpreted or relied upon as legal, accounting, tax, financial, investment or other
                      Professional advice, or as advice on specific facts or matters. The company may, at its discretion, update, edit,
                      Alter and/or remove any information in whole or in part that may be available on the platform and shall not be
                      Responsible or liable for any subsequent action or claim, resulting in any loss, damage and or liability. Nothing
                      Contained herein is to be construed as a recommendation to use any product or process, and the company makes no
                      Representation or warranty, express or implied that, the use thereof will not infringe any patent, or otherwise.
                      </p>
                      </div>
                    </Panel>
                    <Panel header="Termination" key="10">
                      <div className="privacy-content">
                        <p>The company reserves its rights to terminate these terms in the event:</p>
                              <p>- You breach any provision of these terms;</p>
                              <p>- The company is required to do so under law; or</p>
                              <p>- The company chooses to discontinue the services being offered or discontinue to operate the platform;</p>
                              <p>- The license granted to use the app expires;</p>
                              <p>- Of non-payment of outstanding amount(s).</p>
                              <p>
                              Upon termination of these terms, the rights and licenses granted to you under these terms shall cease to exist, and
                              You must forthwith stop using the platform and the services and repay the outstanding amount(s). Notwithstanding
                              Anything contained in these terms or otherwise, the termination of these terms for any reason whatsoever, shall not
                              Affect your obligations, including but not limited to repayment of the outstanding amount(s).</p>
                      </div>
                    </Panel>
                    <Panel header="Disclaimer of Warranties" key="11">
                    <div className="privacy-content">
                      <p>You expressly understand and agree that</p>
                      <p>
                      Your use of the services and the platform is at your sole risk. The services and the platform are provided on an "As
                      Is" and "As available" basis. The company expressly disclaims all warranties of any kind, whether express or
                      Implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose
                      And non-infringement. Any material downloaded or otherwise obtained through the access or use of the platform, is at
                      Your own discretion and risk and that you will be solely responsible for any damage to your computer system,
                      Electronic data or loss of data that results from the download of any such material. No advice or information,
                      Whether verbal or written, obtained by you from the company, for the services or through the platform shall create
                      Any warranty not expressly stated in these terms. The services are intended for personal, non-commercial use. You
                      Shall be solely responsible for the use, misuse, improper usage of the services and the platform. The company shall
                      Not be liable for any damages accruing out of the use of the services which have not been expressly stipulated under
                      These terms. The company makes no warranty, including implied warranty, and expressly disclaims any obligation,
                      That: (a) the contents are and will be complete, exhaustive, accurate or suitable to your requirements; (b) the
                      Platform or the services will meet your requirements or will be available on an uninterrupted, timely, secure, or
                      Error-free basis; (c) the results that may be obtained from the use of the platform or services will be accurate or
                      Reliable.</p>
                    </div>
                    </Panel>
                    <Panel header="Indemnity" key="12">
                      <div className ="privacy-content">
                        <p>You agree to indemnify and hold the company, and its subsidiaries, affiliates, officers, agents, co-branders or
                            Other partners, and employees, harmless from any claim or demand, including attorneysâ€™ fees, made by any third party
                            Due to or arising out of (I) your violation of these terms; (ii) your violation of any rights of other users of the
                            Platform; (iii) your use or misuse of the platform or the services; (iv) your violation of applicable laws.</p>
                      </div>
                    </Panel>
                    <Panel header="Limitations of Liability" key="13">
                      <div className ="privacy-content">
                        <p>You expressly understand and agree that the company, including its directors, officers, employees, representatives
                        Or the service provider, shall not be liable for any direct, indirect, incidental, special, consequential or
                        Exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other
                        Intangible losses (even if the company has been advised of the possibility of such damages), resulting from; (a) use
                        Or the inability to avail the services (b) inability to use the platform (c) failure or delay in providing the
                        Services or access to the platform (d) any performance or non-performance by the company (e) any damages to or
                        Viruses that may infect your electronic devices or other property as the result of your access to the platform or
                        Your downloading of any content from the platform and (f) server failure or otherwise or in any way relating to the
                        Services.</p>
                      </div>
                    </Panel>
                    <Panel header="Force Majeure" key="14">
                      <div className ="privacy-content">
                      <p>Without limiting the foregoing, under no circumstances shall the company be held liable for any damage, loss, loss
                      Of services of platform, due to deficiency in provision of the services resulting directly or indirectly from acts
                      Of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures,
                      Computer equipment failures, telecommunication equipment failures, change in applicable regulations, including
                      Reserve bank of india regulations, or any other government regulations, floods, storms, electrical failure, civil
                      Disturbances, riots.</p>
                      </div>
                    </Panel>
                    <Panel header="Privacy Policy" key="15">
                      <div className ="privacy-content">
                        <p>The personal information collected/shared/uploaded for the provision of services has been exhaustively covered in Our privacy policy ("Privacy policy"). Our privacy policy is available at here.</p>
                      </div>
                    </Panel>
                    <Panel header="Change" key="16">
                      <div className ="privacy-content">
                        <p>The company reserves the right to modify, change, substitute, remove, suspend or update these terms or any
                          Information thereof at any time by posting the updated terms on the platform. Such changes shall be effective
                          Immediately upon such posting. Continued use of the services or the platform, subsequent to making the changes,
                          Shall be deemed to be your acceptance of the revised terms.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Choice of Law and Jurisdiction" key="17">
                      <div className ="privacy-content">
                        <p>This agreement shall be construed and governed by the laws of india without regard to principles of conflict of
                          Laws. Parties further agree that the courts in mumbai, maharashtra, india shall have an exclusive jurisdiction over
                          Such disputes.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Miscellaneous" key="18">
                      <div className ="privacy-content">
                        <p>Entire understanding: these terms, along with the loan agreement, mobile application form and privacy policy,
                            Constitute the entire understanding between you and the company with relation to the services. Waiver:the failure of
                        The company to exercise or enforce any right or provision of these terms shall not constitute a waiver of such right
                        Or provision. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising
                        Out of or related to availing of the services or these terms must be filed within one (1) year after such claim or
                        Cause of action arose or be forever barred. Severability: if any provision of these terms is found by a court of
                        Competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect
                        To the partiesâ€™ intentions as reflected in the provision, and the other provisions of these terms shall remain in
                        Full force and effect.
                        </p>
                      </div>
                    </Panel>
                    <Panel header="Violations" key="19">
                      <div className ="privacy-content">
                        <p>Please report any violations or grievances with relation to these terms to the company at info@oneinfinity.In</p>
                      </div>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </div>
        </Content>
      </Layout>
    </>
  ) 
 }

  const renderThankYou = () => {
    return (
      <div className="consent-card-thankyou">
        <Card bordered={false}>
          <div style={{ marginBottom: "15px" }}>
            <img src={scuess} alt="" />
          </div>
          <p className="confirm-text">Thank You!</p>
          <p>Verification done successfully.</p>
        </Card>
      </div>
    );
  };

  const renderView = () => {
    if (loading) {
      return (
        <div className="loader-view-flowable">
          <Spin
            tip="We are processing your request, please wait"
            size="large"
          ></Spin>
        </div>
      );
    } else if (pageNotFound) {
      return (
        <div className="img-page-not-div">
          <img src={PageNotFound} />
        </div>
      );
    } else if (isThankYou) {
      return renderThankYou();
    } else {
      return (
        <Card bordered={false} className="consent-card">
          <div className="logo-view">
            <img src={Logo} alt="Logo" width="100" />
          </div>
          {
            consentType === "GLOBAL"?renderGlobalTermsConditions():renderTermsConditions()
          }
          <Divider />
          {
          consentType === "GLOBAL"?renderGlobalConsentForm():renderConsentForm()}
        </Card>
      );
    }
  };

  return (
    <>
    {isPrefrLender ? (
      <PrefrConsentScreen isPrefrLender={isPrefrLender} extraData={extraData} submitTask={submitTask} isDisplayOffer={isDisplayOffer} loading={loading} setLoading={setLoading} setIsErrorScreen={setIsErrorScreen} isErrorScreen={isErrorScreen}  />
    ) : (
      <div className="consent-screen">{renderView()}</div>
    )}
    </>
  );
};

export default ConsentHDFC;
