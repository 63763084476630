import { Button, Card, Divider, List } from "antd";
import React, { useEffect, useRef } from "react";
import "./help.scss";
import { FaCopy, FaShare } from "react-icons/fa";
import moment from "moment";
import { toBlob } from "html-to-image";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
// import os from 'os';

type Props = {};

const About: React.FunctionComponent<Props> = () => {
  //   const osversion = window.navigator.appVersion;
  const osversion = "" + parseFloat(navigator.appVersion);
  const deviceName = window.navigator.platform;
  const [userData, setUserData] = React.useState(null as any);
  const exportRef = useRef<HTMLDivElement | null>(null);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUserData(JSON.parse(user));
    }
  }, []);

  const copyToClipboard = async (pngBlob) => {
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          ["image/png"]: pngBlob,
        }),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const copy = async (el) => {
    const newFile = await toBlob(el);
    copyToClipboard(newFile);
  };

  const urlToObject = async (el) => {
    const blob = await toBlob(el);
    const file = new File([blob!], "image.jpg", { type: "image/png" });
    return file;
  };

  const share = async (el) => {
    const newFile = await toBlob(el);
    console.log("image: ", newFile);

    const data = {
      files: [
        new File([newFile!], "image.png", {
          type: "image/png",
        }),
      ],
      title: "Image",
      text: "image",
    };

    try {
      if (!window.navigator.canShare(data)) {
        console.error("Can't share");
      }
      await window.navigator.share(data);
    } catch (err) {
      console.error(err);
    }

    // const shareOption = {
    //   title: "",
    //   url: newFile,
    //   message:
    //     'Download app now: ,AppLink:""',
    // };
    // try {
    //   const shareResponse = Share.open(shareOption);
    //   console.log('share response ,', shareResponse);
    // } catch (error) {
    //   //  alert(error.message);
    // }
  };

  console.log("userData: ", userData);

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  const aboutData = [
    <>
      <p className="about_name">App Version</p>
      <span className="text">7.0.0</span>
    </>,
    <Divider className="underline" />,
    <>
      <p className="about_name">Os Version</p>
      <span className="text">{osversion}</span>
    </>,
    <Divider className="underline" />,
    <>
      <p className="about_name">Last Update</p>
      <span className="text">{moment().format("DD-MM-YYYY")}</span>
    </>,
    <Divider className="underline" />,
    <>
      <p className="about_name">Device Name</p>
      <span className="text">{deviceName}</span>
    </>,
    <Divider className="underline" />,
    <>
      <p className="about_name">Mobile Number</p>
      <span className="text">{userData?.userDetails?.phoneNumber ?? ""}</span>
    </>,
    <Divider className="underline" />,
    <>
      <p className="about_name">User Id</p>
      <span className="text">{userData?.userDetails?.userId ?? ""}</span>
    </>,
  ];

  const aboutBtn = [
    <Button
      type="primary"
      size="middle"
      className="copy_btn dynamic-btn-primary"
      style={btnPrimaryStyles}
      icon={
        <>
          <FaCopy />
        </>
      }
      onClick={() => {
        copy(exportRef.current);
      }}
    >
      Copy
    </Button>,
    <Button
      type="primary"
      size="middle"
      className="share_btn dynamic-btn-primary"
      style={btnPrimaryStyles}
      icon={
        <>
          <FaShare />
        </>
      }
      onClick={() => {
        share(exportRef.current);
      }}
    >
      Share
    </Button>,
  ];

  return (
    <Card className="custom-app-card">
      <div ref={exportRef}>
        <Card className="content">
          <List
            grid={{ gutter: 5, column: 1 }}
            dataSource={aboutData}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Card>
      </div>
      <div className="about_btn">
        <List
          grid={{ gutter: 5, column: 2, xs: 1 }}
          dataSource={aboutBtn}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </div>
    </Card>
  );
};

export default About;
