import { Button, DatePicker, Form, Input, InputNumber, List, Modal, notification, Popconfirm, Select, Space, Upload, UploadProps } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DOUBLE_REGEX, NUMBER_REGEX, SPACE_REGEX } from "shared/constants/AppConstants";
import { RootState } from "shared/redux/store";
import API_SERVICE from "shared/services/api-service";
import { getEnumList, numberWithCommas } from "shared/services/Utility";
import { ReactComponent as IconAction } from "../../../../../assets/images/action.svg";
import { ReactComponent as IconDelete } from "../../../../../assets/images/delete.svg";
import AppUpload from "shared/components/Lead/AppUpload/app-upload";

interface IAddorEditDisbursed {
    finalLender: any;
    setFinalLander: any;
    setIsAddClick: (value: boolean) => void;
    setIsLoading: (value: boolean) => void;
    calcTotalDisbursedAmount: any;
    leadData: any;
    onActionSubmit: any,
    setIsAddCheck: (value: boolean) => void;
    setAddBtnErr: any;
}

const AddDisbursed: React.FC<IAddorEditDisbursed> = (props: IAddorEditDisbursed) => {

    const {
        finalLender,
        setFinalLander,
        setIsAddClick,
        setIsLoading,
        calcTotalDisbursedAmount,
        leadData,
        onActionSubmit,
        setIsAddCheck,
        setAddBtnErr
    } = props;
    const [fileList, setFileList] = useState([] as any);
    // const [imageUrl, setImageUrl] = useState(null as any);
    const [finalValue, setFinalValue] = useState(null as any);
    const [fileSize, setFileSize] = useState(null as any);
    const [showConfirmationPayout, setShowConfirmationPayout] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null as any);
    const [isUploaded, setIsUploaded] = useState(false)
    const [disbursedForm] = Form.useForm() as any;
    const { appColors, isMobile }: any = useSelector((state: RootState) => state.common);
    const isTenantWorkingCapitalEnabled = localStorage.getItem("tenantWorkingCapitalEnabled") == 'true' ? true : false;
    const payoutType = leadData?.caseDetails?.connectorDetails?.payoutType;
    const checkTenativePayout = leadData?.caseDetails?.connectorDetails?.payoutPercentage;
    const customStylesSubmitBtnPrimary: any = {
        '--btnColor': appColors?.appPrimaryColor ?? "",
        'width': "150px",
        'borderRadius': "9px",
        'marginBottom': "10px",
    };
    const disableDate = (current) => {
        let sanctionedDate = finalLender?.sanctionedDate;
        if ((sanctionedDate?.length ?? 0) > 0) {
            return current?.isAfter(moment()) || current?.isBefore(moment(sanctionedDate));
        }
        return current?.isAfter(moment()?.subtract(0, "day"))
    };

    useEffect(() => {
        setIsAddCheck(true);
        setAddBtnErr('Please fill current disbursement details before adding a disbursement');
        disbursedForm.setFieldsValue({
            disbursedBy: "LENDER",
          });
    }, [])

    const data = [
        <Form.Item
            name="insuranceAmount"
            label="Insurance Amount"
            className="decision-item"
            rules={[
                {
                    required: true,
                    message: "Please enter amount",
                },
                () => ({
                    validator(_, value) {
                        if (!value && value != 0) {
                            return Promise.reject();
                        }
                        if (isNaN(value)) {
                            return Promise.reject(
                                "Please enter valid amount"
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ]}
        >
            <InputNumber
                onChange={(ev) => {
                    finalLender.disbursalLenderInfoDetails[0].lenderAmount.insuranceAmount = ev;
                }}
                className="custom-input-number"
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                name="insuranceAmount"
                placeholder="Insurance Amount"
            />
        </Form.Item>,
        <Form.Item
            name="disbursedAmount"
            label="Disbursed Amount"
            className="decision-item"
            rules={[
                {
                    required: true,
                    pattern: NUMBER_REGEX,
                    message: "Please enter amount"
                },
                () => ({
                    validator(_, value) {
                        let amountDisbursed =
                            finalLender?.disbursalLenderInfoDetails.map((item) =>
                                Number(item.lenderAmount.disbursedAmount)
                            );
                        const totalDisbursed = amountDisbursed.reduce(
                            (a, b) => a + b,
                            0
                        );
                        const total = totalDisbursed;
                        if (!value && value != 0) {
                            return Promise.reject();
                        }
                        if (isNaN(value)) {
                            return Promise.reject(
                                "Please enter valid amount"
                            );
                        }
                        calcTotalDisbursedAmount(
                            finalLender?.disbursalLenderInfoDetails
                        );
                        const availbleAmount =
                            finalLender.sanctionedAmount - (total - value);
                        let to = total - value
                        if (value > availbleAmount) {
                            return Promise.reject(
                                `Please enter less amount than ${availbleAmount}`
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ]}
        >
            <InputNumber
                onChange={(ev) => {
                    finalLender.disbursalLenderInfoDetails[0].lenderAmount.disbursedAmount = ev;
                    calcTotalDisbursedAmount(finalLender?.disbursalLenderInfoDetails)
                }}
                className="custom-input-number"
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                name="disbursedAmount"
                placeholder="Disbursed Amount"
            />
        </Form.Item>,
        <Form.Item
            name="disbursalDate"
            label="Disbursed Date"
            className="decision-item"
            rules={[{ required: isTenantWorkingCapitalEnabled, message: "Please Select Disbursal Date" }]}
        >
            <DatePicker
                onChange={(ev) => {
                    finalLender.disbursalLenderInfoDetails[0].lenderAmount.disbursalDate = ev
                        ? moment(ev).format("YYYY-MM-DD").toString()
                        : "";
                }}
                disabledDate={disableDate}
                style={{
                    width: isMobile ? '170px' : '98%',
                    marginLeft: "3px",
                    marginBottom: "5px",
                }}
                format={"YYYY-MM-DD"}
                className="custom-input"
                placeholder="Disbursal Date"
            />
        </Form.Item>,
        <Form.Item
            className="decision-item"
            label="Disbursal By"
            name="disbursedBy"
            initialValue={"Lender"}
            rules={[
                {
                    required: isTenantWorkingCapitalEnabled,
                    message: "Please Select Disbursal By"
                }
            ]}
        >
            <Select
                className="custom-select"
                onSelect={(ev: any) => {
                    finalLender.disbursalLenderInfoDetails[0].lenderAmount.disbursedBy = ev;
                }}
                
            >
                {getEnumList("DISBURSED_BY")?.map((item: any) => (
                    <Select.Option value={item?.key}>
                        {item?.value}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>,
        <Form.Item
            name="lenderUniqueIdentifier"
            label="Lender Identifier"
            className="decision-item"
            rules={[
                {
                    required: isTenantWorkingCapitalEnabled,
                    pattern:SPACE_REGEX,
                    message: "Please enter Lender Identifier",
                },
            ]}
        >
            <Input
                size="large"
                className="custom-input"
                onChange={(ev) => {
                    finalLender.disbursalLenderInfoDetails[0].lenderAmount.lenderUniqueIdentifier = ev?.target?.value;
                }}
                placeholder="Lender Identifier"
            />
        </Form.Item>,
        <>
            <p style={{ fontSize: "12px", margin: "0", color: "#000000a6" }}>
                {isTenantWorkingCapitalEnabled && (<span style={{ color: "red" }}>*</span>)}
                &nbsp;
                Upload Disbursal Proof</p>
            {isUploaded ? (
                <Space>
                    <p style={{ fontSize: "12px", margin: "0", width: "100px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{uploadedFile?.fileName}</p>
                    <Popconfirm
                        placement="topRight"
                        okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border: "none" } }}
                        cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
                        title={"Sure to delete document?"}
                        onConfirm={(event) => {
                            setUploadedFile(null);
                            setIsUploaded(false);
                            finalLender.disbursalLenderInfoDetails[0].lenderAmount.proofOfDisbursalDto = null;
                        }}
                    >
                        <IconDelete style={{ cursor: "pointer" }} />
                    </Popconfirm>
                </Space>
            ) : (
                <AppUpload
                    acceptType={["pdf", "jpeg", "jpg", "png", "svg"]}
                    className=""
                    callBack={(response) => {
                        if ((response?.businessDocumentId?.length ?? 0) > 0) {
                            setUploadedFile(response);
                            setIsUploaded(true);
                        }
                    }}
                    multiple={false}
                    maxSize={10}
                    documentSetId={leadData?.caseDetails?.documentSetId}
                    setFileSize={setFileSize}
                    setUploadedFile={setUploadedFile}
                    setIsSelectedLenderDoc={false}
                    children={(
                        <Button style={{ color: "black" }}>Choose File</Button>
                    )}
                />
            )}
            <p style={{ fontSize: "8px", margin: "0", color: "#000000a6" }}>
                {(isTenantWorkingCapitalEnabled && !isUploaded) && (<span style={{ color: "red", fontSize:"12px" }}>Please upload disbursal proof</span>)}
            </p>
        </>
        ,
        leadData?.sourceOfCase === "CONNECTOR" && (
            <Form.Item
                style={{ paddingLeft: isMobile ? '10px' : '', width: isMobile ? 'auto' : '' }}
                name="payoutPercentage"
                label="Payout %"
                className="decision-item"
                rules={[
                    {
                        required: true,
                        message: "Please enter payout%",
                    },
                    {
                        pattern: DOUBLE_REGEX,
                        message: "Please enter valid payout%",
                    },
                    () => ({
                        validator(_, value) {
                            if (!value && value != 0) {
                                return Promise.reject();
                            }
                            if (isNaN(value)) {
                                return Promise.reject("Please enter valid payout %");
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <InputNumber
                    onChange={(ev: any) => {
                        finalLender.disbursalLenderInfoDetails[0].lenderAmount.payoutPercentage = ev;
                    }}
                    className="custom-input-number"
                />
            </Form.Item>
        ),
        <div style={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Form.Item>
                <Button
                    className="next-btn button-submit dynamic-btn-default"
                    style={{ '--borderColor': appColors?.appSecondaryColor ?? "" } as any}
                    onClick={() => {
                        const tmpLender = { ...finalLender };
                        const disburselLenderInfo = [
                            ...tmpLender.disbursalLenderInfoDetails,
                        ];
                        disburselLenderInfo.splice(0, 1);
                        tmpLender.disbursalLenderInfoDetails = disburselLenderInfo;
                        setFinalLander(tmpLender)
                        setIsAddClick(false);
                    }}
                >Cancel</Button>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className={isMobile ? 'mobile-view-icon-btn' : 'next-btn dynamic-btn-primary'}
                    style={customStylesSubmitBtnPrimary}
                    icon={isMobile ? <IconAction /> : ''}
                >Submit</Button>
            </Form.Item>
        </div>
    ];

    const handleCancel = () => {
        setShowConfirmationPayout(false);
        disbursedForm.setFieldsValue({
            payoutPercentage: checkTenativePayout,
        });
    };

    const confirmationPayout = () => {
        const customStylesBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "85px", height: "35px" };
        const customStylesBtn: any = { '--borderColor': appColors?.appSecondaryColor ?? "", width: "85px", height: "35px" };
        return (
            <Modal
                width={350}
                visible={showConfirmationPayout}
                style={{ marginBottom: 230 }}
                okText="Yes"
                cancelText="No"
                title=""
                centered
                closable={false}
                maskClosable={false}
                footer={false}
            >
                <div style={{ padding: "15px" }}>
                    <p style={{ fontSize: "17px" }}>
                        This is a fixed payout connector. Are you sure you want to edit the
                        payout% ?
                    </p>
                    <Space style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            type="primary"
                            className="dynamic-btn-primary"
                            style={customStylesBtnPrimary}
                            onClick={() => {
                                finalSubmit(finalValue);
                                setShowConfirmationPayout(false);
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            style={customStylesBtn}
                            className="dynamic-btn-default"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Space>
                </div>
            </Modal>
        )
    }

    const actionSubmit = (value: any) => {
        finalLender.disbursalLenderInfoDetails[0].lenderAmount.disbursedBy= value?.disbursedBy
        if (isTenantWorkingCapitalEnabled && (uploadedFile?.businessDocumentId?.length ?? 0) == 0) {
            notification.error({ message: "Please Upload Disbursal Proof" })
        } else {
            finalLender.disbursalLenderInfoDetails[0].lenderAmount.proofOfDisbursalDto = uploadedFile ? {
                "documentIdList": [uploadedFile?.businessDocumentId]
            } : null;
            if (
                payoutType == "FIXED" &&
                value?.payoutPercentage !== checkTenativePayout && value?.payoutPercentage != 0
            ) {
                setFinalValue(value);
                setShowConfirmationPayout(true);
            } else {
                finalSubmit(value);
            }
        }
    };

    const finalSubmit = (value: any) => {
        const infoEntity = finalLender?.disbursalLenderInfoDetails?.find(
            (item: any) => item?.disbursalId == null
        );

        const tmpLender = { ...finalLender };
        tmpLender.disbursalLenderInfoDetails = null;
        tmpLender.disbursalLenderInfoEntity = [infoEntity];
        if (value?.payoutPercentage == 0) {
            notification.error({
                message:
                    "Please ensure that payout % are filled, this field will not be editable beyond this point.",
            });
        } else {
            setIsLoading(true);
            setIsAddClick(false);
            // setLoading(true);
            onActionSubmit(tmpLender, value?.comment);
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                form={disbursedForm}
                style={{ padding: "25px" }}
                onFinish={actionSubmit}
            >
                <List
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
            </Form>
            {showConfirmationPayout && confirmationPayout()}
        </>
    )
};

export default AddDisbursed;