import React from "react";
import CustomButton from "../buttons/CustomButton";
import CustomInput from "../inputs/CustomInput";
import "./setpassword.css";
// import lock from "../asset/images/lock.svg";
import lock from "../../assets/images/lock.svg";
import { useState } from "react";
// import { useSelector } from "react-redux";
import { Navigate,Link } from "react-router-dom";

function SetPassword() {
  const [checkboxError, setCheckboxError] = useState('');
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState("");
  const [token,setToken] = useState("");

  let checkboxFill = false;

  const [inputValue, setInputValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = inputValue;

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue({
      ...inputValue,
      [e.target.name]: value,
    });
    setPasswordError("");
    setConfirmPasswordError("");
  };
  const focusHandler = (input) => {
    if (input === "confirmPassword") {
      if (inputValue.password.trim().length === 0)
        setPasswordError("Please Enter Your Password");
    }
  };
  const checkboxHandler = () => {
    checkboxFill = true;
  }

  const submitHandler = async () => {
    const urlData = {
      // mobileNumber: mobileNumber,
      password: inputValue.confirmPassword,
    };

    if (inputValue.password.trim().length === 0) {
      setPasswordError("Please Enter Your Password");
    } else if (inputValue.confirmPassword.trim().length === 0) {
      setConfirmPasswordError("Please Enter Your Password");
    } else if (checkboxFill === false) {
      setCheckboxError("Please fill The checkbox")
    }
    else {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/dsa/free/update/password`,
        {
          method: "POST",
          body: JSON.stringify(urlData),
          headers: {
            "Content-type": "application/Json",
          },
        }
      );
      if (!response.ok) {
        setError("Please Enter the Fields !");
      } else {
        const data = await response.json();
        setToken(data);        
      }
    }
  };

  return (
    <div>
      <div className="outer-form" style={{ marginBottom: "2rem" }}>
        <div className="inner-form" style={{ height: "650px" ,width:"390px" }}>
          <h3
            className="title"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(39, 56, 150, 0.12)",
                borderRadius: "50%",
                height: "112px",
                width: "112px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img style={{ width: "45%" }} src={lock} alt="" />
            </div>
          </h3>
          <h3 style={{ fontSize: "1.5rem" }} className="title11">Set Your Password</h3>
          <div  className="title111">
            <p>
              Please enter your mobile number and password to access your
              account
            </p>
          </div>
          <CustomInput
            type="text"
            value={password}
            placeholder="Enter Your Password"
            label="Password"
            name="password"
            onChange={handleChange}
            onfocus={(name) => focusHandler(name)}
          />
          {passwordError && <p className="error-text">{passwordError}</p>}
          <CustomInput
            type="text"
            value={confirmPassword}
            placeholder="Confirm Your Password"
            label="Confirmpassword"
            name="confirmPassword"
            onChange={handleChange}
            onfocus={(name) => focusHandler(name)}
          />
          {confirmPasswordError && (
            <p className="error-text">{confirmPasswordError}</p>
          )}
          <div className="title11">
            <div style={{ padding: "20px" }}>
              <input
                type="checkbox"
                style={{ transform: "scale(1.5)" }}
                name=""
                id="check"
                onClick = {checkboxHandler}
              />{" "}
              
              {/* <label htmlFor="check">
                I accept the <span className="colored"> Terms Of Use</span>,
                <span className="colored"> Privacy Policy</span> and{" "}
                <span className="colored"> Marketing permissions</span>.
              </label> */}
              
            </div>
            {checkboxError && (<p className="error-text">{checkboxError}</p>)}
            <CustomButton name="Continue" onSubmit={submitHandler} />
            {error && <p>{error}</p>}
          </div>
        </div>
        {token && <Link to="/dashboard"></Link>}
      </div>
      <Link to="/"className="back">Go Back To Login</Link>
    </div>
  );
}

export default SetPassword;
