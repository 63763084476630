import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Table,
  Modal,
  Tabs,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import search from "../../../assets/images/search.png";
import { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import {
  setPageNo,
  setPageSize,
  setSearchParam,
} from "shared/redux/decisioning-audit-reducer";
// import { RootState } from "../../../src/shared/redux/store";
// import { API_SERVICE } from "../../../src/shared/services/api-services";
import Publish from "./PublishID";
import ReactJson from "@microlink/react-json-view";
import { RootState } from "shared/redux/store";
import API_SERVICE from "shared/services/api-service";
import { setScreenHeader } from "shared/redux/common-reducer";
import "./decisioningAudit.scss";

interface columns {
  key: React.ReactNode;
  name: string;
  description: string;
}

type IDecisioningAudit = {};

const DecisioningAudit: React.FunctionComponent<IDecisioningAudit> = () => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [descAuditList, setDecAuditList] = useState([]);
  const decaudit: any = useSelector(
    (state: RootState) => state.decisioningAudit
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpenTitle, setModalOpenTitle] = useState("");
  const [displayJson, setDisplayJson] = useState({} as any);
  const [caseId, setCaseId] = useState("");
  const [publishedId, setPublishedId] = useState("");
  const [openPublish, setOpenPublish] = useState(false);
  const [outputData, setOutputData] = useState<any>({});
  const [decisionData, setDecisionData] = useState({} as any);
  const [searchCaseForm] = Form.useForm() as any;
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Decisioning Audit" }));
  }, []);

  const columns: ColumnsType<columns> = [
    {
      title: "Decision Data ID",
      dataIndex: "decisioningDataId",
      key: "decisioningDataId",
    },
    {
      title: "Decision Master Name",
      dataIndex: "decisioningMasterName",
      key: "decisioningMasterName",
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
    },
    {
      title: "Journey Name",
      dataIndex: "journeyName",
      key: "journeyName",
    },
    {
      title: "Input",
      align: "center",
      render: (val: any, record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              setDisplayJson(record.inputData);
              showModal("Input");
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Output",
      align: "center",
      render: (record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              setDisplayJson(record.outputData);
              showModal("Output");
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Decentro",
      align: "center",
      render: (val: any, record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              getDecentroFinbox(caseId, val.decisioningDataId, "GET_BUREAU");
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Finbox",
      align: "center",
      render: (val: any, record: any) => {
        return (
          <a
            style={{ color: "#008dff" }}
            onClick={() => {
              getDecentroFinbox(
                caseId,
                val.decisioningDataId,
                "BANK_STATEMENT_ANALYSIS"
              );
            }}
          >
            Click here
          </a>
        );
      },
    },
    {
      title: "Publish ID",
      dataIndex: "decisioningPublishedId",
      key: "decisioningPublishedId",
      align: "center",
      render: (text: string, record: any) => {
        return (
          <>
            <a
              style={{ color: "#008dff" }}
              onClick={() => {
                setDecisionData(record);
                setOutputData(record?.outputData);
                setPublishedId(text);
                getPublishDetails(text);
              }}
            >
              {text}
            </a>
          </>
        );
      },
    },
  ];

  const getPublishDetails = (publishId: any) => {
    setLoading(true);
    const params = { decisioningPublishedId: publishId };
    API_SERVICE.getPublishDetails(params)
      .then(({ data }: any) => {
        setOpenPublish(true);
        setLoading(false);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const getDecentroFinbox = (
    caseId: any,
    decisioningDataId: any,
    vasType: any
  ) => {
    setLoading(true);
    const params = {
      caseId: caseId,
      decisioningDataId: decisioningDataId,
      vasType: vasType,
      loggedInFrom: "WEB",
    };
    API_SERVICE.getDecentroFinbox(params)
      .then(({ data }: any) => {
        if (data?.payload?.decentroResponse) {
          setDisplayJson(data?.payload?.decentroResponse);
          showModal("Decentro");
        }

        if (data?.payload?.finboxResponse) {
          setDisplayJson(data?.payload?.finboxResponse);
          showModal("Finbox");
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const getDecisionAuditList = (
    pageNo: number = 1,
    pageSize: any = 10,
    searchParam: any = null
  ) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      caseId: searchParam,
      loggedInFrom: "WEB",
    };
    API_SERVICE.getDecisioningAuditList(params)
      .then(({ data }: any) => {
        setDecAuditList(data.payload.content);
        setTotalCount(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) =>
        notification.error({ message: API_SERVICE.handleErrors(e) })
      )
      .finally(() => setLoading(false));
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setPageNo(current));
    dispatch(setPageSize(pageSize));
    getDecisionAuditList(current, pageSize, decaudit.searchParam);
  };

  const handleSearch = (value: any) => {
    setOpenPublish(false);
    setCaseId(value?.searchParam);
    dispatch(setPageNo(1));
    dispatch(setSearchParam(value?.searchParam));
    getDecisionAuditList(1, decaudit.pageSize, value?.searchParam);
  };

  const showModal = (title: any) => {
    setIsModalOpen(true);
    setModalOpenTitle(title);
  };

  const showJsonData = (displayJson: any) => {
    return <pre>{JSON.stringify(displayJson, null, 2)}</pre>;
  };

  const modalView = (modaltitle: string) => {
    return (
      <Modal
        title={modaltitle}
        width={900}
        onCancel={() => setIsModalOpen(false)}
        visible={isModalOpen}
        closable
        footer={null}
      >
        <div style={{ height: "72vh", overflow: "auto" }}>
          <ReactJson
            src={displayJson}
            displayDataTypes={false}
            collapsed={true}
          />
        </div>
      </Modal>
    );
  };

  const backDecisioning = () => {
    setOpenPublish(false);
  };

  const addButtons = (
    <div className="add-btns">
      <Button
        type="text"
        style={{ color: "#1677ff" }}
        onClick={backDecisioning}
      >
        Back
      </Button>
    </div>
  );

  const renderTabView = () => {
    return (
      <Tabs
        tabBarExtraContent={addButtons}
        className="scorecard-tabs"
        style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
        defaultActiveKey="1"
        type="card"
      >
        <Tabs.TabPane key="2" tab={<span className="tabActive">Publish</span>}>
          <Publish
            currentPublishedId={publishedId}
            decisionData={decisionData}
            outputData={outputData}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  return (
    <>
      {modalView(modalOpenTitle)}
      <div className="content-box" style={{ marginTop: "40px" }}>
        <Form layout="inline" form={searchCaseForm} onFinish={handleSearch}>
          <Form.Item
            name="searchParam"
            label="Case ID"
            rules={[
              {
                required: true,
                message: "Please enter caseId",
              },
            ]}
          >
            <Input
              size="large"
              className="search-box"
              prefix={<img src={search} alt="" />}
              placeholder="Search"
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="add-button dynamic-btn-primary"
              type="primary"
              style={
                {
                  "--btnColor": appColors?.appPrimaryColor ?? "",
                  width: "100px",
                  height: "40px",
                } as any
              }
              htmlType="submit"
            >
              Search
            </Button>
          </Form.Item>
        </Form>
        {!openPublish ? (
          <div>
            <Row style={{ paddingTop: "30px" }}>
              <Col xs={24} xl={24} span={13}>
                <Table
                  className="dynamic-pagination-checkbox"
                  columns={columns}
                  style={
                    { "--bgColor": appColors?.appPrimaryColor ?? "" } as any
                  }
                  dataSource={descAuditList}
                  size="small"
                  loading={loading}
                  onChange={(pagination: any) => {
                    handlePaginationChange(
                      pagination.current,
                      pagination.pageSize
                    );
                  }}
                  pagination={{
                    position: ["bottomCenter"],
                    current: decaudit.pageNo,
                    pageSize: decaudit.pageSize,
                    total: totalCount,
                  }}
                  scroll={{ x: "max-content" }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          renderTabView()
        )}
      </div>
    </>
  );
};

export default DecisioningAudit;
