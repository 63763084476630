import { Button, Card, Divider, Form, Table, notification } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "shared/redux/store";
import API_SERVICE from "shared/services/api-service";

const PaySubConnectore: React.FunctionComponent<any> = (props) => {
  const [allSubConnector, setAllSubConnector] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  let { id } = useParams() as any;
  const [updateConnectors, setUpdateConnectors] = useState<any>([]);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const {isEditable } = props

  useEffect(() => {
    getPaySubConnetor(page, pageSize)
  }, [])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: isEditable && onSelectChange,
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'emailId',
      key: 'emailId',
    },
    {
      title: 'Business ID',
      dataIndex: 'businessId',
      key: 'businessId',
    },
    {
      title: 'Mobile',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
  ];

  const getPaySubConnetor = (pageNo: number = 1, pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getPaySubConnector(id, params)
      .then(({ data }: any) => {
        if ((data?.payload?.content?.length ?? 0) > 0) {
          data.payload.content.map(subConnector => {
            subConnector.key = subConnector.id;
            if (subConnector?.canAddBankInfo && true === subConnector?.canAddBankInfo) {
              setSelectedRowKeys(oldSelectedRowKeys => [...oldSelectedRowKeys, (subConnector.id)]);
              setUpdateConnectors(updateConnectors => [...updateConnectors, ...new Array({ canAddBankInfo: true, connectorId: subConnector.id })]);
            } else {
              setUpdateConnectors(updateConnectors => [...updateConnectors, ...new Array({ canAddBankInfo: false, connectorId: subConnector.id })]);
            }
          });
          setAllSubConnector(data?.payload?.content);
        } else {
          notification.error({ message: "No Employees found." });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updatePaySubConnetor = () => {
    for (let i in updateConnectors) {
      if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys.indexOf(updateConnectors[i].connectorId) > -1)
        updateConnectors[i].canAddBankInfo = true;
      else
        updateConnectors[i].canAddBankInfo = false;
    }
    API_SERVICE.updateBankInfoFlagForConnectors(updateConnectors)
      .then(({ data }: any) => {
        if (data.payload) {
          notification.success({ message: data.payload });
          setUpdateConnectors([]);
          setSelectedRowKeys([]);
          getPaySubConnetor(page, pageSize);
        } else {
          notification.error({ message: "No Employees found." });
        }
      })
      .finally(() => {
        setLoading(false);
      });

  }

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "", marginRight: '10px'};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <Card className="custom-app-card">
      <Form>
        <Table
          className="dynamic-pagination-checkbox"
          style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
          loading={loading}
          columns={columns}
          dataSource={allSubConnector}
          rowSelection={rowSelection}
          size="small"
        />
        <Divider />
        <div style={{ display: "flex", justifyContent: "end", marginBottom: "10px", marginTop: "-10px" }}>
          <Button  type="default" className="dynamic-btn-default" style={btnDefaultBorderStyle} >Cancel</Button>
          <Button type="primary" htmlType="submit" onClick={updatePaySubConnetor} className="dynamic-btn-primary"
                            style={btnPrimaryStyles}>Save</Button>
        </div>
      </Form>
    </Card>
  );
};

export default PaySubConnectore;