import { Form as Formio } from "react-formio";
import "./LeadDetailNew.scss";
import editIconNew from "../../../../assets/images/editIconNew.svg";
import {ReactComponent as IconEdit} from "../../../../assets/images/edit_border.svg";
import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Modal, Space } from "antd";
import API_SERVICE from "shared/services/api-service";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IProductSteps {
  getTaskData: any;
  submitTask: any;
  leadData: any;
  getJSONSchema: any;
  formJsonSchema: any;
  isStepReadOnly: any;
  currentSteps: any;
  getNextTask: any;
  setGetTaskData: any;
  isDecisioningTabAvailable: boolean;
}

const ProductSteps: React.FC<IProductSteps> = (props: IProductSteps) => {
  const {
    getTaskData,
    submitTask,
    leadData,
    getJSONSchema,
    formJsonSchema,
    isStepReadOnly,
    currentSteps,
    getNextTask,
    setGetTaskData,
    isDecisioningTabAvailable,
  } = props;
  //const jsonSchema = getJSONSchema(getTaskData?.jsonSchema ?? "{}");
  const jsonSchema = getJSONSchema(formJsonSchema ?? "{}");
  const [isEditDetils, setIsEditDetails] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  let tmpFormData = JSON?.parse(getTaskData?.formData ?? "{}") as any;

  const onSubmit = (value) => {
    let tmpObj = Object.assign(tmpFormData, { ...value?.data })
    setGetTaskData({...getTaskData, formData: JSON?.stringify(tmpObj)})
    const payload = {
      formData: JSON?.stringify(value?.data),
      taskDefinitionKey: getTaskData?.taskDefinitionKey,
      taskId: getTaskData?.taskId,
    };
    submitTask(payload);
  };
  const callbackForMobile = (actionType: string) => {
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ actionType: actionType })
      );
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",width: "100px" };
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width: "100px" };

  const actionEditOk = () => {
    setIsEditDetails(false);
    callbackForMobile('EDIT_BUTTON_CLICK');
    getNextTask({
      processInstanceId: leadData?.processInstanceId,
      gotoStep: currentSteps,
      // clientType: "WEB",
    });
    API_SERVICE.purgeAllCaseDecisioningData(
      leadData?.caseDetails?.caseId
    )
      .then((data) => { })
      .catch((e: any) => API_SERVICE.handleErrors(e));   
  }

  const renderEditModal = () => (
    <Modal
      width={450}
      visible={isEditDetils}
      closable={false}
      maskClosable={false}
      footer={false}
      centered
    >
      <div style={{ padding: "30px" }}>
        <p
          style={{
            textAlign: "center",
            fontSize: "18px",
            marginBottom: "30px",
          }}
        >
          Editing of values will cause override of previous decisioning. Please re-run the process for updated decisioning.
        </p>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button
            className="dynamic-btn-default"
            style={btnDefaultBorderStyle}
            size="middle"
            onClick={() => setIsEditDetails(false)}
          >
            Cancel
          </Button>
          <Button
            size="middle"
            type="primary"
            className="dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={() => actionEditOk()}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {isStepReadOnly && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
              className="custome-btn-icon"
              style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
              size="small"
              type="text"
              onClick={() => {
                if(isDecisioningTabAvailable) {
                  setIsEditDetails(true)
                } else {
                  actionEditOk();
                }
              }}
              icon={<IconEdit />}
            />
        </div>
      )}
      <div className="dynamic-formio"
        style={{ '--btnColor': appColors?.appPrimaryColor ?? "" } as any}>
        <Formio
          options={{
            readOnly: isStepReadOnly,
            renderMode: isStepReadOnly ? "html" : "",
          }}
          key={isStepReadOnly ? currentSteps : getTaskData?.taskDefinitionKey}
          form={jsonSchema}
          jsonSchema={jsonSchema}
          submission={{ data: tmpFormData }}
          onSubmit={onSubmit}
        // onCustomEvent={() => setTestDecision(false)}
        />
      </div>
      {isEditDetils && renderEditModal()}
    </>
  );
};

export default ProductSteps;
