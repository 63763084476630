import { Button, Input, Modal, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import "./index.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IReplaceDocument {
  showRenameDoc: boolean;
  setShowRenameDoc: any;
  selectedDocument: any;
  callbackSuccess?: any;
}

const RenameDocument: React.FunctionComponent<IReplaceDocument> = (
  props: IReplaceDocument
) => {
  const { showRenameDoc, setShowRenameDoc, selectedDocument, callbackSuccess } =
    props;

  const [loading, setLoading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentNameExt, setDocumentNameExt] = useState("");
  const {appColors}: any = useSelector((state: RootState) => state.common);

  console.log("selectedDocument -- : ", selectedDocument);

  useEffect(() => {
    const name = selectedDocument?.documentDetails?.documentName;
    const names = name?.split(".");
    setDocumentNameExt(names.at(-1));
    // names?.pop();
    console.log("::names", names);
    setDocumentName(names.at(0));
  }, []);

  const actionOk = () => {
    setLoading(true);
    API_SERVICE.renameDocument(
      selectedDocument?.documentDetails?.businessDocumentId,
      {
        documentName: documentName,
      }
    )
      .then(({ data }) => {
        callbackSuccess({
          ...data.payload,
          documentName: `${data.payload.documentName}.${data.payload.fileExtn}`,
        });
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      });
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <>
      <Modal
        title="Rename Document"
        centered
        width={400}
        onCancel={() => setShowRenameDoc(false)}
        footer={null}
        visible={showRenameDoc}
      >
        <div className="rename-doc">
          <Input
            size="large"
            className="custom-input"
            onChange={(e) => {
              setDocumentName(e?.target?.value);
            }}
            value={documentName}
          />

          <div className="buttons">
            <Button className="dynamic-btn-default" style={btnDefaultBorderStyle} type="default" onClick={() => setShowRenameDoc(false)}>
              Cancel
            </Button>
            <Button
              className="dynamic-btn-primary" 
              style={btnPrimaryStyles}
              type="primary"
              onClick={() => {
                actionOk();
              }}
              loading={loading}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RenameDocument;
