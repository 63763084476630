import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { FaPen } from "react-icons/fa";
import { ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import "./index.scss";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Option } = Select;
const { Title, Text } = Typography;

type Props = {};

const EditLender: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const location = useLocation() as any;
  const { lenderData } = location.state;
  const [pIDetailList, setPIDetailList] = useState([] as any);
  const [hubList, setHubList] = useState([] as any);
  const [products, setProducts] = useState([] as any);
  const [showDSAIdView, setShowDSAIdView] = useState(false);
  const [routingMode, setRoutingMode] = useState("Automation");
  const [selectedLoanProduct, setSelectedLoanProduct] = useState(undefined);
  const [consentRequired, setConsentRequired] = useState(lenderData?.consentRequired)
  const [consentType, setConsentType]=useState('') as any;
  const [selectedLoanProductId, setSelectedLoanProductId] =
  useState<any>(undefined);
  const [selectedHub, setSelectedHub] = useState(null as any);

  const [addLenderForm] = Form.useForm() as any;
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Lender",
        backScreenPath: "/lenders",
        screenTitle: "Edit Lender",
      })
    );
    addLenderForm.setFieldsValue({
      bankName: lenderData.lenderName,
      product: lenderData?.productId[0],
      hub: lenderData?.hubName,
      pinCode: lenderData?.pinCode,
      status: lenderData?.active,
      consentRequired:lenderData?.consentRequired,
      consentType:lenderData?.consentType,
      city: lenderData?.city,
    });
    setConsentType(lenderData?.consentType)
    getFiList();
  }, []);

  const addLender = (values: any) => {
    if (routingMode == "API") {
      updatePartnerIdentificationDetails(values);
    } else {
      updateLenderHandler(values);
    }
  };

  const updateLenderHandler = (values: any) => {
    const payload = {
      active            : values.status,
      consentType       : (consentType==="Noconsent"?null:consentType),
      consentRequired   : consentRequired,
      dsaCode           : lenderData.dsaCode,
      finInfoId         : lenderData.lenderInfoId,
      hub               : values.hub,
      name              : values.bankName,
      pinCode           : values.pinCode,
      productId         : selectedLoanProductId,
      products          : [selectedLoanProduct],
      submissionType    : routingMode,
      city              : values.city,
    };

    API_SERVICE.updateLender(payload, lenderData.lenderMappingId)
      .then(({ data }) => {
        if (data) {
          if (data.message) {
            notification.success({ message: data.message });
            history.goBack();
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getFiList = () => {
    API_SERVICE.getFiList({ pageSize: 500 }).then(({ data }) => {
      if (data?.payload?.content) {
        let filterResult = data?.payload?.content?.find((result) => {
          return result?.fiId === lenderData?.lenderInfoId;
        });
        setProducts(filterResult?.lenderLoanDefinition?.allLoanTypes);
        setHubList(filterResult?.hubDetails);
      }
    });
  };

  const updatePartnerIdentificationDetails = (values) => {
    const keys = Object.keys(values);
    const filterDataObject = [] as any;

    pIDetailList?.map((data) => {
      let filterData = keys.filter((item) => item === data?.key);
      filterDataObject.push({
        key: filterData[0],
        value: values[filterData[0]],
      });
    });

    const params = {
      lenderId: lenderData.lenderInfoId,
      productIds: [selectedLoanProductId],
      partnerIdentificationKeyDetail: {
        partnerIdentificationDetails: filterDataObject,
      },
    };

    API_SERVICE.updatePartnerIdentificationDetails(
      lenderData.lenderInfoId,
      params
    ).then(({ data }) => {
      updateLenderHandler(values);
    })
    .catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
  };

  const getPartnerIdentificationDetails = (lenderId, loanProductId) => {
    API_SERVICE.getPartnerIdentificationDetails(
      lenderId,
      loanProductId
    ).then(({ data }) => {
      if (data?.payload?.partnerIdentificationDetails) {
        setPIDetailList(data.payload.partnerIdentificationDetails);
        setRoutingMode(lenderData?.submissionType);
      }
    });
  };

  const changeProductHandler = (value: any) => {
    const productFiltered = products?.filter(
      (item) => item.loanProductId == value
    );
    if ((productFiltered?.length ?? 0) > 0) {
      setSelectedLoanProductId(productFiltered[0].loanProductId);
      setSelectedLoanProduct(productFiltered[0].loanProductName);

      getPartnerIdentificationDetails(
        lenderData?.lenderInfoId,
        productFiltered[0]?.loanProductId
      );
    }
  };

  const changeHubHandler = (value: any) => {
    let selectedHub = hubList.find((item) => item.hubName == value);
    addLenderForm.setFieldsValue({
      pinCode: selectedHub?.pinCode,
    });
    setSelectedHub(selectedHub);
  };

  const columns = [
    {
      title: "Product Name",
      key: "product",
    },
    {
      title: "Routing Mode",
      key: "submissionType",
      render: (_: any, record: any) => <div> {lenderData?.submissionType}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon-secondary"
            style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
            size="small"
            type="text"          
            icon={<IconEditBorder />}
            onClick={() => {
              setShowDSAIdView(true);
              setSelectedLoanProductId(lenderData?.productId[0]);
              setSelectedLoanProduct(lenderData?.products[0]);
              setRoutingMode(lenderData?.submissionType);
              if (lenderData?.submissionType == "API") {
                getPartnerIdentificationDetails(
                  lenderData?.lenderInfoId,
                  lenderData?.productId[0]
                );
              }
            }}
          />
          {/* <Popconfirm
            title="Sure to delete?"
            // onConfirm={(event) => handleDelete(event, record)}
          >
            <Space
              className="action-button"
              size="middle"
              style={{ marginLeft: "1%" }}
            >
              <Button
                className="pink"
                size="small"
                type="primary"
                shape="circle"
                // onClick={() => handleDelete}
                icon={<AiFillDelete />}
              />
            </Space>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const btnRadioStyles: any = { '--borderColor': appColors?.appPrimaryColor ?? "" };
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  const renderOther = () => {
    return (
      <>
        <Form.Item
          className="field-bg-border"
          name="product"
          label="Product"
          rules={[{ required: true, message: "Please enter product name" }]}
        >
          <Select
            showSearch
            className="custom-select"
            placeholder="Product"
            optionFilterProp="children"
            defaultValue={lenderData?.productId[0]}
            onChange={(value, option) => changeProductHandler(value)}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(input?.toLowerCase())
            }
          >
            {products?.map((product: any, i: number) => (
              <Option key={i} value={product.loanProductId}>
                {product.loanProductName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="routingMode"
          style={{ marginTop: "5px" }}
          label="Select Case Routing Mode"
        >
          <Radio.Group
            className="dynamic-radio"
            style={btnRadioStyles}
            defaultValue={lenderData?.submissionType}
            onChange={(e) =>{
              setRoutingMode(e?.target?.value)
              setConsentType(e?.target?.value==="API"?"GLOBAL":(lenderData?.consentType))
              addLenderForm.setFieldsValue({
                //consentRequired:lenderData?.consentRequired,
                consentType:(e?.target?.value==="API"?"GLOBAL":(lenderData?.consentType))
              });
            }
            }
            name="routingMode"
          >
            <Radio value={"API"}>API</Radio>
            <Radio value={"Automation"} checked>
              Automation
            </Radio>
            <Radio value={"Login"}>Login</Radio>
            <Radio value={"Weblink"}>Weblink</Radio>
          </Radio.Group>
        </Form.Item>

        {routingMode == "API" ? (
          <>
            {pIDetailList?.map((item) => {
              return (
                <Form.Item
                  className="field-bg-border"
                  name={item.key}
                  label={item.key}
                  rules={[
                    { required: true, message: `Please enter ${item.key}` },
                  ]}
                  initialValue={item.value}
                >
                  <Input
                    placeholder={`Enter ${item.key}`}
                    className="custom-input"
                  />
                </Form.Item>
              );
            })}
          </>
        ) : (
          <>
            <Form.Item
              className="field-bg-border"
              label="Hub"
              rules={[{ required: true, message: "Please enter hub" }]}
              name="hub"
            >
              <Select
                showSearch
                className="custom-select"
                placeholder="Hub"
                optionFilterProp="children"
                defaultValue={lenderData?.hubName}
                onChange={(value, option) => changeHubHandler(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {hubList?.map((item: any, i: number) => (
                  <Option key={i} value={item.hubName}>
                    {item.hubName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="field-bg-border"
              name="pinCode"
              label="Pin Code"
            >
              <Input
                readOnly
                className="custom-input"
                defaultValue={lenderData?.pinCode}
                placeholder="Please enter pincode"
              />
            </Form.Item>
            <Form.Item
              className="field-bg-border"
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter city" }]}
            >
              <Input placeholder="Please enter city" className="custom-input" />
            </Form.Item>
          </>
        )}
       {/*} <Form.Item name="consent" label="Consent" style={{ color: "green" }}>
          <Switch defaultChecked={lenderData?.consentRequired}
            onChange={(e)=>setConsentRequired(!consentRequired)}
          />
      { (consentRequired) && (*/}
          <Radio.Group
            className="dynamic-radio"
            style={btnRadioStyles}
            name="consentType"
            value={consentType}
            onChange= { (e) => {
              setConsentType(e?.target?.value) 
              setConsentRequired(e?.target?.value!="Noconsent")
            }}
            >
            <Radio value = "Noconsent">No Consent</Radio>
            <Radio value = "GLOBAL">Global</Radio>
            <Radio value = "Individual">Individual</Radio>
          </Radio.Group>
          {/*})
        }
      </Form.Item>*/}
        <Form.Item name="status" label="Status" style={{ color: "green" }}>
          <Switch defaultChecked={lenderData?.active} style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}/>
        </Form.Item>

        <Form.Item style={{ marginTop: "50px" }}>
          <Space className="buttons">
            <Button
              className="dynamic-btn-primary"
              style={btnPrimaryStyles}
              size="large"
              //   loading={loading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            <div></div>
          </Space>
        </Form.Item>
      </>
    );
  };

  return (
    <div className="content-box">
      <Form
        size="large"
        layout="vertical"
        form={addLenderForm}
        autoComplete="off"
        onFinish={addLender}
      >
        <Row gutter={[64, 16]}>
          <Col span={14}>
            <Form.Item
              className="field-bg-border"
              name="bankName"
              label="Lender Name"
              rules={[{ required: true, message: "Please enter bank name" }]}
            >
              <Text>{lenderData.lenderName}</Text>
            </Form.Item>

            <Card className="Added-Product-List">
              <Title level={3}>Added Product List</Title>

              <Table
                scroll={{ y: 300 }}
                bordered={false}
                pagination={false}
                dataSource={lenderData?.products ?? []}
                columns={columns}
              ></Table>
            </Card>
          </Col>

          <Col span={10}>{showDSAIdView ? renderOther() : null}</Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditLender;
