import { Button } from "antd";
import mobile from "../../assets/images/mobile.svg";
import email from "../../assets/images/email.svg";
import network from "../../assets/images/Website.svg";
import { ReactComponent as Arrow } from "../../assets/images/Arrow.svg";
import SESSion_SERVICE from "../../shared/services/session-service";
import React, { useState, useEffect } from "react";
import { RWebShare } from "react-web-share";
import "./visiting-card.scss";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

type Props = {};

const VisitingCards: React.FunctionComponent<Props> = () => {
  const user = SESSion_SERVICE.getUserDetails();
  const logoDeatils = localStorage.getItem("getlogoDetail") as any;
  const [documentData, setDocumentData] = useState(null as any);
  const [docType, setDocType] = useState(null as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Visiting Crads" }));
    setDocType(JSON.parse(logoDeatils)?.fileExtn);
    setDocumentData(JSON.parse(logoDeatils)?.documentDetails);
  }, []);

  const site = ["whatsapp", "twitter", "facebook"];

  const customStyles: any = { "--color": appColors?.appPrimaryColor ?? "" };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };

  return (
    <div className="content-box">
      <div style={{ marginTop: "10px" }}>
        <div className="visiting-card-main">
          <div className="bg">
            <div className="visiting-card-outer">
              <div className="split left">
                <div
                  style={{
                    height: "48%",
                    alignContent: "end",
                    display: "grid",
                    paddingBottom: "20px",
                  }}
                >
                  {documentData ? (
                    <img
                      className="visit-image"
                      src={"data:" + docType + ";base64," + documentData}
                      alt=""
                      style={{
                        objectFit: "contain",
                        minHeight: "30px",
                        maxHeight: "50px",
                      }}
                    />
                  ) : null}
                </div>

                <div className="visit-divider">
                  <div className="divider"></div>
                </div>

                <div style={{ height: "48%" }}>
                  <p className="visiting-name" style={customStyles}>
                    {user?.fullName}
                  </p>
                  <p>{user?.designation}</p>
                </div>
              </div>

              <div className="visit-icon-display" style={btnPrimaryStyles}>
                <img className="visit-mobile-icon" src={mobile} alt="" />{" "}
                <img className="visit-email-icon" src={email} alt="" />
                <img className="visit-network-icon" src={network} alt="" />
              </div>

              <div className="split right">
                <div className="visit-information">
                  <p>+91 {user?.phoneNumber}</p>
                  <p>{user?.emailId}</p>
                  <p
                    style={{
                      height: "20px",
                      lineBreak: "anywhere",
                      width: "160px",
                    }}
                  >
                    {tenantInfo?.tenantBusinessWebsiteUrl}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <RWebShare
            data={{
              // text: "hello",
              url: "",
              // title: "Flamingos",
            }}
            sites={site}
            onClick={() => console.log("shared successfully!")}
          >
            <Button
              type="primary"
              className="visit-share-butoon"
              icon={<Arrow />}
              style={btnPrimaryStyles}
            >
              {" "}
              Share
            </Button>
          </RWebShare>
        </div>
      </div>
    </div>
  );
};

export default VisitingCards;
