import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const bulkUploadSlice = createSlice({
  name: "BulkUploadReducer",
  initialState: { pageNo: 1, pageSize: 10 },
  reducers: {
    setBulkUploadPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setBulkUploadPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBulkUploadPageNo, setBulkUploadPageSize } =
  bulkUploadSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default bulkUploadSlice.reducer;
