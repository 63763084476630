export const DASHBOARD_ROUTE = '/dashboard';
export const LEAD = '/lead';
export const CONNECTORS = '/connectors';
export const PAYOUT_REQUEST = '/payout-request';
export const ATTENDANCE = '/attendance';
// export const EMPLOYEE = '/employee';
export const ROLES = '/roles';
export const PERMISSIONS = '/permissions';
export const HIERARCY = '/hierarcy';
export const HELP = '/help';
export const POC='/poc'
export const AGENT = '/agent';
export const LOAN_PRODUCT = '/load-product';
export const BANNERS = '/banners';
export const REPORT = '/report';
export const NOTIFICATION = '/notification';
export const APP_CONTENT = '/app-content';
export const BRANCHES = '/branches';
export const DEPARTMENT = '/department';
export const DESIGNATION = '/designation';
export const LOCATION = '/location';
export const OFFERS = '/offers';
export const NOTICES = '/notices';
export const EMPLOYEES = '/employees';
export const USER_PROFILE = '/profile';
export const LENDERS = '/lenders';
export const INSURER = '/insurer'
export const LOGOUT = '/logout';
export const Greeting_Crads = '/greeting-cards';
export const Visiting_Crads = '/visiting-cards';
export const Decisioning_Audit = '/decisioning-audit';
