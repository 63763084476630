import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const connectorSlice = createSlice({
  name: "ConnectorReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null, status: "ALL" },
  reducers: {
    setConnectorPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setConnectorPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setConnectorSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    },
    setConnectorStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setConnectorPageNo, setConnectorPageSize, setConnectorSearchParam, setConnectorStatus } = connectorSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default connectorSlice.reducer;