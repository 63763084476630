export const API_CONFIG = {
    login: 'free/user/login/',
    checkPhone: 'free/get/otp?phoneNumber=',
    resetPassword: 'free/reset/password',
    verifyOtp: 'free/verify/otp',
    tenantDocDetails: 'tenant/short-details',
    enumValues: 'enum-values?loggedInFrom=web',
    updateConnectorBusinessId: 'connector/business/convert-to-sub-connector',
    connectorLists: 'list/connector',
    connectorList: 'list/connectors',
    subConnectorList: 'list/sub-connector/connector/',
    payoutOfCaseDisbursed: 'list/cases/disbursed',
    PayoutOfList: 'payout/list',
    updatePayoutStatus: 'payout/update/status',
    payoutsOfConnectorList: 'list/payout',
    downloadPayoutDetails: 'payout/download',
    approvePayoutInfo: 'update/payout/',
    searchCustomers: 'search/customers?searchParam=',
    updateBusinessId: 'connector/validate/business/',
    connectorListByStatus: 'list/connectors',
    connectorById: 'get/connector',
    assignSalesUserToConnector: 'assign/sales-to-connector/',
    panDetails: 'external/v1/get/pan/verification',
    panDetailsForProfile: 'get/pan-profile/details',
    pinDetails: 'free/get/pin-code-details',
    addLeadBusiness: 'add/business-case',
    addLeadIndividual: 'add/individual-case',
    getLeadAdditional: 'get/lead-additional/pan/',
    editLead: '/update/case/',
    leadList: 'list/cases',
    leadListMobile: 'list/leads/',
    leadListbyFilter: 'filter/case',
    leadListbyFilterPostLender: 'das/lender/filter/cases/list',
    heirarchyUsers: 'list/heirarchy/user/',
    leadListById: 'get/case/',
    addConnector: 'add/connector',
    updateConnector: 'update/connector',
    updateConnectorStatus: 'update/status/connector',
    addDepartment: '/update',
    listDepartment: '/list',
    addDesignation: '/update',
    listDesignation: '/list',
    listLocation: '/list',
    addLocation: '/update',
    fileUploadfree: 'free/fileupload',
    fileUpload: 'fileupload',
    paySubConnector: '/list/sub-connector?',
    paySubConnectorUpdate: '/connectors/bank-info-flag',
    addDocument: "document-upload/add/",
    addDocumentfree: "document-upload/free/add/documentset/",
    documentList: 'document-upload/documentset/',
    documentListfree: 'document-upload/free/documentset/',
    deleteDoc: 'document-upload/delete/',
    uploadDocument: 'document-upload/add/documentset',
    documentHierarchyListfree: "document-upload/free/document-hierarchy/list",
    documentHierarchyList: "document-upload/document-hierarchy/list",
    caseDetailByShareLinkId: "free/shareLink/",
    basicOffersByCaseId: "offers/case/",
    addLender: 'dsa/lender/add',
    lendersList: 'lender/list/lenders/',
    updateLender: 'lender/update/',
    deleteLender: 'lender/delete/',
    fiList: 'dsa/lender/list/fi',
    listOffer: 'business-communication/list',
    addOfferOrNoticeOrGreeting: 'business-communication/add',
    addGreetings: 'business-communication/add',
    searchuser: 'user/search_users',
    usersWithRole: 'list/users?role=ROLE_EMPLOYEE',
    allNotificationList: 'notification/list/all?',
    notificationList: 'notification/list?',
    updateNotificationStatus: 'notification/update',
    unreadNotificationCnt: 'notification/get/unread-count',
    clearAllNotifications: 'notification/clear-all',
    addemployee: 'add/employee',
    bureauCredits: 'bureau/get-counts/bureau-credits/?',
    listAppContent: 'business-communication/get/free/app_content/',
    listAppContentFAQ: 'business-communication/list/',
    helpTermsAndConditions: "business-communication/get/free/app_content/",
    helpFaq: "business-communication/get/free/app_content/",
    helpPrivacyPolicy: "business-communication/get/free/app_content/",
    addAppContent: 'business-communication/add/app_content/',
    addLoanProduct: "/update",
    listLoanProduct: "/list",
    getBusinessid: 'business-communication/get/',
    updateOffers: 'business-communication/update/',
    delDocument: "document-upload/delete/document/",
    lenderList: 'lender/list',
    employeList: 'list/employees',
    updateCase: 'update/case/',
    searchUser: 'user/search_users',
    user: "get/user/",
    statusChange: "update/user/status/",
    document: "document/",
    updateAppContent: 'business-communication/update/app_content',
    deleteAppContent: 'business-communication/delete/bc',
    listUserPermissions: 'list/users/permissions',
    userPermission: 'fetch/user/',
    updatePermission: 'update/',
    updatePassword: 'free/verify-and-update/password',
    deleteOffersAndNotice: 'business-communication/delete/bc/',
    loanProductsList: '/list/',
    listReporters: 'dsa_management/',
    updateemployee: "update/employee",
    LenderstatusChange: "dsa/lender/toggle/lender/",
    generateLeadsReport: "generate/leads/report?",
    generateAttendanceReport: "generate/report?",
    generateReportByReportType: "generate/report?",
    bulkUploadStart: "bulk/upload",
    bulkUploadMap: "bulk/upload/",
    bulkUploadExecute: "bulk/upload/",
    bulkUploadDelete: "bulk/upload/",
    bulkUploadList: "bulk/upload/list",
    downloadFormate: "bulk/upload/downloadFormat",
    attendance: "attendance?source=WEB",
    listAttendance: "get/attendance",
    listAttendanceById: "list/attendance",
    usersByDepartment: 'list/users/by-department',
    getShareLinkId: "shareLink/case/",
    checkRecaptchaResponse: 'free/check/re-captcha-is-required',
};
