import React, { useState } from "react";
import "./PreftScreens.scss";
import FormCheckbox from "components/FormCheckbox/FormCheckbox";
import { Button, Checkbox, Form, notification, Spin } from "antd";
import { useParams } from "react-router-dom";
import preferImg from "../../../../../../assets/images/prefr.svg";
import API_SERVICE from "shared/services/api-service";


const tcMessage = <div>I agree to Prefr’s <a href="https://prefr.com/terms_and_conditions" target="_blank">T&C</a> and <a href="https://prefr.com/privacy_policy" target="_blank">Privacy Policy</a> consent to CKYC and <a href="https://prefr.com/bureau_consent
" target="_blank">authorize</a> Prefr to retrieve my credit report</div>;
const PrefrConsentScreen = (props) => {
  const [formGlobalConsent] = Form.useForm();
  let { uniqueId } = useParams() as any;
  const [globalRequired, setGlobalRequired] = useState(false);
  const callSubmitApi = () => {
    props.setLoading(true);
    API_SERVICE.updateConsentForPrefrLender(uniqueId, {}).then((res) => {
      console.log('res', res);
      if (res?.data?.payload?.eligible) {
        props.setLoading(false);
        window.open(res?.data?.payload?.reason, "_self");
      } 
      if (!res?.data?.payload?.eligible) {
        props.setIsErrorScreen(true);
        props.setIsDisplayOffer(false);
      }
    }).catch((e) => {
      props.setLoading(false);
      notification.error({
        message: API_SERVICE.handleErrors(e),
        style: {
          width: 600,
          maxHeight: "100vh",
          overflow: "auto",
        },
      });
      props.setIsDisplayOffer(true);
    })
  }
  const OfferScreen = () => {
    return (
      <div className="prefrPage">
        <div className="mainSection" style={{ padding: '55px' }}>
          <div className="imgSection">
            <img src={preferImg} />
          </div>
          <div className="offerSection">
            <div>
              <span>Thank you for accepting</span>
            </div>
            <div>
              <span>the Prefr Terms & Conditions.</span>
            </div>
          </div>
          <div className="btnSection">
            <Button className="dynamic-btn-primary" onClick={()=> callSubmitApi()}>View Offer</Button>
          </div>
        </div>
      </div>
    );
  };
  const agreeScreen = () => {
    return (
      <div className="prefrPage">
        <Form form={formGlobalConsent} name="formGlobalConsent" onFinish={props.submitTask}>
        <div className="mainSection">
          <div className="imgSection">
            <img src={preferImg} />
          </div>
          <div className="tncSection">
            <div>
            <FormCheckbox
                  name="globalConsentCheckbox"
                  isRequired
                  //msgRequired={item?.name}
                  textElement={<span>{tcMessage}</span>}
                  onChange={(value) => {
                        setGlobalRequired(value?.target?.checked);
                    }
                  }
                />
            </div>
          </div>
          <div className="btnSection">
            <Button className="dynamic-btn-primary"   type="primary"
            htmlType="submit" disabled={!globalRequired}>I agree</Button>
          </div>
        </div>
        </Form>
      </div>
    );
  };
  const errorScreen = () => {
    return (
      <div className="prefrPage">
        <div className="mainSection" style={{ padding: '55px' }}>
          <div className="imgSection">
            <img src={preferImg} />
          </div>
          <div className="offerSection">
            <div>
              <span>Dear customer, Currently you don't </span>
            </div>
            <div>
              <span>have an offer available at this moment.</span>
            </div>
            <div>
              <span>Please re-try after a month.</span>
            </div>
          </div>
          {/* <div className="btnSection">
            <Button className="btn-close">Close</Button>
          </div> */}
        </div>
      </div>
    );
  }
  return (
    <>
    {props.loading && <div className="loader-view-flowable">
          <Spin
            tip="We are processing your request, please wait"
            size="large"
          ></Spin>
        </div>}
    {props.isErrorScreen && errorScreen()}
    {props.isDisplayOffer ? OfferScreen() : !props.isErrorScreen &&  agreeScreen()}
    </>
  );
};

export default PrefrConsentScreen;
