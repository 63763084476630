import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { notification, Card, Typography, Form, Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./change-password.scss";
import API_SERVICE from "shared/services/api-service";

const { Title, Paragraph } = Typography;

export default function ChangePassword() {
  const history = useHistory();
  const { state } = useLocation() as any;

  if (!state?.data) {
    history.push("/dashboard");
  }

  let userData = localStorage.getItem("user") as any;
  if (userData) {
    userData = JSON.parse(userData);
  }
  const confirmHandler = (values: any) => {
    const payload = {
      mobileNumber: userData.userDetails.phoneNumber,
      password: values.password,
    };
    API_SERVICE.updatePassword(payload)
      .then(({ data }: any) => {
        if (data) {
          notification.success({ message: data.message });
          userData.userDetails.changePasswordNextLogin = false;
          localStorage.setItem("user", JSON.stringify(userData));
          history.push("/dashboard");
          window.location.reload();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  return (
    <div className="login">
      <div className="login-wrapper">
        <Card className={"login-form-wrapper"}>
          <Title level={2}>{"Set Your Password"}</Title>
          <Paragraph>
            Please enter your password to access your account
          </Paragraph>
          <div className="form-wrapper">
            <Form onFinish={confirmHandler} layout="vertical" size="large">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Please enter password"
                  className="custom-input"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The password you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Please enter password"
                  className="custom-input"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="confirm-password-submit"
                  type="primary"
                  htmlType="submit"
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
}
