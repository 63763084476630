import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import iconSearch from "../../../../../assets/images/search.png";
import AppLoading from "shared/components/AppLoading/AppLoading";
import UploadDocument from "shared/components/Lead/UploadDocument/UploadDocument";
import DocCategoryV2 from "./DocCategoryV2";
import {ReactComponent as ZipIcon} from "../../../../../assets/images/zip.svg";
import { setNotificationCount } from "shared/redux/notificationCount-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import imgDocument from "../../../../../assets/images/document_download.svg";
import imgTickRight from "../../../../../assets/images/tickRight.svg";
import imgLoading from "../../../../../assets/images/loading.svg";
import wrong from "../../../../../assets/images/wrong.svg";
import './Document.scss';

interface IDocument {
  setIsLoading: any;
  leadData: any;
  getTaskData: any;
  submitTask: any;
  isCurrentDocument: any;
  setCurrentStage: any;
}

const Document: React.FunctionComponent<IDocument> = (props: IDocument) => {
  const {
    setIsLoading,
    leadData,
    getTaskData,
    submitTask,
    isCurrentDocument,
    setCurrentStage,
  } = props;

  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [documentListCat, setDocumentListCat] = useState([] as any);
  const [filterDocumentList, setFilterDocumentList] = useState([]) as any;
  const [isDocumentAvailable, setIsDocumentAvailable] = useState(false)
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const [bankList, setBankList] = useState([]);
  const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
  const [popupVisible, setPopupVisible] = useState(false);
  const dispatch = useDispatch();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    getDocumentList();
    getDocumentBankData();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getDocumentList = (isLoading = true) => {
    setIsLoading(true);
    API_SERVICE.listDocumentV2(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          const docConfig = data?.payload?.documentConfigDetails
          setDocumentListCat(docConfig);
          setFilterDocumentList(docConfig);
          let tmpIsDocAvailable = false
          docConfig?.map((item: any) => {
            item?.documentTypeWithUploadedDetails.map((data: any) => {
              if (data && data?.documentDetails != null) {
                tmpIsDocAvailable = true
                
              }
            })
          });
          setIsDocumentAvailable(tmpIsDocAvailable)
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const handleSearch = (value: any) => {
    let documentTypeNameFilter = documentListCat?.map((item: any) => {
      const filterData = item?.documentTypeWithUploadedDetails?.filter(
        (data: any) => {
          return data?.documentTypeName
            ?.toLowerCase()
            ?.includes(value?.searchParam?.toLowerCase());
        }
      );
      return { ...item, documentTypeWithUploadedDetails: filterData };
    });
    let getDocumentCategroy = documentTypeNameFilter?.filter((item: any) => {
      return item?.documentTypeWithUploadedDetails?.length > 0;
    });
    setFilterDocumentList(getDocumentCategroy);
  };

  const actionOnDelete = (doc: any) => {
    setIsLoading(true);
    API_SERVICE.deleteDocument(
      doc.documentDetails.businessId,
      doc.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        getDocumentList();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const actionAdd = (doc, index, cat, indexCat) => {
    const docList = cat?.documentTypeWithUploadedDetails ?? [];
    const tmoDocLists = docList.filter(
      (val) => val?.identifier === doc?.identifier
    );

    const docListTmp = [
      ...docList.slice(0, index + 1),
      { ...doc, identifier: `${doc?.identifier}~${tmoDocLists?.length - 1}`, documentDetails: null },
      ...docList.slice(index + 1),
    ];

    const tmp = [...filterDocumentList];
    tmp[indexCat] = { ...cat, documentTypeWithUploadedDetails: docListTmp };
    setFilterDocumentList(tmp);
  };

  const getDisableBtn = () => {
    let isDisable = false;
    documentListCat?.map((document) => {
      if (!isDisable) {
        isDisable = document?.documentTypeWithUploadedDetails?.some(
          (item) => (item?.mandatory && item.documentDetails == null)
        );
      }
    });
    return isDisable;
  };

  const downloadAllDocument = () => {
    const documentSetId = leadData?.caseDetails?.documentSetId;
    notification.success({ message: "Your document Zip download is in process and will take some time to complete. Please check the notification inbox for the link to download once ready" })
    API_SERVICE.downloadAllDocument(documentSetId)
      .then(({ data }) => {
        getUnreadNotificationCnt()
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
  }

  const getUnreadNotificationCnt = () => {
    API_SERVICE.getUnreadNotificationCnt()
      .then(({ data }) => {
        dispatch(setNotificationCount(data?.payload?.unReadCount));
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getDocumentBankData = () => {
    const masterKey = 'BANK_NAME';
    API_SERVICE.getDocumentBankList(masterKey).then(({ data }) => {
      if (data?.payload) {
        const updtedData: any = [];
        data?.payload?.filter((item: any) => {
          updtedData.push({
            label: item.value,
            value: item.id
          });
        });
        setBankList(updtedData);
      }
    }).catch((e) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    })
  }


  const renderUploadDocument = () => {
    return (
      <Modal
        title="Upload New Document:"
        centered
        width={400}
        onCancel={() => setShowUploadDocument(false)}
        footer={null}
        visible={showUploadDocument}
      >
        <UploadDocument
          selectedType={selectedDocument?.documentTypeType ?? ""}
          documentTypeId={selectedDocument?.documentTypeId}
          formatDocument={
            selectedDocument?.appliedFileTypes?.map((v: any) =>
              v.toLowerCase()
            ) ?? ""
          }
          maxSize={selectedDocument?.maximumSizeInMB ?? 10}
          loanProductId={leadData?.loanProductId}
          documentSetId={leadData?.caseDetails?.documentSetId}
          individualType={leadData?.applicationSubType}
          callBack={(isUpload) => {
            setShowUploadDocument(false);
            if (isUpload) {
              getDocumentList();
            }
          }}
        />
      </Modal>
    );
  };
  const customStylesBtnPrimary:any = {'--btnColor': appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--fileIconColor': appColors?.appPrimaryColor ?? ""};
  
  const renderDocumentSearch = () => {
    return(
    <Form
      layout="inline"
      style={{ marginBottom: "10px" }}
      onFinish={handleSearch}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <Form.Item name="searchParam">
            <Input
              size="large"
              style={{ borderRadius: "8px" }}
              prefix={<img src={iconSearch} alt="" width={17} />}
              placeholder="Search Name"
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Button 
             type="primary"
             htmlType="submit"
             size="large" 
             className="dynamic-btn-primary"
             style={customStylesBtnPrimary}
             >
              Search
            </Button>
          </Form.Item>
        </div>
        {isDocumentAvailable && <div className="border-file-icon"> 
          <ZipIcon style={customStyles}/>
          <span onClick={() => { downloadAllDocument() }} style={{cursor:'pointer'}}>Download All</span>
        </div>
        }
      </div>
    </Form>
  )};

  const downloadPopup = () => {
    return (
      <Modal className="model-document-upload" visible={popupVisible} footer={null} onCancel={() => {
        setPopupVisible(false)
        setUploadDocumentList({sucess: [], failed: []});
        }} mask={false}>
        <Row className="header">
          <Col span={24} className="header-title">
            Uploaded {uploadDocumentList.sucess.length} out of {Number(uploadDocumentList.sucess.length) + Number(uploadDocumentList.failed.length)} Files
          </Col>
        </Row>
        {uploadDocumentList?.sucess?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
            {items.status === 'Done' ? (
              <img src={imgTickRight} height={30} width={30} />
            ) : (
              <img className="loading-img" src={imgLoading} height={30} width={30} />
            )}
            </Col>
          </Row>
          );
        })}
        {uploadDocumentList?.failed?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
              <img src={wrong} height={30} width={30} />
            </Col>
          </Row>
          );
        })}
      </Modal>
    );
  };

  return (
    <>
      <div className="document-upload">{downloadPopup()}</div>
      {showUploadDocument && renderUploadDocument()}
      {renderDocumentSearch()}
      <div>
      {!isMobile &&
        <Row style={{ paddingInline: "16px" }}>
          <Col span={7}>{"Name"}</Col>
          <Col span={7}>{"File Name"}</Col>
          <Col span={4} className="h-center">
            {"Format"}
          </Col>
          <Col span={3} className="h-center">
            {"Max Size"}
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            {"Actions"}
          </Col>
        </Row>
      }

        {filterDocumentList?.map((item, indexCat: number) => {
          return (
            <DocCategoryV2
              documentCategoryList={item}
              actionOnDelete={actionOnDelete}
              actionOnUpload={(doc) => {
                setSelectedDocument(doc);
                setShowUploadDocument(true);
              }}
              actionAdd={(doc, index) => {
                actionAdd(doc, index, item, indexCat);
              }}
              loanProductId={leadData?.loanProductId}
              documentSetId={leadData?.caseDetails?.documentSetId}
              individualType={leadData?.applicationSubType}
              getDocumentList={getDocumentList}
              bankList={bankList}
              setPopupVisible={setPopupVisible}
              popupVisible={popupVisible}
              uploadDocumentList={uploadDocumentList}
              setUploadDocumentList={setUploadDocumentList}
              isMobile={isMobile}
            />
          );
        })}
      </div>
      <div className="d-flex gap-10 mt-20 mb-20" style={{position:"sticky", bottom:0}}>
        {isCurrentDocument && (
          <>
            <Button
              size="middle"
              className="pi-30 dynamic-btn-primary"
              style={customStylesBtnPrimary}
              type="primary"
              htmlType="button"
              disabled={getDisableBtn()}
              onClick={() => submitTask({ taskId: getTaskData?.taskId })}
            >
              Continue
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Document;
