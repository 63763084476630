import API_SERVICE from "shared/services/api-service";
import { Button, Modal, notification } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IFlexiPincodeAvailibility {
  showFlexiReason: string;
  setShowFlexiReason: (value: string) => void;
  currLenderId: string;
  currLeadId: string;
  pincode: string;
  message: string;
  showValueForBajaj?: any;
  callBack: () => void;
  setLoading: (value: boolean) => void;
}

const FlexiPincodeAvailibility = (props: IFlexiPincodeAvailibility) => {
  const {
    showFlexiReason,
    setShowFlexiReason,
    currLenderId,
    currLeadId,
    pincode,
    message,
    callBack,
    setLoading,
    showValueForBajaj
  } = props;

  const { appColors }: any = useSelector((state: RootState) => state.common);

  const lenderCaseReject = () => {
    setLoading(true);
    const param = {
      pincode: showFlexiReason == "pincode" ? pincode : null,
      reason: showFlexiReason,
      errorMsg: showValueForBajaj,
    };
    API_SERVICE.lenderCaseReject(currLenderId, currLeadId, param)
      .then(({ data }: any) => callBack())
      .catch((e: any) =>
        notification.error({ message: API_SERVICE.handleErrors(e) })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      centered
      width={450}
      visible={showFlexiReason?.length > 0}
      footer={null}
      closable={false}
    >
      <div
        style={{
          paddingInline: "20px",
          paddingBlock: "15px",
        }}
      >
        <p
          style={{
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "35px",
            marginLeft: "5px",
            fontSize: "medium",
          }}
        >
          {message}
        </p>
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          <Button
            type="primary"
            className="dynamic-btn-primary"
            style={
              {
                "--btnColor": appColors?.appPrimaryColor ?? "",
                width: "155px",
                height: "40px",
                borderRadius: "8px",
              } as any
            }
            onClick={() => {
              if (showFlexiReason == "bankaccount") {
                lenderCaseReject();
              } else {
                setShowFlexiReason("");
              }
            }}
          >
            Yes
          </Button>
          <Button
            type="default"
            className="dynamic-btn-default"
            style={{'--borderColor': appColors?.appSecondaryColor ?? "", width: "155px", height: "40px", borderRadius: "8px" } as any}
            onClick={() => {
              if (showFlexiReason == "bankaccount") {
                setShowFlexiReason("");
              } else {
                lenderCaseReject();
              }
            }}
          >
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default FlexiPincodeAvailibility;
