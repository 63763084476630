import React from "react";

import AddEmployeeForm from 'shared/components/AddEmployeeForm/AddEmployeeForm';

type Props = {};

const AddEmployee: React.FunctionComponent<Props> = () => {
  return (
    <AddEmployeeForm/>
  );
};

export default AddEmployee;