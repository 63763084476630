import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import iconAction from "../../../../../assets/images/action.svg";
import iconApprove from "../../../../../assets/images/apporveIcon.svg";
import { ReactComponent as IconApprove } from "../../../../../assets/images/apporveIcon.svg";
import shareIcon from "../../../../../assets/images/shareIcon.svg";
import iconCopy from "../../../../../assets/images/copy.svg";
import iconError from "../../../../../assets/images/error.svg";
import iconSearch from "../../../../../assets/images/search.png";
import API_SERVICE from "shared/services/api-service";
import DocumentStatus from "shared/components/Lead/RequiredDocuments/DocumentStatus";
import { getEnumValue, numberWithCommas } from "shared/services/Utility";
import "./Login.scss";
import LenderSelection from "./LenderSelection";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface ILogin {
  setIsLoading: any;
  leadData: any;
  updateCase: any;
  getRedirectURLLink: any;
  getCashETriggerSMSLink: any;
  callbackReqDoc: any;
  callbackAdditionalField: any;
  setShowRequirementDocs: any;
  setSelectedLander: any;
  setAdditionalFields: any;
  setCurrLenderId: any;
  setCurrLeadId: any;
  setShowAdditionalFields: any;
  submitTask: any;
  getTaskData: any;
  getLenderAdditonalField: any;
  lenderNextHandler: any;
  loading: any;
  getNextTask: any;
  setCallbackReqDoc: any;
  decisionData: any;
}

const Login: React.FC<ILogin> = (props: ILogin) => {
  const {
    setIsLoading,
    leadData,
    updateCase,
    getRedirectURLLink,
    getCashETriggerSMSLink,
    callbackReqDoc,
    callbackAdditionalField,
    setShowRequirementDocs,
    setSelectedLander,
    setAdditionalFields,
    setCurrLenderId,
    setCurrLeadId,
    setShowAdditionalFields,
    submitTask,
    getTaskData,
    getLenderAdditonalField,
    lenderNextHandler,
    loading,
    getNextTask,
    setCallbackReqDoc,
    decisionData,
  } = props;
  const [totalCount, setTotalCount] = useState(0);
  const [showDocumentStatus, setShowDocumentStatus] = useState(false);
  const [selectedLender, setSelectedLender] = useState({} as any);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [filterListDecision, setFilterListDecision] = useState([] as any);
  const [loginOptionModal, setLoginOptionModal] = useState(false);
  const [updateStatusType, setUpdateStatusType] = useState("");

  const [lenderObj, setLenderObj] = useState({} as any);
  const [lenders, setLenders] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  const commonFontSize = { fontSize: "13px" };
  const [loginSearchForm] = Form.useForm();

  useEffect(() => {
    loginSearchForm.setFieldsValue({
      searchParam: "",
    });
    setPageNo(1);
    setLenders(decisionData);
    setTotalCount(decisionData?.length);
    setSearchValue("");
    setFilterListDecision([]);
  }, [decisionData]);

  useEffect(() => {
    if (Object?.keys(callbackAdditionalField)?.length !== 0) {
      if (
        Object.keys(callbackReqDoc).length == 0 &&
        callbackAdditionalField?.showRequirementDocs != "false"
      ) {
        setShowRequirementDocs(true);
      } else {
        if (
          getTaskData?.taskDefinitionKey == "login_stage_process_login_task"
        ) {
          const payload = {
            taskDefinitionKey: getTaskData?.taskDefinitionKey,
            taskId: getTaskData?.taskId,
          };
          submitTask(payload);
        }
      }
    } else {
      setSelectedLander({});
    }
    setAdditionalFields({});
  }, [callbackAdditionalField]);

  useEffect(() => {
    if (Object.keys(callbackReqDoc).length !== 0) {
      if (getTaskData?.taskDefinitionKey == "login_stage_process_login_task") {
        const payload = {
          taskDefinitionKey: getTaskData?.taskDefinitionKey,
          taskId: getTaskData?.taskId,
        };
        submitTask(payload);
      }
      setCallbackReqDoc({});
    }
  }, [callbackReqDoc]);

  const handlePaginationChange = (current: any, pageSize: any) => {
    const filterData = lenders?.filter((item: any) => {
      return item?.lenderName
        ?.toLowerCase()
        ?.replace(/\s/g, "")
        ?.includes(searchValue?.toLowerCase()?.replace(/\s/g, ""));
    });
    setFilterListDecision(filterData);
    setPageNo(current);
    setPageSize(pageSize);
  };

  const handleSearch = (value: any) => {
    const filterData = lenders?.filter((item: any) => {
      return item?.lenderName
        ?.toLowerCase()
        ?.replace(/\s/g, "")
        ?.includes(value?.searchParam?.toLowerCase()?.replace(/\s/g, ""));
    });
    setFilterListDecision(filterData);
    setSearchValue(value?.searchParam);
    setTotalCount(filterData?.length ?? 0);
    setPageNo(1);
  };

  const flowableResumableLender = ["icici bank", "hdfc"];

  const checkIsFlowableLender = (lenderName: string) => {
    return (
      lenderName?.toLowerCase().includes("kotak") ||
      lenderName?.toLowerCase().includes("icici") ||
      lenderName?.toLowerCase().includes("hdfc") ||
      lenderName?.toLowerCase().includes("flexi") ||
      lenderName?.toLowerCase().includes("kreditbee") ||
      lenderName?.toLowerCase().includes("moneywide") ||
      lenderName?.toLowerCase().includes("casee") ||
      lenderName.toLowerCase().includes("nira finance") ||
      lenderName?.toLowerCase().includes("abcfl") ||
      lenderName?.toLowerCase().includes("abcl") ||
      lenderName?.toLowerCase().includes("credit saison") ||
      lenderName?.toLowerCase().includes("bajaj housing finance") ||
      lenderName?.toLowerCase().includes("prefr") ||
      lenderName?.toLowerCase().includes("incred") ||
      (lenderName?.toLowerCase()?.includes("fibe") &&
        leadData?.loanType == "Buy Now Pay Later")
    );
  };

  const onAction = (lender: any) => {
    if (lender?.submissionType == "API") {
      setLenderObj(lender);
      setLoginOptionModal(true);
    } else {
      handleChangeScreen(lender);
    }
  };

  const handleChangeScreen = (lender: any) => {
    const findLender =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
        (item) => item?.lenderInfoId == lender?.lenderInfoId
      );

    if (!findLender || [""].includes(findLender.status)) {
      updateAssignedCase(lender);
      return;
    }

    let apiIntegratedLender1 =
      findLender?.submissionType?.includes("API") &&
      (findLender.processInstanceId || findLender?.lenderApplicationId) &&
      !findLender?.allDocumentUploaded;

    if (apiIntegratedLender1) {
      setSelectedLander({ ...lender, ...findLender });
      if (checkIsFlowableLender(findLender?.lenderName)) {
        setAdditionalFields([]);
        setCurrLenderId(findLender?.lenderInfoId);
        setCurrLeadId(leadData?.caseDetails?.caseId);
        setShowAdditionalFields(true);
      } else {
        setShowRequirementDocs(true);
      }
      return;
    } else {
      getLenderAdditonalField(findLender);
      return;
    }
  };

  const updateAssignedCase = (lender: any, isOffLine: boolean = false) => {
    const payload = {
      comments: null,
      lenderDetails: [
        {
          branchName: lender?.lenderName,
          hubName: "",
          lenderInfoId: lender?.lenderInfoId,
          lenderName: lender?.lenderName,
          status: "ASSIGNED",
          submissionType: lender?.submissionType,
          isOffline: isOffLine,
        },
      ],
    };
    updateCase(payload, { key: tenantInfo?.businessProductType === "INSURANCE" ? "INSURER_POLICY_SELECTION" : "LENDER_SELECTION" }, !isOffLine);
  };

  const renderDocumentStatus = (lender) => {
    const total = lender?.documentProgressDetails?.totalNumberOfDocs ?? 0;
    const submitted =
      lender?.documentProgressDetails?.documentsSuccessfullySubmitted ?? 0;
    const failed = lender?.documentProgressDetails?.failure ?? 0;
    return (
      <>
        {total > 0 && (submitted > 0 || failed > 0) && (
          <span
            className="checkbox-text small"
            style={{ cursor: "pointer", fontSize: "11px" }}
            onClick={() => {
              setDocumentDetails(
                lender?.documentProgressDetails?.submissionDetails
              );
              setSelectedLender(lender);
              setShowDocumentStatus(true);
            }}
          >
            {`Document Status: ${submitted}/${total}`}
          </span>
        )}
      </>
    );
  };

  const renderLoginUpdateStatus = () => {
    return (
      <Modal
        centered
        width={350}
        footer={null}
        visible={loginOptionModal}
        closable={false}
      >
        <div style={{ display: "grid", gap: "20px", marginTop: "20px" }}>
          <span>Select the Option</span>
          <Radio.Group
            onChange={(e) => setUpdateStatusType(e?.target?.value)}
            name="applicantType"
            className="dynamic-radio"
            style={btnRadioStyles}
          >
            <Space direction="vertical">
              <Radio value={"API"}>{"Send Case via API"}</Radio>
              <Radio value={"OFFLINE"}>
                {"Update Status of an Offline Case"}
              </Radio>
            </Space>
          </Radio.Group>
          <Space style={{ marginBottom: "20px" }}>
            <Button
              size="large"
              className="button-submit dynamic-btn-primary"
              style={customStylesBtnPrimary}
              // loading={loading}
              type="primary"
              onClick={() => {
                setLoginOptionModal(false);
                if (updateStatusType == "API") {
                  handleChangeScreen(lenderObj);
                  if (
                    getTaskData?.productFormMetadata?.stageKey != "loginStage"
                  ) {
                    const payload = {
                      processInstanceId: leadData?.processInstanceId,
                      gotoStep: "loginStage",
                    };
                    getNextTask(payload);
                    // API_SERVICE.productGetNextTask(payload);
                  }
                } else if (updateStatusType == "OFFLINE") {
                  updateAssignedCase(lenderObj, true);
                }
              }}
            >
              Submit
            </Button>
            <Button
              size="large"
              className="button-submit dynamic-btn-default"
              style={btnDefaultBorderStyle}
              htmlType="button"
              onClick={() => setLoginOptionModal(false)}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Modal>
    );
  };

  const renderLenderStatus = (
    lenderDetails: any,
    consentDetails: any,
    statusAlign: string = ""
  ) => (
    <div
      style={{
        color: "#20A74E",
        gap: "10px",
        display: "flex",
        justifyContent: statusAlign,
      }}
    >
      {lenderDetails?.submissionType == "API" && (
        <span
          className="lender-additional-details"
          style={
            { "--appSecondary": appColors?.appSecondaryColor ?? "" } as any
          }
        >
          <IconApprove /> API
        </span>
      )}
      {Object.keys(consentDetails).length > 0 && (
        <span
          className="lender-additional-details"
          style={
            { "--appSecondary": appColors?.appSecondaryColor ?? "" } as any
          }
        >
          <IconApprove /> Consent
        </span>
      )}
    </div>
  );

  const renderCopyiLeansId = (lenderDetails: any) => (
    <div className="checkbox-text-div">
      {lenderDetails?.submissionType?.includes("API") &&
        lenderDetails?.additionalFields?.ilensId?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="lender-additional-details"
              style={{ "--appSecondary": "black" } as any}
            >{`iLens ID: ${
              JSON.parse(lenderDetails?.additionalFields)?.ilensId
            }`}</span>
            <Button
              style={{
                borderColor: "transparent",
                background: "transparent",
                fontSize: "12px",
              }}
              size="small"
              shape="circle"
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.parse(lenderDetails?.additionalFields)?.ilensId ?? ""
                );
                notification.success({ message: "Copied to clipboard" });
              }}
              icon={<img src={iconCopy} alt="" width={10} height={10} />}
            />
          </div>
        )}
    </div>
  );

  const isLenderLogged = (findLender) => {
    const isDocUploaded =
      findLender?.submissionType != "API" ||
      (findLender?.submissionType == "API" &&
        findLender?.allDocumentUploaded) ||
      ["REJECTED", "WITHDRAWN", "PENDENCY"].includes(findLender?.status);
    const isLogged =
      isDocUploaded &&
      ["LOGGED", "SANCTIONED", "DISBURSED"].includes(findLender?.status);
    return isLogged;
  };

  const renderRedirectLink = (lenderDetails: any) => (
    <>
      {lenderDetails?.submissionType?.includes("API") &&
        (lenderDetails?.lenderApplicationId?.length ?? 0) > 0 && (
          <span className="flex" style={{ display: "flex" }}>
            {(["BU9709497975", "BU3620294738"].includes(
              lenderDetails.lenderInfoId
            ) ||
              lenderDetails?.lenderName?.toLowerCase()?.includes("fibe") ||
              lenderDetails?.lenderName?.toLowerCase()?.includes("moneywide") ||
              lenderDetails?.lenderName?.toLowerCase()?.includes("cashe") ||
              lenderDetails?.lenderName?.toLowerCase()?.includes("kreditbee") ||
              lenderDetails?.lenderName?.toLowerCase()?.includes("abcfl") ||
              lenderDetails?.lenderName?.toLowerCase()?.includes("abcl") ||
              ((lenderDetails?.lenderName?.toLowerCase()?.includes("prefr") ||
                lenderDetails?.lenderName?.toLowerCase()?.includes("incred")) &&
                isLenderLogged(lenderDetails)) ||
              lenderDetails?.lenderName
                .toLowerCase()
                .includes("nira finance") ||
              lenderDetails?.lenderName
                ?.toLowerCase()
                ?.includes("credilio")) && (
              <>
                <a
                  onClick={() =>
                    getRedirectURLLink(
                      lenderDetails?.lenderInfoId,
                      leadData?.loanProductId,
                      lenderDetails?.lenderApplicationId
                    )
                  }
                  className="small"
                  style={{ fontSize: "12px" }}
                >
                  {`Lender Link`}
                </a>
                <Button
                  size="small"
                  style={{ margin: "2px", border: "none" }}
                  onClick={() =>
                    getRedirectURLLink(
                      lenderDetails?.lenderInfoId,
                      leadData?.loanProductId,
                      lenderDetails?.lenderApplicationId,
                      true
                    )
                  }
                  icon={
                    <img
                      src={shareIcon}
                      alt=""
                      width={18}
                      height={18}
                      style={{ marginTop: "-4px" }}
                    />
                  }
                />
              </>
            )}
          </span>
        )}
    </>
  );

  const renderLenderNames = (rec: any) => {
    const findLender =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
        (item) => item?.lenderInfoId == rec?.lenderInfoId
      );
    let consentDetails: any = {};
    if ((findLender?.consentDetails?.length ?? 0) > 0) {
      consentDetails = JSON.parse(findLender?.consentDetails ?? {});
    }
    return (
      <div style={{ display: "grid", justifyContent: "left" }}>
        <span style={{ fontSize: "13px", textAlign: "start" }}>
          {rec?.lenderName}
        </span>
        {renderLenderStatus(rec, consentDetails, "left")}
        {(findLender?.lenderApplicationId?.length ?? 0) > 0 && (
          <span
            className="lender-additional-details"
            style={{ "--appSecondary": "black" } as any}
          >
            Lender Loan ID: {findLender?.lenderApplicationId}
          </span>
        )}
        {(findLender?.additionalFields?.length ?? 0) > 0 &&
          findLender?.lenderName?.toLowerCase()?.includes("incred") && (
            <span
              className="lender-additional-details"
              style={{ "--appSecondary": "black" } as any}
            >
              Customer ID: {JSON.parse(findLender?.additionalFields).customerId}
            </span>
          )}
        {renderCopyiLeansId(findLender)}
        {renderDocumentStatus(findLender)}
        {renderRedirectLink(findLender)}
      </div>
    );
  };

  const colAction = (_: any, record: any) => {
    const findLender =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
        (item) => item?.lenderInfoId == record?.lenderInfoId
      );

    const isDocUploaded =
      findLender?.submissionType != "API" ||
      (findLender?.submissionType == "API" &&
        findLender?.allDocumentUploaded) ||
      ["REJECTED", "WITHDRAWN", "PENDENCY"].includes(findLender?.status);
    const isLogged =
      [
        "SANCTIONED",
        "DISBURSED",
        "PENDENCY",
        "REJECTED",
        "WITHDRAWN",
        "POLICY_ISSUED",
        "UNDERWRITING_REJECT",
        "COUNTER_OFFER",
      ].includes(findLender?.status) ||
      (isDocUploaded &&
        [
          "LOGGED",
          "SANCTIONED",
          "DISBURSED",
          "PENDENCY",
          "REJECTED",
          "WITHDRAWN",
          "POLICY_ISSUED",
          "UNDERWRITING_REJECT",
          "COUNTER_OFFER",
        ].includes(findLender?.status));

    return (
      <>
        {isLogged ? (
          <>
            <img src={iconApprove} width={20} />
            <br />
            <span style={{ fontSize: "11px" }}>LOGGED</span>
          </>
        ) : (
          <Button
            style={{ background: "transparent", border: "none" }}
            type="text"
            size="small"
            onClick={() => onAction(record)}
            icon={
              <img
                src={iconAction}
                alt=""
                width={24}
                height={24}
                style={{ marginTop: "-4px" }}
              />
            }
          />
        )}
      </>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      align: "left",
      width: 0,
      sorter: (a: any, b: any) => a.lenderName.localeCompare(b.lenderName),
      render: (_: any, rec: any) => renderLenderNames(rec),
      // onCell: (value: any) => {
      //   return { rowSpan: value?.rowSpan ?? 1 };
      // },
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      render: (value: any, rec: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={commonFontSize}>
              {getEnumValue("DECISIONING_PURPOSE", value ?? "")}
            </span>
            {(rec?.errorWhileCalculatingOffer?.length ?? 0) > 0 && (
              <Tooltip title={rec?.errorWhileCalculatingOffer}>
                <img src={iconError} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "Max Loan Amount",
      dataIndex: "maxLoanAmount",
      sorter: (a: any, b: any) => a.maxLoanAmount - b.maxLoanAmount,
      render: (rec: any) => {
        if (rec == null) {
          return <></>;
        }
        return <span style={commonFontSize}>Rs. {numberWithCommas(rec)}</span>;
      },
    },
    {
      title: "Total EMI",
      dataIndex: "totalEmi",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "Tenure (in years)",
      dataIndex: "tenureInMonths",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "ROI %",
      dataIndex: "roi",
      render: (rec: any) => <span style={commonFontSize}>{rec}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: colAction,
    },
  ];

  const columnsInsurance: ColumnsType<any> = [
    {
      title: "Insurer Name",
      dataIndex: "lenderName",
      align: "left",
      sorter: (a: any, b: any) => a.lenderName.localeCompare(b.lenderName),
      render: (_: any, rec: any) => renderLenderNames(rec),
    },
    {
      title: "Policy Number",
      dataIndex: "policyNumber",
      render: (_: any, rec: any) => {
        const findLender =
          leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
            (item) => item?.lenderInfoId == rec?.lenderInfoId
          );
        const plans = findLender?.insuranceDetails?.insuranceDetails?.map(
          (insure: any) => insure?.policyNumber
        );
        const plan = plans?.filter((plan: any) => plan)?.join(", ");
        return <span style={commonFontSize}>{plan}</span>;
      },
    },
    {
      title: "Proposal Application Number",
      dataIndex: "proposalApplicationNumber",
      render: (_: any, rec: any) => {
        const findLender =
          leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
            (item) => item?.lenderInfoId == rec?.lenderInfoId
          );
        const plans = findLender?.insuranceDetails?.insuranceDetails
          ?.map((insure: any) => insure?.proposalApplicationNumber)
        const plan = plans?.filter((plan: any) => plan)?.join(", ");
        return <span style={commonFontSize}>{plan}</span>;
      },
    },
    {
      title: "Product Plan",
      dataIndex: "productPlan",
      render: (_: any, rec: any) => {
        const findLender =
          leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
            (item) => item?.lenderInfoId == rec?.lenderInfoId
          );
        const plan = findLender?.insuranceDetails?.insuranceDetails
          ?.map((insure: any) => insure?.productPlanName)
          ?.join(", ");
        return <span style={commonFontSize}>{plan}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: colAction,
    },
  ];

  const customStylesBtnPrimary: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };
  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };
  const btnRadioStyles: any = {
    "--borderColor": appColors?.appPrimaryColor ?? "",
  };
  const btnDefaultBorderStyle: any = {
    "--borderColor": appColors?.appSecondaryColor ?? "",
  };

  return (
    <>
      {showDocumentStatus && (
        <DocumentStatus
          showDocumentStatus={showDocumentStatus}
          setShowDocumentStatus={setShowDocumentStatus}
          lenderInfoId={selectedLender?.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          documentDetails={documentDetails}
        />
      )}

      <Form
        form={loginSearchForm}
        layout="inline"
        style={{ padding: "10px" }}
        onFinish={handleSearch}
      >
        <Form.Item name="searchParam" label={tenantInfo?.businessProductType === "INSURANCE" ? "Insurer Selection" : "Decisioning/Lender Selection"}>
          <Input
            size="large"
            style={{ borderRadius: "8px" }}
            prefix={<img src={iconSearch} alt="" width={17} />}
            placeholder={"Search " + (tenantInfo?.businessProductType === "INSURANCE" ? "Insurer" : "Lender")}
            allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="dynamic-btn-primary"
            style={customStylesBtnPrimary}
          >
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={
          tenantInfo?.businessProductType === "INSURANCE"
            ? columnsInsurance
            : columns
        }
        className="table-bg-transparent dynamic-pagination-checkbox"
        style={customStyles}
        dataSource={
          (filterListDecision?.length ?? 0) > 0 ? filterListDecision : lenders
        }
        size="small"
        onChange={(pagination: any) => {
          handlePaginationChange(pagination.current, pagination.pageSize);
        }}
        pagination={{
          current: pageNo,
          pageSize: pageSize,
          total: totalCount,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        bordered
      />
      {loginOptionModal && renderLoginUpdateStatus()}
      {/* {showGlobalLender && (
        <LenderSelection
          leadData={leadData}
          onAction={(lender: any) => onAction(lender)}
          lenderNextHandler={lenderNextHandler}
          listDecisionData={listDecisioningData}
          setIsLoading={setIsLoading}
          loading={loading}
          renderLenderStatus={renderLenderStatus}
          renderDocumentStatus={renderDocumentStatus}
          renderCopyiLeansId={renderCopyiLeansId}
          renderRedirectLink={renderRedirectLink}
        />
      )} */}
    </>
  );
};

export default Login;
