import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
import AppLoading from "shared/components/AppLoading/AppLoading";

const { Option } = Select;
const { Title } = Typography;

type Props = {};

const AddInsurer: React.FunctionComponent<Props> = () => {
  const history = useHistory();

  const [fiList, setFiList] = useState([] as any);
  const [pIDetailList, setPIDetailList] = useState([] as any);
  const [hubList, setHubList] = useState([] as any);
  const [products, setProducts] = useState([] as any);
  const [productPLans, setProductPlans] = useState([] as any);
  const [routingMode, setRoutingMode] = useState("API");
  const [selectedLenderId, setSelectedLenderId] = useState(undefined);
  const [selectedLoanProductCode, setSelectedLoanProductCode] =
    useState<any>(undefined);
  const [addedProductPlans, setAddedProductsPlans] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [lenderMappingId, setLenderMappingId] = useState("");
  const [consentType, setConsentType] = useState("GLOBAL") as any;
  const [addLenderForm] = Form.useForm() as any;
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Insurer",
        backScreenPath: "/insurer",
        screenTitle: "Add New Insurer",
      })
    );
    getFiList();
  }, []);

  const getFiList = () => {
    API_SERVICE.getFiList({ pageSize: 500, financierCategory:"INSURER" })
      .then(({ data }) => {
        setFiList(data?.payload?.content ?? []);
      })
      .finally(() => setLoading(false));
  };

  const getProductPlanList = (loanProductCode) => {
    setLoading(true);

    const selectedBank = fiList.find(
      (item: any) => item.financierName === addLenderForm.getFieldValue("name")
    );
    
    API_SERVICE.getProductPlanList({
      pageSize: 100,
      productType: loanProductCode.loanProductId,
      provider: selectedBank.fiId,
      productPlanStatus: "ACTIVE"
    })
      .then(({ data }) => setProductPlans(data?.payload?.content ?? []))
      .finally(() => setLoading(false));
  };

  const getPartnerIdentificationDetails = (lenderId, loanProductCode) => {
    API_SERVICE.getPartnerIdentificationDetails(
      lenderId,
      loanProductCode.loanProductId
    ).then(({ data }) => {
      setPIDetailList(data?.payload?.partnerIdentificationDetails ?? []);
    });
  };

  const addLender = (values: any) => {
    // if (routingMode == "API") {
    //   updatePartnerIdentificationDetails(values);
    // } else {
      addLenderHandler(values);
    // }
  };

  const addLenderHandler = (values: any) => {
    if ((addedProductPlans?.length ?? 0) > 0) {
      updateLender(values);
      return;
    }

    const selectedBank = fiList.find(
      (item: any) => item.financierName === values.name
    );
    const payload = {
      active: values.status,
      consentType: consentType === "Noconsent" ? null : consentType,
      consentRequired: consentType === "Noconsent" ? false : true,
      finInfoId: selectedBank.fiId,
      hub: values.hub,
      name: values.name,
      product: selectedLoanProductCode.loanProductName,
      productId: selectedLoanProductCode.loanProductId,
      pinCode: values.pinCode,
      city: values.city,
      productPlanDetails: [
        {
          productPlanId: values.productPlan,
          submissionType: routingMode,
          status: values.status ? "ACTIVE" : "INACTIVE",
        },
      ],
    };
    API_SERVICE.addInsurance(payload)
      .then(({ data }) => {
        notification.success({
          message: data?.message ?? "Insurer created successfully",
        });
        setLenderMappingId(data?.payload?.lenderMappingId);
        const plan = productPLans?.find(
          (item) => item.productPlanId == values.productPlan
        );
        const tmpList = [...addedProductPlans];
        tmpList.push({
          productPlanName: plan?.productPlanName,
          submissionType: routingMode,
          status: values.status ? "ACTIVE" : "INACTIVE"
        });
        setAddedProductsPlans(tmpList);
        addLenderForm.resetFields();
        addLenderForm.setFieldsValue({
          name: values.name,
          product: selectedLoanProductCode.loanProductId,
        });
        setRoutingMode("API");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updateLender = (values: any) => {
    const payload = {
      status: values.status ? "ACTIVE" : "INACTIVE",
      submissionType: values.routingMode,
      productPlanId: values.productPlan,
    };

    API_SERVICE.updateProductPlan(lenderMappingId, payload)
      .then(({ data }) => {
        if (data) {
          if (data.message) {
            notification.success({ message: data.message });
          }
          setAddedProductsPlans(data.payload.productPlanDetails);
          addLenderForm.resetFields();
          addLenderForm.setFieldsValue({
            name: values.name,
            product: selectedLoanProductCode.loanProductId,
          });
          setRoutingMode("API");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updatePartnerIdentificationDetails = (values) => {
    const keys = Object.keys(values);
    const filterDataObject = [] as any;

    pIDetailList?.map((data) => {
      let filterData = keys.filter((item) => item === data?.key);
      filterDataObject.push({
        key: filterData[0],
        value: values[filterData[0]],
      });
    });

    const params = {
      lenderId: selectedLenderId,
      productIds: [selectedLoanProductCode.loanProductId],
      partnerIdentificationKeyDetail: {
        partnerIdentificationDetails: filterDataObject,
      },
    };

    API_SERVICE.updatePartnerIdentificationDetails(selectedLenderId, params)
      .then(({ data }) => addLenderHandler(values))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const changeBankHandler = (fiItem: any) => {
    setHubList(fiItem.hubDetails);
    setProducts(fiItem?.lenderLoanDefinition?.allLoanTypes);
    setSelectedLenderId(fiItem.fiId);
    setPIDetailList([]);
    setSelectedLoanProductCode(undefined);
    addLenderForm.setFieldsValue({
      product: null,
      productPlan: null,
    });
  };

  const changeProductHandler = (value: any) => {
    const productFiltered = products?.filter(
      (item) => item.loanProductId == value
    );
    if ((productFiltered?.length ?? 0) > 0) {
      setSelectedLoanProductCode(productFiltered[0]);
      if (selectedLenderId) {
        getPartnerIdentificationDetails(selectedLenderId, productFiltered[0]);
      }
      getProductPlanList(productFiltered[0]);
    }
    addLenderForm.setFieldsValue({
      productPlan: null,
    });
  };

  const changeHubHandler = (value: any) => {
    let selectedHub = hubList.find((item) => item.hubName == value);
    addLenderForm.setFieldsValue({
      pinCode: selectedHub?.pinCode,
    });
  };

  const columns = [
    {
      title: "Product Plan",
      dataIndex: "productPlanName",
      key: "productPlanName",
      render: (text) => <span style={{ color: "blue" }}>{text}</span>,
    },
    {
      title: "Routing Mode",
      dataIndex: "submissionType",
      key: "submissionType",
    },
    {
      title: "Product Plan Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const btnRoundRadioStyles: any = {
    "--borderColor": appColors?.appPrimaryColor ?? "",
  };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };
  const btnDefaultBorderStyle: any = {
    "--borderColor": appColors?.appSecondaryColor ?? "",
  };

  const renderOther = () => {
    return (
      <>
        <Form.Item
          className={`field-bg-border ${
            (addedProductPlans?.length ?? 0) > 0 ? "bg-disabled" : "bg-white"
          } `}
          name="product"
          label="Product"
          rules={[{ required: true, message: "Please enter product name" }]}
        >
          <Select
            showSearch
            className="custom-select"
            placeholder="Product"
            optionFilterProp="children"
            onChange={(value, option) => changeProductHandler(value)}
            disabled={(addedProductPlans?.length ?? 0) > 0}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(input?.toLowerCase())
            }
          >
            {products?.map((product: any, i: number) => (
              <Option key={i} value={product.loanProductId}>
                {product.loanProductName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="field-bg-border bg-white"
          name="productPlan"
          label="Product Plan"
          rules={[{ required: true, message: "Please enter product name" }]}
        >
          <Select
            showSearch
            className="custom-select"
            placeholder="Product Plan"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(input?.toLowerCase())
            }
          >
            {productPLans?.map((product: any, i: number) => (
              <Option key={i} value={product.productPlanId}>
                {product.productPlanName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="routingMode"
          style={{ marginTop: "5px" }}
          label="Select Case Routing Mode"
        >
          <Radio.Group
            defaultValue={"API"}
            className="dynamic-radio"
            style={btnRoundRadioStyles}
            onChange={(e) => {
              setRoutingMode(e?.target?.value);
              setConsentType(
                e?.target?.value === "API" ? "GLOBAL" : "Noconsent"
              );
            }}
            name="routingMode"
          >
            <Radio value={"API"} checked>
              API
            </Radio>
            <Radio value={"LOGIN"}>Login</Radio>
          </Radio.Group>
        </Form.Item>

        {routingMode == "API" ? (
          <>
            {pIDetailList?.map((item) => {
              return (
                <Form.Item
                  className="field-bg-border bg-white"
                  name={item.key}
                  label={item.key}
                  rules={[
                    { required: true, message: `Please enter ${item.key}` },
                  ]}
                >
                  <Input
                    placeholder={`Enter ${item.key}`}
                    className="custom-input"
                  />
                </Form.Item>
              );
            })}
          </>
        ) : (
          <>
            {/* <Form.Item
              className="field-bg-border"
              label="Hub"
              rules={[{ required: true, message: "Please enter hub" }]}
              name="hub"
            >
              <Select
                showSearch
                className="custom-select"
                placeholder="Hub"
                optionFilterProp="children"
                disabled={isViewMode}
                onChange={(value, option) => changeHubHandler(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {hubList?.map((item: any, i: number) => (
                  <Option key={i} value={item.hubName}>
                    {item.hubName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="field-bg-border"
              name="pinCode"
              label="Pin Code"
            >
              <Input
                className="custom-input"
                placeholder="Please enter pincode"
                disabled={isViewMode}
              />
            </Form.Item>
            <Form.Item
              className="field-bg-border"
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter city" }]}
            >
              <Input placeholder="Please enter city" className="custom-input" disabled={isViewMode} />
            </Form.Item> */}
          </>
        )}

        {/* <Radio.Group
          value={consentType}
          className="dynamic-radio"
          style={btnRoundRadioStyles}
          onChange={(e) => setConsentType(e?.target?.value)}
          name="consentType"
          disabled={isViewMode}
        >
          <Radio value={"Noconsent"}>No Consent</Radio>
          <Radio value={"GLOBAL"}>Global</Radio>
          <Radio value={"INDIVIDUAL"}>Individual</Radio>
        </Radio.Group> */}

        <Form.Item
          initialValue={true}
          name="status"
          label="Product Plan Status"
          style={{ color: "green" }}
        >
          <Switch
            defaultChecked
            style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
          />
        </Form.Item>

        <Form.Item style={{ marginTop: "50px" }}>
          <Space className="buttons">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="dynamic-btn-primary"
              style={btnPrimaryStyles}
            >
              Add
            </Button>
            <Button
              className="dynamic-btn-default"
              style={btnDefaultBorderStyle}
              size="large"
              htmlType="button"
              onClick={() => addLenderForm.resetFields()}
            >
              Clear
            </Button>
            <Button
              className="dynamic-btn-default"
              style={btnDefaultBorderStyle}
              size="large"
              htmlType="button"
              onClick={() => history.goBack()}
            >
              Finish
            </Button>
          </Space>
        </Form.Item>
      </>
    );
  };

  return (
    <div className="content-box">
      <Row gutter={[64, 16]}>
        <Col span={10}>
          <Form
            size="large"
            layout="vertical"
            form={addLenderForm}
            autoComplete="off"
            onFinish={addLender}
            style={{ maxWidth: "500px" }}
          >
            <Form.Item
              className={`field-bg-border ${
                (addedProductPlans?.length ?? 0) > 0
                  ? "bg-disabled"
                  : "bg-white"
              } `}
              name="name"
              label="Insurer Name"
              rules={[{ required: true, message: "Please enter Insurer name" }]}
            >
              <Select
                showSearch
                loading={loading}
                className="custom-select"
                placeholder="Insurer Name"
                optionFilterProp="children"
                disabled={(addedProductPlans?.length ?? 0) > 0}
                onChange={(value, option) => {
                  let fiItem = fiList.find(
                    (item) => item.financierName == value
                  );
                  changeBankHandler(fiItem);
                }}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              >
                {fiList
                  ?.sort(function (a: any, b: any) {
                    if (
                      a.financierName.toLowerCase() <
                      b.financierName.toLowerCase()
                    )
                      return -1;
                    if (
                      a.financierName.toLowerCase() >
                      b.financierName.toLowerCase()
                    )
                      return 1;
                    return 0;
                  })
                  ?.map((item: any, i: number) => (
                    <Option key={i} value={item.financierName}>
                      {item.financierName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {renderOther()}
          </Form>
        </Col>

        <Col span={14}>
          <Card className="Added-Product-List">
            <Title level={3}>Added Product Plans</Title>

            <Table
              scroll={{ y: 300 }}
              bordered={false}
              pagination={false}
              dataSource={addedProductPlans}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>
      {loading && <AppLoading />}
    </div>
  );
};

export default AddInsurer;
