import { Button, Divider, Modal, notification, Space } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import API_SERVICE from "shared/services/api-service";
import "./viewDocument.scss";

interface IViewDocument {
    showPopDocument: boolean;
    setIsShowPopDocument: (value: boolean) => void;
    businessDocumentId: string;
}

const ViewDocument: React.FC<IViewDocument> = (props: IViewDocument) => {

    const { setIsShowPopDocument, showPopDocument, businessDocumentId } = props;
    const [documentData, setDocumentData] = useState(null as any);
    const [docType, setDocType] = useState(null as any);
    const [isPDF, setIsPDF] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { appColors }: any = useSelector((state: RootState) => state.common);
    const customStylesBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "85px", height: "35px", float: "right" };

    useEffect(() => {
        showDocumentImage();
    }, [])

    const showDocumentImage = () => {
        if (businessDocumentId) {
            setIsLoading(true)
            API_SERVICE.downloadDocument("", businessDocumentId).then(({ data }) => {
                if (data?.payload?.fileExtn === "pdf") {
                    setDocumentData(data?.payload?.documentDetails);
                    setDocType("application/pdf");
                    setIsPDF(true);
                } else {
                    setDocumentData(data?.payload?.documentDetails);
                    setDocType(data?.payload?.fileExtn);
                }
                setIsShowPopDocument(true);

            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            }).finally(() => setIsLoading(false))
        }
    };

    return (
        <Modal
            width={isPDF ? 800 : 600}
            visible={showPopDocument}
            style={{ marginBottom: 200 }}
            okText="Yes"
            cancelText="No"
            title=""
            centered
            closable={false}
            maskClosable={false}
            footer={false}
        >
            <Space direction="vertical" style={{ padding: "10px", width: "100%" }}>
                <div className="cardmain">
                    <div className="cardinner">
                        {documentData ? (
                            isPDF ? (
                                <iframe src={"data:" + docType + ";base64," + documentData} className="iframe-pdf-view"></iframe>
                            ) : (
                                <img src={"data:" + docType + ";base64," + documentData} alt="icons" className="img-address-view" />
                            )
                        ) : null}
                        <Divider style={{ marginTop: "10px" }} />
                    </div>
                </div>
                <Button
                    type="primary"
                    size="large"
                    className="dynamic-btn-primary"
                    style={customStylesBtnPrimary}
                    onClick={() => setIsShowPopDocument(false)}
                >Close</Button>
            </Space>
        </Modal>
    )
};

export default ViewDocument;