import { Button, Modal, Table, Tooltip, notification } from "antd";
import actionIcon from "../../../../../assets/images/bluePlay.svg";
import wrong from "../../../../../assets/images/wrong.svg";
import apporved from "../../../../../assets/images/emptyApproved.svg";
import blueApporved from "../../../../../assets/images/emptyBlueApporved.svg";
import { ReactComponent as IconReload } from "../../../../../assets/images/reload.svg";
import { ReactComponent as IconAction } from "../../../../../assets/images/bluePlay.svg";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import { ReloadOutlined } from "@ant-design/icons";
import BankStatement from "../BankStatement";
import { getEnumValue } from "shared/services/Utility";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IGetBureauNew {
  leadData: any;
  vasData: any;
  setShowApplicantInfo: any;
  setIsGetBureauStatus: any;
  isGetBureauStatus: any;
  showVasContinue: any;
  setShowVasContinue: any;
  currentStage: any;
  currentSteps: any;
  getTaskData: any;
  submitTask: any;
  setIsLoading: any;
}

const GetBureauNew: React.FC<IGetBureauNew> = (props: IGetBureauNew) => {
  const {
    setIsLoading,
    leadData,
    vasData,
    setShowApplicantInfo,
    showVasContinue,
    setShowVasContinue,
    setIsGetBureauStatus,
    isGetBureauStatus,
    currentStage,
    currentSteps,
    getTaskData,
    submitTask,
  } = props;
  const [isContinueDisable, setIsContinueDisable] = useState(false);
  const [showVasSkip, setShowVasSkip] = useState(false);
  const [vasStatusData, setVasStatusData] = useState([] as any);
  const [isFinBox, setIsFinBox] = useState(false);
  const [VasActionModal, setVasActionModal] = useState(false);
  const [newTaskId, setNewTaskId] = useState("");
  const [showMsgVas, setShowMsgVas] = useState("");
  const { appColors }: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if (isGetBureauStatus) {
      getVASActionStatus();
    }
    setIsGetBureauStatus(false);
  }, [isGetBureauStatus]);

  const getVASActionStatus = () => {
    setIsLoading(true);
    API_SERVICE.getVASActionStatus(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        const tmpData = vasData?.map((item) => {
          let statusMap = data?.payload?.vasTypeAndStatusMap;
          return {
            vas: item?.id,
            status: statusMap[`${item?.id}`]?.status ?? statusMap[item?.id],
            color: item?.id == 'GET_BUREAU' ? statusMap?.GET_BUREAU?.color : null,
            canSkip: item?.canSkip,
          };
        });
        onContinueDisable(tmpData);
        setVasStatusData(tmpData);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onContinueDisable = (data: any) => {
    const findBureau = data?.find((item: any) => item?.vas == "GET_BUREAU");
    const findBankStatement = data?.find(
      (item: any) => item?.vas == "BANK_STATEMENT_ANALYSIS"
    );
    let enableContinueForBureau: any = null;
    if ((findBureau?.status?.length ?? 0) > 0) {
      if (
        findBureau?.status === "Completed" ||
        findBureau?.status === "Initiated" ||
        ((findBureau?.status === "Expired" ||
          findBureau?.status?.replace(/ /g, "") === "NotInitiated" ||
          findBureau?.status?.replace(/ /g, "") == "PanNotFound") &&
          findBureau?.canSkip)
      ) {
        enableContinueForBureau = true;
      } else {
        enableContinueForBureau = false;
      }
    }

    let enableContinueBankStatement: any = null;
    if ((findBankStatement?.status?.length ?? 0) > 0) {
      if (
        findBankStatement?.status === "Completed" ||
        (findBankStatement?.status === "Incompleted" &&
          findBankStatement?.canSkip)
      ) {
        enableContinueBankStatement = true;
      } else {
        enableContinueBankStatement = false;
      }
    }

    let finalContinueStatus = true;
    if ((findBureau?.status?.length ?? 0) > 0) {
      finalContinueStatus = finalContinueStatus && enableContinueForBureau;
    }
    if ((findBankStatement?.status?.length ?? 0) > 0) {
      finalContinueStatus = finalContinueStatus && enableContinueBankStatement;
    }
    setIsContinueDisable(finalContinueStatus);
  };

  const getShowFinboxStatus = () => {
    setIsLoading(true);
    API_SERVICE.getShowFinboxStatus()
      .then(({ data }) => {
        if (data?.payload) {
          setIsFinBox(true);
        } else {
          getAnalysisCheck();
        }
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }).finally(() => {
        setIsLoading(false);
      })
  };

  const getAnalysisCheck = () => {
    setIsLoading(true);
    API_SERVICE.getAnalysisCheck(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if ((data?.payload?.disclaimerMessage?.length ?? 0) > 0) {
          setVasActionModal(true);
          setShowMsgVas(data?.payload?.disclaimerMessage);
        }
        getVASActionStatus();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const getNextTask = (dataVas: any) => {
    setIsLoading(true);
    const payload = {
      processInstanceId: leadData?.processInstanceId,
      gotoStep: (currentSteps?.length ?? 0) > 0 ? currentSteps : currentStage,
      taskCategory: "VAS",
    };
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        if (data?.payload?.taskDefinitionKey == "vas_user_task") {
          setNewTaskId(data?.payload?.taskId);
          setShowVasContinue(true);
          if (dataVas?.vas == "GET_BUREAU") {
            setIsLoading(false);
            setShowApplicantInfo(true);
          } else {
            getShowFinboxStatus();
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const onAction = (data: any) => {
    if (!showVasContinue) {
      getNextTask(data);
    } else {
      setNewTaskId("");
      if (data?.vas == "GET_BUREAU") {
        setShowApplicantInfo(true);
      } else {
        getShowFinboxStatus();
      }
    }
  };

  const btnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };
  const modalBtnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "140px" }
  const VasBtnDefaultBorderStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "", marginRight: "15px" };
  const vasBtnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "", marginLeft: "15px" };


  const columns: ColumnsType<any> = [
    {
      title: "VAS Services",
      dataIndex: "vas",
      key: "vas",
      render: (rec: any) => (
        <span>{getEnumValue("MASTER_PRODUCT_VAS_SERVICES", rec)}</span>
      ),
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ fontSize: "smaller" }}>Current Status</span>{" "}
          <Tooltip title="Refresh">
            <Button
              className="custome-btn-icon"
              style={{ '--bgColor': appColors?.appPrimaryColor ?? "", '--width': "16px" } as any}
              onClick={() => getVASActionStatus()}
              icon={<IconReload />}
              size={"small"}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (rec: any, _: any) => {
        let statusColor: any = undefined;
        if (_?.color) {
          statusColor = _?.color == "GREEN" ? "#20A74E" : _?.color == "RED" ? "#E12121" : undefined
        }
        return _?.rec == "Expired" || rec == "Incompleted" ? (
          <span style={{ color: statusColor ?? "#E12121" }}>
            <img src={wrong} alt="" width={20} /> {rec}
          </span>
        ) : rec == "Completed" ? (
          <span style={{ color: statusColor ?? "#20A74E" }}>
            <img src={apporved} alt="" width={20} /> {rec}
          </span>
        ) : rec == "Initiated" ? (
          <span style={{ color: statusColor ?? "black" }}>
            <img src={blueApporved} alt="" width={20} /> {rec}
          </span>
        ) : (
          <span style={{ color: statusColor ?? "#E12121" }}>{rec ?? "--"}</span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      render: (_: any, rec: any) => {
        const actionDisable =
          (currentSteps?.length ?? 0) > 0
            ? currentSteps == getTaskData?.productFormMetadata?.stepKey &&
            getTaskData?.taskDefinitionKey != "vas_user_task"
            : currentStage == getTaskData?.productFormMetadata?.stageKey &&
            getTaskData?.taskDefinitionKey != "vas_user_task";
        // ||          (rec?.vas == "GET_BUREAU" && rec?.status == "Expired");
        return (
          <Button
            className="custome-btn-icon"
            style={{ '--bgColor': appColors?.appPrimaryColor ?? "", '--width': "16px" } as any}
            icon={<IconAction />}
            type="text"
            // style={{ border: "none" }}
            disabled={
              actionDisable ||
              (rec?.vas == "GET_BUREAU" && rec?.status == "Completed")
            }
            onClick={() => onAction(rec)}
          />
        );
      },
    },
  ];

  const renderVASActionModal = () => {
    return (
      <Modal
        title=""
        centered
        width={400}
        closable={false}
        footer={null}
        visible={VasActionModal}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ textAlign: "center" }}>{showMsgVas}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              className="dynamic-btn-primary"
              style={modalBtnPrimaryStyles}
              size="large"
              type="primary"
              onClick={() => setVasActionModal(false)}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderBankStatement = () => (
    <BankStatement
      setIsFinBox={setIsFinBox}
      leadData={leadData}
      isFinBox={isFinBox}
      getVASActionStatus={getVASActionStatus}
      setIsLoading={setIsLoading}
    />
  );

  const renderModalVasSkip = () => (
    <Modal
      centered
      title=""
      style={{ marginBottom: "100px" }}
      width={350}
      footer={null}
      closable={false}
      visible={showVasSkip}
    >
      <div style={{ padding: "20px" }}>
        <p
          style={{
            textAlign: "center",
            fontSize: "18px",
            marginTop: "15px",
          }}
        >
          Are you sure you want to skip VAS Services?
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            className="modal-btn dynamic-btn-default"
            style={VasBtnDefaultBorderStyle}
            size="large"
            onClick={() => setShowVasSkip(false)}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            className="modal-btn dynamic-btn-primary"
            style={vasBtnPrimaryStyles}
            onClick={() => {
              submitTask({
                taskId:
                  (newTaskId?.length ?? 0) > 0
                    ? newTaskId
                    : getTaskData?.taskId,
              });
              setShowVasSkip(false);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Table
          columns={columns}
          dataSource={vasStatusData}
          size="small"
          pagination={false}
          scroll={{ x: "max-content" }}
          className="table-bg-transparent"
        />
        <div style={{ padding: "10px" }}>
          {showVasContinue && (
            <Button
              className="dynamic-btn-primary"
              style={btnPrimaryStyles}
              type="primary"
              size="large"
              disabled={!isContinueDisable}
              onClick={() => {
                if (
                  vasStatusData?.some(
                    (item: any) => item?.status !== "Completed"
                  )
                ) {
                  setShowVasSkip(true);
                } else {
                  submitTask({
                    taskId:
                      (newTaskId?.length ?? 0) > 0
                        ? newTaskId
                        : getTaskData?.taskId,
                  });
                  setShowVasSkip(false);
                }
              }}
            >
              Continue
            </Button>
          )}
        </div>
      </div>
      {isFinBox && renderBankStatement()}
      {showVasSkip && renderModalVasSkip()}
      {VasActionModal && renderVASActionModal()}
    </>
  );
};

export default GetBureauNew;
