/* eslint-disable import/no-anonymous-default-export */

export const API_BASE_URL = process.env.REACT_APP_COMPELEMENTRY_API_URL; // BU7132242844 //For Dev/UAT
// const API_BASE_URL = "https://preprod.oneflo.in:"; // BU6098926543 //For PreProd
//  const API_BASE_URL = "https://oneflo.in:"; // BU6098926543 // For Prod

// for the development environment
// const DSA_API_URL = "https://dev-distribution.oneflotest.in/api/dsa/";
// const COMMON_API_URL = "https://dev-common.oneflotest.in/api/";
// const LENDER_ECO_SYSTEM_API_URL = "https://dev-lender.oneflotest.in/api/";

// for the uat environment
// const DSA_API_URL = "https://uat-distribution.oneflotest.in/api/dsa/";
// const COMMON_API_URL = "https://uat-common.oneflotest.in/api/";
// const LENDER_ECO_SYSTEM_API_URL = "https://uat-lender.oneflotest.in/api/";

const DSA_API_URL = process.env.REACT_APP_DSA_API_URL;
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL;
const LENDER_ECO_SYSTEM_API_URL = process.env.REACT_APP_LENDER_ECO_SYSTEM_API_URL;

const CLIENT_NAME = "Client Name";
const IMAGE_BASE_URL = "";
export default {
    ApiUrlOne: DSA_API_URL,   //Distribution Channel
    ApiUrlTwo: COMMON_API_URL, //Common Services
    ApiUrlThree: LENDER_ECO_SYSTEM_API_URL, //Lender Ecosystem
    // ApiUrlFour: COMMON_API_URL,
    clientName: CLIENT_NAME,
    ImageBaseUrl: IMAGE_BASE_URL,
};
