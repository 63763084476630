import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Divider, Form, Spin, notification } from "antd";
// import scuess from "../../../assets/images/scuess.svg";
import scuess from "../../../../../../assets/images/scuess.svg";
import iconApporve from "../../../../../../assets/images/apporveIcon.svg";
import API_SERVICE from "shared/services/api-service";
import PageNotFound from "../../../../../../assets/images/pageNotFound.svg";

const ABCLSessionThanksScreen = (props) => {
  console.log('Location', props.location.search);
  const [isValid, setValidCase] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams() as any;
  useEffect(() => {
    const isValid = props.location.search?.split('=')[1];
    API_SERVICE.checkValidCaseID(id)
      .then(({ data }) => {
        // APPLICATION_COMPLETED
        if (isValid.toLowerCase() === 'application_completed') {
          setValidCase(data.payload);
        }
        setIsLoading(false);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      })
      .finally(() => {});
  }, []);
  const renderPageNotFound = () => {
    if (!isLoading) {
      return (
        <div className="img-page-not-div" style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <img src={PageNotFound} />
        </div>
      );
    }
  };
  const renderThank = () => {
    if (!isLoading) {
      return (
        <div className="consent-card-thankyou">
          <Card bordered={false}>
            <div style={{ marginBottom: "15px" }}>
              <img height={68} width={68} src={iconApporve} alt="" />
            </div>
            <p
              className=""
              style={{
                fontSize: "32px",
                fontWeight: "750",
              }}
            >
              Thank You!
            </p>
            <p
              style={{
                fontSize: "28px",
                fontWeight: "400",
              }}
            >
              Our Team will connect with you shortly..
            </p>
          </Card>
        </div>
      );
    }
  };
  return (
    <>
      {isLoading && (
        <div className="loader-view-flowable">
          <Spin
            tip="We are processing your request, please wait"
            size="large"
          />
        </div>
      )}
      {isValid ? renderThank() : renderPageNotFound()}
    </>
  );
};

export default ABCLSessionThanksScreen;
