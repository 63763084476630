import { AutoComplete, Button, Card, Col, Divider, Form, Input, Modal, notification, Radio, Row, Select, Space, Tabs, Typography } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GSTIN_REGEX, PHONE_NUMBER_REGEX, CONNECTOR_NAME_REGEX } from "shared/constants/AppConstants";
import API_SERVICE from "shared/services/api-service";
import {ReactComponent as IconSave} from "../../../assets/images/save.svg";
import Edit from "../../../assets/images/edit.png";
import {ReactComponent as IconEditBorder} from "../../../assets/images/edit_border.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";


const ConnectorBasicDetails: React.FunctionComponent<{ connector: any, disableValue: any, setConnector: any }> = (props) => {
    let { id } = useParams() as any;
    const history = useHistory();
    const [basicDetailsForm] = Form.useForm() as any;

    const [loading, setLoading] = useState(false);
    const [genders, setGenders] = useState([]);
    const [assignToData, setAssignToData] = useState([] as any);
    const [lineManagerId, setLineManagerId] = useState(null as any);
    const [connectorId, setConnectorId] = useState(null as any);
    const [managerData, setManagerData] = useState([] as any);
    const [disablefield, setDisableField] = useState(false);
    const [basicDetails, setBasicDetails] = useState({} as any);
    const [viewModel, setViewModel] = useState(false);
    const [editViewModel, setEditViewModel] = useState(false);
    const [isBusinessIdEdit, setIsBusinessIdEdit] = useState(false);
    const [newBusinessDetails, setNewBusinessDetails] = useState(null as any);
    const {appColors}: any = useSelector((state: RootState) => state.common);

    const professions = [
        { value: "Builder", key: "Builder" },
        { value: "CA/ Tax C", key: "CA/ Tax C" },
        { value: "Ex Banker", key: "Ex Banker" },
        { value: "L Freelancer", key: "L Freelancer" },
        { value: "Ins Agent", key: "Ins Agent" },
        { value: "RE Broker", key: "RE Broker" },
        { value: "Other", key: "Other" },
    ];

    useEffect(() => {
        getAllTypes();
        getConnectorById();
    }, []);

    useEffect(() => {
        if (props?.disableValue === true) {
            setDisableField(true);
        }
        if (props?.connector?.basicDetails) {
            setBasicDetails(props?.connector?.basicDetails);
            basicDetailsForm.setFieldsValue({
                connectorId: props?.connector?.basicDetails?.connectorId,
                connectorName: props?.connector?.basicDetails?.connectorName,
                connectorMobileNumber: props?.connector?.basicDetails?.connectorMobileNumber,
                emailId: props?.connector?.basicDetails?.emailId,
                connectorContactBrandName: props?.connector?.basicDetails?.connectorContactBrandName,
                businessName: props?.connector?.basicDetails?.businessName,
                gstIn:props?.connector?.basicDetails?.gstIn,
                businessId: props?.connector?.basicDetails?.businessId,
                gender: props?.connector?.basicDetails?.gender,
                name: props?.connector?.basicDetails?.name,
                phoneNumber: props?.connector?.basicDetails?.phoneNumber,
                profession: props?.connector?.basicDetails?.profession,
                srmMobileNumber: props?.connector?.basicDetails?.srmMobileNumber,
                srmId: props?.connector ? props?.connector?.basicDetails?.srmId : lineManagerId,
                srmName: props?.connector?.basicDetails?.srmName,
                userId: props?.connector ? props?.connector?.basicDetails?.userId : connectorId,
                channelManagerId: props?.connector ? props?.connector?.basicDetails?.channelManagerId : lineManagerId,
                channelManagerName: props?.connector?.basicDetails?.channelManagerName,
                channelManagerMobileNumber: props?.connector?.basicDetails?.channelManagerMobileNumber
            });
        }
    }, [props.connector]);

    const editBusinessIdconformation = () => {
        setIsBusinessIdEdit(true)
        setEditViewModel(false)
    }
    const editBusinessIdCancle = () => {
        setIsBusinessIdEdit(false)
        setEditViewModel(false)
    }

    const conformVaildBusinessId = () => {
        if (basicDetailsForm.getFieldValue("businessId") && basicDetails?.businessId === basicDetailsForm.getFieldValue("businessId")) {
            notification.error({ message: "Please change BusinessId" });
            setViewModel(false);
        } else {

            API_SERVICE.getBusinessIdUpdate(basicDetailsForm.getFieldValue("businessId"))
                .then(({ data }) => {
                    setNewBusinessDetails(data.payload)
                    setViewModel(true);
                })
                .catch((e: any) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                    setViewModel(false);
                });
        }
    }
    const updateBusinessId = () => {
        const payload = {
            connectorId: id,
            newBusinessId: basicDetailsForm.getFieldValue("businessId"),
            oldBusinessId: basicDetails?.businessId
        }
        API_SERVICE.updateConnectorBusinessId(payload)
            .then(({ data }) => {
                notification.success({ message: data.payload });
                getConnectorById();
                setViewModel(false);
                setIsBusinessIdEdit(false)
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
                setViewModel(false);
            });
    }

    const cancleBusinessId = () => {
        getConnectorById();
        setViewModel(false);
        setIsBusinessIdEdit(false)
    }

    const getAllTypes = () => {
        API_SERVICE.getTypes()
            .then(({ data }) => {
                let filteredGenderType = data.find(
                    (item: any) => item.propertyName == "GENDER"
                );
                setGenders(filteredGenderType.keyAndDisplayValueList);
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            });
    };

    const buildPayload = (formData: any) => {
        /*let userData = localStorage.getItem("user") as any;
        if (userData) {
            userData = JSON.parse(userData);
        }
        let assignTo = assignToData.find(
            (item) => item.fullName == formData.assignedTo
        );*/

        const payload = {
            accounts: null,
            basicDetails: {
                /*address: {
                    address: null,
                    area: null,
                    city: null,
                    country: null,
                    geoCode: null,
                    pinCode: null,
                },
                authorizedSignatory: {
                    active: true,
                    driverLicenseNo: null,
                    email: null,
                    gender: null,
                    managerId: null,
                    name: null,
                    pan: null,
                    phoneNumber: null,
                    primaryRole: null,
                    referralId: null,
                    userId: id,
                },*/
                /*businessName: null,
                businessType: null,*/
                connectorId: formData.connectorId,
                //connectorMobileNumber: null,
                connectorName: formData.connectorName,
                connectorMobileNumber: formData.connectorMobileNumber,
                //connectorType: null,
                //dateOfBirth: null,
                emailId: formData.emailId,
                connectorContactBrandName: formData.connectorContactBrandName,
                //emailVerified: null,
                businessName: formData.businessName,
                gstIn: formData.gstIn,
                businessId: formData.businessId,
                gender: ("Male" === formData.gender ? "M" : ("Female" === formData.gender ? "F" : "O")),
                name: formData.name,
                phoneNumber: formData.phoneNumber,
                //phoneVerified: null,
                profession: formData.profession,
                srmId: formData.srmId,
                srmMobileNumber: formData.srmMobileNumber,
                srmName: formData.srmName,
                channelManagerId: formData.channelManagerId,
                channelManagerName: formData.channelManagerName,
            },
            currentStatus: true,
            kycDetails: null,
            otherDetails: null
        };
        return payload;
    };

    const onFinish = (values: any) => {

        if (
            basicDetails?.connectorId != values?.connectorId ||
            basicDetails?.connectorName != values?.connectorName ||
            basicDetails?.connectorMobileNumber != values?.connectorMobileNumber ||
            basicDetails?.emailId != values?.emailId ||
            basicDetails?.connectorContactBrandName != values?.connectorContactBrandName ||
            basicDetails?.businessName != values?.businessName ||
            basicDetails?.businessId != values?.businessId ||
            basicDetails?.gender != values?.gender ||
            basicDetails?.name != values?.name ||
            basicDetails?.phoneNumber != values?.phoneNumber ||
            basicDetails?.profession != values?.profession ||
            basicDetails?.srmId != values?.srmId ||
            basicDetails?.srmMobileNumber != values?.srmMobileNumber ||
            basicDetails?.srmName != values?.srmName ||
            basicDetails?.channelManagerId != values?.channelManagerId ||
            basicDetails?.channelManagerName != values?.channelManagerName ||
            basicDetails?.gstIn != values?.gstIn
        ) {


            // const filterDataObject = [] as any;
            // const keys = Object.keys(values);

            // keys?.map((data) => {
            //     let filterData = props?.connector?.basicDetails[`${data}`]
            //     filterDataObject.push({
            //         key: data,
            //         value: filterData, 
            //     });
            // });

            // const convertedObject = Object.fromEntries(filterDataObject.map(item => [item.key, item.value]));
            // let res = _.isEqual(convertedObject, values)

            // if (res) {

            //     notification.error({message: "No Changes Found"})

            // } else {
            const payload = buildPayload(values);
            API_SERVICE.updateConnector(id, payload)
                .then(({ data }) => {
                    if (data) {
                        props.setConnector(data?.payload);
                        notification.success({ message: "Connector Updated Successfully" });
                        // history.push("/connectors");
                    }
                })
                .catch((e: any) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                });

        } else {
            notification.error({ message: "No Changes Found" });
        }
    };

    const checkPanDetails = (e: any) => {
        e.persist();
        if (e.target.value) {
            const { value: nextValue } = e.target;
            const params = {
                panNumber: nextValue,
            };
            API_SERVICE.checkPanDetails(params).then(({ data }) => {
                basicDetailsForm.setFieldsValue({
                    name: data.payload.fullName,
                });
            });
        }
    };

    const checkPincode = (e: any) => {
        const { value: nextValue } = e.target;
        const params = {
            pin_code: nextValue,
        };
        API_SERVICE.checkPincode(params).then(({ data }) => {
            if (data) {
                basicDetailsForm.setFieldsValue({
                    city: data.payload.city,
                    state: data.payload.state,
                });
            }
        });
    };

    const searchAssignTo = (ev: any) => {
        basicDetailsForm.setFieldsValue({
            connectorId: null
        });
        const params = {
            pageNo: 1,
            pageSize: 100,
            searchParam: ev,
            status: 'APPROVED'
        };
        API_SERVICE.getConnectors(params).then(({ data }) => {
            if (data) {
                setAssignToData(data?.payload?.content);
                setConnectorId(data?.payload?.content[0]?.businessDetails?.id);
            }
        });
    };

    const onSelectConnectorName = (item, value) => {
        setConnectorId(value.key);
        basicDetailsForm.setFieldsValue({
            connectorId: value.key
        });
        basicDetailsForm.setFieldsValue({
            connectorMobileNumber: value.userDetails.phoneNumber
        });
    }

    const searchManager = (searchVal: any = null) => {

        // basicDetailsForm.setFieldsValue({
        //     srmId: null,
        // });
        const params = { pageNo: 1, pageSize: null, searchParam: searchVal };
        API_SERVICE.getEmployees(params).then(({ data }) => {
            // debugger;
            if (data) {
                setManagerData(data?.payload?.content);
            }
        });
    };

    const onSelectManagerName = (item, value) => {
        setConnectorId(value.key);
        basicDetailsForm.setFieldsValue({
            srmId: value.key
        });
        basicDetailsForm.setFieldsValue({
            srmMobileNumber: value.phoneNumber
        });
    }

    const onSelectChannelManagerName = (item, value) => {
        setConnectorId(value.key);

        basicDetailsForm.setFieldsValue({
            channelManagerId: value.key
        });
        basicDetailsForm.setFieldsValue({
            channelManagerMobileNumber: value.phoneNumber
        });
    }

    const UserID = (ev: any) => {
        const params = {
            name: ev,
        };
        API_SERVICE.searchUser(params).then(({ data }) => {
            if (data) {
                setAssignToData(data?.payload?.content);
                setLineManagerId(data?.payload?.content[0]?.id)
            }
        });
    };

    if (!id) {
        history.goBack();
    }

    const getConnectorById = () => {
        API_SERVICE.getConnectorById(id)
            .then(({ data }) => {
                props.setConnector(data.payload);
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
            });
    }
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const btnRadioStyles: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
    const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
    const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
    const modalBtnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",width: "100px"};
    const modalBtnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width: "100px"};  

    return (
        <Card className="custom-app-card">
            <Form
                layout="vertical"
                form={basicDetailsForm}
                initialValues={{
                    phoneCode: "+91",
                    profession: "Builder",
                }}
                onFinish={onFinish}
                autoComplete="off"
                name="leadForm"
                disabled={disablefield}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item

                            label="Select Profession"
                            name="profession"
                            rules={[
                                { required: false, message: "Please select profession." },
                            ]}
                        >
                            <Radio.Group className="custom-radio profession-btns" style={btnRadioStyles}>
                                <Space direction="horizontal">
                                    {professions &&
                                        professions.map((loan: any, i: number) => (
                                            <Radio.Button key={i} value={loan.key}>
                                                {loan.value}
                                            </Radio.Button>
                                        ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <br />
                        <Form.Item
                            label="Select Gender"
                            name="gender"
                            rules={[{ required: false, message: "Please select gender." }]}
                        >
                            <Radio.Group
                                className="custom-radio profession-btns"
                                name="gender" style={btnRadioStyles}
                            >
                                <Space direction="horizontal">
                                    {genders &&
                                        genders.map((gender: any, i: number) => (
                                            <Radio.Button key={i} value={gender.value}>
                                                {gender.value}
                                            </Radio.Button>
                                        ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <br />
                        <Divider orientation="left" orientationMargin="0">
                            Contact Details
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="Connector Name"
                            name="name"
                            rules={[
                                { required: true, message: "Please enter connector name" },
                                {
                                    pattern: CONNECTOR_NAME_REGEX,
                                    message: "Plesae enter valid name"
                                  },
                            ]}
                        >
                            <Input className="custom-input" placeholder="Plesae enter connector name as per pan card"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Contact/Brand Name"
                            name="connectorContactBrandName"
                        >
                            <Input className="custom-input" placeholder="Plesae enter Contact/Brand name"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Email ID"
                            name="emailId"
                            rules={[
                                { required: true, message: "Please enter email" },
                                { type: "email", message: "Plesae enter valid email" },
                            ]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Email"
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Phone Number"
                            name="phoneNumber"
                            rules={[
                                { required: true, message: "Please enter mobile number" },
                                {
                                    pattern: PHONE_NUMBER_REGEX,
                                    message: "Please enter valid number",
                                },
                            ]}
                        >
                            <Input className="custom-input-phone" placeholder="Please enter Phone Number " />
                            {/* addonBefore={prefixSelector} */}
                        </Form.Item>

                        <Divider orientation="left" orientationMargin="0">
                            Business Details
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="Business Name"
                            name="businessName"
                        >
                            <Input
                                className="custom-input"
                                readOnly={true}
                                placeholder="Please enter Business Name"
                            />
                        </Form.Item>
                        <Form.Item className="field-bg" label="Business ID" name="businessId"
                            rules={[{ required: false, message: "Please enter business id" }]}
                        >
                            <Input className="custom-input"
                                onInput={toInputUppercase}
                                placeholder="Plesae enter Business Id"
                                readOnly={!isBusinessIdEdit}
                                suffix={(id === props?.connector?.basicDetails?.ownerConnectorUserId ?

                                    (!isBusinessIdEdit ? <IconEditBorder className="custome-btn-icon-secondary" style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any} onClick={() => { setEditViewModel(true) }}/> 
                                        :
                                    <p className="cursor-pointer custome-btn-icon"><IconSave style={{"--bgColor": appColors?.appPrimaryColor ?? ""} as any} onClick={() => { conformVaildBusinessId() }}/></p>    
                                            ) : null)
                                } />
                        </Form.Item>
                    </Col>
                    <Divider className="custome-devider-hozntl" type="vertical" style={{ height: "auto" }} />
                    <Col span={10}>
                        <Divider orientation="left" orientationMargin="0">
                            <b> GSTIN</b>
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="GSTIN"
                            name="gstIn"
                            rules={[
                                { required: false, message: "Please enter GSTIN" },
                                {
                                pattern: GSTIN_REGEX,
                                message: "Invaid GSTIN provided, please enter again",
                                },
                            ]}
                        >
                            <Input
                                className="custom-input"
                                readOnly={false}
                                placeholder="Please enter GSTIN"
                            />
                        </Form.Item>
                        <label className="ant-collapse-header">Relationship Mapping</label>
                        <Divider orientation="left" orientationMargin="0">
                            <b> Assign To Sales User</b>
                        </Divider>
                        <Form.Item

                            className="field-bg"
                            label="Sales RelationShip Manager ID"
                            name="srmId"
                            rules={[
                                { required: false, message: "Please enter Manager ID" },
                            ]}
                        >
                            <Input
                                className="custom-input"
                                onChange={debounce(checkPincode, 1000)}
                                placeholder="Please enter Manager ID"
                                readOnly={true}
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Sales RelationShip Manager Name"
                            name="srmName"
                            rules={[{ required: false, message: "Please enter Manager Name" }]}
                        >
                            <AutoComplete
                                className="custom-auto-compleat"
                                onSearch={debounce(searchManager, 1000)}
                                placeholder="RelationShip Manager Name"
                                onSelect={onSelectManagerName}
                            >
                                {managerData?.sort(function (a: any, b: any) {
                                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                                    return 0;
                                })?.map((item: any) => (
                                    <Select.Option key={item.userId} {...item} value={item.fullName}>
                                        <Row gutter={[16, 16]} className="assign-list">
                                            <Col className="left" span={16}>
                                                <span className="name">{item.fullName}</span><br />
                                                <span className="city">{item?.address?.city && item?.address?.city}</span>
                                            </Col>
                                            <Col className="right" span={8}>
                                                <span className="mob">{item?.phoneNumber && item?.phoneNumber}</span>
                                            </Col>
                                        </Row>
                                    </Select.Option>
                                ))}
                            </AutoComplete>
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Sales Relationship Manager Mobile Number"
                            name="srmMobileNumber"

                            rules={[
                                { required: false, message: "Please enter Mobile Number" },
                                {
                                    pattern: PHONE_NUMBER_REGEX,
                                    message: "Please enter valid number",
                                },
                            ]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Mobile Number"
                                readOnly={true}
                            />
                        </Form.Item>
                        <Divider orientation="left" orientationMargin="0">
                            <b>Channel Manger Details</b>
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="ChannelManager ID"
                            name="channelManagerId"
                            rules={[{ required: false, message: "Please enter Channelmanager Id" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Channelmanager Id"
                                readOnly={true}
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="ChannelManager Name"
                            name="channelManagerName"
                            rules={[{ required: false, message: "Please enter Channelmanager Name" }]}
                        >
                            <AutoComplete
                                className="custom-auto-compleat"
                                onSearch={debounce(searchManager, 1000)}
                                placeholder="RelationShip Manager Name"
                                onSelect={onSelectChannelManagerName}
                                disabled={id !== props?.connector?.basicDetails?.ownerConnectorUserId}

                            >
                                {managerData?.sort(function (a: any, b: any) {
                                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                                    return 0;
                                })?.map((item: any) => (
                                    <Select.Option key={item.userId} {...item} value={item.fullName}>
                                        <Row gutter={[16, 16]} className="assign-list">
                                            <Col className="left" span={16}>
                                                <span className="name">{item.fullName}</span><br />
                                                <span className="city">{item?.address?.city && item?.address?.city}</span>
                                            </Col>
                                            <Col className="right" span={8}>
                                                <span className="mob">{item?.phoneNumber && item?.phoneNumber}</span>
                                            </Col>
                                        </Row>
                                    </Select.Option>
                                ))}
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            className="field-bg"
                            label="Channel Manager Mobile Number"
                            name="channelManagerMobileNumber"

                            rules={[
                                { required: false, message: "Please enter Mobile Number" },
                                {
                                    pattern: PHONE_NUMBER_REGEX,
                                    message: "Please enter valid number",
                                },
                            ]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Mobile Number"
                                readOnly={true}
                            />
                        </Form.Item>

                        <Divider orientation="left" orientationMargin="0">
                            <b>Referral Connector Details</b>
                        </Divider>
                        <Form.Item
                            className="field-bg"
                            label="Connector ID"
                            name="connectorId"
                            rules={[{ required: false, message: "Please enter state" }]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Connector ID"
                                readOnly={true}
                            />
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Connector Name"
                            name="connectorName"
                            rules={[{ required: false, message: "Please enter assign to" }]}
                        >
                            <AutoComplete
                                className="custom-auto-compleat"
                                onSearch={debounce(searchAssignTo, 1000)}
                                placeholder="Connector Name"
                                onSelect={onSelectConnectorName}
                            >

                                {assignToData?.sort(function (a: any, b: any) {
                                    if (a?.userDetails?.fullName.toLowerCase() < b?.userDetails?.fullName.toLowerCase()) return -1;
                                    if (a?.userDetails?.fullName.toLowerCase() > b?.userDetails?.fullName.toLowerCase()) return 1;
                                    return 0;
                                })?.map((item: any) => (
                                    (id !== item?.userDetails?.id ?
                                        <Select.Option key={item?.userDetails?.id} {...item} value={item?.userDetails?.fullName}>
                                            <div>
                                                <Row gutter={[16, 16]} className="assign-list">
                                                    <Col className="left" span={16}>
                                                        <span className="name">{item?.userDetails?.fullName}</span><br />
                                                    </Col>
                                                    <Col className="right" span={8}>
                                                        <span className="city">{item?.userDetails?.id}</span>

                                                        {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Select.Option>
                                        :
                                        null
                                    )
                                ))}
                            </AutoComplete>
                        </Form.Item>

                        <Form.Item
                            className="field-bg"
                            label="Connector Mobile Number"
                            name="connectorMobileNumber"

                            rules={[
                                { required: false, message: "Please enter Mobile Number" },
                                {
                                    pattern: PHONE_NUMBER_REGEX,
                                    message: "Please enter valid number",
                                },
                            ]}
                        >
                            <Input
                                className="custom-input"
                                placeholder="Please enter Mobile Number"
                                readOnly={true}
                            />
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={[24, 24]} style={{ display: "flex", justifyContent: "end" }}>
                    <Divider />
                    <Col >
                        <Form.Item>
                            <Button
                                className="dynamic-btn-default"
                                style={btnDefaultBorderStyle}
                                size="large"
                                loading={loading}
                                htmlType="submit"
                                onClick={() => {
                                    history.push("/connectors");
                                }}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item>
                            <Button
                                className="dynamic-btn-primary"
                                style={btnPrimaryStyles}
                                size="large"
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {editViewModel &&
                <Modal
                    width={350}
                    visible={editViewModel}
                    okText="Yes"
                    cancelText="No"

                    closable={false}
                    maskClosable={false}
                    footer={false}
                >
                    <Form
                        layout="vertical"
                        onFinish={editBusinessIdconformation}
                    >
                        <div style={{ textAlign: "center", marginTop: "30px" }} >
                            Are you sure you would like to change the parent/owner Business ID? <br/><b>These changes cannot be reversed.</b>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "20px" }}>
                            <Form.Item>
                                <Space className="buttons">
                                    <Button
                                        className="dynamic-btn-primary"
                                        style={modalBtnPrimaryStyles}
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        loading={loading}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        className="dynamic-btn-default"
                                        style={modalBtnDefaultBorderStyle}
                                        type="default"
                                        size="large"
                                        onClick={() => editBusinessIdCancle()}
                                    >
                                        No
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>
            }

            {viewModel &&
                <Modal
                    width={350}
                    visible={viewModel}
                    okText="Yes"
                    cancelText="No"

                    closable={false}
                    maskClosable={false}
                    footer={false}
                >
                    <Form
                        layout="vertical"
                        onFinish={updateBusinessId}
                    >
                        <div style={{ textAlign: "center", marginTop: "30px" }} >
                            <p> Details changed to:<br />
                                New Business Name: <b>{newBusinessDetails?.businessName}</b><br />
                                New Business ID: <b>{newBusinessDetails?.businessId}</b></p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px", marginTop: "20px" }}>
                            <Form.Item>
                                <Space className="buttons">
                                    <Button                              
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        loading={loading}
                                        style={{ width: "100px" }}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        style={{ width: "100px" }}
                                        type="default"
                                        size="large"
                                        onClick={cancleBusinessId}
                                    >
                                        Cancel
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>

            }
        </Card>

    );
};

export default ConnectorBasicDetails;