import React, { useEffect, useState } from "react";
import { Table, notification, Typography, Button, Tabs, Tooltip, Space } from "antd";
import API_SERVICE from "shared/services/api-service";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

interface IListLeadDetails {
  leadData: any;
  showDecisioning?: any;
  setLeadViewType?: any;
  hideCancel?: boolean;
}

const ListLeadDetails: React.FC<IListLeadDetails> = (
  props: IListLeadDetails
) => {
  const { leadData, showDecisioning, setLeadViewType, hideCancel } = props;
  const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
  const [decisioningTabkey, setDecisioningTabkey] = useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    listDecisioning();
  }, []);

  const handlePaginationChange = (current: any, pageSize: any) => {
    setPageNo(current);
    setPageSize(pageSize);
  };

  const listDecisioning = () => {
    let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
      return item?.journeyId
    })
    API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, false, '', tmpId)
      .then(({ data }) => {
        setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
        setDecisioningTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId)
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lender Name",
      dataIndex: "lenderName",
      key: "lenderName",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Purpose",
      dataIndex: "decisioningPurpose",
      key: "decisioningPurpose",
    },
    {
      title: "Max Loan Amount",
      dataIndex: "maxLoanAmount",
      key: "maxLoanAmount",
    },
    {
      title: "Total EMI",
      dataIndex: "totalEmi",
      key: "totalEmi",
    },
    {
      title: "Tenure",
      dataIndex: "tenureInMonths",
      key: "tenureInMonths",
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
  ];

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",width:'110px',marginBottom: "15px",
    marginLeft: "15px",borderRadius: "5px",display: hideCancel ? "none" : "",};

  return (
    <>
      <div className="">
        {!showDecisioning && (
          <div className="heading" style={{ marginLeft: "1.1rem" }}>
            <Title level={5} style={{ marginTop: "15px" }}>
              Decisioning Output
            </Title>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <Tabs
            className="lender-sub-tabs"
            style={{'--borderColor': appColors?.appPrimaryColor ?? ""} as any}
            size="small"
            activeKey={decisioningTabkey}
            onChange={(key) => setDecisioningTabkey(key)}
          >
            {leadData?.journeyConfigResponseList?.map((data: any) => {
              let tmpFindData = descisioningDetail?.find((item: any) =>
                item?.journeyId == data?.journeyId
              );
              let sortedList = tmpFindData?.decisioningDataListResponseList?.sort((a: any, b: any) =>
                b.maxLoanAmount - a.maxLoanAmount
              );
              return (
                <Tabs.TabPane
                  key={data?.journeyId}
                  tab={data?.journeyName}
                >
                  <div className="tab-Userdetails">
                    <Space className="userdetails">
                      <p>Date/Time: {tmpFindData?.executionTime}</p>
                      <p>Executed By: {tmpFindData?.executedBy}</p>
                    </Space>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={sortedList ?? []}
                    className="dynamic-pagination-checkbox"
                    style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
                    size="small"
                    onChange={(pagination: any) => {
                      handlePaginationChange(pagination.current, pagination.pageSize);
                    }}
                    pagination={{
                      current: pageNo,
                      pageSize: pageSize,
                      total: tmpFindData?.decisioningDataListResponseList?.length,
                      showSizeChanger: true
                    }}
                    scroll={{ x: "max-content" }}
                  />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </div>
        <Button
          type="default"
          className="dynamic-btn-default"
          style={btnDefaultBorderStyle}
          size="large"
          onClick={() => setLeadViewType("LeadDetailNewTabs")}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
export default ListLeadDetails;
