import { CarOutlined, FileAddFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Modal,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { useHistory } from "react-router-dom";
import addfile from "../../../../../src/assets/images/payouts/addFile.svg";
import { ReactComponent as AddFileGray } from "../../../../assets/images/upload-file-gray.svg";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import UploadImportBulkUpload from "./upload-import-bulkupload";
import { saveByteArray } from "shared/services/Utility";
import { ReactComponent as Information } from "../../../../assets/images/information.svg"
import deletefile from "../../../../../src/assets/images/delete.svg";
import { RootState } from "shared/redux/store";
import { useSelector } from "react-redux";

const { Text } = Typography;
type Props = {};

const StartImportBulkUpload: React.FunctionComponent<{
  setActiveStepKey: any;
  setFileName: any;
}> = (props) => {
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState({} as any);
  const [isopenStart, setIsopenStart] = useState(true);
  const [isopenUpload, setIsOpenUpload] = useState(false);
  const [allowedAttechmentTypes] = useState([
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ]);
  const { setActiveStepKey } = props;
  const filePlaceholder = "Drag or Drop your file here";
  const [selectedFileName, setSelectedFileName] = useState(filePlaceholder);
  const { setFileName } = props;
  const [isRemove, setIsRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUserGuideModalOpen, setIsUserGuideModalOpen] = useState(false);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const [listOfLoanDetails, setListOfLoanDetails] = useState([
    {
      loanId: "LP00000001",
      loanProductValue: "INVOICE_FINANCING",
      loanProductName: "Invoice Financing",
    },
    {
      loanId: "LP00000002",
      loanProductValue: "EARLY_SALARY_FINANCING",
      loanProductName: "Early Salary Financing",
    },
    {
      loanId: "LP00000003",
      loanProductValue: "PURCHASE_ORDER_FINANCING",
      loanProductName: "Purchase Order Financing",
    },
    {
      loanId: "LP00000004",
      loanProductValue: "PROOF_OF_DELIVERY_BASED_FINANCING",
      loanProductName: "Proof Of Delivery Financing",
    },
    {
      loanId: "LP00000005",
      loanProductValue: "SERVICE_ORDER_FINANCING",
      loanProductName: "Service Order Financing",
    },
    {
      loanId: "LP00000006",
      loanProductValue: "HOME_LOAN",
      loanProductName: "Home Loan",
    },
    {
      loanId: "LP00000007",
      loanProductValue: "AUTO_LOAN",
      loanProductName: "Auto Loan",
    },
    {
      loanId: "LP00000008",
      loanProductValue: "CORPORATE_LOAN",
      loanProductName: "Corporate Loan",
    },
    {
      loanId: "LP00000009",
      loanProductValue: "BUSINESS_LOAN",
      loanProductName: "Business Loan",
    },
    {
      loanId: "LP000000011",
      loanProductValue: "LOAN_AGAINST_PROPERTY",
      loanProductName: "Loan Against Property",
    },
    {
      loanId: "LP000000012",
      loanProductValue: "LIFE_INSURANCE_POLICY",
      loanProductName: "Life Insurance Policy",
    },
    {
      loanId: "LP000000013",
      loanProductValue: "CREDIT_CARD",
      loanProductName: "Credit Card",
    },
    {
      loanId: "LP000000014",
      loanProductValue: "BUSINESS_INSTALLMENT_LOAN",
      loanProductName: "Business Installment Loan",
    },
    {
      loanId: "LP000000015",
      loanProductValue: "SMALL_BUSINESS_LOAN",
      loanProductName: "Small Business Loan",
    },
    {
      loanId: "LP000000016",
      loanProductValue: "UNSECURED_OVERDRAFT",
      loanProductName: "Unsecured Overdraft",
    },
    {
      loanId: "LP000000017",
      loanProductValue: "COMMERCIAL_PURCHASE",
      loanProductName: "Commercial Purchase",
    },
    {
      loanId: "LP000000018",
      loanProductValue: "SUPPLY_CHAIN_FINANCE",
      loanProductName: "Supply Chain Finance",
    },
    {
      loanId: "LP000000019",
      loanProductValue: "LOAN_AGAINST_SECURITIES",
      loanProductName: "Loan Against Securities",
    },
    {
      loanId: "LP000000020",
      loanProductValue: "WORKING_CAPITAL_OVERDRAFT",
      loanProductName: "Working Capital Overdraft",
    },
    {
      loanId: "LP000000021",
      loanProductValue: "PERSONAL_LOAN_ONLY",
      loanProductName: "Personal Loan",
    },
    {
      loanId: "LP4866184927",
      loanProductValue: "Realty",
      loanProductName: "Realty",
    },
    {
      loanId: "LP9349648467",
      loanProductValue: "CROSS_SELL_HEALTH_INSURANCE_POLICY",
      loanProductName: "Cross sell Health Insurance",
    },
    {
      loanId: "LP6611850548",
      loanProductValue: "CROSS_SELL_LIFE_INSURANCE_POLICY",
      loanProductName: "Cross sell Life Insurance",
    },
    {
      loanId: "LP4746937708",
      loanProductValue: "CROSS_SELL_MOTOR_INSURANCE",
      loanProductName: "Cross sell Motor Insurance",
    },
  ]);

  const onBulkUploadChange = (file: any) => {
    if (isRemove) {
      setIsRemove(false);
    }
    setUploadFile(file);
    setSelectedFileName(file?.file?.name);
  };

  const goToUploadImportBulkUpload = () => {
    if (isRemove) {
      notification.error({ message: "Please select file." });
      return;
    }

    if (!uploadFile.file?.name) {
      notification.error({ message: "Please select file" });
      return;
    }
    let formData = new FormData();

    formData.append("file", uploadFile.file);

    if (allowedAttechmentTypes.indexOf(uploadFile.file.type) === -1) {
      notification.error({ message: "Please attach only .xlsx file." });
      return;
    }
    setLoading(true);
    const entityType = "NEW_LEAD_UPLOAD";
    API_SERVICE.bulkUploadStart(entityType, formData)

      .then(({ data }) => {
        if (data) {
          localStorage.setItem("uploadId", data.payload.uploadId);
          setIsopenStart(false);
          setActiveStepKey(2);
          // setIsOpenUpload(true);
          setFileName(selectedFileName);
        }
      })

      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }).finally(() => {
        setLoading(false);
      });
    // history.push("/bulkUpload/AllBulkUpload/upload-import-bulkupload");
  };

  const handleDownload = () => {
    API_SERVICE.getDownloadFormate("NEW_LEAD_UPLOAD")
      .then(({ data }) => {
        saveByteArray([data], "upload_bulk_lead.xlsx");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFileRemove = () => {
    setSelectedFileName(filePlaceholder);
    if (!isRemove) {
      setIsRemove(true);
    }
  };

  const onClickUserGuide = () => {
    setIsUserGuideModalOpen(true);
  };

  const onOkUserGuideModal = () => {
    setIsUserGuideModalOpen(false);
  };

  const onCancelUserGuideModal = () => {
    setIsUserGuideModalOpen(false);
  };

  const columnForNote = [
    {
      title: "Loan ID",
      dataIndex: "loanId",
      key: "loanId",
    },
    {
      title: "Loan Product Value",
      dataIndex: "loanProductValue",
      key: "loanProductValue",
    },
    {
      title: "Loan Product Name",
      dataIndex: "loanProductName",
      key: "loanProductName",
    },
  ];
  const onDeleteDoc = () => {
    setSelectedFileName(filePlaceholder);
    if (!isRemove) {
      setIsRemove(true);
    }
  };

  const customStylesBtn: any = { '--borderColor': appColors?.appSecondaryColor ?? "", 'marginLeft': "10px" };
  const customStylesBtnPrimary: any = { '--btnColor': appColors?.appPrimaryColor ?? "", 'marginRight': "10px" };
  const customStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };

  return (
    <>
      {isopenStart && (
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Text>
                <h4>Bulk Upload Of Leads</h4>
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="link" onClick={handleDownload} className="bulk-upload-border-primary-btn" style={customStyles}>
                Lead bulk upload .xlsx template
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
                height: "200px",
              }}
            >
              <Dragger
                style={{
                  width: "600px",
                  height: "400px",
                  borderRadius: "20px",
                }}
                accept=".xlsx,"
                name="bulkUpload"
                maxCount={1}
                beforeUpload={() => {
                  return false;
                }}
                onChange={onBulkUploadChange}
                onRemove={onFileRemove}
                showUploadList={false}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                    }}
                  >
                    <Space className=" start-import-upload-icon ">
                      <AddFileGray width={60} height={60} />
                    </Space>
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Text>
                        {isRemove
                          ? "Drag or Drop your file here"
                          : selectedFileName}
                      </Text>
                    </div>
                  </div>
                </div>
              </Dragger>
              <div
                style={{
                  border: "none",
                  marginLeft: "-35px",
                  marginTop: "25px",
                  zIndex: "1"
                  // boxShadow: "none",
                  // backgroundColor: "#FAFAFA",
                }}
              >
                <img src={deletefile} onClick={onDeleteDoc} alt="" width={20} />
              </div>
            </div>
          </div>

          <div>
            <p
              className="bulk-uplopad-span"
              style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
            >
              {" "}
              {/* <img src={info} alt="" width={20} /><span style={{ cursor: "pointer" }} onClick={onClickUserGuide}> User Guide - How to
                Bulk Upload</span> */}
              <Information style={{ "--bgColor": appColors?.appSecondaryColor ?? "", width: '30px' } as any} />
              <span style={{ cursor: "pointer" }} onClick={onClickUserGuide}> User Guide - How to
                Bulk Upload</span>
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <Button
              className="bulk-upload-btn  dynamic-btn-primary"
              style={customStylesBtnPrimary}
              type="primary"
              loading={loading}
              onClick={() => goToUploadImportBulkUpload()}
            >
              Next
            </Button>

            <Popconfirm
              title="Your uploaded file will be deleted"
              onConfirm={(event) => history.goBack()}
              okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border: "none" } }}
              cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }}
            >
              <Button
                className="bulk-upload-btn  dynamic-btn-default"
                style={customStylesBtn}
              // onClick={() => { history.goBack() }}
              >
                Back
              </Button>
            </Popconfirm>
          </div>
        </div>
      )}
      <Modal
        footer={false}
        style={{marginBottom:"8rem"}}
        width={800}
        centered
        title="Note:"
        visible={isUserGuideModalOpen}
        onOk={onOkUserGuideModal}
        onCancel={onCancelUserGuideModal}
      >
        <p>You can use this feature to create bulk leads in Oneflo. Please ensure the below while creating leads in bulk:</p>
        <div style={{ fontWeight: "300" }}>
          1. Always download the latest template for bulk upload
        </div>
        <div style={{ fontWeight: "300" }}>
          2. Ensure that the mandatory fields(*) are filled in for all rows that you are creating
        </div>
        <div style={{ fontWeight: "300" }}>
          3. Use only the values available in the drop-down form fields where the drop-down is available on the excel template (Eg: Applicant Type, Applicant Sub-type etc)
        </div>
        <div style={{ fontWeight: "300" }}>
          4. Field Mappings and Validations:
          <ul>
            <li style={{ fontWeight: "300" }}>
              Applicant Type and Applicant Sub Type should be correctly mapped:
            </li>
            <ul>
              <li style={{ fontWeight: "300" }}>Applicant Type Individual can only match with Applicant Sub Type 'SALARIED' or 'SELF_EMPLOYED'.</li>
              <li style={{ fontWeight: "300" }}>Applicant Type Business cannot match with Applicant Sub Type 'SALARIED' or 'SELF_EMPLOYED'.</li>
            </ul>
          </ul>
        </div>
        <div style={{ fontWeight: "300" }}>
          5. The optional fields can be included in the upload for better data tracking
        </div>
        {/* <Table columns={columnForNote} dataSource={listOfLoanDetails} className="dynamic-pagination-checkbox" style={customStyles} /> */}
      </Modal>
    </>
  );
};

export default StartImportBulkUpload;
