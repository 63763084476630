import {
    Button,
    Col,
    Form,
    Input,
    notification,
    Row,
    Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { FaFile } from 'react-icons/fa';
import {ReactComponent as FileBorderIcon} from "../../assets/images/file-border.svg";
import { Link, useHistory, useParams } from 'react-router-dom';
import API_SERVICE from 'shared/services/api-service';
import { base64ToArrayBuffer, saveByteArray } from 'shared/services/Utility';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setScreenHeader } from 'shared/redux/common-reducer';
import { RootState } from 'shared/redux/store';

const { Title } = Typography;

const ViewNotice: React.FC = () => {
    const history = useHistory();
    const {appColors}: any = useSelector((state: RootState) => state.common);
    const dispatch = useDispatch();

    const [leadData, setLeadData] = useState(null as any);

    let { id } = useParams() as any;

    if (!id) {
        history.goBack();
    }

    useEffect(() => {
        dispatch(
            setScreenHeader({
              backScreenTitle: "Notices",
              backScreenPath: "/notices",
              screenTitle: "View Notice",
            })
        );
        getLeadById();
    }, []);
    const [mobileOffer, setMobileOfferDocumentDetails] = useState(false);

    const [form] = Form.useForm();
    const getLeadById = () => {
        // setLoading(true)
        API_SERVICE.getBusinessid(id)
            .then(({ data }) => {
                if (data?.payload) {
                    setLeadData(data.payload);
                    if (data.payload) {
                        form.setFieldsValue({
                            title: data.payload.title,
                            description: data.payload.description
                        });
                        // setDesc(data.payload.description.toString())
                        // setTitle(data.payload.title)

                    }
                    if (data.payload.documentDetails.fileExtn !== "") {
                        setMobileOfferDocumentDetails(true)
                    }

                    if (data?.payload?.caseDetails?.caseHistoryHolder) {
                        // setLeadHistory(data.payload.caseDetails.caseHistoryHolder)
                    }
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
            });
    };

    const downloadDocument = (item: any) => {
        API_SERVICE.downloadDocument(
          item.businessId,
          item.businessDocumentId
        )
          .then(({ data }) => {
            let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
            saveByteArray([bytesData], data.payload.documentName);
          })
          .catch((e: any) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          });
      };

    const document = (leadData?.documentDetails?.length ?? 0) > 0 ? leadData.documentDetails[0] : {};
    const customStyles: any = {'--fileIconColor': appColors?.appPrimaryColor ?? ""};

    return (
        <div>
            <Row>
                <Col span={6} style={{ marginTop: '2%' }}>
                    {mobileOffer &&
                        <>
                            <h3>Attached Documents</h3>
                            <div className="doc-detail" >
                                <FileBorderIcon onClick={() => downloadDocument(document)}  style={customStyles}/>
                                {/* <Button
                                    className="blue"
                                    size="middle"
                                    // type="primary"
                                    // shape="circle"
                                    icon={<FaFile />}
                                    // onClick={(event) => downloadDocument(event, record)}
                                /> */}
                                <span>
                                    {document?.documentName}
                                    <br />
                                    <span className="type">
                                        {document?.documentType}
                                    </span>
                                </span>
                            </div>
                        </>
                    }
                </Col>

            </Row>
            <Form
                style={{ "marginTop": "2.5%" }}
                layout='vertical'
                form={form}
                autoComplete='off'
                name='leadForm'
            >
                <Row gutter={[16, 16]} style={{ "marginTop": "1%" }}>
                    <Col span={16}>
                        <Form.Item
                            label='Title'
                            name='title'
                        >
                            <Input
                                className='custom-input'
                                readOnly
                            />
                        </Form.Item>
                        <Form.Item
                            label='Description'
                            name='description'
                        >
                            <TextArea
                                className='custom-textarea'
                                rows={8}
                                readOnly
                            />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </div>
    );
};

export default ViewNotice;
