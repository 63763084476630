import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { ReactComponent as IconEyeBorder } from "../../assets/images/eye_border.svg";
import { ReactComponent as IconEditBorder } from "../../assets/images/edit_border.svg";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import "../Lenders/index.scss";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { set } from "lodash";

const { Title, Text } = Typography;
const { Option } = Select;

type Props = {};

const EditInsurer: React.FunctionComponent<Props> = () => {
  const location = useLocation() as any;
  const { lenderMappingId, type } = location.state;
  const [lenderData, setLenderData] = useState({} as any);
  const [pIDetailList, setPIDetailList] = useState([] as any);
  const [showProductPlan, setShowProductPlan] = useState(false);
  const [routingMode, setRoutingMode] = useState("API");
  const [selectedProductPlan, setSelectedProductPlan] = useState(
    undefined as any
  );
  const [productPLans, setProductPlans] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const [addLenderForm] = Form.useForm() as any;
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const isViewMode = type == "VIEW";

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Insurer",
        backScreenPath: "/insurer",
        screenTitle: (isViewMode ? "View" : "Edit") + " Insurer",
      })
    );
    getInsuranceDetail();
  }, []);

  const getInsuranceDetail = () => {
    const payload = { insuranceInfoId: lenderMappingId };
    setLoading(true);
    API_SERVICE.getInsurance(payload)
      .then(({ data }) => {
        const lender = data?.payload;
        setLenderData(lender);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const getProductPlanList = () => {
    if ((productPLans?.length ?? 0) > 0) return;

    setLoading(true);
    API_SERVICE.getProductPlanList({
      pageSize: 100,
      productType: lenderData.productId,
      provider: lenderData?.finInfoId,
      productPlanStatus: "ACTIVE"
    })
      .then(({ data }) => setProductPlans(data?.payload?.content ?? []))
      .finally(() => setLoading(false));
  };

  const addLender = (values: any) => {
    // if (routingMode == "API") {
    //   updatePartnerIdentificationDetails(values);
    // } else {
      updateLenderHandler(values);
    // }
  };

  const updateLenderHandler = (values: any) => {
    const payload = {
      status: selectedProductPlan?.status ?? (values.status ? "ACTIVE" : "INACTIVE"),
      submissionType: values.routingMode,
      productPlanId: selectedProductPlan?.productPlanId ?? values.productPlanId,
    };

    API_SERVICE.updateProductPlan(lenderData.lenderMappingId, payload)
      .then(({ data }) => {
        if (data) {
          if (data.message) {
            notification.success({ message: data.message });
            getInsuranceDetail();
            setShowProductPlan(false);
            setSelectedProductPlan(undefined);
            setIsAdd(false);
            addLenderForm.resetFields();
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updatePartnerIdentificationDetails = (values) => {
    const keys = Object.keys(values);
    const filterDataObject = [] as any;

    pIDetailList?.map((data) => {
      let filterData = keys.filter((item) => item === data?.key);
      filterDataObject.push({
        key: filterData[0],
        value: values[filterData[0]],
      });
    });

    const params = {
      lenderId: lenderData.finInfoId,
      productIds: lenderData.productId,
      partnerIdentificationKeyDetail: {
        partnerIdentificationDetails: filterDataObject,
      },
    };

    API_SERVICE.updatePartnerIdentificationDetails(lenderData.finInfoId, params)
      .then(({ data }) => {
        updateLenderHandler(values);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getPartnerIdentificationDetails = () => {
    API_SERVICE.getPartnerIdentificationDetails(
      lenderData?.finInfoId,
      lenderData?.productId
    ).then(({ data }) => {
      if (data?.payload?.partnerIdentificationDetails) {
        setPIDetailList(data.payload.partnerIdentificationDetails);
      }
    });
  };

  const columns = [
    {
      title: "Product Plan",
      dataIndex: "productPlanName",
      key: "productPlanName",
    },
    {
      title: "Routing Mode",
      dataIndex: "submissionType",
      key: "submissionType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: any) => (
        <Switch
          // style={customStyles}
          disabled={isViewMode}
          checked={_ == "ACTIVE"}
          onChange={(event) => {
            const payload = {
              status: event,
              routingMode: record.submissionType,
              productPlanId: record.productPlanId,
            };
            updateLenderHandler(payload);
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className={
              isViewMode ? "custome-btn-icon" : "custome-btn-icon-secondary"
            }
            style={
              {
                "--bgColor":
                  (isViewMode
                    ? appColors?.appPrimaryColor
                    : appColors?.appSecondaryColor) ?? "",
              } as any
            }
            size="small"
            type="text"
            icon={isViewMode ? <IconEyeBorder /> : <IconEditBorder />}
            onClick={() => {
              setIsAdd(false);
              setShowProductPlan(false);
              addLenderForm.resetFields();
              setTimeout(() => {
                setShowProductPlan(true);
              }, 100);
              setSelectedProductPlan(record);
              setRoutingMode(record?.submissionType);
              if (record?.submissionType == "API") {
                getPartnerIdentificationDetails();
              }
            }}
          />
        </Space>
      ),
    },
  ];

  const btnRadioStyles: any = {
    "--borderColor": appColors?.appPrimaryColor ?? "",
  };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };

  const renderOther = () => {
    return (
      <>
        {!isAdd ? (
          <Form.Item
            className="field-bg-border bg-disabled"
            name="productPlanId"
            label="Product Plan"
          >
            <Text>{selectedProductPlan?.productPlanName}</Text>
          </Form.Item>
        ) : (
          <Form.Item
            className="field-bg-border bg-white"
            name="productPlanId"
            label="Product Plan"
            rules={[{ required: true, message: "Please enter product name" }]}
          >
            <Select
              showSearch
              className="custom-select"
              placeholder="Product Plan"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase())
              }
            >
              {productPLans?.map((product: any, i: number) => (
                <Option key={i} value={product.productPlanId}>
                  {product.productPlanName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="routingMode"
          style={{ marginTop: "5px" }}
          label="Select Case Routing Mode"
          initialValue={routingMode}
        >
          <Radio.Group
            className="dynamic-radio"
            style={btnRadioStyles}
            disabled={isViewMode}
            onChange={(e) => setRoutingMode(e?.target?.value)}
            name="routingMode"
          >
            <Radio value={"API"}>API</Radio>
            <Radio value={"LOGIN"}>Login</Radio>
          </Radio.Group>
        </Form.Item>

        {routingMode == "API" &&
          pIDetailList?.map((item) => {
            return (
              <Form.Item
                className="field-bg-border bg-white"
                name={item.key}
                label={item.key}
                rules={[
                  { required: true, message: `Please enter ${item.key}` },
                ]}
                initialValue={item.value}
              >
                <Input
                  placeholder={`Enter ${item.key}`}
                  className="custom-input"
                  disabled={isViewMode}
                />
              </Form.Item>
            );
          })}

        {isAdd && (
          <Form.Item
            initialValue={true}
            name="status"
            label="Status"
            style={{ color: "green" }}
          >
            <Switch
              defaultChecked
              style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
            />
          </Form.Item>
        )}

        <Form.Item style={{ marginTop: "50px" }}>
          <Space className="buttons">
            <Button
              className="dynamic-btn-primary"
              style={btnPrimaryStyles}
              size="large"
              //   loading={loading}
              hidden={isViewMode}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Space>
        </Form.Item>
      </>
    );
  };

  return (
    <div className="content-box mt-20">
      <Form
        size="large"
        layout="vertical"
        form={addLenderForm}
        autoComplete="off"
        onFinish={addLender}
      >
        <Row gutter={[48, 16]}>
          <Col span={14}>
            <div style={{ display: "flex", gap: "10px" }}>
              <Form.Item
                className="field-bg-border bg-disabled"
                name="bankName"
                label="Insurer Name"
                style={{ flex: 1 }}
              >
                <Text>{lenderData.name}</Text>
              </Form.Item>
              <Form.Item
                className="field-bg-border bg-disabled"
                name="product"
                label="Product"
                style={{ flex: 1 }}
              >
                <Text>{lenderData.product}</Text>
              </Form.Item>
            </div>

            <Card className="Added-Product-List">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title level={3}>Product Plans</Title>
                <Button
                  className="dynamic-btn-primary"
                  style={btnPrimaryStyles}
                  size="middle"
                  type="primary"
                  hidden={isViewMode}
                  onClick={() => {
                    addLenderForm.resetFields();
                    setIsAdd(true);
                    setShowProductPlan(true);
                    getProductPlanList();
                    getPartnerIdentificationDetails();
                  }}
                >
                  Add
                </Button>
              </div>

              <Table
                scroll={{ y: 300 }}
                bordered={false}
                pagination={false}
                dataSource={lenderData?.productPlanDetails ?? []}
                columns={columns}
              />
            </Card>
          </Col>

          <Col span={10}>{showProductPlan ? renderOther() : null}</Col>
        </Row>
      </Form>
      {loading && <AppLoading />}
    </div>
  );
};

export default EditInsurer;
