import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import './i18n';

import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';

import en_US from 'antd/es/locale/en_US';

import { AuthProvider } from 'shared/contexts/AuthContext';

import LoaderOverlay from 'shared/LoaderOverlay';

import ForgotPassword from "./components/ForgotPassword/ForgotPassword"
import detail from "./components/verifydetail/VerifyDetails"
import Password from "./components/setpassword/SetPassword"
import './App.scss';
import './responsive.scss';
import ProtectedRoute from 'shared/ProtectedRoute';
import Layout from 'components/Layout';
import LoginPage from 'components/login/Login';
import ChangePassword from 'components/ChangePassword/ChangePassword';
import ShareLink from 'shared/components/Lead/ShareLink/ShareLink';
import BorrowerLink from 'shared/components/Lead/ApplicantInformation/BorrowerScreens';
import MoneyWideTemplete from 'components/MoneyWide/templet';
import ConsentHDFC from 'shared/components/ConsentForms/ConsentHDFC';
import MobileLender from 'shared/components/Lead/AdditionalField/MobileLender';
import ABCLSessionThanksScreen from 'shared/components/Lead/AdditionalField/FlowableScreens/ABCLScreens/ABCLSessionThanksScreen';
import MobileRunTime from 'shared/components/LeadNew/LeadDetail/MobileRunTime';
import PrefrConsentScreen from 'shared/components/Lead/AdditionalField/FlowableScreens/PrefrScreens/PrefrConsentScreen';
import { useDispatch } from 'react-redux';
import { setIsMobile } from 'shared/redux/common-reducer';

function App() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    function handleWindowSizeChange() {
        const isMobile = window.innerWidth <= 768;
        dispatch(setIsMobile(isMobile));
    }

    return (
        <ConfigProvider locale={en_US}>
            <AuthProvider>
                {loading ? <LoaderOverlay size={'large'} /> : <Router>
                    <Switch>
                        <Route path={"/mobile-runtype"} component={MobileRunTime} />
                        <Route exact path="/shareLink/:id" component={ShareLink} />
                        <Route exact path="/prefr" component={PrefrConsentScreen} />
                        <Route exact path="/consent-hdfc/:uniqueId" component={ConsentHDFC} />
                        <Route exact path="/consent-abcl/:uniqueId" component={ConsentHDFC} />
                        <Route exact path="/consent/le/:uniqueId" component={ConsentHDFC} />
                        <Route exact path="/consent/le/" component={ConsentHDFC} />
                        <Route exact path="/consent/:uniqueId" component={BorrowerLink} />
                        <Route exact path="/consent/" component={BorrowerLink} />
                        <Route exact path="/ty/:id" component={ABCLSessionThanksScreen} />
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/tnc/:caseID" component={MoneyWideTemplete} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/details" component={detail} />
                        <Route exact path="/password" component={Password} />
                        <Route exact path="/change-password" component={ChangePassword} />
                        <Route path={"/mobile-lender"} component={MobileLender} />
                        <ProtectedRoute path="/" component={Layout} />
                    </Switch>
                </Router>}
            </AuthProvider>
        </ConfigProvider>
    );
}

export default App;
