import { Button, Card, Divider, Form, notification } from 'antd';
import "./borrower.scss";
import scuess from "../../../../assets/images/scuess.svg";
import Logo from "../../../../assets/images/OneInfinityLogo.svg";
import season_expried from "../../../../assets/images/season-Expried.svg";
import PageNotFound from "../../../../assets/images/pageNotFound.svg"
import React, { useEffect, useState } from 'react';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import API_SERVICE from 'shared/services/api-service';
import { useParams } from 'react-router-dom';
import TermsDetails from './termsDetails';

const BorrowerScreen: React.FC = () => {

    const [isThankYou, setIsThankYou] = useState(false);
    const [showMobileView, setShowMobileView] = useState(false);
    const [checkLinkExpried, setCheckLinkExpried] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [ip, setIP] = useState("");
    const [tenantName, setTenantName] = useState(""); 
    const [consentId, setConsentId] = useState(""); 

    let { uniqueId } = useParams() as any;

    useEffect(() => {
        console.log("Borrower: " );
        let searchParams = new URL(window.location.href).searchParams;
        const urlParams: any = {};
        searchParams?.forEach((value, key) => {
            urlParams[key] = value;
        });
        const cid = urlParams?.id || uniqueId;
        setConsentId(cid);

        getIPData();
        checkExpireyLink(cid);
        handleWindowSizeChange();
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);
    
    const getIPData = async () => {    
        API_SERVICE.getIPData()
            .then(({ data }) => setIP(data.ip))
            .catch((e: any) => notification.error({ message: API_SERVICE.handleErrors(e) }));
    };

    function handleWindowSizeChange() {
        const isMobile = window.innerWidth <= 768
        setShowMobileView(isMobile);
    }

    const checkExpireyLink = (cid) => {
        const id = {
          uniqueId: cid,
        };

        API_SERVICE.checkExpireyLink(id)
            .then(({ data }) => {
                setCheckLinkExpried(data?.payload?.isLinkExpired);
                setIsThankYou(data?.payload?.isLinkUsed)
                setTenantName(data?.payload?.tenantName)
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
                console.log(e.response.data.payload.errorMessage)
                setPageNotFound(true);
            })
    }

    const updateConsent = () => {
        setIsDisable(true);
        const tcMessage = `I agree to share my information with ${tenantName}`;

        const id = {
            uniqueId: consentId,
            consentAdditionalInfo: {
                tcMessage: tcMessage,
                ipAddress: ip,
                deviceType: window.navigator.platform,
                
              },
        }

        API_SERVICE.updateConsent(id)
            .then(({ data }) => {
                if (data) {
                    setIsThankYou(data?.payload)
                }
            }).catch((e: any) => {
                if(e?.response?.data?.statusCode == 500) {
                    setIsThankYou(true)
                } else {
                    notification.error({ message: API_SERVICE.handleErrors(e) })
                }
            })
    }

    const renderConsentForm = () => {
        return (
            <Form
                name="borrowerForm"
                onFinish={updateConsent}
            >
                <FormCheckbox
                    name={"verifyTerms"}
                    isRequired
                    msgRequired="terms & condition"
                    textElement={
                        <div>
                            I agree to share my information with Infinityflo Private Limited and {tenantName}
                        </div>
                    }
                />
                <Form.Item>
                    <Button
                        type='primary'
                        className='next-btn'
                        htmlType='submit'
                        disabled={isDisable}
                    >
                        I Agree
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const renderTermsConditions = () => {
        return (
            <>
                <p style={{ fontSize: "12px", marginTop: "3px" }}>
                    PLEASE READ THESE TERMS AND CONDITIONS ("TERMS") CAREFULLY. THESE TERMS FORM A LEGAL AGREEMENT
                    BETWEEN INFINITYFLO PRIVATE LIMITED, {tenantName.toUpperCase()} AND YOU. BY CLICKING ON "I ACCEPT" OR
                    "I AGREE" OR BY DOWNLOADING, INSTALLING OR OTHERWISE USING THE SERVICES,
                    YOU ACCEPT AND AGREE TO THE TERMS CONTAINED HEREIN. IF YOU DO NOT AGREE TO THESE TERMS,
                    DO NOT CLICK "I ACCEPT" OR "I AGREE" OR DOWNLOAD, INSTALL OR USE THE SERVICES.
                </p>
                <TermsDetails />
            </>
        )
    }

    const renderThankYou = () => {
        return (
            <div className='borrower-details'>
                <div style={{ marginBottom: "10px" }}>
                    <img src={scuess} alt="" />
                </div>
                <p className='confirm-text'>Thank You!</p>
                <p >
                    Verification done successfully.
                    Progress of your application will be
                    communicated to you shortly.
                </p>
            </div>

        )
    }

    const renderExpiredView = () => {

        return (
            <div className='borrower-details'>
                <div style={{ marginBottom: "10px" }}>
                    <img src={season_expried} alt='season Expried' width='100' />
                    <p className='expired-text'>Link Expired</p>
                </div>
                <p>Oops, this link has expired! Please contact your relationship manager for a new link.</p>
            </div>
        )
    }


    return (
        <>

            <div className='borrower-screen'>

                {!showMobileView ? (
                    <>
                        {pageNotFound ? (
                            <img
                                src={PageNotFound}
                                className='page-img'
                            />
                        ) : (
                            <>
                                <Card bordered={false} className='borrower-card'>
                                    {checkLinkExpried ? (
                                        renderExpiredView()
                                    ) : (
                                        <>
                                            {!isThankYou ? (
                                                <>
                                                    <img src={Logo} alt="Logo" width="100" />
                                                    <div>
                                                        <p style={{ fontWeight: "bold", marginTop: "5px" }}>Terms & Conditions</p>
                                                        <div className="terms-conditions ">
                                                            {renderTermsConditions()}
                                                        </div>
                                                        <Divider />
                                                        {renderConsentForm()}
                                                    </div>
                                                </>
                                            ) : (
                                                renderThankYou()
                                            )}
                                        </>
                                    )}
                                </Card>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {pageNotFound ? (
                            <img
                                src={PageNotFound}
                                className='page-img img-width'
                            />
                        ) : (
                            <>
                                {checkLinkExpried ? (
                                    renderExpiredView()
                                ) : (
                                    <>
                                        {!isThankYou ? (
                                            <>
                                                <div style={{ padding: "10px" }}>
                                                    <img src={Logo} alt="Logo" width="100" />
                                                    <p style={{ fontWeight: "bold", marginTop: "5px" }}>Terms & Conditions</p>
                                                    <div className="terms-conditions mobile-view">
                                                        {renderTermsConditions()}
                                                    </div>
                                                    <Divider />
                                                    {renderConsentForm()}
                                                </div>
                                            </>
                                        ) : (
                                            renderThankYou()
                                        )}
                                    </>
                                )}
                            </>
                        )}


                    </>
                )}
            </div>
        </>

    );
};

export default BorrowerScreen;