import { Button, Col, Form, Input, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import debounce from "lodash/debounce";
import { FaKey, FaSearch } from "react-icons/fa";
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { useDispatch } from "react-redux/es/exports";
import "./permissions.scss";
import { setPermissionPageNo, setPermissionPageSize, setPermissionSearchParam } from "shared/redux/permissions-reducer";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as IconKey} from "../../assets/images/key.svg";
import { setScreenHeader } from "shared/redux/common-reducer";

const { Title } = Typography;

const Permissions: React.FC = () => {
  const history = useHistory();
  const [allUsers, setAllUsers] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  //const [searchValue, setSearchValue] = useState(null as any);
  //const [currentPage, setCurrentPage] = useState(1);
  //const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState();
  const [form] = Form.useForm();

  const permission: any = useSelector((state: RootState) => state.permission);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: ["fullName"],
      key: "name",
    },
    {
      title: "Mobile No.",
      dataIndex: ["phoneNumber"],
      key: "phoneNumber",
    },
    {
      title: "Email",
      dataIndex: ["emailId"],
      key: "email",
    },
    {
      title: "Role",
      dataIndex: ["role"],
      key: "role",
    },
    {
      title: "Active",
      dataIndex: ["active"],
      key: "active",
      render: (_, record) => (record.active ? "Active" : "InActive"),
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: 'center',
      width: 70,
      render: (_: any, record: any, i: any) => (
        <Button
          key={i}
          size="small"
          type="primary"
          shape="circle"
          className="custome-btn-icon"
          style={{'--bgColor': appColors?.appPrimaryColor ?? ""} as any}
          onClick={() => editPermission(record)}
          icon={<IconKey />}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Manage Permissions"}));
    form.setFieldsValue({
      searchBox: permission.searchParam
    })
    getUsersList(permission.pageNo, permission.pageSize, permission.searchParam);
  }, []);

  const getUsersList = (pageNo = 1, pageSize: any = null, searchVal: any = null) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, searchParam: searchVal };
    API_SERVICE.getUsersPermissionList(params)
      .then(({ data }: any) => {
        if (data.payload) {
          setAllUsers(data.payload.content);
          setTotalCount(data.payload.totalElements);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editPermission = (item) => {
    history.push({
      pathname: `permissions/edit-permission/${item.userId}`,
      state: { fullName: item.fullName },
    });
  };

  const searchHandler = (event) => {
    //setCurrentPage(1);
    //setSearchValue(event.target.value);
    dispatch(setPermissionPageNo(1));
    dispatch(setPermissionSearchParam(event.target.value))
    getUsersList(1, permission.pageSize, event.target.value);
  };

  const handlePaginationChange = (pagination) => {
    let page = (pagination?.pageSize != permission.pageSize) ? 1 : pagination.current;
    //setCurrentPage(page);

    //setPageSize(pagination.pageSize);
    dispatch(setPermissionPageNo(page));
    dispatch(setPermissionPageSize(pagination.pageSize));
    setTotal(pagination.total);
    getUsersList(page, pagination.pageSize, permission.searchParam);
  };

  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

  return (
    <div className="permission-wrapper content-box" >
      <Form form={form}>
        <Row>
          <Col className="filter-bar" span={20}>
            <Form.Item name="searchBox">
              <Input
                size="large"
                className="search-box"
                placeholder="Search"
                onChange={debounce(searchHandler, 600)}
                allowClear
                prefix={<FaSearch />}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button
              style={{ marginTop: "30px" }}
              type="primary"
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: ""
                })
                //setSearchValue("");
                dispatch(setPermissionSearchParam(""));
                dispatch(setPermissionPageNo(1));
                getUsersList(1, permission.pageSize, "");
              }}
              shape="round"
              //icon={<ReloadOutlined />}
              size={"middle"}
            > <img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
          </Col>

        </Row>
      </Form>
      {allUsers.length > 0 && (
        <Table
          className="dynamic-pagination-checkbox"
          style={customStyles}
          loading={loading}
          columns={columns}
          dataSource={allUsers}
          scroll={{ x: 'max-content' }}
          size="small"
          onChange={handlePaginationChange}
          pagination={{
            current: permission.pageNo,
            pageSize: permission.pageSize,
            total: totalCount,
          }}
        />
      )}
    </div>
  );
};

export default Permissions;
