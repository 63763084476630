import React from "react";

import EmployeePage from "shared/components/Employees/Employees";

type Props = {};

const Employees: React.FunctionComponent<Props> = () => {
  return <EmployeePage />;
};

export default Employees;
