import { Button, Col, Modal, notification, Row, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import API_SERVICE from "shared/services/api-service";
import { useState } from "react";
import { RWebShare } from 'react-web-share';
import { Image } from 'antd';
import 'antd/dist/antd.css';
import { async } from "@firebase/util";
// import { FacebookShareButton } from "react-share";
// import FacebookIcon from "react-share/lib/FacebookIcon";
// import WhatsappShareButton from "react-share/lib/WhatsappShareButton";
// import WhatsappIcon from "react-share/lib/WhatsappIcon";
// import EmailShareButton from "react-share/lib/EmailShareButton";
// import EmailIcon from "react-share/lib/EmailIcon";
// import { getShareUrl, SocialPlatforms } from "@phntms/react-share";
import Compressor from 'compressorjs';

const { Title, Text } = Typography;
type Props = {};


const ViewGreetingCardDetails: React.FunctionComponent<Props> = () => {

    let { id } = useParams() as any;
    const [greetingData, setGreetingData] = useState({} as any);
    const [visible, setVisible] = useState(false);
    const [shareFileList, setShareFileList] = useState({} as any);
    const [documentImage, setDocumentImage] = useState([] as any);
    const [imageUrl, setImageUrl] = useState([] as any);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const site = [
        "whatsapp",
        "twitter",
        "facebook"
    ];

    useEffect(() => {
        getGreetingById();
    }, []);

    const getGreetingById = () => {

        API_SERVICE.getBusinessid(id)
            .then(({ data }) => {
                if (data?.payload) {
                    setGreetingData(data?.payload);
                    setDocumentImage(data?.payload?.documentDetails);
                    if (data?.payload?.documentDetails?.length != 0) {
                        data?.payload?.documentDetails?.map((data) => {
                            let type = "image/" + data?.fileExtn
                            let name = data?.documentName

                            const blob = new Blob([data?.documentDetails], { type: type });
                            const file = new File([blob], name);

                            let url = URL.createObjectURL(file);
                            let imgUrl = url.split("blob:");

                            setImageUrl(file)

                        });
                    }
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => { });
    };

    const handleSharing = () => {
        // if (documentImage?.length != 0) {
        //     documentImage?.map((data) => {
        //         setShareFileList(data);

        //     });
        // }


        // if (shareFileList) {
        //     let res = fetch(shareFileList?.documentDetails);
        //     let type = "image/" + shareFileList?.fileExtn
        //     let name = shareFileList?.documentName
        //     const blob = new Blob([shareFileList?.documentDetails], { type: type });
        //     const file = new File([blob], name);
        //     setImageUrl(file)
        //     new Compressor(file, {
        //         quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        //         success: (compressedResult) => {
        //         }
        //     })
        // }
    }

    const handleShareImage = () => {
        setIsModalOpen(true);
        setShowPreview(true);
    }

    const handleCancelShare = () => {
        setIsModalOpen(false);
    }

    const handleImageShareWhatsapp = async () => {

    }

    const shareData = {

        url: "https://images.app.goo.gl/cj81w7euvXFxSCFu8",
        text: greetingData?.title,
    }

    return (
        <>
            <Title level={3}>
                <Link className="link" to={"/greeting-cards"}>
                    Greeting Crads
                </Link>{" "}
                &nbsp; &gt; &nbsp; View Greeting Card
            </Title>

            <Row>
                <Col span={12}>
                    <div>
                        {greetingData?.documentDetails?.map((file, index) => {
                            return (<>

                                <Image src=
                                    {
                                        "data:image/" +
                                        file?.fileExtn +
                                        ";base64," +
                                        file?.documentDetails
                                    } alt="icons" key={index}
                                    style={{ height: "100px", width: "100px", marginRight: "10px", marginTop: "10px" }}
                                    onClick={() => setVisible(true)}
                                    preview={false}

                                />
                            </>
                            )
                        })}
                    </div>
                    <div>
                        {visible &&
                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                                    {greetingData?.documentDetails?.map((file, index) => {
                                        return (<>
                                            <Image src=
                                                {
                                                    "data:image/" +
                                                    file?.fileExtn +
                                                    ";base64," +
                                                    file?.documentDetails
                                                }

                                            />
                                        </>
                                        )
                                    })}
                                </Image.PreviewGroup>
                            </div>
                        }
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ marginLeft: "40px" }}>
                        <div style={{ marginTop: "20px" }}>
                            <Text>{greetingData?.title}</Text>
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <RWebShare
                                data={shareData}
                                sites={site}
                                onClick={handleSharing}
                            >
                                <Button type='primary'>Share</Button>

                            </RWebShare>
                        </div>
                        {/* <div style={{ marginTop: "20px" }}><Button type='primary' onClick={handleShareImage}>Share</Button></div> */}
                    </div>
                </Col>
            </Row>

            <Modal
                style={{ backgroundColor: "transparent" }}
                footer={false}
                visible={isModalOpen}
                onOk={() => { }}
                onCancel={handleCancelShare}
            >
                <div>
                    {showPreview ? (greetingData?.documentDetails?.map((file, index) => {
                        return (<>

                            <Image src=
                                {
                                    "data:image/" +
                                    file?.fileExtn +
                                    ";base64," +
                                    file?.documentDetails
                                } alt="icons" key={index}
                                style={{ height: "100px", width: "100px", marginRight: "10px", marginTop: "10px" }}
                                onClick={() => setVisible(true)}
                                preview={false}

                            />
                        </>
                        )
                    })) : null}
                </div>
                <div>

                    {/* <FacebookShareButton
                        url="https://www.facebook.com/"
                    >

                        <FacebookIcon size={40} round={true}></FacebookIcon>
                    </FacebookShareButton>

                    <WhatsappShareButton url="https://images.app.goo.gl/Diwalicj81w7euvXFxSCFu8" onClick={handleImageShareWhatsapp}>
                        <WhatsappIcon size={40} round={true}></WhatsappIcon>
                    </WhatsappShareButton>

                    <EmailShareButton url="https://mail.google.com/">
                        <EmailIcon size={40} round={true}></EmailIcon>
                    </EmailShareButton> */}

                </div>
            </Modal>


        </>
    )
}

export default ViewGreetingCardDetails;