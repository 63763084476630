import { Button, Collapse, List, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import LenderDisbursed from "./LenderDisbursed";
import copy from "../../../../../assets/images/copy.svg";
import { ReactComponent as IconApprove } from "../../../../../assets/images/apporveIcon.svg";
import shareIcon from "../../../../../assets/images/shareIcon.svg";
import iconCopy from "../../../../../assets/images/copy.svg";
import { ReactComponent as IconDownload } from "../../../../../assets/images/download-border.svg";
import { ReactComponent as IconFileBorder } from "../../../../../assets/images/file-border.svg";
import API_SERVICE from "shared/services/api-service";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import DocumentStatus from "shared/components/Lead/RequiredDocuments/DocumentStatus";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface ILenderDecisionMain {
  setIsLoading: any;
  leadData: any;
  selectedLenderList: any;
  lenderDisbursedHandler: any;
  getRedirectURLLink: any;
  getDownloadLetterForICICI: any;
  loadingUpdate: any;
  getCashETriggerSMSLink?: any;
  selectedLenderName: any;
}

const { Title } = Typography;

const LenderDisbursedMain: React.FC<ILenderDecisionMain> = (
  props: ILenderDecisionMain
) => {
  const {
    setIsLoading,
    leadData,
    selectedLenderList,
    lenderDisbursedHandler,
    getRedirectURLLink,
    getDownloadLetterForICICI,
    loadingUpdate,
    getCashETriggerSMSLink,
    selectedLenderName,
  } = props;
  const { Panel } = Collapse;
  const [documentList, setDocumentList] = useState([]);
  const findSelected =
    leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
      (item) => item?.lenderName == selectedLenderName
    );
  const [selectedLender, setSelectedLender] = useState({} as any);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [showDocumentStatus, setShowDocumentStatus] = useState(false);
  const [lenderRequestPayload, setLenderRequestPayload] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    getLenderRequestPayload();
    getAllDocumentList();
  }, []);

  const getAllDocumentList = () => {
    const submissionDetails =
      findSelected?.documentProgressDetails?.submissionDetails;
    if ((submissionDetails?.length ?? 0) > 0) {
      setDocumentList(submissionDetails);
      return;
    }
    setIsLoading(true);
    API_SERVICE.listDocumentV2(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          const listDoc = [] as any;
          data?.payload?.documentConfigDetails?.map((docCon: any) => {
            docCon?.documentTypeWithUploadedDetails?.map((doc: any) => {
              if (doc?.documentDetails) {
                listDoc?.push(doc?.documentDetails);
              }
            });
          });
          setDocumentList(listDoc);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const getLenderRequestPayload = () => {
    setIsLoading(true);
    API_SERVICE.getLenderRequestPayload(
      leadData?.caseDetails?.caseId,
      findSelected?.lenderInfoId
    )
      .then(({ data }) => {
        const map = data?.payload?.map((item: any) => {
          return JSON.parse(item);
        });
        setLenderRequestPayload(map);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const downloadDocument = (item: any) => {
    setIsLoading(true);
    API_SERVICE.downloadDocument(
      item?.businessId,
      item?.documentId ?? item?.businessDocumentId
    )
      .then(({ data }) => {
        let bytesData = base64ToArrayBuffer(data.payload.documentDetails);
        saveByteArray([bytesData], data.payload.documentName);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const renderObject = (item: any) => {
    if (item == null) {
      return <></>;
    }

    const keys = Object.keys(item);
    return keys?.map((key: any, index: any) => {
      let res = item[key];
      if (["string", "number", "boolean"].includes(typeof res)) {
        // console.log("key --- : ", key + ": " + res);
        return <span style={{ overflowWrap: "break-word" }}>{`${key}: ${res}`}</span>;
      } else if (
        [
          "conditionMethodCalculationCollection",
          "evaluatedmonthlyemiresponse",
        ].includes(key)
      ) {
        return <></>;
      } else {
        return renderObject(res);
      }
    });
  };

  const renderDetails = (list: any) => {
    const listTmp = list?.map((item: any) => {
      return renderObject(item);
    });

    // console.log("listTmp: ", listTmp)

    // const tmp = [] as any;
    // listTmp?.map((item: any) => {
    //   tmp.push(...item)
    // })

    // console.log("tmp: ", tmp)

    if ((listTmp?.at(0)?.at(0)?.length ?? 0) > 0) {
      return listTmp?.at(0)?.at(0);
    } else {
      return listTmp?.at(0);
    }
  };

  const renderConsentDetails = () => {
    let consentDetails: any = {};
    if ((findSelected?.consentDetails?.length ?? 0) > 0) {
      consentDetails = JSON.parse(findSelected?.consentDetails ?? {});
    }
    return (
      <>
        {Object.keys(consentDetails).length > 0 && (
          <span className="lender-additional-details" style={{ "--appSecondary": appColors?.appSecondaryColor ?? "" } as any}>
            <IconApprove /> Consent
          </span>
        )}
      </>
    );
  };

  const renderDocumentStatus = (lender) => {
    const total = lender?.documentProgressDetails?.totalNumberOfDocs ?? 0;
    const submitted =
      lender?.documentProgressDetails?.documentsSuccessfullySubmitted ?? 0;
    const failed = lender?.documentProgressDetails?.failure ?? 0;
    return (
      <>
        {total > 0 && (submitted > 0 || failed > 0) && (
          <span
            style={{ cursor: "pointer", fontSize: "12px", float: "right" }}
            onClick={() => {
              setDocumentDetails(
                lender?.documentProgressDetails?.submissionDetails
              );
              setSelectedLender(lender);
              setShowDocumentStatus(true);
            }}
          >
            Document Status: <br />
            <span style={{ fontSize: "13px" }}>
              {submitted}/{total}
            </span>
          </span>
        )}
      </>
    );
  };

  const isLenderLogged = (findLender) => {
    const isDocUploaded =
      findLender?.submissionType != "API" ||
      (findLender?.submissionType == "API" &&
        findLender?.allDocumentUploaded) ||
      ["REJECTED", "WITHDRAWN", "PENDENCY"].includes(findLender?.status);
    const isLogged =
      isDocUploaded &&
      [
        "LOGGED",
        "SANCTIONED",
        "DISBURSED"
      ].includes(findLender?.status)
    return isLogged;
  }
  const renderRedirectLink = () => {
    return (
      <>
        {findSelected?.submissionType?.includes("API") &&
          (findSelected?.lenderApplicationId?.length ?? 0) > 0 && (
            <span
              className="flex"
              style={{ display: "flex", marginTop: "5px" }}
            >
              {(["BU9709497975", "BU3620294738"].includes(
                findSelected.lenderInfoId
              ) ||
                findSelected?.lenderName?.toLowerCase()?.includes("fibe") ||
                findSelected?.lenderName
                  ?.toLowerCase()
                  ?.includes("moneywide") ||
                findSelected?.lenderName?.toLowerCase()?.includes("cashe") ||
                findSelected?.lenderName
                  ?.toLowerCase()
                  ?.includes("kreditbee") ||
                findSelected?.lenderName
                  .toLowerCase()
                  .includes("nira finance") ||
                findSelected?.lenderName?.toLowerCase()?.includes("abcfl") ||
                findSelected?.lenderName?.toLowerCase()?.includes("abcl") ||
                findSelected?.lenderName?.toLowerCase()?.includes("prefr") ||
                findSelected?.lenderName?.toLowerCase()?.includes("incred") ||
                findSelected?.lenderName
                  ?.toLowerCase()
                  ?.includes("credilio")) && isLenderLogged(findSelected) && (
                  <>
                    <a
                      onClick={() =>
                        getRedirectURLLink(
                          findSelected?.lenderInfoId,
                          leadData?.loanProductId,
                          findSelected?.lenderApplicationId
                        )
                      }
                      className="small"
                      style={{ fontSize: "12px" }}
                    >
                      {`Lender Link`}
                    </a>
                    <Button
                      size="small"
                      style={{ margin: "2px", border: "none" }}
                      onClick={() =>
                        getRedirectURLLink(
                          findSelected?.lenderInfoId,
                          leadData?.loanProductId,
                          findSelected?.lenderApplicationId,
                          true
                        )
                      }
                      icon={
                        <img
                          src={shareIcon}
                          alt=""
                          width={18}
                          height={18}
                          style={{ marginTop: "-4px" }}
                        />
                      }
                    />
                  </>
                )}
            </span>
          )}
      </>
    );
  };

  const renderLenderLoanID = () => {
    return (
      <>
        {findSelected?.submissionType?.includes("API") &&
          (findSelected?.lenderApplicationId?.length ?? 0) > 0 && (
            <div style={{ display: "grid" }}>
              <span style={{ fontSize: "12px" }}>Lender Loan ID</span>
              <span style={{ fontSize: "11px", float: "left" }}>
                {`${findSelected.lenderApplicationId}`}{" "}
                <img
                  src={copy}
                  alt=""
                  width={11}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      findSelected?.lenderApplicationId
                    )
                  }
                />
              </span>
            </div>
          )}
      </>
    );
  };

  const renderILanceId = () => {
    return (
      <>
        {findSelected?.submissionType?.includes("API") &&
          findSelected?.additionalFields?.ilensId?.length > 0 && (
            <div
              style={{
                display: "grid",
              }}
            >
              <span style={{ fontWeight: "300" }}>iLens ID </span>
              <span style={{ fontSize: "11px", float: "left" }}>
                {`${JSON.parse(findSelected?.additionalFields)?.ilensId}`}
                <Button
                  style={{
                    borderColor: "transparent",
                    background: "transparent",
                    fontSize: "12px",
                  }}
                  size="small"
                  shape="circle"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      JSON.parse(findSelected?.additionalFields)?.ilensId ?? ""
                    );
                    notification.success({ message: "Copied to clipboard" });
                  }}
                  icon={<img src={iconCopy} alt="" width={14} height={14} />}
                />
              </span>
            </div>
          )}
      </>
    );
  };

  return (
    <>
      <div>
        {showDocumentStatus && (
          <DocumentStatus
            showDocumentStatus={showDocumentStatus}
            setShowDocumentStatus={setShowDocumentStatus}
            lenderInfoId={selectedLender?.lenderInfoId}
            caseId={leadData?.caseDetails?.caseId}
            documentDetails={documentDetails}
          />
        )}
        <div
          style={{
            padding: "10px",
            display: "flex",
            gap: "100px",
            background: "#bfbfbf1c",
          }}
        >
          <div style={{ display: "grid" }}>
            <Title level={5}>{findSelected?.lenderName}</Title>
            <div
              style={{
                color: "#20A74E",
                gap: "10px",
                display: "flex",
                justifyContent: "left",
              }}
            >
              {(findSelected?.lenderSubmissionType == "API" ||
                findSelected?.submissionType == "API") && (
                  <span className="lender-additional-details" style={{ "--appSecondary": appColors?.appSecondaryColor ?? "" } as any}>
                    <IconApprove /> API
                  </span>
                )}
              {renderConsentDetails()}
            </div>
            {renderRedirectLink()}
          </div>
          {renderLenderLoanID()}
          {renderILanceId()}
          {renderDocumentStatus(findSelected)}
        </div>
        <div style={{ paddingInline: "10px", marginTop: "10px" }}>
          <Collapse style={{ borderRadius: "6px" }} expandIconPosition="end">
            <Panel header="Lead Details" key="1">
              <List
                className="leadDisburse-Detail-scroll"
                grid={{ gutter: 7, column: 3, xs: 1 }}
                dataSource={renderDetails(lenderRequestPayload)}
                renderItem={(item: any) => <List.Item>{item}</List.Item>}
              />
            </Panel>
          </Collapse>

          <Collapse
            style={{ borderRadius: "6px", marginTop: "12px" }}
            expandIconPosition="end"
          >
            <Panel header="Document Details" key="1">
              {documentList?.map((item: any, index: number) => {
                return (
                  <div
                    className="leadDisburse-Detail-scroll"
                    key={`${item?.documentId}-${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="custome-btn-icon">
                      <IconFileBorder style={{ '--bgColor': appColors?.appPrimaryColor ?? '' } as any} />{" "}
                      {item?.documentName}
                    </span>
                    <Button
                      className="custome-btn-icon-secondary-fill"
                      style={{ '--bgColor': appColors?.appSecondaryColor ?? "" } as any}
                      type="text"
                      icon={<IconDownload />}
                      onClick={() => downloadDocument(item)}
                    />
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <LenderDisbursed
          setIsLoading={setIsLoading}
          leadData={leadData}
          assignedLenders={selectedLenderList}
          lenderDisbursedHandler={lenderDisbursedHandler}
          getRedirectURLLink={getRedirectURLLink}
          getDownloadLetterForICICI={getDownloadLetterForICICI}
          loadingUpdate={loadingUpdate}
          getCashETriggerSMSLink={getCashETriggerSMSLink}
          selectedLenderName={selectedLenderName}
        />
      </div>
    </>
  );
};
export default LenderDisbursedMain;
