import React from "react";
import { Button, Modal, notification, Divider } from "antd";
import iconCopy from "../../../../assets/images/CopyIcon.svg";

interface IErrorPopup {
  setShowErrorModel: any;
  isShowErrorModel: any;
  errorMsg: any;
}

const ErrorPopup: React.FunctionComponent<IErrorPopup> = ({
  setShowErrorModel,
  isShowErrorModel,
  errorMsg,
}) => {
  return (
    <Modal
      title="API Response"
      centered
      width={350}
      onCancel={() => setShowErrorModel(false)}
      footer={null}
      visible={isShowErrorModel}
    >
      <div>
        <div style={{ paddingBlock: "10px" }}>{errorMsg}</div>
        <Divider
          style={{
            margin: "5px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            paddingBlock: "8px",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            htmlType="button"
            style={{
              paddingInline: "20px",
              borderRadius: "5px",
            }}
            onClick={() => setShowErrorModel(false)}
          >
            Close
          </Button>
          <Button
            type="primary"
            style={{ borderRadius: "5px" }}
            onClick={() => {
              navigator.clipboard.writeText(errorMsg);
              notification.success({
                message: "Response message copied successfully",
              });
            }}
          >
            <img
              src={iconCopy}
              width="18px"
              height="18px"
              alt=""
              style={{ marginBottom: "3px", marginRight: "3px" }}
            />
            Copy
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorPopup;
