import {
  Button,
  Divider,
  Modal,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import "antd/dist/antd.css";
import API_SERVICE from "shared/services/api-service";
import { useState } from "react";
import { ReactComponent as IconEyeBorder } from "../../../assets/images/eye_border.svg";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import blackRefreshIcon from "../../../assets/images/blackReferesh.svg";
import { GrCircleInformation } from "react-icons/gr"
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
import {
  setBulkUpdatePageNo,
  setBulkUpdatePageSize,
} from "shared/redux/bulkUpdate-reducer";

const { Title, Text } = Typography;
type Props = {};

const BulkUpdateImport: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [bulkUploadList, setBulkUploadList] = useState([] as any);
  const [bulkModalVisible, setBulkModalVisible] = useState(false);
  const [failedData, setFaildedData] = useState({} as any);
  const [data, setData] = useState([] as any);
  const [successData, setSuccessData] = useState([] as any);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const { pageNo, pageSize } = useSelector(
    (state: RootState) => state.bulkUpdate
  );

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Lead",
        backScreenPath: "/lead",
        screenTitle: "Update Status Lead",
      })
    );
    getBulkUploadList();
  }, []);

  const getBulkUploadList = (pageNo: number = 1, pageSize: number = 10) => {
    var params = { pageNo: pageNo, pageSize: pageSize, type: "UPDATE_LEAD" };
    setLoading(true);
    API_SERVICE.getBulkOperationList(params)
      .then(({ data }) => {
        setBulkUploadList(data?.payload?.content ?? []);
        setTotalRecords(data?.payload?.totalElements ?? 0);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Uploaded Id",
      dataIndex: "uploadId",
      key: "uploadId",
    },
    {
      title: "File Name",
      dataIndex: "uploadedFileName",
      key: "uploadedFileName",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "date",
      render: (creationDate: any, record: any) => (
        <span>
          {moment(new Date(creationDate))
            .locale("en")
            .format("DD/MM/YYYY, hh:mm A")}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "Action",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Button
            className="custome-btn-icon"
            style={customStyles}
            size="small"
            type="text"
            icon={<IconEyeBorder />}
            onClick={() => viewModel(record)}
          />
        </Space>
      ),
    },
  ];

  const viewModel = (record) => {
    setData(record?.bulkUploadReponsePayloadBean?.responsePayload?.FAILED);
    setSuccessData(
      record?.bulkUploadReponsePayloadBean?.responsePayload?.SUCCESS
    );
    setFaildedData(record);
    setBulkModalVisible(true);
  };

  const handlePaginationChange = (pagination) => {
    let page = pagination.pageSize !== pageSize ? 1 : pagination.current;
    dispatch(setBulkUpdatePageNo(page));
    dispatch(setBulkUpdatePageSize(pagination.pageSize));
    getBulkUploadList(page, pagination.pageSize);
  };

  const goToStartImportBulkUpload = () => {
    history.push("/lead/bulkUpdate/import-bulkUpdate");
  };

  const customStylesBtnPrimary: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
    marginRight: "10px",
  };
  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };

  const renderStatus = () => {
    const isFailed = failedData?.status == "FAILED";
    return (
      <Modal
        width={"70%"}
        title={
          <div
            style={{
              display: isFailed ? "grid" : "flex",
              gap: isFailed ? "0px" : "15px",
            }}
          >
            <Text style={{ color: isFailed ? "red" : "green" }}>
              <Text>Status : </Text> {failedData?.status}
            </Text>
            <Text style={{ color: isFailed ? "red" : "green" }}>
              <Text>Status description : </Text>
              {failedData?.statusDescription}
            </Text>
          </div>
        }
        visible={bulkModalVisible}
        closable={true}
        footer={false}
        onCancel={() => setBulkModalVisible(false)}
        bodyStyle={{ paddingRight: '0px' }}
      >
        <div style={{ overflow: 'auto', height: '70vh' }}>
          <Title level={4} style={{ marginTop: "10px" }}>
            Success Record
          </Title>
          {(successData?.length ?? 0) == 0 ? (
            <span>No Record Found</span>
          ) : (
            <span>{successData?.join(", ")}</span>
          )}

          <div
            style={{
              borderBottom: "1px solid lightgray",
              marginBlock: "20px",
            }}
          />

          <Title level={4}>
            Failed Record&nbsp;
            {data?.length > 0 && (
              <Tooltip title="The row number displayed on the error screen corresponds directly to the same row in the uploaded Excel sheet for easy identification and correction.">
                <GrCircleInformation style={{ marginBottom: '3px', fontSize: '20px' }} />
              </Tooltip>
            )}
          </Title>

          {(data?.length ?? 0) == 0 ? (
            <div style={{ paddingBlock: "10px" }}>No Record Found</div>
          ) : (
            <>
              <div
                style={{
                  borderBottom: "1px solid lightgray",
                  marginBlock: "1px",
                }}
              />
              {data?.map((item, index) => {
                return (
                  <div
                    style={{
                      borderBottom: "1px solid lightgray",
                      paddingBlock: "5px",
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </>
          )}

          <br />
        </div>
      </Modal>
    );
  };

  return (
    <div className="content-box">
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>

          <Button
            className="bulk-upload-btn dynamic-btn-primary"
            style={customStylesBtnPrimary}
            type="primary"
            onClick={() => goToStartImportBulkUpload()}
          >
            START
          </Button>

          <Button
            className="refresh-button"
            style={{ marginRight: "10px", border: "none" }}
            type="primary"
            onClick={() => getBulkUploadList()}
          >
            <img
              src={blackRefreshIcon}
              alt=""
              style={{ marginLeft: "-10px" }}
            />
          </Button>
        </div>

        <div style={{ marginTop: "20px" }}>
          <div style={{}}>
            <Table
              className="dynamic-pagination-checkbox"
              style={customStyles}
              pagination={{
                current: pageNo,
                pageSize: pageSize,
                total: totalRecords,
              }}
              onChange={handlePaginationChange}
              loading={loading}
              scroll={{ x: "max-content", y: 600 }}
              columns={columns}
              dataSource={bulkUploadList}
            />
          </div>
        </div>
      </div>
      {renderStatus()}
    </div>
  );
};

export default BulkUpdateImport;
