import { Col, Form, Input, notification, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import "./index.scss";
import { useDispatch } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";

const { Title } = Typography;
const ViewOffer: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  let { id } = useParams() as any;

  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Offers",
        backScreenPath: "/offers",
        screenTitle: "View Offer",
      })
    );
    getLeadById();
  }, []);
  const [leadData, setLeadData] = useState(null as any);
  const [mobileOffer, setMobileOfferDocumentDetails] = useState(false);
  const [webOffer, setWebOfferDocumentDetails] = useState(false);

  const [form] = Form.useForm();
  const getLeadById = () => {
    API_SERVICE.getBusinessid(id)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data.payload);
          if (data.payload) {
            form.setFieldsValue({
              title: data.payload.title,
              description: data.payload.description,
            });
          }
          if (data.payload.mobileOfferDocumentDetails.fileExtn !== "") {
            setMobileOfferDocumentDetails(true);
          }
          if (data.payload.webOfferDocumentDetails.fileExtn !== "") {
            setWebOfferDocumentDetails(true);
          }

          if (data?.payload?.caseDetails?.caseHistoryHolder) {
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => { });
  };

  const documentMobile = (leadData?.mobileOfferDocumentDetails?.length ?? 0) > 0 ? leadData.mobileOfferDocumentDetails[0] : {};
  const documentWeb = (leadData?.webOfferDocumentDetails?.length ?? 0) > 0 ? leadData.webOfferDocumentDetails[0] : {};

  return (
    <div className="content-box">
      <Row gutter={16}>
        <Col span={8}>
          {mobileOffer && (
            <>
              <h3>Mobile Offer</h3>
              <img
                src={
                  "data:image/" +
                  documentMobile?.fileExtn +
                  ";base64," +
                  documentMobile?.documentDetails
                }
                width="300px"
                height="150px"
                alt="banner"
              />
            </>
          )}
        </Col>
        <Col span={8}>
          {webOffer && (
            <>
              <h3>Web Offer</h3>
              <img
                src={
                  "data:image/" +
                  documentWeb?.fileExtn +
                  ";base64," +
                  documentWeb?.documentDetails
                }
                width="300px"
                height="150px"
                alt="banner"
              />
            </>
          )}
        </Col>
      </Row>
      <Form
        style={{ marginTop: "2.5%" }}
        layout="vertical"
        autoComplete="off"
        name="leadForm"
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              label="Title"
              name="title"
            >
              <Input
                className="custom-input"
                readOnly
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
            >
              <TextArea
                className="custom-textarea"
                autoSize={{ minRows: 5, maxRows: 8 }}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ViewOffer;
