import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Input,
  Row,
  Col,
  Form,
  Button,
  Table,
  Space,
  notification,
  Tooltip,
  DatePicker,
  Modal,
  Upload,
  UploadProps,
} from "antd";
import {
  FaSearch,
} from "react-icons/fa";
import {ReactComponent as AddFileGray} from "../../assets/images/upload-file-gray.svg";
import { GrCircleInformation } from "react-icons/gr"
import API_SERVICE from "shared/services/api-service";
import debounce from "lodash/debounce";
import { saveByteArray } from "shared/services/Utility";
import CheckableTag from "antd/lib/tag/CheckableTag";
import type { ColumnsType } from "antd/es/table";
import iconError from "../../assets/images/error.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setPayoutPageNo, setPayoutPageSize, setPayoutSearchParam, setPayoutStatus } from "shared/redux/payout-reducer";
import moment from "moment";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import { setScreenHeader } from "shared/redux/common-reducer";

const { Title } = Typography;
type Props = {};

const PayoutRequest: React.FunctionComponent<Props> = () => {
  
  const [allConnectors, setAllConnectors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showUplaod, setShowUpload] = useState(false);
  const [showPayoutReqInfo, setShowPayoutReqInfo] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [transferDate, setTransferDate] = useState(null as any);
  const [fileList, setFileList] = useState([] as any);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const payoutReq: any = useSelector((state: RootState) => state.payoutReq);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {appColors}: any = useSelector((state: RootState) => state.common);
  
  const [completeForm] = Form.useForm();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Connector Payout Requests"}));
  }, []);

  const [listCase, setListCase] = useState([
    {
      key: "DISBURSED",
      value: "Case Disbursed",
      total: "",
    },
    {
      key: "REQUESTED",
      value: "Payout Requested",
      total: "",
    },
    {
      key: "APPROVED",
      value: "Payout Approved",
      total: "",
    },
    {
      key: "COMPLETED",
      value: "Payout Completed",
      total: "",
    },
  ]);

  const colCaseId: any = {
    title: "Case ID",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "caseId"
        : ["payoutLenderDetails", "caseId"],
    key: "caseId",
    fixed: "left",
  };

  const colDisbursalId: any = {
    title: "Disbursal ID",
    dataIndex: "disbursalId",
    key: "disbursalId",
    fixed: "left",
  };

  const colPayoutId: any = {
    title: "Payout ID",
    dataIndex: "payoutId",
    key: "payoutId",
  };

  const colConnectorBusinessName: any = {
    title: "Connector Business Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "connectorBusinessName"
        : ["payoutLenderDetails", "connectorBusinessName"],
    key: "connectorBusinessName",
  };

  const colConnectorName: any = {
    title: "Connector Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "connectorName"
        : ["payoutLenderDetails", "connectorName"],
    key: "connectorName",
  };

  const colBorrowerName: any = {
    title: "Borrower Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "borrowerName"
        : ["payoutLenderDetails", "borrowerName"],
    key: "borrowerName",
  };

  const colLenderName: any = {
    title: "Lender Name",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "lenderName"
        : ["payoutLenderDetails", "lenderName"],
    key: "lenderName",
  };

  const colProdcut: any = {
    title: "Product",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "loanProductName"
        : ["payoutLenderDetails", "loanProductName"],
    key: "product",
  };

  const colDisbursementDate: any = {
    title: "Disbursement Date",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "disbursalDate"
        : ["payoutLenderDetails", "disbursedDate"],
    key: "disbursementDate",
  };

  const colRequestedDate: any = {
    title: "Payout Req Date",
    dataIndex: "requestedDate",
    key: "requestedDate",
  };

  const colApprovalDate: any = {
    title: "Payout Approval Date",
    dataIndex: "approvalDate",
    key: "approvalDate",
  };

  const colPaymentDate: any = {
    title: "Payment Date",
    dataIndex: "transferAmountDate",
    key: "transferAmountDate",
  };

  const colDisbursalAmount: any = {
    title: "Disbursal Amount",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "disbursedAmount"
        : ["payoutLenderDetails", "disbursedAmount"],
    key: "disbursalAmount",
  };

  const colInsuranceAmount: any = {
    title: "Insurance Amount ",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "insuranceAmount"
        : ["payoutLenderDetails", "insuranceAmount"],
    key: "insuranceAmount ",
  };

  const colTentativePayoutPercentage: any = {
    title: ["APPROVED", "COMPLETED"].includes(payoutReq.payoutStatus)
      ? "Payout %"
      : "Tentative Payout %",
    dataIndex:
      payoutReq.payoutStatus == "DISBURSED"
        ? "tentativePayoutPercentage"
        : ["monetaryDetails", "payoutPercentage"],
    key: "tentativePayoutPercentage",
  };

  const colAdjustments: any = {
    title: "Adjustments",
    dataIndex: ["monetaryDetails", "adjustmentAmount"],
    key: "adjustments",
  };

  const colTentativeTaxableValue: any = {
    title: ["APPROVED", "COMPLETED"].includes(payoutReq.payoutStatus)
      ? "Taxable Value "
      : "Tentative Taxable Value",
    dataIndex:payoutReq.payoutStatus == "DISBURSED"
        ? "tentativeTaxableValue"
        : ["monetaryDetails", "grossAmount"],
    key: "tentativeTaxableValue",
  };

  const colTds: any = {
    title: "TDS",
    dataIndex: ["monetaryDetails", "tdsAmount"],
    key: "tds",
  };

  const colGst: any = {
    title: "GST",
    dataIndex: ["monetaryDetails", "gstAmount"],
    key: "gst",
  };

  const colNetPayoutAmount: any = {
    title: "Net Amount",
    dataIndex: ["monetaryDetails", "netPayoutAmount"],
    key: "netPayoutAmount",
    render: (_: any, record: any) => {
      return (
        <>
          {record?.monetaryDetails?.netPayoutAmount < 0 ? (
            <span style={{ color: "red" }}>
              {record?.monetaryDetails?.netPayoutAmount}
            </span>
          ) : (
            <span>{record?.monetaryDetails?.netPayoutAmount}</span>
          )}
        </>
      );
    },
  };

  const colComment: any = {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
  };

  const colsalesUserHierarchy: any = {
    title: "Sales User Hierarchy",
    dataIndex: ["payoutLenderDetails", "salesUserHierarchy"],
    key: "salesUserHierarchy",
  };

  const colBankAccNo: any = {
    title: "Bank Acc No.",
    dataIndex: ["payoutLenderDetails", "bankDetails", "accNumber"],
    key: "bankAccNo",
  };

  const colIfsc: any = {
    title: "IFSC",
    dataIndex: ["payoutLenderDetails", "bankDetails", "ifscCode"],
    key: "ifsc",
  };

  const colRectify: any = {
    title: "Rectify",
    dataIndex: "rectify",
    key: "rectify",
    align: "center",
    render: (_: any, record: any) =>
      record?.errorDescription?.length > 0 && (
        <Tooltip placement="topRight" title={record?.errorDescription}>
          <img src={iconError} width="20px" height="20px" alt="" />
        </Tooltip>
      ),
  };


  const columnsDisbursed: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colConnectorBusinessName,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProdcut,
    colDisbursementDate,
    colDisbursalAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colTentativeTaxableValue,
    colRectify,
  ];
  const columnsRequested: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colPayoutId,
    colConnectorBusinessName,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProdcut,
    colDisbursementDate,
    colRequestedDate,
    colDisbursalAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colAdjustments,
    colTentativeTaxableValue,
    colTds,
    colGst,
    colNetPayoutAmount,
    colsalesUserHierarchy,
    colBankAccNo,
    colIfsc,
    colRectify,
  ];
  const columnsApproved: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colPayoutId,
    colConnectorBusinessName,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProdcut,
    colDisbursementDate,
    colRequestedDate,
    colApprovalDate,
    colDisbursalAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colAdjustments,
    colTentativeTaxableValue,
    colTds,
    colGst,
    colNetPayoutAmount,
    colComment,
    colsalesUserHierarchy,
    colBankAccNo,
    colIfsc,
    colRectify,
  ];
  const columnsCompleted: ColumnsType<any> = [
    colCaseId,
    colDisbursalId,
    colPayoutId,
    colConnectorBusinessName,
    colConnectorName,
    colBorrowerName,
    colLenderName,
    colProdcut,
    colDisbursementDate,
    colRequestedDate,
    colApprovalDate,
    colPaymentDate,
    colDisbursalAmount,
    colInsuranceAmount,
    colTentativePayoutPercentage,
    colAdjustments,
    colTentativeTaxableValue,
    colTds,
    colGst,
    colNetPayoutAmount,
    colComment,
    colsalesUserHierarchy,
    colBankAccNo,
    colIfsc,
  ];

  const filterColumns = () => {
    if ("DISBURSED" == payoutReq.payoutStatus) {
      return columnsDisbursed;
    } else if ("REQUESTED" == payoutReq.payoutStatus) {
      return columnsRequested;
    } else if ("APPROVED" == payoutReq.payoutStatus) {
      return columnsApproved;
    } else if ("COMPLETED" == payoutReq.payoutStatus) {
      return columnsCompleted;
    }
  };

  const handlePaginationChange = (pagination) => {
    let page = (pagination?.pageSize != payoutReq.pageSize) ? 1 : pagination.current;
    dispatch(setPayoutPageNo(page));
    dispatch(setPayoutPageSize(pagination.pageSize));
    getPayoutAllList(page, pagination.pageSize, payoutReq.searchParam);
  };

  const getPayoutAllList = (
    pageNo: number = 1,
    pageSize: number = 10,
    searchVal: any = null,
  ) => {
    if (payoutReq.payoutStatus == "DISBURSED") {
      getPayoutCaseDisbursed(pageNo, pageSize, searchVal);
    } else {
      getPayoutList(pageNo, pageSize, searchVal)
    }
  }

  const getPayoutList = (pageNo, pageSize, searchVal) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParam: searchVal,
      payoutStatus: payoutReq.payoutStatus
    }
    setLoading(true);
    API_SERVICE.getPayoutList(params)
      .then(({ data }) => {
        setAllConnectors(data?.payload?.content);
        setTotalCount(data?.payload?.totalElements);
        if ((data?.payload?.content?.length ?? 0) > 0) {
          data.payload.content.map((connector: any) => {
            connector.key = connector.payoutId;
          });
        }
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) })
      }).finally(() => {
        setLoading(false);
      });
  }

  const getPayoutCaseDisbursed = (pageNo, pageSize, searchVal) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParam: searchVal
    };
    setLoading(true);
    API_SERVICE.getPayoutCaseDisbursed(params)
      .then(({ data }) => {
        setAllConnectors(data?.payload?.content);
        setTotalCount(data?.payload?.totalElements);
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) })
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (event: any) => {
    dispatch(setPayoutPageNo(1));
    dispatch(setPayoutSearchParam(event?.target?.value))
    getPayoutAllList(1, payoutReq.pageSize, event.target.value);
  };

  const handleConnectorTagChange = (i: number, value: string) => {
    dispatch(setPayoutPageNo(1));
    dispatch(setPayoutStatus(value));
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize);
    form.setFieldsValue({
      searchBox: null
    })
  }, [payoutReq.payoutStatus]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handlePayoutUpdateStatus = () => {
    let getDate = moment(transferDate).format("YYYY-MM-DD").toString()
    const params: any = [];
    selectedRowKeys.map((item: any) => {
      const tmpobj = {
        "payoutId": item,
        "payoutInfoStatus": payoutReq.payoutStatus == "REQUESTED" ? "APPROVED" : "COMPLETED",
        "transferAmountDate": payoutReq.payoutStatus == "APPROVED" ? getDate == 'Invalid date' ? null : getDate : null
      }
      params.push(tmpobj);
      return tmpobj;
    });
    if (selectedRowKeys.length > 0) {
      setLoading(true);
      API_SERVICE.updatePayoutStatus(params)
        .then(({ data }) => {
          notification.success({ message: data?.message });
          getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize);
          setLoading(false);
          completeForm.resetFields();
          setTransferDate(null)
        }).catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) })
        }).finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Please select at least 1 payout!' })
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
    }),
  };

  const handleDownloadPayoutDetails = async () => {
    const payoutStatus = payoutReq.payoutStatus == "REQUESTED" ? "REQUESTED" : "COMPLETED";
    API_SERVICE.downloadPayoutDetails(payoutStatus, payoutReq?.searchParam)
      .then((data) => {
        const getFileName = data?.request?.getResponseHeader('content-disposition')?.split(";")[1]?.split("=")[1]?.trim() ?? "payout-Requested.xlsx"
        saveByteArray([data?.data], getFileName);
      }).catch(async (e: any) => {
        const text = await new Response(e?.response?.data).text();
        notification.error({ message: API_SERVICE.handleErrors(JSON.parse(text)) });
      })
  }

  const uploadProps: UploadProps = {
    listType: "text",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onUpload = useCallback((acceptFile: any) => {
    setUploadedFile(acceptFile?.file);
  }, []);
  const entityType = "PAYOUT_MONETARY_UPLOAD";
  const uploadedDocumentFile = () => {
    const formData = new FormData();
    formData.append("file", uploadedFile)
    if (fileList.length > 0) {
      setLoading(true)
      API_SERVICE.bulkUploadStart(entityType, formData)
        .then(({ data }) => {
          setFileList([]);
          notification.success({ message: data?.message });
          handleBulkUploadExecuteFile(data?.payload?.uploadId)
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        }).finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: 'Please Upload .xlsx file!' })
    }
  };

  const handleBulkUploadExecuteFile = (uploadId: any) => {
    const entityType = "PAYOUT_MONETARY_UPLOAD";
    API_SERVICE.bulkUploadExecute(uploadId, entityType)
      .then(({ data }) => {
        notification.success({ message: data?.message });
        setLoading(false);
        setShowUpload(false);
        getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize);
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }).finally(() => {
        setLoading(false);
      });
  };

  const renderPayoutCompleted = () => (
    <Form form={completeForm} onFinish={() => handlePayoutUpdateStatus()}>
      <Space>
        <Form.Item name="transferDate" className="field-bg">
          <DatePicker
            disabledDate={(current) => current.isAfter(moment().subtract(0, "day"))}
            className="custom-input"
            style={{ width: '160px' }}
            onChange={(e: any) => setTransferDate(e)} 
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="export-button dynamic-btn-primary" style={exportBtnPrimaryStyles}>Payout Completed</Button>
        </Form.Item>
        <Tooltip title="Please enter the payment date for the selected payouts and click on the Payout Completed button">
          <GrCircleInformation style={{ marginBottom: '5px', fontSize: '25px' }} />
        </Tooltip>
      </Space>
    </Form>
  );

  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const modalBtnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width: '250px'}
  const modalBtnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",width: '250px'};
  const exportBtnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width:'160px'};
  const infoBtnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",width:'100px'};

  return (
    <>
      <div className="content-box">
        <Form form={form}>
          <Row>
            <Col className="filter-bar" span={14}>
              <Form.Item name="searchBox">
                <Input
                  size="large"
                  className="searchBox"
                  onChange={debounce(handleSearch, 1000)}
                  placeholder="Search"
                  allowClear
                  prefix={<FaSearch />}
                />
              </Form.Item>
            </Col>
            <Col className="filter-bar" span={8}>
              <Space style={{ marginLeft: '15px' }}>
                <Tooltip title="Refresh">
                  <Button type="primary"  className="refresh-button" onClick={() => { form.setFieldsValue({ searchBox: "" }); getPayoutAllList(payoutReq.pageNo, payoutReq.pageSize); }} > <img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/></Button>
                </Tooltip>
                {(payoutReq.payoutStatus == "REQUESTED") && (
                  <>
                    <Button
                      className="export-button dynamic-btn-primary"
                      style={btnPrimaryStyles}
                      type="primary"
                      onClick={handleDownloadPayoutDetails}
                    >Export
                    </Button>
                    <Button
                      className="export-button dynamic-btn-primary"
                      style={btnPrimaryStyles}
                      type="primary"
                      onClick={() => { setShowUpload(true); setFileList([]) }}
                    >Import
                    </Button>
                    <Button
                      style={{ border: 'none', backgroundColor:'transparent', boxShadow:'none' }}
                      icon={<GrCircleInformation style={{ fontSize: '25px' }} />}
                      onClick={() => setShowPayoutReqInfo(true)}
                    />
                    <Modal
                      width={500}
                      visible={showPayoutReqInfo}
                      style={{ marginBottom: 230 }}
                      title=''
                      centered
                      closable={false}
                      maskClosable={false}
                      footer={false}
                    >
                      <ul style={{ marginTop: '15px' }}>
                        <li>Export - click here to download the payout requests for action. Please note - if a filter is applied, then only the filtered leads will be populated in the exported file.</li><br />
                        <li>Import - click here to upload the payout approvals. Please pay attention to the mandatory fields on the sheet being uploaded to avoid any errors.</li>
                      </ul>
                      <div style={{ display: 'flex', justifyContent: 'center', padding: '5px' }}>
                        <Button
                          className="dynamic-btn-primary"
                          style={infoBtnPrimaryStyles}
                          type="primary"    
                          onClick={() => setShowPayoutReqInfo(false)}
                        >
                          OK
                        </Button>
                      </div>
                    </Modal>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col >
            {
              listCase.map((item: any, i: number) => (
                <CheckableTag
                  className="payout-checkable"
                  style={customStyles}
                  key={i}
                  checked={item.key === payoutReq.payoutStatus}
                  onChange={(checked) => handleConnectorTagChange(i, item.key)}
                >
                  {item.value}
                </CheckableTag>
              ))}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              rowSelection={(payoutReq.payoutStatus == "REQUESTED" || payoutReq.payoutStatus == "APPROVED") ? rowSelection : undefined}
              className="dynamic-pagination-checkbox"
              style={customStyles}
              scroll={{ x: 'max-content' }}
              loading={loading}
              columns={filterColumns()}
              dataSource={allConnectors}
              onChange={handlePaginationChange}
              pagination={{
                current: payoutReq.pageNo,
                pageSize: payoutReq.pageSize,
                total: totalCount,
              }}
              size="small"
            />
            <div style={{ padding: '10px' }}>
              {payoutReq.payoutStatus == "DISBURSED" && (
                <div>
                  <li>The payout displayed is subject to adjustments related to subventions, advance payments etc. which are calculated post payout request.</li>
                  <li> The Tentative Taxable value of payout does not include taxes like GST</li>
                  <li>  For any queries please contact your relationship manager</li>
                </div>
              )}
              {payoutReq.payoutStatus == "REQUESTED" && (
                <Button
                  type="primary"                 
                  className="export-button dynamic-btn-primary"
                  style={exportBtnPrimaryStyles}
                  loading={loading}
                  onClick={handlePayoutUpdateStatus}
                >Approve Payout
                </Button>
              )}
              {payoutReq.payoutStatus == "APPROVED" && (
                renderPayoutCompleted()
              )}
            </div>
          </Col>
        </Row>
        <Modal
          width={300}
          visible={showUplaod}
          style={{ marginBottom: 230 }}
          okText="Yes"
          cancelText="No"
          title='Upload Excel'
          centered
          closable={false}
          maskClosable={false}
          footer={false}
        >

          <Upload
            className="upload-wrapper"
            accept=".xlsx"
            name="file"
            maxCount={1}
            onChange={onUpload}
            {...uploadProps}
          > <AddFileGray />  Upload Excel File</Upload>
          <Space direction="vertical" style={{ marginBottom: '15px' }}>
            <Button
              type="primary"
              className="export-button dynamic-btn-primary"
              style={modalBtnPrimaryStyles}
              loading={loading}
              onClick={uploadedDocumentFile}
            >Upload</Button>
            <Button onClick={() => setShowUpload(false)} className="export-button dynamic-btn-default" style={modalBtnDefaultBorderStyle}>Close</Button>
          </Space>
        </Modal>
      </div>
    </>
  );
};

export default PayoutRequest;
