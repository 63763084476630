import * as React from "react";
import { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { notification, Card, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import LoginForm from "./LoginForm";

import "./Login.scss";
import { useTranslation } from "react-i18next";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "shared/services/api-service";

import Logo from "assets/images/logo.png";

const { Title, Paragraph } = Typography;

interface Props {
  location: any;
}
export default function Login({ location }: Props) {
  let history = useHistory();
  const { t } = useTranslation();
  const [fields, setFields] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile] = useState("");
    const [showCaptchaForConfirmMobile, setShowCaptchaForConfirmMobile] = useState(false);

  const { from } = { from: { pathname: "/dashboard" } };

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
        if(data){
            localStorage.setItem('enumValues', JSON.stringify(data))
        }
    })
}

const getUsersPermissions = () => {
  const user = localStorage.getItem("user");
  API_SERVICE.getUsersPermission(
    JSON.parse(user as string).userDetails.userId
  ).then(({ data }) => {
    if (data) {
      localStorage.setItem(
        "userPermission",
        JSON.stringify(data.payload.roles)
      );
      window.location.reload();
    }
  })
};

  return (
    <AuthConsumer>
      {({ isAuth, updateAPICreds, permissions }) => {
        return isAuth ? (
          <Redirect to={from} />
        ) : (
          <div className="login">
            <div className="login-wrapper">
              {/* <div className="logo">
                <img src={Logo} alt="Logo" />
              </div> */}
              <Card className={"login-form-wrapper"}>
                <Title level={2}>{t("Sign In")}</Title>
                <Paragraph>
                  Please enter your mobile number and password to access your
                  account
                </Paragraph>
                {
                  <div className="form-wrapper">
                    <LoginForm
                        onChange={setFields}
                        fields={fields}
                        onLogin={async (
                          mobileNumber: string,
                          password: string
                        ) => {
                          setLoading(true);
                          try {
                            const { data } = await API_SERVICE.login({
                              mobileNumber,
                              password
                            });
                            if (!data) {
                              throw t("login.unableToLogin");
                            }
                            if (data?.payload?.userDetails?.role == "ROLE_CONNECTOR") {
                              throw `User with: ${mobileNumber} not allowed/permitted to login`;
                            }
                            updateAPICreds(data);
                            getUsersPermissions();
                            getAllTypes();
                          } catch (e) {
                            notification.error({
                              message: t(API_SERVICE.handleErrors(e)),
                            });
                            setLoading(false);
                          }

                        } }
                        loading={loading}
                        setLoading={setLoading} 
                        reCaptchaTokenForConfirmMobile={reCaptchaTokenForConfirmMobile}
                         setReCaptchaTokenForConfirmMobile={setReCaptchaTokenForConfirmMobile}
                          showCaptchaForConfirmMobile={showCaptchaForConfirmMobile}
                           setShowCaptchaForConfirmMobile={setShowCaptchaForConfirmMobile}                    />
                  </div>
                }
                {/* <Divider /> */}
                <Row justify={"space-around"}>
                  <Col>
                    <Link to="/forgot-password">
                      {t("genDict.forgotPassword")}
                    </Link>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        );
      }}
    </AuthConsumer>
  );
}
