import { Col, Row, Steps } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import BulkupdateMapImport from "./AllBulkUpdateSteps/BulkupdateMapImport";
import BulkUpdateStartImport from "./AllBulkUpdateSteps/BulkUpdateStartImport";
import BulkUpdateSuccessImport from "./AllBulkUpdateSteps/BulkUpdateSuccessImport";
import "antd/dist/antd.css";

const { Step } = Steps;

const BulUpdateStepImport: React.FunctionComponent<{
  setBulkUploadExecuteData: any;
  setFileName: any;
  setStatus: any;
}> = (props) => {
  const [activeStepKey, setActiveStepKey] = useState(0);
  const [bulkUploadEcecuteData, setBulkUploadExecuteData] = useState([] as any);
  const [fileName, setFileName] = useState(null as any);
  const [status, setStatus] = useState("SUCCESS");
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };
  return (
    <>
      <div className="content-box">
        <div>
          <Row>
            <Col span={3}>
              <label>{" Import files "}</label>
            </Col>
            <Col span={18}>
              <Steps
                current={activeStepKey}
                progressDot
                size={"small"}
                className="dynamic-Step"
                style={customStyles}
              >
                <Step title="UPLOAD" />
                <Step title="MAP" />
                <Step title={status.replace("_", " ")} />
              </Steps>
            </Col>
            <Col span={3}>
              {" "}
              <label>{" step " + activeStepKey + " of 3"}</label>
            </Col>
          </Row>
        </div>
      </div>

      <div style={{ marginTop: "50px" }}>
        <Content>
          {activeStepKey === 0 && (
            <BulkUpdateStartImport
              setActiveStepKey={setActiveStepKey}
              setFileName={setFileName}
            />
          )}
          {activeStepKey === 2 && (
            <BulkupdateMapImport
              setActiveStepKey={setActiveStepKey}
              setBulkUploadExecuteData={setBulkUploadExecuteData}
              setStatus={setStatus}
            />
          )}
          {activeStepKey === 3 && (
            <BulkUpdateSuccessImport
              setActiveStepKey={setActiveStepKey}
              bulkUploadEcecuteData={bulkUploadEcecuteData}
            />
          )}
        </Content>
      </div>
    </>
  );
};

export default BulUpdateStepImport;
