import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import iconVerified from "../../assets/images/right-border.svg";
import iconInfo from "../../assets/images/info.svg";
import "./FormInput.scss";

interface IFormInput {
  name: string;
  type?: string;
  label?: string;
  inputMode?: "url" | "email" | "text" | "search" | "none" | "tel" | "numeric" | "decimal";
  tooltip?: string;
  placeholder?: string;
  prefix?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVerified?: boolean;
  isUpperCase?: boolean;
  showVerification?: boolean;
  max?:BigInteger;
  min?:BigInt;
  rules?: Rule[] | undefined;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  removeDefaultCss?:boolean
}

const FormInput = (props: IFormInput) => {
  const {
    name,
    type,
    inputMode,
    label,
    tooltip,
    isRequired,
    prefix,
    showVerification,
    isVerified,
    isDisabled,
    isUpperCase,
    placeholder,
    rules,
    defaultValue,
    onChange,
    onBlur,
    removeDefaultCss,
  } = props;

  const toInputUppercase = (e: any) => {
    if (isUpperCase) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        
        className={removeDefaultCss ? '' : "field-bg"}
        label={label}
        // tooltip={tooltip}
        tooltip={!tooltip ? "" : { title: tooltip, icon: <img src={iconInfo} height={20} width={20} /> }}
        rules={[
          {
            required: isRequired,
            message: `Please Enter ${label ?? placeholder}`,
          },
          ...(rules ?? []),
        ]}
      >
        <Input
          size="large"
          className="custom-input"
          type={type ?? "text"}
          placeholder={placeholder}
          onInput={toInputUppercase}
          inputMode={inputMode ?? "text"}
          disabled={isDisabled}
          prefix={prefix}
          // className="form-input"
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Form.Item>
      {showVerification &&
        (isVerified ? (
          <div className="label-verified">
            <img src={iconVerified} />
            <label>Verified</label>
          </div>
        ) : (
          <label className="label-verficiation-pending">
            Verification Pending
          </label>
        ))}
    </>
  );
};

export default FormInput;
