import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  Input,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { downloadDocument } from "shared/services/Utility";
import "./index.scss";
import "./doc-category.scss";
// import bankList from "./banklist.json";
import bankListForCreditSaison from "./banklistForCreditSaison.json";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Panel } = Collapse;

interface IDocCategory {
  updateBankDetails?: any;
  doc?: any;
  index?: any;
  selectedLander?: any;
  bankList?: any;
}

const BankStatementFields: React.FunctionComponent<IDocCategory> = (
  props: IDocCategory
) => {
  const { updateBankDetails, index, doc, selectedLander, bankList } = props;
  const [backName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [backPassword, setBankPassword] = useState("");
  //const [filePassword, setFilePassword] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const getDisableBtn = () => {
    let isDisable: any = false;
    isDisable = !backName && !accountNumber && !backPassword;
    return isDisable;
  };

  const setAccountNumber1 = (val) => {
    const regex = /^[0-9]*$/;
    if (regex.test(val)) {
      setAccountNumber(val);
    } else {
    }
  };

  const setFilePassword = (val) => {
    setSelectValue(val);
  };

  useEffect(() => {
    // Update selectValue based on backPassword
    if (backPassword === '') {
      const data = null;
      setFilePassword(data);
    } 
    else if (backPassword === null) {
      setFilePassword('NO');
    }
    else {
      setSelectValue("YES");
    }
  }, [backPassword]);

  useEffect(() => {
    if (doc?.documentDetails) {
      const data = doc?.documentDetails?.documentAdditionalInfoDto;
      updateData(data);
    }
  }, [doc]);

  const getBankNameFromList = (bankName) => {
    let fullBankName = "";

    if (selectedLander?.lenderName === "CREDIT SAISON") {
      bankListForCreditSaison &&
        bankListForCreditSaison.filter((items) => {
          if (items.value === bankName) {
            fullBankName = items.label;
          }
        });
    } else {
      bankList &&
        bankList.filter((items) => {
          if (items.value === bankName) {
            fullBankName = items.label;
          }
        });
    }

    return fullBankName;
  };

  const updateData = (req) => {
    setBankName(req?.bankId);
    //setSelectValue(req?.selectValue);
    setAccountNumber(req?.accountNumber);
    if (req?.filePassword) {
      const ddlValue = "YES";
      setFilePassword(ddlValue);
    } else {
      if (req?.bankId) {
        const ddlValue = "NO";
        setFilePassword(ddlValue);
      }
    }
    if (req?.bankId) {
      setBankPassword(req?.filePassword);
    } else {
      setBankPassword('');
    }
    if (!req?.bankId) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  };

  const onClickOnSave = () => {
      const payload = {
        filePassword: selectValue === "NO" ? null : backPassword,
        bankName: backName,
        accountNumber: accountNumber,
        doc: doc
      };
    const documentBusinessID = doc?.documentDetails?.businessDocumentId;
    updateBankDetails(
      documentBusinessID,
      { additionalInfo: payload },
      updateData
    );
  };

  return (
    <>
      {isEditable ? (
        <>
          <Row
            key={`${doc?.documentType}_${index + 1}`}
            className="other-detail"
            style={{
              background: "#DFE0E680",
            }}
          >
            <Col
              span={1}
              className="doc-checkbox"
              style={{ marginLeft: "4%" }}
            ></Col>
            <Col
              span={8}
              style={{
                textOverflow: "ellipsis",
                flexWrap: "nowrap",
                overflow: "hidden",
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Tooltip title="Bank Name">
                <span style={{ verticalAlign: "sub" }}>
                <Select
                  placeholder="Search Or Select Bank Name"
                  className="custom-select"
                  disabled={isEditable}
                  options={bankList}
                  showSearch
                  value={backName}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    console.log("::e", e);
                    setBankName(e);
                  }}
                />
                </span>
              </Tooltip>
            </Col>

            <Col
              span={4}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              {selectedLander?.lenderName
                ?.toLowerCase()
                ?.includes("lending") ? (
                  <Tooltip title="Bank Account Number">
                  <Input
                    className="custom-input"
                    max={100}
                    type="text"
                    min={0}
                    value={accountNumber}
                    disabled={isEditable}
                    onChange={(e) => setAccountNumber1(e?.target?.value)}
                    placeholder="Bank Account No."
                  />
                </Tooltip>
              ) : (
                <Tooltip title="is File passsword protected?">
                  <Select
                    className="custom-select"
                    placeholder="Is file password protected?"
                    showSearch
                    value={selectValue}
                    disabled={isEditable}
                    onChange={(e) => {
                      console.log("abc", e);
                      setFilePassword(e);
                    }}
                    options={[
                      {
                        value: "YES",
                        label: "YES",
                      },
                      {
                        value: "NO",
                        label: "NO",
                      },
                    ]}
                  />
                </Tooltip>
              )}
            </Col>
            <Col
              span={5}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              {selectValue === "YES" || selectValue === "" ? (
                <Tooltip title="File Password">
                  <Input
                    className="custom-input"
                    max={100}
                    min={0}
                    value={backPassword}
                    disabled={isEditable}
                    type="password"
                    onChange={(e) => setBankPassword(e?.target?.value)}
                    placeholder="Password"
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Col>
            <Col
              span={3}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Button
                size="large"
                className="button-submit dynamic-btn-primary"
                style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
                type="primary"
                htmlType="submit"
                onClick={() => setIsEditable(false)}
              >
                Edit
              </Button>
            </Col>
            <Col span={1}></Col>
          </Row>
        </>
      ) : (
        <>
          <Row
            key={`${doc?.documentType}_${index + 1}`}
            className="other-detail"
            style={{
              background: "#DFE0E680",
            }}
          >
            <Col
              span={1}
              className="doc-checkbox"
              style={{ marginLeft: "4%" }}
            ></Col>
            <Col
              span={8}
              style={{
                textOverflow: "ellipsis",
                flexWrap: "nowrap",
                overflow: "hidden",
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Tooltip title="Bank Name">
                <Select
                  placeholder="Search Or Select Bank Name"
                  className="custom-select"
                  options={bankList}
                  showSearch
                  value={backName}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    console.log("::e", e);
                    setBankName(e);
                  }}
                />
              </Tooltip>
            </Col>
            <Col
              span={4}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              {selectedLander?.lenderName
                ?.toLowerCase()
                ?.includes("lending") ? (
                <Tooltip title="Bank Account Number">
                  <Input
                    className="custom-input"
                    max={100}
                    type="text"
                    min={0}
                    value={accountNumber}
                    onChange={(e) => setAccountNumber1(e?.target?.value)}
                    placeholder="Bank Account No."
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Is file password protected?">
                  <Select
                    className="custom-select"
                    placeholder="Is file password protected?"
                    showSearch
                    value={selectValue}
                    onChange={(e) => {
                      console.log("abc", e);
                      setFilePassword(e);
                    }}
                    options={[
                      {
                        value: "YES",
                        label: "YES",
                      },
                      {
                        value: "NO",
                        label: "NO",
                      },
                    ]}
                  />
                </Tooltip>
              )}
            </Col>
            <Col
              span={5}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              {selectValue !== 'NO' ? (
                <Tooltip title="File Password">
                  <Input
                    className="custom-input"
                    max={100}
                    min={0}
                    value={backPassword}
                    onChange={(e) => setBankPassword(e?.target?.value)}
                    placeholder="Password"
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Col>
            <Col
              span={3}
              style={{
                padding: "2px",
                paddingRight: "1%",
              }}
            >
              <Button
                size="large"
                className="button-submit dynamic-btn-primary"
                // loading={loading}
                style={{'--btnColor' :appColors?.appPrimaryColor ?? ""} as any}
                type="primary"
                htmlType="submit"
                disabled={getDisableBtn()}
                onClick={onClickOnSave}
              >
                Save
              </Button>
            </Col>
            <Col span={1}></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BankStatementFields;
