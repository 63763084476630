import React from "react";
import "./number.css";

function NumberInput({
  value,
  label,
  type,
  onChange,
  name,
  
  
}) {
  const handleChange = (e) => {
    onChange(e);
  };
  
  return (
    <div>
      <input
        type={type}
        value={value}
        name={name}
        onChange={handleChange}
        className="square"
        
      />
    </div>
  );
}

export default NumberInput;
