import { Modal, Radio, Space, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import iconApprove from "../../../../assets/images/apporveIcon.svg";
import iconFailed from "../../../../assets/images/failed.svg";
import { ColumnsType } from "antd/lib/table";
import API_SERVICE from "shared/services/api-service";
import "./index.scss";
import "./doc-category.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IDocumentStatus {
  showDocumentStatus: boolean;
  setShowDocumentStatus: any;
  lenderInfoId: string;
  caseId: string;
  documentDetails: any;
}

const DocumentStatus: React.FunctionComponent<IDocumentStatus> = (
  props: IDocumentStatus
) => {
  const {
    showDocumentStatus,
    setShowDocumentStatus,
    lenderInfoId,
    caseId,
    documentDetails,
  } = props;

  const [documentListFailed, setDocumentListFailed] = useState([] as any);
  const [documentListSuccess, setDocumentListSuccess] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("success");
  const {appColors}: any = useSelector((state: RootState) => state.common);

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = () => {
    setLoading(true);
    if (documentDetails) {
      setLoading(false);
      const successed = documentDetails?.filter(
        (doc) => doc?.status == "UPLOADED"
      );
      setDocumentListSuccess(successed);

      const failed = documentDetails?.filter((doc) => doc?.status == "FAILED");
      setDocumentListFailed(failed);
    }
    // API_SERVICE.getLenderDocumentlist(lenderInfoId, caseId)
    //   .then(({ data }) => {

    //   })
    //   .catch((e: any) => {
    //     notification.error({ message: API_SERVICE.handleErrors(e) });
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Lender Doc Name",
      key: "documentType",
      width: "300px",
      render: (_: any, record: any) => {
        const docName = record?.lenderDocValue
          ? record?.lenderDocValue
          : record?.docType;
        return <span>{docName}</span>;
      },
    },
    {
      title: "Status",
      key: "status",
      fixed: "right",
      width: 110,
      render: (_: any, record: any) => {
        const isDocUploaded = record?.status == "UPLOADED";
        return (
          <span className={isDocUploaded ? "success" : "failed"}>
            <img
              src={isDocUploaded ? iconApprove : iconFailed}
              width="16px"
              height="16px"
              alt=""
              style={{ marginRight: "6px" }}
            />
            {isDocUploaded ? "Success" : "Failed"}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title="Document Status"
        centered
        width={500}
        onCancel={() => {
          setShowDocumentStatus(false);
        }}
        footer={null}
        visible={showDocumentStatus}
      >
        <Radio.Group
          onChange={(e) => {
            setSelectedStatus(e?.target?.value);
          }}
          className="custom-radio"
          name="applicantType"
          value={selectedStatus}
          style={{'--borderColor': appColors?.appSecondaryColor ?? "",paddingBlock: "10px"}as any}
        >
          <Space direction="horizontal">
            <Radio.Button value={"success"}>{"Success"}</Radio.Button>
            <Radio.Button value={"Failed"}>{"Failed"}</Radio.Button>
          </Space>
        </Radio.Group>

        <Table
          className="dynamic-pagination-checkbox"
          style={{'--bgColor': appColors?.appPrimaryColor ?? ""}as any}
          loading={loading}
          columns={columns}
          dataSource={
            selectedStatus == "success"
              ? documentListSuccess
              : documentListFailed
          }
          size="small"
          scroll={{ x: "max-content" }}
        />

        {/* {loading && <AppLoading />} */}
      </Modal>
    </>
  );
};

export default DocumentStatus;
