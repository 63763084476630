import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import {
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Form,
  notification,
  Switch,
  Popconfirm,
  Tag,
  Tooltip,
} from "antd";
import { FaSearch, FaPlus } from "react-icons/fa";
import type { ColumnsType } from "antd/es/table";
import API_SERVICE from "shared/services/api-service";
import { useHistory, useLocation } from "react-router-dom";
import { hasUserRole } from "shared/services/Utility";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import { ReactComponent as IconEyeBorder } from "../../assets/images/eye_border.svg";
import { ReactComponent as IconEditBorder } from "../../assets/images/edit_border.svg";
import { ReactComponent as IconDeleteBorder } from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {
  setInsurerPageNo,
  setInsurerPageSize,
  setInsurerSearchParam,
} from "shared/redux/Insurer-reducer";
import { setScreenHeader } from "shared/redux/common-reducer";

type Props = {};

const Insurer: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const userRolePermission = hasUserRole();
  const [allLenders, setAllLenders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [apiCount, setApiCount] = useState(0);
  const [form] = Form.useForm();
  const [total, setTotal] = useState();
  const insurer: any = useSelector((state: RootState) => state.insurer);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const columns: ColumnsType<any> = [
    {
      title: "Insurer Name",
      dataIndex: "branchName",
      key: "branchName",
      width: 350,
      ellipsis: true,
    },
    {
      title: "Product",
      dataIndex: "products",
      key: "products",
      // width: 1110,
      ellipsis: true,
      render: (products: string[]) => (
        <span>
          {products?.map((product) => {
            return <Tag key={product}>{product?.toUpperCase()}</Tag>;
          })}
        </span>
      ),
    },
    {
      title: "Product Plan",
      dataIndex: "activeProductPlans",
      key: "productPlan",
      width: 350,
      ellipsis: true,
      render: (plans: string) => (
        <Tooltip title={plans} placement="topLeft">
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "300px",
              display: "block",
            }}
          >
            {plans}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      render: (_: any, record: any) => (
        <Switch
          style={customStyles}
          checked={record.active}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      width: 140,
      render: (_: any, record: any) => (
        <Space className="action-button" size="small">
          <Button
            className="custome-btn-icon"
            style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
            size="small"
            type="text"
            onClick={() => {
              history.push({
                pathname: "insurer/view",
                state: {
                  lenderMappingId: record?.lenderMappingId,
                  type: "VIEW",
                },
              });
            }}
            icon={<IconEyeBorder />}
          />
          <Button
            className="custome-btn-icon-secondary"
            style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
            size="small"
            type="text"
            onClick={() => {
              history.push({
                pathname: "insurer/edit",
                state: {
                  lenderMappingId: record?.lenderMappingId,
                  type: "EDIT",
                },
              });
            }}
            icon={<IconEditBorder />}
            hidden={
              !(
                userRolePermission["UpdateOperations"] ||
                userRolePermission["Admin"]
              )
            }
          />
          {/* <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => deleteConfirm(record)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              style: {
                backgroundColor: appColors?.appPrimaryColor ?? "#273896",
                border: "none",
              },
            }}
            cancelButtonProps={{
              style: {
                borderColor: appColors?.appSecondaryColor ?? "#C71C8E",
                color: appColors?.appSecondaryColor ?? "#C71C8E",
              },
            }}
          >
            <Button
              style={{ backgroundColor: "transparent", border: "none" }}
              size="small"
              type="text"
              hidden={
                !(
                  userRolePermission["UpdateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
              icon={<IconDeleteBorder width={24} height={24} />}
            />
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Manage Insurer" }));
    form.setFieldsValue({
      searchBox: insurer.searchParam,
    });
    getAllLenders(insurer.pageNo, insurer.pageSize, insurer.searchParam);
  }, []);

  const onChangeStatus = (event: any, item: any) => {
    item.active = event;
    API_SERVICE.updateInsuranceStatus(item.lenderMappingId, item.active)
      .then(({ data }) => {
        if (data) {
          getAllLenders(insurer.pageNo, insurer.pageSize, insurer.searchParam);
          if (data.message) {
            notification.success({ message: data.message });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const deleteConfirm = (lender) => {
    API_SERVICE.deleteLender(lender.lenderMappingId)
      .then(({ data }) => {
        if (data) {
          getAllLenders(insurer.pageNo, insurer.pageSize);
          if (data.message) {
            notification.success({ message: data.message });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllLenders = (
    pageNo: number = 1,
    pageSize: any = null,
    searchVal: any = null
  ) => {
    setLoading(true);
    const params = { pageNo: pageNo, pageSize: pageSize, name: searchVal };
    API_SERVICE.getLenders(params)
      .then(({ data }) => {
        let lenders = data.payload.content.map((lender) => ({
          lenderInfoId: lender.lenderInfoId,
          dsaBusinessId: lender.dsaBusinessId,
          fiInfoId: lender.fiInfoId,
          branchName: lender.branchName,
          hubName: lender.hubName,
          products: lender.products,
          role: lender.role,
          active: lender.active,
          pinCode: lender.pinCode,
        }));
        setAllLenders(lenders);
        mapLeadsData(data);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const mapLeadsData = (data: any) => {
    setTotalCount(data.payload.totalElements);
    setAllLenders(data.payload.content);
    setApiCount(1);
  };

  const handlePaginationChange = (pagination) => {
    dispatch(setInsurerPageNo(pagination.current));
    dispatch(setInsurerPageSize(pagination.pageSize));
    setTotal(pagination.total);
    getAllLenders(pagination.current, pagination.pageSize, insurer.searchParam);
  };

  const addHandler = () => {
    history.push("/insurer/add");
  };

  const lenderSearchHandler = (event: any) => {
    dispatch(setInsurerPageNo(1));
    dispatch(setInsurerSearchParam(event.target.value));
    getAllLenders(1, insurer.pageSize, event.target.value);
  };

  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };
  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };

  return (
    <div className="content-box">
      <Form form={form}>
        <Row>
          <Col className="filter-bar" span={16}>
            <Form.Item name="searchBox">
              <Input
                size="large"
                className="search-box"
                placeholder="Search"
                onChange={debounce(lenderSearchHandler, 600)}
                allowClear
                prefix={<FaSearch />}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="filter-bar  filter-bar-right">
            <Button
              style={{ marginLeft: "80px" }}
              type="primary"
              className="refresh-button"
              onClick={() => {
                form.setFieldsValue({
                  searchBox: "",
                });
                dispatch(setInsurerPageNo(1));
                dispatch(setInsurerSearchParam(""));
                getAllLenders(1, insurer.pageSize);
              }}
              shape="round"
              size={"middle"}
            >
              <img
                src={blackRefreshIcon}
                alt=""
                style={{ marginLeft: "-10px" }}
              />
            </Button>
            <Button
              type="primary"
              className="add-button dynamic-btn-primary"
              style={btnPrimaryStyles}
              onClick={addHandler}
              shape="round"
              icon={<FaPlus />}
              size={"middle"}
              disabled={
                !(
                  userRolePermission["Admin"] ||
                  userRolePermission["CreateOperations"]
                )
              }
              hidden={
                !(
                  userRolePermission["CreateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
            >
              Add Insurer
            </Button>
          </Col>
        </Row>
      </Form>
      <Row></Row>
      <Row>
        <Col span={24}>
          <Table
            className="dynamic-pagination-checkbox"
            style={customStyles}
            loading={loading}
            columns={columns}
            dataSource={allLenders}
            scroll={{ x: "max-content" }}
            size="small"
            onChange={handlePaginationChange}
            pagination={{
              current: insurer.pageNo,
              pageSize: insurer.pageSize,
              total: totalCount,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Insurer;
