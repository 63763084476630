import { Button, Space, Typography } from "antd";
import successimage from "../../../../../src/assets/images/payouts/payoutSuccess.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Text } = Typography;

const SuccessImportBulkUpload: React.FunctionComponent<{
  bulkUploadEcecuteData: any;
  setActiveStepKey: any;
}> = (props) => {
  const { bulkUploadEcecuteData } = props;
  const history = useHistory();
  const {appColors}: any = useSelector((state: RootState) => state.common);

  return (
    <>
      <div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Text>
              <h4
                style={{
                  color:
                    bulkUploadEcecuteData?.status === "FAILED"
                      ? "red"
                      : "green",
                }}
              >
                {bulkUploadEcecuteData?.status.replace("_", " ")}
              </h4>
            </Text>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Text  className="bulk-upload-text" style={{'--textColor': appColors?.appSecondaryColor ?? ""} as any}>
              Click on “Return to Bulk Upload Summary Page” button below to
              check the status of your bulk upload request.
            </Text>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Space>
              <img
                src={successimage}
                alt=""
                className=" start-import-upload-icon "
                width={"350px"}
              />
            </Space>
          </div>

          {bulkUploadEcecuteData?.status != "FAILED" &&
            <div style={{ display: "flex", justifyContent: "center", marginTop:'20px' }}>
              Your file upload is in process and will take some time to complete.
            </div>
          }

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
            }}
          >
            <Button
              size="large"
              className="bulk-upload-btn bulk-upload-border-default-btn"
              style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
              onClick={() => {
                history.goBack();
              }}
            >
              Return to Bulk Upload Summary Page
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessImportBulkUpload;
