import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const notificationCountSlice = createSlice({
    name: "NotificationReducer",
    initialState: { notificationCount: 0 },
    reducers: {

        setNotificationCount: (state, action: PayloadAction<any>) => {
            state.notificationCount = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setNotificationCount } = notificationCountSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default notificationCountSlice.reducer;  