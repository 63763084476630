import { notification } from "antd";
import "./FormInput.scss";
import { useEffect, useState } from "react";
import { PAN_REGAX } from "shared/constants/AppConstants";
import FormInput from "./FormInput";
import API_SERVICE from "shared/services/api-service";

interface IFormInputPan {
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  showVerification?: boolean;
  isDisabled?:boolean;
  callBack: (panData?: any) => void;
}

const FormInputPan = (props: IFormInputPan) => {
  const { name, label, placeholder, showVerification, value, callBack,isDisabled } = props;

  const [panVerified, setPanVerified] = useState(false);
  const [pan, setPan] = useState("");

  useEffect(() => {
    if ((value?.length ?? 0) == 10) {
      getPanDetail(value);
    }
  }, [value]);

  const getPanDetail = (pan) => {
    const data ={
      panNumber:pan
    }

      API_SERVICE.getPanVerify(data).then(({ data }) => {
      if (data?.payload?.fullName) {
        setPanVerified(true);
      }
      callBack(data);
    }).catch((e: any) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }).finally(() => {
      setPan(pan)
    })
  };

  const checkPan = (_: any, valueI: any) => {
    if (valueI == null || (valueI?.length ?? 0) == 0) {
      return Promise.resolve();
    }

    if (PAN_REGAX.test(valueI)) {
      if(pan != valueI) {
        getPanDetail(valueI);
      }
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Please enter valid pan number`));
    }
  };

  return (
    <FormInput
      name={name}
      label={label}
      placeholder={placeholder}
      onChange={(e: any) => {
        setPanVerified(false);
      }}
      isDisabled={isDisabled}
      isVerified={panVerified}
      showVerification={showVerification}
      isUpperCase
      rules={[
        {
          required: true,
          message: "Please enter PAN",
        },
        { validator: checkPan },
      ]}
    />
  );
};

export default FormInputPan;
