import {
  Button,
  Modal,
  notification,
  Select,
  Typography,
  Spin,
  Checkbox,
  Row,
  Col,
  Space,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import API_SERVICE from "shared/services/api-service";
import UploadDocument from "../UploadDocument/UploadDocument";
import "./index.scss";
import DocCategory from "./DocCategory";
import ReplaceDocument from "./ReplaceDocument";
import RenameDocument from "./RenameDocument";
import SkipDocument from "./SkipDocument";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import imgDocument from "../../../../assets/images/document_download.svg";
import imgTickRight from "../../../../assets/images/tickRight.svg";
import imgLoading from "../../../../assets/images/loading.svg";
import wrong from "../../../../assets/images/wrong.svg";
import '../../LeadNew/LeadDetail/Document/Document.scss';

const { Title } = Typography;
const { Option } = Select;
const RequiredDocumentFlowable: React.FunctionComponent<any> = ({
  leadData,
  handleFormSubmit,
  setFinalJSONSchema,
  // documentList,
  setDocumentList,
  finalJSONSchema,
  selectedLander,
  loadingDisplay,
  isDocumentUploadResponse,
  skippedLoginStatus,
  setShowAdditionalFields,
  failedCount,
  getLenderDocumentUploadDetails,
}) => {
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [masterParentIDs, setMasterParentIDs] = useState<any>(undefined);
  const [masterChildIDs, setMasterChildIDs] = useState<any>(undefined);
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [mappedList, setMappedList] = useState([] as any);
  const [documentMappingList, setDocumentMappingList] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [documentList1, setDocumentList1] = useState([finalJSONSchema] as any);
  const [documentListCat, setDocumentListCat] = useState([] as any);
  const [showReplace, setShowReplace] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [isAllDocChecked, setIsAllDocChecked] = useState<any>(false);
  const [checkedDocuments, setCheckedDocuments] = useState<any>([]);
  // const [sampleDataSet, setSampleDataSet] = useState([] as any);
  // console.log(documentList);
  const [SelectedChild, setSelectedChild] = useState([] as any);
  const [showSkipDoc, setShowSkipDoc] = useState(false);

  const [tmpdocumentList1, setTmpdocumentList1] = useState([] as any);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const [tableCurrentPageNo, setTableCurrentPageNo] = useState(0);
  const [isConflictClick, setIsConflictClick] = useState(false);
  const [allDocumentList, setAllDocumentList] = useState([] as any);
  const smapleNewData1: any[] = [];
  const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
  const [popupVisible, setPopupVisible] = useState(false);
  const [bankList, setBankList] = useState([]);

  const getAllDocumentList = () => {
    API_SERVICE.getLenderDocumentlist(
      selectedLander.lenderInfoId,
      leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          updateDocs(data.payload);
        }
      })
      .catch((e: any) => {
        console.log(newArray1);
        // updateDocs();
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
    // checkedNexBtn();
    const updateDocs = (json) => {
      const tmpLendersData = Array.from(
        new Set(json.map((item) => item.parentDocId))
      );
      json.filter((item, indexx) => {
        smapleNewData1.push({
          isVisible: true,
          isUpdatable: true,
          isRemovable: false,
          childDocIdOrg: item?.childDocId,
          // require: item?.childRequired === 'yes',
          // indexValue: `${updateIndex}`,
          ...item,
          // documentCategory: item?.docName.split("~").at(0),
        });
      });
      // setSampleDataSet(smapleNewData1);
      generateCategoryList(smapleNewData1);
      setTmpdocumentList1(smapleNewData1);
      // const json = tmpdocumentList1;
      setAllDocumentList(json);
    };
  };

  useEffect(() => {
    getAllDocumentList();
    getLenderDocumentUploadDetails();
    getDocumentBankList();
  }, []);

  const getDocumentBankList = () => {

    API_SERVICE.getLenderShortCode(selectedLander.lenderInfoId).then(({ data }) => {
      if (data?.payload) {
        const masterKey = 'BANK_NAME';
        const lenderShortCode = data?.payload?.lenderShortCode;
        API_SERVICE.getDocumentBankListFoLender(masterKey, lenderShortCode).then(({ data }) => {
          if (data?.payload) {
            if (data?.payload?.content?.length > 0) {
              const updtedData: any = [];
                  data?.payload?.content?.filter((item: any) => {
                    updtedData.push({
                      label: item.value,
                      value: item.key
                    });
                  });
              setBankList(updtedData);
            } else {
              API_SERVICE.getDocumentBankList(masterKey).then(({ data }) => {
                if (data?.payload) {
                  const updtedData: any = [];
                  data?.payload?.filter((item: any) => {
                    updtedData.push({
                      label: item.value,
                      value: item.id
                    });
                  });
                  setBankList(updtedData);
                }
              }).catch((e) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
              })
            }
          }
        }).catch((e) =>{
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
      }
    }).catch((e) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
  }

  const errorCallback = () => {
    getAllDocumentList();
    setIsAllDocChecked(false);
    setCheckedDocuments([]);
  };
  const checkNextBtnStatus = useEffect(() => {
    console.log("::allDocumentList", allDocumentList);
    if (allDocumentList?.length > 0) {
      const tmpMasterParentData = Array.from(
        new Set(
          allDocumentList.map((item: any) => {
            if (
              item.childRequired.toUpperCase() === "YES_OR_NO" &&
              item.lenderDocumentStatus !== "UPLOADED"
            ) {
              return item.parentDocId;
            }
          })
        )
      );
      const tmpMasterChildData = Array.from(
        new Set(
          allDocumentList.map((item: any) => {
            if (
              item.childRequired.toUpperCase() === "YES" &&
              item.lenderDocumentStatus !== "UPLOADED"
            ) {
              return item.childDocId;
            }
          })
        )
      );
      allDocumentList?.map((doc) => {
        if (doc?.documentDetails) {
          const indexA = tmpMasterParentData.findIndex(
            (val) => val === doc?.parentDocId
          );
          const indexChild = tmpMasterChildData.findIndex(
            (val) => val === doc?.childDocId
          );

          if (
            doc?.childRequired?.toUpperCase() === "YES" &&
            indexChild !== -1
          ) {
            tmpMasterChildData.splice(indexChild, 1);
          }
          // if (doc?.childRequired !== "yes") {
          //   setSelectedChild([...SelectedChild, doc?.parentDocId]);
          // }
          if (indexA !== -1 && doc?.childRequired?.toUpperCase() !== "YES") {
            tmpMasterParentData.splice(indexA, 1);
            // setSelectedChild([...SelectedChild, ...a]);
          }
        }
      });
      const indexChild = tmpMasterChildData.findIndex(
        (val) => val === undefined
      );
      const indexParent = tmpMasterParentData.findIndex(
        (val) => val === undefined
      );
      if (indexChild !== -1) {
        tmpMasterChildData.splice(indexChild, 1);
      }
      if (indexParent !== -1) {
        tmpMasterParentData.splice(indexParent, 1);
      }
      console.log("Doc>>P", tmpMasterParentData);
      console.log("Doc>>C", tmpMasterChildData);
      setMasterParentIDs(tmpMasterParentData);
      setMasterChildIDs(tmpMasterChildData);
    }
  }, [allDocumentList, allDocumentList.length]);
  
  const newArray1 = [] as any;

  let tempArray = [{}];
  let tempObj = {};
  const onClickNext = (documentList) => {
    documentList.map((item) => {
      if (
        item?.documentDetails?.documentName !== undefined &&
        item?.documentDetails?.documentType !== undefined
      ) {
        tempObj = {
          ...item,
          userId: "11142",
        };
        tempArray.push(tempObj);
      }
    });
    let allData: any = [];
    documentListCat.map((items) => {
      allData = [...allData, ...items?.docs];
    });
    const tmp1 = allData.filter(
      (items) =>
        items?.documentDetails && items?.lenderDocumentStatus !== "UPLOADED"
    );
    const tmp2 = tmp1.filter((docs) =>
      checkedDocuments.includes(docs?.identifier)
    );
    const data = tmp2.map((item) => {
      return {
        ...item,
        lenderDocumentValues: item?.lenderDocumentValues?.split("~").at(0),
        identifier: item?.identifier.split("~").at(0),
      };
    });
    if (checkedDocuments?.length > 0) {
      handleFormSubmit({ data: data }, errorCallback);
    } else {
      notification.error({ message: "Please select Document before upload" });
    }
  };

  const updateBankDetails = (documentID, reqData, callBack) => {
    const { bankName, accountNumber, filePassword, doc } = reqData?.additionalInfo;
    const payload = {
      documentAdditionalInfo: {
        documentApplicantType: doc?.documentDetails?.documentApplicantType,
        docOrder: doc?.documentDetails?.docOrder,
        fileSize: doc?.documentDetails?.fileSize,
        documentTypeId: doc?.documentTypeId,
        bankId: bankName,
        accountNumber: accountNumber,
        filePassword: filePassword,
      },
    };
    const businessID = doc?.documentDetails?.businessDocumentId;
    API_SERVICE.updateDocumentBankDetail(businessID, payload)
      .then(({ data }) => {
        if (data) {
          if (data?.payload) {
            callBack(data?.payload?.documentAdditionalInfoDto);
          }
        }
      })
      .catch((error) => {});
  };
  
  const generateCategoryList = (docs: any) => {
    const tmpLendersData = Array.from(
      new Set(docs.map((item) => item.documentCategory))
    ).sort((a: any, b: any) => (a > b ? 1 : -1));

    const tmp = tmpLendersData?.map((category) => {
      const filter = docs.filter((item) => item.documentCategory == category);

      const f1 = filter?.map((item) => {
        let docType = "";
        if (item?.documentDetails != null) {
          docType = item?.documentType + "~" + item?.documentDetails?.docPage;
        }
        let lenderValue = "";
        if (
          item?.documentDetails != null &&
          item?.documentDetails?.docPage > 0
        ) {
          lenderValue = item?.identifier + "~" + item?.documentDetails?.docPage;
        }
        return {
          ...item,
          isUpdatable: true,
          isRemovable: false,
          documentOrgType: item?.documentType,
          documentType:
            (docType?.length ?? 0) > 1 ? docType : item?.documentType,
          lenderDocumentValuesOrg: item?.lenderDocumentValues,
          identifierOrg: item?.identifier,
          identifier:
            (lenderValue?.length ?? 0) > 1 ? lenderValue : item?.identifier,
          // lenderDocumentValues:
          //   (lenderValue?.length ?? 0) > 1 ? lenderValue : item?.lenderDocumentValues,
        };
      });
      console.log("mandatory2: ", f1);
      const uploadedDoc = f1?.filter((items) => items.documentDetails);
      const mandatoryDocs = f1?.filter(
        (items) => items.require && items.documentDetails
      );
      const mandatoryTotalDocs = f1?.filter((items) => items.require);
      const requiredYes = f1?.some(
        (item) => item.childRequired.toUpperCase().trim() === "YES"
      );
      console.log("requiredYes", requiredYes);
      const requiredYesOrNO = f1?.some(
        (item) => item.childRequired.toUpperCase().trim() === "YES_OR_NO"
      );
      console.log("requiredYesOrNO", requiredYesOrNO);
      const mandatoryU = requiredYes ? 1 : requiredYesOrNO ? 2 : 0;
      console.log();
      console.log("mandatory3: ", mandatoryDocs);
      console.log("mandatory4: ", uploadedDoc.length);
      console.log("mandatory5: ", mandatoryTotalDocs);
      return {
        category: category,
        docs: f1,
        mandatory: mandatoryU,
        totalDocs: mandatoryTotalDocs?.length,
        uploadedDocs: uploadedDoc?.length,
        mandatoryCount: mandatoryDocs?.length,
      };
    });

    setDocumentListCat(tmp);
  };

  const actionOnDelete = (
    doc: any,
    isRemove: any = false,
    isChangeDoc: any = true
  ) => {
    API_SERVICE.deleteDocument(
      doc.documentDetails.businessId,
      doc.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        API_SERVICE.deleteDocReference(
          leadData?.caseDetails?.caseId,
          doc.documentDetails.businessDocumentId
        ).then((data1) => {
          if (data1) {
            if (isRemove) {
              actionRemove(doc);
            }
            if (isChangeDoc) {
              changeDocumentDetail(doc, null);
            }
          }
        });
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const actionAdd = (doc, index) => {
    const tmp1 = documentListCat?.map((document) => {
      if (document.category == doc.documentCategory) {
        const tmplDoclist = document?.docs.map((items) => {
          if (items?.identifier === doc?.identifier) {
            items.isUpdatable = false;
            return items;
          }
          return items;
        });
        const tmoDocLists = tmplDoclist.filter(
          (val) => val?.identifierOrg === doc?.identifierOrg
        );
        const indexDocumnet = tmplDoclist.findIndex(
          (lstItem) => lstItem?.identifier === doc?.identifier,
          index
        );
        if (indexDocumnet !== -1) {
          console.log("::doc", doc);
          tmplDoclist.splice(index + 1, 0, {
            ...doc,
            isUpdatable: true,
            documentDetails: null,
            isRemovable: true,
            isAddedCase: true,
            lenderDocumentStatus: "",
            documentType: doc?.documentType,
            lenderDocumentValues: `${doc?.lenderDocumentValuesOrg}~${tmoDocLists?.length}`,
            identifierOrg: doc?.identifier,
            identifier: `${doc?.identifier}~${tmoDocLists?.length}`,
          });
        }
        const uploadedDoc = tmplDoclist?.filter(
          (items) => items.documentDetails
        );
        const mandatoryDocs = tmplDoclist?.filter(
          (items) => items.require && items.documentDetails
        );
        const mandatoryTotalDocs = tmplDoclist?.filter(
          (items) => items.require
        );
        return {
          category: document.category,
          docs: tmplDoclist,
          totalDocs: mandatoryTotalDocs?.length,
          uploadedDocs: uploadedDoc?.length,
          mandatoryCount: mandatoryDocs?.length,
        };
      }

      return document;
    });

    setDocumentListCat(tmp1);
  };

  const actionRemove = (doc) => {
    const tmp1 = documentListCat?.map((document) => {
      if (document.category == doc.documentCategory) {
        let updateTmpLst = document?.docs?.filter(
          (items) => items.identifier !== doc.identifier
        );
        const lstName: any[] = [];
        updateTmpLst.filter((value) => {
          if (value.identifierOrg === doc.identifierOrg) {
            lstName.push(value.identifier);
          }
        });
        if (lstName?.length > 0) {
          updateTmpLst = updateTmpLst.map((value) => {
            if (value.identifier === lstName[lstName.length - 1]) {
              return {
                ...value,
                isUpdatable: true,
                isRemovable: lstName?.length !== 1,
              };
            }
            return value;
          });
        }
        const updatedDocumentMappingList = documentMappingList?.filter(
          (items) => items.identifier !== doc.identifier
        );
        if (doc?.documentDetails) {
          API_SERVICE.deleteDocument(
            doc?.documentDetails?.businessId,
            doc?.documentDetails?.businessDocumentId
          ).then((data) => {
            console.log("Document Delete and removed", data);
          });
        }
        console.log("::updatedDocumentMappingList", updatedDocumentMappingList);
        setDocumentMappingList(updatedDocumentMappingList);
        const uploadedDoc = updateTmpLst?.filter(
          (items) => items.documentDetails
        );
        const mandatoryDocs = updateTmpLst?.filter(
          (items) => items.require && items.documentDetails
        );
        const mandatoryTotalDocs = updateTmpLst?.filter(
          (items) => items.require
        );
        return {
          category: document.category,
          docs: updateTmpLst,
          totalDocs: mandatoryTotalDocs?.length,
          uploadedDocs: uploadedDoc?.length,
          mandatoryCount: mandatoryDocs?.length,
        };
      }

      return document;
    });

    setDocumentListCat(tmp1);
  };

  const docReplaceDocs = (docID) => {
    const tmp = documentListCat.map((documents) => {
      const updatedDocs = documents.docs.filter((item) => {
        if (item?.documentDetails) {
          if (item?.documentDetails?.businessDocumentId === docID) {
            item.documentDetails = null;
            item.conflict = true;
            return { ...item };
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
      const uploadedDoc = updatedDocs?.filter((items) => items.documentDetails);
      const mandatoryDocs = updatedDocs?.filter(
        (items) => items.require && items.documentDetails
      );
      const mandatoryTotalDocs = updatedDocs?.filter((items) => items.require);
      return {
        category: documents.category,
        docs: updatedDocs,
        totalDocs: mandatoryTotalDocs?.length,
        uploadedDocs: uploadedDoc?.length,
        mandatoryCount: mandatoryDocs?.length,
      };
    });
    setDocumentListCat(tmp);
  };
  
  const changeDocumentDetail = (doc, docDetail) => {
    getAllDocumentList();
  };

  useEffect(() => {
    if (isDocumentUploadResponse) {
      setIsAllDocChecked(false);
      setCheckedDocuments([]);
      if (
        isDocumentUploadResponse?.documentsSuccessfullySubmitted > 0 ||
        isDocumentUploadResponse?.failure > 0
      ) {
        const submittedData = isDocumentUploadResponse?.submissionDetails;
        const tmp1: any = [];
        const tmpFailed: any = [];
        const tmpFailedDocIDs: any = [];
        const failedErrorMsg: any = [];
        submittedData.map((items) => {
          if (items?.status === "UPLOADED") {
            tmp1.push(`${items?.docType}~${items?.docOrder}`);
          } else if (items?.status === "FAILED") {
            tmpFailedDocIDs.push(items?.documentId);
            tmpFailed.push(`${items?.docType}~${items?.docOrder}`);
            failedErrorMsg[`${items?.docType}~${items?.docOrder}`] = {
              error: items.error,
            };
          }
        });
        // const tmp1 = submittedData.map((items) => {
        const subDocs = documentListCat.map((document) => {
          const docs = document.docs.map((docs) => {
            const docNames = docs?.documentType.split("~");
            if (
              tmp1?.includes(`${docNames[0]}~${docNames[1]}`) &&
              docs?.documentDetails &&
              !docs?.documentType?.includes(",")
            ) {
              return {
                ...docs,
                lenderDocumentStatus: "UPLOADED",
              };
            } else if (
              tmpFailed?.includes(`${docNames[0]}~${docNames[1]}`) &&
              docs?.documentDetails &&
              !docs?.documentType?.includes(",")
            ) {
              return {
                ...docs,
                lenderDocumentStatus: "FAILED",
                error: failedErrorMsg[`${docNames[0]}~${docNames[1]}`].error,
              };
            } else {
              return docs;
            }
          });
          return { category: document.category, docs: docs };
        });
        //   return subDocs;
        // });
        // setCheckedDocuments(tmpFailedDocIDs);
        console.log("subDocs", subDocs);
        console.log("tmp1", tmp1);
        console.log("tmpFailed", tmpFailed);
        setDocumentListCat(subDocs);
      }
    }
  }, [isDocumentUploadResponse]);

  const actionReplaceDoc = (doc) => {
    setSelectedDocument(doc);
    setShowReplace(true);
  };

  const actionRenameDoc = (doc) => {
    setSelectedDocument(doc);
    setShowRename(true);
  };

  const onChangeCheckbox = (value, doc, isDelete = false) => {
    const id = doc?.identifier;
    let tmp = [...checkedDocuments];
    if (tmp.includes(id)) {
      setIsAllDocChecked(false);
      const filtered = tmp.filter((lstItem) => lstItem !== id);
      tmp = filtered;
    } else {
      if (!isDelete) {
        tmp.push(id);
      }
    }
    setCheckedDocuments(tmp);
  };

  const onChangeCheckboxAll = (value) => {
    const checked = value?.target?.checked;
    setIsAllDocChecked(checked);
    let tmp = [...checkedDocuments];
    if (checked) {
      documentListCat?.map((document) => {
        document?.docs?.map((item) => {
          if (
            item?.lenderDocumentStatus !== "UPLOADED" &&
            item?.documentDetails
          ) {
            tmp.push(item?.identifier ?? "");
          }
        });
      });
    } else {
      tmp = [];
    }
    setCheckedDocuments(tmp);
  };

  const getDocPage = () => {
    const docPageValues = selectedDocument?.identifier?.split("~");
    const docPage = docPageValues.length > 1 ? docPageValues?.at(-1) : 0;
    return (docPage?.length ?? 0) == 0 ? 0 : docPage;
  };

  const getDisableBtn = () => {
    let isDisable = false;
    if (!selectedLander?.lenderName?.toLowerCase()?.includes("kotak")) {
      // TOOD: Enable next button without upload any document
      if (masterParentIDs?.length === 0 && masterChildIDs?.length === 0) {
        isDisable = false;
      } else {
        isDisable = true;
      }
    } else {
      isDisable = false;
    }

    tmpdocumentList1?.map((document) => {
      if (!isDisable) {
        isDisable =
          (document?.documentDetails &&
            (document?.maxSize < document?.documentDetails?.fileSize ||
              !document?.format?.includes(
                document?.documentDetails?.fileExtn
              ))) ||
          (document?.require &&
            document?.lenderDocumentStatus !== "UPLOADED" &&
            !checkedDocuments.includes(document?.identifier));
      } else {
      }
    });

    if (!isDisable) {
      documentListCat.map((document) => {
        const tmpTotalM = document?.mandatory === 2 ? 1 : 0;
        const totalM =
          document?.totalDocs == 0 ? tmpTotalM : document?.totalDocs;
        const uploade = document?.uploadedDocs;
        const tmpUploadM =
          document?.mandatory === 2 ? (uploade == 0 ? 0 : 1) : 0;
        const uploadM =
          document?.mandatoryCount == 0 ? tmpUploadM : document?.mandatoryCount;
        if (!isDisable && uploadM < totalM) {
          isDisable = true;
        }
      });
    }

    if (
      !isDisable &&
      selectedLander?.lenderName?.toLowerCase()?.includes("credit saison")
    ) {
      tmpdocumentList1?.map((document) => {
        if (
          document?.documentType?.includes("BANK_STATEMENT") &&
          document?.documentDetails &&
          !document?.documentDetails?.documentAdditionalInfoDto?.bankId
        ) {
          isDisable = true;
        } else if (
          document?.documentType?.includes("BANK_STATEMENT") &&
          document?.documentDetails &&
          document?.documentDetails?.documentAdditionalInfoDto?.bankId !=
            null
        ) {
          isDisable = false;
        } else {
        }
      });
    } else {
    }

    return isDisable;
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",paddingInline: "30px"};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",paddingInline: "30px"};
  const btnCheckboxStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };


  //
  const downloadPopup = () => {
    console.log('::data');
    return (
      <Modal className="model-document-upload" visible={popupVisible} footer={null} onCancel={() => {
        setPopupVisible(false)
        setUploadDocumentList({sucess: [], failed: []});
        }} mask={false}>
        <Row className="header">
          <Col span={24} className="header-title">
            Uploaded {uploadDocumentList.sucess.length} out of {Number(uploadDocumentList.sucess.length) + Number(uploadDocumentList.failed.length)} Files
          </Col>
        </Row>
        {uploadDocumentList?.sucess?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
            {items.status === 'Done' ? (
              <img src={imgTickRight} height={30} width={30} />
            ) : (
              <img className="loading-img" src={imgLoading} height={30} width={30} />
            )}
            </Col>
          </Row>
          );
        })}
        {uploadDocumentList?.failed?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
              <img src={wrong} height={30} width={30} />
            </Col>
          </Row>
          );
        })}
      </Modal>
    );
  };
  // multilender document
  return (
    <>
    <div className="document-upload">{downloadPopup()}</div>
      {showReplace && (
        <>
          <ReplaceDocument
            documentSetId={selectedLander?.documentSetId}
            showReplaceDoc={showReplace}
            setShowReplaceDoc={setShowReplace}
            selectedDocument={selectedDocument}
            isConflict={isConflictClick}
            leadData={leadData}
            getDocPage={getDocPage}
            docReplaceDocs={docReplaceDocs}
            getAllDocumentList={getAllDocumentList}
            selectedLander={selectedLander}
            actionAdd={() => {
              setShowReplace(false);
              setShowUploadDocument(true);
            }}
            callbackSuccess={(docDetail) => {
              changeDocumentDetail(selectedDocument, docDetail);
              setShowReplace(false);
            }}
            callbackDocUploaded={(isUpload, tmpDoc) => {
              if (isUpload) {
                selectedDocument.documentDetails = tmpDoc;
                changeDocumentDetail(selectedDocument, tmpDoc);
              }
              setShowUploadDocument(false);
              setShowReplace(false);
              setIsDocumentUploaded(true);
              // checkedNexBtn();
              // if (isUpload) {
              //   console.log("tmpDoc", tmpDoc);
              //   const tmpDocumentList = tmpdocumentList1?.map((doc) => {
              //     if (
              //       doc?.parentDocId === tmpDoc?.parentDocId &&
              //       doc?.childDocId === tmpDoc?.childDocId && !doc?.headers
              //     ) {
              //       const indexA = masterParentIDs.findIndex(
              //         (val) => val === doc?.parentDocId
              //       );
              //       const indexChild = masterChildIDs.findIndex(
              //         (val) => val === doc?.childDocId
              //       );

              //       if (doc?.childRequired === "yes" && indexChild !== -1) {
              //         masterChildIDs.splice(indexChild, 1);
              //       }
              //       if (doc?.childRequired !== "yes") {
              //         setSelectedChild([...SelectedChild, doc?.parentDocId]);
              //       }
              //       if (indexA !== -1 && doc?.childRequired !== "yes") {
              //         masterParentIDs.splice(indexA, 1);
              //         // setSelectedChild([...SelectedChild, ...a]);
              //       }

              //       return { ...doc, documentDetails: tmpDoc };
              //     }
              //     return doc;
              //   });
              //   checkedNexBtn();
              //   setDocumentList(tmpDocumentList);
              //   setTmpdocumentList1(tmpDocumentList);

              //   let tmpDocumentMappingList = [...documentMappingList];

              //   documentMappingList?.map((item) => {
              //     if (item.documentType == tmpDoc?.documentType) {
              //       const index = tmpDocumentMappingList.findIndex(
              //         (obj) => obj.documentType === tmpDoc?.documentType
              //       );
              //       tmpDocumentMappingList.splice(index, 1);
              //     }
              //     return "";
              //   });

              //   tmpDocumentMappingList.push({
              //     documentId: tmpDoc?.businessDocumentId,
              //     documentType: tmpDoc?.documentType,
              //     mapDocumentType: tmpDoc?.documentName,
              //   });
              //   setDocumentMappingList(tmpDocumentMappingList);
              //   console.log("map list", tmpDocumentMappingList);
              //   console.log("documnet", tmpDoc);
              //   // const tmpArr = [] as any;
              //   // tmpDocumentList?.map((item: any) => {
              //   //   if (item.documentDetails != null) {
              //   //     tmpArr.push(item?.documentDetails?.documentName);
              //   //     // if (item?.require) {
              //   //     // }
              //   //   }
              //   //   return "";
              //   // });
              //   // const uniqueNames = Array.from(new Set(tmpArr));
              //   // setMappedList(uniqueNames);
              // }
            }}
            actionOnDelete={actionOnDelete}
          />
        </>
      )}
      {showRename && (
        <RenameDocument
          showRenameDoc={showRename}
          setShowRenameDoc={setShowRename}
          selectedDocument={selectedDocument}
          callbackSuccess={(docDetail) => {
            changeDocumentDetail(selectedDocument, docDetail);
            setShowRename(false);
          }}
        />
      )}

      {showSkipDoc && (
        <SkipDocument
          showSkipDoc={showSkipDoc}
          setShowSkipDoc={setShowSkipDoc}
          documentListCat={documentListCat}
          lenderId={selectedLander.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          callbackSuccess={() => {
            setShowSkipDoc(false);
            setShowAdditionalFields(false);
            skippedLoginStatus();
          }}
        />
      )}
      {showUploadDocument && (
        <Modal
          title="Upload New Document:"
          centered
          width={400}
          onCancel={() => {
            setShowUploadDocument(false);
          }}
          footer={null}
          visible={showUploadDocument}
        >
          <UploadDocument
            // selectedType={"SIX_MONTH_SALARY_SLIP"}
            isCustomUI={true}
            isLenderDocs={true}
            multiple={true}
            // selectedType={selectedDocument?.internalDocumentType ? selectedDocument?.internalDocumentType : selectedDocument?.docName}
            selectedType={selectedDocument?.documentType ?? ""}
            parentDocId={selectedDocument?.parentDocId}
            childDocId={selectedDocument?.childDocId}
            documentSetId={selectedLander?.documentSetId}
            docOrder={
              selectedDocument?.documentDetails?.docOrder ??
              selectedDocument?.documentOrgType?.split("~")?.at(-1) ??
              0
            }
            docPage={getDocPage()}
            formatDocument={selectedDocument?.format ?? ""}
            maxSize={selectedDocument?.maxSize ?? 5}
            selectedDocument={selectedDocument}
            individualType={leadData.applicationSubType}
            loanProductId={leadData.loanProductId}
            selectedLander = {selectedLander}
            setPopupVisible={setPopupVisible}
            popupVisible={popupVisible}
            uploadDocumentList={uploadDocumentList}
            setUploadDocumentList={setUploadDocumentList}
            callBack={(isUpload, tmpDoc) => {
              selectedDocument.documentDetails = tmpDoc;
              setShowUploadDocument(false);
              setIsDocumentUploaded(true);
              if (isUpload) {
                console.log("tmpDoc", tmpDoc);
                const tmpDocumentList = tmpdocumentList1?.map((doc) => {
                  if (
                    doc?.parentDocId === tmpDoc?.parentDocId &&
                    doc?.childDocId === tmpDoc?.childDocId &&
                    !doc?.headers
                  ) {
                    const indexA = masterParentIDs.findIndex(
                      (val) => val === doc?.parentDocId
                    );
                    const indexChild = masterChildIDs.findIndex(
                      (val) => val === doc?.childDocId
                    );

                    if (
                      doc?.childRequired?.toUpperCase() === "YES" &&
                      indexChild !== -1
                    ) {
                      masterChildIDs.splice(indexChild, 1);
                    }
                    if (doc?.childRequired?.toUpperCase() !== "YES") {
                      setSelectedChild([...SelectedChild, doc?.parentDocId]);
                    }
                    if (
                      indexA !== -1 &&
                      doc?.childRequired?.toUpperCase() !== "YES"
                    ) {
                      masterParentIDs.splice(indexA, 1);
                      // setSelectedChild([...SelectedChild, ...a]);
                    }

                    return { ...doc, documentDetails: tmpDoc };
                  }
                  return doc;
                });
                changeDocumentDetail(selectedDocument, tmpDoc);
                // checkedNexBtn();
                setDocumentList(tmpDocumentList);
                setTmpdocumentList1(tmpDocumentList);

                let tmpDocumentMappingList = [...documentMappingList];

                documentMappingList?.map((item) => {
                  if (item.documentType == tmpDoc?.documentType) {
                    const index = tmpDocumentMappingList.findIndex(
                      (obj) => obj.documentType === tmpDoc?.documentType
                    );
                    tmpDocumentMappingList.splice(index, 1);
                  }
                  return "";
                });

                tmpDocumentMappingList.push({
                  documentId: tmpDoc?.businessDocumentId,
                  documentType: tmpDoc?.documentType,
                  mapDocumentType: tmpDoc?.documentName,
                });
                setDocumentMappingList(tmpDocumentMappingList);
              }
            }}
          />
          {loadingDisplay && (
            <div className="loader-view-flowable">
              <Spin
                tip="We are processing your request, please wait"
                size="large"
              ></Spin>
            </div>
          )}
        </Modal>
      )}
      <div>
        <Row>
          <Col span={2} style={{ paddingInlineStart: "41px" }}>
            <Checkbox
              className="dynamic-pagination-checkbox"
              style={btnCheckboxStyles}
              checked={isAllDocChecked}
              onChange={onChangeCheckboxAll}
            />
          </Col>
          <Col span={6}>{"File Name"}</Col>
          <Col span={5}>{"Lender Docu Name"}</Col>
          <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
            {"Format"}
          </Col>
          <Col span={2}>{"Max Size"}</Col>
          <Col span={6} style={{ textAlign: "center" }}>
            {"Actions"}
          </Col>
        </Row>
        {documentListCat?.map((item) => {
          return (
            <DocCategory
              isFlowable={true}
              documentCategoryList={item}
              actionOnDelete={actionOnDelete}
              actionOnUpload={(doc) => {
                setSelectedDocument(doc);
                setShowUploadDocument(true);
              }}
              actionAdd={actionAdd}
              actionRemove={actionRemove}
              actionReplaceDoc={actionReplaceDoc}
              actionRenameDoc={actionRenameDoc}
              onChangeCheckbox={onChangeCheckbox}
              checkedDocuments={checkedDocuments}
              selectedLander={selectedLander}
              setIsConflictClick={setIsConflictClick}
              updateBankDetails={updateBankDetails}
              callbackUpdateDoc={(documents) => {
                const tmp = [...documentListCat];
                const findExistingItem = tmp.find((item) => {
                  return item.category === documents.category;
                });
                if (findExistingItem) {
                  findExistingItem.docs = documents.docs;
                }
                setDocumentListCat(tmp);
              }}
              bankList={bankList}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          marginBlock: "10px",
        }}
      >
        <Space className="">
          <Button
            className="dynamic-btn-default"
            size="middle"
            style={btnDefaultBorderStyle}
            htmlType="button"
            disabled={failedCount < 3}
            onClick={() => {
              setShowSkipDoc(true);
            }}
          >
            Skip
          </Button>
          <Button
            className="dynamic-btn-primary"
            size="middle"
            style={btnPrimaryStyles}
            loading={loading}
            disabled={getDisableBtn()}
            type="primary"
            htmlType="button"
            onClick={() => onClickNext(tmpdocumentList1)}
          >
            Next
          </Button>
        </Space>
      </div>
    </>
  );
};

export default RequiredDocumentFlowable;
