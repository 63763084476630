import { Checkbox, Form, Input, Space, Typography } from "antd";
import { Rule } from "antd/es/form";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface IFormCheckbox {
  name: string;
  text?: string;
  textElement?: JSX.Element;
  isRequired?: boolean;
  msgRequired?: string;
  onChange?: ((e: CheckboxChangeEvent) => void) | undefined;
  isDisable?: any;
  className?: any;
  style?: any;
}

const FormCheckbox = (props: IFormCheckbox) => {
  const { name, text, textElement, isRequired, msgRequired, onChange, isDisable, className, style } = props;

  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value || !isRequired
              ? Promise.resolve()
              : Promise.reject(new Error(`${msgRequired}`)),
        },
      ]}
    >

      <Checkbox onChange={onChange} disabled={isDisable} className={className} style={style}>
        <Space>
          {isRequired &&
            <p style={{ color: "red", marginTop: "-2rem" }}>*</p>
          }
          {textElement ?? text}
        </Space>
      </Checkbox>
    </Form.Item>
  );
};

export default FormCheckbox;
