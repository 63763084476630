import { Row, Typography, Button, Col, Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "shared/services/Utility";
import add from "../../../../assets/images/add.svg";
import {ReactComponent as AddBorder} from "../../../../assets/images/add-border.svg";
import DisbursedGroup from "./DisbursedGroup";

import "./index.scss";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;

const Disbursed: React.FunctionComponent<any> = ({
  lender,
  lenderIndex,
  onActionSubmit,
  leadData,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
}) => {
  const [accountTypes, setAccountTypes] = useState([] as any);
  const [finalLender, setFinalLander] = useState({} as any);
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState(0);
  const [isAddCheck, setIsAddCheck] = useState(false);
  const [addBtnErr, setAddBtnErr] = useState("");
  const {appColors}: any = useSelector((state: RootState) => state.common);
  useEffect(() => {
    setEnumValues();
  }, []);

  useEffect(() => {
    lender?.disbursalLenderInfoDetails?.map((amount) => {
      amount.lenderAmount.isDisabled = amount.lenderAmount.disbursedAmount > 0;
    });
    setFinalLander(lender);
    calcTotalDisbursedAmount(lender?.disbursalLenderInfoDetails);
  }, [lender]);

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let accountType = enumValues.find(
        (item: any) => item.propertyName == "ACCOUNT_TYPE"
      );
      if (accountType) {
        setAccountTypes(accountType.keyAndDisplayValueList);
      }
    }
  };

  const isAddEneble = (item) => {
    let amountDisbursed = item.disbursalLenderInfoDetails.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    // let amountInsurance = item.disbursalLenderInfoDetails.map((item) =>
    //   Number(item.lenderAmount.insuranceAmount)
    // );
    const totalDisbursed = amountDisbursed.reduce((a, b) => a + b, 0);
    // const totalInsurance = amountInsurance.reduce((a, b) => a + b, 0);
    const total = totalDisbursed;
    return total < item.sanctionedAmount;
  };

  const setAddButton = (isHidden, lender, lenderIndex, index) => {
    return (
      <Tooltip
        title={isAddEneble(lender) ? "" : "You disbursed all sanctioned amount"}
        placement="topRight"
      >
        <Button
         className="btn-add"
         style={{"--bgColor": appColors?.appSecondaryColor ?? ""} as any}
         type="text"
          hidden={isHidden}
          onClick={() => {
            if (!isAddEneble(lender)) {
              return;
            }
            if(isAddCheck){
              return notification.error({message:addBtnErr})
            }
            const lenderInfo = {
              disbursedId: null,
              lenderAmount: {
                disbursedAmount: null,
                insuranceAmount: 0,
                payoutPercentage:
                  leadData?.caseDetails?.connectorDetails?.payoutType == "FIXED"
                    ? leadData?.caseDetails?.connectorDetails?.payoutPercentage
                    : leadData?.sourceOfCase == "CONNECTOR"
                    ? leadData?.caseDetails?.loanDetails?.expectedInterestRate
                    : null,
              },
            };
            const tmpLender = { ...finalLender };
            const disburselLenderInfo = [
              ...tmpLender.disbursalLenderInfoDetails,
            ];
            disburselLenderInfo.push(lenderInfo);
            tmpLender.disbursalLenderInfoDetails = disburselLenderInfo;

            if ((tmpLender?.payoutDetails?.length ?? 0) > 0) {
              const tmpPayoutDetails = tmpLender.payoutDetails?.map(
                (payoutDetail) => {
                  const tmpPayoutDetail = payoutDetail?.map((detail) => {
                    const tmpDetail = { ...detail };
                    tmpDetail.disbursalGroup = `${index + 1}`;
                    tmpDetail.accountType = "";
                    tmpDetail.paymentType = null;
                    tmpDetail.payoutAmount = "";
                    tmpDetail.payoutId = null;
                    return tmpDetail;
                  });
                  return tmpPayoutDetail;
                }
              );
              const payoutDetails = [...tmpLender.payoutDetails];
              if (tmpPayoutDetails[0]) {
                payoutDetails.push(tmpPayoutDetails[0]);
              }
              tmpLender.payoutDetails = payoutDetails;
            }
            setFinalLander(tmpLender);
          }}
        >
          <AddBorder width={40} height={40}/>
        </Button>
      </Tooltip>
    );
  };

  const calcTotalDisbursedAmount = (disbursalLenderInfoDetails) => {
    let amountDisbursed = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    // let amountInsurance = disbursalLenderInfoDetails?.map((item) =>
    //   Number(item.lenderAmount.insuranceAmount)
    // );
    const totalDisbursed = amountDisbursed?.reduce((a, b) => a + b, 0);
    // const totalInsurance = amountInsurance?.reduce((a, b) => a + b, 0);
    const total = totalDisbursed ;
    setTotalDisbursedAmount(isNaN(total) ? 0 : total);
  };

  return (
    <div style={{ marginTop: "16px" }}>
      {finalLender?.disbursalLenderInfoDetails?.map((payment, index) => {
        const isHidden = index !== 0;

        return (
          <>
            {!isHidden && (
              <>
                <Row className="disbursed-lenders-header">
                  <Col span={6} className="disbursed-heading">
                    Lender
                  </Col>
                  <Col span={8} className="disbursed-heading">
                    Sanctioned Amount
                  </Col>
                  <Col span={8} className="disbursed-heading">
                    Disbursed Amount
                  </Col>
                  <Col span={2}></Col>
                </Row>
                <Row className="disbursed-lenders-header">
                  <Col span={6} className="disbursed-heading">
                    <Title level={5}>{lender?.lenderName}</Title>
                  </Col>
                  <Col span={8} className="disbursed-heading">
                    <Title level={5}>
                      {lender?.sanctionedAmount &&
                        numberWithCommas(lender?.sanctionedAmount)}
                    </Title>
                  </Col>
                  <Col span={8} className="disbursed-heading">
                    <Title level={5}>
                      {totalDisbursedAmount &&
                        numberWithCommas(totalDisbursedAmount)}
                    </Title>
                  </Col>
                  <Col span={2}>
                    {setAddButton(isHidden, lender, lenderIndex, index)}
                  </Col>
                </Row>
              </>
            )}

            <DisbursedGroup
              lender={finalLender}
              leadData={leadData}
              lenderIndex={lenderIndex}
              setIsAddDisabled={setIsAddCheck}
              setAddBtnErr={setAddBtnErr}
              getRedirectURLLink={getRedirectURLLink}
              payment={payment}
              index={index}
              payoutDetailIndex={index}
              payoutType
              loadingUpdate={loadingUpdate}
              getDownloadLetterForICICI={getDownloadLetterForICICI}
              onAmountChange={() => {
                calcTotalDisbursedAmount(
                  finalLender?.disbursalLenderInfoDetails
                );
              }}
              onActionSubmit={(newLander, comment) => {
                lender = { ...newLander };
                onActionSubmit(newLander, comment);
              }}
            />
          </>
        );
      })}
    </div>
  );
};

export default Disbursed;
