import { Button, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import "./index.scss";

interface ISkipDocument {
  showSkipDoc: boolean;
  setShowSkipDoc: any;
  documentListCat: any;
  lenderId: any;
  caseId: any;
  callbackSuccess?: any;
}

const SkipDocument: React.FunctionComponent<ISkipDocument> = (
  props: ISkipDocument
) => {
  const {
    showSkipDoc,
    setShowSkipDoc,
    documentListCat,
    lenderId,
    caseId,
    callbackSuccess,
  } = props;

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<any>({});

  useEffect(() => {
    let uploadedDocs = 0;
    let mandatoryCount = 0;
    let totalDocs = 0;
    documentListCat.map((doc: any) => {
      uploadedDocs = uploadedDocs + (doc?.uploadedDocs ?? 0);
      mandatoryCount = mandatoryCount + (doc?.mandatoryCount ?? 0);
      totalDocs = totalDocs + (doc?.totalDocs ?? 0);
    });

    setCount({ uploadedDocs, totalDocs, pending: totalDocs - mandatoryCount });
  }, []);

  const postLenderDocumentUploadDetails = () => {
    API_SERVICE.postLenderDocumentUploadDetails(lenderId, caseId)
      .then(({ data }) => {
        if (data) {
          if (data?.payload) {
            callbackSuccess({});
          }
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  return (
    <>
      <Modal
        title="Document"
        centered
        width={350}
        onCancel={() => setShowSkipDoc(false)}
        footer={null}
        visible={showSkipDoc}
      >
        <div className="rename-doc">
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <span style={{ fontWeight: "300" }}>
              Required: <span>{count?.totalDocs}</span>
            </span>
            <span style={{ fontWeight: "300" }}>
              Uploaded: <span>{count?.uploadedDocs}</span>
            </span>
          </div>
          <p
            style={{
              fontWeight: "300",
              textAlign: "center",
              marginTop: "20px",
            }}
          >{`Are you sure you want to skip this stage, there are ${count?.pending} document pending to submit`}</p>
          <div className="buttons">
            <Button
              type="primary"
              onClick={() => postLenderDocumentUploadDetails()}
              loading={loading}
            >
              Yes
            </Button>
            <Button
              type="default"
              className="btn-default-bg"
              onClick={() => setShowSkipDoc(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SkipDocument;
