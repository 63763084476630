/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Input,
  Button,
  Space,
  Checkbox,
  notification,
  Modal,
  Tooltip,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import {ReactComponent as IconApprove} from "../../../../assets/images/apporveIcon.svg";
import API_SERVICE from "shared/services/api-service";
import "formiojs/dist/formio.builder.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import shareIcon from "../../../../assets/images/shareIcon.svg";
import DocumentStatus from "../RequiredDocuments/DocumentStatus";
import copy from "../../../../assets/images/copy.svg";
import parameters from "shared/parameters";
import ConsentDetails from "./ConsentDetails";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { TextArea } = Input;
const { Title } = Typography;

const LenderLogin: React.FunctionComponent<any> = ({
  leadData,
  assignedLenders,
  lenderLoginHandler,
  setShowRequirementDocs,
  setShowAdditionalFields,
  setAdditionalFields,
  setSelectedLander,
  callbackAdditionalField,
  callbackReqDoc,
  setCallbackAdditionalField,
  setCallbackReqDoc,
  loading,
  setFormData,
  setTaskDetails,
  setCurrLenderId,
  setCurrLoanProductId,
  setCurrLeadId,
  setCustomUI,
  getRedirectURLLink,
  isLastTask,
  setIsLastTask,
  getCashETriggerSMSLink,
  skippedLoginStatus,
  getDownloadLetterForICICI,
}) => {
  const [comment, setComment] = useState(null as any);
  const [showConsentDetails, setShowConsentDetails] = useState(false);
  const [assignedLenderData, setAssignedLenderData] = useState(assignedLenders);
  const [assignedLenderFiltered, setAssignedLenderFiltered] = useState(
    [] as any
  );
  const [showUpdateStatusOnNextClick, setShowUpdateStatusOnNextClick] =
    useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState({} as any);
  const [updateStatusType, setUpdateStatusType] = useState("");
  const [showDocumentStatus, setShowDocumentStatus] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [selectedLender, setSelectedLender] = useState({} as any);
  const {appColors}: any = useSelector((state: RootState) => state.common);


  const flowableResumableLender = [
    "icici bank",
    "hdfc bank",
    "aditya birla capital finance ltd (abcfl)",
    "aditya birla capital ltd (abcl)",
    "credit saison",
    "bajaj housing finance",
    "prefr"
  ];

  useEffect(() => {
    assignedLenders.map((item) => {
      item.checked = false;
    });
    setAssignedLenderData(assignedLenders);

    const tmp = assignedLenders?.filter(
      (item) =>
        [
          "LOGGED",
          "SANCTIONED",
          "DISBURSED",
          "REJECTED",
          "PENDENCY",
          "WITHDRAWN",
        ].includes(item.status) ||
        (flowableResumableLender.includes(item.lenderName.toLowerCase()) &&
          item.processInstanceId)
    );
    setAssignedLenderFiltered(tmp);
  }, []);

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    str = str?.replaceAll(
      /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
      parameters.ApiUrlOne
    );
    str = str.replaceAll(
      /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
      parameters.ApiUrlTwo
    );
    str = str?.replaceAll(
      /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
      parameters.ApiUrlThree
    );
    return JSON.parse(str);
  };

  const getLenderAdditonalField = (apiIntegratedLender) => {
    setCurrLenderId(apiIntegratedLender.lenderInfoId);
    setCurrLeadId(leadData?.caseDetails?.caseId);
    setCurrLoanProductId(leadData?.loanProductId);
    // setShowAdditionalFields(true);
    API_SERVICE.getLenderAdditonalField(
      apiIntegratedLender.lenderInfoId,
      leadData?.caseDetails?.caseId,
      leadData?.loanProductId
    )
      .then(({ data }) => {
        if (data?.payload?.workflowType === "FLOWABLE") {
          setAdditionalFields([]);
          setSelectedLander(apiIntegratedLender);
          setShowAdditionalFields(true);
          // setIsLastTask();
          setCustomUI(data?.payload?.taskDetail?.customUI ?? false);
          setIsLastTask(data?.payload?.taskDetail?.lastStep);
          setFormData(
            getJSONSchema(
              data?.payload?.taskDetail?.jsonSchema
                .replaceAll("console.log(value);", "")
                .replaceAll(`"required": true`, `"required": false`)
            )
          );
          setTaskDetails({
            taskName: data?.payload?.taskDetail?.taskName,
            taskDefinitionKey: data?.payload?.taskDetail?.taskDefinitionKey,
            taskId: data?.payload?.taskDetail?.taskId,
            responseToRender: data?.payload?.taskDetail?.responseToRender,
          });
        } else {
          if ((data?.payload?.lenderFieldValues?.length ?? 0) > 0) {
            setAdditionalFields(data?.payload?.lenderFieldValues);
            // setAdditionalFields(dummyAdditionalFieldData);
            setSelectedLander(apiIntegratedLender);
            setShowAdditionalFields(true);
            // setShowRequirementDocs(true);
          } else {
            setCheckedLeader(true, apiIntegratedLender.lenderInfoId);
          }
        }
      })
      .catch((e: any) => {
        if (
          apiIntegratedLender?.lenderName?.toLowerCase()?.includes("kreditbee")
        ) {
          setCheckedLeader(true, apiIntegratedLender.lenderInfoId);
          notification.success({ message: "Lead updated successfully" });
          skippedLoginStatus();
        } else {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setCheckedLeader(false, apiIntegratedLender.lenderInfoId);
        }
      });
  };

  const checkIsFlowableLender = (lenderName: string) => {
    return (
      lenderName?.toLowerCase().includes("kotak") ||
      lenderName?.toLowerCase().includes("icici") ||
      lenderName?.toLowerCase().includes("hdfc") ||
      lenderName?.toLowerCase().includes("flexi") ||
      lenderName?.toLowerCase().includes("kreditbee") ||
      lenderName?.toLowerCase().includes("moneywide") ||
      lenderName?.toLowerCase().includes("casee") ||
      lenderName?.toLowerCase().includes("abcfl") ||
      lenderName?.toLowerCase().includes("abcl") ||
      lenderName?.toLowerCase().includes("nira finance") ||
      lenderName?.toLowerCase().includes("credit saison") ||
      lenderName?.toLowerCase().includes("bajaj housing finance") ||
      lenderName?.toLowerCase().includes("prefr") ||
      lenderName?.toLowerCase().includes("incred")

    );
  };

  const checkShowUpdateStatusOnNextStep = () => {
    //TODO: If change anything in this method then same as change in setupLender()
    let apiIntegratedLender1 = assignedLenderData?.find((item) => {
      if (item.lenderName.toLowerCase().includes("icici")) {
        return (
          item?.submissionType?.includes("API") &&
          item.processInstanceId &&
          !item.additionalFields &&
          ![
            "LOGGED",
            "SANCTIONED",
            "DISBURSED",
            "REJECTED",
            "PENDENCY",
            "WITHDRAWN",
          ].includes(item.status)
        );
      }
      return (
        item?.submissionType?.includes("API") &&
        item.processInstanceId &&
        !item?.allDocumentUploaded &&
        (["LOGGED"].includes(item.status) ||
          (flowableResumableLender.includes(item.lenderName.toLowerCase()) &&
            item.processInstanceId)) &&
        !item?.allDocumentUploaded &&
        ![
          "LOGGED",
          "SANCTIONED",
          "DISBURSED",
          "REJECTED",
          "PENDENCY",
          "WITHDRAWN",
        ].includes(item.status)
      );
    });
    if (apiIntegratedLender1) {
      setShowUpdateStatusOnNextClick(true);
      setShowUpdateStatus(apiIntegratedLender1);
    } else {
      const filtered = assignedLenderFiltered?.filter((item) => {
        const isDocUploaded =
          item?.submissionType != "API" ||
          (item?.submissionType == "API" && item?.allDocumentUploaded);
        const isDisabled =
          isDocUploaded &&
          [
            "LOGGED",
            "SANCTIONED",
            "DISBURSED",
            "REJECTED",
            "PENDENCY",
            "WITHDRAWN",
          ].includes(item.status);
        return (
          (item?.checked || isDisabled) &&
          (item?.lenderApplicationId?.length ?? 0) > 0 &&
          !item?.allDocumentUploaded &&
          !item?.isOffline
        );
      });
      if ((filtered?.length ?? 0) > 0) {
        setSelectedLander(filtered[0]);
        if (
          Object.keys(callbackReqDoc).length == 0 &&
          callbackAdditionalField?.showRequirementDocs != "false"
        ) {
          if (checkIsFlowableLender(filtered[0]?.lenderName)) {
            setShowUpdateStatusOnNextClick(true);
            setShowUpdateStatus(filtered[0]);
          } else {
            setShowUpdateStatusOnNextClick(true);
            setShowUpdateStatus(filtered[0]);
          }
        }
        return;
      } else {
        const loggedLender = assignedLenderFiltered?.filter(
          (lader: any) =>
            lader?.status == "ASSIGNED" ||
            (lader?.submissionType == "API" && lader?.isOffline)
        );
        // console.log("loggedLender: ", loggedLender);
        // return;
        lenderLoginHandler({ comment, assignedLenderFiltered: loggedLender });
      }
    }
  };

  const setupLender = (updateStatusLender?: any) => {
    //TODO: If change anything in this method then same as change in checkShowUpdateStatusOnNextStep()
    let apiIntegratedLender1 = assignedLenderData?.find((item) => {
      if (item.lenderName.toLowerCase().includes("icici")) {
        return (
          item?.submissionType?.includes("API") &&
          item.processInstanceId &&
          !item.additionalFields &&
          ![
            "LOGGED",
            "SANCTIONED",
            "DISBURSED",
            "REJECTED",
            "PENDENCY",
            "WITHDRAWN",
          ].includes(item.status)
        );
      }
      return (
        item?.submissionType?.includes("API") &&
        item.processInstanceId &&
        !item?.allDocumentUploaded &&
        (["LOGGED"].includes(item.status) ||
          (flowableResumableLender.includes(item.lenderName.toLowerCase()) &&
            item.processInstanceId)) &&
        !item?.allDocumentUploaded &&
        ![
          "LOGGED",
          "SANCTIONED",
          "DISBURSED",
          "REJECTED",
          "PENDENCY",
          "WITHDRAWN",
        ].includes(item.status)
      );
    });
    console.log("apiIntegratedLender1: ", apiIntegratedLender1);
    if (apiIntegratedLender1) {
      if (apiIntegratedLender1) {
        setAdditionalFields([]);
        setCurrLenderId(apiIntegratedLender1?.lenderInfoId);
        setCurrLeadId(leadData?.caseDetails?.caseId);
        setSelectedLander(apiIntegratedLender1);
        setShowAdditionalFields(true);
        // getLenderAdditonalField(apiIntegratedLender1);
      }
    } else {
      const filtered = updateStatusLender
        ? [updateStatusLender]
        : assignedLenderFiltered?.filter((item) => {
            const isDisabled = [
              "LOGGED",
              "SANCTIONED",
              "DISBURSED",
              "REJECTED",
              "PENDENCY",
              "WITHDRAWN",
            ].includes(item.status);
            return (
              (item?.checked || isDisabled) &&
              (item?.lenderApplicationId?.length ?? 0) > 0 &&
              !item?.allDocumentUploaded
            );
          });
      console.log("assignedLenderFiltered: ", assignedLenderFiltered);
      console.log("filtered: ", filtered);
      if ((filtered?.length ?? 0) > 0) {
        setSelectedLander(filtered[0]);
        if (
          Object.keys(callbackReqDoc).length == 0 &&
          callbackAdditionalField?.showRequirementDocs != "false"
        ) {
          if (checkIsFlowableLender(filtered[0]?.lenderName)) {
            setAdditionalFields([]);
            setCurrLenderId(filtered[0]?.lenderInfoId);
            setCurrLeadId(leadData?.caseDetails?.caseId);
            setSelectedLander(filtered[0]);
            setShowAdditionalFields(true);
          } else {
            setShowRequirementDocs(true);
          }
        }
        return;
      } else {
        const loggedLender = assignedLenderFiltered?.filter(
          (lader: any) => lader?.status == "ASSIGNED"
        );
        lenderLoginHandler({ comment, assignedLenderFiltered: loggedLender });
      }
    }
  };

  const onChangeLender = (ev: any, index: number) => {
    if (ev.target.checked) {
      let apiIntegratedLender = assignedLenderData?.find(
        (item) =>
          item.lenderInfoId === ev.target.value &&
          item?.submissionType?.includes("API")
        // &&
        // (item?.lenderApplicationId?.length ?? 0) == 0
      );
      if (ev.target.checked && apiIntegratedLender) {
        // getLenderAdditonalField(apiIntegratedLender);
        setShowUpdateStatus(apiIntegratedLender);
        return;
      }
    }
    setCheckedLeader(ev.target.checked, ev.target.value);
  };

  const setCheckedLeader = (
    checked,
    lenderInfoId,
    isOffline: boolean = false
  ) => {
    const tempLender = [...assignedLenderData];
    let lender = tempLender.find((item) => item.lenderInfoId === lenderInfoId);
    lender.rmName = lender?.rmName ? lender?.rmName : "";
    lender.checked = checked;
    lender.openInput = checked;
    lender.isOffline = isOffline;
    setAssignedLenderData(tempLender);

    const tempFiltered = [...assignedLenderFiltered];
    if (checked) {
      tempFiltered.push(lender);
    } else {
      let index = tempLender.findIndex(
        (item) => item.lenderInfoId === lenderInfoId
      );
      if (index !== -1) {
        tempFiltered.splice(index, 1);
      }
    }
    setAssignedLenderFiltered(tempFiltered);
  };

  const rmInputHandler = (ev: any, i: any) => {
    const tempLender = [...assignedLenderData];
    assignedLenderData[i].rmName = ev.target.value;
    setAssignedLenderData(tempLender);
  };

  useEffect(() => {
    if (Object?.keys(callbackAdditionalField)?.length !== 0) {
      if ((callbackAdditionalField?.loanId?.length ?? 0) > 0) {
        const tempLender = [...assignedLenderData];
        let lender = tempLender.find(
          (item) => item.lenderInfoId === callbackAdditionalField?.lenderInfoId
        );
        lender.lenderApplicationId = callbackAdditionalField?.loanId ?? "";
        setAssignedLenderData(tempLender);
      }
      setCheckedLeader(true, callbackAdditionalField?.lenderInfoId);
      if (
        Object.keys(callbackReqDoc).length == 0 &&
        callbackAdditionalField?.showRequirementDocs != "false"
      ) {
        setShowRequirementDocs(true);
      }
    } else {
      setSelectedLander({});
    }
    setAdditionalFields({});
  }, [callbackAdditionalField]);

  useEffect(() => {
    if (Object.keys(callbackReqDoc).length !== 0) {
      const tempLender = [...assignedLenderData];
      let lender = tempLender.find(
        (item) => item.lenderInfoId === callbackReqDoc.lenderInfoId
      );
      lender.allDocumentUploaded = true;
      setAssignedLenderData(tempLender);

      setShowRequirementDocs(false);
      setSelectedLander({});
      setCallbackAdditionalField({});
      setCallbackReqDoc({});
    }
  }, [callbackReqDoc]);

  const renderDocumentStatus = (lender) => {
    const total = lender?.documentProgressDetails?.totalNumberOfDocs ?? 0;
    const submitted =
      lender?.documentProgressDetails?.documentsSuccessfullySubmitted ?? 0;
    const failed = lender?.documentProgressDetails?.failure ?? 0;
    return (
      <>
        {total > 0 && (submitted > 0 || failed > 0) && (
          <span
            className="checkbox-text small"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDocumentDetails(
                lender?.documentProgressDetails?.submissionDetails
              );
              setSelectedLender(lender);
              setShowDocumentStatus(true);
            }}
          >
            {`Document Status: ${submitted}/${total}`}
          </span>
        )}
      </>
    );
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const btnRadioStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "",alignSelf: "baseline" };
  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};

  const renderUpdateStatus = () => {
    return (
      <Modal
        centered
        width={350}
        footer={null}
        visible={!!Object.keys(showUpdateStatus).length}
        closable={false}
      >
        <div style={{ display: "grid", gap: "20px", marginTop: "20px" }}>
          <span>Select the Option</span>
          <Radio.Group
            className="dynamic-radio"
            style={{"--borderColor": appColors?.appPrimaryColor ?? ""} as any}
            onChange={(e) => setUpdateStatusType(e?.target?.value)}
            name="applicantType"
          >
            <Space direction="vertical">
              <Radio value={"API"}>{"Send Case via API"}</Radio>
              <Radio value={"OFFLINE"}>
                {"Update Status of an Offline Case"}
              </Radio>
            </Space>
          </Radio.Group>
          <Space style={{ marginBottom: "20px" }}>
            <Button
              size="large"
              className="button-submit dynamic-btn-primary"
              style={btnPrimaryStyles}
              loading={loading}
              type="primary"
              onClick={() => {
                if (showUpdateStatusOnNextClick) {
                  if (updateStatusType == "API") {
                    setupLender();
                    setShowUpdateStatusOnNextClick(false);
                  } else if (updateStatusType == "OFFLINE") {
                    lenderLoginHandler({
                      comment,
                      assignedLenderFiltered: [
                        { ...showUpdateStatus, isOffline: true },
                      ],
                    });
                    setShowUpdateStatus({});
                  }
                } else {
                  if (updateStatusType == "API") {
                    console.log("showUpdateStatus: ", showUpdateStatus);
                    if (
                      showUpdateStatus.processInstanceId ||
                      (showUpdateStatus?.lenderApplicationId?.length ?? 0) > 0
                    ) {
                      setupLender(showUpdateStatus);
                      // setAdditionalFields([]);
                      // setSelectedLander(showUpdateStatus);
                      // setCurrLenderId(showUpdateStatus?.lenderInfoId);
                      // setCurrLeadId(leadData?.caseDetails?.caseId);
                      // setShowAdditionalFields(true);
                    } else {
                      getLenderAdditonalField(showUpdateStatus);
                    }
                    setShowUpdateStatus({});
                  } else if (updateStatusType == "OFFLINE") {
                    setCheckedLeader(
                      true,
                      showUpdateStatus?.lenderInfoId,
                      true
                    );
                    setShowUpdateStatus({});
                  }
                }
              }}
            >
              Submit
            </Button>
            <Button
              size="large"
              className="button-submit dynamic-btn-default"
              style={btnDefaultBorderStyle}
              htmlType="button"
              onClick={() => setShowUpdateStatus({})}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Modal>
    );
  };

  const renderConsentDetails = () => {
    return (
      <ConsentDetails
        show={showConsentDetails}
        setShow={setShowConsentDetails}
      />
    );
  };
  const isLenderLogged = (findLender) => {
    const isDocUploaded =
    findLender?.submissionType != "API" ||
    (findLender?.submissionType == "API" &&
      findLender?.allDocumentUploaded) ||
    ["REJECTED", "WITHDRAWN", "PENDENCY"].includes(findLender?.status);
  const isLogged =
    isDocUploaded &&
    [
      "LOGGED",
      "SANCTIONED",
      "DISBURSED"
    ].includes(findLender?.status)
   return isLogged;
  }

  return (
    <>
      {Object.keys(showUpdateStatus).length > 0 && renderUpdateStatus()}
      {showConsentDetails && renderConsentDetails()}
      {showDocumentStatus && (
        <DocumentStatus
          showDocumentStatus={showDocumentStatus}
          setShowDocumentStatus={setShowDocumentStatus}
          lenderInfoId={selectedLender?.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          documentDetails={documentDetails}
        />
      )}
      <Title level={5}>Login</Title>
      <div className="assigned-lender-list">
        <Space direction="vertical">
          {assignedLenderData?.map((item, i) => {
            const isDocUploaded =
              item?.submissionType != "API" ||
              (item?.submissionType == "API" && item?.allDocumentUploaded);
            const isDisabled =
              [
                "SANCTIONED",
                "DISBURSED",
                "REJECTED",
                "WITHDRAWN",
              ].includes(item.status) ||
              (isDocUploaded &&
              [
                "LOGGED",
                "SANCTIONED",
                "DISBURSED",
                "REJECTED",
                "PENDENCY",
                "WITHDRAWN",
              ].includes(item.status));
            let consentDetails: any = {};
            if ((item?.consentDetails?.length ?? 0) > 0) {
              consentDetails = JSON.parse(item?.consentDetails ?? {});
            }

            return (
              item.status != "UNASSIGNED" && (
                <div className="assigned-list" key={i}>
                  <Checkbox
                    className="lender-checkbox dynamic-pagination-checkbox"
                    style={btnRadioStyles}
                    disabled={isDisabled}
                    checked={
                      isDisabled || item.checked
                      // ||
                      // (flowableResumableLender.includes(
                      //   item.lenderName.toLowerCase()
                      // ) &&
                      //   item.processInstanceId)
                    }
                    onChange={(ev) => onChangeLender(ev, i)}
                    value={item.lenderInfoId}
                    //style={{ alignSelf: "baseline" }}
                  >
                    <div className="checkbox-text-div">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="checkbox-text">{item.lenderName}</span>
                        {Object.keys(consentDetails).length > 0 && (
                          <Tooltip
                            title={`Time stemp: ${consentDetails?.timeStamp}`}
                          >
                            <span className="api-intergrated-text" style={{"--appSecondary": appColors?.appSecondaryColor ?? ""} as any}>
                              <IconApprove /> {"Consent"}
                            </span>
                          </Tooltip>
                        )}
                      </div>

                      {item?.submissionType?.includes("API") && (
                        <Space>
                          {/* {
                            item?.lenderApplicationId !== null?  <img src={iconSave} alt="" width={14} height={14} />:""
                          } */}
                          <span className="api-intergrated-text" style={{"--appSecondary": appColors?.appSecondaryColor ?? ""} as any}>{"API"}</span>
                        </Space>
                      )}
                    </div>
                  </Checkbox>
                  {  !item?.lenderName?.toLowerCase()?.includes("bajaj housing finance")  && (
                  <div className="checkbox-text-div">
                    {item?.submissionType?.includes("API") &&
                      (item?.lenderApplicationId?.length ?? 0) > 0 && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="checkbox-text small">{`Lender loan ID: ${item.lenderApplicationId}`}</span>
                        </div>
                      )}
                    {renderDocumentStatus(item)}
                  </div>
                  )}

                  {/* BAJAJ HOSUING FINANCE Changes */}

                {  item?.lenderName?.toLowerCase()?.includes("bajaj housing finance")  && (
                  <div className="checkbox-text-div">
                    {item?.submissionType?.includes("API") &&
                      item?.additionalFields?.length > 0 &&
                       item?.lenderName?.toLowerCase()?.includes("bajaj housing finance") && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="checkbox-text small">{`Lender lead ID: ${JSON.parse(item?.additionalFields)?.leadId}`}</span>
                          <Button
                            style={{
                              borderColor: "transparent",
                              fontSize: "12px",
                            }}
                            size="small"
                            shape="circle"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                JSON.parse(item?.additionalFields)?.leadId ??
                                  ""
                              );
                              notification.success({
                                message: "Copied to clipboard",
                              });
                            }}
                            icon={<img src={copy} alt="" width={12} />}
                          />
                        </div>
                      )}
                  </div>
                  
                )}

                {  item?.lenderName?.toLowerCase()?.includes("bajaj housing finance")  && (
                  <div className="checkbox-text-div">
                    {item?.submissionType?.includes("API") &&
                      item?.additionalFields?.length > 0 &&
                       item?.lenderName?.toLowerCase()?.includes("bajaj housing finance") && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="checkbox-text small">{`Lender Offer ID: ${JSON.parse(item?.additionalFields)?.offerId}`}</span>
                          <Button
                            style={{
                              borderColor: "transparent",
                              fontSize: "12px",
                            }}
                            size="small"
                            shape="circle"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                JSON.parse(item?.additionalFields)?.offerId ??
                                  ""
                              );
                              notification.success({
                                message: "Copied to clipboard",
                              });
                            }}
                            icon={<img src={copy} alt="" width={12} />}
                          />
                        </div>
                      )}
                  </div>
                  
                )}
                  
                  {/* ICICI Changes */}
                  <div className="checkbox-text-div">
                    {item?.submissionType?.includes("API") &&
                      item?.additionalFields?.length > 0 &&
                      item?.lenderName?.toLowerCase()?.includes("icici") && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="checkbox-text small">{`iLens ID: ${
                            JSON.parse(item?.additionalFields)?.ilensId
                          }`}</span>
                          <Button
                            style={{
                              borderColor: "transparent",
                              fontSize: "12px",
                            }}
                            size="small"
                            shape="circle"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                JSON.parse(item?.additionalFields)?.ilensId ??
                                  ""
                              );
                              notification.success({
                                message: "Copied to clipboard",
                              });
                            }}
                            icon={<img src={copy} alt="" width={12} />}
                          />
                        </div>
                      )}
                  </div>
                  <div className="checkbox-text-div">
                    {item?.submissionType?.includes("API") &&
                      item?.additionalFields?.length > 0 &&
                      item?.lenderName?.toLowerCase()?.includes("icici") && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="checkbox-text small">{`Application Form No: ${
                            JSON.parse(item?.additionalFields)
                              ?.applicationFormNo
                          }`}</span>
                          <Button
                            style={{
                              borderColor: "transparent",
                              fontSize: "12px",
                            }}
                            size="small"
                            shape="circle"
                            className="small"
                            // style={{ fontSize: "12px" }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                JSON.parse(item?.additionalFields)
                                  ?.applicationFormNo ?? ""
                              );
                              notification.success({
                                message: "Copied to clipboard",
                              });
                            }}
                            icon={<img src={copy} alt="" width={12} />}
                          />
                        </div>
                      )}
                  </div>
                  <div className="checkbox-text-div">
                    {item?.submissionType?.includes("API") &&
                      (item?.lenderApplicationId?.length ?? 0) > 0 && (
                        <span className="checkbox-text small">
                          {(["BU9709497975", "BU3620294738"].includes(
                            item.lenderInfoId
                          ) ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("icici")) && (
                            <>
                              <a
                                className="small"
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                  getDownloadLetterForICICI(item?.lenderInfoId);
                                }}
                              >
                                {item.lenderName.split(" ")[0]} Download letter
                              </a>
                            </>
                          )}
                        </span>
                      )}
                  </div>
                  {/* ICIC changes */}
                  <div className="checkbox-text-div">
                    <span className="checkbox-text small">
                      {/* {item.hubName} */}
                    </span>
                    {item?.submissionType?.includes("API") &&
                      (item?.lenderApplicationId?.length ?? 0) > 0 && (
                        <span className="checkbox-text small">
                          {(["BU9709497975", "BU3620294738"].includes(
                            item.lenderInfoId
                          ) ||
                            item?.lenderName?.toLowerCase()?.includes("fibe") ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("moneywide") ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("cashe") ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("kreditbee") ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("abcfl") ||
                            item?.lenderName?.toLowerCase()?.includes("abcl") ||
                            item?.lenderName?.toLowerCase()?.includes("prefr") ||
                            item?.lenderName?.toLowerCase()?.includes("incred") ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("nira finance") ||
                            item?.lenderName
                              ?.toLowerCase()
                              ?.includes("credilio")) && isLenderLogged(item) && (
                            <>
                              <a
                                onClick={() =>
                                  getRedirectURLLink(
                                    item?.lenderInfoId,
                                    leadData?.loanProductId,
                                    item?.lenderApplicationId
                                  )
                                }
                                className="small"
                                style={{ fontSize: "12px" }}
                              >
                                {item?.lenderName
                                  ?.toLowerCase()
                                  ?.includes("abcfl") ||
                                item?.lenderName
                                  ?.toLowerCase()
                                  ?.includes("abcl")
                                  ? "Lender Link"
                                  : ` Lender Link`}
                              </a>
                              <Button
                                size="small"
                                style={{ margin: "2px", border: "none" }}
                                onClick={() =>
                                  getRedirectURLLink(
                                    item?.lenderInfoId,
                                    leadData?.loanProductId,
                                    item?.lenderApplicationId,
                                    true
                                  )
                                }
                                icon={
                                  <img
                                    src={shareIcon}
                                    alt=""
                                    width={18}
                                    height={18}
                                    style={{ marginTop: "-4px" }}
                                  />
                                }
                              />
                              {item?.lenderName
                                ?.toLowerCase()
                                ?.includes("cashe") && (
                                <Button
                                  size="small"
                                  style={{ margin: "2px", border: "none" }}
                                  onClick={() =>
                                    getCashETriggerSMSLink(
                                      item?.lenderApplicationId
                                    )
                                  }
                                  icon={
                                    <img
                                      src={shareIcon}
                                      alt=""
                                      width={18}
                                      height={18}
                                      style={{ marginTop: "-4px" }}
                                    />
                                  }
                                />
                              )}
                            </>
                          )}
                        </span>
                      )}
                    {/* {item?.submissionType?.includes("API") &&
                        (item?.lenderApplicationId?.length ?? 0) > 0 && (
                          <span className="checkbox-text small">
                            {(["BU9709497975", "BU3620294738"].includes(
                              item.lenderInfoId
                            ) ||
                              item?.lenderName
                                ?.toLowerCase()
                                ?.includes("icici")) && (
                                  <>
                                  <a
                                    className="small"
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                      getDownloadLetterForICICI(
                                        item?.lenderInfoId
                                     )
                                    }}
                                  >
                                    {item.lenderName.split(" ")[0]} Download letter
                                  </a>
                                </>
                            )}
                          </span>
                        )} */}
                  </div>
                  {(item.openInput || item.status == "LOGGED") && (
                    <Input
                      className="custom-input"
                      value={item.rmName}
                      onChange={(ev) => rmInputHandler(ev, i)}
                      placeholder="RM name"
                    />
                  )}
                </div>
              )
            );
          })}
        </Space>
      </div>

      <div className="comment-box">
        <TextArea
          placeholder="Comment"
          showCount
          maxLength={500}
          className="custom-textarea"
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <Button
        type="primary"
        loading={loading}
        disabled={assignedLenderFiltered.length == 0}
        className="flex next-btn dynamic-btn-primary"
        style={btnPrimaryStyles}
        onClick={checkShowUpdateStatusOnNextStep}
      >
        Next Stage
      </Button>
      {/* <ICICIAddtionalFields leadData={leadData} setOpenFlowablePath={setOpenFlowablePath} openFlowablePath={openFlowablePath}  currLenderId={currLenderId} currLoanProductId={currLoanProductId}  currLeadId={currLeadId} taskDetails={taskDetails} customUI={customUI} jsonSchema={jsonSchema} /> */}
    </>
  );
};

export default LenderLogin;
