import React, { useState, useEffect } from "react";
import "../index.scss";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  Form,
  Input,
  Table,
} from "antd";
import API_SERVICE from "shared/services/api-service";
import type { ColumnsType } from "antd/es/table";
import { numberWithCommas } from "shared/services/Utility";

interface Multioffer {
  leadData: any;
}

const MultiLenderOfferScreen: React.FunctionComponent<Multioffer> = ({
  leadData,
}) => {
  const [offerData, setOfferData] = useState([]);
  const [pageData, setPageData] = useState<any>({});
  const [bureauOffer, setBureauOffer] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const getAllOfferData = (page: Number = 1, pageSize: Number = 10) => {
    API_SERVICE.getMultipleLenderOffer(
      leadData.caseDetails.caseId,
      page,
      pageSize
    )
      .then((res) => {
        if (res.data.payload.page) {
          setPageData(res.data.payload.page);
          setOfferData(res.data.payload.page.content);
          setLoading(false);
        }
      })
      .catch((e) => {
        notification.error({
          message: API_SERVICE.handleErrors(e),
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    getAllOfferData();
    // list/multi-lender-offer/{dsaCaseId}
  }, []);
  useEffect(() => {
    if (leadData?.dsaCaseAdditionalFileds?.bureauLoanOffer) {
      setBureauOffer([leadData?.dsaCaseAdditionalFileds?.bureauLoanOffer]);
    }
    console.log('leadData', leadData?.dsaCaseAdditionalFileds?.bureauLoanOffer);
  }, [leadData])
  const columns: ColumnsType<any> = [
    {
      title: "Lender",
      key: "lenderName",
      dataIndex: "lenderName",
      ellipsis: true,
      width: 350,
    },
    {
      title: "Offer Amount",
      key: "calculatedLoanAmount",
      dataIndex: "calculatedLoanAmount",
      ellipsis: true,
      width: 350,
      render: (_: any, record: any) => {
        return `Rs. ${numberWithCommas(
          Math.round(record?.calculatedLoanAmount)
        )}`;
      },
    },
    {
      title: "EMI",
      key: "totalEMI",
      dataIndex: "totalEMI",
      ellipsis: true,
      width: 350,
      render: (_: any, record: any) => {
        return `Rs. ${numberWithCommas(Math.round(record?.totalEMI))}`;
      },
    },
  ];
  const columnsBuerueOffer: ColumnsType<any> = [
    // {
    //   title: "Lender",
    //   key: "lenderName",
    //   dataIndex: "lenderName",
    //   ellipsis: true,
    //   width: 350,
    // },
    {
      title: "Offer Amount",
      key: "calculatedLoanAmount",
      dataIndex: "calculatedLoanAmount",
      ellipsis: true,
      width: 350,
      render: (_: any, record: any) => {
        return `Rs. ${numberWithCommas(
          Math.round(record?.calculatedLoanAmount)
        )}`;
      },
    },
    {
      title: "EMI",
      key: "totalEMI",
      dataIndex: "totalEMI",
      ellipsis: true,
      width: 350,
      render: (_: any, record: any) => {
        return `Rs. ${numberWithCommas(Math.round(record?.totalEMI))}`;
      },
    },
  ];
  const onHandlingPages = (pagginations) => {
    getAllOfferData(pagginations.current, pagginations.pageSize);
  };
  return (
    <div className="">
      <div className="lead-detail-box multiplender-offer-main mb-3">
        <Table
          loading={loading}
          columns={columnsBuerueOffer}
          dataSource={bureauOffer}
          pagination={false}
          // pagination={{
          //   current: pageData?.number + 1,
          //   pageSize: pageData?.size,
          //   total:
          //     pageData?.totalElements /* total recrod count returned from backend (totalElements)*/,
          // }}
          size="small"
          onChange={onHandlingPages}
        />
      </div>
      <span style={{
              fontSize: '24px',
              fontWeight: '350',
              marginTop: '25px',
              marginBottom: '25px'
            }}>Bank Statement Based Multilender Offer</span>
      <div className="lead-detail-box multiplender-offer-main">
        <Table
          loading={loading}
          columns={columns}
          dataSource={offerData}
          pagination={{
            current: pageData?.number + 1,
            pageSize: pageData?.size,
            total:
              pageData?.totalElements /* total recrod count returned from backend (totalElements)*/,
          }}
          size="small"
          onChange={onHandlingPages}
        />
      </div>
    </div>
  );
};

export default MultiLenderOfferScreen;
