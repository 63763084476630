import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Space,
  Button,
  Radio,
  Row,
  Col,
  Table,
  Modal,
  Select,
  Form,
  notification,
  Popconfirm,
} from "antd";
import { FaSearch, FaFilter, FaPlus, FaEye, FaPen } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import "./index.scss";
import API_SERVICE from "shared/services/api-service";
import { useHistory } from "react-router-dom";
import type { ColumnsType } from 'antd/es/table';

import form from "antd/lib/form";
import { hasUserRole } from "shared/services/Utility";
import { STRING_ONLY } from "shared/constants/AppConstants";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";

const { Option } = Select;
const { Title } = Typography;
type Props = {};

const Designation: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();

  const history = useHistory();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allLeads, setAllLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allConnectors, setAllConnectors] = useState([]);
  const [allConnectors1, setAllConnectors1] = useState([]);
  const [department, setDepartment] = useState([]);
  const [location1, setLocation1] = useState([]);
  const [value, setValue] = useState();
  const [isFilterVisible1, setIsFilterVisible1] = useState(false);
  const [designation, setdesignation] = useState();
  const [department1, setdepartment1] = useState();
  const [reportingto, setreportingTo] = useState();
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({screenTitle: "Manage Designations"}));
    getAllTypes();
    getManager();
    getAllDesignation();
  }, []);

  const handleDelete1 = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key);
    setAllConnectors1(newData);
  };

  const [addConnectorForm] = Form.useForm() as any;
  const edit = (record: any) => {
    addConnectorForm.setFieldsValue({
      designation: record.designation,
      department: record.department,
      reportingTo: record.reportingTo,
    });
    // department designation reportingTo

    setValue(record);
    setdesignation(record.designation);
    setdepartment1(record.department);
    setreportingTo(record.reportingTo);
    handleDelete1(record);
    setIsFilterVisible1(true);
  };

  const handleDelete = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key);
    var newData1 = [...allConnectors, newData];
    const payload = newData;
    API_SERVICE.addDesignations(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Designation",
      dataIndex: "designation",
      key: "productType",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "productType",
    },
    // {
    //   title: "Reporting",
    //   dataIndex: "reportingTo",
    //   key: "productType",
    // },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      align: 'center',
      width: 100,
      render: (_: any, record: any) => (
        <Space className="action-button" size="middle">
          <Space
            className="action-button"
            size="small"
            style={{ marginLeft: "1%" }}
          >
            <Button
              className="custome-btn-icon-secondary"
              style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
              size="small"
              type="text"
              icon={<IconEditBorder />}
              onClick={() => edit(_)}
              hidden={
                !(
                  userRolePermission["UpdateUser"] ||
                  userRolePermission["Admin"]
                )
              }
            />
          </Space>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(_)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
            >
              <Button
                style={{backgroundColor:"transparent", border:'none'}}
                size="small"
                type="text"
                icon={<IconDeleteBorder width={24} height={24}/>}
                hidden={
                  !(
                    userRolePermission["UpdateUser"] ||
                    userRolePermission["Admin"]
                  )
                }
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getAllDesignation = () => {
    setLoading(true);
    API_SERVICE.getDesignations()
      .then(({ data }) => {
        if (data.payload.length > 0) {
          setAllConnectors(data.payload);
        } else {
          notification.error({ message: "No designations found." });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearch = () => { };

  const onSelectFilter = () => { };

  const handleFilterModal = () => {
    setIsFilterVisible(true);
  };

  const getAllTypes = () => {
    API_SERVICE.getDepartments()
      .then(({ data }) => {
        setDepartment(data.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getManager = () => {
    API_SERVICE.getUsersWithRole()
      .then(({ data }) => {
        setLocation1(data.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFilterSubmit = (event: any) => {

    const data = {
      department: event?.department,
      designation: event?.designation.trim(),
      reportingTo: event?.reportingTo,
    }
    let newArray;
    newArray = [...allConnectors, data];
    const payload = newArray;
    API_SERVICE.addDesignations(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
          onReset();
          //   getAllLeads();
          //   history.push("/department");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFilterSubmit1 = (event: any, value: { officeName: any }) => {
    const data = {
      department: event?.department,
      designation: event?.designation.trim(),
      reportingTo: event?.reportingTo,
    }
    var newData2 = [...allConnectors1, data];

    const payload = newData2;
    API_SERVICE.addDesignations([
      ...allConnectors1,
      {
        department: event.department ? event.department : department1,
        designation: event.designation ? event.designation : designation,
        reportingTo: event.reportingTo ? event.reportingTo : reportingto,
      },
    ])
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible1(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  const filterCancel = () => {
    setIsFilterVisible(false);
    onReset();
  };

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <div className="content-box">
      {isFilterVisible && (
        <Modal
          title="Add New Designation"
          centered
          width={700}
          onCancel={filterCancel}
          // onCancel={() => setIsFilterVisible(false)}
          footer={null}
          visible={isFilterVisible}
        >
          <Adddesignation
            onSubmitFilter={onFilterSubmit}
            department={department}
            location={location1}
            onReset={onReset}
            form={form}
          />
        </Modal>
      )}

      {isFilterVisible1 && (
        <Modal
          title="Edit Designation"
          centered
          width={700}
          onCancel={() => setIsFilterVisible1(false)}
          footer={null}
          visible={isFilterVisible1}
        >
          <AddDepartment1
            onSubmitFilter1={onFilterSubmit1}
            value1={value}
            department={department}
            location={location1}
            form1={addConnectorForm}
          />
        </Modal>
      )}
      <Row>
        <Col className="filter-bar" span={16}>
        </Col>
        <Col span={8} className="filter-bar filter-bar-right">
          <Button
            style={{ marginLeft: "65px" }}
            type="primary"
            className="refresh-button"
            onClick={() => {
              getAllDesignation();
            }}
            shape="round"
            //icon={<ReloadOutlined />}
            size={"middle"}
          > <img src={blackRefreshIcon} alt="" style={{marginLeft:'-10px'}}/>
          </Button>
          <Button
            type="primary"
            className="add-button11 dynamic-btn-primary"
            style={btnPrimaryStyles}
            onClick={handleFilterModal}
            shape="round"
            icon={<FaPlus />}
            size={"middle"}
            disabled={!(userRolePermission["Admin"] || userRolePermission["CreateUser"])}
            hidden={
              !(
                userRolePermission["CreateUser"] || userRolePermission["Admin"]
              )
            }
          >
            Add Designation
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={allConnectors}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Designation;

export const Adddesignation: React.FunctionComponent<any> = ({
  onSubmitFilter,
  department,
  location,
  onReset,
  form,
}) => {

  const {appColors}: any = useSelector((state: RootState) => state.common);

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <Form
      form={form}
      name="basic"
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmitFilter}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Select Department"
            name="department"
            rules={[{ required: true, message: "Please select Department." }]}
          >
            <Select placeholder="Please select">
              {department
                ?.sort(function (a: any, b: any) {
                  if (a.toLowerCase() < b.toLowerCase()) return -1;
                  if (a.toLowerCase() > b.toLowerCase()) return 1;
                  return 0;
                })?.map((province) => (
                  <Select.Option key={province} value={province}>
                    {province}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Designation Name"
            name="designation"
            rules={[{ required: true, message: "Please Enter Designation" }, { pattern: STRING_ONLY, message: "Please enter valid designation" }]}
          >
            <Input placeholder="Sunil Shah" size="large" />
          </Form.Item>
        </Col>

        {/* <Col span={24}>
          <Form.Item
            label="Report To"
            name="reportingTo"
            rules={[{ required: true, message: "Please select ReportTo." }]}
          >
            <Select placeholder="Please select" showSearch>
              {location &&
                location.map((loan: any, i: number) => (
                  <Select.Option key={loan.id} value={loan.fullName}>
                    {loan.fullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={onReset} className="dynamic-btn-default" style={btnDefaultBorderStyle}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Add
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};

export const AddDepartment1: React.FunctionComponent<any> = ({
  onSubmitFilter1,
  department,
  location,
  value1, form1
}) => {

  const {appColors}: any = useSelector((state: RootState) => state.common);

  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};

  return (
    <Form
      name="basic"
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmitFilter1}
      form={form1}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            label="Select Department"
            name="department"
            rules={[{ required: true, message: "Please select Department." }]}
          >
            <Select
              placeholder="Please select"
            >
              {department.map((province) => (
                <Select.Option key={province} value={province}>
                  {province}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Designation Name"
            name="designation"
            rules={[{ required: true, message: "Please enter Designation Name" },
            {
              pattern: /^[A-Za-z\\s ]+$/,
              message: "Plesae enter valid Designation Name",
            },]}
          >
            <Input placeholder="Enter Designation" size="large" />
          </Form.Item>
        </Col>
        {/* <Col span={24}>
          <Form.Item
            label="Report To"
            name="reportingTo"
            rules={[{ required: true, message: "Please select ReportTo." }]}
          >
            <Select
              placeholder="Please select"
              showSearch
            >
              {location &&
                location.map((loan: any, i: number) => (
                  <Select.Option key={loan.id} value={loan.fullName}>
                    {loan.fullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              {/* <Button htmlType="button">Clear</Button> */}
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Save
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};
