import { Card, Collapse, Typography } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import styled from "styled-components";
import { ellipsis } from "polished";
import Linkify from "linkify-react";
import "./help.scss";

type Props = {};
const { Title } = Typography;
const { Panel } = Collapse;

const FAQ: React.FunctionComponent<Props> = () => {
  const [faq, setFaq] = useState([]);
  const [isShowMore, setIsShowMore] = useState(true);
  const toggleReadMore = () => setIsShowMore((show) => !show);

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  useEffect(() => {
    getFaq();
  }, []);

  const getFaq = () => {
    API_SERVICE.faq().then((data) => {
      if (data?.data?.payload?.length > 0) {
        console.log(data.data.payload);
        setFaq(data.data.payload);
      }
    });
  };

  const descMainContainer = (description: string) => {
    return (
      <MainContainer>
        <DescriptionText showMore={isShowMore}>
          <Linkify options={{ target: "blank" }}>{description}</Linkify>
        </DescriptionText>
        <ShowMoreText onClick={toggleReadMore}>
          {isShowMore ? "Show more" : "Show less"}
        </ShowMoreText>
      </MainContainer>
    );
  };

  const getValidDescription = (data: any) => {
    if (data && data.length > 0) {
      if (data.split("~").length >= 2) {
        let [video, description] = data.split("~");
        if (video.startsWith("https://vimeo.com")) {
          let videoId = "";
          videoId = video.substring(video.lastIndexOf("/") + 1, video.length);
          return (
            <>
              <div
                className="container"
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyItems: "start",
                }}
              >
                <div className="videos" style={{ marginRight: "20px" }}>
                  <iframe
                    src={`https://player.vimeo.com/video/${videoId}`}
                    title={videoId}
                    width="340"
                    height="300"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
                <p>{descMainContainer(description)}</p>
              </div>
              <p
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  width: "250px",
                }}
              ></p>
            </>
          );
        } else {
          return descMainContainer(description);
        }
      } else {
        return descMainContainer(data);
      }
    }
  };

  const MainContainer = styled.div`
    padding: 0px;
  `;

  const TitleText = styled.div`
    font-size: 18px;
  `;

  const DescriptionText = styled.div`
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    ${({ showMore }) => showMore && ellipsis(undefined, 12)}
  `;

  const ShowMoreText = styled.div`
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-decoration: underline;
  `;

  return (
    <>
      <Card className="custom-app-card">
        <div
          className="faq-panel"
          style={{ padding: "5px", height: "77vh", overflow: "auto" }}
        >
          <div style={{ marginTop: "0px" }}>
            <Title level={5}>FAQ</Title>

            <Collapse
              defaultActiveKey={["1"]}
              onChange={onChange}
              ghost
              expandIconPosition="right"
            >
              {faq.map((data: any, index) => (
                <Panel header={data.title} key={"faq_" + index}>
                  <p style={{ paddingLeft: "15px" }}>
                    {getValidDescription(data.description)}
                  </p>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </Card>
    </>
  );
};

export default FAQ;
