// import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
  Upload,
  Select,
  DatePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import { FaFileMedical } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import type { UploadProps } from "antd/es/upload/interface";

// type Props = {};

const { Title } = Typography;
const Banner: React.FunctionComponent = () => {
  const history = useHistory();

  const [fileList, setFileList] = useState([] as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [location1, setLocation1] = useState([]);

  const uploadProps: UploadProps = {
    listType: "text",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const uploadDocumentHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          setImageUrl(data);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onUpload = useCallback((acceptedFiles: any) => {
    setUploadedFile(acceptedFiles.file);
    uploadDocumentHandler(acceptedFiles.file);
  }, []);

  useEffect(() => {
    getManager();
  }, []);
  const getManager = () => {
    API_SERVICE.getManager()
      .then(({ data }) => {
        setLocation1(data.payload.content);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFinish = (values: any) => {
  };
  return (
    // <h1>Notification Section</h1>
    <div>
      <Title level={3}>Send Notification</Title>
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        name="leadForm"
        // onFinish={onFinishForm}
      >
        <Row>
          <Col span={6} style={{ marginTop: "2%" }}>
            <Upload
              className="upload-wrapper"
              listType="picture"
              onChange={onUpload}
              {...uploadProps}
              maxCount={1}
            >
              <FaFileMedical />
              Upload Banner
            </Upload>
          </Col>

          <Col span={6} style={{ marginTop: "5%", marginLeft: "1%" }}>
            <Form.Item
              label=""
              name="lineManager"
              rules={[
                { required: true, message: "Please select Line Manager type" },
              ]}
            >
              <Select
                placeholder="Select User"
                className="custom-select"
                showSearch
              >
                {/* <Select.Option value="Hwllo">Hello</Select.Option> */}
                {location1 &&
                  location1.map((loan: any, i: number) => (
                    <Select.Option key={loan.id} value={loan.id}>
                      {loan.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6} style={{ marginTop: "5%", marginLeft: "1%" }}>
            <Form.Item
              name="dob"
              rules={[{ required: true, message: "Please enter Schedule" }]}
            >
              <DatePicker className="custom-input" placeholder="Schedule" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          profession: "Builder",
          gender: "M",
        }}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinish}
      >
        <Row gutter={[18, 18]}>
          <Col span={19}>
            <Form.Item
              label="Title*"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
                {
                  pattern: /^[A-Za-z\\s ]+$/,
                  message: "Plesae enter valid company name",
                },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Please enter title"
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <TextArea
                className="custom-textarea"
                rows={8}
                placeholder="Please Enter Description"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          {/* <Divider/> */}
          <Col offset={18}>
            <Form.Item>
              <Button
                style={{ marginTop: "14px" }}
                size="large"
                type="primary"
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Banner;
