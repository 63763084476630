import React, { useEffect, useState } from "react";
import {
    Typography,
    Row,
    Col,
    Space,
    Tabs,
    Button,
    Tooltip,
    Form,
    Divider,
    notification,
} from "antd";
import API_SERVICE from "shared/services/api-service";
import "./index.scss";
import { Link, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, FileOutlined } from "@ant-design/icons";
import { base64ToArrayBuffer, saveByteArray } from "shared/services/Utility";
import cloudDown from "../../assets/images/cloudDown.svg"
import {ReactComponent as FileBorderIcon} from "../../assets/images/file-border.svg";
import PaySubConnectore from "./edit-connectors/payout-details/PaySubConnectore";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
const { TabPane } = Tabs;
const { Title, Text } = Typography;
type Props = {};

const ViewConnector: React.FunctionComponent<Props> = () => {

    const [connector, setConnector] = useState({} as any);
    const [activeKey, setActiveKey] = useState('1');
    const [payoutActiveKey, setPayoutActiveKey] = useState('1');
    const [primaryData, setPrimaryData] = useState({} as any);
    const [alternateOneData, setAlternateOneData] = useState({} as any);
    const [alternateTwoData, setAlternateTwoData] = useState({} as any);
    const {appColors}: any = useSelector((state: RootState) => state.common);
    const dispatch = useDispatch();

    const { basicDetails } = connector;
    const { kycDetails } = connector;
    const { otherDetails } = connector;
    const { accounts } = connector;

    useEffect(() => {
        accounts?.map((accounts) => {
            if (accounts?.accType === "PRIMARY") {
                setPrimaryData(accounts);
            } else if (accounts?.accType === "ALTERNATE_ACCOUNT_1") {
                setAlternateOneData(accounts);
            } else if (accounts?.accType === "ALTERNATE_ACCOUNT_2") {
                setAlternateTwoData(accounts);
            }
        });
    });

    let { id } = useParams() as any;
    if (!id) {
        // history.goBack();
    }

    useEffect(() => {
        dispatch(
            setScreenHeader({
              backScreenTitle: "Connector",
              backScreenPath: "/connectors",
              screenTitle: "View Connector",
            })
        );
        getConnectorById();
    }, []);

    const getConnectorById = () => {
        API_SERVICE.getConnectorById(id)
        .then(({ data }) => setConnector(data.payload))
        .catch((e: any) => notification.error({ message: API_SERVICE.handleErrors(e) }));
    }

    const onTabChange = (key: any) => {
        setActiveKey(key);
    };

    const onPayoutTabChange = (key: any) => {
        setPayoutActiveKey(key);
    };

    const downloadDocument = (doc: any) => {
        if (doc && doc.businessId && doc.businessDocumentId) {
            API_SERVICE.downloadDocument(doc.businessId, doc.businessDocumentId).then(({ data }) => {
                let bytesData = base64ToArrayBuffer(data?.payload?.documentDetails)
                saveByteArray([bytesData], data?.payload?.documentName)
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
        }
    };

    const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? ""};

    const showBasicDetails = () => {

        return (
            <>
                <div className="connector-details-box">

                    <div className="connector-detail-box-inner">

                        <Row gutter={[16, 16]}>

                            <Col span={12} style={{ borderRight: "solid 1px #e9e9e9", paddingRight: "0px", marginTop: "10px" }}>


                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Basic Details
                                </Divider>

                                <div style={{ marginLeft: "30px", }}>
                                    <Row className="custom-row" >
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Profession</Text>
                                            <Title level={5}>{basicDetails?.profession}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Gender</Text>
                                            <Title level={5}>{basicDetails?.gender}</Title>
                                        </Space>
                                    </Row>

                                </div>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Contact Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Connector Name</Text>
                                            <Title level={5}>{basicDetails?.name}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Contact/Brand Name</Text>
                                            <Title level={5}>{basicDetails?.connectorContactBrandName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Email ID</Text>
                                            <Title level={5}>{basicDetails?.emailId}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Phone Number</Text>
                                            <Title level={5}>{basicDetails?.phoneNumber}</Title>
                                        </Space>
                                    </Row>
                                </div>
                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Business Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Business Name</Text>
                                            <Title level={5}>{basicDetails?.businessName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Business Id</Text>
                                            <Title level={5}>{basicDetails?.businessId}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={12} style={{ marginTop: "10px" }}>
                            <div style={{ marginLeft: "30px" }}>
                                <Row className="custom-row">
                                    <Space direction="vertical" className="gap">
                                        <Text className="title">GSTIN</Text>
                                        <Title level={5}>{basicDetails?.gstIn}</Title>
                                    </Space>
                                </Row>
                            </div>
                                <label className="ant-collapse-header">Relationship Mapping</label>
                                <Divider orientation="left" orientationMargin="0">
                                    Assign To Sales User
                                </Divider>
                                <div style={{ marginLeft: "30px" }}>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Sales RelationShip Manager ID</Text>
                                            <Title level={5}>{basicDetails?.srmId}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Sales RelationShip Manager Name</Text>
                                            <Title level={5}>{basicDetails?.srmName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Sales Relationship Manager Mobile Number</Text>
                                            <Title level={5}>{basicDetails?.srmMobileNumber}</Title>
                                        </Space>
                                    </Row>
                                </div>

                                <Divider orientation="left" orientationMargin="0">
                                    Channel Manger Details
                                </Divider>
                                <div style={{ marginLeft: "30px" }}>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">ChannelManager ID</Text>
                                            <Title level={5}>{basicDetails?.channelManagerId}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">ChannelManager Name</Text>
                                            <Title level={5}>{basicDetails?.channelManagerName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Channel Manager Mobile Number</Text>
                                            <Title level={5}>{basicDetails?.channelManagerMobileNumber}</Title>
                                        </Space>
                                    </Row>
                                </div>

                                <Divider orientation="left" orientationMargin="0">
                                    Referral Connector Details
                                </Divider>
                                <div style={{ marginLeft: "30px" }}>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Connector ID</Text>
                                            <Title level={5}>{basicDetails?.connectorId}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Connector Name</Text>
                                            <Title level={5}>{basicDetails?.connectorName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Connector Mobile Number</Text>
                                            <Title level={5}>{basicDetails?.connectorMobileNumber}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </>
        )
    };

    const showKycDetails = () => {


        return (
            <>
                <div className="connector-details-box">

                    <div className="connector-detail-box-inner">

                        <Row gutter={[16, 16]}>

                            <Col span={12} style={{ borderRight: "solid 1px #e9e9e9", paddingRight: "0px", marginTop: "10px" }}>


                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    PAN Details
                                </Divider>
                                <div style={{ marginLeft: "30px", }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">PAN</Text>
                                            {((undefined !== kycDetails?.pan && null !== kycDetails?.pan)) ?
                                                (kycDetails?.panVerified ?
                                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                                    :
                                                    <label className="label-verficiation-pending">Verification Pending</label>
                                                )
                                                :
                                                null
                                            }
                                            <Title level={5}>{kycDetails?.pan}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Name as per PAN Card</Text>
                                            <Title level={5}>{kycDetails?.panName}</Title>
                                        </Space>
                                    </Row>

                                </div>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Address Proof
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>


                                    <Form layout="vertical">
                                        <Form.Item className="field-bg" label="Address Proof" style={{ marginRight: "10px" }}>
                                            {((undefined !== kycDetails?.status && null !== kycDetails?.status)) ?
                                                ("APPROVED" === kycDetails?.status ?
                                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                                    :
                                                    ("REJECTED" === kycDetails?.status) ?
                                                        <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                                        :
                                                        <label className="label-verficiation-pending">Verification Pending</label>
                                                )
                                                :
                                                null
                                            }

                                            <Row gutter={[16, 16]}>
                                                <Col span={3} style={{ display: "flex", justifyContent: "start" }}>
                                                    <Space className="action-button" size="middle">
                                                        <Button
                                                            className="border-file-icon"                                                       
                                                            icon={<FileBorderIcon style={{'--fileIconColor': appColors?.appPrimaryColor ?? ""} as any}/>}
                                                            onClick={() => { }}
                                                        />
                                                    </Space>
                                                </Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        style={{ overflow: "hidden" }}
                                                        name="doc"
                                                    >
                                                        <Tooltip title={kycDetails?.addressProofDoc?.documentName}>
                                                            <Title level={5}>{kycDetails?.addressProofDoc?.documentName}</Title>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                        {/* <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FaPen />}
                                                        /> */}
                                                    </Space>
                                                    <Space className="action-button" size="middle">
                                                        {kycDetails?.addressProofDoc &&
                                                            < Button
                                                                className="blue"
                                                                size="small"
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<CloudDownloadOutlined />}
                                                                onClick={() => { downloadDocument(kycDetails?.addressProofDoc) }}
                                                            />
                                                        }
                                                    </Space>
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    </Form>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Document Type</Text>
                                            <Title level={5}>{kycDetails?.addressProofDoc?.documentType}</Title>
                                        </Space>
                                    </Row>

                                </div>

                            </Col>

                            <Col span={12} style={{ marginTop: "10px" }}>

                                <Divider orientation="left" orientationMargin="0">
                                    Current Address
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Address Line 1</Text>
                                            <Title level={5}>{kycDetails?.currentAddress?.address}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Pin Code</Text>
                                            <Title level={5}>{kycDetails?.currentAddress?.pinCode}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">City</Text>
                                            <Title level={5}>{kycDetails?.currentAddress?.city}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">State</Text>
                                            <Title level={5}>{kycDetails?.currentAddress?.state}</Title>
                                        </Space>
                                    </Row>

                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </>
        )
    };

    const showOtherDetails = () => {

        return (
            <>
                <div className="connector-details-box">

                    <div className="connector-detail-box-inner">

                        <Row gutter={[16, 16]}>

                            <Col span={12} style={{ borderRight: "solid 1px #e9e9e9", paddingRight: "0px", marginTop: "10px" }}>


                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Basic Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>
                                    {/* <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Bureau Requests - Allowed</Text>
                                            <Title level={5}>{otherDetails?.bureauRequestAllowed}</Title>
                                        </Space>
                                    </Row> */}

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Connector Level</Text>
                                            <Title level={5}>{otherDetails?.selectConnectorLevel}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Payout</Text>
                                            <Title level={5}>{otherDetails?.choosePayout}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={12} style={{ marginTop: "10px" }}>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Status Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Current Status</Text>
                                            <Title level={5}>{otherDetails?.status}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Onboarding Status</Text>
                                            <Title level={5}>{otherDetails?.status}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    };

    const showPayoutsDetails = () => {

        return (
            <>
                <div className="connector-payout-box">
                    <Row>
                        <Col className="btn-box" span={24}>
                            <Tabs activeKey={payoutActiveKey} onChange={onPayoutTabChange} className="custom-app-tab dynamic-tabs" style={customStyles}>
                                <TabPane tab="Primary A/C" key="1">
                                    {showPrimaryDetails()}
                                </TabPane>
                                {id == connector?.basicDetails?.ownerConnectorUserId &&
                                    <TabPane tab="Pay Sub-Connectors" key="2" >
                                        {showPaySubConnectors()}
                                    </TabPane>
                                }
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </>
        )
    };

    const showPrimaryDetails = () => {

        return (
            <>
                <div className="connector-details-box">

                    <div className="connector-detail-box-inner">

                        <Row gutter={[16, 16]}>

                            <Col span={12} style={{ paddingRight: "0px", marginTop: "10px" }}>
                                <Form layout="vertical">
                                    <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    </Divider>
                                    <Form.Item className="field-bg" label="Bank Details" name="bankDetails" style={{ marginRight: "10px" }}>
                                        {((undefined !== primaryData?.status && null !== primaryData?.status)) ?
                                            ("APPROVED" === primaryData?.status ?
                                                <label className="label-verified"><CheckOutlined />Verified</label>
                                                :
                                                ("REJECTED" === primaryData?.status) ?
                                                    <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                                    :
                                                    <label className="label-verficiation-pending">Verification Pending</label>
                                            )
                                            :
                                            null
                                        }
                                        <Row gutter={[16, 16]}>
                                            <Col span={2} style={{ display: "flex", justifyContent: "start" }}>
                                                <Space className="action-button border-file-icon" size="middle" >
                                                    {/* <img src={fileUploadIcon} /> */}
                                                    <FileBorderIcon style={{'--fileIconColor': appColors?.appPrimaryColor ?? ""} as any}/>
                                                </Space>
                                            </Col>
                                            <Col span={15}>
                                                <Form.Item
                                                    style={{ overflow: "hidden" }}
                                                    name="doc"
                                                >
                                                    <Tooltip title={primaryData?.cancelledChequeDoc?.documentName}>
                                                        <label style={{}} className="display-label">{primaryData?.cancelledChequeDoc?.documentName}</label>
                                                    </Tooltip>
                                                </Form.Item>
                                            </Col>
                                            <Col span={7} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                    {primaryData?.cancelledChequeDoc &&

                                                        <img src={cloudDown} alt="cloudDown" onClick={() => { downloadDocument(primaryData?.cancelledChequeDoc) }} />
                                                    }
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Bank Details
                                </Divider>
                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">BANK NAME</Text>
                                            <Title level={5}>{primaryData?.bankDetails?.bankName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Account Holder Name</Text>
                                            <Title level={5}>{primaryData?.bankDetails?.accHolderName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Account Number</Text>
                                            <Title level={5}>{primaryData?.bankDetails?.accNumber}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">IFSC Code</Text>
                                            <Title level={5}>{primaryData?.bankDetails?.ifscCode}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    };
    const showPaySubConnectors = () => {
        return (
            <>
                <PaySubConnectore />
            </>
        )
    }
    const showAlternateOneDetails = () => {

        return (
            <>
                <div className="connector-details-box">

                    <div className="connector-detail-box-inner">

                        <Row gutter={[16, 16]}>

                            <Col span={12} style={{ borderRight: "solid 1px #e9e9e9", paddingRight: "0px", marginTop: "10px" }}>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Basic Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Contact Name</Text>
                                            <Title level={5}>{alternateOneData?.basicDetails?.name}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Phone Number</Text>
                                            <Title level={5}>{alternateOneData?.basicDetails?.phoneNumber}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Email ID</Text>
                                            <Title level={5}>{alternateOneData?.basicDetails?.emailId}</Title>
                                        </Space>
                                    </Row>
                                </div>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    PAN Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">PAN</Text>
                                            <Title level={5}>{alternateOneData?.kycDetails?.pan}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Name as per PAN Card</Text>
                                            <Title level={5}>{alternateOneData?.kycDetails?.panName}</Title>
                                        </Space>
                                    </Row>
                                </div>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Address Proof
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>
                                    <Form layout="vertical">
                                        <Form.Item className="field-bg" label="Address Proof" style={{ marginRight: "10px" }}>
                                            {((undefined !== alternateOneData?.kycDetails?.status && null !== alternateOneData?.kycDetails?.status)) ?
                                                ("APPROVED" === alternateOneData?.kycDetails?.status ?
                                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                                    :
                                                    ("REJECTED" === alternateOneData?.kycDetails?.status) ?
                                                        <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                                        :
                                                        <label className="label-verficiation-pending">Verification Pending</label>
                                                )
                                                :
                                                null
                                            }
                                            <Row gutter={[16, 16]}>
                                                <Col span={3} style={{ display: "flex", justifyContent: "start" }}>
                                                    <Space className="action-button" size="middle">
                                                        <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FileOutlined />}
                                                            onClick={() => { }}
                                                        />
                                                    </Space>
                                                </Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        style={{ overflow: "hidden" }}
                                                        name="doc"
                                                    >
                                                        <Tooltip title={alternateOneData?.kycDetails?.addressProofDoc?.documentName}>
                                                            <label style={{}} className="display-label">{alternateOneData?.kycDetails?.addressProofDoc?.documentName}</label>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                        {/* <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FaPen />}
                                                        /> */}
                                                    </Space>
                                                    <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                        {alternateOneData?.kycDetails?.addressProofDoc &&
                                                            <Button
                                                                className="blue"
                                                                size="small"
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<CloudDownloadOutlined />}
                                                                onClick={() => { downloadDocument(alternateOneData?.kycDetails?.addressProofDoc) }}
                                                            />
                                                        }
                                                    </Space>
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    </Form>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Document Type</Text>
                                            <Title level={5}>{alternateOneData?.kycDetails?.addressProofDoc?.documentType}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={12} style={{ marginTop: "10px" }}>


                                {/* <div style={{ marginLeft: "30px" }}> */}
                                <Form layout="vertical">
                                    <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                        Payment Details
                                    </Divider>
                                    <Form.Item className="field-bg" label="UPI Id" style={{ marginRight: "10px" }}>

                                        {((undefined !== alternateOneData?.upiId && null !== alternateOneData?.upiId)) ?
                                            (alternateOneData?.upiVerified ?
                                                <label className="label-verified"><CheckOutlined />Verified</label>
                                                :
                                                <label className="label-verficiation-pending">Verification Pending</label>
                                            )
                                            :
                                            null
                                        }
                                        <Title level={5}>{alternateOneData?.upiId}</Title>
                                    </Form.Item>
                                    <Form.Item className="field-bg" label="Bank Details" style={{ marginRight: "10px" }}>
                                        {((undefined !== alternateOneData?.status && null !== alternateOneData?.status)) ?
                                            ("APPROVED" === alternateOneData?.status ?
                                                <label className="label-verified"><CheckOutlined />Verified</label>
                                                :
                                                ("REJECTED" === alternateOneData?.status) ?
                                                    <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                                    :
                                                    <label className="label-verficiation-pending">Verification Pending</label>
                                            )
                                            :
                                            null
                                        }
                                        <Row gutter={[16, 16]}>
                                            <Col span={3} style={{ display: "flex", justifyContent: "start" }}>
                                                <Space className="action-button" size="middle">
                                                    <Button
                                                        className="blue"
                                                        size="small"
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<FileOutlined />}
                                                        onClick={() => { }}
                                                    />
                                                </Space>
                                            </Col>
                                            <Col span={15}>
                                                <Form.Item
                                                    style={{ overflow: "hidden" }}
                                                    name="doc"
                                                >
                                                    <Tooltip title={alternateOneData?.cancelledChequeDoc?.documentName}>
                                                        <label style={{}} className="display-label">{alternateOneData?.cancelledChequeDoc?.documentName}</label>
                                                    </Tooltip>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                    {/* <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FaPen />}
                                                        /> */}
                                                </Space>
                                                <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                    {alternateOneData?.cancelledChequeDoc &&
                                                        <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<CloudDownloadOutlined />}
                                                            onClick={() => { downloadDocument(alternateOneData?.cancelledChequeDoc) }}
                                                        />
                                                    }
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>

                                {/* </div> */}

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Bank Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">BANK NAME</Text>
                                            <Title level={5}>{alternateOneData?.bankDetails?.bankName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Account Holder Name</Text>
                                            <Title level={5}>{alternateOneData?.bankDetails?.accHolderName}</Title>
                                        </Space>
                                    </Row>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Account Number</Text>
                                            <Title level={5}>{alternateOneData?.bankDetails?.accNumber}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">IFSC Code</Text>
                                            <Title level={5}>{alternateOneData?.bankDetails?.ifscCode}</Title>
                                        </Space>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    };

    const showAlternateTwoDetails = () => {

        return (
            <>
                <div className="connector-details-box">

                    <div className="connector-detail-box-inner">

                        <Row gutter={[16, 16]}>

                            <Col span={12} style={{ borderRight: "solid 1px #e9e9e9", paddingRight: "0px", marginTop: "10px" }}>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Basic Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Contact Name</Text>
                                            <Title level={5}>{alternateTwoData?.basicDetails?.name}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Phone Number</Text>
                                            <Title level={5}>{alternateTwoData?.basicDetails?.phoneNumber}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Email ID</Text>
                                            <Title level={5}>{alternateTwoData?.basicDetails?.emailId}</Title>
                                        </Space>
                                    </Row>
                                </div>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    PAN Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">PAN NUMBER</Text>
                                            <Title level={5}>{alternateTwoData?.kycDetails?.pan}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Name as per PAN Card</Text>
                                            <Title level={5}>{alternateTwoData?.kycDetails?.panName}</Title>
                                        </Space>
                                    </Row>
                                </div>

                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Address Proof
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>
                                    <Form layout="vertical">
                                        <Form.Item className="field-bg" label="Address Proof" style={{ marginRight: "10px" }}>
                                            {((undefined !== alternateTwoData?.kycDetails?.status && null !== alternateTwoData?.kycDetails?.status)) ?
                                                ("APPROVED" === alternateTwoData?.kycDetails?.status ?
                                                    <label className="label-verified"><CheckOutlined />Verified</label>
                                                    :
                                                    ("REJECTED" === alternateTwoData?.kycDetails?.status) ?
                                                        <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                                        :
                                                        <label className="label-verficiation-pending">Verification Pending</label>
                                                )
                                                :
                                                null
                                            }
                                            <Row gutter={[16, 16]}>
                                                <Col span={3} style={{ display: "flex", justifyContent: "start" }}>
                                                    <Space className="action-button" size="middle">
                                                        <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FileOutlined />}
                                                            onClick={() => { }}
                                                        />
                                                    </Space>
                                                </Col>
                                                <Col span={15}>
                                                    <Form.Item
                                                        style={{ overflow: "hidden" }}
                                                        name="doc"
                                                    >
                                                        <Tooltip title={alternateTwoData?.kycDetails?.addressProofDoc?.documentName}>
                                                            <label style={{}} className="display-label">{alternateTwoData?.kycDetails?.addressProofDoc?.documentName}</label>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                        {/* <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FaPen />}
                                                        /> */}
                                                    </Space>
                                                    <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                        {alternateTwoData?.kycDetails?.addressProofDoc &&
                                                            <Button
                                                                className="blue"
                                                                size="small"
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<CloudDownloadOutlined />}
                                                                onClick={() => { downloadDocument(alternateTwoData?.kycDetails?.addressProofDoc) }}
                                                            />
                                                        }
                                                    </Space>
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    </Form>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Document Type</Text>
                                            <Title level={5}>{alternateTwoData?.kycDetails?.addressProofDoc?.documentType}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>

                            <Col span={12} style={{ marginTop: "10px" }}>
                                <Form layout="vertical">
                                    <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                        Payment Details
                                    </Divider>

                                    {/* <div style={{ marginLeft: "30px" }}> */}
                                    <Form.Item className="field-bg" label="UPI Id" style={{ marginRight: "10px" }}>

                                        {((undefined !== alternateTwoData?.upiId && null !== alternateTwoData?.upiId)) ?
                                            (alternateTwoData?.upiVerified ?
                                                <label className="label-verified"><CheckOutlined />Verified</label>
                                                :
                                                <label className="label-verficiation-pending">Verification Pending</label>
                                            )
                                            :
                                            null
                                        }
                                        <Title level={5}>{alternateTwoData?.upiId}</Title>
                                    </Form.Item>
                                    <Form.Item className="field-bg" label="Bank Details" style={{ marginRight: "10px" }}>
                                        {((undefined !== alternateTwoData?.status && null !== alternateTwoData?.status)) ?
                                            ("APPROVED" === alternateTwoData?.status ?
                                                <label className="label-verified"><CheckOutlined />Verified</label>
                                                :
                                                ("REJECTED" === alternateTwoData?.status) ?
                                                    <label className="label-verified-rejected"><CloseOutlined />Verification Rejected</label>
                                                    :
                                                    <label className="label-verficiation-pending">Verification Pending</label>
                                            )
                                            :
                                            null
                                        }
                                        <Row gutter={[16, 16]}>
                                            <Col span={3} style={{ display: "flex", justifyContent: "start" }}>
                                                <Space className="action-button" size="middle">
                                                    <Button
                                                        className="blue"
                                                        size="small"
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<FileOutlined />}
                                                        onClick={() => { }}
                                                    />
                                                </Space>
                                            </Col>
                                            <Col span={15}>
                                                <Form.Item
                                                    style={{ overflow: "hidden" }}
                                                    name="doc"
                                                >
                                                    <Tooltip title={alternateTwoData?.cancelledChequeDoc?.documentName}>
                                                        <label style={{}} className="display-label">{alternateTwoData?.cancelledChequeDoc?.documentName}</label>
                                                    </Tooltip>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                    {/* <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<FaPen />}
                                                        /> */}
                                                </Space>
                                                <Space className="action-button" size="middle" style={{ marginRight: "10px" }}>
                                                    {alternateTwoData?.cancelledChequeDoc &&
                                                        <Button
                                                            className="blue"
                                                            size="small"
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<CloudDownloadOutlined />}
                                                            onClick={() => { downloadDocument(alternateTwoData?.cancelledChequeDoc) }}
                                                        />
                                                    }
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Form>
                                {/* </div> */}
                                <Divider orientation="left" orientationMargin="0" style={{ paddingLeft: "10px" }}>
                                    Bank Details
                                </Divider>

                                <div style={{ marginLeft: "30px" }}>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">BANK NAME</Text>
                                            <Title level={5}>{alternateTwoData?.bankDetails?.bankName}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Account Holder Name</Text>
                                            <Title level={5}>{alternateTwoData?.bankDetails?.accHolderName}</Title>
                                        </Space>
                                    </Row>
                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">Account Number</Text>
                                            <Title level={5}>{alternateTwoData?.bankDetails?.accNumber}</Title>
                                        </Space>
                                    </Row>

                                    <Row className="custom-row">
                                        <Space direction="vertical" className="gap">
                                            <Text className="title">IFSC Code</Text>
                                            <Title level={5}>{alternateTwoData?.bankDetails?.ifscCode}</Title>
                                        </Space>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            <div className="content-box">
               
                <Row>
                    <Col className="btn-box" span={24}>
                        <Tabs activeKey={activeKey} onChange={onTabChange} type="card" className="custom-app-tab dynamic-tabs" style={customStyles}>
                            <TabPane tab="Basic Details" key="1">
                                {showBasicDetails()}
                            </TabPane>
                            <TabPane tab="KYC Details" key="2">
                                {showKycDetails()}
                            </TabPane>
                            {(id === connector?.basicDetails?.ownerConnectorUserId || connector?.basicDetails?.connectorAdditionalInfo?.canAddBankInfo) &&
                                <TabPane tab="Payout Details" key="3">
                                    {showPayoutsDetails()}
                                </TabPane>
                            }
                            <TabPane tab="Other Details" key="4">
                                {showOtherDetails()}
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

            </div>
        </>
    );
};

export default ViewConnector;
