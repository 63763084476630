import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const insurerSlice = createSlice({
  name: "InsurerReducer",
  initialState: { pageNo: 1, pageSize: 10, searchParam: null },
  reducers: {
    setInsurerPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setInsurerPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setInsurerSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setInsurerPageNo, setInsurerPageSize, setInsurerSearchParam } = insurerSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default insurerSlice.reducer;